import { Row, Col, Container } from "react-bootstrap";
import OwnerShopProfile from "../../components/OwnerShopProfile/OwnerShopProfile";
import ShopItemsOwner from "../../components/ShopItemsOwner/ShopItems";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import url from "../../config/axios";
import BackArrow from "../../components/backArrow";
import Managebuttons from "../../components/Manage_Buttons";
import { useSelector } from "react-redux";

const RawMaterailOwnerProfile = () => {
  const { id } = useParams();
  const [shopData, setShopData] = useState();
  const [suggest, setSuggest] = useState(0);
  const [suggestdetail, setSuggestdetail] = useState(0);
  const { name } = useSelector((state) => state.user);
  useEffect(() => {
    url
      .get(`shop/user/?userid=${name._id}&businessType=rawmaterialShop`)
      .then(async (res) => {
        if (res.data.success) {
          localStorage.setItem("mycategory", (res?.data?.data?.Shops[0]?.shopCategory?.name));
        }
      });
  }, []);
  useEffect(() => {
    url.get(`shop/id?id=${id}`).then(async (res) => {
      setShopData(res?.data?.data?.Shop);
    });
  }, []);
  return (
    <Container>
      <Col md={11} lg={11} className="mx-auto ">
        <Row>
          <Col md={4} lg={3} className="p-0">
            {" "}
            <OwnerShopProfile
              Add="Create Items"
              navigateTo="/update-rawmaterial-form"
              Name="Update Raw Material Profile"
              update="/update-rawmaterial-form"
              // post="/rawmaterial-postAdd"
              shopData={shopData}
              setSuggest={setSuggest}
            />
          </Col>
          <Col md={8} lg={9} className=" screenHeight custom_ps-4">
            <div className="Form-Shadow h-100">
              <Row>
                <Col lg="6">

                  <BackArrow
                    back={`/rawmaterial-business-profile/${id}`}
                    mystyle="p-1 ps-2"
                  />
                </Col>
                <Col lg="6" className="mt-2 pe-4">

                  <Managebuttons id={id} businessurl="/RawmaterialShop" />
                </Col>
              </Row>
              <ShopItemsOwner
                urlPass="/rawmaterial-ownerview"
                navigateto="/raw-material-form"
                shopid={id}
                post={`/rawmaterial-postAdd/${id}`}
                suggest={suggest}
                setSuggest={setSuggest}
                suggestdetail={suggestdetail}
                setSuggestdetail={setSuggestdetail}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default RawMaterailOwnerProfile;
