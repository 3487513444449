import React from "react";
import BoxData from "../Box";
import CardNav from "../CardNav";
import { Col, Row, Card } from "react-bootstrap";
import { images } from "../../../../constants";
import { useState } from "react";
const ScrapMaterial = ({ btn }) => {
  const [id, setIs] = useState(1);
  const [myinfo, setMyinfo] = useState("scrap info");

  return (
    <div>
      <Row className="set-one">
        <Col lg="12">
          <CardNav Header="Single Property" myid={id} myinfo={myinfo} />
          <Card className="shadow-main mt-2">
            <BoxData
              Title="Property Adds"
              Butonn={btn}
              imgg={images.Businessimage}
              url="/buy-single-property"
              link="/buy-single-property"
              ms="twentythree-element"
              buy="twenty"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ScrapMaterial;
