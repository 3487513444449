import { ShopModelCarousal } from "../../../constants/jsonConstant";
import { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import "../../../components/shopItemsView/shopitemview.css";
import { Row, Col, Container } from "react-bootstrap";
import BackArrow from "../../../components/backArrow";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RedoIcon from "@mui/icons-material/Redo";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { SuccessNotify } from "../../../constants/tostify";
import { FailedNotify } from "../../../constants/tostify";
import Deletemodal from "../../../components/DeleteModal";
import url from "../../../config/axios";
import ShareModel from "../../../components/Sharemodel";
import ShareOption from "../../../components/Shareoption";
import Loader from "../../../components/Loader";
import { useSelector } from "react-redux";
import { getAsset } from "../../../helpers/helper";
const FoodItemsView = () => {
  const { name } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const { id, shopid } = useParams();
  console.log("itemid", id);
  const handleClose = () => setShows(0);
  const [increase, setIncrease] = useState(1);
  const [Itemdata, setItemsData] = useState();
  const [show, setShow] = useState(0);
  const [shows, setShows] = useState(false);
  useEffect(() => {
    url.get(`fooditem/id/?id=${id}`).then(async (response) => {
      console.log("Itemdata", response);

      setItemsData(response?.data?.data);
    });
  }, []);
  const Deleteitem = () => {
    setShows(0);
    setIsloading(true);
    url
      .delete(`fooditem/?id=${id}`)
      .then(async (response) => {
        if (response.data.success) {
          setIsloading(false);
          SuccessNotify("Item Deleted Successfully");

          navigate(`/RestaurantAlldata/${Itemdata.shopid}`);
        }
      })
      .catch((e) => {
        setIsloading(false);
        FailedNotify("Failed to Delete Try Again!");
      });
  };

  const [showmodal, setShowmodal] = useState(false);
  const handleClosemodal = () => setShowmodal(false);
  const handleShow = () => setShowmodal(true);

  const [showshare, setShowshare] = useState(false);
  const handleCloseshare = () => {
    setShowshare(false);
    setShowmodal(true);
  };
  const handleShowshare = () => setShowshare(true);

  useEffect(() => {
    const img = document.querySelectorAll('.image-gallery-image');
  
    img.forEach(element => {
      element?.setAttribute('crossorigin', 'anonymous');
    });
  
  }, []);

  useEffect(() => {
    const thumimgs = document.querySelectorAll('.image-gallery-thumbnail-image');
  
    thumimgs.forEach(element => {
      element?.setAttribute('crossorigin', 'anonymous');
    });
  }, []);




const ShopModelCarousal = [
    {
      id: 1,
      original: getAsset(Itemdata?.images[0]),
      thumbnail: getAsset(Itemdata?.images[0]),
      
    
  
    },
    {
      id: 2,
      original:  getAsset(Itemdata?.images[1]),
      thumbnail:  getAsset(Itemdata?.images[1]),
  
    },
    {
      id: 3,
      original:  getAsset(Itemdata?.images[2]),
      thumbnail:  getAsset(Itemdata?.images[2]),
  
    },
    {
      id: 4,
      original:  getAsset(Itemdata?.images[3]),
      thumbnail:  getAsset(Itemdata?.images[3]),
    
    },
    {
      id: 5,
      original:  getAsset(Itemdata?.images[4]),
      thumbnail:  getAsset(Itemdata?.images[4]),
  
    },
  ];


  return (
    <>
      <ShareOption showshare={showshare} handleCloseshare={handleCloseshare} />
      <ShareModel
        showmodal={showmodal}
        handleClosemodal={handleClosemodal}
        handleShowshare={handleShowshare}
      />
      {shows === 1 && (
        <Deletemodal
          shows={shows}
          title="Profile"
          handleClose={handleClose}
          animation={true}
          handledelete={Deleteitem}
        />
      )}
      <Container>
        <div className="screenHeight">
          <div className="ps-5">
            <h5>{Itemdata?.createdAt}</h5>
          </div>
          <Row>
            <Col lg="6">
              <div className="row ">
              <ImageGallery
                  showPlayButton={false}
                  showFullscreenButton={false}
                  items={ShopModelCarousal}
                 
                
                />
              </div>
            </Col>
            <Col lg="6">
              <div className=" ps-4 pe-4 pt-4 pb-1 bg-vilot View-Model-Shadow">
                {isloading === true ? (
                  <Loader heightsetting="screenHeight" />
                ) : (
                  <Row className="ps-5 pe-5">
                    <Col lg="6" md="6" sm="6" xs="6">
                      <h4 className="fw-bold">{Itemdata?.dishname}</h4>
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="6" className="text-center">
                      <button className="shareItembtn" onClick={handleShow}>
                        Share <RedoIcon className="share_icon_f" />
                      </button>
                    </Col>
                    <p className="col-11 fw-lighter">{Itemdata?.description}</p>
                    <p className="">
                      Preparation Time : {Itemdata?.preparationTime}
                    </p>
                    <div>
                      <h3 className="font-blue">{Itemdata?.price}</h3>
                    </div>
                    <div>
                      <p className="mb-0">Contact us on</p>
                      <p className="mb-0">{name.email}</p>
                      <p className="mb-0">{Itemdata?.contact}</p>
                    </div>
                    <div className="bg-white quantityBox mt-2 mb-2  pe-2 ps-2 w-25 d-flex align-items-center ">
                      <div>
                        <p className="mb-0 pe-1">QTY:</p>
                      </div>
                      <div>
                        <p className="mb-0 pe-1">{increase}</p>
                      </div>
                      <div>
                        <div className="d-flex flex-column align-items-center ">
                          <ExpandLessIcon
                            fontSize="small"
                            onClick={() => setIncrease(increase + 1)}
                          />
                          <ExpandMoreIcon
                            fontSize="small"
                            onClick={() => setIncrease(increase - 1)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pt-4 d-flex">
                      <button
                        className="d-flex align-items-center border rounded ps-3 pe-3 pt-1 pb-1 bg-red text-white"
                        onClick={() => setShows(1)}
                      >
                        <DeleteOutlineOutlinedIcon />
                        <span className="ps-1">Delete</span>
                      </button>
                      <button
                        className="d-flex align-items-center border rounded ps-4 pe-4 pt-1 pb-1 bg-blue ms-3 me-3 text-white"
                        onClick={() => navigate(`/resturent-update-form/` + id)}
                      >
                        <CreateOutlinedIcon />
                        <span className="ps-1">Edit</span>
                      </button>
                    </div>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default FoodItemsView;
