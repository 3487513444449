import { images } from "../../../../constants";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import CallIcon from "@mui/icons-material/Call";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { useState, useEffect } from "react";
import url from "../../../../config/axios";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import ReportIcon from "@mui/icons-material/Report";
import SettingsIcon from "@mui/icons-material/Settings";
import CampaignIcon from "@mui/icons-material/Campaign";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import StarIcon from "@mui/icons-material/Star";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./companySidebar.css";
import Qrmodal from "../../../../components/QRmodal/Qrmodal";
import Desmodal from "../../../../components/Des_Modal";
const BusinessPropertySideBar = ({
  navigateTo,
  Name,
  Add,
  post,
  setSuggest,
  suggestdatta,

  shopData,
}) => {
  const [showmodal, setShowmodal] = useState(false);
  const closemodal = () => setShowmodal(false);
  const { id } = useParams();
  const { name } = useSelector((state) => state.user);
  const navigate = useNavigate();
  // const [shopData, setshopData] = useState();
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Click on address to view map
    </Tooltip>
  );
  const [desshow, setDesshow] = useState(false);

  const handleClosedes = () => setDesshow(false);
  const handleShowdes = () => setDesshow(true);
  // useEffect(() => {
  //   url.get(`/company/id/?id=${id}`).then(async (res) => {
  //     setshopData(res?.data?.data?.Company);
  //   });
  // }, []);

  return (
    <Row>
      <Desmodal
        desshow={desshow}
        handleClosedes={handleClosedes}
        des={shopData?.description}
      />
      <Qrmodal show={showmodal} handleClosemodal={closemodal} />
      <Col
        className="shop-profile-box  pt-1 px-3  ms-0 me-0 screenHeight"
        md={12}
        lg={12}
      >
        <div
          className="d-flex justify-content-center pb-2"
          onClick={() => navigate(`${navigateTo}/` + id)}
        >
          <img className="w-img" src={images.UpdateShop} alt="UpdateShop" />
          <h6 className="mb-0 ps-2 pt-1 updateSize cursor">{Name}</h6>
        </div>
        <div>
          <img
            className="w-100 shop-profile-img"
            src={shopData?.image}
            alt=""
          />
        </div>
        <div className="box-container mt-1">
          <Row>
            <Col lg="9">
              <div className="pt-2 pb-1">
                <h4 className="shop-Profile-font mb-0 pb-1 ">
                  {shopData?.companyname.slice(0, 15) + "..."}
                </h4>
                <Col lg="8">
                  <p className="shop-profile-para mb-0 d-flex">
                    <div>{shopData?.description.slice(0, 10)}</div>
                    <div>
                      {shopData?.description.length > 10 && (
                        <p
                          onClick={handleShowdes}
                          className="mb-0 cursor_P font_11 text-dark"
                          style={{ marginTop: "3px" }}
                        >
                          &nbsp;<b>ReadMore...</b>
                        </p>
                      )}
                    </div>
                  </p>
                </Col>
              </div>
            </Col>
            <Col lg="3">
              <div className="d-flex justify-content-end">
                <QrCodeScannerIcon onClick={() => setShowmodal(true)} />
              </div>
            </Col>
          </Row>
          <Row>
            {/* <Col lg={12} xl={12}>
              <p className="shop-profile-para mb-0 ">{shopData?.description}</p>
            </Col> */}
          </Row>

          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <div className="d-flex align-items-center">
              <LocationCityIcon className="silver-icon" />
              <p className="mb-0 ViewlabelText pt-1 pb-1 ps-2">
                {shopData?.companyaddress}
              </p>
            </div>
          </OverlayTrigger>
        </div>

        <div>
          <div>
            <div className="d-flex">
              <div className="">
                <div className="d-flex align-items-center  pb-2 mt-2 cursor_P">
                  <StarIcon className="silver-icon" />
                  <p className="mb-0 ViewlabelText ps-2">4.5</p>
                </div>
                <div className="d-flex align-items-center  pb-2 cursor_P">
                  <ThumbUpIcon className="silver-icon" />
                  <p className="mb-0 ViewlabelText ps-2">Liked by 250 </p>
                </div>
                <div className="d-flex align-items-center  pb-2 cursor_P">
                  <ThumbDownIcon className="silver-icon" />
                  <p className="mb-0 ViewlabelText ps-2">Unliked by 45 </p>
                </div>
                <div
                  className="d-flex align-items-center  pb-2 cursor_P"
                  onClick={() => {
                    setSuggest(1);
                    suggestdatta();
                  }}
                >
                  <NotificationsIcon className="silver-icon" />
                  <p className="mb-0 ViewlabelText ps-2">Suggestions</p>
                </div>

                <div
                  className="d-flex align-items-center cursor_P pb-2"
                  onClick={() => setSuggest(2)}
                >
                  <ReportIcon className="silver-icon" />
                  <p className="mb-0 ViewlabelText ps-2">Report</p>
                </div>
                <div
                  className="d-flex align-items-center pb-2 cursor_P"
                  onClick={() => navigate("/company-setting")}
                >
                  <SettingsIcon className="silver-icon" />
                  <p className="mb-0 ViewlabelText ps-2">Setting</p>
                </div>
                <div
                  className="d-flex align-items-center pb-2 cursor_P"
                  onClick={() => navigate(`/promote-shop/${id}`)}
                >
                  <CampaignIcon className="silver-icon" />
                  <p className="mb-0 ViewlabelText ps-2">Promote Your Shop</p>
                </div>
                <div className="d-flex align-items-center  pb-2 cursor_P">
                  <VideoCallIcon className="silver-icon" />
                  <p className="mb-0 ViewlabelText ps-2">Go Live</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" pt-1"></div>
      </Col>
    </Row>
  );
};

export default BusinessPropertySideBar;
