import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import BackArrow from "../../backArrow";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import url from "../../../config/axios";  
import axios from "axios";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { SuccessNotify, FailedNotify } from "../../../constants/tostify";
import Loader from "../../Loader";


  const JobApplicationForm = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const { name } = useSelector((state) => state.user);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedstateone, setSelectedstateone] = useState("");
  const [searchTermone, setSearchTermone] = useState("");

  const [selectedcity, setSelectedcity] = useState("");
  const [searchTermtwo, setSearchTermtwo] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCountryChange = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
  };

  const handleSearchChangestate = (event) => {
    setSearchTermone(event.target.value);
  };


  const handleSearchChangecity = (event) => {
    setSearchTermtwo(event.target.value);
  };

  // Function to handle the state selection
  const handleCountryChangestate = (event) => {
    const { value } = event.target;
    setSelectedstateone(value);
  };

  // Function to handle the city selection
  const handleCountryChangecity = (event) => {
    const { value } = event.target;
    setSelectedcity(value);
  };
  const [hidefield, setHidefield] = useState(1);
  const [hidestate, setHidestate] = useState(1);
  const [hidecity, setHidecity] = useState(1);

  const [countrydata, SetCountrydata] = useState([]);
  const [getcountry] = useState();
  const [selectedState] = useState();
  const [infooo, setInfooo] = useState();
  const [getState, setState] = useState([]);
  const [getcity, setGetcity] = useState([]);
  const [statetwo, setStatetwo] = useState(getcountry);
  const [city, setCity] = useState(selectedState);
  const [employeedata, setEmployeedata] = useState();
  const [isloading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const [shopCategory, setShopCategory] = useState();
  const [jobdataone, SetJobdataone] = useState({
    categoryid: "",
  });

  const [jobcategory, SetJobcategory] = useState();
  const [jobcategorytwo, SetJobcategorytwo] = useState();

  const Handlejobdata = (e) => {
    SetJobdataone((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split("."),
    }));
  };

  const categoryName = jobdataone?.categoryid[1];
  const categoryidd = jobdataone?.categoryid[0];

  useEffect(() => {
    url.get(`/user/id?id=${name?._id}`).then((response) => {
      setInfooo(response?.data?.data?.User);
    });
  }, []);


  const [jobData, setJobData] = useState({
    userid: name._id,
    jobtitle: "",
    jobtype: "",
    country: "",
    categoryid: categoryidd,
    category: "",
    subCategoryid: "",
    city: "",
    state: "",
    jobexperience: "",
    image: [],
    name: name.fullname,
    siteremote: "",
    salarywant: "",
    jobdescription: "",
    duration: new Date(),
    status: true,
  });

  useEffect(() => {
    setJobData((jobData) => ({
      ...jobData,
      image: infooo?.imageUrl,
    }));
    // ...
  }, [infooo]);
  useEffect(() => {
    setJobData(() => ({
      ...jobData,
      categoryid: categoryidd

    }))
  }, [categoryidd])

  useEffect(() => {
    url
      .get("/category/type/?type=jobs")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => { });
  }, []);

  useEffect(() => {
    url
      .get("/category/type/?type=jobs&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          SetJobcategory(response?.data?.data?.Category);
        }
      });
  }, []);

  const Mydatathree = () => {
    url
      .get(`/category/type/?type=jobs&parentCategory=${categoryName}`)
      .then(async (response) => {
        if (response?.data?.success) {
          SetJobcategorytwo(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("jobcategory", error);
      });
  };

  useEffect(() => {
    url.get(`/employee/id/?id=${id}`).then(async (response) => {
      setEmployeedata(response?.data?.data?.Employee);
    });
  }, []);

  const JobSubmit = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("/employee/", jobData)
      .then(async (response) => {
        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Form Createjob Created Successfully");
            navigate(`/view-application/${response?.data?.data?._id}`);
          }, 1000);
        }
      })
      .catch(() => {
        setIsloading(false);
        FailedNotify("Submission Failed ! Try Again");
      });
  };

  const JobUpdate = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .put(`/employee/id/?id=${id}`, jobData)
      .then(async (response) => {
        if (response.data.success) {
          setIsloading(false);
          SuccessNotify("Form Createjob Updated Successfully");
          setTimeout(() => {
            navigate("/view-application/" + id);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsloading(false);

        FailedNotify("Form Updation Failed ! Try Again");
      });
  };

  const InputJob = (event) => {
    setJobData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };


  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => SetCountrydata(res.data))

      .catch((error) => { });
  }, []);

  const country = [...new Set(countrydata.map((item) => item.country))];

  country.sort();

  const handleCountry = (e) => {
    let states = countrydata.filter(
      (state) => state.country === e.target.value
    );
    states = [...new Set(states.map((item) => item.subcountry))];
    states.sort();
    setState(states);
  };

  const handleState = (e) => {
    let cities = countrydata.filter(
      (city) => city.subcountry === e.target.value
    );
    setGetcity(cities);
  };
  if (isloading) {
    return (
      <div>
        <Loader heightsetting="screenHeight" />
      </div>
    );
  }

  return (
    <Row>
      <form>
        <div className="screenHeight d-flex justify-content-center align-items-center">
          <Col lg={9} md={10} sm={9} xs={12} className="Form-Shadow ">
            <div className="arrowback pb-1 ">
              <BackArrow
                back={props.back}
                black="black"
                mystyle="p-1 ps-4 pt-2"
              />
            </div>
            <div className="px-4">
              <Form.Label className="custm-lble pt-1 ps-4">
                Job Wanted
              </Form.Label>
              <Form onSubmit={(e) => JobSubmit(e)}>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-2 ps-4">
                    <Form.Check
                      inline
                      label="Full time"
                      type={type}
                      id={`inline-${type}-1`}
                      className="labelText"
                      name="jobtype"
                      onChange={(e) => InputJob(e)}
                      value="full time"
                    />
                    <Form.Check
                      inline
                      label="Part time"
                      type={type}
                      id={`inline-${type}-2`}
                      className="labelText"
                      name="jobtype"
                      onChange={(e) => InputJob(e)}
                      value="part time"
                    />
                  </div>
                ))}
              </Form>
              <Row className="ps-4 pe-4">
                <Col md={4} lg={4} xl={4}>
                  <Form.Label className="custm-lble pb-1">
                    Select Job
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="jobtitle"
                    onChange={(e) => InputJob(e)}
                    className="input-style form-control-seller"
                  >
                    {shopCategory?.map((category) => (
                      <option key={category._id} value={category?.name}>
                        {category?.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>

                <Col md={4} lg={4} xl={4}>
                  <Form.Label className="custm-lble pb-1">
                    Job Category
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="categoryid"
                    className="input-style form-control-seller"
                    onChange={(e) => {
                      Handlejobdata(e);
                    }}
                  >
                    <option>job category</option>
                    {jobcategory?.map((category) => (
                      <>
                        <option
                          key={category._id}
                          value={`${category?._id}.${category?.name}`}
                        >
                          {category?.name}
                        </option>
                      </>
                    ))}
                  </Form.Select>
                </Col>

                <Col md={4} lg={4} xl={4}>
                  <Form.Label className="custm-lble pb-1">
                    Job Sub-Category
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="subCategoryid"
                    className="input-style form-control-seller"
                    onChange={(e) => {
                      InputJob(e);
                    }}
                    onClick={Mydatathree}
                  >
                    {" "}
                    <option>job sub category</option>{" "}
                    {jobcategorytwo?.map((subcategory) => (
                      <>
                        <option key={subcategory._id} value={subcategory?._id}>
                          {subcategory?.name}
                        </option>
                      </>
                    ))}
                  </Form.Select>
                </Col>

                <div className="col-md-4">
                  <label
                    htmlFor="inputPassword4"
                    placeholder="First name"
                    className="form-label labelSize"
                  >
                    Country*
                  </label>
                  <div className="blood-type input-style">
                    <div className="d-flex">
                      <div className="w-100">
                        <input
                          type="search"
                          value={searchTerm}
                          onChange={handleSearchChange}
                          placeholder="Search country here...."
                          className="w-100 search-input"
                          onClick={() => setHidefield(0)}
                        />
                      </div>
                      <span>
                        <SearchIcon className="search-icon-fs" />
                      </span>
                    </div>

                    <select
                      onClick={() => setHidefield(1)}
                      value={selectedCountry}
                      onChange={(e) => {
                        handleCountryChange(e);
                        handleCountry(e);
                        InputJob(e);
                      }}
                      className="b-radius selectSize w-100"
                      name="country"
                    >
                      {hidefield && <option>Select country</option>}

                      {country
                        .filter((country) =>
                          country
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                        .map((country, index) => (
                          <option key={country} value={getcountry}>
                            {country}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <label
                    htmlFor="inputPassword4"
                    placeholder="First name"
                    className="form-label labelSize"
                  >
                    State*
                  </label>
                  <div className="blood-type input-style">
                    <div className="d-flex">
                      <div className="w-100">
                        <input
                          type="search"
                          value={searchTermone}
                          onChange={handleSearchChangestate}
                          placeholder="Search state here...."
                          className="w-100 search-input"
                          onClick={() => setHidestate(0)}
                        />
                      </div>
                      <span>
                        <SearchIcon className="search-icon-fs" />
                      </span>
                    </div>

                    <select
                      onClick={() => setHidestate(1)}
                      className="b-radius selectSize w-100 "
                      value={selectedstateone}
                      aria-label="Default select example "
                      name="state"
                      onChange={(e) => {
                        InputJob(e);

                        handleState(e);
                        handleCountryChangestate(e);
                      }}
                    >
                      {hidestate && <option>Select state</option>}
                      {/* <option>
                            {Open === undefined
                              ? "Select Country First"
                              : "select state"}
                          </option> */}

                      {getState
                        .filter((country) =>
                          country
                            .toLowerCase()
                            .includes(searchTermone.toLowerCase())
                        )
                        .map((country) => (
                          <option key={country} value={selectedState}>
                            {country}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <label
                    htmlFor="inputPassword4"
                    placeholder="First name"
                    className="form-label labelSize"
                  >
                    City
                  </label>
                  <div className="blood-type input-style">
                    <div className="d-flex">
                      <div className="w-100">
                        <input
                          type="search"
                          value={searchTermtwo}
                          onChange={handleSearchChangecity}
                          placeholder="Search city here...."
                          className="w-100 search-input"
                          onClick={() => setHidecity(0)}
                        />
                      </div>
                      <span>
                        <SearchIcon className="search-icon-fs" />
                      </span>
                    </div>

                    <select
                      onClick={() => setHidecity(1)}
                      value={selectedcity}
                      className="b-radius selectSize w-100"
                      aria-label="Default select example"
                      name="city"
                      onChange={(e) => {
                        InputJob(e);

                        handleCountryChangecity(e);
                      }}
                    >
                      {/* <option>
                              {city === undefined
                                ? "Select State First"
                                : "Select City"}
                            </option> */}

                      {hidecity && <option>Select city</option>}

                      {getcity
                        .filter((country) =>
                          country.name
                            .toLowerCase()
                            .includes(searchTermtwo.toLowerCase())
                        )
                        .map((country) => (
                          <option key={country.name}>{country.name}</option>
                        ))}
                    </select>
                  </div>
                </div>

                <Col className="pt-2" md={3} lg={4} xl={4} sm={12} xs={12}>
                  <Form.Label className="custm-lble ">
                    Job Experience
                  </Form.Label>
                  <Form.Control
                    placeholder="Job Experience"
                    onChange={(e) => InputJob(e)}
                    name="jobexperience"
                    className="input-style ps-3 pe-3 form-control-seller"
                    defaultValue={employeedata?.jobexperience}
                  />
                </Col>
                <Col className="pt-2" md={3} lg={4} xl={4}>
                  <Form.Label className="custm-lble">
                    Salary You Want
                  </Form.Label>
                  <Form.Control
                    placeholder="Salary You Want"
                    onChange={(e) => InputJob(e)}
                    name="salarywant"
                    className="input-style form-control-seller"
                    defaultValue={employeedata?.salarywant}
                  />
                </Col>
              </Row>

              <Form.Label className="custm-lble mb-0 pb-1 ps-4">
                OnSite/Remotely/Hybrid
              </Form.Label>
              <Form>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-2 ps-4">
                    <Form.Check
                      inline
                      label="onsite"
                      name="siteremote"
                      type={type}
                      id={`inline-${type}-1`}
                      className="labelText"
                      value="onsite"
                      onChange={(e) => InputJob(e)}
                    />
                    <Form.Check
                      inline
                      label="Remotely"
                      name="siteremote"
                      type={type}
                      id={`inline-${type}-2`}
                      className="labelText"
                      value="remote"
                      onChange={(e) => InputJob(e)}
                    />
                    <Form.Check
                      inline
                      label="Hybrid"
                      name="siteremote"
                      type={type}
                      id={`inline-${type}-2`}
                      className="labelText"
                      value="hybrid"
                      onChange={(e) => InputJob(e)}
                    />
                  </div>
                ))}
              </Form>

              <div className="ps-4 pe-4">
                <Form.Label className="custm-lble pb-1">
                  Add Description
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={6}
                  className="input-style"
                  placeholder="Add Description"
                  name="jobdescription"
                  onChange={(e) => InputJob(e)}
                  defaultValue={employeedata?.jobdescription}
                />
              </div>

              <div className="btns pe-4 pb-3 pt-3">
                <button className="btn1" onClick={() => navigate("/CV-Form")}>
                  Cancel
                </button>
                <Button
                  className="btn2 p-1"
                  variant="primary"
                  onClick={
                    props.butn === "Post"
                      ? (e) => JobSubmit(e)
                      : (e) => JobUpdate(e)
                  }
                >
                  {props.butn}
                </Button>
              </div>
            </div>
          </Col>
        </div>
      </form>
    </Row>
  );
};
export default JobApplicationForm;
