import Logoone from "../assets/images/Auth/“.svg";
import Logotwo from "../assets/images/Auth/Vector 1.svg";
import Selectezilogo from "../assets/images/Auth/selectezi.svg";
import ProfileImg from "../assets/images/dashboard/profile.png";
import Selecteziimg from "../assets/images/Navbar/selectezi.png";
import Bell from "../assets/images/Navbar/bell.png";
import LogOut from "../assets/images/Navbar/out.png";
import Home from "../assets/images/Navbar/house.png";
import LeftArrow from "../assets/images/Form/leftarrow.png";
import Upload from "../assets/images/Form/upload.png";
import Businessdashboard from "../assets/images/Sidebarmenu/business dashboard top.png";
import Add from "../assets/images/Form/Add.png";
import File from "../assets/images/Form/File.png";

import Profileimg from "../assets/images/Profile_img/Circular2.png";
import Timelineimg from "../assets/images/Timeline/timeline.png";

import Jobonline from "../assets/images/dashboard/job.svg";
import Servicesonline from "../assets/images/dashboard/service.svg";
import Propertyonline from "../assets/images/dashboard/property.svg";
import Candidtaesonline from "../assets/images/dashboard/candidate.svg";
import Minerals from "../assets/images/dashboard/minerals.svg";
import Newproducts from "../assets/images/dashboard/New.svg";
import Transportation from "../assets/images/dashboard/Transportation.svg";
import Homemade from "../assets/images/dashboard/homemade.svg";
import Call from "../assets/images/dashboard/call.svg";
import Email from "../assets/images/dashboard/emial.svg";
import Chatroom from "../assets/images/dashboard/chatroom.svg";
import Usedproducts from "../assets/images/dashboard/usedproduct.svg";
import Items from "../assets/images/Items/Items.png";
import StarImg from "../assets/images/Items/stars.png";
import Image1 from "../assets/images/ShopModel/image1.png";
import Image2 from "../assets/images/ShopModel/image2.png";
import Image3 from "../assets/images/ShopModel/image3.png";
import ShopItem from "../assets/images/Items/ShopItem.png";
import Phone from "../assets/images/Items/Phone.png";
import Mail from "../assets/images/Items/Mail.png";
import Maps from "../assets/images/ShopProfile/map.png";
import ShopProfile from "../assets/images/ShopProfile/shopProfile.png";
import buliding from "../assets/images/ShopProfile/buliding.png";
import dislike from "../assets/images/ShopProfile/dislike.png";
import Like from "../assets/images/ShopProfile/like.png";
import Reciver from "../assets/images/ShopProfile/reciver.png";
import Car from "../assets/images/AddsImg/car.png";
import Update from "../assets/images/ShopProfile/update.png";
import UpdateShop from "../assets/images/ShopProfile/updateShop.png";
import OfferJob from "../assets/images/createJobs/offerjob.svg";
import CandidatesJob from "../assets/images/createJobs/candidates.svg";
import StarsShop from "../assets/images/Items/stars.png";
import Cart from "../assets/images/Items/cart.png";

import Shop from "../assets/images/ShopProfiles/Shop_pic.png";
import Shopadone from "../assets/images/ShopProfiles/Shop-adone.png";
import Shopadtwo from "../assets/images/ShopProfiles/Shop-adtwo.png";
import Shopadthree from "../assets/images/ShopProfiles/Shop-adthree.png";
import Eye from "../assets/images/Items/Eye.png";
import Property from "../assets/images/Property/Property.png";
import Transportprofile from "../assets/images/Transport/Profile6.png";
import transport from "../assets/images/Transport/transport.png";
import car1 from "../assets/images/Transport/car1.png";
import Car1 from "../assets/images/services/car1.svg";
import Car2 from "../assets/images/services/car2.svg";
import Car3 from "../assets/images/services/car3.svg";
import Car4 from "../assets/images/services/car4.svg";
import Car5 from "../assets/images/services/car5.svg";
import Car6 from "../assets/images/services/car6.svg";
import Up from "../assets/images/ShopModel/up.png";
import Down from "../assets/images/ShopModel/down.png";
import Call2 from "../assets/images/Items/call2.png";
import BluePhone from "../assets/images/Items/bluePhone.png";
import BlueMail from "../assets/images/Items/BlueMail.png";
import Helpline from "../assets/images/helpline/helpline.svg";
import WorldSearch from "../assets/images/filterationForm Img/WorldSearch.png";
import WorldSearchtwo from "../assets/images/filterationForm Img/WorldSearchtwo.svg";
import PostArrow from "../assets/images/Items/postArrow.svg";
import CompanyDash from "../assets/images/dashboard/company.svg";
import NewItemsDash from "../assets/images/dashboard/newitems.svg";
import UsedItemsDash from "../assets/images/dashboard/useditems.svg";
import ServicesDash from "../assets/images/dashboard/services.svg";
import PersonaPropertyDash from "../assets/images/dashboard/personalproperty.svg";
import BusinessPropertyDash from "../assets/images/dashboard/businessproperty.svg";
import HomeMAdeDash from "../assets/images/dashboard/homemadedash.svg";
import MineralsDash from "../assets/images/dashboard/mineralsdash.svg";
import TranportaionDash from "../assets/images/dashboard/Transportation.svg";
import JobWantedDash from "../assets/images/dashboard/bag.svg";
import Menuone from "../assets/images/Sidebarmenu/New.svg";
import Menutwo from "../assets/images/Sidebarmenu/menutwo.svg";
import Menuthree from "../assets/images/Sidebarmenu/menuthree.svg";
import Menufour from "../assets/images/Sidebarmenu/menufour.svg";
import Menufive from "../assets/images/Sidebarmenu/menufive.svg";
import Menusix from "../assets/images/Sidebarmenu/menusix.svg";
import Menuseven from "../assets/images/Sidebarmenu/menuseven.svg";
import Menueight from "../assets/images/Sidebarmenu/menueight.png";
import Menunine from "../assets/images/Sidebarmenu/menunine.png";
import Menuten from "../assets/images/Sidebarmenu/menuten.png";
import Menueleven from "../assets/images/Sidebarmenu/menueleven.png";
import CVProfileImg from "../assets/images/cv/cvprofile.svg";
import DownLine from "../assets/images/cv/downline.svg";
import SideLine from "../assets/images/cv/sideline.svg";
import HRLine from "../assets/images/cv/hrline.svg";
import Category1 from "../assets/images/Category/Beanie.png";
import Category2 from "../assets/images/Category/Womens Blouse.png";
import Category3 from "../assets/images/Category/Shorts.png";
import Category4 from "../assets/images/Category/Girl.png";
import Category5 from "../assets/images/Category/Bride.png";
import Category6 from "../assets/images/Category/Formal Outfit.png";
import Category7 from "../assets/images/Category/Dress Back View.png";
import Drag from "../assets/images/Profile_img/Drag.svg";
import dashboard_1 from "../assets/images/dashboard/1_b.svg";
import dashboard_2 from "../assets/images/dashboard/2_b.svg";
import dashboard_3 from "../assets/images/dashboard/3_b.svg";
import Farming from "../assets/images/dashboard/farming.svg";
import dashboard_5 from "../assets/images/dashboard/5_b.svg";
import dashboard_6 from "../assets/images/dashboard/6_b.svg";
import dashboard_7 from "../assets/images/dashboard/7_b.svg";
import dashboard_8 from "../assets/images/dashboard/8_b.svg";
import dashboard_9 from "../assets/images/dashboard/9_b.svg";
import dashboard_10 from "../assets/images/dashboard/10_b.svg";
import dashboard_11 from "../assets/images/dashboard/11_b.svg";
import dashboard_12 from "../assets/images/dashboard/12_b.svg";
import dashboard_14 from "../assets/images/dashboard/14_b.svg";

import dashboard_d_1 from "../assets/images/dashboard/1_d.svg";
import dashboard_d_2 from "../assets/images/dashboard/2_d.svg";
import dashboard_d_3 from "../assets/images/dashboard/3_d.svg";
import dashboard_d_4 from "../assets/images/dashboard/4_d.svg";
import dashboard_d_5 from "../assets/images/dashboard/5_d.svg";
import dashboard_d_6 from "../assets/images/dashboard/6_d.svg";
import dashboard_d_7 from "../assets/images/dashboard/7_d.svg";
import dashboard_d_8 from "../assets/images/dashboard/8_d.svg";
import dashboard_d_9 from "../assets/images/dashboard/9_d.svg";
import dashboard_d_10 from "../assets/images/dashboard/10_d.svg";
import dashboard_d_11 from "../assets/images/dashboard/11_d.svg";
import dashboard_d_12 from "../assets/images/dashboard/12_d.svg";
import dashboard_d_13 from "../assets/images/dashboard/13_d.svg";
import dashboard_d_14 from "../assets/images/dashboard/14_d.svg";
import dashboard_d_16 from "../assets/images/dashboard/16_d.svg";
import Businessimage from "../assets/images/Property/businesscard.png";
import Singlebusinessimage from "../assets/images/Property/singlecard.png";
import ErrorImg from "../assets/images/dashboard/404.svg";
import Usedvehicleshop from "../assets/images/Used_vehicle/used-v-shop.svg";
import Usedvehiclesingle from "../assets/images/Used_vehicle/used-v-single.svg";
import BGimgone from "../assets/images/filterationForm Img/BGimgone.png";
import Timeline from "../assets/images/sharemodel/timeline.png";
import Telegram from "../assets/images/sharemodel/telegram.png";
import Twitter from "../assets/images/sharemodel/twitter.png";
import Whatsapp from "../assets/images/sharemodel/whatsapp.png";
import Link from "../assets/images/sharemodel/link.png";
import Emailtwo from "../assets/images/sharemodel/email.png";
import Delete from "../assets/images/Addtocartmodal/Delete.png";
import BackArrow from "../assets/images/Addtocartmodal/BackArrow.png";
import Location from "../assets/images/Addtocartmodal/Location.png";
import Hours from "../assets/images/Addtocartmodal/24hours.png";
import Return from "../assets/images/Addtocartmodal/return.png";
import Wallet from "../assets/images/Addtocartmodal/wallet.png";
import goback from "../assets/images/Items/goback.svg";
import FindClinic from "../assets/images/Items/FindClinic.svg";
import AddImage from "../assets/images/Items/AddImage.svg";
import AddImage2 from "../assets/images/Items/AddImage2.svg";
import Addvideo from "../assets/images/Items/Addvideo.svg";
import Alex1 from "../assets/images/Items/Alex1.svg";
import Alex2 from "../assets/images/Items/Alex2.svg";
import goback2 from "../assets/images/Items/goback2.svg";
import Mainlogo from "../assets/images/Newdashboard/SELECTEZI.png";

import MainAvatar from "../assets/images/Newdashboard/MainAvatar.png";
import Vector from "../assets/images/Newdashboard/Vector.png";
import IronLogo from "../assets/images/Newdashboard/IronLogo.png";
import WholeSaler from "../assets/images/Newdashboard/WholeSaler.png";
import Retailer from "../assets/images/Newdashboard/Retailer.png";
import Factory from "../assets/images/Newdashboard/Factory.png";
import NewItems from "../assets/images/Newdashboard/NewItems.png";
import UsedItems from "../assets/images/Newdashboard/UsedItems.png";
import Homemadetwo from "../assets/images/Newdashboard/HomeMade.png";
import GemStone from "../assets/images/Newdashboard/GemStone.png";
import Jobs from "../assets/images/Newdashboard/Jobs.png";
import Candidates from "../assets/images/Newdashboard/Candidates.png";
import SellProperties from "../assets/images/Newdashboard/SellProperties.png";
import BuyVehicles from "../assets/images/Newdashboard/BuyVehicles.png";

import TransportServices from "../assets/images/Newdashboard/TransportServices.png";
import SingleAdds from "../assets/images/Newdashboard/SingleAdds.png";
import Services from "../assets/images/Newdashboard/Services.png";
import ScrapMaterial from "../assets/images/Newdashboard/ScrapMaterial.png";
import Recycled from "../assets/images/Newdashboard/Recycled.png";
// import Farming from '../assets/images/Newdashboard/Farming.png'
import SendInquiry from "../assets/images/Newdashboard/SendInquiry.png";
// import Email from '../assets/images/Newdashboard/Email.png'
import ChatRoom from "../assets/images/Newdashboard/ChatRoom.png";
import HelpLine from "../assets/images/Newdashboard/HelpLine.png";
// import Bell from '../assets/images/Newdashboard/bell.png'
import Out from "../assets/images/Newdashboard/out.png";
import Selectezi from "../assets/images/Newdashboard/SELECTEZI.png";
import Envelop from "../assets/images/Newdashboard/Envelop.png";
import House from "../assets/images/Newdashboard/house.png";
import SingleVehicle from "../assets/images/Newdashboard/SingleVehicle.png";

import CircularAvatar from "../assets/images/CommentSection/CircularAvatar.png";
import JuliaSideCard from "../assets/images/EmailSideCards/JuliaSideCardsimg.png";

import Verified from "../assets/images/Items/Verified.svg";
import Manual from "../assets/images/Newdashboard/manual.png";
import plusicon from "../assets/images/Email/plusicon.svg";

import chat from "../assets/images/Email/chat.png";
import impor from "../assets/images/Email/impor.png";
import joinmeet from "../assets/images/Email/joinmeet.png";
import mail_one from "../assets/images/Email/mail_one.png";
import meet from "../assets/images/Email/meet.png";
import schedule from "../assets/images/Email/schedule.png";

import Meting from "../assets/images/Email/Meting.png";
import Edit_pen from "../assets/images/Email/Edit_pen.png";
import Date_one from "../assets/images/Email/Date_one.png";
import Time_one from "../assets/images/Email/Time_one.png";
import Alert_one from "../assets/images/Email/Alert_one.png";
import Emailpic from "../assets/images/Email/Emailpic.svg";
import Companypic from "../assets/images/Email/Companypic.svg";
import Rectangle_1 from "../assets/images/GoogleMeet/Rectangle_1.png";
import Rectangle_2 from "../assets/images/GoogleMeet/Rectangle_2.png";
import Rectangle_3 from "../assets/images/GoogleMeet/Rectangle_3.png";
// import Rectangle_4 from "../assets/images/GoogleMeet/Rectangle_4.png"
import Rectangle_5 from "../assets/images/GoogleMeet/Rectangle_5.png";
import Vectorone from "../assets/images/GoogleMeet/Vector_one.png";
import Chatroomimg from "../assets/images/Chatroom/chatroomimg.png";
import Chatroomimg8 from "../assets/images/Chatroom/chatroomimg8.png";
import Chatroomimg2 from "../assets/images/Chatroom/chatroomimg2.png";
import Chatroomimg3 from "../assets/images/Chatroom/chatroomimg3.png";
import Chatroomimg4 from "../assets/images/Chatroom/chatroomimg4.png";
import Chatroomimg5 from "../assets/images/Chatroom/chatroomimg5.png";
import Chatroomimg6 from "../assets/images/Chatroom/chatroomimg6.png";
import Chatroomimg7 from "../assets/images/Chatroom/chatroomimg7.png";
import ownchatroom1 from "../assets/images/Chatroom/ownchatroom1.svg";
import ownchatroom2 from "../assets/images/Chatroom/ownchatroom2.svg";
import ownchatroom3 from "../assets/images/Chatroom/ownchatroom3.svg";
import ownchatroom4 from "../assets/images/Chatroom/ownchatroom4.svg";
import ownchatroom5 from "../assets/images/Chatroom/ownchatroom5.svg";
import ownchatroom6 from "../assets/images/Chatroom/ownchatroom6.svg";
import ownchatroom7 from "../assets/images/Chatroom/ownchatroom7.svg";
import ownchatroom8 from "../assets/images/Chatroom/ownchatroom8.svg";
import ownchatroom9 from "../assets/images/Chatroom/ownchatroom9.svg";
import Uploadchat from "../assets/images/Chatroom/upload_chat.png";
import livechat1 from "../assets/images/Chatroom/livechat1.png";
import livechat10 from "../assets/images/Chatroom/livechat10.png";
import livechat11 from "../assets/images/Chatroom/livechat11.png";
import livechat12 from "../assets/images/Chatroom/livechat12.png";
import livechat13 from "../assets/images/Chatroom/livechat13.png";
import livechat14 from "../assets/images/Chatroom/livechat14.png";
import livechat15 from "../assets/images/Chatroom/livechat15.png";
import livechat16 from "../assets/images/Chatroom/livechat16.png";
import livechat17 from "../assets/images/Chatroom/livechat17.png";
import livechat18 from "../assets/images/Chatroom/livechat18.png";
import lobi1 from "../assets/images/Chatroom/lobi1.png";
import lobi2 from "../assets/images/Chatroom/lobi2.png";
import lobi3 from "../assets/images/Chatroom/lobi3.png";
import lobi4 from "../assets/images/Chatroom/lobi4.png";
import lobi5 from "../assets/images/Chatroom/lobi5.png";
import lobi6 from "../assets/images/Chatroom/lobi6.png";
import lobi7 from "../assets/images/Chatroom/lobi7.png";
import lobi8 from "../assets/images/Chatroom/lobi8.png";
import lobi9 from "../assets/images/Chatroom/lobi9.png";
import lobi10 from "../assets/images/Chatroom/lobi10.png";
import lobi11 from "../assets/images/Chatroom/lobi11.png";
import lobi12 from "../assets/images/Chatroom/lobi12.png";
import lobi13 from "../assets/images/Chatroom/lobi13.png";
import lobi14 from "../assets/images/Chatroom/lobi14.png";
import lobi15 from "../assets/images/Chatroom/lobi15.png";
import lobi16 from "../assets/images/Chatroom/lobi16.png";
import lobi17 from "../assets/images/Chatroom/lobi17.png";
import lobi18 from "../assets/images/Chatroom/lobi18.png";
import JoyStick from "../assets/images/Chatroom/JoyStick.png";
import Imgboyy1 from "../assets/images/Chatroom/imgboyy1.png";
import LiveChatroom from "../assets/images/Chatroom/liveChatroom.svg";
import InsertRow from "../assets/images/Tableimg/InsertRow.png";
import Imgeone from "../assets/images/GoogleMeet/Imgeone.png";
import mssgg from "../assets/images/GoogleMeet/mssgg.png";
import Settings from "../assets/images/Settings/Settings.svg";

import Mailbox from "../assets/images/Email/Mailbox.svg";
import PaperPlane from "../assets/images/Email/PaperPlane.svg";
import StarFilled from "../assets/images/Email/StarFilled.svg";
import TrashCan from "../assets/images/Email/TrashCan.svg";
import Draft from "../assets/images/Email/Draft.svg";
import Outbox from "../assets/images/Email/Outbox.svg";
import Spam from "../assets/images/Email/Spam.svg";
import purchase from "../assets/images/Email/purchase.svg";
import questions from "../assets/images/Email/questions.svg";
import receipt from "../assets/images/Email/receipt.svg";
import Tracking from "../assets/images/Email/Tracking.svg";
import Save from "../assets/images/Email/Save.svg";
import order from "../assets/images/Email/order.svg";
import Schedulemeeting from "../assets/images/Email/Schedulemeeting.svg";
import Chats from "../assets/images/Email/Chats.svg";
import Newmeeting from "../assets/images/Email/Newmeeting.svg";
import Joinmeeting from "../assets/images/Email/Joinmeeting.svg";
import Spamm from "../assets/images/Email/Spamm.png";
import loginpics from "../assets/images/dashboard/loginpics.svg";
import loginbackground from "../assets/images/dashboard/loginbackground.png";
import Commentspic from "../assets/images/Email/Commentspic.png";
import commentspic2 from "../assets/images/Email/commentspic2.png";
import Frame from "../assets/images/Auth/Frame.png";
import Arrowright from "../assets/images/Email/Arrowright.svg";
import Arrowleft from "../assets/images/Email/Arrowleft.svg";
import Nodata from "../assets/images/Email/Nodata.jpg";
import img_one from "../assets/images/Form/img_one.png";
import img_two from "../assets/images/Form/img_two.png";
import img_three from "../assets/images/Form/img_three.png";
import img_four from "../assets/images/Form/img_four.png";
import img_five from "../assets/images/Form/img_five.png";
import computer from "../assets/images/dashboard/computer.svg";
import Foodbar from "../assets/images/Food/Food Bar.png";
import Leatherbag from "../assets/images/Form/Leatherbag.png";
import Activelike from "../assets/images/filterationForm Img/active_like.png";
import ActiveDislike from "../assets/images/filterationForm Img/active_dislike.png";
import Liked from "../assets/images/filterationForm Img/Vector (2).png";
import DisLiked from "../assets/images/filterationForm Img/Vector (3).png";
import Star from "../assets/images/filterationForm Img/Star Filled.png";
import Building from "../assets/images/filterationForm Img/Vector (4).png";
import Reportcard1 from "../assets/images/dashboard/Reportcard1.svg";
import Reportcard2 from "../assets/images/dashboard/Reportcard2.svg";
import Reportcard3 from "../assets/images/dashboard/Reportcard3.svg";
import Cafe from "../assets/images/dashboard/Cafe.svg";
import FoodBeef from "../assets/images/dashboard/FoodBeef.svg";
import Foodsalad from "../assets/images/dashboard/Foodsalad.svg";
import Dish1 from "../assets/images/dashboard/Dish1.svg";
import Dish2 from "../assets/images/dashboard/Dish2.svg";
import Dish3 from "../assets/images/dashboard/Dish3.svg";
import Dish4 from "../assets/images/dashboard/Dish4.svg";
import Dish5 from "../assets/images/dashboard/Dish5.svg";
import Newshop from "../assets/images/ShopProfile/newshop.png";
import Integrateshop from "../assets/images/ShopProfile/integrateshop.png";
import payment_one from "../assets/images/Transport/payment_one.png";
import payment_two from "../assets/images/Transport/payment_two.png";
import payment_three from "../assets/images/Transport/payment_three.png";
import payment_four from "../assets/images/Transport/payment_four.png";
import payment_five from "../assets/images/Transport/payment_five.png";
import Connect from "../assets/images/Category/Ok.png";
import Vector3 from "../assets/images/searchicon/Vector3.png";
import live from "../assets/images/icons/live.png";
import report from "../assets/images/icons/report.png";
import suggest from "../assets/images/icons/suggest.png";
import Loc from "../assets/images/Location_img/Loc.png";
import Mapmaker from "../assets/images/icons/Mapmaker.png";
import CASH from "../assets/images/icons/CASH.png";
import p1 from "../assets/images/vicky/p1.jpg";
import p2 from "../assets/images/vicky/p2.jpg";
import p3 from "../assets/images/vicky/p3.jpg";
import p4 from "../assets/images/vicky/p4.jpg";
import p5 from "../assets/images/vicky/p5.jpg";
import p6 from "../assets/images/vicky/p6.jpg";
import p7 from "../assets/images/vicky/p7.jpg";
import p8 from "../assets/images/vicky/p8.jpg";
import p9 from "../assets/images/vicky/p9.jpg";
import p10 from "../assets/images/vicky/p10.jpg";
import p11 from "../assets/images/vicky/p11.jpg";
import p12 from "../assets/images/vicky/p12.jpg";
import Delivery from "../assets/images/icons/delivery.png"
import Menusixteen from "../assets/images/Newdashboard/Public Transportation.png";
import Menutwelve from "../assets/images/Newdashboard/Recycle.png";
import Menuthirteen from "../assets/images/Newdashboard/Farm 2.png";
import Menufourteen from "../assets/images/Newdashboard/menusixteen.png";
import Menufifteen from "../assets/images/Newdashboard/Food Bar.png";
import Bank from "../assets/images/sponserdpic/Bank.png"
import PSO from "../assets/images/sponserdpic/PSO.png"
import Candyland from "../assets/images/sponserdpic/Candyland.png"
import Mughal from "../assets/images/sponserdpic/Mughal.png"
import Steel from "../assets/images/sponserdpic/Steel.png"
import Volta from "../assets/images/sponserdpic/Volta.png"
import Allied from "../assets/images/sponserdpic/Allied.png"
import noprofile from "../assets/images/vicky/noprofile.png"
import gh from "../assets/images/vicky/gh.png";
import Planmodal from "../assets/images/Planmodal/Planmodal.png"

const images = {
  Dish1,
  Dish2,
  Dish3,
  Dish4,
  Dish5,
  FoodBeef,
  Foodsalad,
  Cafe,
  loginpics,
  Reportcard1,
  Reportcard2,
  Reportcard3,
  Commentspic,
  commentspic2,
  Arrowright,
  Arrowleft,
  loginbackground,
  Mailbox,
  Nodata,
  PaperPlane,
  StarFilled,
  TrashCan,
  Draft,
  Outbox,
  Spam,
  purchase,
  questions,
  receipt,
  Tracking,
  Save,
  order,
  Schedulemeeting,
  Chats,
  Newmeeting,
  Joinmeeting,
  CompanyDash,
  NewItemsDash,
  UsedItemsDash,
  ServicesDash,
  Drag,
  PersonaPropertyDash,
  PersonaPropertyDash,
  BusinessPropertyDash,
  MineralsDash,
  HomeMAdeDash,
  TranportaionDash,
  BluePhone,
  BlueMail,
  buliding,
  dislike,
  Like,
  Reciver,
  Logoone,
  Logotwo,
  Selectezilogo,
  ProfileImg,
  Selecteziimg,
  Bell,
  LogOut,
  Home,
  Upload,
  LeftArrow,
  Add,
  File,
  Profileimg,
  Timelineimg,
  Jobonline,
  Servicesonline,
  Propertyonline,
  Candidtaesonline,
  Minerals,
  Newproducts,
  Transportation,
  Homemade,
  Call,
  Email,
  Chatroom,
  Usedproducts,
  Items,
  StarImg,
  Image1,
  Image2,
  Image3,
  ShopItem,
  Phone,
  Mail,
  Maps,
  ShopProfile,
  Car,
  Update,
  UpdateShop,
  OfferJob,
  CandidatesJob,
  StarsShop,
  Cart,
  Eye,
  Shop,
  Property,
  transport,
  Shop,
  Transportprofile,
  car1,
  Car1,
  Car2,
  Car3,
  Car4,
  Car5,
  Car6,
  Up,
  Down,
  Call2,
  Helpline,
  WorldSearch,
  WorldSearchtwo,
  PostArrow,
  JobWantedDash,
  Menuone,
  Menutwo,
  Menuthree,
  Menufour,
  Menufive,
  Menusix,
  Menuseven,
  CVProfileImg,
  DownLine,
  SideLine,
  HRLine,
  Category1,
  Category2,
  Category3,
  Category4,
  Category5,
  Category6,
  Category7,
  dashboard_1,
  dashboard_2,
  dashboard_3,
  Farming,
  dashboard_5,
  dashboard_6,
  dashboard_7,
  dashboard_8,
  dashboard_9,
  dashboard_10,
  dashboard_11,
  dashboard_12,
  dashboard_14,
  dashboard_d_1,
  dashboard_d_2,
  dashboard_d_3,
  dashboard_d_4,
  dashboard_d_5,
  dashboard_d_6,
  dashboard_d_7,
  dashboard_d_8,
  dashboard_d_9,
  dashboard_d_10,
  dashboard_d_11,
  dashboard_d_12,
  dashboard_d_13,
  dashboard_d_14,
  dashboard_d_16,
  Businessimage,
  Singlebusinessimage,
  ErrorImg,
  Usedvehicleshop,
  Usedvehiclesingle,
  Menueight,
  Menunine,
  Menuten,
  Menueleven,
  Shopadone,
  Shopadtwo,
  Shopadthree,
  BGimgone,
  Timeline,
  Telegram,
  Twitter,
  Whatsapp,
  Link,
  Emailtwo,
  Delete,
  BackArrow,
  Location,
  Hours,
  Return,
  Wallet,
  goback,
  FindClinic,
  AddImage,
  AddImage2,
  Addvideo,
  Alex1,
  Alex2,
  goback2,
  Mainlogo,
  MainAvatar,
  Vector3,
  IronLogo,
  WholeSaler,
  Retailer,
  Factory,
  NewItems,
  UsedItems,
  Homemade,
  GemStone,
  Jobs,
  Candidates,
  SellProperties,
  BuyVehicles,
  Verified,
  TransportServices,
  SingleAdds,
  Services,
  ScrapMaterial,
  Recycled,
  Farming,
  SendInquiry,
  Email,
  ChatRoom,
  HelpLine,
  Bell,
  Out,
  Selectezi,
  Envelop,
  House,
  SingleVehicle,
  goback2,
  Manual,
  goback2,
  CircularAvatar,
  JuliaSideCard,
  plusicon,
  chat,
  impor,

  joinmeet,
  mail_one,
  meet,

  schedule,

  Meting,
  Edit_pen,
  Date_one,
  Time_one,
  Alert_one,
  Emailpic,
  Companypic,
  Rectangle_1,
  Rectangle_2,
  Rectangle_3,
  Rectangle_5,
  Vectorone,
  Chatroomimg,
  Chatroomimg8,
  Chatroomimg2,
  Chatroomimg3,
  Chatroomimg4,
  Chatroomimg5,
  Chatroomimg6,
  Chatroomimg7,
  ownchatroom1,
  ownchatroom9,
  ownchatroom2,
  ownchatroom3,
  ownchatroom4,
  ownchatroom5,
  ownchatroom6,
  ownchatroom7,
  ownchatroom8,
  Uploadchat,
  livechat1,
  livechat10,
  livechat11,
  livechat12,
  livechat13,
  livechat14,
  livechat15,
  livechat16,
  livechat17,
  livechat18,
  lobi1,
  lobi2,
  lobi3,
  lobi4,
  lobi5,
  lobi6,
  lobi7,
  lobi8,
  lobi9,
  lobi10,
  lobi11,
  lobi12,
  lobi13,
  lobi14,
  lobi15,
  lobi16,
  lobi17,
  lobi18,
  JoyStick,
  Imgboyy1,
  LiveChatroom,
  InsertRow,
  Imgeone,
  mssgg,
  Spamm,
  Settings,
  Frame,
  img_one,
  img_two,
  img_three,
  img_four,
  img_five,
  computer,
  Foodbar,
  Leatherbag,
  ActiveDislike,
  DisLiked,
  Building,
  Star,
  Liked,
  Businessdashboard,
  Integrateshop,
  Newshop,
  payment_one,
  payment_two,
  payment_three,
  payment_four,
  payment_five,
  Connect,
  Homemadetwo,
  Activelike,
  Vector3,
  live,
  report,
  suggest,
  Loc,
  Mapmaker,
  CASH,
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  p8,
  p9,
  p10,
  p11,
  p12,
  Delivery,
  Menusixteen,
  Menutwelve,
  Menuthirteen,
  Menufourteen,
  Menufifteen,
  Bank,
  PSO,
  Candyland,
  Mughal,
  Steel,
  Volta,
  Allied,
  noprofile,
  gh,
  Planmodal

};
export default images;
