// import { useEffect } from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import { useLocation, useNavigate, } from "react-router-dom";
// import { images } from "../../constants/index"
// import Navbarr from '../../components/Navbar/Withnavbar/withnavbar';
// function NoMatch() {
// const navigate=useNavigate()

// useEffect(()=>{
// navigate('/404')
// },[])
//   return (
//     <Container>
//       <Row className="screenHeight">
//         <Col lg="6" >
//           <div className="height_100 d-flex align-items-center">
//             <div>
//               <h1 className='pb-2'>Oops....</h1>
//               <h4 className='pb-2'>Page not found.</h4>
//               <p className='pe-2'>This Page doesn`t exist or was removed!
//                 We suggest you back to home.</p>
//                 <button className="view-button" onClick={()=>navigate("/dashboard")}>Home Page</button>
//             </div>
//           </div>
//         </Col>
//         <Col lg="6" >
//           <div className="height_100 d-flex align-items-center">
//             <img src={images?.ErrorImg} alt="" />
//           </div>
//         </Col>
//       </Row>
//     </Container>
//   );
// }
// export default NoMatch;

import React, { useState, useEffect } from "react";
import axios from "axios";

const NoMatch = () => {
  const [countries, setCountries] = useState([]);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    // Fetch countries from API
    axios
      .get(`https://www.universal-tutorial.com/api/countries`)
      .then((response) => {
        setCountries(response);
      })

      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <h1>Searchable Dropdown</h1>
    </div>
  );
};

export default NoMatch;
