import { useNavigate, useLocation } from "react-router-dom";

const Managebuttons = ({ id, businessurl }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <div className="d-flex justify-content-between">
        {location.pathname === `/rawmaterial-owner-profile/${id}` ||
        location.pathname === `/manufacturer-owner-profile/${id}` ||
        location.pathname === `/Sale-Bussines-Property/${id}` ||
        location.pathname === `/company-profile/${id}` ? (
          ""
        ) : (
          <div>
            <button
              className="btn-primary pt-1 pb-1"
              onClick={() => navigate("/OrderHistory")}
            >
              Order History
            </button>
          </div>
        )}

        <div>
          <button
            className="btn-primary pt-1 pb-1"
            onClick={() => navigate(businessurl)}
          >
            Business Dashboard
          </button>
        </div>
        <div>
          <button
            className="btn-primary pt-1 pb-1"
            onClick={() => navigate("/ShopManage")}
          >
            Give Admin Rights For This Shop
          </button>
        </div>
      </div>
    </>
  );
};

export default Managebuttons;
