import { Container, Row } from "react-bootstrap";
import Usedvehicleform from "../../../components/Used_Vehicle_shop";
import url from "../../../config/axios";
import { useState,useEffect } from "react";
import { SuccessNotify, FailedNotify } from "../../../constants/tostify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";

const Sellsingle = () => {
  const navigate = useNavigate();
  const imageUrl = useSelector((state) => state.imgurl);
  const { name } = useSelector((state) => state.user);
  const [isloading, setIsloading] = useState(false);
  const [usedvehicle, setUsedvehicle] = useState({
    userid: name._id,
    shopid: "",
    modelYear: "",
    model: "",
    make: "",
    version: "",
    registeredIn: "",
    color: "",
    assembly: "",
    city: "",
    cityArea: "",
    engineType: "",
    engineCapactiy: "",
    mileage: "",
    transmission: "",
    price: "",
    contact: "",
    description: "",
    images: "",
    status: false,
  });
  useEffect(() => {
    setUsedvehicle((usedvehicle) => ({
      ...usedvehicle,
      images: imageUrl?.imgurl || "",  
    }));
    // ...
  }, [imageUrl]);

  const PostVehicleData = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("sellvehicle/", usedvehicle)
      .then(async (response) => {
        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Add Posted Successfully");
            navigate(`/show-single-add`);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed To Post Add");
      });
  };
  if (isloading) {
    return (
      <div>
        <Loader heightsetting="screenHeight" />
      </div>
    );
  }
  return (
    <>
      <Container>
        <Row className="screenHeight">
          <Usedvehicleform
            setUsedvehicle={setUsedvehicle}
            PostVehicleData={PostVehicleData}
            butn="Create"
          />
        </Row>
      </Container>
    </>
  );
};

export default Sellsingle;
