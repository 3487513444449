import { Container } from "react-bootstrap";
import { RotatingSquare } from "react-loader-spinner";

const Loader = ({heightsetting}) => {
  return (
    <>
      <Container>
        <div className={`${heightsetting} d-flex justify-content-center align-items-center`}>
          <RotatingSquare
            height="100"
            width="100"
            color="#267DCF"
            ariaLabel="rotating-square-loading"
            strokeWidth="4"
            visible={true}
          />
        </div>
      </Container>
    </>
  );
};

export default Loader;
