import "./ownchatroom.css";
import Helmet from "react-helmet";
import { Ownchatroomapi } from "../../../constants/jsonConstant";
import UserChatroomprofile from "./component/Userprofile";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Badge from "@mui/material/Badge";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

const Ownchatroom = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(Ownchatroomapi);
  function handleSelectChange(event) {
    const selectedOption = event.target.value;
    navigate(selectedOption);
  }
  return (
    <>
      <Helmet>
        <body style="background-color: white" />
      </Helmet>
      <div className="backgroundOwnChatRomm">
        <div className="container">
          <div className="screenHeight">
            <div className="col-lg-11 col-12 mx-auto">
              <div className="row ">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6">
                  <UserChatroomprofile />
                </div>

                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-xs-6 pb-4">
                  <div className="ownchatroomcontainer ">
                    <div className="col-12 d-flex justify-content-end pe-lg-3 pt-3">
                      <Badge badgeContent={4} color="primary">
                        <NotificationsNoneIcon className="text-white" />
                      </Badge>
                    </div>

                    {/* Desktop show */}
                    <div className="hide-desktop-view">
                      <div className="row  pe-2 d-flex justify-content-center ">
                        <div className="col-2 ps-0 pe-0">
                          <button
                            style={{
                              background: "var(--Bg-White)",
                              color: "var(--Secondary-color)",
                            }}
                            type="button"
                            className=" ownchat-btn"
                            onClick={() => navigate("/my-chart")}
                          >
                            My Chats
                          </button>
                        </div>
                        <div className="col-2 col-md-3 text-center ps-0 pe-0">
                          <button
                            type="button"
                            className=" createchatroom-btn"
                            onClick={() => navigate("/create-chart-room")}
                          >
                            Create chat Room
                          </button>
                        </div>
                        <div className="col-2 text-end ps-0 pe-0">
                          <button
                            type="button"
                            className=" ownchat-btn text-center "
                            onClick={() => navigate("/join-lobby")}
                          >
                            Join Lobby
                          </button>
                        </div>
                        <div className="col-2 text-end ps-0 pe-0">
                          <button
                            style={{
                              background: "var(--Bg-White)",
                              color: "var(--Secondary-color)",
                            }}
                            type="button"
                            className=" ownchat-btn text-center "
                            onClick={() => navigate("/live-chart-room")}
                          >
                            Live Video
                          </button>
                        </div>

                        <div className="col-2 text-end ps-0 pe-0">
                          <button
                            style={{
                              background: "var(--Bg-White)",
                              color: "var(--Secondary-color)",
                            }}
                            type="button"
                            className=" ownchat-btn text-center "
                            onClick={() => navigate("/live")}
                          >
                            Single Chat
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Mobile Show */}
                    <div className="hide_dropdown col-10 mx-auto">
                      <select
                        aria-label="Default select example"
                        name="city"
                        className="custom-fix-one form-select"
                        onChange={handleSelectChange}
                      >
                        <option>Select Category</option>
                        <option value="/my-chart">My Chart</option>
                        <option value="/create-chart-room">
                          Create Chatroom
                        </option>
                        <option value="/join-lobby">Join Lobby</option>
                        <option value="/live-chart-room">Live Video</option>
                        <option value="/messenger">Single Chat</option>
                      </select>
                    </div>

                    <div className="group_scroll noscroll ps-lg-2 pe-lg-2 ">
                      <div className="row p-3">
                        {data.map((e) => (
                          <div className="col-xl-3 col-lg-4 col-md-6 col-12 mt-4 d-flex justify-content-center">
                            <div
                              class="card"
                              style={{ width: "11.4 rem", border: "none" }}
                            >
                              <div className="activechatroom pr-1">
                                <span className="dotttt">.</span>
                              </div>

                              <img
                                class="card-img-top"
                                src={e.image}
                                alt="Card image cap"
                              />
                              <div class="card-body p-0 ownchatroombody">
                                <h6 class="card-title text-white text-center mt-2">
                                  {e.groupname}
                                </h6>
                                <div className="d-flex justify-content-center">
                                  <button
                                    type="button"
                                    className=" own-btn mb-2"
                                    onClick={() =>
                                      navigate("/chatroom-streamline")
                                    }
                                  >
                                    Join
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ownchatroom;
