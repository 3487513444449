import { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Url from "../../../../config/axios";
import { useSelector } from "react-redux";
import { getAsset } from "../../../../helpers/helper";
const Rawmaterialcard = ({ id,profileurl,setProfileshowhide }) => {
  const [connectedData, setConnectedData] = useState([]);
  const [topdata, setTopdata] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();
  const { name } = useSelector((state) => state.user);
 
  useEffect(() => {
    // Fetch the connected data when the component is mounted
    const fetchConnectedData = async () => {
      try {
        const response = await Url.get(
          `conection/getAllConnected/?userid=${name._id}&shopid=${id}`
        );
        setTopdata(response?.data?.connectedBT);
        setConnectedData(response?.data?.connectedBT[0]);
      } catch (error) {
        console.error("Error fetching connected data:", error);
      } finally {
        setLoading(false); // Set loading to false after the API call
      }
    };

    fetchConnectedData();
  }, [name._id, id]);

  console.log("sddda++__+_++_", connectedData);
  console.log("yyyyyyyyy", topdata);

  return (
    <div className="scrollable-connect noscroll">
      <Row className="ps-2 pe-2">
        {
        
        topdata?.map((connectedItem) => (
          <Col lg="4" className="">
            <Card className="mt-2 custom-styling-card">
              <Card.Body className="p-2 pt-1 pb-1">
                <div className="row pt-3">
                  <>
                    <Col lg="8">
                      <p className="font_13 text-left mb-0">
                        {connectedItem?.businessType}
                      </p>
                      <p className="font_11 text-left ">
                        {connectedItem?.count} {connectedItem?.businessType}
                      </p>
                    </Col>
                    <Col lg="4">
                      <p
                        className="font_11 text-right cursor_P"
                        style={{ color: "#0353A4", fontWeight: "700" }}
                        onClick={() => setProfileshowhide(true)}
                      >
                       
                        View All
                      </p>
                    </Col>

                    {connectedData?.items.length > 0 ? (
                      connectedData?.items.map((item) => (
                        <div className="col-4 ps-2 pe-2 text-center">
                          <div className="personImgSize w-100 img-fluid ms-auto me-auto d-flex justify-content-center align-items-center">
                            <img
                              src={getAsset(item?.recievershopimage[0])}
                              alt={item?.recievershopname}
                              crossOrigin="anonymous"
                            />
                          </div>
                          <div className="font_exsmall mt-1">
                            <p className="text-center mb-1">
                              {item?.sendershopname}
                            </p>
                            <button className="follow-connt text-center mb-2" onClick={() => navigate(`${profileurl}/${id}`)}>
                              View
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="row text-center mt-5">
                        <h6>No Connection Found</h6>
                      </div>
                    )}
                  </>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Rawmaterialcard;
