import React, { useState, useEffect } from "react";
import { Row, Container, Col } from "react-bootstrap";
import UserItemShop from "../../components/UserShopItem/UserItemShop";
import UserShopProfile from "../../components/UserShopProfile/UserShopProfile";
import { useNavigate, useParams } from "react-router-dom";
import { FailedNotify, SuccessNotify } from "../../constants/tostify";
import BackArrow from "../../components/backArrow";
import { useSelector } from "react-redux";
import url from "../../config/axios";
const RetailerItem = () => {
  const { shopid } = useParams();
  const navigate = useNavigate();
  const { name } = useSelector((state) => state.user);

  const itemData = useSelector((state) => state.itemdata);
  const newItemdata = itemData?.itemdata;
  const regular = [];
  const specialoffer = [];
  const salespromotion = [];

  // Iterate through ShopItems and categorize them
  newItemdata?.forEach((item) => {
    if (
      (!item.specialoffer || item.specialoffer === "") &&
      (!item.salespromotion || item.salespromotion === "")
    ) {
      regular.push(item);
    } else if (!item.specialoffer || item.specialoffer === "") {
      salespromotion.push(item);
    } else if (!item.salespromotion || item.salespromotion === "") {
      specialoffer.push(item);
    } else {
      specialoffer.push(item);
      salespromotion.push(item);
    }
  });

  console.log("regular", regular);
  console.log("special", specialoffer);
  console.log("sale", salespromotion);

  const [loading, setloading] = useState(false);
  const [lgShowreport, setLgShowreport] = useState(false);
  const [active, setActive] = useState();
  const [shopData, setShopData] = useState();

  const [createShop, setCreateShop] = useState({
    userid: name._id,
    problem: "",
    desciription: "",
    image: [""],
  });

  useEffect(() => {
    url.get(`shop/id?id=${shopid}`).then(async (res) => {
      setShopData(res?.data?.data?.Shop);
    });

    postLikeDislike();
  }, []);

  const [liked, setLiked] = useState({
    userid: name._id,
    vendorid: shopid,
    likedislike: "",
  });

  const postLikeDislike = async (rating) => {

    const likeDislikeValue = rating === "dislike" ? "dislike" : "like";
    const updatedLiked = { ...liked, likedislike: likeDislikeValue };

    setLiked(updatedLiked);
    try {
      const res = await url.put("likedislike/", updatedLiked);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleLikeDislike = (rating) => {

    setActive(rating);
    postLikeDislike(rating);
  };

  const PostShopDatas = (e) => {
    setloading(true);
    e.preventDefault();
    url
      .post("help/?", createShop)
      .then(async (response) => {
        if (response?.data?.success) {
          setLgShowreport(false);
          setloading(false);
          setTimeout(() => {
            SuccessNotify("Form Retailer Shop Created Successfully");
            navigate(`/retailer-userprofile`);
          }, 1000);
        }
      })
      .catch((error) => {
        setloading(false);
        FailedNotify("Failed To Create Shop");
      });
  };



  return (
    <Container>
      <Row>
        <Col lg="4" className="screenHeight">
          <div className="border b-radius height_100">
            <UserShopProfile
              lgShowreport={lgShowreport}
              setLgShowreport={setLgShowreport}
              PostShopDatas={PostShopDatas}
              setCreateShop={setCreateShop}
              shopid={shopid}
              handleLikeDislike={handleLikeDislike}
              active={active}
              shopData={shopData}
            />
          </div>
        </Col>
        <Col lg="8" className="screenHeight">
          <div className="border b-radius">
            <BackArrow back="/buy-retailer" mystyle="ps-2 pt-1" />
            <UserItemShop
              shopid={shopid}
              itemData={itemData}
              regular={regular}
              specialoffer={specialoffer}
              salespromotion={salespromotion}
              newItemdata={newItemdata}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default RetailerItem;
