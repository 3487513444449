// import { createSlice } from '@reduxjs/toolkit';
// import { PURGE } from 'redux-persist/es/constants';
// import storage from 'redux-persist/lib/storage';
// export const imgurlSlice = createSlice({
//     name: 'imgurl',
//     initialState: {
//         imgurl: '',

//     },
//     reducers: {
//         updateUrl: (state, action) => {
//             state.imgurl = action.payload;
//         },
//     },

// });
// export const { updateUrl } = imgurlSlice.actions;
// export default imgurlSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/es/constants';
import storage from 'redux-persist/lib/storage';
export const imgurlSlice = createSlice({
    name: 'imgurl',
    initialState: {
        imgurls: [],  // Changed to an array to store multiple URLs
    },
    reducers: {
        updateUrl: (state, action) => {
            state.imgurls.push(action.payload);  // Adds a new URL to the array
        },
        removeUrl: (state, action) => {
            state.imgurls = state.imgurls.filter(url => url !== action.payload);  // Removes a URL from the array
        },
        clearUrls: (state) => {
            state.imgurls = [];  // Clears all URLs from the array
        },
    },
});
export const { updateUrl, removeUrl, clearUrls } = imgurlSlice.actions;
export default imgurlSlice.reducer;
