import React from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { images } from "../../constants";
import { useState, useEffect } from "react";
import { SuccessNotify, FailedNotify } from "../../constants/tostify";
import url from "../../config/axios";
import "../../pages/suggestionmodal/Style.css";
import {useSelector} from "react-redux";
import { getAsset } from "../../helpers/helper";
const Suggestion = ({
  setOpen,
  setHide,
  suggestsingleid,
  shopData,
  suggestdata,
  setSuggest,
}) => {

  const {name}=useSelector((state)=>state.user);
 
  const [singledata, setSingledata] = useState();
  const [rplydata, setRplydata] = useState({
    message: "",
  });


  const Rply = () => {
    
    url
      .put(`suggestion/reply/suggestionId?suggestionId=${singledata?._id}`, {
        reply: [
          {
            userid: name?._id,
            role: "Shop Owner",
            message: rplydata?.message,
            imageUrl:shopData?.image
          },
        ],
        
      })

      .then((res) => {
        if (res.data.success) {
          SuccessNotify("Reply successfully ");
        }
      })
      .catch((e) => {
        FailedNotify("Failed to  Reply");
      });
  };

  useEffect(() => {
    url
      .get(
        `suggestion/getShopSingleSuggestion/${suggestsingleid}/${shopData?.shopid}`
      )
      .then(async (res) => {
        setSingledata(res?.data?.data[0]);
      });
  }, []);
  const handleChange = (e) => {
    setRplydata((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
 
  return (
    <div>
      <Container>
        <div>
          <div>
            <Modal.Header
              className="suggst-btn"
              closeButton
              onClick={() => {
                setSuggest(0);
                setHide(true);
              }}
            >
              <Modal.Title>Suggestions</Modal.Title>
            </Modal.Header>
            <hr />
            <Modal.Dialog>
              <Modal.Body className="d-flex">
                <div>
                  <img className="picture" src={getAsset(singledata?.imageUrl)} crossOrigin="anonymous" alt="" />
                </div>
                <div>
                  <p className="mb-0">{singledata?.username}</p>
                  <p className="mb-0">{singledata?.role}</p>
                </div>
              </Modal.Body>
              <div>
                <p className="mb-0 font_13">Message*</p>
                <p className="mb-0 font-sm-15">{singledata?.message}</p>
              </div>
              <div>
                <p className="mb-0 font_13">Reply*</p>
                <textarea
                  className="text-area mb-0"
                  name="message"
                  onChange={(e) => handleChange(e)}
                ></textarea>
              </div>
              <Modal.Footer>
                <Button className="mt-2" variant="primary" onClick={Rply}>
                  Send
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default Suggestion;
