import { Col, Row } from "react-bootstrap";
import "./UsersideTransport.css";
import BackArrow from "../../components/backArrow";
import { images } from "../../constants";
import { useState, useEffect } from "react";
import Rating from "@mui/material/Rating";
import { Vehicaldetail, RatingModel } from "../../pages/index";
import Typography from "@mui/material/Typography";
import ReplyIcon from "@mui/icons-material/Reply";
import GradeIcon from "@mui/icons-material/Grade";
import MessageIcon from "@mui/icons-material/Message";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import {
  usertransportbtn,
  Transportdetail,
} from "../../constants/jsonConstant";
import Modal from "react-bootstrap/Modal";
import Reportbox from "../../components/Reportbox";
import Reportselectezibox from "../../components/Report to selectezi";
import CandidatesBox from "../../components/candidatesBox";
import Qrmodal from "../../components/QRmodal/Qrmodal";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import CallIcon from "@mui/icons-material/Call";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ErrorIcon from "@mui/icons-material/Error";
import Connectmodal from "../../components/Connect_modal";
import Suggestionbox from "../../components/Suggestionbox";
import QRCode from "qrcode.react";
import { useSelector } from "react-redux";
import { SuccessNotify, FailedNotify } from "../../constants/tostify";
import url from "../../config/axios";
import { getAsset } from "../../helpers/helper";
import { Form, FormControl, Dropdown } from "react-bootstrap";
import Desmodal from "../../components/Des_Modal";

const UserTransport = ({ shopData, itemshow, shopid, itemData }) => {
  const [value] = useState(3);
  const [viewmodal, setViewmodal] = useState(0);
  const { name } = useSelector((state) => state.user)
  const [lgShow, setLgShow] = useState(0);
  const [isloading, setIsloading] = useState(false);
  const [showqr, setShowqr] = useState(0);
  const [smShow, setSmShow] = useState(0);
  const [lgShowreport, setLgShowreport] = useState(false);
  const [selectezireport, setSelectezireport] = useState(false);
  const [showoneModal, setShowoneModal] = useState(false);
  const [showmodal, setShowmodal] = useState(false);
  const [showconnect, setShowconnect] = useState(false);
  const [showtransport, setShowtransport] = useState();
  const [desshow, setDesshow] = useState(0);
  const [des, setDes] = useState();
  const [suggestion, setSuggestion] = useState({
    vendorid: "",
    userid: name._id,
    username: name.fullname,
    useremail: name.email,
    role: "",
    imageUrl: "",
    message: "",
  });


  const closemodal = () => setShowmodal(false);

  useEffect(() => {
    url.get(`user/id?id=${name._id}`).then((res) => {

      setSuggestion((prevState) => ({
        ...prevState,
        imageUrl: res?.data?.data?.User?.imageUrl[0],
        vendorid: shopData?.shopid,

      }));
    })
  }, [shopData])
  const handleTabSelect = () => {
    setShowoneModal(true);
  };

  const handleClosedes = () => {
    setDesshow(0);
  }
  useEffect(() => {
    setShowqr(1);
  }, [setShowqr]);
  const handleCloses = () => {
    setShowoneModal(false);
  };
  const Closeconnect = () => {
    setShowconnect(false);
  };
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchItems] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
  ]);

  const handleFocus = () => {
    setShowDropdown(true);
  };

  const handleBlur = () => {
    // Delay the dropdown hide to allow clicks to register on dropdown items
    setTimeout(() => setShowDropdown(false), 100);
  };
  const suggestPost = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post(`/suggestion/`, suggestion)
      .then((res) => {
        SuccessNotify("Suggestion Data is posted successfully");
        setLgShow(0);
        setIsloading(false);
      })
      .catch((err) => {
        setLgShow(0);
        FailedNotify("Failed to  post suggestion Data");
      });
  };

  const filterTransport = (e) => {

    url.get(`tranportvehicle/searchbybrandName?brandName=${e}&businessType=transportShop`).then((res) => {

      setShowtransport(res?.data?.data);

    })
  }
  const suggestHandel = (event) => {
    setSuggestion((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  return (
    <>
      <Desmodal desshow={desshow} handleClosedes={handleClosedes} des={des} />
      <Suggestionbox lgShow={lgShow} setLgShow={setLgShow} suggestPost={suggestPost} suggestHandel={suggestHandel} />
      <Reportbox lgShowreport={lgShowreport} />
      <Reportselectezibox
        selectezireport={selectezireport}
        setSelectezireport={setSelectezireport}
      />
      <RatingModel smShow={smShow} setSmShow={setSmShow} />
      <Connectmodal
        showconnect={showconnect}
        handleCloseconnect={Closeconnect}
      />

      <Reportbox
        lgShowreport={lgShowreport}
        setLgShowreport={setLgShowreport}
      />
      <Reportselectezibox
        selectezireport={selectezireport}
        setSelectezireport={setSelectezireport}
      />
      <Modal
        size="lg mb-0 mt-0"
        show={viewmodal}
        onHide={() => setViewmodal(0)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="p-2"
      >
        <Vehicaldetail singleheight="" />
      </Modal>

      <RatingModel setSmShow={setSmShow} smShow={smShow} />
      {/* <CandidatesBox showoneModal={showoneModal} handleClose={handleCloses} /> */}

      <Col lg="8 mx-auto shop-profile-box ps-3 pe-3">
        <div className="arrow_div">
          <BackArrow back="/find-Transport" />
          <button className="share_btton">
            share
            <ReplyIcon
              style={{
                transform: "rotateY(180deg)",
                color: "#0353A4",
                fontSize: "medium",
              }}
            />
          </button>
        </div>

        <Row className="first_divmain">
          <Col lg="3">
            <div className="img_fix mt-2">
              <img
                src={shopData?.image}
                alt=""
                className="transport_img"
              />
            </div>
          </Col>
          <Col lg="8" className="d-flex flex-column">
            <div className="name_qrset">
              <h2 className="font-bold ">{shopData?.shopname}</h2>
              <Col lg="1" className="mt-3">
                {
                  showqr == 1 && (<QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={`https://selectezi.com/${shopid}`}
                    viewBox={`0 0 256 256`} />)
                }
              </Col>

            </div>
            <p className="mb-1 font-sm-15 spaces">
              <b>SHOP ID :</b>{shopData?.shopid}
            </p>
            <p className="mb-1 font-sm-15 spaces">{shopData?.shopaddress}</p>
            <div className="d-flex">
              <p className="mb-1 font_13 mt-1">
                <Typography component="legend" className="font_13">
                  <b>3.6</b>
                </Typography>
              </p>
              &nbsp;
              <Rating name="read-only" value={value} readOnly />
            </div>
            <p
              className="mb-1 font-sm-15 spaces d-flex align-items-center"
              onClick={() => setSmShow(true)}
              style={{ cursor: "pointer" }}
            >
              <GradeIcon /> &nbsp; Rate this shop
            </p>
            <p
              className="mb-1 font-sm-15 spaces d-flex align-items-center"
              onClick={() => setShowconnect(true)}
              style={{ cursor: "pointer" }}
            >
              <DeviceHubIcon /> &nbsp; Send connection request
            </p>
            <div className="sugetion_btns_fit ">
              <div>
                <p
                  className="mb-1 font-sm-15 spaces d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => setLgShow(true)}
                >
                  <MessageIcon /> &nbsp; Give suggestion to shop owner
                </p>
                <p
                  className="mb-1 font-sm-15 spaces d-flex align-items-center"
                  onClick={() => setLgShowreport(true)}
                  style={{ cursor: "pointer" }}
                >
                  <ErrorIcon /> &nbsp; Report to Shop owner
                </p>
                <p
                  className="mb-1 font-sm-15 spaces d-flex align-items-center"
                  onClick={() => setSelectezireport(true)}
                  style={{ cursor: "pointer" }}
                >
                  <ReportProblemIcon style={{ color: "red" }} /> &nbsp; Report
                  to selectezi
                </p>
              </div>
              <div className="d-flex flex-column">
                {/* <button className="call_bttnset mb-2">
                  <CallIcon />
                  Call
                </button> */}
                <button className="call_bttnset">
                  <ChatBubbleIcon />
                  Message
                </button>
              </div>
            </div>

          </Col>
        </Row>
        <div className="main_relativdiv position-relative">
          <Row>
            <Tabs className="service_TAB ">
              <Tab eventKey="service" title="Services">
                <Col lg="12 mt-2">
                  <Row className="t-scroll noscroll">
                    {console.log("112233",)}
                    <Row className="pe-0">
                      {
                        showtransport && showtransport?.length > 0 ?
                          showtransport?.map((e) => (
                            <>
                              <Col lg="4" className="mt-2">
                                <img
                                  src={e?.images}
                                  alt=""
                                  className="img-fluid w-100"
                                />
                              </Col>

                              <Col lg="8" className="border_2 b-radius mt-2">
                                <Row className="mt-2">
                                  <Col lg="4">
                                    <h4 className="font-bold">{e?.brandName}</h4>
                                  </Col>
                                  <Col lg="5">
                                    <h5>{e?.title}</h5>
                                  </Col>
                                  <Col lg="2">
                                    <button
                                      className="viewshop ms-2"
                                      onClick={() => setViewmodal(e.id)}
                                    >
                                      View
                                    </button>
                                  </Col>
                                </Row>
                                <Row className="mt-3">
                                  <Col lg="6" xl="8">
                                    <p>
                                      <b>Description</b>
                                    </p>
                                    <p className="font-grey font-sm">
                                      {e.description}
                                    </p>

                                  </Col>
                                  <Col lg="6" xl="4" className="loop_starset">
                                    <Typography component="legend">
                                      Rating
                                    </Typography>
                                    <Rating
                                      name="read-only"
                                      value={value}
                                      readOnly
                                    />
                                    <p className="mb-1 font_small"></p>
                                  </Col>
                                </Row>
                              </Col>
                            </>
                          ))
                          : itemData?.itemdata?.map((e) => (
                            <>
                              <Col lg="4" className="mt-2">
                                <div className="img_fixxx">
                                  <img
                                    src={getAsset(e?.images)}
                                    alt=""
                                    className="w-100 transport_imgggg"
                                    crossOrigin="anonymous"
                                  />
                                </div>

                              </Col>
                              <Col lg="8" style={itemData?.itemdata.length == 1 ? { height: "32vh" } : { height: "" }} className="border_2 b-radius mt-2">
                                <Row className="mt-2">
                                  <Col lg="4">
                                    <h4 className="font-bold">{e?.brandName}</h4>
                                  </Col>
                                  <Col lg="5">
                                    <h5>{e?.title}</h5>
                                  </Col>
                                  <Col lg="2">
                                    <button
                                      className="viewshop ms-2"
                                      onClick={() => setViewmodal(e.id)}
                                    >
                                      View
                                    </button>
                                  </Col>
                                </Row>
                                <Row className="mt-3">
                                  <Col lg="6" xl="8">
                                    <p>
                                      <b>Description</b>
                                    </p>
                                    <p className="font-grey font-sm">
                                      {e?.description}
                                      {e?.description.length > 20 && (
                                        <span
                                          onClick={() => {
                                            setDesshow(e?._id);
                                            setDes(e?.description);
                                          }}
                                          className="mb-0 cursor_P font_11 text-dark"
                                          style={{ marginTop: "3px" }}
                                        >
                                          &nbsp;<b>ReadMore...</b>
                                        </span>
                                      )}
                                    </p>

                                  </Col>
                                  <Col lg="6" xl="4" className="loop_starset">
                                    <Typography component="legend">
                                      Rating
                                    </Typography>
                                    <Rating
                                      name="read-only"
                                      value={value}
                                      readOnly
                                    />
                                    <p className="mb-1 font_small"></p>
                                  </Col>
                                </Row >
                              </Col >
                            </>
                          ))
                      }
                    </Row>
                  </Row>
                </Col>
              </Tab>
            </Tabs>

          </Row>

          <div className="position-absolute search-absolute">
            <Form
              className="position-relative"
              onFocus={handleFocus}
              onBlur={handleBlur}
            >
              <FormControl
                type="text"
                placeholder="Search"
                className="mr-sm-2 search-buing-area"
                name="brandName"
                onChange={(e) => filterTransport(e.target.value)}
              />

            </Form>
          </div>
        </div >


      </Col >
    </>
  );
};

export default UserTransport;
