import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { images } from "../../../../constants";
import Form from "react-bootstrap/Form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Deletemodal } from "../../../../pages/index";
import { useNavigate, useParams } from "react-router-dom";
import url from "../../../../config/axios";
import moment from "moment";
import BAckArrow from "../../../../components/backArrow";
import "./viewapplication.css";
import { FailedNotify, SuccessNotify } from "../../../../constants/tostify";
import { useSelector } from "react-redux";
const ViewApplication = () => {
  const navigate = useNavigate();
  const { name } = useSelector((state) => state.user);
  const { id } = useParams();
  const [employeedata, setEmployeedata] = useState();

  const [show, setShow] = useState(0);
  const handleClose = () => setShow(0);
  useEffect(() => {
    url.get(`/employee/id/?id=${id}`).then(async (response) => {
      setEmployeedata(response?.data?.data?.Employee);
    });
  }, []);
  const Deletedata = (e) => {
    e.preventDefault();
    url
      .delete(`employee/?id=${id}`)
      .then(async (response) => {
        if (response?.data?.success) {
          SuccessNotify("Deleted Successfully!");
        }
        setShow(0);
        setTimeout(() => {
          navigate("/create-job-app");
        }, 4000);
      })
      .catch((error) => {
        setShow(0);
        FailedNotify("Failed to Delete !");
      });
  };
  return (
    <Row>
      {show === 1 && (
        <Deletemodal
          shows={show}
          title="CV"
          handleClose={handleClose}
          handledelete={Deletedata}
        />
      )}

      <div className="screenHeight d-flex justify-content-center align-items-center">
        <Col lg={11} md={11} className="p-0 ">
          <Col xl={5} lg={7} md={9} className="Form-Shadow  mx-auto ">
            <div className="arrowback   pe-3 pt-2 pb-1">
              <BAckArrow
                back="/create-job-app"
                black="black"
                mystyle="ps-3 p-1"
              />
            </div>
            <div className="ps-4 pe-2">
              <div>
                <h4 className="fs-4 mb-1 ps-3">Application</h4>
              </div>
              <Form className="ps-3">
                {["radio"].map((type) => (
                  <div key={`inline-${type}`}>
                    <div>
                      <Form.Check
                        checked
                        inline
                        label="Full time"
                        name="group1"
                        type={type}
                        className=" ViewlabelText "
                      />
                    </div>

                    <Form.Check
                      checked
                      inline
                      label="On-Site"
                      name="group1"
                      type={type}
                      className="ViewlabelText"
                    />
                  </div>
                ))}
              </Form>
              <div className="ps-3">
                <h6 className="mb-1 jobviewhead">Salary</h6>

                <p className="ViewlabelText mb-1 ">
                  {employeedata?.salarywant}
                </p>
              </div>
              <div className="ps-3">
                <h6 className="mb-1 pt-1 jobviewhead">Job</h6>
                <p className="ViewlabelText mb-1">{employeedata?.jobtitle}</p>
              </div>
              <div className="ps-3">
                <h6 className="mb-1 pt-1 jobviewhead">Country,State,City</h6>
                <p className="ViewlabelText mb-1">{`${employeedata?.country}, ${employeedata?.state}, ${employeedata?.city}`}</p>
              </div>
              <div className="ps-3">
                <h6 className="mb-1 pt-1 jobviewhead">Job Experience</h6>
                <p className="ViewlabelText mb-1">
                  {employeedata?.jobexperience}
                </p>
              </div>
              <div className="ps-3">
                <h6 className="mb-1 pt-1 jobviewhead">Contact Number</h6>
                <p className="ViewlabelText mb-1">{name.phonenumber}</p>
              </div>
              <div className="ps-3">
                <h6 className="mb-1 pt-1 jobviewhead">Email</h6>
                <p className="ViewlabelText mb-1">{name.email}</p>
              </div>
              <div className="ps-3">
                <h6 className="mb-1 pt-1 jobviewhead">Remaining Time</h6>
                <p className=" ViewlabelText mb-1">
                  {moment(employeedata?.duration).format("DD-MMMM-YYYY")}
                </p>
              </div>
              <div className="ps-3">
                <h6 className="mb-3 pt-1 jobviewhead">Description</h6>
                <p className="ViewlabelText">{employeedata?.jobdescription}</p>
              </div>
              <div>
                <Row className="d-flex  align-items-center pt-3 pb-3">
                  <Col md={6} lg={4}>
                    <div className="d-flex">
                      <div className="pe-2 ps-2">
                        <button
                          className="btn btn-danger pt-1 pb-1"
                          onClick={() => setShow(1)}
                          style={{fontSize:"small"}}
                        >
                          <DeleteIcon
                          style={{fontSize:"large"}}
                            className=" me-1 "
                          />
                          Delete
                        </button>
                      </div>
                    </div>
                  </Col>
                  <Col
                    md={6}
                    lg={7}
                    className="pe-3 d-flex justify-content-end"
                  >
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="pe-2 ">
                        <button className="m-0 btn btn-primary pt-1 pb-1 ">
                          <VisibilityIcon className="iconWhite me-1" />
                          View CV
                        </button>
                      </div>

                      <button
                        className=" m-0 btn btn-primary pt-1 pb-1"
                        onClick={() =>
                          navigate("/update-job-app/" + employeedata?._id)
                        }
                      >
                        <EditIcon className="iconWhite me-1" />
                        Edit
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Col>
      </div>
    </Row>
  );
};
export default ViewApplication;
