import { Row, Col, Container } from "react-bootstrap";
import CVAccordions from "../CVForm/components/CVAccordions";
import AddIcon from "@mui/icons-material/Add";
import "../../pages/CVForm/CVform.css";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import BackArrow from "../../components/backArrow";
import { useLocation } from "react-router-dom";
import CompanyAccordian from "../../components/Company-Accordians/CompanyAccordian";
import ResturantAccordians from "../../components/Resturant-Accordians/ResturantAccordians";
import { useParams } from "react-router-dom";
const CVForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {shopid} = useParams();
  return (
    <Row>
      <Container>
        <form>
          <div className="screenHeight d-flex justify-content-center align-items-center">
            <Col lg={8} md={9} className="Form-Shadow px-3">
              <div className=" p-1">
                <BackArrow back="/SellingSide" />
              </div>
              <Col md={11} lg={11} className="mx-auto px-3 pb-5">
                <div className="d-flex justify-content-center pt-3 ">
                  <div className="d-flex align-content-center gap-3">
                    <SettingsIcon className="iconWhite plus-icon mt-2" />
                    <p className="color-one mt-1 jobviewhead">Settings</p>
                  </div>
                </div>
                <div>
                  {(location.pathname === `/company-setting/${shopid}`) || (location.pathname === `/usedvehicle-setting/${shopid}`) ? (
                    <CompanyAccordian
                      three="Request Name Change for Company"
                      five="Request to Delete Company"
                    />
                  ) : location.pathname === `/resturant-setting/${shopid}` ? (
                    <ResturantAccordians
                      two="Setup Payment Methods"
                      three="Request Name Change for Shop"
                      five="Request to Delete Shop"
                    />
                  ) : (
                    <CVAccordions
                    shopid={shopid}
                      one="Setup Payment Methods"
                      three="Request Name Change for Shop "
                      four="shipping and Delivery"
                      five="Request to Delete Shop"
                    />
                  )}
                </div>
              </Col>
            </Col>
          </div>
        </form>
      </Container>
    </Row>
  );
};
export default CVForm;
