import React from "react";
import Modal from "react-bootstrap/Modal";
import { images } from "../../constants";

const LocationModal = ({ mshow, handleClose }) => {
  return (
    <>
      <Modal show={mshow} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={images.Loc} alt="" width="100%" />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LocationModal;
