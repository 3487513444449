import { Container, Col, Row, Button } from "react-bootstrap";
import BackArrow from "../../../../components/backArrow";
import { images } from "../../../../constants/index";
import Rating from "@mui/material/Rating";
import { useState } from "react";
import Typography from "@mui/material/Typography";
// import CandidateBox from "../../../../components/candidatesBox/index";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./service.css";
import Reportbox from "../../../../components/Reportbox";
import Reportselectezibox from "../../../../components/Report to selectezi";
import Connectmodal from "../../../../components/Connect_modal";
import Suggestionbox from "../../../../components/Suggestionbox";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import ServicesVideo from "../servicesVideo/index";
import ServicesImages from "../servicesImage/index";
import ServicesPortfolio from "../servicesPortfolio/index";
import RatingModel from "../../../../components/ProductsRating";
import { useNavigate } from "react-router-dom";
import PlaceIcon from "@mui/icons-material/Place";
import ReportIcon from "@mui/icons-material/Report";
import StarIcon from "@mui/icons-material/Star";
import ShareIcon from "@mui/icons-material/Share";
import ChatIcon from "@mui/icons-material/Chat";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import Qrmodal from "../../../../components/QRmodal/Qrmodal";
import url from "../../../../config/axios";
import { getAsset } from "../../../../helpers/helper";
import ReplyIcon from "@mui/icons-material/Reply";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { SuccessNotify, FailedNotify } from "../../../../constants/tostify";
const ServicesOnlineBio = ({id,setShow}) => {
  const [qrmodal, setQrmodal] = useState(false);
  const [value] = useState(3);
  const [key, setKey] = useState("home");
  const {name}=useSelector((state)=>state?.user)
  const [shopdata, setShopdata] = useState();
  const [lgShowreport, setLgShowreport] = useState(false);
  const [selectezireport, setSelectezireport] = useState(false);
  const [smShow, setSmShow] = useState(0);
  const [showoneModal, setShowoneModal] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [showconnect, setShowconnect] = useState(false);
  const [lgShow, setLgShow] = useState(0);
const [suggestion, setSuggestion] = useState({
  vendorid: "",
  userid: name?._id,
  username: name?.fullname,
  useremail: name?.email,
  role: "",
  imageUrl: "",
})


  const navigate = useNavigate();

  const handleTabSelect = () => {
    setShowoneModal(true);
  };

  const handleCloses = () => {
    setShowoneModal(false);
  };
  const handleCloseconnect = () => {
    setShowconnect(false);
  };
  const onHide = () => {
    setQrmodal(false);
  };

  useEffect(() => {
    url.get(`service/id/?id=${id}`).then(async (response) => {
      setShopdata(response?.data?.data?.Service);
    });
  }, []);


  useEffect(() => {
    url.get(`user/id?id=${name?._id}`).then((res) => {
    setSuggestion((prevState) => ({
      ...prevState,
      imageUrl: res?.data?.data?.User?.imageUrl,
      vendorid:shopdata?.serviceid
    }))
    })
    }, [shopdata]);
    
    const suggestHandel = (e) => {
      setSuggestion((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    };
    
    
      const suggestPost = (e) => {
        setIsloading(true);
        e.preventDefault();
        url
          .post(`/suggestion/`, suggestion)
          .then((res) => {
            setLgShow(0);
            SuccessNotify("Suggestion Data is posted successfully");
            setIsloading(false);
          })
          .catch((err) => {
            setLgShow(0);
            FailedNotify("Failed to  post suggestion Data");
          });
      };

  return (
    <>
      <Qrmodal show={qrmodal} handleClosemodal={onHide} />
      <Suggestionbox
        lgShow={lgShow}
        setLgShow={setLgShow}
        suggestion={suggestion}
        suggestHandel={suggestHandel}
        suggestPost={suggestPost}
      />
      <Connectmodal
        showconnect={showconnect}
        handleCloseconnect={handleCloseconnect}
      />
      <RatingModel setSmShow={setSmShow} smShow={smShow} />
      <Reportbox
        lgShowreport={lgShowreport}
        setLgShowreport={setLgShowreport}
      />
      <Reportselectezibox
        selectezireport={selectezireport}
        setSelectezireport={setSelectezireport}
      />
      <Container>
        <div className="border b-radius mx-auto servicesHeight">
          <Row>
            <Col lg="6" className="ps-5">
              <BackArrow back="/create-service"  />
            </Col>
            <Col lg="6" className="text-end">
              <button className="share_btton me-5 mt-2" onClick={()=>setShow(1)}>
                share
                <ReplyIcon
                  style={{
                    transform: "rotateY(180deg)",
                    color: "#0353A4",
                    fontSize: "medium",
                  }}
                />
              </button>
            </Col>
          </Row>

          <Row className="p-0 m-0 pt-2 pb-2">
            <Col lg="11" className="mx-auto">
              <Row>
                <Col lg="6" xl="5" className="mt-2">
                  <div className="mt-2">
                    <img
                      alt=""
                      className="Restaurant-img"
                      src={getAsset(shopdata?.images)}
                      crossOrigin="anonymous"
                    />
                  </div>
                </Col>
                <Col lg="6" xl="7" className="mt-2">
                  <Row>
                    <Col lg="6" xl="8" className="pe-0">
                      <h2 className="font-bold">{shopdata?.profileName}</h2>
                      <span className="font-bold font_12">Profile ID :</span>
                      <span className="font_12" style={{ color: "grey" }}>
                      {shopdata?.serviceid}
                      </span>
                      <p className="mb-0 font_12">
                        <PlaceIcon style={{ fontSize: "16px" }} /> {shopdata?.country},
                        {shopdata?.state}, {shopdata?.city}
                      </p>
                      <div className="">
                        {/* <p className="mb-0 font_13">
                          branding,logo design, ui/ux, photoshop,
                        </p> */}
                      </div>
                      <div>
                        <p className="mb-1 font_small w-25 d-flex">
                          <Typography
                            component="legend"
                            className="font_small mt-1"
                          >
                            <b>3.6</b>
                          </Typography>
                          <Rating name="read-only" readOnly />
                        </p>
                      </div>
                      <div className="">
                        <div className="d-flex">
                          <div
                            className="d-flex"
                            style={{ textDecoration: "none" }}
                            onClick={() => setSmShow(true)}
                          >
                            {" "}
                            <StarIcon className="report-icn mt-1 cursor_P" />
                            <p className="font_13 m-0 cursor_P">
                              &nbsp;Rate This Services
                            </p>
                          </div>
                        </div>
                        <div
                          className="d-flex mt-1"
                          onClick={() => setShowconnect(true)}
                        >
                          <ShareIcon className="report-icn " />
                          <p className="font_13 m-0 cursor_P">
                            &nbsp;Send Connection Request
                          </p>
                        </div>

                        <div
                          className="d-flex mt-1"
                          onClick={() => setLgShow(1)}
                        >
                          <ChatIcon className="report-icn " />
                          <p className="font_13 m-0 cursor_P">
                            &nbsp;Give Suggestion To Service Owner
                          </p>
                        </div>

                        <div
                          className="d-flex mt-1"
                          onClick={() => setLgShowreport(true)}
                        >
                          <ReportIcon className="report-icn" />
                          <p className="font_13 m-0 cursor_P">
                            &nbsp;Report To Service Owner
                          </p>
                        </div>

                        <div
                          className="d-flex mt-1"
                          onClick={() => setSelectezireport(true)}
                        >
                          <ReportProblemIcon
                            className="report-icn"
                            style={{ color: "red" }}
                          />
                          <p className="font_13 m-0 cursor_P">
                            &nbsp;Report To Selectezi
                          </p>
                        </div>
                      </div>
                    </Col>

                    <Col lg="6" xl="4" className="d-flex flex-column text-end">
                      <div
                        className="mb-5 pb-3 text-end cursor_P"
                        onClick={() => setQrmodal(true)}
                      >
                        <QrCode2Icon style={{ fontSize: "35px" }} />
                      </div>

                      <div>
                        <Button className=" custom-available-button ps-3 pe-3  pt-2 pb-2 mt-3">
                          <FiberManualRecordIcon
                            fontSize="small"
                            style={{ color: "green" }}
                          />{" "}
                          Available
                        </Button>
                      </div>

                      <div>
                        <Button className="mt-3 custom-button-one ps-4 pe-4 pt-2 pb-2 mt-3 btn btn-primary">
                          <CallIcon fontSize="small" />
                          &nbsp; Call &nbsp;&nbsp;
                        </Button>
                      </div>

                      <div>
                        <Button className="custom-button-one ps-3 pe-3  pt-2 pb-2 mt-3 btn btn-primary mt-3">
                          <EmailIcon fontSize="small" />
                          &nbsp; Message
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Col lg="12">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => {
                    setKey(k);
                  }}
                  className="mb-3 ms-1 font_small"
                >
                  <Tab eventKey="home" title="Service">
                    <Row className="services-scroll noscroll">
                      <ServicesVideo shopdata={shopdata} />
                      {/* <ServicesImages /> */}
                      <ServicesPortfolio shopdata={shopdata} />
                    </Row>
                  </Tab>
                </Tabs>
              </Col>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
export default ServicesOnlineBio;
