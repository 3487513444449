import { Col, Row } from "react-bootstrap";
import HelplineImg from "./components/helplineImage";
import HelplineForm from "./components/helplineForm";
import BackArrow from "../../components/backArrow";
import url from "../../config/axios";
import { useSelector } from "react-redux";
import { FailedNotify, SuccessNotify } from "../../constants/tostify";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import "./helpline.css";
import { publishImage} from "../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
const SelecteziHelpline = () => {
  const navigate = useNavigate();
  const { name } = useSelector((state) => state.user);
  const [displayimg,setDisplayimg]=useState();
  const [helpreport, setHelpReport]=useState();
  const [isloading, setIsloading] = useState(false);

  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setHelpline((helpline) => ({
        ...helpline,
        image: abc
      }));

      setDisplayimg(abc);
    
    }catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });
  const [helpline, setHelpline] = useState({
    
    userid: name._id,
    problem: "",
    desciription: "",
    image: [""]
  });


  useEffect(() => {
    url
      .get("/help/id?")
      .then(async (response) => {
        if (response?.data?.success) {
          setHelpReport(response?.data?.data?.help);
        }
      })
      .catch((error) => {
      });
  }, []);



  const PostHelplineData = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("help/?", helpline)
      .then(async (response) => {
        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Form Retailer Shop Created Successfully");
            navigate(
              `/SellingSide/${response?.data?.data?._id}`
            );
          }, 1000);
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed To Create Shop");
      });
  };
  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }
  return (
    <div className="d-flex justify-content-center">
      <Col md={11} lg={9} sm={12} xs={12} className="helplineback">
        <div className="px-3 pt-1">
          <BackArrow back="/BuyingSide" />
        </div>
        <Row>
          <Col
            md={6}
            lg={6}
            className="d-flex justify-content-center align-items-center"
          >
            <HelplineImg />
          </Col>

          <Col md={6} lg={6}>
            <HelplineForm
            CreateHandler={PostHelplineData}
            setHelpline={setHelpline}
            helpline={helpline}
            PostHelplineData={PostHelplineData}
            helpreport={helpreport}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            displayimg={displayimg}
            setDisplayimg={setDisplayimg}
             />
          </Col>
        </Row>
      </Col>
    </div>
  );
};
export default SelecteziHelpline;
