// // import {
// //   getStorage,
// //   ref,
// //   uploadBytesResumable,
// //   getDownloadURL,
// // } from "firebase/storage";
// // import config from "../config/firebase";
// // import { useDispatch } from "react-redux";
// // import { updateUrl } from "../store/modules/firebaseimg";
// // import { useEffect } from "react";

// // const PersonalImage = ({ image }) => {
// //   const dispatch = useDispatch();

// //   useEffect(() => {
// //     const storage = getStorage(config);
// //     const storageRef = ref(storage, `images/${image.name}`);
// //     const uploadTask = uploadBytesResumable(storageRef, image);

// //     // Register three observers:
// //     // 1. 'state_changed' observer, called any time the state changes
// //     // 2. Error observer, called on failure
// //     // 3. Completion observer, called on successful completion
// //     uploadTask.on(
// //       "state_changed",
// //       (snapshot) => {
// //         // Observe state change events such as progress, pause, and resume
// //         // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
// //         const progress =
// //           (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
// //         console.log("Upload is " + progress + "% done");
// //         switch (snapshot.state) {
// //           case "paused":
// //             console.log("Upload is paused");
// //             break;
// //           case "running":
// //             console.log("Upload is running");
// //             break;
// //         }
// //       },
// //       (error) => {
// //         // Handle unsuccessful uploads
// //         console.log(error);
// //       },
// //       () => {
// //         // Handle successful uploads on complete
// //         // For instance, get the download URL: https://firebasestorage.googleapis.com/...
// //         getDownloadURL(uploadTask.snapshot.ref)
// //           .then((downloadURL) => {
// //             // localStorage.setItem('img_url', );
// //             dispatch(updateUrl(downloadURL));

// //             console.log("File available at", downloadURL);
// //             // Update the component state or prop with the new image URL
// //             // Example:
// //             // setMyImageURL(downloadURL);
// //           })
// //           .catch((error) => {
// //             // Handle any errors that occur while retrieving the download URL
// //             console.log(error);
// //           });
// //       }
// //     );
// //   }, []);
// // };

// // export default PersonalImage;


import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import config from "../config/firebase";
import { useDispatch } from "react-redux";
import { updateUrl } from "../store/modules/firebaseimg";
import { useEffect } from "react";

const PersonalImage = ({ image }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const storage = getStorage(config);
    const storageRef = ref(storage, `images/${image.name}`);
    const uploadTask = uploadBytesResumable(storageRef, image);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        console.log(error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            dispatch(updateUrl(downloadURL));  // Use updateUrl to add the URL to the array in the Redux store
            console.log("File available at", downloadURL);
          })
          .catch((error) => {
            // Handle any errors that occur while retrieving the download URL
            console.log(error);
          });
      }
    );
  }, [image, dispatch]);  // Added image and dispatch to dependency array
};

export default PersonalImage;
