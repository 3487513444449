import { Col, Row } from "react-bootstrap";
import backArrow from "../../../../components/backArrow";
import Shops from "../../../../components/Multipleshopprofile/Components/Shops/Shops";

const Displayshops = ({
  url,
  companyData,
  showdata,
  newitemraw,
  newitemfinish,
  newitemmanufacture,
  newitemretailer,
  seconditem,
  homemaderaw,
  newitemwhole,
  homemadefinish,
  homemademanufacturer,
  homemadewhole,
  homemaderetailer,
  mineralraw,
  mineralfinish,
  mineralmanufacture,
  mineralwhole,
  mineralretailer,
  serviceit,
  servicetraining,
  consultingservice,
  marketingservice,
  constructionservice,
  wasteservices,
  legalservice,
  healthservice,
  securityservice,
  travelservices,
  researchservice,
  financeservices,
  deliveryservices,
  childcareservices,
  personalservices,
  setShowdata,
  Newitemdataraw,
}) => {
  return (
    <>
      <Col lg="8" className="screenHeight b-radius border_2 ms-3">
        <Row>
          <Col lg="4" md="4" sm="4" xs="3">
            <div className="ps-4 pt-4 pb-2">
              <backArrow back="/SellingSide" />
            </div>
          </Col>
          <Col lg="8" md="8" sm="8" xs="9">
            <div>
              <h5 className="font-primary mt-4">Manage Your Shops Here</h5>
            </div>
          </Col>
        </Row>

        <Shops
          Newitemdataraw={Newitemdataraw}
          setShowdata={setShowdata}
          url={url}
          shopadata={companyData}
          link="/company-profile"
          data={showdata}
          newitemraw={newitemraw}
          newitemfinish={newitemfinish}
          newitemmanufacture={newitemmanufacture}
          newitemwhole={newitemwhole}
          newitemretailer={newitemretailer}
          seconditem={seconditem}
          homemaderaw={homemaderaw}
          homemadefinish={homemadefinish}
          homemademanufacturer={homemademanufacturer}
          homemadewhole={homemadewhole}
          homemaderetailer={homemaderetailer}
          mineralraw={mineralraw}
          mineralfinish={mineralfinish}
          mineralmanufacture={mineralmanufacture}
          mineralwhole={mineralwhole}
          mineralretailer={mineralretailer}
          serviceit={serviceit}
          servicetraining={servicetraining}
          consultingservice={consultingservice}
          marketingservice={marketingservice}
          constructionservice={constructionservice}
          wasteservices={wasteservices}
          legalservice={legalservice}
          healthservice={healthservice}
          securityservice={securityservice}
          travelservices={travelservices}
          researchservice={researchservice}
          financeservices={financeservices}
          deliveryservices={deliveryservices}
          childcareservices={childcareservices}
          personalservices={personalservices}
        />
      </Col>
    </>
  );
};

export default Displayshops;
