import { Container, Row, Col, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import url from "../../config/axios";
import { SuccessNotify, FailedNotify } from "../../constants/tostify";

const Jazzcashform = ({ setOpenmodal, openmodal, shopid, datavalue }) => {
  const [detail, setDetail] = useState({
    username: "",
    accountNumber: "",
  });

  const inputChange = (e) => {
    setDetail((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    url
      .put(`shop/paymentMethod/shopid?shopid=${shopid}`, {
        paymentMethod: [
          {
            method: datavalue,
            details: {
              jazzCash: {
                username: detail.username,
                accountNumber: detail.accountNumber,
              },
            },
          },
        ],
      })
      .then((res) => {
        if (res.data.success) {
          setOpenmodal(0);
          SuccessNotify(res.data.message);
        }
      })
      .catch((e) => {
        FailedNotify(e.response.data.message);
      });
  };

  return (
    <>
      <Modal
        size="lg"
        show={openmodal}
        onHide={() => setOpenmodal(0)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Enter Your Jazz Cash Details:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <Container>
            <Row>
              <Col md={6} lg={6}>
                <Form.Label className="custm-lble ">User Name</Form.Label>
                <Form.Control
                  placeholder="Name on Account"
                  type="text"
                  name="username"
                  className="labelText"
                  onChange={(e) => inputChange(e)}
                />
              </Col>
              <Col md={6} lg={6}>
                <Form.Label className="custm-lble">Account Number</Form.Label>
                <Form.Control
                  placeholder="Enter Jazz Cash Mobile Number"
                  type="number"
                  name="accountNumber"
                  className="labelText"
                  onChange={(e) => inputChange(e)}
                />
              </Col>
            </Row>

            <Row>
              <div className="d-flex justify-content-end">
                <Col lg="2 mt-3 text-end">
                  <button
                    className="btn-blue-sm ps-0 pe-0"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Submit
                  </button>
                </Col>
              </div>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Jazzcashform;
