import { Row, Col } from "react-bootstrap";
import "./CVimage.css";
import { images } from "../../../../../constants/index";
import ProfileInformationCV from "../profileInformation4";
import CVProfileBio from "../CVProfileBio4";
const CVImage = ({ cvdata }) => {
  return (
   
          <Row>
            <Col md={4} lg={4} xl={3} className="ps-0 pe-0">
              <div>
                <div>
                  <Col md={12} lg={12} className=" p-0">
                    <div className="img-4-Background">
                      <img src={cvdata?.profileInformation?.photo} alt="" className="img-5-Background " />
                    </div>
                  </Col>

                  <div className="profilescroll noscroll ps-4 ms-2">
                    <ProfileInformationCV cvdata={cvdata} />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={8} lg={8} xl={9} className="pe-0 ps-0">
              <CVProfileBio cvdata={cvdata} />
            </Col>
          </Row>
   
  );
};
export default CVImage;
