import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./style.css";

const RemoveModal = ({ closeHandler, isShown, handleRemoveClick, }) => {
  return (
    <div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isShown} // Update isShown to show
        onHide={closeHandler}
        animation={false}
      >
        <Modal.Header
          className=" cv-modal-head border-0 p-2 text-white"
          closeButton
        >
          <Modal.Title className="fs-5 "></Modal.Title>
        </Modal.Header>
        <div className="cv-modaal-main">
          <p className="fs-2 pb-4 text-center w-45 fw-bold">
            Are you sure you want to remove user.
          </p>
          <div className="w-50 pb-3 d-flex justify-content-between">
            <button className="cv-modal-btn1" onClick={closeHandler}>
              Cancel
            </button>
            <button className="cv-modal-btn2" onClick={handleRemoveClick}>
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RemoveModal;
