import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import AttachFileSharpIcon from '@mui/icons-material/AttachFileSharp';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import TextRotationNoneOutlinedIcon from '@mui/icons-material/TextRotationNoneOutlined';
import './style.css'



const ChatingInputField = ({setHidebtn,setDisplayChild}) => {
    
const Showhandler=()=>{
    setHidebtn(1);
    setDisplayChild(false)
}
    return (
        <div>

                <div>
                    <Container className='ps-0 pe-0'>
                    <div className="d-flex flex-column ">

                        <textarea                            
                            placeholder="Write here...."
                            className=" noscroll chating-input-area"
                        />

                        <Row>
                            <Col lg={10} md={10} sm={9} xs={9}>
                                <TextRotationNoneOutlinedIcon className='ms-0 me-1' sx={{width:'25px', height:'25px'}} />

                                <AttachFileSharpIcon sx={{width:'25px', height:'25px'}} />

                                <ImageOutlinedIcon sx={{width:'25px', height:'25px'}} />
                            </Col>
                            <Col className='d-flex justify-content-center' lg={2} md={2} sm={3} xs={3}>
                                <Button variant="primary" onClick={Showhandler}>Send</Button>
                            </Col>

                        </Row>
                    </div>
                    </Container>
                </div>

           

        </div>
    )
}

export default ChatingInputField;
