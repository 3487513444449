import "../Auth.css";
import Sidebar from "../../Auth/components/sidebar/sidebar";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
///1//
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import LocationOnIcon from "@mui/icons-material/LocationOn";

////////
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import url from "../../../config/axios";
import { Button } from "react-bootstrap";
import { SuccessNotify } from "../../../constants/tostify";
import { FailedNotify } from "../../../constants/tostify";
import { useDispatch } from "react-redux";
import Loader from "../../../components/Loader";
import Modalcondition from "../components/modaltermecondition";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { InputGroup } from "react-bootstrap";
import MarqueePeople from "../../../components/marqueeslider/Marqueeslider";

const Signup = () => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedstateone, setSelectedstateone] = useState("");
  const [searchTermone, setSearchTermone] = useState("");
  const [showPassword, setShowPassword] = useState("password");
  const [changeicon, setChangeicon] = useState(1);
  const [selectedcity, setSelectedcity] = useState("");
  const [searchTermtwo, setSearchTermtwo] = useState("");
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const location = useLocation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle the country selection
  const handleCountryChange = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
  };

  const handleSearchChangestate = (event) => {
    setSearchTermone(event.target.value);
  };

  const handleSearchChangecity = (event) => {
    setSearchTermtwo(event.target.value);
  };

  // Function to handle the state selection
  const handleCountryChangestate = (event) => {
    const { value } = event.target;
    setSelectedstateone(value);
  };

  // Function to handle the city selection
  const handleCountryChangecity = (event) => {
    const { value } = event.target;
    setSelectedcity(value);
  };

  const [hidefield, setHidefield] = useState(1);
  const [hidestate, setHidestate] = useState(1);
  const [hidecity, setHidecity] = useState(1);

  const [countrydata, SetCountrydata] = useState([]);
  const [getcountry] = useState();
  const [getState, setState] = useState([]);
  const [selectedState] = useState();
  const [getcity, setGetcity] = useState([]);
  const [Open, setOpen] = useState(getcountry);
  const [city, setCity] = useState(selectedState);
  const [isloading, setIsloading] = useState(false);
  const [userData, setUserData] = useState({
    fullname: "",
    email: "",
    password: "",
    country: "",
    state: "",
    city: "",
    dob: "",
    gender: "",
    imageUrl: "",
    coverUrl: "",
    phonenumber: "",
    description: {
      text: "Add your description",
      privacy: "private",
    },
    contactNumber: {
      text: "123321312",
      privacy: "public",
    },
    address: {
      text: "Abbottabad",
      privacy: "friends",
    },
    bio: {
      text: "General Manager at xyz",
      privacy: "public",
    },

    education: [
      {
        text: "Studied at oxford",
        privacy: "private",
        degree: "primary",
      },

      {
        text: "Higher education at xyx",
        privacy: "public",
        degree: "higher",
      },
    ],
    isVerified: false,
    status: true,
  });

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("invalid email address")
      .required("Email is required*"),
    country: Yup.string().required("Country is required*"),
    state: Yup.string().required("State is required*"),
    city: Yup.string().required("City is required*"),
    dob: Yup.string().required("date of birth is required*"),
    gender: Yup.string().required("Select gender*"),
    acceptTerms: Yup.bool().oneOf(
      [true],
      "Accept Terms & Conditions is required*"
    ),

    password: Yup.string()
      .required("Password Required*")
      .min(8, "Must be 8 characters or more"),

    phonenumber: Yup.string()
      .required("Number Required*")
      .matches(phoneRegExp, "Phone number is not valid"),

    fullname: Yup.string()
      .min(3, "minimun 3 characters are required")
      .max(25, "maximum 25 characters are required")
      .required("name is required*"),
  });
  ////////
  const userDatahandler = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("/user", userData)
      .then(async (response) => {
        localStorage.setItem("userid", JSON.stringify(response.data.data._id));
        localStorage.setItem("user", JSON.stringify(response.data.data.email));
        if (response.data.success) {
          setIsloading(false);
          SuccessNotify("Registered Successfully!");
          setTimeout(() => {
            navigate("/verify-code/" + response.data.data.email);
          }, 1000);
        }
      })
      .catch((e) => {
        setIsloading(false);
        FailedNotify(e.response.data.message);
      });
  };

  const inputChange = (event) => {
    setUserData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => SetCountrydata(res.data))

      .catch((error) => { });
  }, []);

  const country = [...new Set(countrydata.map((item) => item.country))];

  country.sort();

  const handleCountry = (e) => {
    let states = countrydata.filter(
      (state) => state.country === e.target.value
    );
    states = [...new Set(states.map((item) => item.subcountry))];
    states.sort();
    setState(states);
  };

  const handleState = (e) => {
    let cities = countrydata.filter(
      (city) => city.subcountry === e.target.value
    );

    setGetcity(cities);
  };

  if (isloading) {
    return (
      <div>
        <Loader heightsetting="screenHeight" />
      </div>
    );
  }
  const Selectezi = "Selectezi"
  return (
    <>
      <Modalcondition handleClose={handleClose} show={show} />
      <Container fluid>
   
        <Row className="sponsersettingrelative">
          <Col lg="2" md="2" sm="12" className="p-lg-5 ">
            {/* <Sidebar /> */}
            <div>
              <h4 className="font-weight-bold selectezilogo">{Selectezi.toUpperCase()}</h4>
            </div>
          </Col>
          <Col lg="6" md="7" sm="12" className=" mx-auto ">
            <div className="center-data">
              <div className="Signup ">
                <h3 className="mb-2  font-bold mt-lg-4">Sign Up</h3>
                <p className=" login_font">
                  Sign up to create your profile to establish your business Shop
                  to join the business network.where you can engage your
                  business with thousands of new clients along your area.
                </p>
              </div>
              <Formik
                initialValues={{
                  fullname: "",
                  email: "",
                  password: "",
                  phonenumber: "",
                  country: "",
                  state: "",
                  city: "",
                  dob: "",
                  gender: "",
                  acceptTerms: false,
                }}
                // validateOnChange={false}
                validateOnBlur={false}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  // same shape as initial values
                }}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  errors,
                  touched,
                  isValid,
                  dirty,
                }) => (
                  <Form
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <div className="row">
                      <div className="col-md-6 mt-2">
                        <label
                          htmlFor="inputEmail4"
                          className="form-label labelSize"
                        >
                          Full Name*
                        </label>
                        <Field
                          type="text"
                          className="form-control  box-height inputSizing "
                          placeholder="Full Name"
                          id="inputEmail4"
                          name="fullname"
                          onChange={(e) => {
                            inputChange(e);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.fullname && touched.fullname ? (
                          <div>
                            <p className="error_text_color">
                              {errors.fullname}
                            </p>
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-6 mt-2">
                        <label
                          htmlFor="inputPassword4"
                          placeholder="Email address"
                          className="form-label labelSize "
                        >
                          Email address*
                        </label>
                        <Field
                          type="text"
                          name="email"
                          className="form-control  box-height inputSizing"
                          placeholder="Email address"
                          id="inputPassword4"
                          onChange={(e) => {
                            inputChange(e);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email ? (
                          <div>
                            <p className="error_text_color">{errors.email}</p>
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-6 mt-2">
                        <label
                          htmlFor="inputPassword4"
                          placeholder="First name"
                          className="form-label labelSize"
                        >
                          Password*
                        </label>
                        <InputGroup style={{ flexWrap: "nowrap" }} className="form-control  box-height inputSizing">
                          <Field
                            name="password"
                            type={showPassword}
                            className="border-0 password-width-signup"
                            placeholder="Password"
                            onChange={(e) => {
                              inputChange(e);
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          />

                          {changeicon === 1 && (
                            <VisibilityIcon
                              className="cursor_P"
                              onClick={() => {
                                setShowPassword("text");
                                setChangeicon(2);
                              }}
                            />
                          )}
                          {changeicon === 2 && (
                            <VisibilityOffIcon
                              className="cursor_P"
                              onClick={() => {
                                setShowPassword("password");
                                setChangeicon(1);
                              }}
                            />
                          )}
                        </InputGroup>
                        {errors.password && touched.password ? (
                          <div>
                            <p className="error_text_color">
                              {errors.password}
                            </p>
                          </div>
                        ) : null}
                      </div>

                      <div className="col-md-6 mt-2">
                        <label
                          htmlFor="inputPassword4"
                          placeholder="First name"
                          className="form-label labelSize"
                        >
                          Phone Number* 
                        </label>

                        <Field
                          type=""
                          className="form-control  box-height inputSizing"
                          placeholder="Enter number"
                          name="phonenumber"
                          onChange={(e) => {
                            inputChange(e);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        />

                        {errors.phonenumber && touched.phonenumber ? (
                          <div>
                            <p className="error_text_color">
                              {errors.phonenumber}
                            </p>
                          </div>
                        ) : null}
                      </div>

                      <div className="col-md-6 mt-2">
                        <label
                          htmlFor="inputPassword4"
                          placeholder="First name"
                          className="form-label labelSize"
                        >
                          Country*
                        </label>
                        <div className="blood-type">
                          <div className="d-flex">
                            <div className="w-100">
                              <input
                                type="search"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Search country here...."
                                className="w-100 search-input"
                                onClick={() => setHidefield(0)}
                              />
                            </div>

                            <div>
                              <span>
                                <SearchIcon className="search-icon-fs" />
                              </span>
                            </div>
                          </div>

                          <select
                            onClick={() => setHidefield(1)}
                            value={selectedCountry}
                            onChange={(e) => {
                              handleCountryChange(e);
                              handleCountry(e);
                              inputChange(e);
                              handleChange(e);
                            }}
                            className="b-radius selectSize w-100"
                            name="country"
                          >
                            {hidefield && <option>Select country</option>}

                            {country
                              .filter((country) =>
                                country
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                              )
                              .map((country, index) => (
                                <option key={country} value={getcountry}>
                                  {country}
                                </option>
                              ))}
                          </select>
                        </div>
                        {errors.country && touched.country ? (
                          <div>
                            <p className="error_text_color">{errors.country}</p>
                          </div>
                        ) : null}
                      </div>

                      <div className="col-md-6 mt-2">
                        <label
                          htmlFor="inputPassword4"
                          placeholder="First name"
                          className="form-label labelSize"
                        >
                          State*
                        </label>
                        <div className="blood-type">
                          <div className="d-flex">
                            <div className="w-100">
                              <input
                                type="search"
                                value={searchTermone}
                                onChange={handleSearchChangestate}
                                placeholder="Search state here...."
                                className="w-100 search-input"
                                onClick={() => setHidestate(0)}
                              />
                            </div>
                            <div>
                              <span>
                                <SearchIcon className="search-icon-fs" />
                              </span>
                            </div>
                          </div>

                          <select
                            onClick={() => setHidestate(1)}
                            className="b-radius selectSize w-100 "
                            value={selectedstateone}
                            aria-label="Default select example "
                            name="state"
                            onChange={(e) => {
                              inputChange(e);

                              handleChange(e);
                              handleState(e);
                              handleCountryChangestate(e);
                            }}
                          >
                            {hidestate && <option>Select state</option>}
                            {/* <option>
                            {Open === undefined
                              ? "Select Country First"
                              : "select state"}
                          </option> */}

                            {getState
                              .filter((country) =>
                                country
                                  .toLowerCase()
                                  .includes(searchTermone.toLowerCase())
                              )
                              .map((country) => (
                                <option key={country} value={selectedState}>
                                  {country}
                                </option>
                              ))}
                          </select>
                        </div>

                        {errors.state && touched.state ? (
                          <div>
                            <p className="error_text_color">{errors.state}</p>
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-6 mt-2">
                        <label
                          htmlFor="inputPassword4"
                          placeholder="First name"
                          className="form-label labelSize"
                        >
                          City
                        </label>
                        <div className="blood-type">
                          <div className="d-flex">
                            <div className="w-100">
                              <input
                                type="search"
                                value={searchTermtwo}
                                onChange={handleSearchChangecity}
                                placeholder="Search city here...."
                                className="w-100 search-input"
                                onClick={() => setHidecity(0)}
                              />
                            </div>
                            <div>
                              <span>
                                <SearchIcon className="search-icon-fs" />
                              </span>
                            </div>
                          </div>

                          <select
                            onClick={() => setHidecity(1)}
                            value={selectedcity}
                            className="b-radius selectSize w-100 "
                            aria-label="Default select example"
                            name="city"
                            onChange={(e) => {
                              inputChange(e);
                              handleChange(e);
                              handleCountryChangecity(e);
                            }}
                          >
                            {/* <option>
                              {city === undefined
                                ? "Select State First"
                                : "Select City"}
                            </option> */}

                            {hidecity && <option>Select city</option>}

                            {getcity
                              .filter((country) =>
                                country.name
                                  .toLowerCase()
                                  .includes(searchTermtwo.toLowerCase())
                              )
                              .map((country) => (
                                <option key={country.name}>
                                  {country.name}
                                </option>
                              ))}
                          </select>
                        </div>

                        {errors.city && touched.city ? (
                          <div>
                            <p className="error_text_color">{errors.city}</p>
                          </div>
                        ) : null}
                      </div>

                      <div className="col-md-6 mt-2">
                        <label
                          htmlFor="inputPassword4"
                          placeholder="First name"
                          className="form-label labelSize"
                        >
                          Date of birth*
                        </label>
                        <Field
                          type="date"
                          className="form-control  box-height inputSizing"
                          placeholder="Date of birth"
                          name="dob"
                          onChange={(e) => {
                            inputChange(e);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.dob && touched.dob ? (
                          <div>
                            <p className="error_text_color">{errors.dob}</p>
                          </div>
                        ) : null}
                      </div>
                      <Row>
                        <div className="col-6">
                          <label
                            htmlFor="inputPassword4"
                            placeholder="First name"
                            className="form-label labelSize pt-3"
                          >
                            Gender
                          </label>
                          <div className="col-md-10  pb-3 ps-0 pe-0">
                            <div className="form-check form-check-inline me-lg-4">
                              <Field
                                className="form-check-input mt-1"
                                type="radio"
                                id="inlineRadio1"
                                value="Male"
                                name="gender"
                                onChange={(e) => {
                                  inputChange(e);
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                              />
                              <label
                                className="form-check-label radio labelSize form-label"
                                htmlFor="inlineRadio1"
                              >
                                Male
                              </label>
                            </div>
                            <div className="form-check form-check-inline me-lg-4">
                              <Field
                                className="form-check-input mt-1"
                                type="radio"
                                id="inlineRadio2"
                                value="Female"
                                name="gender"
                                onChange={(e) => {
                                  inputChange(e);
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                              />
                              <label
                                className="form-check-label radio labelSize form-label"
                                htmlFor="inlineRadio2"
                              >
                                Female
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <Field
                                className="form-check-input mt-1"
                                type="radio"
                                id="inlineRadio3"
                                value="Other"
                                name="gender"
                                onChange={(e) => {
                                  inputChange(e);
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                              />
                              <label
                                className="form-check-label radio form-label labelSize"
                                htmlFor="inlineRadio3"
                              >
                                Other
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-6 pt-3 ">
                        <button
                          style={{ color: "#0353A4" }}
                          className="p-2 location_map_btn "
                        >
                          <LocationOnIcon style={{ color: "#0353A4" }} />
                          Location on Map
                        </button>
                      </div> */}

                        {errors.gender && touched.gender ? (
                          <div>
                            <p className="error_text_color">{errors.gender}</p>
                          </div>
                        ) : null}
                        <div className="form-check d-flex justify-content-center col-6 align-items-center">
                          <Field
                            className="form-check-input"
                            type="checkbox"
                            name="acceptTerms"
                            onChange={(e) => {
                              inputChange(e);
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          />
                          <label
                            className="form-check-label radio form-label labelSize mb-0 mt-2 ms-1"
                            htmlFor="flexCheckDisabled"
                          >
                            <a className="cur-pointer" onClick={handleShow}>
                              I agree to terms & conditions
                            </a>
                          </label>
                        </div>
                        {errors.acceptTerms && touched.acceptTerms ? (
                          <div>
                            <p className="error_text_color">
                              {errors.acceptTerms}
                            </p>
                          </div>
                        ) : null}

                      </Row>
                      <div className="d-grid  col-12">
                        <Button
                          onClick={(e) => userDatahandler(e)}
                          disabled={!(isValid && dirty)}
                          type="submit"
                          className="disable-btn text-white  w-100  p-3"
                          data-title={
                            !(isValid && dirty)
                              ? "Please fill out all Fields to enable button!"
                              : null
                          }
                          style={{ backgroundColor: "#020094" }}
                        >
                          Sign Up
                        </Button>
                      </div>

                      <div className="text-center pt-3 fs-7">
                        <div>
                          <NavLink to="/">
                            Have a Account ?
                            <span style={{ color: "#003559E0" }}>Login</span>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
        {/* <Row className="mt-1" >
          <Col lg={3} md={3} sm={12} className="sponsored_by p-1 ">
            <h3 className="text-sm-center text-xs-center">Sponsored By</h3>
          </Col>
          <Col lg={7} md={7} sm={12} className=" p-0 mt-sm-4"><MarqueePeople /></Col>
          <Col lg={2} md={2} sm={""} className="sponsored_by d-sm-none d-md-block"></Col>
        </Row> */}
      </Container >
    </>
  );
};

export default Signup;
