import { Container,Row,Col } from "react-bootstrap";
import Shopintegrate from "../../components/shop_integrate";


const Propertyintegrate=()=>{

return(<>
<Container>
    <div className="screenHeight">
<Shopintegrate newshop="/business-property-form" url="/property-integrate-form"/>
    </div>
</Container>

</>)

}

export default Propertyintegrate