import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BackArrow from "../../../../components/backArrow";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import url from "../../../../config/axios";
import Loader from "../../../../components/Loader";
import { SuccessNotify } from "../../../../constants/tostify";
const ViewJobsDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isloading, setIsloading] = useState(false);
  const [getjobDetail, setGetJobDetail] = useState();

  useEffect(() => {
    setIsloading(true);
    url.get(`job/id/?id=${id}`).then(async (response) => {
      if (response.data.success) {
        setIsloading(false);
        setGetJobDetail(response?.data?.data?.job);
      }
    });
  }, []);
  if (isloading) {
    return (
      <div>
        <Loader heightsetting="screenHeight" />
      </div>
    );
  }

  const Deletehandler = () => {
    setIsloading(true);
    url
      .delete(`job/?id=${id}`)
      .then(async (res) => {
        if (res.data.success) {
          setIsloading(false);
          SuccessNotify("Job Deleted Successfully");
          navigate(`/company-profile/${getjobDetail.companyId}`);
        }
      })
      .catch(() => {
        setIsloading(false);
      });
  };
  return (
    <Row>
      <div className="screenHeight d-flex justify-content-center align-items-center px-5">
        <Col lg={5} md={10} className="Form-Shadow ">
          <div className="pe-3 pt-2 pb-2">
            <div>
              <BackArrow back={`${"/company-profile/" + id}`} mystyle="ps-4" />
            </div>
          </div>

          <div className="px-5">
            <div>
              <h4 className="fs-5 mb-1">{getjobDetail?.title}</h4>
              <p className="titleText mb-0">{getjobDetail?.companyname}</p>
              <h6 className="mb-2 candidateOccupation pt-1">
                {getjobDetail?.country}
                {getjobDetail?.city}
                {getjobDetail?.state}(on-site)
              </h6>
            </div>
            <div>
              <h5 className="custm-lble mb-1">Job Type</h5>
              <Form>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`}>
                    <div>
                      <Form.Check
                        checked
                        inline
                        label={getjobDetail?.jobtype}
                        name="group1"
                        type={type}
                        className="labelText"
                      />
                    </div>
                  </div>
                ))}
              </Form>
            </div>
            <div>
              <h5 className="custm-lble mb-1">On Site</h5>
              <Form>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`}>
                    <div>
                      <Form.Check
                        checked
                        inline
                        label={getjobDetail?.siteremote}
                        name="group1"
                        type={type}
                        className="labelText mb-0"
                      />
                    </div>
                  </div>
                ))}
              </Form>
            </div>

            <div>
              <Form.Label className="custm-lble mb-1"> Email</Form.Label>

              <p className="labelText mb-1">{getjobDetail?.email}</p>
            </div>
            <div>
              <Form.Label className="custm-lble pt-1 mb-1">
                Contact Number
              </Form.Label>

              <p className="labelText mb-1">{getjobDetail?.contactnumber}</p>
            </div>
            <div>
              <Form.Label className="custm-lble pt-1 mb-1">
                Last Date Of Apply
              </Form.Label>

              <p className="labelText mb-1">{getjobDetail?.duration}</p>
            </div>

            <div>
              <Form.Label className="custm-lble pt-1 mb-1">
                Job Description
              </Form.Label>

              <p className="labelText mb-1">{getjobDetail?.description}</p>
            </div>

            <div className="d-flex justify-content-end py-3 align-items-end">
              <div className="me-2">
                <button className="btn-red-sm" onClick={Deletehandler}>
                  {" "}
                  <DeleteIcon className="iconWhite me-1" /> Delete
                </button>
              </div>
              <div onClick={() => navigate("/update-job/" + id)}>
                <button className="m-0 btn-blue-sm ">
                  <EditIcon className="iconWhite me-1" />
                  Edit
                </button>
              </div>
            </div>
          </div>
        </Col>
      </div>
    </Row>
  );
};
export default ViewJobsDetails;
