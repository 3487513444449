import React from "react";
import { Container, Col, Card, Row } from "react-bootstrap";
import { images } from "../../../../../../constants";
import { Addbox } from "../../../../../../constants/jsonConstant";
import "./style.css";

const Chatleave = () => {
  return (
    <div className="pt-4 p-3">
      <Container>
        <div className="lmt-smt pb-0">
          <p className="text-light ps-2 pt-3">Chat</p>

          <div className="noscroll-al noscroll">
            {Addbox.map((box) => (
              <>
                <div className="dlt-slt ps-3 ms-3 mb-2 pb-1">
                  <div className="d-flex justify-content-between text-light ps-1 pe-2 pt-2">
                    <p className="mb-2">{box.text}</p>
                    <p className="mb-2 ">{box.time}</p>
                  </div>
                  <p className="text-light ps-1 pe-3">{box.description}</p>
                </div>

                <div className="d-flex justify-content-end pe-3 pb-2 amt-qwt">
                  <div className="you-yet ps-3 mt-3 mb-2">
                    <div className="d-flex justify-content-between text-light ps-1 pe-2 pt-2">
                      <p className="mb-2">{box.text}</p>
                      <p className="mb-2">{box.time}</p>
                    </div>
                    <p className="text-light ps-1 pe-3">{box.description} </p>
                  </div>
                </div>
              </>
            ))}

           
          </div>
          <div className="wri-te ps-1 mt-3 ms-2 mb-3 ">
              <div className=" text-light ps-1 pe-2 ">
                <Row>
                  <Col lg={10} md={10} sm={10}>
                    {" "}
                    <textarea
                      placeholder="Write a Message..."
                      className="w-100 textMEssage pt-3"
                    />
                  </Col>
                  <Col lg={2} md={2} sm={2} className=" mt-1">
                    {" "}
                    <div className="you-icn ms-1">
                      <img className="pt-2 mt-1 ps-2" src={images.mssgg} />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
        </div>
      </Container>
    </div>
  );
};

export default Chatleave;
