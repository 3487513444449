import React from 'react'
import { images } from '../../../../../../constants'
import './style.css'

const ImageOne = () => {
  return (
    
    <div className='alt-seet '>
       <div className='position-relative'>
        <div className='position-absolute postn-abtn '>
        <p className='seeter-tlt d-flex justify-content-center pt-1'>Franklen Joe</p>
        </div>
        </div>
        <div className='object-fit-cover nat-roa'>
        <img className='nat-roa' src={images.Imgeone}/>
        </div>

    </div>
  )
}

export default ImageOne