import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import Shops from "../../../components/Multipleshopprofile/Components/Shops/Shops";
import ShopAdds from "../../../components/shopAds/ShopAdds";
import { useState, useEffect } from "react";
import url from "../../../config/axios";
import axios from "axios";
import { images } from "../../../constants";
import ShareModel from "../../../components/Sharemodel";
import ShareOption from "../../../components/Shareoption";
import BackArrow from "../../../components/backArrow";
import SearchIcon from "@mui/icons-material/Search";
import UserItemShop from "../../../components/UserShopItem/UserItemShop";
const BuySingleProperty = () => {
  const [countrydata, SetCountrydata] = useState([]);
  const [getcountry] = useState();
  const [selectedState] = useState();
  const [getState, setState] = useState([]);
  const [getcity, setGetcity] = useState([]);
  const [statetwo, setStatetwo] = useState(getcountry);
  const [city, setCity] = useState(selectedState);
  const [open, setOpen] = useState(getcountry);
  const [data, setData] = useState(0);
  const [shopdata, setShopdata] = useState();

  // const [data1,setData1]=useState([]);
  // filter property api call
  const [selectedSegment, setSelectedSegment] = useState("custom");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedstateone, setSelectedstateone] = useState("");
  const [searchTermone, setSearchTermone] = useState("");

  const [selectedcity, setSelectedcity] = useState("");
  const [searchTermtwo, setSearchTermtwo] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle the country selection
  const handleCountryChange = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
  };

  const handleSearchChangestate = (event) => {
    setSearchTermone(event.target.value);
  };

  const handleSearchChangecity = (event) => {
    setSearchTermtwo(event.target.value);
  };

  // Function to handle the state selection
  const handleCountryChangestate = (event) => {
    const { value } = event.target;
    setSelectedstateone(value);
  };

  // Function to handle the city selection
  const handleCountryChangecity = (event) => {
    const { value } = event.target;
    setSelectedcity(value);
  };
  const [hidefield, setHidefield] = useState(1);
  const [hidestate, setHidestate] = useState(1);
  const [hidecity, setHidecity] = useState(1);

  const handleSegmentChange = (value) => {
    setSelectedSegment(value);
  };

  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => SetCountrydata(res.data))

      .catch((error) => { });
  }, []);

  const country = [...new Set(countrydata.map((item) => item.country))];

  country.sort();

  const handleCountry = (e) => {
    let states = countrydata.filter(
      (state) => state.country === e.target.value
    );
    states = [...new Set(states.map((item) => item.subcountry))];
    states.sort();
    setState(states);
  };

  const handleState = (e) => {
    let cities = countrydata.filter(
      (city) => city.subcountry === e.target.value
    );
    setGetcity(cities);
  };

  const [dataCategory, setDataCategory] = useState({
    categoryid: "",


  })

  const categoryName = dataCategory?.categoryid[1];
  const categoryidone = dataCategory.categoryid[0];

  const GetFIlterData = () => {
    url

      .get(`property/filter?categoryid=${additionalShopCategoryData?.categoryid}&subCategoryid=${additionalShopCategoryData?.subCategoryid}&country=${additionalShopCategoryData?.country}&state=${additionalShopCategoryData?.state}&city=${additionalShopCategoryData?.city}&saletype=${additionalShopCategoryData?.saletype}&min=${additionalShopCategoryData?.min}&max=${additionalShopCategoryData?.max}&filtermethod=propertyads`)

      .then(async (res) => {
        console.log("singel property data", res)
        setShopdata(res?.data?.property)

      });
  };

  useEffect(() => {
    setAdditionalShopCategoryData((additionalShopCategoryData) => ({
      ...additionalShopCategoryData,
      categoryid: categoryidone !== undefined ? categoryidone : "",

    }));
  }, [categoryidone]);

  const [additionalShopCategoryData, setAdditionalShopCategoryData] = useState({
    isShop: false,
    categoryid: "",
    subCategoryid: "",
    shopCategory: "",
    country: "",
    state: "",
    city: "",
    saletype: "",
    min: "",
    max: "",
  })
  console.log("inside state", additionalShopCategoryData)
  const Handleadditioanalshop = (e) => {
    setAdditionalShopCategoryData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value

    }))
  }
  const [showmodal, setShowmodal] = useState(false);
  const handleClosemodal = () => setShowmodal(false);
  const handleShow = () => setShowmodal(true);

  const [showshare, setShowshare] = useState(false);
  const handleCloseshare = () => {
    setShowshare(false);
    setShowmodal(true);
  };
  const handleShowshare = () => setShowshare(true);

  const [shopCategory, setShopCategory] = useState();
  const [shopCategorytwo, setShopCategorytwo] = useState();

  const Handledata = (e) => {
    setDataCategory((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split(".")
    }));
  };
  useEffect(() => {
    url
      .get("/category/type/?type=property&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });
  }, []);

  const Mydata = () => {
    if (dataCategory?.categoryid) {
      url
        .get(
          `/category/type/?type=property&parentCategory=${categoryName}`)

        .then(async (response) => {
          if (response?.data?.success) {
            setShopCategorytwo(response?.data?.data?.Category);
          }
        })
        .catch((error) => {
          console.log("servicesCategories", error);
        });
    }
  };
  return (
    <>
      <ShareOption showshare={showshare} handleCloseshare={handleCloseshare} />
      <ShareModel
        showmodal={showmodal}
        handleClosemodal={handleClosemodal}
        handleShowshare={handleShowshare}
      />
      <Container>
        <div>
          <Row>
            <Col lg="4" className="screenHeight">
              <div className="border b-radius height_100 ">
                <div className="  background-image">
                  <div className="d-flex bg-primary-one pt-2 pb-2 mb-1 ps-3">
                    <div>
                      <img
                        className="w-imgone"
                        src={images.WorldSearchtwo}
                        alt="web"
                      />
                    </div>
                    <div>
                      <h6 className="titleText pt-1 ps-2 mb-0">
                        Search Category, Area & Desired Location
                      </h6>
                    </div>
                  </div>

                  <div className="px-3 pt-2">
                    <div>
                      <Form.Label className="custm-lble">
                        Search by Category
                      </Form.Label>

                      <Form.Select
                        aria-label="Default select example"
                        name="categoryid"
                        onChange={(e) => {
                          Handledata(e);
                        }}
                      >
                        <option disabled selected>
                          Select Category
                        </option>
                        {shopCategory?.map((category) => (
                          <>
                            <option
                              key={category._id}
                              value={`${category?._id}.${category?.name}`}
                            >
                              {category?.name}

                            </option>
                          </>
                        ))}
                      </Form.Select>
                    </div>

                    <div>
                      <Form.Label className="custm-lble">
                        Search By Sub Category
                      </Form.Label>
                      {/* <Form.Select
                        className="custom-fix-one"
                        placeholder="Sub-Category"
                      >
                        <option> select Category</option>
                      </Form.Select> */}
                      <Form.Select
                        aria-label="Default select example"
                        name="subCategoryid"
                        onChange={(e) => {

                          Handleadditioanalshop(e);
                        }}
                        onClick={Mydata}
                      >
                        <option disabled selected>
                          Select Sub-Category
                        </option>

                        {shopCategorytwo?.map((subcategory) => (
                          <>
                            <option key={subcategory._id} value={subcategory?._id}>
                              {subcategory?.name}
                            </option>
                          </>
                        ))}
                      </Form.Select>
                    </div>
                    <div>
                      <Form.Label className="custm-lble">
                        Sale/Rent/Lease
                      </Form.Label>{" "}
                      <br></br>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="saletype"
                          id="inlineRadio1"
                          value="Sale"
                          onChange={(e) => {
                            Handleadditioanalshop(e);
                          }}
                        />
                        <label class="form-check-label" for="inlineRadio1">
                          Sale
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="saletype"
                          id="inlineRadio2"
                          value="Rent"
                          onChange={(e) => {
                            Handleadditioanalshop(e);
                          }}
                        />
                        <label class="form-check-label" for="inlineRadio2">
                          Rent
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="saletype"
                          id="inlineRadio3"
                          value="Lease"
                          onChange={(e) => {
                            Handleadditioanalshop(e);
                          }}
                        />
                        <label class="form-check-label" for="inlineRadio3">
                          Lease
                        </label>
                      </div>{" "}
                    </div>

                    <div>
                      <Form.Label className="custm-lble pt-3">
                        Search by Location
                      </Form.Label>
                    </div>

                    <div className="col-md-12">
                      <label
                        htmlFor="inputPassword4"
                        placeholder="First name"
                        className="form-label labelSize"
                      >
                        Country*
                      </label>
                      <div className="blood-type custom-fix-one">
                        <div className="d-flex">
                          <div className="w-100">
                            <input
                              type="search"
                              value={searchTerm}
                              onChange={handleSearchChange}
                              placeholder="Search country here...."
                              className="w-100 search-input"
                              onClick={() => setHidefield(0)}
                            />
                          </div>
                          <div>
                            <span>
                              <SearchIcon className="search-icon-fs" />
                            </span>
                          </div>
                        </div>

                        <select
                          onClick={() => setHidefield(1)}
                          value={selectedCountry}
                          name="country"
                          onChange={(e) => {
                            handleCountryChange(e);
                            handleCountry(e);
                            Handleadditioanalshop(e);
                          }}
                          className="b-radius selectSize w-100"

                        >
                          {hidefield && <option>Select country</option>}

                          {country
                            .filter((country) =>
                              country
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            )
                            .map((country, index) => (
                              <option key={country} value={getcountry}>
                                {country}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label
                        htmlFor="inputPassword4"
                        placeholder="First name"
                        className="form-label labelSize"
                      >
                        State*
                      </label>
                      <div className="blood-type custom-fix-one">
                        <div className="d-flex">
                          <div className="w-100">
                            <input
                              type="search"
                              value={searchTermone}
                              onChange={handleSearchChangestate}
                              placeholder="Search state here...."
                              className="w-100 search-input"
                              onClick={() => setHidestate(0)}
                            />
                          </div>
                          <div>
                            <span>
                              <SearchIcon className="search-icon-fs" />
                            </span>
                          </div>
                        </div>

                        <select
                          onClick={() => setHidestate(1)}
                          className="b-radius selectSize w-100"
                          value={selectedstateone}
                          aria-label="Default select example "
                          name="state"
                          onChange={(e) => {
                            handleState(e);
                            handleCountryChangestate(e);
                            Handleadditioanalshop(e);
                          }}
                        >
                          {hidestate && <option>Select state</option>}
                          {/* <option>
                            {Open === undefined
                              ? "Select Country First"
                              : "select state"}
                          </option> */}

                          {getState
                            .filter((country) =>
                              country
                                .toLowerCase()
                                .includes(searchTermone.toLowerCase())
                            )
                            .map((country) => (
                              <option key={country} value={selectedState}>
                                {country}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label
                        htmlFor="inputPassword4"
                        placeholder="First name"
                        className="form-label labelSize"
                      >
                        City
                      </label>
                      <div className="blood-type custom-fix-one">
                        <div className="d-flex">
                          <div className="w-100">
                            <input
                              type="search"
                              value={searchTermtwo}
                              onChange={handleSearchChangecity}
                              placeholder="Search city here...."
                              className="w-100 search-input"
                              onClick={() => setHidecity(0)}
                            />
                          </div>
                          <div>
                            <span>
                              <SearchIcon className="search-icon-fs" />
                            </span>
                          </div>
                        </div>

                        <select
                          onClick={() => setHidecity(1)}
                          value={selectedcity}
                          className="b-radius selectSize w-100"
                          aria-label="Default select example"
                          name="city"
                          onChange={(e) => {
                            handleCountryChangecity(e);
                            Handleadditioanalshop(e);
                          }}
                        >
                          {/* <option>
                              {city === undefined
                                ? "Select State First"
                                : "Select City"}
                            </option> */}

                          {hidecity && <option>Select city</option>}

                          {getcity
                            .filter((country) =>
                              country.name
                                .toLowerCase()
                                .includes(searchTermtwo.toLowerCase())
                            )
                            .map((country) => (
                              <option key={country.name}>{country.name}</option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div>
                      <Form.Label className="custm-lble pt-2">
                        Price Filter
                      </Form.Label>
                      <Row className="d-flex">
                        <Col xl={3} lg={4} md={2} sm={2} xs={3} className="d-flex">
                          <Form.Label className="custm-lble pt-1 pe-1">
                            Min
                          </Form.Label>
                          <div className="inputSetting">
                            <input placeholder="" type="number"
                              name="min"
                              onChange={(e) => Handleadditioanalshop(e)}
                            />
                          </div>
                        </Col>
                        <Col xl={3} lg={4} md={2} sm={2} xs={3} className="d-flex">
                          <Form.Label className="custm-lble pt-1 pe-1">
                            Max
                          </Form.Label>
                          <div className="inputSetting">
                            <input placeholder="" type="number"
                              name="max"
                              onChange={(e) => Handleadditioanalshop(e)}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="pt-3 pb-2 d-flex justify-content-center">
                      <button
                        className="btn-lg bg-blue text-white"
                        onClick={GetFIlterData}
                      >
                        Search
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </Col>

            <Col lg="8" className="screenHeight ">
              <div className="border b-radius ps-3 pe-3">
                <BackArrow back="/BuyingSide " mystyle="ps-2 pt-1" />
                <ShopAdds />
                <UserItemShop
                  title="Property"
                  data={data}
                  handleShow={handleShow}
                  shopdata={shopdata}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
export default BuySingleProperty;
