import { Container,Row,Col } from "react-bootstrap";
import Shopintegrate from "../../components/shop_integrate";


const Homemadeintegrate=()=>{

return(<>
<Container>
    <div className="screenHeight">
<Shopintegrate newshop="/Home-Made-Form" url="/homemade-integrate-form"/>
    </div>
</Container>

</>)

}

export default Homemadeintegrate