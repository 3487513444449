import Restaurantform from "../../../components/Resturent_form";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import url from "../../../config/axios";
import { useSelector } from "react-redux";
import { SuccessNotify, FailedNotify } from "../../../constants/tostify";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import { publishImage } from "../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
const ResturentUpdateform = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [displayimg, setDisplayimg] = useState();
  const { name } = useSelector((state) => state.user);
  const [isloading, setIsloading] = useState();

  const [data, setData] = useState({
    category: "",
  });
  const [mygetitem, setMygetitem] = useState();

  useEffect(() => {
    setMygetitem(localStorage.getItem('mycategory'))
  }, [])



  const Handledata = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split("."),
    }));
  };

  const categoryName = data?.category[1];
  const categoryid = data?.category[0];

  const [shopCategory, setShopCategory] = useState();
  const [shopCategorytwo, setShopCategorytwo] = useState();

  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setUpdatedata((updatedata) => ({
        ...updatedata,
        images: abc
      }));

      setDisplayimg(abc);

    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });
  const [updatedata, setUpdatedata] = useState({
    userid: name?._id,
    shopid: "",
    dishname: "",
    category: categoryid,
    subCategory: "",
    price: "",
    preparationTime: "",
    country: "",
    city: "",
    state: "",
    contact: "",
    email: "",
    salesPromotion: "",
    specialOffers: "",
    currency: "",
    duration: "",
    images: [""],
    status: true,
  });
  useEffect(() => {
    url.get(`fooditem/id?id=${id}`).then(async (res) => {
      setUpdatedata(res?.data?.data);
    });
  }, []);

  useEffect(() => {
    setUpdatedata((updatedata) => ({
      ...updatedata,
      category: categoryid,
    }));
  }, [categoryid]);

  useEffect(() => {
    url
      .get("/category/type/?type=foodShop&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });
  }, []);

  const Mydata = () => {
    url
      .get(
        `/category/type/?type=foodShopItem&parentCategory=${categoryName}`
      )
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategorytwo(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });
  };
  const updateDatahandler = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .put(`fooditem/id?_id=${id}`, updatedata)
      .then(async (response) => {
        console.log("data", response);

        if (response?.data?.scuess) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Add Post Successfully");
          }, 1000);

          navigate(
            `/RestaurantAlldata/${response?.data?.data?.foodItem?.shopid}`
          );
        }
      })
      .catch((e) => {
        setIsloading(false);
        FailedNotify("Failed To Post Try Again!");
      });
  };

  useEffect(() => {
    setUpdatedata({
      ...updatedata,
      shopid: updatedata?.shopid,
    });
  }, []);

  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }

  const handlevalue = (e) => {
    setUpdatedata((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <Restaurantform
        btntext="Update"
        updatedata={updatedata}
        id={id}
        handlevalue={handlevalue}
        updateDatahandler={updateDatahandler}
        Mydata={Mydata}
        shopCategory={shopCategory}
        shopCategorytwo={shopCategorytwo}
        Handledata={Handledata}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        displayimg={displayimg}
        setDisplayimg={setDisplayimg}

      />
    </>
  );
};

export default ResturentUpdateform;
