import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import BackArrow from "../../backArrow";
import { Form, FormControl, Dropdown } from "react-bootstrap";
import { useState } from "react";
const DashboardButtons = ({ linkurl, textone, texttwo }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showDropdown, setShowDropdown] = useState(false);
  const [searchItems] = useState(["1", "2", "3", "4", "5"]);

  const handleFocus = () => {
    setShowDropdown(true);
  };

  const handleBlur = () => {
    // Delay the dropdown hide to allow clicks to register on dropdown items
    setTimeout(() => setShowDropdown(false), 100);
  };

  return (
    <>
      <Row className="bg-white ms-1 me-1 mt-3 pt-1 pb-1 rounded">
        <Col lg="2" md="2" sm="2" xs="2" className="text-start">
          <BackArrow />
        </Col>
        {/* {location.pathname === "/BuyingSide" ? (
          <Col lg="7" md="5" sm="5" xs="5" >
            <div className="search-absolute ">
              <Form
                className="position-relative"
                onFocus={handleFocus}
                onBlur={handleBlur}
              >
                
                <FormControl
                  type="text"
                  placeholder="Search"
                  className="mr-sm-2 search-buing-area"
                  onChange={() => {}}
                />
                {showDropdown && (
                  <div show className="position-absolute drop-abb">
                    {searchItems.map((item, index) => (
                      <Dropdown.Item key={index}>{item}</Dropdown.Item>
                    ))}
                  </div>
                )}
              </Form>
            </div>
          </Col>
        ) : (
          ""
        )} */}

        <Col lg="10" md="10" sm="10" xs="10" className="text-end">
          <div onClick={() => navigate(linkurl)} className="cursor_P">
            <AutorenewIcon style={{ color: "#3d3bee" }} />{" "}
            <b className="switch_text">{texttwo}</b>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default DashboardButtons;
