import TransportForm from "../../../../components/forms/transportForm";
import { useEffect, useState } from "react";
import url from "../../../../config/axios";
import { SuccessNotify, FailedNotify } from "../../../../constants/tostify";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader"
import { publishImage } from "../../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
const Usedvehicleshopupdate = () => {
  const navigate = useNavigate();
  const { name } = useSelector((state) => state.user);
  const [displayimg, setDisplayimg] = useState();
  const [shopCategory, setShopCategory] = useState();
  const [isloading, setIsloading] = useState(false);
  const { id } = useParams();
  const [selectshop, setSelectshop] = useState({
    shopCategory: ""
  });

  const Shopdatas = (e) => {
    setSelectshop((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split("."),
    }));
  };

  const categoryName = selectshop?.shopCategory[1];
  const categoryid = selectshop?.shopCategory[0];

  localStorage.setItem('mycategory', categoryName);

  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setCreateShop((createShop) => ({
        ...createShop,
        image: abc
      }));

      setDisplayimg(abc);

    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });
  const [createShop, setCreateShop] = useState({
    userid: name?._id,
    shopname: "",
    shopaddress: "",
    shoplicense: "",
    contact: "",
    image: [""],
    shopCategory: categoryid,
    marketPlace: "",
    businessType: "usedVehicleShop",
    description: "",
    status: true,
  });


  useEffect(() => {
    url
      .get("category/type/?type=usedVehicleShop&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("usedCategories", error);
      });
  }, []);
  useEffect(() => {
    setIsloading(true);
    url
      .get(`shop/id?id=${id}`)
      .then(async (response) => {
        if (response.data.success) {
          setIsloading(false);
          setCreateShop(response?.data?.data?.Shop);
        }

      })
      .catch((error) => {
        setIsloading(false);
        console.log("error", error);
      });
  }, []);

  const Updatevehicleshop = (e) => {
    e.preventDefault();
    url
      .put(`shop/id?id=${id}`, createShop)
      .then(async (response) => {
        if (response?.data?.success) {
          setTimeout(() => {
            SuccessNotify("Form Update Successfully");
            navigate("/used-vehicle-profile/" + id);
          }, 1000);
        }
      })
      .catch((error) => {
        FailedNotify("Failed to Update");
      });
  };

  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }
  return (
    <TransportForm
      title="Fill to Update Shop Profile"
      inputtitle="Shop Name"
      butn="update"
      createShop={createShop}
      shopCategory={shopCategory}
      setCreateShop={setCreateShop}
      Updatevehicleshop={Updatevehicleshop}
      link="/usedvehicle-update/"
      id={id}
      getInputProps={getInputProps}
      getRootProps={getRootProps}
      displayimg={displayimg}
      Shopdatas={Shopdatas}
      setDisplayimg={setDisplayimg}
    />
  );
};
export default Usedvehicleshopupdate;
