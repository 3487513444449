
import "../Live_Streamline/livestreaming.css";
import { Helmet } from "react-helmet";
import UserChatroomview from "../../../components/chatroomview";
import Livechatinvite from "../../../components/Livechat_invite";
import { useRef } from "react";
import { useState } from "react";
import Chattingarea from "../../../components/Chatting_area";

const Joinownchatroom = () => {
  const [addMembers, setAddMembers] = useState(1);
  const inputfile = useRef(null);
  const upload_img = () => {
    inputfile.current.click();
  };

  return (
    <div>
      <Helmet>
        <body style="background:#FFFFFF"></body>
      </Helmet>
      <div className="container ">
        <div className="screenHeight col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12 mx-auto">
          <div className="row  pt-2">
            <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12">
              <UserChatroomview setAddMembers={setAddMembers} title="Technology" />
            </div>
            <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12 col-12 mx-auto streamline--container mt-2">
              {(addMembers === 2 && (
                <Livechatinvite
                  setAddMembers={setAddMembers}
                  addMembers={addMembers}
                />
              )) ||
                (addMembers === 1 && <Chattingarea header="Streamline" url="/user-live-chatroom"/>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Joinownchatroom;
