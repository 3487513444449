import { Container, Col, Row } from "react-bootstrap";
import ServicesBio from "./components/servicesBio";
import { useParams } from "react-router-dom";
import ShareModel from "../../components/Sharemodel";
import ShareOption from "../../components/Shareoption";
import { useState } from "react";
const ServicesOnlineProfile = () => {
  const [showshare, setShowshare] = useState(0);
  const [show, setShow] = useState(0);
  const { id } = useParams();
  const handleCloseshare = () => {
    setShowshare(0);
    setShow(1);
  };

  const handleClosemodal = () => {
    setShow(0);
  };

  const handleShowshare = () => {
    setShow(0);
    setShowshare(1);
  };
  return (
    <>
      <ShareOption showshare={showshare} handleCloseshare={handleCloseshare} />
      <ShareModel
        handleClosemodal={handleClosemodal}
        show={show}
        handleShowshare={handleShowshare}
      />
      <Container>
        <Col md={10} lg={9} className="screenHeight mx-auto">
          <Row className="d-flex justify-content-center">
            <Col md={10} lg={10}>
              <ServicesBio id={id} setShow={setShow} />
            </Col>
          </Row>
        </Col>
      </Container>
    </>
  );
};
export default ServicesOnlineProfile;
