import { Container, Row, Col, Card, Button } from "react-bootstrap";
import BackArrow from "../backArrow";
import { images } from "../../constants";
import "./integrate.css";
import { useNavigate, useLocation } from "react-router-dom";
const Shopintegrate = ({ newshop, url }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <div className="center-alignment">
        <Row className="col-10 mx-auto custom-card p-4 bg-white">
          <BackArrow back="/SellingSide" />
          <Col lg="8" className="mx-auto">
            <Row className="d-flex justify-content-center text-center">
              <Col lg="5">
                <div className="custom-card mb-5">
                  <Card.Img variant="top" src={images.Newshop} />
                  <Card.Body className="ps-2 pe-2 pt-3 pb-3">
                    <Card.Text className="custm-lble-2">
                      Create a New
                      {location.pathname === "/service-integrate"
                        ? "Service"
                        : "Shop"}
                      on Selectezi
                    </Card.Text>
                    <div className="d-flex justify-content-center">
                      <Button
                        variant="primary"
                        onClick={() => navigate(newshop)}
                      >
                        Create Now
                      </Button>
                    </div>
                  </Card.Body>
                </div>
              </Col>
              <Col lg="5">
                <div className="custom-card mb-5 text-center">
                  <Card.Img variant="top" src={images.Integrateshop} />
                  <Card.Body className="ps-2 pe-2 pt-3 pb-3">
                    <Card.Text className="custm-lble-2">
                      Integrate Your
                      {location.pathname === "/service-integrate"
                        ? "Service"
                        : "Shop"}
                      With Selectezi
                    </Card.Text>
                    <div className="d-flex justify-content-center">
                      <Button variant="primary" onClick={() => navigate(url)}>
                        Integrate Now
                      </Button>
                    </div>
                  </Card.Body>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Shopintegrate;
