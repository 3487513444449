import { Modal } from "react-bootstrap";
const ProfileDel = ({ shows, handleClose, handledelete }) => {
    return (
        <>
            <Modal
                show={shows}
                animation={true}
                onHide={handleClose}
                className="mt-5 pt-5"
            >
                <Modal.Body className="col-8 mx-auto text-center p-t-3 pb-3">
                    <h5 className="mt-4">Are You Sure You Want to Delete Your Selectezi account</h5>
                    <small> your profile will be deleted with complete data </small>
                </Modal.Body>
                <div className="d-flex justify-content-center pb-4">

                    <button className="cancle-btn" onClick={handledelete}>
                        YES
                    </button>
                    <button className="ms-3 post-btn" onClick={handleClose}>
                        NO
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default ProfileDel;
