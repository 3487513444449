import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import UserItemShop from "../../components/UserShopItem/UserItemShop";
import UserShopProfile from "../../components/UserShopProfile/UserShopProfile";

const NewItemsUserProfile = () => {
  return (
    <>
      <Container>
        <Row>
          <Col lg="4" className="screenHeight">
            <div className="border b-radius height_100">
              <UserShopProfile />
            </div>
          </Col>

          <Col lg="8" className="screenHeight">
            <div className="border b-radius">
              <UserItemShop />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NewItemsUserProfile;
