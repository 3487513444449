import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Col } from "react-bootstrap";
import { images } from "../../../constants";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import BackArrow from "../../backArrow";
import "./transport.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import ClearIcon from "@mui/icons-material/Clear";
import PersonalImage from "../../../helpers/fileUpload";
import url from "../../../config/axios";
import { getAsset } from "../../../helpers/helper";
const TransportForm = ({
  updatehandel,
  butn,
  CreateHandler,
  setCreateShop,
  createShop,
  title,
  backurl,
  UpdateShop,
  link,
  id,
  inputtitle,
  PostShopData,
  shopCategory,
  Updatevehicleshop,
  getInputProps,
  getRootProps,
  displayimg,
  setDisplayimg,
  Shopdatas,
}) => {
  const [selectedImage, setSelectedImage] = useState("");
  const [transportc, setTransportc] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const removeImage = (index) => {
    const updatedImages = [...displayimg];
    updatedImages.splice(index, 1);
    setDisplayimg(updatedImages);
  };

  const ShopHandler = (event) => {
    setCreateShop((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const validationSchema = Yup.object({
    shopname: Yup.string().required("shopname is required*"),
    // shopCategory: Yup.string().required("shopCategory is required*"),
    shoplicense: Yup.string().required("shoplicense is required*"),
    shopaddress: Yup.string().required("shopaddress is required*"),
    description: Yup.string().required("description is required*"),
    // image: Yup.string().required("image is required*"),
  });

  const HandleCategory = () => {
    url
      .get("category/type/?type=transportShopItem&parentCategory=")

      .then(async (res) => {
        setTransportc(res.data.data.Category);
      });
  };

  return (
    <>
      {selectedImage && <PersonalImage image={selectedImage} />}

      <Formik
        initialValues={{
          shopname: "",
          shoplicense: "",
          shopaddress: "",
          description: "",
          // image: [""],
        }}
        // validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={(values) => { }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
          isValid,
          dirty,
        }) => (
          <Row>
            <form
              onSubmit={(e) => {
                CreateHandler(e);
                handleSubmit(e);
              }}
            >
              <div className="screenHeight d-flex justify-content-center align-items-center">
                <div className="Form-Shadow col-lg-6 col-md-6 col-sm-8 col-xs-12  pb-3">
                  <div className="arrowback ">
                    <BackArrow
                      back={backurl}
                      black="black"
                      mystyle="p-1 ps-4"
                    />
                  </div>
                  <div className="ps-5 pe-3 pt-4">
                    <div>
                      <h4 className="createHeading">{title}</h4>
                    </div>

                    <Row className="pt-3 ps-1 pe-3">
                      <Col lg="6" className="d-flex flex-column ">
                        <label className="custm-lble-2 pb-2">
                          {inputtitle}
                        </label>
                        <input
                          className="input-style"
                          placeholder="Name"
                          defaultValue={createShop?.shopname}
                          name="shopname"
                          readOnly={
                            location.pathname === `/usedvehicle-update/${id}`
                          }
                          onChange={(e) => {
                            ShopHandler(e);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.shopname && touched.shopname ? (
                          <div>
                            <p className="error_text_color">
                              {errors.shopname}
                            </p>
                          </div>
                        ) : null}
                      </Col>
                      <Col lg="6">
                        {location.pathname === "/used-vehicle" ||
                          location.pathname === "/usedvehicle-update/" + id ? (
                          <>
                            <label className="custm-lble-2 pb-2">
                              Select Category
                            </label>
                            <Form.Select
                              className="  input-style"
                              aria-label="Default select example"
                              name="shopCategory"
                              onChange={(e) => {
                                ShopHandler(e);
                                handleChange(e);

                              }}
                              onBlur={handleBlur}
                            >
                              <option>Select Category</option>
                              {shopCategory?.map((category) => (
                                <option
                                  key={category._id}
                                  value={category?._id}
                                >
                                  {category?.name}
                                </option>
                              ))}
                            </Form.Select>
                            {errors.shopCategory && touched.shopCategory ? (
                              <div>
                                <p className="error_text_color">
                                  {errors.shopCategory}
                                </p>
                              </div>
                            ) : null}
                          </>
                        ) : (
                          <>
                            <Form.Label className="custm-lble-2 mb-1">
                              Select Category
                            </Form.Label>
                            <Form.Select
                              className="input-style"
                              aria-label="Default select example"
                              defaultValue={createShop?.shopCategory}
                              name="shopCategory"
                              onChange={(e) => {
                                ShopHandler(e);
                                Shopdatas(e);
                              }}
                              onBlur={handleBlur}
                              onClick={HandleCategory}
                            >
                              <option>Select Category</option>

                              {transportc?.map((e) => (
                                <option value={`${e?._id}.${e?.name}`}>{e?.name}</option>

                              ))}

                            </Form.Select>
                            {errors.shopCategory && touched.shopCategory ? (
                              <div>
                                <p className="error_text_color">
                                  {errors.shopCategory}
                                </p>
                              </div>
                            ) : null}
                          </>
                        )}
                      </Col>
                    </Row>

                    <Row className="pt-3 ps-1 pe-3">
                      {location.pathname === "/used-vehicle" ||
                        location.pathname === "/usedvehicle-update/" + id ? (
                        <Col lg="6" className="d-flex flex-column ">
                          {" "}
                          <label className="custm-lble-2 pb-2">
                            Market Place
                          </label>
                          <input
                            className="input-style"
                            placeholder="Market Place"
                            name="marketPlace"
                            onChange={(e) => {
                              ShopHandler(e);
                            }}
                            onBlur={handleBlur}
                          />
                        </Col>
                      ) : null}

                      <div
                        lg="6"
                        className={`d-flex flex-column ${location.pathname === "/used-vehicle" ||
                          location.pathname === "/usedvehicle-update/" + id
                          ? "col-lg-6"
                          : "col-12"
                          }`}
                      >
                        {" "}
                        <label className="custm-lble-2 pb-2">
                          CNIC or Licence Number
                        </label>
                        <input
                          defaultValue={createShop?.shoplicense}
                          name="shoplicense"
                          onChange={(e) => {
                            ShopHandler(e);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          className="input-style"
                          placeholder="Licence Number/CNIC"
                        ></input>
                        {errors.shoplicense && touched.shoplicense ? (
                          <div>
                            <p className="error_text_color">
                              {errors.shoplicense}
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </Row>

                    <Row className="pt-3 ps-1 pe-3">
                      <Col md={12} lg={12} xl={12}>
                        <Form.Label className="custm-lble-2 pt-2 ">
                          Shop Address
                        </Form.Label>
                        <Form.Control
                          placeholder="Add Address with maps"
                          className="input-style"
                          name="shopaddress"
                          defaultValue={createShop?.shopaddress}
                          onChange={(e) => {
                            ShopHandler(e);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        />
                        <div className="position-relative">
                          <img className="iMg" src={images.FindClinic} />
                        </div>
                      </Col>
                      {errors.shopaddress && touched.shopaddress ? (
                        <div>
                          <p className="error_text_color">
                            {errors.shopaddress}
                          </p>
                        </div>
                      ) : null}
                    </Row>

                    {/* Description */}
                    <div className="ps-1 pe-3">
                      <div className="d-flex flex-column pt-1">
                        <label className="custm-lble-2 pb-2">Description</label>
                        <textarea
                          placeholder="Description"
                          className="input-style pt-1 ps-2 pb-5 pe-2 "
                          defaultValue={createShop?.description}
                          name="description"
                          onChange={(e) => {
                            ShopHandler(e);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        />
                      </div>
                      {errors.description && touched.description ? (
                        <div>
                          <p className="error_text_color">
                            {errors.description}
                          </p>
                        </div>
                      ) : null}
                    </div>

                    {/* UploadImg */}

                    <Col lg="9" className="me-auto">
                      <Row>
                        <Col lg={3} className="mt-2 ms-1">
                          <Card
                            style={{ width: "6rem", height: "6rem" }}
                            {...getRootProps()}
                            className="dropzone"
                          >
                            <Card.Img
                              className="imet-fit"
                              src={images.AddImage}
                            />
                            <Card.Body>
                              <input
                                type="file"
                                id="file"
                                style={{ display: "none" }}
                                {...getInputProps()}
                                multiple
                              />
                            </Card.Body>
                          </Card>
                        </Col>
                        {
                          displayimg?.length > 0 && (
                            <Col lg={7} className=" mt-1 d-flex">


                              {displayimg.map((image, index) => (
                                <Card
                                  className="me-2 mt-1"
                                  style={{
                                    width: "6rem",
                                    height: "6rem",
                                    border: "2px solid",
                                  }}

                                >
                                  <Card.Img
                                    className="imet-fit upload-img mt-0"
                                    src={getAsset(image)}
                                    alt=""
                                    crossOrigin="anonymous"
                                  />
                                  <div
                                    className="close-btnone"
                                    onClick={() => removeImage(index)}
                                  >
                                    <ClearIcon />
                                  </div>
                                </Card>
                              ))}
                            </Col>
                          )

                        }

                      </Row>
                    </Col>

                    <div className="btns pe-4">
                      <button
                        className="btn1"
                        onClick={
                          location.pathname === "/usedvehicle-update/" + id
                            ? () => navigate("/used-vehicle-profile/" + id)
                            : () => navigate("/SellingSide")
                        }
                      >
                        Cancel
                      </button>
                      <Button
                        className="btn2 disable-btn p-1"
                        variant="primary"
                        disabled={
                          location.pathname === `${link}${id}`
                            ? ""
                            : !(isValid && dirty)
                        }
                        data-title={
                          location.pathname === `${link}${id}`
                            ? null
                            : !(isValid && dirty)
                              ? "Please fill out all Fields to enable button!"
                              : null
                        }
                        onClick={
                          location.pathname === "/create-transport"
                            ? CreateHandler
                            : "" || butn === "Update"
                              ? UpdateShop
                              : "" || location.pathname === "/used-vehicle"
                                ? PostShopData
                                : "" || butn === "update"
                                  ? Updatevehicleshop
                                  : "" || location?.pathname === `${link}${id}`
                                    ? updatehandel
                                    : ""
                        }
                      >
                        {butn}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Row>
        )}
      </Formik>
    </>
  );
};
export default TransportForm;
