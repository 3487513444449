import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { images } from "../../../../constants";
import "./style.css";
import { useLocation } from "react-router-dom";

const Emailpic = (props) => {
  const location = useLocation();

  return (
    <div>
      <Container>
        <div className=" pb-1 ">
          <img src={images.Companypic} />
          <h6 className="pt-1">company name</h6>

          <div>
            <h6> Adress </h6>
            <Row>
              <Col lg="6">
                <h6>Phone No</h6>
              </Col>

              {location.pathname === "/quotation" && (
                <Col lg="6" className="d-flex justify-content-center">
                  <h6>Quotation No: 23</h6>
                </Col>
              )}
            </Row>
            <Row>
              <Col lg="6" md="6">
                <h6>Email Adress</h6>
              </Col>
              <Col lg="6" md="6" className="d-flex justify-content-center">
                <h6>Date: 23-02-2022</h6>
              </Col>
            </Row>

            <Row>
              <Col lg="7" md="6" sm="6" xs="6">
                <p className="phera pt-1 ps-1 mb-2">From</p>

                <div className="inputswidth">
                  <div className="pb-1">
                    <input
                      className="inputcolors"
                      placeholder="[Company name]"
                    />
                  </div>
                  <div className="pb-1">
                    <input
                      className="inputcolors"
                      placeholder="[Company Address]"
                    />
                  </div>
                  <div className="pb-1">
                    <input
                      className="inputcolors"
                      placeholder="[Contact number]"
                    />
                  </div>
                  <div className="pb-2">
                    <input
                      className="inputcolors"
                      placeholder="[Email adress]"
                    />
                  </div>
                </div>
              </Col>

              <Col lg="5" md="6" sm="6" xs="6">
                <p className="Clientinfo pt-1 ps-1 mb-2">To</p>
                <div className="pb-1">
                  <input
                    className="inputcolor ps-1"
                    placeholder="[Company name]"
                  />
                </div>
                <div className="pb-1">
                  <input
                    className="inputcolor ps-1"
                    placeholder="[Company Address]"
                  />
                </div>
                <div className="pb-1">
                  <input
                    className="inputcolor ps-1"
                    placeholder="[Contact number]"
                  />
                </div>
                <div className="pb-1">
                  <input
                    className="inputcolor ps-1"
                    placeholder="[Email adress]"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Emailpic;
