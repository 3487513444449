import { Container } from "react-bootstrap";
import { Col, Row, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import BackArrow from "../../../components/backArrow";
import { useRef } from "react";
import PersonalImage from "../../../helpers/fileUpload";
import { useState } from "react";
import { useEffect } from "react";
import Card from "react-bootstrap/Card";
import SearchIcon from "@mui/icons-material/Search";
import { images } from "../../../constants";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Measurementunit, currencies } from "../../../constants/jsonConstant";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import url from "../../../config/axios";
import { SuccessNotify, FailedNotify } from "../../../constants/tostify";
const UpdateScrapAdd = () => {
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const { id } = useParams();
  const imageUrl = useSelector((state) => state.imgurl);
  const { name } = useSelector((state) => state.user);
  const [selectedImage, setSelectedImage] = useState("");
  const [scrapcategory, setScrapcategory] = useState();

  const [userData, setUserData] = useState({
    userid: name._id,
    isSingleItem: false,
    isScrapItem: true,
    price: "",
    scrapType: "",
    country: "",
    city: "",
    state: "",
    quantity: "",
    description: "",
    unitOfMeasurement: "",
    currency: "",
    images: [""],
    status: true,
  });

  useEffect(() => {
    setUserData((userData) => ({
      ...userData,
      images: imageUrl?.imgurl || "",
    }));
    // ...
  }, [imageUrl]);

  const InputChange = (e) => {
    setUserData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const userDatahandler = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .put(`shopitem/id/?id=${id}`, userData)
      .then(async (response) => {
       

        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Add Updated Successfully");
          }, 1000);
          navigate(`/scrap-item-show`);
        }
      })
      .catch((e) => {
        setIsloading(false);
        FailedNotify("Failed To Update Try Again!");
      });
  };

  useEffect(() => {
    url
      .get(`shopitem/id/?id=${id}`)
      .then(async (res) => {
        setUserData(res.data.data.ShopItem);
      })
      .catch(() => {});
  }, []);

  const [uploadedImages, setUploadedImages] = useState([]);

  const onChangePicture = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setUploadedImages([...uploadedImages, reader.result]);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const removeImage = (index) => {
    const updatedImages = [...uploadedImages];
    updatedImages.splice(index, 1);
    setUploadedImages(updatedImages);
  };

  const inputfile = useRef(null);
  const upload_img = () => {
    inputfile.current.click();
  };

  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedstateone, setSelectedstateone] = useState("");
  const [searchTermone, setSearchTermone] = useState("");

  const [selectedcity, setSelectedcity] = useState("");
  const [searchTermtwo, setSearchTermtwo] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle the country selection
  const handleCountryChange = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
  };

  const handleSearchChangestate = (event) => {
    setSearchTermone(event.target.value);
  };

  const handleSearchChangecity = (event) => {
    setSearchTermtwo(event.target.value);
  };

  // Function to handle the state selection
  const handleCountryChangestate = (event) => {
    const { value } = event.target;
    setSelectedstateone(value);
  };

  // Function to handle the city selection
  const handleCountryChangecity = (event) => {
    const { value } = event.target;
    setSelectedcity(value);
  };
  const [hidefield, setHidefield] = useState(1);
  const [hidestate, setHidestate] = useState(1);
  const [hidecity, setHidecity] = useState(1);

  const [countrydata, SetCountrydata] = useState([]);
  const [getcountry] = useState();
  const [selectedState] = useState();
  const [getState, setState] = useState([]);
  const [getcity, setGetcity] = useState([]);
  const [statetwo, setStatetwo] = useState(getcountry);
  const [city, setCity] = useState(selectedState);
  const [open, setOpen] = useState(getcountry);

  useEffect(() => {
    url.get("category/consumer/?type=scrap").then(async (res) => {
      setScrapcategory(res.data.data.Category);
    });
  }, []);

  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => SetCountrydata(res.data))

      .catch((error) => {});
  }, []);

  const country = [...new Set(countrydata.map((item) => item.country))];

  country.sort();

  const handleCountry = (e) => {
    let states = countrydata.filter(
      (state) => state.country === e.target.value
    );
    states = [...new Set(states.map((item) => item.subcountry))];
    states.sort();
    setState(states);
  };

  const handleState = (e) => {
    let cities = countrydata.filter(
      (city) => city.subcountry === e.target.value
    );
    setGetcity(cities);
  };
  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }
  console.log("fghjkl", userData);
  return (
    <>
      {selectedImage && <PersonalImage image={selectedImage} />}
      <Container>
        <Row>
          <div className="screenHeight d-flex justify-content-center align-items-center">
            <Col lg={10} md={12} sm={12} xs={12} className="Form-Shadow">
              <Row>
                <Col className="">
                  <div className="arrowback ps-3">
                    <BackArrow
                      back="/SellingSide"
                      black="black"
                      mystyle="ps-4"
                    />
                  </div>
                  {/* <div className="pe-3  pb-2"> */}
                  {/* </div> */}
                </Col>
              </Row>
              <div className="p-4">
                <Row className="pt-2 pb-2 px-3">
                  <Col md={3} lg={3} xl={3}>
                    <Form.Label className="custm-lble">Scrap Type</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="scrapType"
                      className="input-style form-control-seller"
                      onChange={(e) => InputChange(e)}
                    >
                      {scrapcategory?.map((category) => (
                        <>
                          <option key={category._id} value={`${category?._id}`}>
                            {category?.name}
                          </option>
                        </>
                      ))}
                    </Form.Select>
                  </Col>
                  <div className="col-md-3">
                    <label
                      htmlFor="inputPassword4"
                      placeholder="First name"
                      className="form-label labelSize"
                    >
                      Country*
                    </label>
                    <div className="blood-type input-style">
                      <div className="d-flex">
                        <div className="w-100">
                          <input
                            type="search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder="Search country here...."
                            className="w-100 search-input"
                            onClick={() => setHidefield(0)}
                          />
                        </div>
                        <div>
                          <span>
                            <SearchIcon className="search-icon-fs" />
                          </span>
                        </div>
                      </div>

                      <select
                        onClick={() => setHidefield(1)}
                        value={selectedCountry}
                        onChange={(e) => {
                          InputChange(e);
                          handleCountryChange(e);
                          handleCountry(e);
                        }}
                        className="b-radius selectSize w-100"
                        name="country"
                      >
                        {hidefield && <option>{userData?.country}</option>}

                        {country
                          .filter((country) =>
                            country
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          .map((country, index) => (
                            <option key={country} value={getcountry}>
                              {country}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <label
                      htmlFor="inputPassword4"
                      placeholder="First name"
                      className="form-label labelSize"
                    >
                      State*
                    </label>
                    <div className="blood-type input-style">
                      <div className="d-flex">
                        <div className="w-100">
                          <input
                            type="search"
                            value={searchTermone}
                            onChange={handleSearchChangestate}
                            placeholder="Search state here...."
                            className="w-100 search-input"
                            onClick={() => setHidestate(0)}
                          />
                        </div>
                        <div>
                          <span>
                            <SearchIcon className="search-icon-fs" />
                          </span>
                        </div>
                      </div>

                      <select
                        onClick={() => setHidestate(1)}
                        className="b-radius selectSize w-100"
                        value={selectedstateone}
                        aria-label="Default select example "
                        name="state"
                        onChange={(e) => {
                          InputChange(e);
                          handleState(e);
                          handleCountryChangestate(e);
                        }}
                      >
                        {hidestate && <option>{userData?.state}</option>}
                        {/* <option>
                            {Open === undefined
                              ? "Select Country First"
                              : "select state"}
                          </option> */}

                        {getState
                          .filter((country) =>
                            country
                              .toLowerCase()
                              .includes(searchTermone.toLowerCase())
                          )
                          .map((country) => (
                            <option key={country} value={selectedState}>
                              {country}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3 ">
                    <label
                      htmlFor="inputPassword4"
                      placeholder="First name"
                      className="form-label labelSize"
                    >
                      City
                    </label>
                    <div className="blood-type input-style">
                      <div className="d-flex">
                        <div className="w-100">
                          <input
                            type="search"
                            value={searchTermtwo}
                            onChange={handleSearchChangecity}
                            placeholder="Search city here...."
                            className="w-100 search-input"
                            onClick={() => setHidecity(0)}
                          />
                        </div>
                        <div>
                          <span>
                            <SearchIcon className="search-icon-fs" />
                          </span>
                        </div>
                      </div>

                      <select
                        onClick={() => setHidecity(1)}
                        value={selectedcity}
                        className="b-radius selectSize w-100"
                        aria-label="Default select example"
                        name="city"
                        onChange={(e) => {
                          handleCountryChangecity(e);
                          InputChange(e);
                        }}
                      >
                        {/* <option>
                              {city === undefined
                                ? "Select State First"
                                : "Select City"}
                            </option> */}

                        {hidecity && <option>{userData?.city}</option>}

                        {getcity
                          .filter((country) =>
                            country.name
                              .toLowerCase()
                              .includes(searchTermtwo.toLowerCase())
                          )
                          .map((country) => (
                            <option key={country.name}>{country.name}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                </Row>

                <Row className="pt-2 pb-2  px-3">
                  <Col md={3} lg={3} xl={3}>
                    <Form.Label className="custm-lble">
                      Total Quantity
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => InputChange(e)}
                      placeholder="Quantity"
                      defaultValue={userData?.quantity}
                      name="quantity"
                      className="input-style form-control-seller"
                      type="number"
                    />
                  </Col>

                  <Col md={3} lg={3} xl={3}>
                    <Form.Label className="custm-lble">Price</Form.Label>
                    <Form.Control
                      onChange={(e) => InputChange(e)}
                      placeholder="Price"
                      defaultValue={userData?.price}
                      name="price"
                      className="input-style form-control-seller"
                      type="number"
                    />
                  </Col>

                  <Col md={3} lg={3} xl={3}>
                    <Form.Label className="custm-lble">
                      Unit of Measurement{" "}
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => InputChange(e)}
                      aria-label="Default select example"
                      name="unitOfMeasurement"
                      className="input-style form-control-seller"
                      // onChange={(e) => {
                      //   handleChange(e);
                      //   inputChange(e);
                      // }}
                    >
                      {Measurementunit.map((e) => (
                        <option>{e.name}</option>
                      ))}
                    </Form.Select>
                  </Col>

                  <Col md={3} lg={3} xl={3}>
                    <Form.Label className="custm-lble">Currency </Form.Label>
                    <Form.Select
                      onChange={(e) => InputChange(e)}
                      aria-label="Default select example"
                      name="currency"
                      className="input-style form-control-seller"
                      // onChange={(e) => {
                      //   handleChange(e);
                      //   inputChange(e);
                      // }}
                    >
                      <option>{userData?.currency}</option>
                      {currencies.map((e) => (
                        <option>
                          {e.abbreviation} {e.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>

                <div className="ps-3 pe-3 pt-4">
                  <Form.Label className="custm-lble">Description</Form.Label>
                  <Form.Control
                    onChange={(e) => InputChange(e)}
                    as="textarea"
                    rows={5}
                    name="description"
                    defaultValue={userData?.description}
                    className="input-style"
                  />
                </div>
                <div className="d-flex justify-content-between pt-2">
                  <Col>
                    <Row>
                      <Col lg={2} className="mt-2 pt-4 ps-4">
                        <Card
                          style={{ width: "6rem", height: "6rem" }}
                          onClick={upload_img}
                        >
                          <Card.Img
                            className="imet-fit"
                            src={images.AddImage}
                            onChange={(e) => {}}
                          />
                          <Card.Body>
                            <input
                              type="file"
                              id="file"
                              ref={inputfile}
                              style={{ display: "none" }}
                              name="images"
                              onChange={(e) => {
                                onChangePicture(e);
                              }}
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg={10} className=" d-flex gap-2 pt-4">
                        {uploadedImages.map((image, index) => (
                          <Card
                            style={{
                              width: "6rem",
                              height: "6rem",
                              border: "2px solid",
                            }}
                            key={index}
                          >
                            <Card.Img
                              className="imet-fit upload-img"
                              src={image}
                              alt={`Uploaded ${index}`}
                            />
                            <div
                              className="close-btnone"
                              onClick={() => removeImage(index)}
                            >
                              <ClearIcon />
                            </div>
                          </Card>
                        ))}
                      </Col>
                    </Row>
                  </Col>

                  <div className="d-flex align-items-end pb-3 pe-3">
                    <div className="btns">
                      <button
                        className="btn1"
                        onClick={() => navigate("/SellingSide")}
                      >
                        Cancle
                      </button>
                      <Button
                        className="btn2 p-1"
                        variant="primary"
                        onClick={(e) => userDatahandler(e)}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default UpdateScrapAdd;
