import Modal from "react-bootstrap/Modal";
import ReplyIcon from "@mui/icons-material/Reply";
import ShareModel from "../../components/Sharemodel/index";
import { useState } from "react";
import QRCode from 'qrcode.react';
import ShareOption from "../../components/Shareoption";
const Qrmodal = (props) => {
  const [showmodal, setshowmodal] = useState(false);
  const [showshare, setShowshare] = useState(false);


  const closemodal = () => {
    setshowmodal(false);
  };
  
  const handleCloseshare = () => {
    setShowshare(false);
    setshowmodal(true);
  };
  
  const handleShowshare = () => {
    setShowshare(true);
  };

  return (
    <>
      <ShareOption showshare={showshare} handleCloseshare={handleCloseshare} />
      <ShareModel
        show={showmodal}
        handleClosemodal={closemodal}
        handleShowshare={handleShowshare}
      />
      <Modal
        size="sm"
        show={props.show}
        onHide={props.handleClosemodal}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header
          className="p-2"
          closeButton
          style={{ backgroundColor: "#0353A4" }}
        >
          <Modal.Title
            id="example-modal-sizes-title-sm "
            style={{ color: "white", fontSize: "13px", paddingleft: "9px" }}
          >
            Shop QR Code
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <div className="d-flex flex-column">
            <div
              style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: 64,
                width: "100%",
              }}
            >
              
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={`https://selectezi.com/${props.id}`}
                viewBox={`0 0 256 256`}  />
            </div>

            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="btn d-flex pt-0 pb-0 ps-1 pe-1"
                style={{
                  backgroundColor: "#0353A4",
                  color: "white",
                }}
                onClick={() => setshowmodal(true)}
              >
                Share

                <ReplyIcon
                  style={{
                    transform: "rotateY(180deg)",
                    fontSize: "revert-layer",
                    color: "white",
                  }}
                />
              </button>
            </div>
            
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Qrmodal;
