import { useParams, useNavigate } from "react-router-dom";
import PropertyAdForm from "../forms/propertyAdform";
import { useState, useEffect } from "react";
import url from "../../config/axios";
import { SuccessNotify } from "../../constants/tostify";
import { FailedNotify } from "../../constants/tostify";
import { useSelector } from "react-redux";
import Loader from "../Loader";
import { publishImage } from "../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
const UpdateBussinessPro = () => {
  const { name } = useSelector((state) => state.user);
  const { index } = useParams();
  const [displayimg,setDisplayimg]=useState();
  const [isloading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const [companyid, setCompanyid] = useState();
  const [shopCategory, setShopCategory] = useState();
  const [shopCategorytwo, setShopCategorytwo] = useState();

  const [data, setData] = useState({
    category:"",
    subCategory:""
  })

  const Handledata = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split(".")

    }))
  }

  const categoryName = data?.category[1];
  const categoryid = data.category[0];
  const subcategoryName = data?.subCategory[1];
  const subcategoryid = data.subCategory[0];




  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setPropertyadd((propertyadd) => ({
        ...propertyadd,
        image: abc
      }));

      setDisplayimg(abc);
    
    }catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });
  const [propertyadd, setPropertyadd] = useState({
    companyid: "",
    userid: name?._id,
    plottype: "",
    category: "",
    categoryid:"",
    subCategory: "",
    subCategoryid:"",
    country: "",
    city: "",
    state: "",
    area: "",
    contactnumber: "",
    email: "",
    price: "",
    saletype: "",
    description: "",
    image: [""],
    status: true,
  });

  useEffect(() => {
    setPropertyadd((propertyadd) => ({
      ...propertyadd,
    category:categoryName,
    categoryid:categoryid,
    subCategory:subcategoryName,
    subCategoryid:subcategoryid
     
    }));
  
  }, [categoryid,categoryName,subcategoryName,subcategoryid]);


  const Firstcategory = () => {
    url
      .get("category/consumer/?type=property&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });
  };

  const Mydata = () => {
    url
      .get(`category/consumer/?type=property&parentCategory=${categoryName}`)
      .then(async (response) => {
        console.log("jksahdf", response);
        if (response?.data?.success) {
          setShopCategorytwo(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });
  };


  const Updatefunction = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .put(`property/id/?id=${index}`, propertyadd)
      .then(async (response) => {
        if (response.data.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Add Update Successfully");
          }, 1000);
          navigate(`/Sale-Bussines-Property/${companyid}`);
        }
      })
      .catch((e) => {
        setIsloading(false);
        FailedNotify("Failed To Update Try Again!");
      });
  };

  useEffect(() => {
    url.get(`property/id/?id=${index}`).then(async (response) => {
      setCompanyid(response?.data?.data?.Property?.companyid);
      setPropertyadd({
        ...propertyadd,
        companyid: response?.data?.data?.Property?.companyid,
        plottype: response?.data?.data?.Property?.plottype,
        country: response?.data?.data?.Property?.country,
        city: response?.data?.data?.Property?.city,
        state: response?.data?.data?.Property?.state,
        area: response?.data?.data?.Property?.area,
        contactnumber: response?.data?.data?.Property?.contactnumber,
        email: response?.data?.data?.Property?.email,
        price: response?.data?.data?.Property?.price,
        saletype: response?.data?.data?.Property?.saletype,
        description: response?.data?.data?.Property?.description,
        image: response?.data?.data?.Property?.image,
      });
    });
  }, []);

  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }
  return (
    <div>
      <PropertyAdForm
        title="Update Business Property Category,Location,Price,Description"
        butn="Update"
        Updatehandler={Updatefunction}
        setPropertyadd={setPropertyadd}
        propertyadd={propertyadd}
        index={index}
        navigateTo="/Sale-Bussines-Property"
        link="/update-bussiness-profile/"
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        displayimg={displayimg}
        setDisplayimg={setDisplayimg}
        shopCategory={shopCategory}
        shopCategorytwo={shopCategorytwo}
        Mydata={Mydata}
        Handledata={Handledata}
        Firstcategory={Firstcategory}
      />
    </div>
  );
};
export default UpdateBussinessPro;
