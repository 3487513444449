import { Container, Row, Col } from "react-bootstrap";
import Shopintegrate from "../../components/shop_integrate";

const Recycledintegrate = () => {
  return (
    <>
      <Container>
        <div className="screenHeight">
          <Shopintegrate
            newshop="/recycle-shop"
            url="/recycled-integrate-form"
          />
        </div>
      </Container>
    </>
  );
};

export default Recycledintegrate;
