import CVProfileInformation from "../../../../../components/CVprofileInformation";
import "./profileinformation.css";
const CVInformation = ({ cvdata }) => {
  return (
    <div>
      <CVProfileInformation
        header="cv-header-5 "
        title="title-margin-5"
        desc="desc-margin"
        cvdata={cvdata}
      />
    </div>
  );
};
export default CVInformation;
