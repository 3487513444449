import React from "react";
import BoxData from "../Box";
import CardNav from "../CardNav";
import { Col, Container, Row, Card } from "react-bootstrap";
import { images } from "../../../../constants";
import { useState } from "react";

const Recycled = ({ btn }) => {
  const [id,setIs]=useState(1);
  const [myinfo,setMyinfo]=useState("single info")
  return (
    <div>
      <Row>
        <Col lg="12">
        <CardNav Header="Recycled" myid={id} myinfo={myinfo} />

          <Card className="shadow-main mt-2">
            <BoxData
              Title="Recycled Material"
              Butonn={btn}
              imgg={images.Recycled}
              url="/recycled-integrate"
              link="/recycle-online"
              ms="twentyone-element"
              buy="eighteen"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Recycled;
