import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

import SearchIcon from "@mui/icons-material/Search";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import SpecificModal from "../Specific_modal";

const ModalEmailone = ({ handleClosesecond, secondmodal, addUsers }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [showaccesstwo, setShowaccesstwo] = useState(false);

  const handleCloseaccesstwo = () => setShowaccesstwo(false);
  const handleShowaccesstwo = () => setShowaccesstwo(true);
  return (
    <>
    <SpecificModal handleCloseaccesstwo={handleCloseaccesstwo} showaccesstwo={showaccesstwo} />
      <Modal
        className="modal-setone"
        centered
        show={secondmodal}
        onHide={handleClosesecond}
      >
        <Modal.Header className="modalcolor" closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            <b>Who should have task access to this Shop?</b>
          </p>
          <p>Enter Email of person you want to add</p>

          <InputGroup className="mb-3">
            <Form.Control
              className="fw-bolder"
              placeholder="Search by Email"
              aria-label="Search by Email "
              aria-describedby="basic-addon2"
            />

            <div>
              <div className="position-relative">
                <div className="position-absolute custom-butn-set">
                  <Button
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <SearchIcon />
                  </Button>
                </div>
              </div>
            </div>
          </InputGroup>
          <div className="d-flex justify-content-end">
            <Button className="btn-primary" onClick={handleShowaccesstwo}>
              Next
            </Button>
          </div>
        </Modal.Body>

        {/* <Form.Control size="lg" type="text" placeholder="Large text" /> */}
      </Modal>
    </>
  );
};

export default ModalEmailone;
