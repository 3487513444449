import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import Shops from "../../../components/Multipleshopprofile/Components/Shops/Shops";
import ShopAdds from "../../../components/shopAds/ShopAdds";
import { useState, useEffect } from "react";
import url from "../../../config/axios";
import axios from "axios";
import { images } from "../../../constants";
import ShareModel from "../../../components/Sharemodel";
import ShareOption from "../../../components/Shareoption";
import BackArrow from "../../../components/backArrow";
import SearchIcon from "@mui/icons-material/Search";
const PropertyFilter = () => {
  const [countrydata, SetCountrydata] = useState([]);
  const [getcountry] = useState();
  const [selectedState] = useState();
  const [getState, setState] = useState([]);
  const [getcity, setGetcity] = useState([]);
  const [statetwo, setStatetwo] = useState(getcountry);
  const [city, setCity] = useState(selectedState);
  const [open, setOpen] = useState(getcountry);
  const [data, setData] = useState(0);
  const [shopdata, setShopdata] = useState();
  const [itemmdata, setItemmdata] = useState();
  const [dataCategory, setDataCategory] = useState({
    categoryid: "",
  });

  const [shopCategory, setShopCategory] = useState();
  const [shopCategorytwo, setShopCategorytwo] = useState();

  const Handledata = (e) => {
    setDataCategory((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split(".")
    }));
  };
  const categoryName = dataCategory?.categoryid[1];
  const categoryidone = dataCategory.categoryid[0];
  const [filterpropertybycompany, setFilterpropertybycompany] = useState({
    companyname: "",
    companyid: "",
    marketplace: "",
  });

  const [filterpropertybyproperty, setFilterpropertybyproperty] = useState({
    categoryid: "",
    subCategoryid: "",
    saletype: "",
    country: "",
    state: "",
    city: "",
    min: "",
    max: "",
  });

  useEffect(() => {
    setFilterpropertybyproperty((filterpropertybyproperty) => ({
      ...filterpropertybyproperty,
      categoryid: categoryidone !== undefined ? categoryidone : "",

    }));

  }, [categoryidone]);

  const [selectedSegment, setSelectedSegment] = useState("custom");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedstateone, setSelectedstateone] = useState("");
  const [searchTermone, setSearchTermone] = useState("");

  const [selectedcity, setSelectedcity] = useState("");
  const [searchTermtwo, setSearchTermtwo] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle the country selection
  const handleCountryChange = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
  };

  const handleSearchChangestate = (event) => {
    setSearchTermone(event.target.value);
  };

  const handleSearchChangecity = (event) => {
    setSearchTermtwo(event.target.value);
  };

  // Function to handle the state selection
  const handleCountryChangestate = (event) => {
    const { value } = event.target;
    setSelectedstateone(value);
  };

  // Function to handle the city selection
  const handleCountryChangecity = (event) => {
    const { value } = event.target;
    setSelectedcity(value);
  };
  const [hidefield, setHidefield] = useState(1);
  const [hidestate, setHidestate] = useState(1);
  const [hidecity, setHidecity] = useState(1);

  const handleSegmentChange = (value) => {
    setSelectedSegment(value);
  };

  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => SetCountrydata(res.data))

      .catch((error) => { });
  }, []);

  const country = [...new Set(countrydata.map((item) => item.country))];

  country.sort();

  const handleCountry = (e) => {
    let states = countrydata.filter(
      (state) => state.country === e.target.value
    );
    states = [...new Set(states.map((item) => item.subcountry))];
    states.sort();
    setState(states);
  };

  const handleState = (e) => {
    let cities = countrydata.filter(
      (city) => city.subcountry === e.target.value
    );
    setGetcity(cities);
  };

  const GetFIlterData = () => {
    url
      .get(
        `/property/filter/?companyname=${filterpropertybycompany?.companyname}&companyid=${filterpropertybycompany?.companyid}&marketplace=${filterpropertybycompany?.marketplace}&filtermethod=company`
      )
      .then(async (res) => {
        console.log("iopp", res)
        setShopdata(res?.data?.company)
        setItemmdata(res?.data?.property[0])


      });
  };
  const GetFIlterDatatwo = () => {
    console.log("filtercatogery", filterpropertybyproperty?.categoryid)
    url
      .get(
        `/property/filter/?categoryid=${filterpropertybyproperty?.categoryid}&subCategoryid=${filterpropertybyproperty?.subCategoryid}&saletype=${filterpropertybyproperty?.saletype}&country=${filterpropertybyproperty?.country}&state=${filterpropertybyproperty?.state}&city=${filterpropertybyproperty?.city}&min=${filterpropertybyproperty?.min}&max=${filterpropertybyproperty?.max}&filtermethod=propertyads`
      )
      .then(async (res) => {
        console.log("++++++", res)
        setShopdata(res?.data?.company)
        setItemmdata(res?.data?.property)


      });
  }

  const FilterPropertyDatatwo = (e) => {
    setFilterpropertybyproperty((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const FilterPropertyData = (e) => {
    setFilterpropertybycompany((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [showmodal, setShowmodal] = useState(false);
  const handleClosemodal = () => setShowmodal(false);
  const handleShow = () => setShowmodal(true);

  const [showshare, setShowshare] = useState(false);
  const handleCloseshare = () => {
    setShowshare(false);
    setShowmodal(true);
  };
  const handleShowshare = () => setShowshare(true);


  useEffect(() => {
    url
      .get("/category/type/?type=property&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });
  }, []);

  const Mydata = () => {

    url
      .get(
        `/category/type/?type=property&parentCategory=${categoryName}`
      )
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategorytwo(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });

  };

  return (
    <>
      <ShareOption showshare={showshare} handleCloseshare={handleCloseshare} />
      <ShareModel
        showmodal={showmodal}
        handleClosemodal={handleClosemodal}
        handleShowshare={handleShowshare}
      />
      <Container>
        <div>
          <Row>
            <Col lg="4" className="screenHeight">
              <div className="border b-radius height_100 ">
                <div className="  background-image">
                  <div className="d-flex bg-primary-one pt-2 pb-2 mb-1 ps-3">
                    <div>
                      <img
                        className="w-imgone"
                        src={images.WorldSearchtwo}
                        alt="web"
                      />
                    </div>
                    <div>
                      <h6 className="titleText pt-1 ps-2 mb-0">
                        Search Category, Area & Desired Location
                      </h6>
                    </div>
                  </div>

                  <div className=" ps-3 pe-3 pt-1 pb-1">
                    <div className="segment-container mt-2">
                      <button
                        className={`segment-button pt-1 pb-1 ${selectedSegment === "custom" ? "active" : ""
                          }`}
                        onClick={() => handleSegmentChange("custom")}
                      >
                        Search by item details
                      </button>
                      <button
                        className={`segment-button ${selectedSegment === "segment" ? "active" : ""
                          }`}
                        onClick={() => handleSegmentChange("segment")}
                      >
                        Search by shop details
                      </button>
                    </div>
                  </div>

                  <div className="px-3 pt-2">
                    {selectedSegment === "custom" ? (
                      <>
                        <div>
                          <Form.Label className="custm-lble">
                            Search by Category
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="categoryid"
                            onChange={(e) => {
                              Handledata(e);
                              FilterPropertyDatatwo(e);

                            }}
                          >
                            <option selected disabled>Category</option>
                            {shopCategory?.map((category) => (
                              <>
                                <option
                                  key={category._id}
                                  value={`${category?._id}.${category?.name}`}
                                >
                                  {category?.name}
                                </option>
                              </>
                            ))}
                          </Form.Select>
                        </div>

                        <div>
                          <Form.Label className="custm-lble">
                            Search By Sub Category
                          </Form.Label>
                          {/* <Form.Select
                        className="custom-fix-one"
                        placeholder="Sub-Category"
                      >
                        <option> select Category</option>
                      </Form.Select> */}
                          <Form.Select
                            aria-label="Default select example"
                            name="subCategoryid"
                            onChange={(e) => {

                              FilterPropertyDatatwo(e);

                            }}
                            onClick={Mydata}
                          >

                            <option selected disabled>Select Sub Category</option>
                            {shopCategorytwo?.map((subcategory) => (
                              <>
                                <option
                                  key={subcategory._id}
                                  value={subcategory?._id}
                                >
                                  {subcategory?.name}
                                </option>
                              </>
                            ))
                            }
                          </Form.Select>
                        </div>

                        <div>
                          {" "}
                          <Form.Label className="custm-lble">
                            Sale/Rent/Lease
                          </Form.Label>{" "}
                          <br></br>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="saletype"
                              id="inlineRadio1"
                              value="Sale"
                              onChange={(e) => FilterPropertyDatatwo(e)}

                            />
                            <label class="form-check-label" for="inlineRadio1">
                              Sale
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="saletype"
                              id="inlineRadio2"
                              value="rent"
                              onChange={(e) => FilterPropertyDatatwo(e)}
                            />
                            <label class="form-check-label" for="inlineRadio2">
                              Rent
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="saletype"
                              id="inlineRadio3"
                              value="lease"
                              onChange={(e) => FilterPropertyDatatwo(e)}
                            />
                            <label class="form-check-label" for="inlineRadio3">
                              Lease
                            </label>
                          </div>
                        </div>

                        <div>
                          <Form.Label className="custm-lble pt-3">
                            Search by Location
                          </Form.Label>
                        </div>

                        <div className="col-md-12">
                          <label
                            htmlFor="inputPassword4"
                            placeholder="First name"
                            className="form-label labelSize"
                          >
                            Country*
                          </label>
                          <div className="blood-type custom-fix-one">
                            <div className="d-flex">
                              <div className="w-100">
                                <input
                                  type="search"
                                  value={searchTerm}
                                  onChange={handleSearchChange}
                                  placeholder="Search country here...."
                                  className="w-100 search-input"
                                  onClick={() => setHidefield(0)}
                                />
                              </div>
                              <div>
                                <span>
                                  <SearchIcon className="search-icon-fs" />
                                </span>
                              </div>
                            </div>

                            <select
                              onClick={() => setHidefield(1)}
                              value={selectedCountry}
                              onChange={(e) => {
                                handleCountryChange(e);
                                handleCountry(e);
                                FilterPropertyDatatwo(e);
                              }}
                              className="b-radius selectSize w-100"
                              name="country"
                            >
                              {hidefield && <option>Select country</option>}

                              {country
                                .filter((country) =>
                                  country
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                )
                                .map((country, index) => (
                                  <option key={country} value={getcountry}>
                                    {country}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <label
                            htmlFor="inputPassword4"
                            placeholder="First name"
                            className="form-label labelSize"
                          >
                            State*
                          </label>
                          <div className="blood-type custom-fix-one">
                            <div className="d-flex">
                              <div className="w-100">
                                <input
                                  type="search"
                                  value={searchTermone}
                                  onChange={handleSearchChangestate}
                                  placeholder="Search state here...."
                                  className="w-100 search-input"
                                  onClick={() => setHidestate(0)}
                                />
                              </div>
                              <div>
                                <span>
                                  <SearchIcon className="search-icon-fs" />
                                </span>
                              </div>
                            </div>

                            <select
                              onClick={() => setHidestate(1)}
                              className="b-radius selectSize w-100"
                              value={selectedstateone}
                              aria-label="Default select example "
                              name="state"
                              onChange={(e) => {
                                handleState(e);
                                handleCountryChangestate(e);
                                FilterPropertyDatatwo(e);
                              }}
                            >
                              {hidestate && <option>Select state</option>}
                              {/* <option>
                            {Open === undefined
                              ? "Select Country First"
                              : "select state"}
                          </option> */}

                              {getState
                                .filter((country) =>
                                  country
                                    .toLowerCase()
                                    .includes(searchTermone.toLowerCase())
                                )
                                .map((country) => (
                                  <option key={country} value={selectedState}>
                                    {country}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <label
                            htmlFor="inputPassword4"
                            placeholder="First name"
                            className="form-label labelSize"
                          >
                            City
                          </label>
                          <div className="blood-type custom-fix-one">
                            <div className="d-flex">
                              <div className="w-100">
                                <input
                                  type="search"
                                  value={searchTermtwo}
                                  onChange={handleSearchChangecity}
                                  placeholder="Search city here...."
                                  className="w-100 search-input"
                                  onClick={() => setHidecity(0)}
                                />
                              </div>
                              <div>
                                <span>
                                  <SearchIcon className="search-icon-fs" />
                                </span>
                              </div>
                            </div>

                            <select
                              onClick={() => setHidecity(1)}
                              value={selectedcity}
                              className="b-radius selectSize w-100"
                              aria-label="Default select example"
                              name="city"
                              onChange={(e) => {
                                handleCountryChangecity(e);
                                FilterPropertyDatatwo(e);
                              }}
                            >
                              {/* <option>
                              {city === undefined
                                ? "Select State First"
                                : "Select City"}
                            </option> */}

                              {hidecity && <option>Select city</option>}

                              {getcity
                                .filter((country) =>
                                  country.name
                                    .toLowerCase()
                                    .includes(searchTermtwo.toLowerCase())
                                )
                                .map((country) => (
                                  <option key={country.name}>
                                    {country.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        <div>
                          <Form.Label className="custm-lble pt-2">
                            Price Filter
                          </Form.Label>
                          <Row className="d-flex">
                            <Col xl={3} className="d-flex">
                              <Form.Label className="custm-lble pt-1 pe-1">
                                Min
                              </Form.Label>
                              <div className="inputSetting">
                                <input placeholder=""
                                  type="number"
                                  name="min"
                                  onChange={(e) => FilterPropertyDatatwo(e)}


                                />
                              </div>
                            </Col>
                            <Col xl={3} className="d-flex">
                              <Form.Label className="custm-lble pt-1 pe-1">
                                Max
                              </Form.Label>
                              <div className="inputSetting">
                                <input placeholder=""
                                  type="number"
                                  name="max"
                                  onChange={(e) => FilterPropertyDatatwo(e)}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="pt-3 pb-2 d-flex justify-content-center">
                          <button
                            className="btn-lg bg-blue text-white"
                            onClick={GetFIlterDatatwo}
                          >
                            Search
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>

                          <Form.Label className="custm-lble">

                            Company Name
                          </Form.Label>
                          <Form.Control
                            className="custom-fix-one"
                            placeholder="companyname"
                            name="companyname"
                            onChange={(e) => FilterPropertyData(e)}

                          />
                        </div>

                        <div>
                          <Form.Label className="custm-lble">
                            Company ID
                          </Form.Label>
                          <Form.Control
                            className="custom-fix-one"
                            placeholder="companyid"
                            name="companyid"
                            onChange={(e) => FilterPropertyData(e)}
                          />
                        </div>
                        <div>
                          <Form.Label className="custm-lble">
                            Market Place
                          </Form.Label>
                          <Form.Control
                            className="custom-fix-one"
                            placeholder="marketPlace"
                            name="marketplace"
                            onChange={(e) => FilterPropertyData(e)}
                          />
                        </div>
                        <div className="pt-3 pb-2 d-flex justify-content-center">
                          <button
                            className="btn-lg bg-blue text-white"
                            onClick={GetFIlterData}
                          >
                            Search
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>

            <Col lg="8" className="screenHeight ">
              <div className="border b-radius ps-3 pe-3">
                <BackArrow back="/BuyingSide " mystyle="ps-2 pt-1" />
                <ShopAdds />
                <Shops
                  link="/user-peronal-property"
                  title="Property"
                  data={data}
                  handleShow={handleShow}
                  shopdata={shopdata}
                  itemmdata={itemmdata}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
export default PropertyFilter;
