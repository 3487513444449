import "../My_chart/mychart.css";
import  PersonalImage  from "../../../helpers/fileUpload";
import { images } from "../../../constants";
import { Ownchatroom } from "../../../constants/jsonConstant";
import { useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import MessageIcon from "@mui/icons-material/Message";
import SearchIcon from '@mui/icons-material/Search';
import Chatroombackarrow from "../../../components/Chatroom-backarrow";

const Createchatroom = () => {
  const inputfile = useRef(null);
  const upload_img = () => {
    inputfile.current.click();
  };

  const navigate = useNavigate();
  const [data, setData] = useState(Ownchatroom);

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      PersonalImage(e.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  return (
    <>
      <div className="container">
        {" "}
        <div className="screenHeight">
          <div className="row d-flex justify-content-evenly  pt-2 chatroom-custom-c">
            <div className="col-xl-5  col-lg-5 col-md-5 col-12 custom-bg mt-2 ">
              <div className="row mt-1 bordr-bottm">
                <div className="col-4"><Chatroombackarrow url="/own-chatroom" /></div>
                <div className="col-8 ">
                  <h6 className=" pt-1 pb-1">Create Chat Room</h6>
                </div>
              </div>

              <div className="pt-5">
                <MessageIcon className="msg_icon" />
                <h6>Fill to Create Chat Room</h6>
                <div class="form-group mt-2">
                  <label for="exampleInputEmail1" className="chatroom_label">
                    Chat Room Name
                  </label>
                  <input class="form-control" placeholder="Name"></input>
                </div>

                <div class="form-group mt-2">
                  <label for="exampleInputEmail1" className="chatroom_label">
                    Chat Room Category
                  </label>
                  <input class="form-control" placeholder="Category"></input>
                </div>

                <div class="form-group mt-2">
                  <label for="exampleInputEmail1" className="chatroom_label">
                    Enter Country/State/City
                  </label>
                  <input class="form-control" placeholder="Location"></input>
                </div>

                <div class="form-group mt-3">
                  <label
                    for="exampleFormControlTextarea1"
                    className="chatroom_label"
                  >
                    Description
                  </label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="4"
                    placeholder="Description"
                  ></textarea>
                </div>

                <div className="col-4 mt-3 ps-0">
                  <h6 className="text-white" style={{ fontSize: "11px" }}>
                    Upload Image
                  </h6>
                  <div className="cam chat_chat">
                    <div onClick={upload_img}>
                      <img src={images.Uploadchat} alt="" width="25%" />

                      <input
                        type="file"
                        id="file"
                        ref={inputfile}
                        style={{ display: "none" }}
                        name="image"
                        onChange={(e) => onChangePicture(e)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 p-0 mt-2 pb-3">
                  <button className=" create-chatroom-btn p-2 border-0 w-100" onClick={()=>navigate('/joinown-chatroom')}>
                    Create
                  </button>
                </div>
              </div>
            </div>

            <div className="col-xl-5 col-lg-5 col-md-5 col-12 custom-bg-2  mt-2  ">
              <div className="row bordr-bottm mt-1">
                <div className="col-12 ">
                  <h6 className="text-center pt-1 pb-1">Join Chat Rooms</h6>
                </div>
              </div>
              <div className="col-12 mt-2">
                <div className="d-flex justify-content-center">
                  <div className="col-xl-5 col-lg-8 col-md-12 col-11 ps-0 pe-0">
                    {" "}
                    <div class="input-group">
                      {" "}
                      <span class="input-group-addon bg-white border-0">
                        {" "}
                        <SearchIcon />{" "}
                      </span>{" "}
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search"
                      />{" "}
                    </div>{" "}
                  </div>
                </div>
              </div>

              <div className="row scroll__row noscroll">
                {data.map((e) => (
                  <div className="col-xl-4 col-lg-6 col-md-6 mt-4 mobile_colmn">
                    <div class="card" style={{ border: "none" }}>
                      <div className="activechatroom pr-1">
                        <span className="dotttt">.</span>
                      </div>

                      <img
                        class="card-img-top"
                        src={e.image}
                        alt="Card image cap"
                      />
                      <div class="card-body p-0 ownchatroombody">
                        <h6 class="card-title text-white text-center mt-2">
                          {e.groupname}
                        </h6>
                        <div className="d-flex justify-content-center">
                          <button
                            type="button"
                            className="own-btn mb-2"
                            onClick={() => navigate("/chatroom-streamline")}
                          >
                            Join
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Createchatroom;
