import CVProfileInformation from "../../../../../components/CVprofileInformation";
import "./profileinformation.css";
const CVInformation = ({ cvdata }) => {
  return (
    <div>
      <CVProfileInformation
        header="cv-header"
        title="cv-title "
        desc="cv-desc"
        cvdata={cvdata}
      />
    </div>
  );
};
export default CVInformation;
