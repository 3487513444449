import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoutes = () => {
  const { name } = useSelector((state) => state.user);

  if (!name) {
    // User not authenticated
    return <Navigate to="/" />;
  } else {
    // User authenticated
    return <Outlet />;
  }
};

export default PrivateRoutes;
