import { Container, Row, Col, Form } from "react-bootstrap";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { SuccessNotify,FailedNotify } from "../../constants/tostify";
import url from "../../config/axios";

const Cashdeliveryform = ({ setOpenmodal, openmodal, shopid,datavalue }) => {
  const [cashdelivery, setCashdelivery] = useState({
    paymentMethod: [
      {
        method: datavalue,
        details: {
          cashOnDelivery: {
            termOfConditions: "",
          },
        },
      },
    ],
  });
  const inputChange = (e) => {
    setCashdelivery((prevState) => ({
      ...prevState,
      paymentMethod: [
        {
          method: datavalue,
          details: {
            cashOnDelivery: {
              [e.target.name]: e.target.value
            },
          },
        },
      ]
        
      
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    url
      .put(`shop/paymentMethod/shopid?shopid=${shopid}`, cashdelivery)
      .then((res) => {
        if (res.data.success) {
          setOpenmodal(0);
          SuccessNotify(res.data.message);
        }
      })
      .catch((e) => {
        FailedNotify(e.response.data.message);
      });
  };
  return (
    <>
      <Modal
        size="lg"
        show={openmodal}
        onHide={() => setOpenmodal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Cash on Delivery:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form>
              <Row>
                <Col md={12} lg={12}>
                  <div class="form-group d-flex flex-column">
                    <label for="exampleFormControlTextarea1">
                      Terms and Condition
                    </label>
                    <textarea
                      id="exampleFormControlTextarea1"
                      rows="5"
                      className="labelText form-control"
                      name="termOfConditions"
                      onChange={(e) => inputChange(e)}
                    ></textarea>
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="d-flex justify-content-end">
                  <Col lg="2 mt-3 text-end">
                    <button
                      className="btn-blue-sm ps-0 pe-0"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Submit
                    </button>
                  </Col>
                </div>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Cashdeliveryform;
