import React, { useState } from "react";
import ReportSection from "../../components/Food_Repot/ReportSection";
import Suggestion from "../../pages/suggestion";
const FoodsuggestionSection = () => {
  return (
    <>
      <ReportSection title="" />
    </>
  );
};

export default FoodsuggestionSection;
