import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import UploadIcon from "@mui/icons-material/Upload";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { images } from "../../constants";
import "../../pages/User_Chatroom/Live_Streamline/livestreaming.css";
import { useNavigate, useLocation } from "react-router-dom";
const Chattingarea = ({ header, url }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <div className="row mt-2"></div>
      <div className="row">
        <div className="col-6">
          <h5>{header}</h5>
        </div>

        <div className="col-6 text-end mt-3">
          {location.pathname === "/chatroom-streamline" ? (
            <button className=" leavechat-btn bg-dark text-white">Leave Chat</button>
          ) : (
            <button className="leavechat-btn bg-dark text-white" onClick={() => navigate(url)}>
              Go Live
            </button>
          )}
        </div>
      </div>
      <div className="row mt-2 p-4" style={{ background: "#F5FFFE" }}>
        <h6>Welcome to streamline chat</h6>
        <p className="para-font-s mt-2">
          Lorem ipsum dolor sit amet, consectetur adi por incidila ۔bore et
          dolore magna quis nostrud exercitation ullamco.
        </p>
      </div>

      <div className="text-center">
        <p className="chat-font mt-2">Chat will be deleted after 24 hours</p>
      </div>

      <div className="scroll_messages noscroll">
        <div className="row mt-2">
          <div className="col-xl-6 col-lg-6 col-md-6 col-8">
            <div className="row">
              <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                <div className="img__setting">
                  <img src={images.livechat18} />
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-9 col-9 pl-0">
                <p className="chat_bar">Awesome it’s going to be amzing</p>
                <p className="chat_bar mt-2">Hope for the best</p>
                <p className="chat_bar mt-2">Best of Luck</p>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-4"></div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-4"></div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-8">
            <div className="d-flex justify-content-end">
              <button className=" leavechat-btn">Remove</button>
            </div>
            <div className="row mt-2">
              {" "}
              <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                <div className="img__setting">
                  <img src={images.livechat18} />
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-9 col-9 pl-0">
                <p className="chat_bar">
                  Lorem ipsum dolor sit amet, consectetur...
                </p>
                <p className="chat_bar mt-2">Lorem consectetur...</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-8">
            <div className="row">
              <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                <div className="img__setting">
                  <img src={images.livechat18} />
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-9 col-9 pl-0">
                <p className="chat_bar">
                  Lorem ipsum dolor sit amet, consectetur...
                </p>
                <p className="chat_bar mt-2">Lorem consectetur...</p>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-4"></div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-4"></div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-8">
            <div className="row">
              <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                <div className="img__setting">
                  <img src={images.livechat18} />
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-9 col-9 pl-0">
                <p className="chat_bar">
                  Lorem ipsum dolor sit amet, consectetur...
                </p>
                <p className="chat_bar mt-2">Lorem consectetur...</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-8">
            <div className="row">
              <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                <div className="img__setting">
                  <img src={images.livechat18} />
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-9 col-9 pl-0">
                <p className="chat_bar">
                  Lorem ipsum dolor sit amet, consectetur...
                </p>
                <p className="chat_bar mt-2">Lorem consectetur...</p>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-4"></div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-4"></div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-8">
            <div className="row">
              <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                <div className="img__setting">
                  <img src={images.livechat18} />
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-9 col-9 pl-0">
                <p className="chat_bar">
                  Lorem ipsum dolor sit amet, consectetur...
                </p>
                <p className="chat_bar mt-2">Lorem consectetur...</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-8">
            <div className="row">
              <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                <div className="img__setting">
                  <img src={images.livechat18} />
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-9 col-9 pl-0">
                <p className="chat_bar">
                  Lorem ipsum dolor sit amet, consectetur...
                </p>
                <p className="chat_bar mt-2">Lorem consectetur...</p>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-4"></div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-4"></div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-8">
            <div className="row">
              <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                <div className="img__setting">
                  <img src={images.livechat18} />
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-9 col-9 pl-0">
                <p className="chat_bar">
                  Lorem ipsum dolor sit amet, consectetur...
                </p>
                <p className="chat_bar mt-2">Lorem consectetur...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row bg-writemsg_messngr ">
        <div class="col-1 p-2 pr-0 d-flex justify-content-center">
          <div class="left-image text-center">
            <InsertPhotoIcon />
            <input type="file" id="file" style={{ display: "none" }} />
          </div>
        </div>
        <div class="col-1 p-2 pr-0 d-flex justify-content-center">
          <div class="left-image-two text-center">
            <UploadIcon />
            <input type="file" id="file" style={{ display: "none" }} />
          </div>
        </div>
        <div class="col-xl-8 col-lg-7 col-md-7 col-7 p-2">
          <div class="form-group d-flex bg-white">
            <SentimentSatisfiedAltIcon className="mt-1 ms-1 pt-1" />
            <textarea
              class="form-control custom_form_Control"
              id="exampleFormControlTextarea1"
              placeholder="Write Message..."
              rows="1"
            ></textarea>
          </div>
        </div>
        <div class="col-1 p-2 d-flex justify-content-center">
          <div class="left-image-two text-center">
            <KeyboardVoiceIcon />
          </div>
        </div>
        <div class="col-xl-1 col-lg-2 col-md-2 col-2 p-2">
          <button class="border-0 send__btn">Send</button>
        </div>
      </div>
    </>
  );
};

export default Chattingarea;
