import { ShopModelCarousal } from "../../../../constants/jsonConstant";
import { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import RedoIcon from "@mui/icons-material/Redo";
import Deletemodal from "../../../../components/DeleteModal";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import url from "../../../../config/axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { SuccessNotify, FailedNotify } from "../../../../constants/tostify";
import Loader from "../../../../components/Loader";
import ShareModel from "../../../../components/Sharemodel";
import ShareOption from "../../../../components/Shareoption";
import BackArrow from "../../../../components/backArrow";
import { getAsset } from "../../../../helpers/helper";
const Vehicleaddshow = () => {
  const { name } = useSelector((state) => state.user);
  const { id } = useParams();
  const { shopid } = useParams();
  const navigate = useNavigate();
  const handleClose = () => setShows(0);
  const [isloading, setIsloading] = useState(false);
  const [increase, setIncrease] = useState(1);
  const [showmodal, setShowmodal] = useState(false);
  const [showshare, setShowshare] = useState(false);
  const [itemsdata, setItemsData] = useState();
  const [show, setShow] = useState(0);
  const [shows, setShows] = useState(false);

  useEffect(() => {
    const img = document.querySelectorAll('.image-gallery-image');
  
    img.forEach(element => {
      element?.setAttribute('crossorigin', 'anonymous');
    });
  
  }, []);
  
  useEffect(() => {
    const thumimgs = document.querySelectorAll('.image-gallery-thumbnail-image');
  
    thumimgs.forEach(element => {
      element?.setAttribute('crossorigin', 'anonymous');
    });
  }, []);

  const Deleteitem = () => {
    setIsloading(true);
    url
      .delete(`sellvehicle/?id=${id}`)
      .then(async (response) => {
        if (response.data.success) {
          setIsloading(false);
          setShows(0);
          SuccessNotify("Item Deleted Successfully");
          
          navigate(`/used-vehicle-profile/${shopid}`);
        }
      })
      .catch((e) => {
        setIsloading(false);
        FailedNotify("Failed to Delete Try Again!");
      });
  };

  useEffect(() => {
    url.get(`sellvehicle/id/?id=${id}`).then(async (res) => {
      setItemsData(res.data.data.SellVehicle);
    });
  }, []);

  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }


  const handleClosemodal = () => setShowmodal(false);
  const handleCloseshare = () => setShowshare(false);
  const handleShowshare = () => setShowshare(true);

   

const ShopModelCarousal = [
  {
    id: 1,
    original: getAsset(itemsdata?.images[0]),
    thumbnail: getAsset(itemsdata?.images[0]),
    
  

  },
  {
    id: 2,
    original:  getAsset(itemsdata?.images[1]),
    thumbnail:  getAsset(itemsdata?.images[1]),

  },
  {
    id: 3,
    original:  getAsset(itemsdata?.images[2]),
    thumbnail:  getAsset(itemsdata?.images[2]),

  },
  {
    id: 4,
    original:  getAsset(itemsdata?.images[3]),
    thumbnail:  getAsset(itemsdata?.images[3]),
  
  },
  {
    id: 5,
    original:  getAsset(itemsdata?.images[4]),
    thumbnail:  getAsset(itemsdata?.images[4]),

  },
];
  return (
    <>
      <ShareOption
          showshare={showshare}
          handleCloseshare={handleCloseshare}
        />
        <ShareModel
          show={showmodal}
          handleClosemodal={handleClosemodal}
          handleShowshare={handleShowshare}
        />
      {shows === 1 && (
        <Deletemodal
          shows={shows}
          title="Profile"
          handleClose={handleClose}
          animation={true}
          handledelete={Deleteitem}
        />
      )}
      <Container>
        <div className="screenHeight">
          <div className="ps-5">
            <h5>{itemsdata?.createdAt}</h5>
          </div>
          <Row>
            <Col lg="6">
              <div className="row">
              <ImageGallery
                  showPlayButton={false}
                  showFullscreenButton={false}
                  items={ShopModelCarousal}
                 
                
                />
              </div>
            </Col>
            <Col lg="6">
              <div className=" ps-4 pe-4 pt-4 pb-1 bg-vilot View-Model-Shadow ">
                <Row>
                  <Col lg="6" md="6" sm="6" xs="6" className="text-start"><BackArrow /></Col>
                  <Col lg="6" md="6" sm="6" xs="6" className="text-end">
                    <button className="shareItembtn " onClick={() => setShowmodal(true)}>
                      Share <RedoIcon className="share_icon_f" />
                    </button>
                  </Col>
                </Row>
                <Row className="mt-2 font-sm">
                  <Col lg="3">
                    {" "}
                    <label for="exampleInputEmail1" class="form-label">
                      Model
                    </label>
                    <p className="p-2 border_3 mb-0">{itemsdata?.model}</p>
                  </Col>
                  <Col lg="3">
                    <label for="exampleInputEmail1" class="form-label">
                      Registered In
                    </label>
                    <p className="p-2 border_3">
                      {moment(itemsdata?.registeredIn).format("DD-MMMM-YYYY")}
                    </p>
                  </Col>{" "}
                  <Col lg="3">
                    <label for="exampleInputEmail1" class="form-label">
                      Mileage * (km)
                    </label>
                    <p className="p-2 border_3">{itemsdata?.mileage}</p>
                  </Col>
                  <Col lg="3">
                    {" "}
                    <label for="exampleInputEmail1" class="form-label">
                      Assembly
                    </label>
                    <p className="p-2 border_3 mb-0">{itemsdata?.assembly}</p>
                  </Col>
                </Row>
                <Row className="mt-2 font-sm">
                  <Col lg="3">
                    <label for="exampleInputEmail1" class="form-label">
                      Version
                    </label>
                    <p className="p-2 border_3">{itemsdata?.version}</p>
                  </Col>{" "}
                  <Col lg="3">
                    <label for="exampleInputEmail1" class="form-label">
                      Make
                    </label>
                    <p className="p-2 border_3">{itemsdata?.make}</p>
                  </Col>
                  <Col lg="3">
                    {" "}
                    <label for="exampleInputEmail1" class="form-label">
                      Engine Capacity * (cc)
                    </label>
                    <p className="p-2 border_3 mb-0">
                      {itemsdata?.engineCapactiy}
                    </p>
                  </Col>
                  <Col lg="3">
                    <label for="exampleInputEmail1" class="form-label">
                      Color
                    </label>
                    <p className="p-2 border_3">{itemsdata?.color}</p>
                  </Col>
                </Row>{" "}
                <Row className="mt-2 font-sm">
                  <Col lg="3">
                    <label for="exampleInputEmail1" class="form-label">
                      Transmission
                    </label>
                    <p className="p-2 border_3">{itemsdata?.transmission}</p>
                  </Col>
                  <Col lg="3">
                    {" "}
                    <label for="exampleInputEmail1" class="form-label">
                      City
                    </label>
                    <p className="p-2 border_3 mb-0">{itemsdata?.city}</p>
                  </Col>
                  <Col lg="3">
                    <label for="exampleInputEmail1" class="form-label">
                      City Area
                    </label>
                    <p className="p-2 border_3">{itemsdata?.cityArea}</p>
                  </Col>{" "}
                  <Col lg="3">
                    <label for="exampleInputEmail1" class="form-label">
                      Engine Type
                    </label>
                    <p className="p-2 border_3">{itemsdata?.engineType}</p>
                  </Col>
                </Row>
                <p className="col-11 fw-lighter">{itemsdata?.description}</p>
                <div>
                  <h3 className="font-blue">{itemsdata?.price}</h3>
                </div>
                <div>
                  <p className="mb-0">Contact us on</p>
                  <p className="mb-0 pt-2 pb-2">{name.email}</p>
                  <p className="mb-0">{name.phonenumber}</p>
                </div>
                <div className="bg-white quantityBox mt-2 mb-2  pe-2 ps-2 w-25 d-flex align-items-center ">
                  <div>
                    <p className="mb-0 pe-1">QTY:</p>
                  </div>
                  <div>
                    <p className="mb-0 pe-1">{increase}</p>
                  </div>
                  <div>
                    <div className="d-flex flex-column align-items-center ">
                      <ExpandLessIcon
                        fontSize="small"
                        onClick={() => setIncrease(increase + 1)}
                      />
                      <ExpandMoreIcon
                        fontSize="small"
                        onClick={() => setIncrease(increase - 1)}
                      />
                    </div>
                  </div>
                </div>
                <div className="pt-4 d-flex pb-3">
                  <button
                    className="d-flex align-items-center border rounded ps-3 pe-3 pt-1 pb-1 bg-red text-white"
                    onClick={() => setShows(1)}
                  >
                    <DeleteOutlineOutlinedIcon />
                    <span className="ps-1">Delete</span>
                  </button>
                  <button
                    className="d-flex align-items-center border rounded ps-4 pe-4 pt-1 pb-1 bg-blue ms-3 me-3 text-white"
                    onClick={() =>
                      navigate(`/update-use-vehicle/${itemsdata?._id}`)
                    }
                  >
                    <CreateOutlinedIcon />
                    <span className="ps-1">Edit</span>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Vehicleaddshow;
