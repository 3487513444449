import React from 'react'
import { Container } from 'react-bootstrap'
import Shopintegrate from '../../components/shop_integrate'

const Restaurantintegrate = () => {
  return (
    <>
    <Container>
      <div className="screenHeight">
        <Shopintegrate
          newshop="/restaurant-shop-form"
          url="/restaurant-integrate-form"
        />
      </div>
    </Container>
  </>
  )
}

export default Restaurantintegrate