
import "../Live_Streamline/livestreaming.css";
import { Helmet } from "react-helmet";
import UserChatroomview from "../../../components/chatroomview";
import Livechatinvite from "../../../components/Livechat_invite";
import { useRef } from "react";
import { useState } from "react";
import Chattingarea from "../../../components/Chatting_area";

const Lobbychatroom = () => {
  const [openAddMembers, setAddMembers] = useState(false);
  const inputfile = useRef(null);
  const upload_img = () => {
    inputfile.current.click();
  };

  return (
    <div>
      <Helmet>
        <body style="background:#FFFFF"></body>
      </Helmet>
      <div className="container ">
        <div className="screenHeight col-11 mx-auto">
          <div className="row  pt-2">
            <div className="col-4">
              <UserChatroomview setAddMembers={setAddMembers} title="Technology" />
            </div>
            <div className="col-8 mx-auto streamline--container mt-2">
              {openAddMembers === true && (
              
                  <Livechatinvite setAddMembers={setAddMembers} />
             
              )}
              <Chattingarea btntext="Group Chat" header="Technology" url="/user-live-chatroom"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lobbychatroom;
