import Carousel from "react-bootstrap/Carousel";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import "./vehicleDetail.css";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { Deletemodal } from "../../pages/index";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import url from "../../config/axios";
import Loader from "../Loader";
import { SuccessNotify } from "../../constants/tostify";
import { FailedNotify } from "../../constants/tostify";
import { getAsset } from "../../helpers/helper";
const Vehicaldetail = (props) => {
  const { id, shopid } = useParams();
  const [show, setShow] = useState(0);
  const handleShow = () => setShow(1);
  const [isloading, setIsloading] = useState(false);
  const handleClose = () => setShow(0);
  const [singleCar, setSingleCar] = useState();
  useEffect(() => {
    url.get(`/tranportvehicle/id/?id=${id}`).then(async (res) => {
      setSingleCar(res?.data?.data?.TransportVehicle);
    });
  }, []);
  const handeldelete = () => {
    setIsloading(true);
    url
      .delete(`tranportvehicle/?id=${id}`)
      .then((res) => {
        if (res.data.success) {
          setIsloading(false);

          setShow(0);
          SuccessNotify("Successfully Deleted");
          navigate(`/transport-profile/${shopid}`);
        }
      })
      .catch(() => {
        FailedNotify("Failed To Deleted");
      });
  };

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <Container>
        {show === 1 && (
          <Deletemodal
            id={id}
            show={show}
            title="Profile"
            handleClose={handleClose}
            handledelete={handeldelete}
          />
        )}
        {isloading ? (
          <Loader heightsetting="screenHeight" />
        ) : (
          <div className={`${props.heightclass} noscroll`}>
            <Row>
              <Col lg="10 mx-auto" className="p-4">
                <Row>
                  <Col
                    sm={12}
                    md={9}
                    lg={12}
                    xl={12}
                    className="pe-0 ps-0 mt-2"
                  >
                    <div className=" ps-3 pe-3 pb-2 ">
                      <Carousel>
                        {singleCar?.map((item, index) => (
                          <Carousel.Item className="img-items text-center" key={index}>
                            <img
                              className="car_img_w"
                              src={getAsset(item?.images)}
                              alt="First slide"
                              crossOrigin="anonymous"
                            />
                            <Carousel.Caption></Carousel.Caption>
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    </div>
                  </Col>
                </Row>
                <h3 className="font-bold">Vehicle Name and Model</h3>
                <p className="font-grey">
                  {singleCar?.brandName} ({singleCar?.model})
                </p>
                <h5>Description</h5>
                <p className="font-grey">{singleCar?.description}</p>
                <Row>
                  <Col lg="3">
                    <p className="mb-0">
                      <b>Number Plate</b>
                    </p>
                    <p className="font-grey">{singleCar?.noPlate}</p>
                  </Col>
                  <Col lg="3">
                    <p className="mb-0">
                      <b>Vehicle Colour</b>
                    </p>
                    <p className="font-grey">{singleCar?.color}</p>
                  </Col>
                  <Col lg="6">
                    <h5>Contact us on</h5>
                    <p className="font-grey mb-0">james@gmail.com</p>
                    <p className="font-grey">{singleCar?.contact}</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" className="text-end">
                    {location.pathname === "/find-Transport" ? (
                      ""
                    ) : (
                      <Col lg="12">
                        <button
                          className="b-radius  text-danger border-0 ps-3 pe-3 pt-1 pb-1 bg-white btn-outline-1"
                          onClick={() =>
                            navigate(`/transport-profile/${singleCar?.shopid}`)
                          }
                        >
                          Cancle
                        </button>
                        <button
                          className="b-radius bg-danger text-white border-0 ms-2 ps-3 pe-3 pt-1 pb-1"
                          onClick={() => setShow(1)}
                        >
                          <DeleteIcon className="icont_f" />
                          Delete
                        </button>
                        &nbsp;
                        <button
                          className="b-radius bg-primery text-white bg-btn-primary bg-app-btn border-0 ps-3 pe-3 pt-1 pb-1"
                          onClick={() => navigate("/update-vehicle/" + id)}
                        >
                          <ModeEditOutlineIcon className="icont_f" />
                          Edit
                        </button>
                      </Col>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </>
  );
};
export default Vehicaldetail;
