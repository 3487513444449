import { Container } from "react-bootstrap"
import IntegrateForm from "../../components/Integrate-form"
const PropertyIntegrateform=()=>{

return(<>

<Container>
    <div className="screenHeight">
<IntegrateForm backurl="/property-integrate" />
    </div>
</Container>

</>)


}


export default PropertyIntegrateform