import { Col, Form } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

import Changeshopname from "../../pages/Main_setting/components/Change_shopname";

import RequestDeleteshop from "../../pages/Main_setting/components/Request_to_deleteshop";
const CompanyAccordian = ({ three, five }) => {
  return (
    <Col>
      <Accordion>
        <Accordion.Item eventKey="3" className="mb-2">
          <Accordion.Header className="accordion-border">
            <p className="font-blue-text m-0">{three}</p>
          </Accordion.Header>
          <Accordion.Body className="accordion-body-open noscroll">
            <Changeshopname />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5" className="mb-2">
          <Accordion.Header className="accordion-border">
            <p className="font-blue-text m-0">{five}</p>
          </Accordion.Header>
          <Accordion.Body className="accordion-body-open noscroll">
            <RequestDeleteshop />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Col>
  );
};
export default CompanyAccordian;
