import { Button, Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Pagination from "react-bootstrap/Pagination";
import "./candidatesBox.css";
import { useLocation } from "react-router-dom";
import Url from "../../config/axios";

const CandidatesBox = ({ pendingData }) => {
  const location = useLocation();

  const handleConnect = async (
    senderId,
    senderShopId,
    receiverId,
    receiverShopId,
    businessType
  ) => {
    try {
      const response = await Url.post("conection/acceptrequestcoonect/", {
        senderid: senderId,
        sendershopid: senderShopId,
        recieverid: receiverId,
        recievershopid: receiverShopId,
        businessType: businessType, // replace this with actual data
      });

      console.log("Connection successful:", response.data);
      // Handle success - e.g., show a success message or update UI
    } catch (error) {
      console.error("Error connecting:", error);
      // Handle error - e.g., show an error message
    }
  };

  const handleReject = async (
    senderId,
    senderShopId,
    receiverId,
    receiverShopId,
    businessType
  ) => {
    try {
      const response = await Url.post("conection/cancelrequestcoonect/", {
        senderid: senderId,
        sendershopid: senderShopId,
        recieverid: receiverId,
        recievershopid: receiverShopId,
        businessType: businessType, // replace this with actual data
      });

      console.log("Connection successful:", response.data);
      // Handle success - e.g., show a success message or update UI
    } catch (error) {
      console.error("Error connecting:", error);
      // Handle error - e.g., show an error message
    }
  };
  return (
    <Row className="px-3">
      <div
        className={
          location.pathname === "/RestaurantAlldata"
            ? ""
            : `noscroll manage-height`
        }
      >
        <Row>
          {pendingData?.pending?.map((box) => (
            <Col md={3} lg={3} className="px-md-1 pb-1" key={box.id}>
              <Card className="border">
                <div className="py-1 d-flex flex-column align-items-center">
                  <div className="candidateImg">
                    <img
                      src={box.sendershopimage}
                      alt={box.name}
                      className="h-100 w-100"
                    />
                  </div>
                  <div>
                    <h6 className="candidateName mb-0">{box.sendershopname}</h6>
                  </div>
                  <div>
                    <p className="candidateOccupation mb-0">
                      {box.businessType}
                    </p>
                  </div>
                  <div className="d-flex justify-content-around">
                    <button
                      className="connect-btn mt-1 ps-3 pe-3 me-2"
                      onClick={() =>
                        handleConnect(
                          box.senderid,
                          box.sendershopid,
                          box.recieverid,
                          box.recievershopid,
                          box.businessType
                        )
                      }
                    >
                      Connect
                    </button>
                    <button className="connect-btn-one mt-1 ps-3 pe-3" 
                     onClick={() =>
                      handleReject(
                        box.senderid,
                        box.sendershopid,
                        box.recieverid,
                        box.recievershopid,
                        box.businessType
                      )
                    }
                    >
                      Ignore
                    </button>
                  </div>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>

      {/* Pagination */}
      {pendingData?.length > 0 && (
        <Col className="pt-2 mx-auto d-flex justify-content-center mt-5">
          <Pagination>
            <Pagination.First />
            <Pagination.Prev />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Ellipsis />
            <Pagination.Item>{10}</Pagination.Item>
            <Pagination.Item>{11}</Pagination.Item>
            <Pagination.Item active>{12}</Pagination.Item>
            <Pagination.Item>{13}</Pagination.Item>
            <Pagination.Item disabled>{14}</Pagination.Item>
            <Pagination.Ellipsis />
            <Pagination.Item>{20}</Pagination.Item>
            <Pagination.Next />
            <Pagination.Last />
          </Pagination>
        </Col>
      )}
    </Row>
  );
};

export default CandidatesBox;
