import { Row, Col, Card, Button } from "react-bootstrap";
import Leftmenubar from "../Components/Left_menubar";
import BackArrow from "../../../components/backArrow";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RedoIcon from "@mui/icons-material/Redo";
import url from "../../../config/axios";
import { useSelector } from "react-redux";
import Nodata from "../../../components/Nodata";
import { getAsset } from "../../../helpers/helper";
import Loader from "../../../components/Loader";
import ShareModel from "../../../components/Sharemodel";
import ShareOption from "../../../components/Shareoption";

const WholesalerShop = () => {
  const { name } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const [wholesalerShop, setWholesalerShop] = useState();
  const [showshare, setShowshare] = useState(0);
  const [userimg, setUserimg] = useState();
  const [show, setShow] = useState(0);
  const handleCloseshare = () => {
    setShowshare(0);
    setShow(1);
  };

  const handleClosemodal = () => {
    setShow(0);
  };
//jjj
  const handleShowshare = () => {
    setShow(0);
    setShowshare(1);
  };
  useEffect(() => {
    setIsloading(true);
    url
      .get(`shop/user/?userid=${name._id}&businessType=wholesellerShop`)
      .then(async (res) => {
        if (res.data.success) {
          setIsloading(false);
          setWholesalerShop(res?.data?.data?.Shops);
          localStorage.setItem("mycategory", (res?.data?.data?.Shops[0]?.shopCategory?.name));
        }
      });
  }, []);

  useEffect(() => {
    url.get(`/user/id?id=${name?._id}`).then((response) => {
      setUserimg(response?.data?.data?.User?.imageUrl);
    });
  }, [])
  return (
    <Row>
      <ShareOption showshare={showshare} handleCloseshare={handleCloseshare} />
      <ShareModel handleClosemodal={handleClosemodal} show={show} handleShowshare={handleShowshare} />
      <Col lg={3} md={3} >
        <Leftmenubar userimg={userimg} />
      </Col>
      <Col lg={8} md={8} className="screenHeight b-radius border_2 ms-lg-3 ms-md-3 col-12">
        <Row>
          <Col xl="4" lg="3" md="3" sm="3" xs="6" >
            <div className="ms-4 ms-sm-0 pt-4 pb-2 text-sm-center text-md-start">
              <BackArrow back="/SellingSide" />
            </div>
          </Col>
          <Col xl="4" lg="5" md="6" sm="6" xs="6">
            <div className="text-sm-center ">
              <h5 className="font-primary mt-4 res-manage-btn">Manage Your Shops Here</h5>
            </div>
          </Col>
          <Col lg="4" md="3" sm="3" xs="5" className=" text-sm-center text-lg-end text-md-end">
            <div className="mt-4 ps-lg-4 ps-md-4 p-xs-2">
              <Button className="createbtt ms-5 ms-sm-0" onClick={() =>
                navigate("/wholeseller-form")
              } >Create New</Button>
            </div>
          </Col>
        </Row>
        {isloading ? (
          <Loader heightsetting="loaderHeight" />
        ) : (
          <Row className="scroll-shops-business noscroll justify-content-center justify-content-sm-around justify-content-md-start">
            {wholesalerShop && wholesalerShop.length > 0 ? (
              wholesalerShop.map((e) => (
                <Col lg="4" xl="3" md="6" sm="5" xs="11" className="mt-1">
                  <Card className="ShopsCards_business">
                    <div className="fix-img">
                      <img className="c_img" src={getAsset(e?.image)} alt="" crossOrigin="anonymous" />
                    </div>
                    <Card.Body className="p-2">
                      <Card.Title className="font-sm-15 mb-0 pb-1">
                        <div className="d-flex justify-content-between">
                          {e?.shopname.length >= 7 ? e?.shopname.substring(0, 7) + `...` : e?.shopname}

                          <button className="text-white bg-blue share_font" onClick={() => setShow(1)}>
                            Share <RedoIcon className="share_icon_f" />
                          </button>
                        </div>
                      </Card.Title>
                      <Card.Text className="font-sm font-primary mb-2">
                        {e?.shopaddress.length >= 12 ? e?.shopaddress.substring(0, 12) + `...` : e?.shopaddress}
                      </Card.Text>
                      <button
                        className="viewshop w-100 text-white bg-blue"
                        onClick={() =>
                          navigate(`/wholsaler-bussines-Profile/${e._id}`)
                        }
                      >
                        Manage Your Shop
                      </button>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <div>
                <Nodata />
              </div>
            )}
          </Row>
        )}
      </Col>
    </Row>

  );
};

export default WholesalerShop;
