import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Form from "../../../../components/forms/index";
import { FailedNotify, SuccessNotify } from "../../../../constants/tostify";
import url from "../../../../config/axios";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
import { publishImage } from "../../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
const ManufacturerForm = () => {
  const navigate = useNavigate();
  const [displayimg, setDisplayimg] = useState();
  const { name } = useSelector((state) => state.user);
  const [shopCategoryone, setShopCategoryone] = useState();
  const [isloading, setIsloading] = useState(false);
  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setCreateShop((createShop) => ({
        ...createShop,
        image: abc
      }));

      setDisplayimg(abc);

    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });


  const [selectshop, setSelectshop] = useState({
    shopCategory: ""
  });
  const Shopdatas = (e) => {
    setSelectshop((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split("."),
    }));
  };

  const categoryName = selectshop?.shopCategory[1];
  const categoryid = selectshop?.shopCategory[0];

  localStorage.setItem('mycategory', categoryName);

  const [createShop, setCreateShop] = useState({
    userid: name._id,
    shopname: "",
    shopaddress: "",
    shoplicense: "",
    contact: "",
    image: [""],
    isFoodShop: false,
    marketPlace: "",
    shopCategory: "",
    businessType: "manufacturerShop",
    description: "",
    status: true,
  });

  useEffect(() => {
    setCreateShop((createShop) => ({
      ...createShop,
      shopCategory: categoryid
    }
    ))
  }, [categoryid]);

  useEffect(() => {
    url
      .get("category/type/?type=manufacturerShop&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategoryone(response?.data?.data?.Category);
        }
      })
      .catch((error) => { });
  }, []);
  const PostShopData = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("shop/", createShop)
      .then(async (response) => {
        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Form manufacturer Created Successfully");
            navigate(
              `/manufacturer-bussines-Profile/${response?.data?.data?._id}`
            );
          }, 1000);
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed To Create Shop");
      });
  };
  if (isloading) {
    return (
      <div>
        <Loader heightsetting="screenHeight" />
      </div>
    );
  }
  return (
    <Form
      title="Fill to Create Manufacturer Shop"
      butn="Create"
      back="/manufacturer-integrate"
      CreateHandler={PostShopData}
      setCreateShop={setCreateShop}
      createShop={createShop}
      shopCategoryone={shopCategoryone}
      getInputProps={getInputProps}
      getRootProps={getRootProps}
      displayimg={displayimg}
      setDisplayimg={setDisplayimg}
      Shopdatas={Shopdatas}
    />
  );
};

export default ManufacturerForm;
