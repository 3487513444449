import React from "react";
import BackArrow from "../../../../components/backArrow";
import { Button, Col, Container, Row } from "react-bootstrap";
import { images } from "../../../../constants";
import ClearIcon from "@mui/icons-material/Clear";
import Card from "react-bootstrap/Card";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FailedNotify, SuccessNotify } from "../../../../constants/tostify";
import url from "../../../../config/axios";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
import { useParams } from "react-router-dom";
import { publishImage } from "../../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
import { getAsset } from "../../../../helpers/helper";
const RestaurantshopUpdateForm = ({ back }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [isloading, setIsloading] = useState();
  const { name } = useSelector((state) => state.user);
  const [displayimg, setDisplayimg] = useState();
  const removeImage = (index) => {
    const updatedImages = [...displayimg];
    updatedImages.splice(index, 1);
    setDisplayimg(updatedImages);
  };

  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setCreateShop((createShop) => ({
        ...createShop,
        image: abc,
      }));

      setDisplayimg(abc);
    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });
  const [createShop, setCreateShop] = useState({
    userid: name._id,
    shopname: "",
    shopaddress: "",
    shoplicense: "",
    image: [""],
    marketPlace: "",
    isFoodShop: true,
    description: "",
    status: true,
  });

  useEffect(() => {
    url.get(`shop/id?id=${id}`).then(async (res) => {
      setCreateShop(res?.data?.data?.Shop);
    });
  }, []);

  const UpdateShopData = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .put(`shop/id?id=${id}`, createShop)
      .then(async (response) => {
        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Form Updated  Successfully");
            navigate(`/RestaurantAlldata/${id}`);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed To Create Shop");
      });
  };

  const handleValue = (e) => {
    setCreateShop((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }

  return (
    <div>
      <Container>
        <form>
          <div className="screenHeight d-flex justify-content-center align-items-center">
            <div className="Form-Shadow  col-lg-6 col-md-8 col-sm-12 col-xs-12 ">
              <div className="arrowback ">
                <BackArrow
                  back="/restaurant-integrate"
                  black="black"
                  mystyle="p-1 ps-4"
                />
              </div>
              <div className="ps-0 pe-0 ps-sm-1 pe-sm-1 ps-md-2 pe-md-2 ps-lg-3 pe-lg-3 pt-1">
                <div>
                  <h4 className="createHeading ps-3 pt-3 ">
                    Fill To create shop profile
                  </h4>
                </div>

                <div className="d-flex flex-column ps-4 pe-4 mt-3 ">
                  <label className="custm-lble-2 pb-2">Shop Name</label>
                  <input
                    name="shopname"
                    disabled
                    className="input-style"
                    placeholder="Name"
                    onChange={(e) => handleValue(e)}
                    defaultValue={createShop?.shopname}
                  />
                </div>
                {/* Inputs  */}
                <div className="d-flex justify-content-between pt-1">
                  <div className="d-flex flex-column pt-3 col-6 ps-4 pe-3">
                    <label className="custm-lble-2 pb-2">Market Place</label>
                    <input
                      name="marketPlace"
                      className="input-style"
                      placeholder="Market Place"
                      onChange={(e) => handleValue(e)}
                      defaultValue={createShop?.marketPlace}
                    />
                  </div>

                  <div className="d-flex flex-column pt-3 col-6 pe-4">
                    <label className="custm-lble-2 pb-2">
                      CNIC or Licence Number
                    </label>
                    <input
                      name="shoplicense"
                      disabled
                      className="input-style"
                      placeholder="Licence Number/CNIC"
                      onChange={(e) => handleValue(e)}
                      defaultValue={createShop?.shoplicense}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column ps-4 pe-4 mt-3 ">
                  <label className="custm-lble-2 pb-2">Shop Address</label>
                  <input
                    name="shopaddress"
                    className="input-style"
                    placeholder="Add Address with maps"
                    onChange={(e) => handleValue(e)}
                    defaultValue={createShop?.shopaddress}
                  />
                  <div className="position-relative">
                    <img className="iMg" src={images.FindClinic} />
                  </div>
                </div>

                {/* Description */}
                <div>
                  <div className="d-flex flex-column pt-2 ps-4 pe-4 mt-3">
                    <label className="custm-lble-2 pb-2">Description</label>
                    <textarea
                      onChange={(e) => handleValue(e)}
                      placeholder="Description"
                      className="input-style pt-1 ps-2 pb-5 pe-2 "
                      name="description"
                      defaultValue={createShop?.description}
                    />
                  </div>
                </div>

                <Col lg="9" className="me-auto">
                  <Row>
                    <Col lg={3} className="mt-2 ms-4">
                      <Card
                        style={{ width: "6rem", height: "6rem" }}
                        {...getRootProps()}
                        className="dropzone"
                      >
                        <Card.Img className="imet-fit" src={images.AddImage} />
                        <Card.Body>
                          <input
                            type="file"
                            id="file"
                            style={{ display: "none" }}
                            {...getInputProps()}
                            multiple
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                    {displayimg?.length > 0 && (
                      <Col lg={7} className=" mt-1 d-flex">
                        {displayimg.map((image, index) => (
                          <Card
                            className="me-2 mt-1"
                            style={{
                              width: "6rem",
                              height: "6rem",
                              border: "2px solid",
                            }}
                          >
                            <Card.Img
                              className="imet-fit upload-img mt-0"
                              src={getAsset(image)}
                              alt=""
                              crossOrigin="anonymous"
                            />
                            <div
                              className="close-btnone"
                              onClick={() => removeImage(index)}
                            >
                              <ClearIcon />
                            </div>
                          </Card>
                        ))}
                      </Col>
                    )}
                  </Row>
                </Col>

                <div className="btns pe-4 pb-4">
                  <button
                    className="btn1"
                    onClick={() => navigate("/SellingSide")}
                  >
                    Cancel
                  </button>
                  <Button
                    onClick={UpdateShopData}
                    className="btn2 disable-btn p-1"
                    variant="primary"
                  >
                    Update
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default RestaurantshopUpdateForm;
