import React from "react";
import { images } from "../../../../constants";

const Requestcall = () => {
  return (
    <div>
      <div className="input-styleone">
        <div className=" d-flex justify-content-center align-items-center mb-2">
          <img className="img_four-div" variant="top" src={images.img_three} />
        </div>
        <div className="d-flex justify-content-center fw-bold">
          <h3 className="cht-online">Call selectezi support</h3>
        </div>
        <div className="d-flex justify-content-center fw-bold">
          <p className="m-0 phr">
            Fill out the contact form with your <br /> information, and we’ll
            get back to <br />
            <span className="d-flex justify-content-center">you shortly.</span>
          </p>
        </div>
        <div className="d-flex justify-content-center fw-bold fs-4 link-primary mt-4">
          <p>Call Now</p>
        </div>
      </div>
    </div>
  );
};

export default Requestcall;
