import { ShopModelCarousal } from "../../constants/jsonConstant";
import { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import Modal from "react-bootstrap/Modal";
import { images } from "../../constants";
import "./shopViewModel.css";
import { Row, Col, Tabs, Tab, Card } from "react-bootstrap";
import Rating from "@mui/material/Rating";
import { RatingStar } from "../../constants/jsonConstant";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAsset } from "../../helpers/helper";
import MessageIcon from '@mui/icons-material/Message';
import moment from "moment";
const UserbuyShopViewModel = ({ show, handleClosetwo, handleShowModal, shopview }) => {
  const { name } = useSelector((state) => state.user);
  const [increase, setIncrease] = useState(1);
  const [value] = useState(5);
  const [valueone] = useState(4);
  const [valuetwo] = useState(3);
  const [valuethree] = useState(2);
  const [valuefour] = useState(1);
  const location = useLocation();

  useEffect(() => {
    if (show) {
      const imgs = document.querySelectorAll('.image-gallery-image');
      imgs.forEach((element) => {
        element.setAttribute('crossorigin', 'anonymous');
      });
    }
  }, [show]);

  useEffect(() => {

    if (show) {
      const thumimgs = document.querySelectorAll('.image-gallery-thumbnail-image');

      thumimgs.forEach(element => {
        element?.setAttribute('crossorigin', 'anonymous');
      });
    }

  }, [show]);

  const ShopModelCarousal = [
    {
      id: 1,
      original: getAsset(shopview?.images ? shopview?.images[0] : shopview?.image[0]),
      thumbnail: getAsset(shopview?.images ? shopview?.images[0] : shopview?.image[0]),
    },
    {
      id: 2,
      original: getAsset(shopview?.images ? shopview?.images[1] : shopview?.image[1]),
      thumbnail: getAsset(shopview?.images ? shopview?.images[1] : shopview?.image[1]),

    },
    {
      id: 3,
      original: getAsset(shopview?.images ? shopview?.images[2] : shopview?.image[2]),
      thumbnail: getAsset(shopview?.images ? shopview?.images[2] : shopview?.image[2]),

    },
    {
      id: 4,
      original: getAsset(shopview?.images ? shopview?.images[3] : shopview?.image[3]),
      thumbnail: getAsset(shopview?.images ? shopview?.images[3] : shopview?.image[3]),

    },
    {
      id: 5,
      original: getAsset(shopview?.images ? shopview?.images[4] : shopview?.image[4]),
      thumbnail: getAsset(shopview?.images ? shopview?.images[4] : shopview?.image[4]),

    },
  ];
  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClosetwo}
      >
        <Modal.Body className=" pt-3  pb-3">
          <div>
            <p>Posted At : {moment(shopview?.createdAt).format("DD-MM-YYYY")}</p>
          </div>
          <Row>
            <Col lg="6">
              <div className="row ">
                <ImageGallery
                  showPlayButton={false}
                  showFullscreenButton={false}
                  items={ShopModelCarousal}
                />
              </div>
            </Col>
            <Col lg="6">
              <Tabs
                defaultActiveKey="Item"
                id="fill-tab-example"
                className="mb-3"
                fill
              >
                <Tab title="Item" eventKey="Item">
                  <div className=" ps-4 pe-4 pt-4 pb-1 bg-vilot View-Model-Shadow ">
                    <h4 className="fw-bold">{shopview?.itemname || shopview?.subCategory}</h4>
                    <p className="col-10 fw-lighter">
                      {shopview?.description}
                    </p>

                    <div>
                      <p className="mb-0">Price:</p>
                      <h3 className="font-blue">{shopview?.currency.slice(0, 3)}&nbsp;{shopview?.price}</h3>
                    </div>
                    <div>
                      <p className="mb-0">Contact us on</p>
                      <p className="mb-0 pt-2 pb-2">{name?.email}</p>
                      <p className="mb-0">{name?.phonenumber}</p>
                    </div>

                    {/* {location.pathname === "/wholeseller-userprofile" ||
                      location.pathname === "/manufacturer-userprofile" ||
                      location.pathname === "/rawmaterial-userprofile" ? (
                      ""
                    ) : (
                      <div className="bg-white quantityBox mt-2 mb-2  pe-2 ps-2 w-25 d-flex align-items-center ">
                        <div>
                          <p className="mb-0 pe-1">QTY:</p>
                        </div>
                        <div>
                          <p className="mb-0 pe-1">{increase}</p>
                        </div>

                        <div>
                          <div className="d-flex flex-column align-items-center ">
                            <ExpandLessIcon
                              fontSize="small"
                              onClick={() => setIncrease(increase + 1)}
                            />
                            <ExpandMoreIcon
                              fontSize="small"
                              onClick={() => setIncrease(increase - 1)}
                            />
                          </div>
                        </div>
                      </div>
                    )} */}

                    <div className="pt-3 cursor">
                      {location.pathname === "/wholeseller-userprofile" ||
                        location.pathname === "/manufacturer-userprofile" ||
                        location.pathname === "/rawmaterial-userprofile" ? (
                        ""
                      ) : (
                        <button
                          className=" d-flex align-items-center justify-content-center mb-0 border rounded ps-4 pe-4 pt-1 pb-1 bg-blue ms-3 me-3 w-75 text-white"
                          // onClick={handleShowModal}
                        >
                          <MessageIcon fontSize="small" className="mt-1" />
                          <span className="ps-2">Send Message</span>
                        </button>
                      )}
                      {location.pathname === "/wholeseller-userprofile" ||
                        location.pathname === "/manufacturer-userprofile" ||
                        location.pathname === "/rawmaterial-userprofile" ? (
                        <button className=" d-flex align-items-center justify-content-center mb-0 border rounded ps-4 pe-4 pt-1 pb-1 bg-blue ms-3 me-3 w-75 text-white">
                        <MessageIcon fontSize="small" className="mt-1" />
                        <span className="ps-2">Send Message</span>
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Tab>
                <Tab
                  className="shadow-none  bg-light rounded"
                  eventKey="Reviews"
                  title="Reviews"
                >
                  <Row>
                    <Col lg={6}>
                      <div className="mt-3">
                        <h4 className="mb-4">4.4/5</h4>

                        <Rating
                          className="mb-3"
                          name="read-only"
                          value={value}
                          readOnly
                        />
                        <p>7 Ratings</p>
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div className="mt-3 mb-2">
                        <div className="rating-stars  d-flex ">
                          <Rating name="read-only" value={value} readOnly />
                          <h6>6</h6>
                        </div>
                        <div className="rating-stars  d-flex ">
                          <Rating name="read-only" value={valueone} readOnly />
                          <h6>0</h6>
                        </div>
                        <div className="rating-stars  d-flex ">
                          <Rating name="read-only" value={valuetwo} readOnly />
                          <h6>0</h6>
                        </div>
                        <div className="rating-stars  d-flex ">
                          <Rating
                            name="read-only"
                            value={valuethree}
                            readOnly
                          />
                          <h6>0</h6>
                        </div>
                        <div className="rating-stars  d-flex ">
                          <Rating name="read-only" value={valuefour} readOnly />
                          <h6>1</h6>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="card-scrolll">
                    {RatingStar.map((e) => (
                      <div>
                        <img src={e.imgstarss} alt="" />
                        <h6 className="pt-1">
                          BY A...
                          <img src={e.Phera2} alt="" /> Verified purchase
                        </h6>

                        <Card style={{ height: "13rem" }}>
                          <Card.Body>
                            <h6 className="phgh">{e.Phera} </h6>
                            <img src={e.image} alt="" className="pe-2" />
                            <img src={e.images} alt="" />
                          </Card.Body>
                        </Card>
                      </div>
                    ))}
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserbuyShopViewModel;
