import { Container, Button, Modal, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import VideocamIcon from "@mui/icons-material/Videocam";
import PersonalImage from "../../../../../helpers/fileUpload";
import Card from "react-bootstrap/Card";
import { publishImage } from "../../../../../helpers/imageupload";
import { SuccessNotify, FailedNotify } from "../../../../../constants/tostify";
import { useDropzone } from "react-dropzone";

const Photovideo = ({ show, handleClose, CreatePostSubmit, createPost, setCreatePost }) => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [disabled, setDisabled] = useState(true);
  
  const handleValue = (e) => {
    const { name, value } = e.target;
    setCreatePost((prevState) => ({
      ...prevState,
      my: prevState?.my?.map((item) => ({
        ...item,
        [name]: value,
      })),
    }));
  };

  useEffect(() => {
    // Check if both 'heading' and 'content' are filled
    const headingFilled = createPost?.my?.some((item) => item.heading && item.heading.trim() !== "");
    const contentFilled = createPost?.my?.some((item) => item.content && item.content.trim() !== "");

    if (headingFilled && contentFilled) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [createPost]);

  const onDropport = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setCreatePost((prevState) => ({
        ...prevState,
        my: prevState?.my?.map((item) => ({
          ...item,
          img: abc,
        })),
      }));
    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  };

  const onDropvideo = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setCreatePost((prevState) => ({
        ...prevState,
        my: prevState?.my?.map((item) => ({
          ...item,
          video: abc,
        })),
      }));
    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  };

  const { getRootProps: getRootPropsPort, getInputProps: getInputPropsPort } = useDropzone({
    accept: "image/*",
    onDrop: onDropport,
  });

  const { getRootProps: getRootPropsVideo, getInputProps: getInputPropsVideo } = useDropzone({
    accept: "video/*",
    onDrop: onDropvideo,
  });

  return (
    <>
      {selectedImage && <PersonalImage image={selectedImage} />}
      <Container>
        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton className="font-sm">
            <Modal.Title>
              <h6 className="m-0">Photos/Videos</h6>
            </Modal.Title>
          </Modal.Header>

          <Form.Group className="mt-3 ps-3 pe-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              placeholder="Title..."
              onChange={handleValue}
              name="heading"
            />
          </Form.Group>
          <Modal.Body>
            <div className="form-group">
              <textarea
                className="form-control"
                rows="3"
                name="content"
                placeholder=" Write something...."
                onChange={handleValue}
              ></textarea>
            </div>
            <div className="d-flex justify-content-end">
              <div className="text-warning me-2" {...getRootPropsPort()}>
                <CameraAltIcon />
                <input
                  type="file"
                  id="file"
                  name="img"
                  style={{ display: "none" }}
                  {...getInputPropsPort()}
                  multiple
                />
              </div>{" "}
              <div className="text-success" {...getRootPropsVideo()}>
                <VideocamIcon />
                <input
                  type="file"
                  style={{ display: "none" }}
                  name="video"
                  {...getInputPropsVideo()}
                />
              </div>
            </div>
            <div>
              {uploadedImages.map((image, index) => (
                <Card
                  style={{
                    width: "6rem",
                    height: "6rem",
                    border: "2px solid",
                  }}
                  key={index}
                >
                  <Card.Img
                    className="imet-fit upload-img"
                    src={image}
                    alt={`Uploaded ${index}`}
                  />
                </Card>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <Button onClick={handleClose} className="cancle-btn">
              Cancel
            </Button>
            <Button className="post-btn" disabled={disabled} onClick={CreatePostSubmit}>
              Post
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default Photovideo;
