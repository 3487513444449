import BuyingFilter from "../../components/filteration/buyingFilter";
import UserItemShop from "../../components/UserShopItem/UserItemShop";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import ShareModel from "../../components/Sharemodel";
import url from "../../config/axios"
import { useLocation } from "react-router-dom";
import ShareOption from "../../components/Shareoption";
import BackArrow from "../../components/backArrow";


const SingleItemBuy = () => {
  const location = useLocation();
  const [data, setData] = useState(0);
  const [showmodal, setShowmodal] = useState(false);
  const handleClosemodal = () => setShowmodal(false);
  const handleShow = () => setShowmodal(true);
  const [shopdata, setShopdata] = useState();
  const [isloading, setIsloading] = useState();

  const [showshare, setShowshare] = useState(false);
  const handleCloseshare = () => {
    setShowshare(false);
    setShowmodal(true);
  };
  const handleShowshare = () => setShowshare(true);

  const [dataCategory, setDataCategory] = useState({
    categoryid: "",
    subCategoryid: "",

  })

  const categoryName = dataCategory?.categoryid[1];
  const categoryidone = dataCategory.categoryid[0];
  const subcategoryName = dataCategory?.subCategoryid[1];
  const subcategoryitwo = dataCategory.subCategoryid[0];

  const [additionalShopCategoryData, setAdditionalShopCategoryData] = useState({
    isShop: false,
    itemname: "",
    categoryid: "",
    subCategoryid: "",
    shopCategory: "",
    country: "",
    state: "",
    city: "",
    sale: "",
    min: "",
    max: "",
  })
  const [shopCategory, setShopCategory] = useState()
  const [shopCategorytwo, setShopCategorytwo] = useState()
  const Handledata = (e) => {
    setDataCategory((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split(".")

    }))
  }
  useEffect(() => {
    setAdditionalShopCategoryData((additionalShopCategoryData) => ({
      ...additionalShopCategoryData,
      categoryid: categoryidone !== undefined ? categoryidone : "",
      subCategoryid: subcategoryitwo !== undefined ? subcategoryitwo : "",

    }));
  }, [categoryidone, subcategoryitwo]);

  const Handleadditioanalshop = (e) => {
    setAdditionalShopCategoryData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value

    }))
  }
  useEffect(() => {
    url
      .get("/category/type/?type=singleItem&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error)
      });
  }, [])

  const FilterHandleritem = () => {
    setIsloading(true);
    url
      .get(`/shop/filter?itemname=${additionalShopCategoryData?.itemname}&filtermethod=shopitem&categoryid=${additionalShopCategoryData?.categoryid}&subCategoryid=${additionalShopCategoryData?.subCategoryid}&country=${additionalShopCategoryData?.country}&state=${additionalShopCategoryData?.state}&city=${additionalShopCategoryData?.city}&sale=${additionalShopCategoryData?.sale}&min=${additionalShopCategoryData?.min}&max=${additionalShopCategoryData?.max}&isSingleItem=true`)
      .then(async (response) => {
        if (response?.data?.success) {
          console.log("ssssssssssssssss", response)
          setIsloading(false);
          setShopdata(response?.data?.ShopItem);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
        setIsloading(false);
      });
  };

  const Mydata = () => {
    if (dataCategory?.categoryid) {
      url
        .get(`/category/type/?type=singleItem&parentCategory=${categoryName}`)
        .then(async (response) => {
          if (response?.data?.success) {
            setShopCategorytwo(response?.data?.data?.Category)
          }
        })
        .catch((error) => {
          console.log("servicesCategories", error)
        });
    }
  }

  return (
    <>
      <ShareOption showshare={showshare} handleCloseshare={handleCloseshare} />
      <ShareModel
        showmodal={showmodal}
        handleClosemodal={handleClosemodal}
        handleShowshare={handleShowshare}
      />
      <Container>
        <Row>
          <Col lg="4" className="screenHeight background-image">
            <div className="border b-radius height_100">
              <BuyingFilter dataCategory={dataCategory} Handleadditioanalshop={Handleadditioanalshop} shopCategory={shopCategory} Handledata={Handledata} shopCategorytwo={shopCategorytwo} Mydata={Mydata} FilterHandleritem={FilterHandleritem} />
            </div>
          </Col>
          <Col lg="8" className="screenHeight">
            <div className="border b-radius p-1 ps-2">
              <BackArrow back="/BuyingSide" mystyle="ps-1" />
              <UserItemShop shopdata={shopdata} />

            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default SingleItemBuy;
