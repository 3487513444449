import { Container, Row, Col, Form } from "react-bootstrap";
import { TextField } from "@mui/material";
import BackArrow from "../../components/backArrow";
import "./profilesetting.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useState } from "react";

const Profilesetting = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [inputs, setInputs] = useState([""]);

  const handleAddInput = () => {
    setInputs([...inputs, ""]);
  };

  const handleRemoveInput = (index) => {
    const updatedInputs = [...inputs];
    updatedInputs.splice(index, 1);
    setInputs(updatedInputs);
  };

  const handleInputChange = (index, value) => {
    const updatedInputs = [...inputs];
    updatedInputs[index] = value;
    setInputs(updatedInputs);
  };

  const handleMouseEnter = () => {
    setIsDisabled(true);
  };

  const handleMouseLeave = () => {
    setIsDisabled(false);
  };
  return (
    <>
      <Container>
        <div className="screenHeight d-flex align-items-center">
          <Col lg="9" className="mx-auto ">
            <div className="p-setting_h">
              <div className="arrowback p-1">
                <BackArrow back="/SellingSide" black="black" />
              </div>
              <div className="Profile-setting p-5">
                <h4>Profile Setting</h4>
                <Row className="d-flex justify-content-between">
                  <Col xl="5" lg="6" className="mt-4">
                    <div className="d-flex flex-column position-relative">
                      <label class="custm-lble-2 pb-2">
                        Name{" "}
                        <span style={{ color: "#7A7A7A", fontSize: "10px" }}>
                          (Note: you can change your name after 6 month only)
                        </span>
                      </label>
                      <input
                        type="text"
                        name="shopname"
                        className={`${isDisabled} ? 'disabled' : '' input-style`}
                        placeholder="Name"
                        disabled={isDisabled}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      />
                      {isDisabled && (
                        <span className="disabled-icon">&#128274;</span>
                      )}
                    </div>
                  </Col>
                  <Col xl="5" lg="6" className="mt-4">
                    {/* <div className="d-flex flex-column">
                      {" "}
                      <label class="custm-lble-2 pb-2">Phone Number*</label>
                      <input
                        type="number"
                        name="shopname"
                        class="input-style"
                        placeholder="Number (require)"
                      />
                    </div> */}
                    <div className="d-flex flex-column exclude-number">
                      <label class="custm-lble-2 pb-2">Phone Number*</label>

                      <input
                        type="number"
                        name="shopname"
                        class="input-style"
                        placeholder="Number (require)"
                        id="numberInput"
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between">
                  <Col xl="5" lg="6" className="mt-4">
                    <div className="d-flex flex-column">
                      {" "}
                      <label class="custm-lble-2 pb-2">Country</label>
                      <input
                        name="shopname"
                        class="input-style"
                        placeholder="Name"
                      />
                    </div>
                  </Col>
                  <Col xl="5" lg="6" className="mt-4">
                    <div className="d-flex flex-column">
                      {" "}
                      <label class="custm-lble-2 pb-2">State</label>
                      <input
                        name="shopname"
                        class="input-style"
                        placeholder="Name"
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between">
                  <Col xl="5" lg="6" className="mt-4">
                    <div className="d-flex flex-column">
                      {" "}
                      <label class="custm-lble-2 pb-2">City</label>
                      <input
                        name="shopname"
                        class="input-style"
                        placeholder="Name"
                      />
                    </div>
                  </Col>
                  <Col xl="5" lg="6" className="mt-4">
                    <div className="d-flex flex-column">
                      {" "}
                      <label class="custm-lble-2 pb-2">Current Status</label>
                      {["radio"].map((type) => (
                        <div key={`inline-${type}`}>
                          <Form.Check
                            inline
                            label="Active"
                            name="group1"
                            type={type}
                            id={`inline-${type}-1`}
                          />
                          <Form.Check
                            className="ms-5 text-center"
                            inline
                            label="Inactive"
                            name="group1"
                            type={type}
                            id={`inline-${type}-2`}
                          />
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
                <Row className="d-flex">
                  <Col xl="6" lg="7" className="mt-4 pe-lg-0">
                    <div className="d-flex flex-column">
                      <label class="custm-lble-2 pb-2">Education</label>

                      <div className="scroll-education noscroll">
                        {inputs.map((input, index) => (
                          <Row key={index}>
                            <Col lg="10">
                              <input
                                name="shopname"
                                class="input-style mb-2 input-width-setting"
                                placeholder="Education"
                                value={input}
                                onChange={(e) =>
                                  handleInputChange(index, e.target.value)
                                }
                              />
                            </Col>

                            <Col lg="2">
                              {index !== 0 && (
                                <div onClick={() => handleRemoveInput(index)}>
                                  <RemoveCircleOutlineIcon />
                                </div>
                              )}
                              {index === inputs.length - 1 && (
                                <div onClick={handleAddInput}>
                                  <AddCircleOutlineIcon />
                                </div>
                              )}
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </div>
                  </Col>
                  <Col lg="5" xl="6" className="text-end mt-5">
                    <button className="btn-blue-sm ps-0 pe-0 mt-2">
                      Update
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </>
  );
};

export default Profilesetting;
