import { Row, Col, Container} from "react-bootstrap";
import "./usedItemsCards.css";
import BackArrow from "../../components/backArrow";
import { useNavigate } from "react-router-dom";
import { UsedItems } from "../../constants/jsonConstant";
const UsedItemsCards = () => {
  const navigate = useNavigate();
  return (
    <Row>
      <div className="screenHeight">
        <Container>
          <div className="Form-Shadow mx-auto pt-1 pb-1 ">
            <div className="ps-4 pt-4 pb-2">
              <BackArrow back="/SellingSide" />
            </div>

            <Row className="justify-content-center align-items-center  height-boxes">
              {UsedItems?.map((e) => (
                <Col
                  lg={4}
                  className="cursor card w-50 bg-blue pd-box-1 ms-4 me-4"
                  onClick={() => navigate(e?.link)}
                >
                  <div className="card-body p-2">
                    <p className="card-text text-white font-size-items">
                      {e?.title}
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </div>
    </Row>
  );
};

export default UsedItemsCards;
