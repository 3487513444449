import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { images } from "../../constants";

const Planmodal = ({ show, setModalShow }) => {
  return (
    <>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          onClick={() => setModalShow(false)}
          className="pt-2 pb-2  text-white"
          style={{ background: "#0353a4" }}
        >
          <h6 id="contained-modal-title-vcenter" className="font-md">
            Purchase a Plan
          </h6>
        </Modal.Header>
        <Modal.Body className="m-auto">
          <div className="d-flex justify-content-center">
            <img src={images.Planmodal} alt="" className="Plan_img" />
          </div>

          <h6 className="text-center">
            It seems you haven't purchased a plan yet.
          </h6>
          <p className="font_13">
            You need to purchase a plan to share this post outside your
            timeline.
          </p>
        </Modal.Body>
        <Modal.Footer className="m-auto">
          <Button onClick={() => setModalShow(false)} className="bg-danger">
            Cancle
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button onClick={() => setModalShow(false)}>View Plans</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Planmodal;
