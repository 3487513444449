import { ShopModelCarousal } from "../../constants/jsonConstant";
import ImageGallery from "react-image-gallery";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import { images } from "../../constants";
import "./shopViewModel.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import { RatingStar } from "../../constants/jsonConstant";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useState, useEffect } from "react";
import Rating from "@mui/material/Rating";
import { useLocation } from "react-router-dom";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getAsset } from "../../helpers/helper";
import { useSelector } from "react-redux";

const UserPropertyViewModel = ({ show, handleClose, handleShowModal, itemsdata }) => {
  const { name } = useSelector((state) => state.user);
  console.log("nnnnnnnn//////", name)
  const [value] = useState(5);
  const [valueone] = useState(4);
  const [valuetwo] = useState(3);
  const [valuethree] = useState(2);
  const [valuefour] = useState(1);
  const location = useLocation();
  const [increase, setIncrease] = useState(1);

  useEffect(() => {
    if (show) {
      const imgs = document.querySelectorAll('.image-gallery-image');
      imgs.forEach((element) => {
        element.setAttribute('crossorigin', 'anonymous');
      });
    }
  }, [show]);

  useEffect(() => {

    if (show) {
      const thumimgs = document.querySelectorAll('.image-gallery-thumbnail-image');

      thumimgs.forEach(element => {
        element?.setAttribute('crossorigin', 'anonymous');
      });
    }

  }, [show]);

  const ShopModelCarousal = [
    {
      id: 1,
      original: getAsset(itemsdata?.image[0]),
      thumbnail: getAsset(itemsdata?.image[0]),
    },
    {
      id: 2,
      original: getAsset(itemsdata?.image[1]),
      thumbnail: getAsset(itemsdata?.image[1]),

    },
    {
      id: 3,
      original: getAsset(itemsdata?.image[2]),
      thumbnail: getAsset(itemsdata?.image[2]),

    },
    {
      id: 4,
      original: getAsset(itemsdata?.image[3]),
      thumbnail: getAsset(itemsdata?.image[3]),

    },
    {
      id: 5,
      original: getAsset(itemsdata?.image[4]),
      thumbnail: getAsset(itemsdata?.image[4]),

    },
  ];
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Body className=" pt-3  pb-3">
        <div>
          <p>Posted on 21 May</p>
        </div>
        <Row>
          <Col lg="6">
            <div className="row ">
              <ImageGallery
                showPlayButton={false}
                showFullscreenButton={false}
                items={ShopModelCarousal}
              />
            </div>
          </Col>
          <Col lg="6">
            <Tabs
              defaultActiveKey="Item"
              id="fill-tab-example"
              className="mb-3"
              fill
            >
              <Tab title="Item" eventKey="Item">
                <div className="ps-3 pe-3 pt-4 pb-1 bg-vilot View-Model-Shadow  ">
                  <h4 className="fw-bold">{itemsdata?.subCategory}</h4>
                  <p className="col-10 fw-lighter description-font-size">
                    {itemsdata?.description}
                  </p>

                  <div>
                    <h3 className="font-blue">{itemsdata?.price}</h3>
                  </div>
                  {location.pathname === "/Singleitemmerge" ? (
                    ""
                  ) : (
                    <>
                      <div>
                        <p className="mb-0 fw-bold pt-1 pb-1">
                          Residential/Plot/Commercial
                        </p>
                        <Row>
                          <Col lg={1}>
                            {/* <input
                              type="radio"
                              id="html"
                              name="fav_language"
                              value="HTML"
                            /> */}
                          </Col>
                          <Col lg={10} className="ps-1">
                            <label className="fw-light">{itemsdata?.plottype}</label>
                          </Col>
                        </Row>
                        <div>
                          <p className="mb-0 fw-bold pt-1 pb-1">
                            {" "}
                            Rent/Buy/Lease{" "}
                          </p>
                        </div>
                        <Row>
                          <Col lg={1}>
                            {/* <input
                              type="radio"
                              id="html"
                              name="fav_language"
                              value="HTML"
                            /> */}
                          </Col>
                          <Col lg={10} className="ps-1">
                            <label className="fw-light">{itemsdata?.saletype}</label>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}

                  <div>
                    <p className="mb-0 fw-bold">Contact us on</p>
                    <p className="mb-0 pt-2 pb-2 fw-bold">
                      {name?.email}
                    </p>
                    <p className="mb-0 fw-bold">{name?.phonenumber}</p>
                  </div>

                  {location.pathname === "/Singleitemmerge" ? (
                    <>
                      <div className="bg-white quantityBox mt-2 mb-2  pe-2 ps-2 w-25 d-flex align-items-center ">
                        <div>
                          <p className="mb-0 pe-1">QTY:</p>
                        </div>
                        <div>
                          <p className="mb-0 pe-1">{increase}</p>
                        </div>

                        <div>
                          <div className="d-flex flex-column align-items-center ">
                            <ExpandLessIcon
                              fontSize="small"
                              onClick={() => setIncrease(increase + 1)}
                            />
                            <ExpandMoreIcon
                              fontSize="small"
                              onClick={() => setIncrease(increase - 1)}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div></div>
                  <div class="pt-3 cursor">
                    {location.pathname === "/Singleitemmerge" ? (
                      <button
                        className=" d-flex align-items-center justify-content-center mb-0 border rounded ps-4 pe-4 pt-1 pb-1 bg-blue ms-3 me-3 w-75 text-white"
                        onClick={handleShowModal}
                      >
                        <img src={images.Cart} />
                        <span className="ps-2">Add to cart</span>
                      </button>
                    ) : (
                      <button className=" d-flex align-items-center justify-content-center mb-0 border rounded ps-4 pe-4 pt-1 pb-1 bg-blue ms-3 me-3 w-75 text-white">
                        <span className="ps-2">Send Inquiry</span>
                      </button>
                    )}
                  </div>
                </div>
                {/* <div className="ps-3 pe-3 pt-4 pb-1 bg-vilot View-Model-Shadow  ">
                            <h4 className="fw-bold">Shirts</h4>
                            <p className="col-10 fw-lighter description-font-size">
                                Lorem ipsum dolor sit amet, consectetur adi tempor incidila
                                bore et dolore magna quis nostrud exercitation ullamco
                                laboris. Lorem ipsum dolor sit amet, consectetur adi tempor
                                incidila bore et dolore magna quis nostrud exercitation
                                ullamco laboris.
                            </p>

                            <div>
                                <h3 className="font-blue">PKR 15’000</h3>
                            </div>
                            <div>
                                <p className="mb-0 fw-bold pt-1 pb-1">Residential/Plot/Commercial</p>
                                <Row>
                                    <Col lg={1}>
                                        <input type="radio" id="html" name="fav_language" value="HTML" />
                                    </Col>
                                    <Col lg={10} className="ps-1">
                                        <label className="fw-light">Commercial</label>
                                    </Col>
                                </Row>
                                <div>
                                    <p className="mb-0 fw-bold pt-1 pb-1"> Rent/Buy/Lease  </p>
                                </div>
                                <Row>
                                    <Col lg={1}>
                                        <input type="radio" id="html" name="fav_language" value="HTML" />
                                    </Col>
                                    <Col lg={10} className="ps-1">
                                        <label className="fw-light">Rent</label>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <p className="mb-0 fw-bold">Contact us on</p>
                                <p className="mb-0 pt-2 pb-2 fw-bold">james.levin23@gmail.com</p>
                                <p className="mb-0 fw-bold">0321-4546589</p>
                            </div>
                            <div>

                            </div>
                            <div className='margin-Boxes pt-2 pb-2'>
                                <Row className='row bg-blue '>
                                    <Col lg={6} className="p-0">
                                        <div className='border-end cursor'>
                                            <p className='mb-0 ps-1 d-flex p-1'>
                                                <img className='Model_btn ' src={images.BlueMail}  /><span className='btns text-white ps-1'>Message</span>
                                            <p className='mb-0 ps-1 pt-1 pb-1'>
                                                <img className='Model_btn ' src={images.BlueMail} /><span className='btns text-white ps-1'>Message</span>
                                            </p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="p-0">
                                        <div className='cursor'>
                                            <p className='mb-0 ps-1 d-flex p-1 '>
                                                <img className='Model_btn' src={images.BluePhone} /><span className='btns text-white ps-1'>Call</span>
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>  */}
              </Tab>
              <Tab
                className="shadow-none bg-light rounded"
                eventKey="Reviews"
                title="Reviews"
              >
                <Row>
                  <Col lg={6}>
                    <div className="mt-3">
                      <h4 className="mb-4">4.4/5</h4>

                      <Rating
                        className="mb-3"
                        name="read-only"
                        value={value}
                        readOnly
                      />

                      <p>7 Ratings</p>
                    </div>

                  </Col>

                  <Col lg={6}>
                    <div className="mt-3 mb-2">
                      <div className="rating-stars  d-flex ">
                        <Rating name="read-only" value={value} readOnly />
                        <h6>6</h6>
                      </div>
                      <div className="rating-stars  d-flex ">
                        <Rating name="read-only" value={valueone} readOnly />
                        <h6>0</h6>
                      </div>
                      <div className="rating-stars  d-flex ">
                        <Rating name="read-only" value={valuetwo} readOnly />
                        <h6>0</h6>
                      </div>
                      <div className="rating-stars  d-flex ">
                        <Rating name="read-only" value={valuethree} readOnly />
                        <h6>0</h6>
                      </div>
                      <div className="rating-stars  d-flex ">
                        <Rating name="read-only" value={valuefour} readOnly />
                        <h6>1</h6>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="card-scrolll">
                  {RatingStar.map((e) => (
                    <div>
                      <img src={e.imgstarss} alt="" />
                      <h6 className="pt-1">
                        BY A...
                        <img src={e.Phera2} alt="" /> Verified purchase
                      </h6>

                      <Card style={{ height: "13rem" }}>
                        <Card.Body>
                          <h6 className="phgh">{e.Phera} </h6>
                          <img src={e.image} alt="" className="pe-2" />
                          <img src={e.images} alt="" />
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default UserPropertyViewModel;
