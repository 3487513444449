import "./businessmenPlatform.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { businessCards } from "../../../../constants/jsonConstant";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import url from "../../../../config/axios";
import { useSelector } from "react-redux";
const BusinessmenPlatform = () => {
  const { name } = useSelector((state) => state.user);
  const [companyview, setCompanyview] = useState();
  const [serviceview, setServiceview] = useState();
  const [propertyview, setPropertyview] = useState();
  const [singlepropertyview, setSinglePropertyview] = useState();
  const id = name._id;
  useEffect(() => {
    url
      .get(`/company/?userid=${id}&isBusinessProperty=false&isJobsCompany=true`)
      .then((response) => {  
        setCompanyview(response?.data?.data?.Companies.at(-1));
      });

    url.get(`/service/user/?userid=${id}`).then((response) => {
      setServiceview(response?.data?.data?.Services.at(-1));
    });

    url
      .get(`/company/?userid=${id}&isBusinessProperty=true&isJobsCompany=false`)
      .then((response) => {  
        setPropertyview(response?.data?.data?.Companies.at(-1));
      });

    url.get(`/property/user/?userid=${id}`).then((response) => {
      setSinglePropertyview(response?.data?.data?.Companies.at(-1));
    });
  }, [id]);
  const navigate = useNavigate();
  return (
    <Row className="px-2">
      {businessCards?.map((cards) => (
        <Col className=" px-0">
          <Card
            key={cards.id}
            className="businesscardsStyle businesscards  px-1"
          >
            <Row>
              <div className="d-flex justify-content-end">
                <div>
                  <MailOutlineIcon />
                </div>
              </div>
            </Row>

            <Card.Body className="d-flex flex-column align-items-center pt-0 px-0">
              <div
                className={`${
                  cards.id === 1
                    ? "business-images-1 mb-2"
                    : "business-images mb-2"
                }`}
              >
                <img src={cards.img} alt="" />
              </div>

              <h4 className="font-up-cards mb-1">{cards.title}</h4>

              {cards?.id === 2 || cards?.id === 7 ? (
                companyview?.userid || serviceview?.userid ? (
                  <button
                    className="btn-dash-sm  bg-blue-lg text-white d-flex justify-content-center align-items-center mt-1"
                    onClick={() => {
                      (cards?.id === 2 &&
                        navigate(`${cards.url}/${companyview?._id} `)) ||
                        (cards?.id === 7 &&
                          navigate(`${cards.url}/${serviceview?._id} `));
                    }}
                  >
                    View
                  </button>
                ) : (
                  <button
                    className="btn-dash-sm  bg-blue-lg text-white d-flex justify-content-center align-items-center mt-1"
                    onClick={() => navigate(cards.link)}
                  >
                    Create
                  </button>
                )
              ) : (
                <button
                  className="btn-dash-sm  bg-blue-lg text-white d-flex justify-content-center align-items-center mt-1"
                  onClick={() => navigate(cards.link)}
                >
                  Create
                </button>
              )}
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};
export default BusinessmenPlatform;
