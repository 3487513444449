import OwnerPropertyViewModel from "../../components/ShopViewModel/OwnerPropertyViewModel";

const SinglePropertyItemsCard = () => {
  return (
    <>
      <OwnerPropertyViewModel
       update="/update-business-Ad/"
      deleteurl="/personal-add/"
      />
    </>
  );
};
export default SinglePropertyItemsCard;
