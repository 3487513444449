import React from 'react'
import {Container, Row, Col } from "react-bootstrap";
import Singleitem from './component/Leftside';
import UserPropertyItems from '../../components/UserProperty/UserPropertyItems';

const Singleitemmerge = () => {
  return (
    <div>
        <Container >
            <div className='screenHeight'>

                <Row>
                    <Col className=" ps-0 pe-4" lg="4">
                        <div style={{height:'91vh',borderRadius:"6px"}} className='border_3 pe-0'>
                    <Singleitem/>
                    </div>
                    </Col>
                    <Col style={{height:'91vh',borderRadius:"6px"}} className=' border_3 '  lg="8">
                  
                    <UserPropertyItems />
                    </Col>
                </Row>

            </div>
        </Container>
    </div>
  )
}

export default Singleitemmerge
