import "./SpecialOffer.css";
// import "../../components/ShopItemsOwner/shopItems.css";
import React from "react";
import { Col, Row, Button, Container } from "react-bootstrap";
import { images } from "../../constants";
import RedoIcon from "@mui/icons-material/Redo";
import { useNavigate } from "react-router-dom";
import { getAsset } from "../../helpers/helper";
import itemdata from "../../store/modules/itemdata";

const SpecialOffer = ({
  specialoffer,
  urlPass,
  shopid,
  setShows,
  setItemid,
  setShowmodal,
  setShow,
  UserShopView,
  newItemdata,
  filterspecial
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <div className="shop-scroll noscroll ">
          <div className="items_scroll noscroll">
            <div className=" row ps-2 pe-2">
              {
  filterspecial
   &&  filterspecial?.length>0 ? 

   filterspecial?.map((Items) => (
    <div className="col-lg-4 col-xl-3 col-md-6 p-2">
      <div className="border_2 ">
        <div key={Items?.id}>
          <div className="fix-img position-relative">
            <p className="mb-0  position-absolute p-1 bg-danger text-white font_12 ">
              {Items?.specialoffer}
            </p>
            <img
              className="c_img"
              src={getAsset(
                Items?.images ? Items?.images[0] : Items?.image[0]
              )}
              alt=""
              crossOrigin="anonymous"
            />
          </div>
          <Row className="align-items-center">
            <Col lg={6} md={5} className="pe-0">
              <div className="pt-1 ms-2">
                <p className="mb-0 ps-2 pe-2 items-card-font fw-bold">
                  {Items?.itemname.slice(0, 7)}
                </p>
              </div>
            </Col>
            <Col lg={6} md={7} className="ps-0">
              <div>
                {newItemdata && newItemdata.length > 0 ? (
                  <p className="mb-0 ps-2 pe-2  stock mt-2 me-2">
                    {Items?.stock} stock
                  </p>
                ) : (
                  <p className="mb-0 ps-2  labelText fw-bold pt-1 ms-2 ms-lg-1">
                    Quantity ({Items?.quantity})
                  </p>
                )}
              </div>
            </Col>
          </Row>
          <div className="d-flex justify-content-between align-items-center  ps-2 pe-2 ">
            <p className="mb-0 pt-1  items-card-font font-blue ms-2">
              <span style={{ color: "black" }}>Price:</span>
              &nbsp;
              {Items?.price}
            </p>
            <img src={images.img_five} />
          </div>
          {newItemdata && newItemdata.length > 0 ? (
            ""
          ) : (
            <div className="row align-items-center">
              <Col lg="6" className="pe-0">
                <h6 className="items-card-font ms-3 mb-0">
                  Orders (10)
                </h6>
              </Col>
              <Col lg="6" className="text-center">
                <button
                  className="text-white bg-blue share_font item-share-btn"
                  onClick={() => {
                    setShowmodal(true);
                  }}
                >
                  Share
                  <RedoIcon className="share_icon_f" />
                </button>
              </Col>
            </div>
          )}

          <div className="d-flex justify-content-around mb-2 mt-1">
            <p
              className={
                newItemdata && newItemdata.length > 0
                  ? "mb-0 ps-lg-4 pe-lg-4 view_btn-User"
                  : "mb-0 view-btn btn-primary text-center ms-1 me-1 mt-1"
              }
              onClick={() => {
                newItemdata && newItemdata.length > 0
                  ? (() => {
                      UserShopView(Items?._id);
                      setShow(true);
                    })()
                  : navigate(`${urlPass}/${shopid}/${Items._id}`);
              }}
            >
              View
            </p>

            {newItemdata && newItemdata.length > 0 ? (
              ""
            ) : (
              <Button
                className="mb-0 view-btnone text-white  text-center ms-1 me-1 mt-1"
                variant="danger"
                onClick={() => {
                  setShows(true);
                  setItemid(Items._id);
                }}
              >
                Delete
              </Button>
            )}

    
          </div>
          {newItemdata && newItemdata.length > 0 ? (
              <div className="margin-Boxes">
                <Row className="row bg-blue ">
                  <Col lg={6} md={4} className="p-0">
                    <div className="border-end cursor">
                      <p className="mb-0 ps-1 d-flex justify-content-center p-1">
                        <img
                          className="w-img-card"
                          src={images?.Mail}
                        />
                        <span className="btns text-white ps-1">
                          Message
                        </span>
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} md={4} className="p-0">
                    <div className="cursor ">
                      <p className="mb-0 ps-1 d-flex justify-content-center p-1">
                        <img
                          className="w-img-card"
                          src={images?.Phone}
                        />
                        <span className="btns text-white ps-1">
                          Call
                        </span>
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              ""
            )}
        </div>
      </div>
    </div>
  ))


:
              specialoffer && specialoffer?.length>0 ?
              
              specialoffer?.map((Items) => (
                <div className="col-lg-4 col-xl-3 col-md-6 p-2">
                  <div className="border_2 ">
                    <div key={Items?.id}>
                      <div className="fix-img position-relative">
                        <p className="mb-0  position-absolute p-1 bg-danger text-white font_12 ">
                          {Items?.specialoffer}
                        </p>
                        <img
                          className="c_img"
                          src={getAsset(
                            Items?.images ? Items?.images[0] : Items?.image[0]
                          )}
                          alt=""
                          crossOrigin="anonymous"
                        />
                      </div>
                      <Row className="align-items-center">
                        <Col lg={6} md={5} className="pe-0">
                          <div className="pt-1 ms-2">
                            <p className="mb-0 ps-2 pe-2 items-card-font fw-bold">
                              {Items?.itemname.slice(0, 7)}
                            </p>
                          </div>
                        </Col>
                        <Col lg={6} md={7} className="ps-0">
                          <div>
                            {newItemdata && newItemdata.length > 0 ? (
                              <p className="mb-0 ps-2 pe-2  stock mt-2 me-2">
                                {Items?.stock} stock
                              </p>
                            ) : (
                              <p className="mb-0 ps-2  labelText fw-bold pt-1 ms-2 ms-lg-1">
                                Quantity ({Items?.quantity})
                              </p>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-between align-items-center  ps-2 pe-2 ">
                        <p className="mb-0 pt-1  items-card-font font-blue ms-2">
                          <span style={{ color: "black" }}>Price:</span>
                          &nbsp;
                          {Items?.price}
                        </p>
                        <img src={images.img_five} />
                      </div>
                      {newItemdata && newItemdata.length > 0 ? (
                        ""
                      ) : (
                        <div className="row align-items-center">
                          <Col lg="6" className="pe-0">
                            <h6 className="items-card-font ms-3 mb-0">
                              Orders (10)
                            </h6>
                          </Col>
                          <Col lg="6" className="text-center">
                            <button
                              className="text-white bg-blue share_font item-share-btn"
                              onClick={() => {
                                setShowmodal(true);
                              }}
                            >
                              Share
                              <RedoIcon className="share_icon_f" />
                            </button>
                          </Col>
                        </div>
                      )}

                      <div className="d-flex justify-content-around mb-2 mt-1">
                        <p
                          className={
                            newItemdata && newItemdata.length > 0
                              ? "mb-0 ps-lg-4 pe-lg-4 view_btn-User"
                              : "mb-0 view-btn btn-primary text-center ms-1 me-1 mt-1"
                          }
                          onClick={() => {
                            newItemdata && newItemdata.length > 0
                              ? (() => {
                                  UserShopView(Items?._id);
                                  setShow(true);
                                })()
                              : navigate(`${urlPass}/${shopid}/${Items._id}`);
                          }}
                        >
                          View
                        </p>

                        {newItemdata && newItemdata.length > 0 ? (
                          ""
                        ) : (
                          <Button
                            className="mb-0 view-btnone text-white  text-center ms-1 me-1 mt-1"
                            variant="danger"
                            onClick={() => {
                              setShows(true);
                              setItemid(Items._id);
                            }}
                          >
                            Delete
                          </Button>
                        )}

                
                      </div>
                      {newItemdata && newItemdata.length > 0 ? (
                          <div className="margin-Boxes">
                            <Row className="row bg-blue ">
                              <Col lg={6} md={4} className="p-0">
                                <div className="border-end cursor">
                                  <p className="mb-0 ps-1 d-flex justify-content-center p-1">
                                    <img
                                      className="w-img-card"
                                      src={images?.Mail}
                                    />
                                    <span className="btns text-white ps-1">
                                      Message
                                    </span>
                                  </p>
                                </div>
                              </Col>
                              <Col lg={6} md={4} className="p-0">
                                <div className="cursor ">
                                  <p className="mb-0 ps-1 d-flex justify-content-center p-1">
                                    <img
                                      className="w-img-card"
                                      src={images?.Phone}
                                    />
                                    <span className="btns text-white ps-1">
                                      Call
                                    </span>
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          ""
                        )}
                    </div>
                  </div>
                </div>
              ))
              
              : <h4 className="text-center">No items found</h4>
              }
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default SpecialOffer;
