import React, { useEffect, useState } from "react";
import { images } from "../../constants/index";
import { Col, Row } from "react-bootstrap";
import "./ownerShopProfile.css";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import StoreIcon from "@mui/icons-material/Store";
import CallIcon from "@mui/icons-material/Call";
import StarRateIcon from "@mui/icons-material/StarRate";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ReportIcon from "@mui/icons-material/Report";
import SettingsIcon from "@mui/icons-material/Settings";
import CampaignIcon from "@mui/icons-material/Campaign";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import url from "../../config/axios";
import { useSelector } from "react-redux";
import Salespromotion from "../../components/salespromotion_modal";
import FiberManualRecordSharpIcon from "@mui/icons-material/FiberManualRecordSharp";
import Qrmodal from "../QRmodal/Qrmodal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Desmodal from "../Des_Modal";
import { getAsset } from "../../helpers/helper";
const OwnerShopProfile = ({
  shopData,
  navigateTo,
  Name,
  Add,
  post,
  setSuggest,
  Setreport,
  Reportdetail,
  promote,
  salespromotion,
  icon,
  suggestdatta,
  shopid,
}) => {

  const { id } = useParams();
  const { name } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [TLike, setTLike] = useState(null);
  const [TDislike, setTDislike] = useState(null);
  const [showmodal, setShowmodal] = useState(false);
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Click on address to view map
    </Tooltip>
  );

  const promotaddTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Coming Soon
    </Tooltip>
  );
  
  const goliveTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Coming Soon
    </Tooltip>
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const closemodal = () => setShowmodal(false);

  const [desshow, setDesshow] = useState(false);

  const handleClosedes = () => setDesshow(false);
  const handleShowdes = () => setDesshow(true);

  useEffect(() => {
    url
      .get(`likedislike/vendorid?vendorid=${shopid}`)
      .then((response) => {
        const data = response.data;
        if (data.success && data.data.length > 0) {
          const item = data.data[0];
          setTLike(item.Tlike);
          setTDislike(item.Tdislike);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      <Desmodal
        desshow={desshow}
        handleClosedes={handleClosedes}
        des={shopData?.description}
      />
      <Qrmodal show={showmodal} handleClosemodal={closemodal} id={id} />
      <Salespromotion
        shopid={shopid}
        show={show}
        setShow={setShow}
        handleClose={handleClose}
      />
      <Row>
        <Col
          className="shop-profile-box pt-1 px-3 ms-0 me-0 screenHeight"
          md={12}
          lg={12}
        >
          <div
            className="d-flex justify-content-center pb-2 cursor_P"
            onClick={() => navigate(`${navigateTo}/` + id)}
          >
            <img className="w-img" src={images.UpdateShop} alt="UpdateShop" />
            <h6 className="mb-0 ps-2 pt-1 updateSize ">{Name}</h6>
          </div>
          <div className="relate_div">
            <div className="d-flex availble_bt0n">
              <FiberManualRecordSharpIcon
                style={{ color: "rgb(106 235 64)", fontSize: "19px" }}
              />
              <span>Available</span>
            </div>
            <img
              className="w-100 shop-profile-img"
              src={getAsset(shopData?.image)}
              alt=""
              crossOrigin="anonymous"
            />
          
          </div>

          <div className="box-container mt-1 mb-2">
            {/* <div className="d-flex justify-content-end">
            <QrCodeScannerIcon/>
          </div> */}
            <Row>
              <Col lg="10">
                <div className="pt-2 pb-1 d-flex">
                  <h4 className="shop-Profile-font mb-0 pb-1">
                    {shopData?.shopname}
                  </h4>
                </div>
              </Col>
              <Col lg="2">
                <div className="d-flex justify-content-end align-items-center mt-2">
                  <QrCodeScannerIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowmodal(true)}
                  />
                </div>
              </Col>
            </Row>
            <Col lg="12" className="d-flex">
              <p className="shop-profile-para mb-0">
                {shopData?.description.slice(0, 25)}
              </p>
              <p className="shop-profile-para mb-0">
                {shopData?.description.length > 25 && (
                  <span
                    onClick={handleShowdes}
                    className="mb-0 cursor_P font_11 text-dark"
                    style={{ marginTop: "3px" }}
                  >
                    &nbsp;<b>ReadMore...</b>
                  </span>
                )}
              </p>
            </Col>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <div className="d-flex align-items-center">
                <LocationCityIcon className="silver-icon" />
                <p className="mb-0 ViewlabelText pt-1 pb-1 ps-2 cursor_P">
                  {shopData?.shopaddress}
                </p>
              </div>
            </OverlayTrigger>
            <div className="d-flex align-items-center">
              <StoreIcon className="silver-icon" />
              <p className="mb-0 ViewlabelText ps-2">{shopData?.marketPlace}</p>
            </div>
          </div>
          {/* details */}
          <div>
            <div>
              <div className="d-flex align-items-center">
                <StarRateIcon className="silver-icon" />
                <p className="mb-0 ViewlabelText ps-2">4.5</p>
              </div>
              {/* <div className="d-flex align-items-center">
              <LocationCityIcon className="silver-icon" />
              <p className="mb-0 ViewlabelText pt-1 pb-1 ps-2">
                {shopData?.shopaddress}
              </p>
            </div> */}
              <div className="d-flex">
                <div>
                  {/* <div className="d-flex align-items-center  ">
                  <StoreIcon className="silver-icon" />
                  <p className="mb-0 ViewlabelText ps-2">
                    {shopData?.marketPlace}
                  </p>
                </div> */}
                  <div className="d-flex align-items-center">
                    <ThumbUpIcon className="silver-icon" />
                    <p className="mb-0 ViewlabelText ps-2">Liked by {TLike} </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <ThumbDownIcon className="silver-icon" />
                    <p className="mb-0 ViewlabelText ps-2">
                      Unliked by {TDislike}{" "}
                    </p>
                  </div>
                  <div
                    className="d-flex align-items-center cursor_P"
                    onClick={() => {
                      setSuggest(1);
                      suggestdatta();
                    }}
                  >
                    <NotificationsIcon className="silver-icon" />
                    <p className="mb-0 ViewlabelText ps-2">Suggestions</p>
                  </div>
                  <div
                    className="d-flex align-items-center cursor_P"
                    onClick={() => setSuggest(2)}
                  >
                    <ReportIcon className="silver-icon" />
                    <p className="mb-0 ViewlabelText ps-2">Report</p>
                  </div>
                  <div
                    className="d-flex align-items-center cursor_P"
                    onClick={
                      location.pathname === `/used-vehicle-profile/${id}`
                        ? () =>
                            navigate(`/usedvehicle-setting/${shopData?.shopid}`)
                        : () => navigate(`/shop-setting/${shopData?.shopid}`)
                    }
                  >
                    <SettingsIcon className="silver-icon" />
                    <p className="mb-0 ViewlabelText ps-2">Setting</p>
                  </div>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={promotaddTooltip}
                  >
                    <div
                      className="d-flex align-items-center cursor_P"
                      // onClick={() =>
                      //   navigate(`/promote-shop/${shopData?.shopid}`)
                      // }
                    >
                      <CampaignIcon className="silver-icon" />
                      <p className="mb-0 ViewlabelText ps-2">
                        {" "}
                        Ads and promotion
                      </p>
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={goliveTooltip}
                  >
                    <div className="d-flex align-items-center cursor_P">
                      <VideoCallIcon className="silver-icon" />
                      <p className="mb-0 ViewlabelText ps-2">Go Live</p>
                    </div>
                  </OverlayTrigger>

                  <div
                    className="d-flex align-items-center promotion"
                    onClick={handleShow}
                  >
                    {icon}
                    <p className="mb-0 ViewlabelText ps-2">{salespromotion} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Post-Data */}
          {/* <Col md={9} lg={9} className="px-0 mx-auto">
          <div className="postItemback">
            <div className="position-relative d-flex justify-content-center ">
              <div className=" bg-white p-1 position-absolute rounded-circle relative-up">
                <img src={images.PostArrow} />
              </div>
            </div>
            <div className="d-flex flex-column align-items-center ">
              <p className=" postHead mb-0">{Add}</p>
              <p className="text-white   mb-0  text-center  mx-auto col-10 postPara">
                Lorem ipsum dolor sit amet amet amet
              </p> */}
          {/* <div className="pb-2  pt-0 pt-lg-0 pt-xl-1 ">
                <button
                  className="post-item-btn text-white"
                  onClick={() => navigate(`${post}/` + id)}
                >
                  Post Items
                </button>
              </div> */}
          {/* </div>
          </div>
        </Col> */}
          {/* End-btn */}
          <div className=" pt-1">
            <div>
              {/* <button
              className="border ps-4 pe-4 pt-1 pb-1 bg-blue text-white rounded w-100"
              onClick={() => navigate(`/promote-shop/${id}`)}>
              Promote Your Shop
            </button> */}
              {/* <button className="border ps-4 pe-4 pt-1 pb-1 mt-1 bg-blue text-white rounded w-100">
              Go Live
            </button> */}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default OwnerShopProfile;
