import React from 'react'
import { Container } from 'react-bootstrap'
import './style.css'
import { useState } from 'react'
import ChatingInputField from '../ChatingInputField'


const ChatingSideBtns = () => {

    const [hidebtn, setHidebtn] = useState(1)
    const [displayChild, setDisplayChild] = useState(false)

    return (
        <div>

            <Container>
                {
                    hidebtn === 1 && (<div className="last-buttons">
                    <button className='message-chatting-btn'>Forward</button>
                    <button onClick={() => { setDisplayChild(true); setHidebtn(0) }} className='primary-chatting-button'>Reply</button>
                </div>) 

                    
                }



                <div className='input-field-class'>
                    {
                        displayChild === true && (<ChatingInputField setHidebtn={setHidebtn} setDisplayChild={setDisplayChild} />)
                    }
                    
                </div>

            </Container>

        </div>
    )
}

export default ChatingSideBtns
