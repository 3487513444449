import "./suggest.css";
import { images } from "../../constants";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import url from "../../config/axios";
import { useState,useEffect } from "react";
import { getAsset } from "../../helpers/helper";

const Suggestionbox = ({ lgShow, setLgShow, suggestHandel, suggestPost }) => {
  const { name } = useSelector((state) => state.user);
const [imagepast,setImagepast]=useState();
  useEffect(() => {
    if(lgShow){
      url.get(`user/id?id=${name._id}`).then((res) => {
        setImagepast(res?.data?.data?.User?.imageUrl);
      })
    }
  
  }, [lgShow]);
  return (
    <>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <h5 className="text-center">Suggestion</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-11 mx-auto">
            <div className="row">
              <div className="col-2">
                <img src={imagepast ? getAsset(imagepast) : ""} crossOrigin="anonymous" alt="sss" className="suggest-img" />
              </div>
            
              <div className="col-7 d-flex align-items-center mt-4">
                <h5>{name.fullname}</h5>
              </div>
            </div>
            <div className="d-flex flex-column ">
              <label className="" style={{ color: "#7B7B93" }}>
                Type your Role Here
              </label>
              <input
                type="text"
                className="w-50 mt-2"
                style={{
                  background: "rgb(243, 240, 240)",
                  border: "1px solid  #ced4da",
                  borderRadius: "4px",
                }}
                name="role"
                onChange={(e) => suggestHandel(e)}
              />
            </div>
            <p className="mt-3" style={{ color: "#7B7B93" }}>
              How may i can help You.Write Your sugestion and message here..
            </p>

            <label for="exampleFormControlTextarea1" class="form-label mt-2">
              Message*
            </label>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="8"
              style={{ background: "#F3F0F0" }}
              placeholder="Type your message here.."
              name="message"
              onChange={(e) => suggestHandel(e)}
            ></textarea>
            <div className="d-flex justify-content-end mt-2">
              <button
                className="butns-0 me-3 mt-3"
                onClick={() => setLgShow(false)}
              >
                Back
              </button>
              <button className="butns-1 mt-3" onClick={suggestPost}>
                Send
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Suggestionbox;
