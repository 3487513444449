import { Col, Row, Form } from "react-bootstrap";
import axios from "axios";

import BackArrow from "../../components/backArrow";
import { useState, useEffect, useRef } from "react";
import { images } from "../../constants";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ClearIcon from "@mui/icons-material/Clear";
import { Formik } from "formik";
import * as Yup from "yup";
import SearchIcon from "@mui/icons-material/Search";
import url from "../../config/axios";
import { Measurementunit, currencies } from "../../constants/jsonConstant";
import PersonalImage from "../../helpers/fileUpload";
import { useSelector } from "react-redux";
import { SuccessNotify, FailedNotify } from "../../constants/tostify";

const Scrapematerial = () => {
  const navigate = useNavigate();
  const imageUrl = useSelector((state) => state.imgurl);
  const { name } = useSelector((state) => state.user);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedstateone, setSelectedstateone] = useState("");
  const [searchTermone, setSearchTermone] = useState("");

  const [selectedcity, setSelectedcity] = useState("");
  const [searchTermtwo, setSearchTermtwo] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle the country selection
  const handleCountryChange = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
  };

  const handleSearchChangestate = (event) => {
    setSearchTermone(event.target.value);
  };

  const handleSearchChangecity = (event) => {
    setSearchTermtwo(event.target.value);
  };

  // Function to handle the state selection
  const handleCountryChangestate = (event) => {
    const { value } = event.target;
    setSelectedstateone(value);
  };

  // Function to handle the city selection
  const handleCountryChangecity = (event) => {
    const { value } = event.target;
    setSelectedcity(value);
  };
  const [hidefield, setHidefield] = useState(1);
  const [hidestate, setHidestate] = useState(1);
  const [hidecity, setHidecity] = useState(1);

  const [countrydata, SetCountrydata] = useState([]);
  const [getcountry] = useState();
  const [selectedState] = useState();
  const [getState, setState] = useState([]);
  const [getcity, setGetcity] = useState([]);
  const [statetwo, setStatetwo] = useState(getcountry);
  const [city, setCity] = useState(selectedState);
  const [datatwo, setDatatwo] = useState({
    scrapTypeid: "",
  });
  // Countries funtioncallity
  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => SetCountrydata(res.data))

      .catch((error) => { });
  }, []);

  const country = [...new Set(countrydata.map((item) => item.country))];

  country.sort();

  const handleCountry = (e) => {
    let states = countrydata.filter(
      (state) => state.country === e.target.value
    );
    states = [...new Set(states.map((item) => item.subcountry))];
    states.sort();
    setState(states);
  };

  const handleState = (e) => {
    let cities = countrydata.filter(
      (city) => city.subcountry === e.target.value
    );
    setGetcity(cities);
  };

  const [uploadedImages, setUploadedImages] = useState([]);

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      console.log("mtfile", e.target.files[0]);
      setSelectedImage(e.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
    }

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setUploadedImages([...uploadedImages, reader.result]);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const removeImage = (index) => {
    const updatedImages = [...uploadedImages];
    updatedImages.splice(index, 1);
    setUploadedImages(updatedImages);
  };

  const inputfile = useRef(null);
  const upload_img = () => {
    inputfile.current.click();
  };

  const [scrapcategory, setScrapcategory] = useState();
  const InputChangetwo = (e) => {
    setDatatwo((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split("."),
    }));
  };
  const categoryName = datatwo?.scrapTypeid[1];
  const categoryid = datatwo?.scrapTypeid[0];
  useEffect(() => {
    setUserData((userData) => ({
      ...userData,
      scrapTypeid: categoryid,
      scrapType: categoryName,
    }));
  }, [categoryid, categoryName]);

  const validationSchema = Yup.object({
    scrapTypeid: Yup.string().required("Type is required*"),
    price: Yup.number().required("Price is required*"),
    currency: Yup.string().required("Currency is required*"),
    unitOfMeasurement: Yup.string().required("Unit of Measurment is required*"),
    description: Yup.string().required("Description is required*"),
    country: Yup.string().required("Country is required*"),
    state: Yup.string().required("State is required*"),
    city: Yup.string().required("City is required*"),
    quantity: Yup.string().required("quantity is required*"),
    images: Yup.string().required("Please select an image."),
  });

  useEffect(() => {
    url.get("category/consumer/?type=scrapMaterial").then(async (res) => {
      console.log("reseeessssss", res)
      setScrapcategory(res.data.data.Category);
    });
  }, []);

  const [userData, setUserData] = useState({
    userid: name._id,
    isSingleItem: false,
    isScrapItem: true,
    price: "",
    scrapTypeid: "",
    scrapType: "",
    country: "",
    city: "",
    state: "",
    quantity: "",
    description: "",
    unitOfMeasurement: "",
    currency: "",
    images: [""],
    status: true,
  });

  useEffect(() => {
    setUserData((userData) => ({
      ...userData,
      images: imageUrl?.imgurl || "",
    }));
    // ...
  }, [imageUrl]);
  const InputChange = (e) => {
    setUserData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,

    }));
  };

  const handleFunction = (e) => {
    e.preventDefault();
    url
      .post(`shopitem/`, userData)
      .then(async (res) => {
        if (res.data.success) {
          SuccessNotify("Scrap Item Posted Successfully");
          navigate(`/scrap-item-show`);
        }
      })
      .catch(() => {
        FailedNotify("Failed to Post Try Again!");
      });
  };

  return (
    <>
      {selectedImage && <PersonalImage image={selectedImage} />}

      <Formik
        initialValues={{
          scrapTypeid: "",
          quantity: "",
          price: "",
          unitOfMeasurement: "",
          images: "",
          country: "",
          state: "",
          city: "",
          currency: "",
          description: "",
        }}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={(values) => { }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
          isValid,
          dirty,
        }) => (
          <Form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Row>
              <div className="screenHeight d-flex justify-content-center align-items-center">
                <Col lg={9} md={9} sm={12} xs={12} className="Form-Shadow ">
                  <div className="arrowback ">
                    <BackArrow
                      back="/SellingSide"
                      black="black"
                      mystyle="p-1 ps-4"
                    />
                  </div>
                  <div className="p-3">
                    <Col md={10} lg={11} xl={11} className="ps-0">
                      <div className="d-flex justify-content-center pt-2">
                        {/* <div>
                                <AddIcon className="iconWhite plus-icon " />
                              </div> */}
                      </div>
                    </Col>

                    <Row className="pt-3 ps-3 pe-3">
                      <Col md={3} lg={3} xl={3} className="bluedrop5">
                        <Form.Label className="custm-lble ">
                          Scrape Type
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="scrapTypeid"
                          className="input-style form-control-seller "
                          onChange={(e) => {
                            InputChangetwo(e);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          <option>Scrape type</option>
                          {scrapcategory?.map((category) => (
                            <>
                              <option
                                key={category._id}
                                value={`${category?._id}.${category?.name}`}
                              >
                                {category?.name}
                              </option>
                            </>
                          ))}
                        </Form.Select>
                        {errors.scrapTypeid && touched.scrapTypeid ? (
                          <div>
                            <p className="error_text_color">
                              {errors.scrapTypeid}
                            </p>
                          </div>
                        ) : null}
                      </Col>

                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword4"
                          placeholder="First name"
                          className="form-label labelSize"
                        >
                          Country*
                        </label>
                        <div className="blood-type input-style">
                          <div className="d-flex">
                            <div className="w-100">
                              <input
                                type="search"
                                value={searchTerm}
                                onChange={(e) => {
                                  handleSearchChange(e);
                                }}
                                placeholder="Search country here...."
                                className="w-100 search-input"
                                onClick={() => setHidefield(0)}
                              />
                            </div>
                            <div>
                              <span>
                                <SearchIcon className="search-icon-fs" />
                              </span>
                            </div>
                          </div>

                          <select
                            onClick={() => setHidefield(1)}
                            value={selectedCountry}
                            name="country"
                            onChange={(e) => {
                              handleCountryChange(e);
                              handleCountry(e);
                              InputChange(e);
                              handleChange(e);
                            }}
                            className="b-radius selectSize w-100"
                          >
                            {hidefield && <option>Select country</option>}

                            {country
                              .filter((country) =>
                                country
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                              )
                              .map((country, index) => (
                                <option key={country} value={getcountry}>
                                  {country}
                                </option>
                              ))}
                          </select>
                        </div>
                        {errors.country && touched.country ? (
                          <div>
                            <p className="error_text_color">{errors.country}</p>
                          </div>
                        ) : null}
                      </div>

                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword4"
                          placeholder="First name"
                          className="form-label labelSize"
                        >
                          State*
                        </label>
                        <div className="blood-type input-style">
                          <div className="d-flex">
                            <div className="w-100">
                              <input
                                type="search"
                                value={searchTermone}
                                onChange={handleSearchChangestate}
                                placeholder="Search state here...."
                                className="w-100 search-input"
                                onClick={() => setHidestate(0)}
                              />
                            </div>
                            <div>
                              <span>
                                <SearchIcon className="search-icon-fs" />
                              </span>
                            </div>
                          </div>

                          <select
                            onClick={() => setHidestate(1)}
                            className="b-radius selectSize w-100"
                            value={selectedstateone}
                            aria-label="Default select example "
                            name="state"
                            onChange={(e) => {
                              handleState(e);
                              handleCountryChangestate(e);
                              InputChange(e);
                              handleChange(e);
                            }}
                          >
                            {hidestate && <option>Select state</option>}
                            {/* <option>
                            {Open === undefined
                              ? "Select Country First"
                              : "select state"}
                          </option> */}

                            {getState
                              .filter((country) =>
                                country
                                  .toLowerCase()
                                  .includes(searchTermone.toLowerCase())
                              )
                              .map((country) => (
                                <option key={country} value={selectedState}>
                                  {country}
                                </option>
                              ))}
                          </select>
                        </div>
                        {errors.state && touched.state ? (
                          <div>
                            <p className="error_text_color">{errors.state}</p>
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword4"
                          placeholder="First name"
                          className="form-label labelSize"
                        >
                          City
                        </label>
                        <div className="blood-type input-style">
                          <div className="d-flex">
                            <div className="w-100">
                              <input
                                type="search"
                                value={searchTermtwo}
                                onChange={handleSearchChangecity}
                                placeholder="Search city here...."
                                className="w-100 search-input"
                                onClick={() => setHidecity(0)}
                              />
                            </div>
                            <div>
                              <span>
                                <SearchIcon className="search-icon-fs" />
                              </span>
                            </div>
                          </div>

                          <select
                            onClick={() => setHidecity(1)}
                            value={selectedcity}
                            className="b-radius selectSize w-100"
                            aria-label="Default select example"
                            name="city"
                            onChange={(e) => {
                              handleCountryChangecity(e);
                              InputChange(e);
                              handleChange(e);
                            }}
                          >
                            {/* <option>
                              {city === undefined
                                ? "Select State First"
                                : "Select City"}
                            </option> */}

                            {hidecity && <option>Select city</option>}

                            {getcity
                              .filter((country) =>
                                country.name
                                  .toLowerCase()
                                  .includes(searchTermtwo.toLowerCase())
                              )
                              .map((country) => (
                                <option key={country.name}>
                                  {country.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {errors.city && touched.city ? (
                          <div>
                            <p className="error_text_color">{errors.city}</p>
                          </div>
                        ) : null}
                      </div>

                      <Col md={3} lg={3} xl={3}>
                        <Form.Label className="custm-lble mt-3">
                          Quantity
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="quantity"
                          placeholder="Quantity"
                          className="input-style form-control-seller"
                          onChange={(e) => {
                            handleChange(e);
                            InputChange(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.quantity && touched.quantity ? (
                          <div>
                            <p className="error_text_color">
                              {errors.quantity}
                            </p>
                          </div>
                        ) : null}
                      </Col>
                      <Col md={3} lg={3} xl={3}>
                        <Form.Label className="custm-lble mt-3">
                          Price
                        </Form.Label>
                        <Form.Control
                          placeholder="Price"
                          type="number"
                          name="price"
                          className="input-style form-control-seller"
                          onChange={(e) => {
                            handleChange(e);
                            InputChange(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.price && touched.price ? (
                          <div>
                            <p className="error_text_color">{errors.price}</p>
                          </div>
                        ) : null}
                      </Col>

                      <Col md={3} lg={3} xl={3} className="bluedrop6">
                        <Form.Label className="custm-lble mt-3 ">
                          Unit of Measurement
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="unitOfMeasurement"
                          className="input-style form-select-seller"
                          onChange={(e) => {
                            handleChange(e);
                            InputChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {Measurementunit.map((e) => (
                            <option>{e.name}</option>
                          ))}
                        </Form.Select>
                        {errors.unitOfMeasurement &&
                          touched.unitOfMeasurement ? (
                          <div>
                            <p className="error_text_color">
                              {errors.unitOfMeasurement}
                            </p>
                          </div>
                        ) : null}
                      </Col>

                      <Col md={3} lg={3} xl={3} className="bluedrop6">
                        <Form.Label className="custm-lble mt-3">
                          Currency
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="currency"
                          className="input-style form-select-seller"
                          onChange={(e) => {
                            handleChange(e);
                            InputChange(e);
                          }}
                        >
                          {currencies.map((e) => (
                            <option>
                              {e.abbreviation} {e.name}
                            </option>
                          ))}
                        </Form.Select>
                        {errors.currency && touched.currency ? (
                          <div>
                            <p className="error_text_color">
                              {errors.currency}
                            </p>
                          </div>
                        ) : null}
                      </Col>
                    </Row>

                    <div className="ps-3 pe-3 mt-3">
                      <Form.Label className="custm-lble pb-2">
                        Job Description
                      </Form.Label>
                      <Form.Control
                        name="description"
                        as="textarea"
                        rows={9}
                        className="input-style"
                        onChange={(e) => {
                          handleChange(e);
                          InputChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.description && touched.description ? (
                        <div>
                          <p className="error_text_color">
                            {errors.description}
                          </p>
                        </div>
                      ) : null}
                    </div>

                    <Col>
                      <Row>
                        <Col lg={2} className="mt-2 pt-4 ps-4">
                          <Card
                            style={{ width: "6rem", height: "6rem" }}
                            onClick={upload_img}
                          >
                            <Card.Img
                              className="imet-fit"
                              src={images.AddImage}
                            />
                            <Card.Body>
                              <input
                                type="file"
                                id="file"
                                ref={inputfile}
                                style={{ display: "none" }}
                                name="images"
                                onChange={(e) => {
                                  onChangePicture(e);
                                  handleChange(e);
                                }}
                              />
                            </Card.Body>
                          </Card>
                          {errors.images && touched.images ? (
                            <div>
                              <p className="error_text_color">
                                {errors.images}
                              </p>
                            </div>
                          ) : null}
                        </Col>
                        <Col lg={10} className=" d-flex gap-2 pt-4">
                          {uploadedImages.map((image, index) => (
                            <Card
                              style={{
                                width: "6rem",
                                height: "6rem",
                                border: "2px solid",
                              }}
                              key={index}
                            >
                              <Card.Img
                                className="imet-fit upload-img"
                                src={image}
                                alt={`Uploaded ${index}`}
                              />
                              <div
                                className="close-btnone"
                                onClick={() => removeImage(index)}
                              >
                                <ClearIcon />
                              </div>
                            </Card>
                          ))}
                        </Col>
                      </Row>
                    </Col>

                    <div className="d-flex justify-content-end py-3 ">
                      <div>
                        <div className="btns pe-4 pb-4">
                          <button
                            className="btn1"
                            onClick={() => navigate("/SellingSide")}
                          >
                            Cancel
                          </button>
                          <Button
                            disabled={!(isValid && dirty)}
                            type="submit"
                            className="disable-btn text-white  w-50"
                            data-title={
                              !(isValid && dirty)
                                ? "Please fill out all Fields to enable button!"
                                : null
                            }
                            onClick={handleFunction}
                          >
                            Post
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Scrapematerial;
