import React from "react";
import BoxData from "../Box";
import CardNav from "../CardNav";
import { Col, Container, Row, Card } from "react-bootstrap";
import { images } from "../../../../constants";
import { useState } from "react";

const Transport = ({ btn }) => {
  const [id,setIs]=useState(1);
  const [myinfo,setMyinfo]=useState("Transport info")
  return (
    <div>
      <Row>
        <Col lg="12">
          <CardNav Header="Transport Provider" myid={id} myinfo={myinfo} />
          <Card className="shadow-main mt-2">
            <BoxData
              Title="Transport Service"
              Butonn={btn}
              imgg={images.TransportServices}
              url="/transport-integrate"
              link="/find-Transport"
              ms="twenty-element"
              buy="seventeen"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Transport;
