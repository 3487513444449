import { userShopITems } from "../../constants/jsonConstant";
import { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Row, Col } from "react-bootstrap";
import ShopAdds from "../shopAds/ShopAdds";
import { useNavigate } from "react-router-dom";
import {Form, FormControl,Dropdown} from "react-bootstrap"; 
import images from "../../constants/images"
import UserPropertyViewModel from "../../components/ShopViewModel/UserPropertyViewModel";
import { Categories } from "../../constants/jsonConstant";
import { ShopCategory } from "../../pages/index";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import CandidatesBox from "../candidatesBox";
import Addtocartmodal from "../Addtocartmodal";
import { getAsset } from "../../helpers/helper";
import url from "../../config/axios";
import BackArrow from "../backArrow";
const UserPropertyItems = ({itemData}) => {
  const Navigate = useNavigate();
  const [showModel, setShowModel] = useState(1);
  const [show, setShow] = useState(0);
  const handleClose = () => setShow(0);
  const handleClosetwo = () => setShow(0);
  const [key, setKey] = useState("Items");
  const [showcategory, setShowcategory] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [itemsdata, setItemsData] = useState();
  const [propertysearch,setPropertysearch]=useState();
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [searchItems] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
  ]);

  const handleFocus = () => {
    setShowDropdown(true);
  };

  const handleBlur = () => {
    // Delay the dropdown hide to allow clicks to register on dropdown items
    setTimeout(() => setShowDropdown(false), 100);
  };


  const handleShowModal = () => {
    setShowModal(true);
    setShow(0);
  };

  const [showoneModal, setShowoneModal] = useState(false);

  const handleTabSelect = (eventKey) => {
    if (eventKey === "tab1") {
      setShowoneModal(true);
    }
  };

const filterProperty=(e)=>{

url.get(`company/searchbyname/?businessType=businessPropertyCompany&subCategory=${e}`).then((response) => {
  
  setPropertysearch(response?.data?.data)
});

}

  const handleCloses = () => {
    setShowoneModal(false);
  };
  useEffect(() => {
    url.get(`/property/id/?id=${show}`).then((response) => {
      console.log("itemmm res", response)
      setItemsData(response?.data?.data?.Property)
    });
  }, [show]);
  console.log("modalllll itemmmm", itemsdata)

  return (
    <>
      {/* <CandidatesBox showoneModal={showoneModal} handleClose={handleCloses} /> */}
      <Addtocartmodal
        handleCloseModal={handleCloseModal}
        showModal={showModal}

      />
      
      <BackArrow back="/find-property" />
      <ShopAdds />
      <div className="me-3 ms-3 border-top" onBlur={() => setShowcategory(0)}>
        <UserPropertyViewModel
          show={show}
          handleClosetwo={handleClosetwo}
          handleShowModal={handleShowModal}
          handleClose={handleClose}
          itemsdata={itemsdata}
        />

        <div className="position-relative">
        <Tabs
          activeKey={key}
          id="uncontrolled-tab-example"
          onSelect={(k) => handleTabSelect(k)}
          className="mb-1"
        >
          <Tab eventKey="Items" title="Items" className="jobviewhead">

            <div className="  noscroll shop-scroll ">
              <div className="row ps-2 pe-2">
                {
                propertysearch && propertysearch.length>0 ?
                propertysearch?.map((Items) => (
                  <div className="col-3 pb-2 ps-2 pe-2 pt-1">
                    <div
                      key={Items?.id}
                      className="ShopsCards ms-3 me-3 mt-3 mb-3"
                    >
                      <img className="card-img"
                        crossOrigin="anonymous"
                        src={getAsset(Items?.image[0])} alt="" />
                      <div className="border">
                        <div className="">
                          <Row className="align-items-center pt-2">
                            <Col>
                              <div>
                                <p className="mb-0 ps-2 pe-2 items-card-font fw-bold">
                                  {Items?.subCategory}
                                </p>
                              </div>
                            </Col>
                          </Row>

                          <Row className="ps-2 pe-3">
                            <Col lg={6} className="ps-1 pe-0">
                              <div>
                                <p className="mb-0 ps-2 pe-2 pt-1  items-card-font font-blue">
                                  {Items?.price}
                                </p>
                              </div>
                            </Col>
                            <Col lg={6} className="p-0">
                              <div>
                                <img className="" src={images?.StarsShop} alt="" />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="d-flex justify-content-around pt-1 pb-1 mb-1 mt-1">
                          <p
                            className="mb-0 ps-lg-4 pe-lg-4 view_btn-User"
                            onClick={() => setShow(Items?._id)}
                          >
                            View
                          </p>
                        </div>
                        <div className="margin-Boxes">
                          <Row className="row bg-blue ">
                            <Col lg={6} className="p-0">
                              <div className="border-end cursor">
                                <p className="mb-0 ps-1 d-flex p-1">
                                  <img
                                    className="w-img-card"
                                    src={images?.Mail}
                                  />
                                  <span className="btns text-white ps-1">
                                    Message
                                  </span>
                                </p>
                              </div>
                            </Col>
                            <Col lg={6} className="p-0">
                              <div className="cursor">
                                <p className="mb-0 ps-1 d-flex p-1">
                                  <img
                                    className="w-img-card"
                                    src={images.Phone}
                                  />
                                  <span className="btns text-white ps-1">
                                    Call
                                  </span>
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                ))

                :
                itemData?.itemdata?.map((Items) => (
                  <div className="col-3 pb-2 ps-2 pe-2 pt-1">
                    <div
                      key={Items?.id}
                      className="ShopsCards ms-3 me-3 mt-3 mb-3"
                    >
                      <img className="card-img"
                        crossOrigin="anonymous"
                        src={getAsset(Items?.image[0])} alt="" />
                      <div className="border">
                        <div className="">
                          <Row className="align-items-center pt-2">
                            <Col>
                              <div>
                                <p className="mb-0 ps-2 pe-2 items-card-font fw-bold">
                                  {Items?.subCategory}
                                </p>
                              </div>
                            </Col>
                          </Row>

                          <Row className="ps-2 pe-3">
                            <Col lg={6} className="ps-1 pe-0">
                              <div>
                                <p className="mb-0 ps-2 pe-2 pt-1  items-card-font font-blue">
                                  {Items?.price}
                                </p>
                              </div>
                            </Col>
                            <Col lg={6} className="p-0">
                              <div>
                                <img className="" src={images?.StarsShop} alt="" />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="d-flex justify-content-around pt-1 pb-1 mb-1 mt-1">
                          <p
                            className="mb-0 ps-lg-4 pe-lg-4 view_btn-User"
                            onClick={() => setShow(Items?._id)}
                          >
                            View
                          </p>
                        </div>
                        <div className="margin-Boxes">
                          <Row className="row bg-blue ">
                            <Col lg={6} className="p-0">
                              <div className="border-end cursor">
                                <p className="mb-0 ps-1 d-flex p-1">
                                  <img
                                    className="w-img-card"
                                    src={images?.Mail}
                                  />
                                  <span className="btns text-white ps-1">
                                    Message
                                  </span>
                                </p>
                              </div>
                            </Col>
                            <Col lg={6} className="p-0">
                              <div className="cursor">
                                <p className="mb-0 ps-1 d-flex p-1">
                                  <img
                                    className="w-img-card"
                                    src={images.Phone}
                                  />
                                  <span className="btns text-white ps-1">
                                    Call
                                  </span>
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                
                
                }
              </div>
            </div>
          </Tab>
          {/* <Tab eventKey="tab1" title="Connects" variant="primary"></Tab> */}
        </Tabs>

        <div className="position-absolute search-absolute mt-1">
              <Form
                className="position-relative"
                onFocus={handleFocus}
                onBlur={handleBlur}
              >
                <FormControl
                  type="text"
                  placeholder="Search"
                  className="mr-sm-2 search-buing-area"
                  name="subCategory"
                  onChange={(e) => {filterProperty(e.target.value)}}
                />
              
              </Form>
            </div>

        </div>
       
      </div>
    </>
  );
};

export default UserPropertyItems;
