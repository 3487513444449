import { Button, Col, Row } from "react-bootstrap";
import "./ads.css";
import Carousel from "react-bootstrap/Carousel";
import { images } from "../../constants";
import backArrow from "../backArrow";
import { Navigate, useNavigate } from "react-router-dom";
import ShopManage from "../../pages/Manage_Shop/Component/ShopManage";
const ShopAdds = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <Row>
        <Col sm={12} md={12} lg={12} xl={12} className="pe-0 ps-0">
          <div className=" ps-4 pe-4 pb-3 ">
            <div className="d-flex pb-2 pt-2">
              <backArrow back={props.back} black="black" />
              {/* <Button
                className="pt-0 pb-0"
                onClick={() => navigate(`/ShopManage/${props.shopid}`)}
              >
                Give admin rights for this shop
              </Button> */}
            </div>
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-100 custm_img_h"
                  src={images.p6}
                  alt="First slide"
                />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 custm_img_h"
                  src={images.p3}
                  alt="Second slide"
                />

                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 custm_img_h"
                  src={images.p1}
                  alt="Third slide"
                />

                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 custm_img_h"
                  src={images.p4}
                  alt="Fourth slide"
                />

                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ShopAdds;
