import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { persistor } from "../../store/index";

const Logoutmodal = ({ show, handleClose }) => {

  const navigate = useNavigate();

  const purge = () => {
    persistor.purge();
    navigate("/");
}
  return (
    <> 
      <Modal
        show={show}
        animation={true}
        onHide={handleClose}
        className="mt-5 pt-5"
      >
        <Modal.Body className="col-8 mx-auto text-center p-t-3 pb-3">
          <h5 className="mt-4">
            Are You Sure You Want to
            <span style={{ color: "#3D3BEE" }}>Logout</span>
          </h5>
        </Modal.Body>
        <div className="d-flex justify-content-center pb-4">
          <button className="post-btn" onClick={handleClose}>
            Cancel
          </button>
          <button className="ms-3 cancle-btn" onClick={() => purge()}>
            Yes
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Logoutmodal;
