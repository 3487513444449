import "./avaliblemodel.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { Container } from "react-bootstrap";
const AvailabilityModel = ({ showavaliable, handleClose, setShowavaliable }) => {
  return (
    <>
      <Container>
        <Modal
          show={showavaliable}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton className="bg-blue "></Modal.Header>
          <Modal.Body className="p-5 text-center">
            <p className="font_13">
              <b>
                Set your availability to show you costumer's that you are
                available right now or not
              </b>
            </p>
            <div className="mt-4">
              <button className="btn-avaliable">Avaliable</button>
              <button className="btn-working  ms-3">Working</button>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button className="btn-avaliable" onClick={() => setShowavaliable(false)}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default AvailabilityModel;
