import React from "react";
import "./userChatroom.css";
import { images } from "../../constants";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const UserChatroom = ({btn,title}) => {
  const navigate = useNavigate();
  const inputFile = useRef(null);

  const onButtonClick = () => {
    inputFile.current.click();
  };

  return (
    <>
      <div className="container">
        <div className="screenHeight col-11 mx-auto">
          <div className="row">
            {/* Inner_form */}
            <div
              style={{ background: "var(--Secondary-color)" }}
              className="Inner_form col-6 ms-auto me-auto mt-2  p-0"
            >
              <div className="col-2 "></div>
              <p className="Update_text pt-2 pb-2 ps-3 pe-3">{title} </p>

              {/* labels_Inputs_User */}

              <label className="Input_lable ps-3 pe-3">User Name </label>
              <div className="labels_Inputs ps-3 pe-3">
                <input
                  placeholder="Name"
                  className="Input_user pt-1 pb-1 ps-1 pt-sm-2 pb-sm-2 ps-sm-2
                         pt-md-2 pb-md-2 ps-md-2  pt-lg-2 pb-lg-2 ps-lg-3 pt-xl-2 pb-xl-2 ps-xl-3"
                />
              </div>
              {/* lable_Input_ */}

              <label className="Input_Description   pt-2 text-white mb-1 ps-3 pe-3">
                Description{" "}
              </label>
              <div className="labels_Inputs mb-3 ps-3 pe-3">
                <input
                  placeholder="Description"
                  className="Input_Description w-100 pb-2 pt-2 ps-1 pb-sm-3 pt-sm-2 ps-sm-2 
                        pb-md-4 pt-md-2 ps-md-2 pb-lg-5 pt-lg-3 ps-lg-3 pb-xl-5 pt-xl-3 ps-x-3"
                />
              </div>

              {/* image_Section */}
              <div className="image_Section ps-3 pe-3">
                <div className="paragh ">
                  <p className="pt-2  Avatar_text ">
                    Select Avatar or Upload Image
                  </p>
                </div>

                <div className="row ps-1 pe-1">
                  <div className="col-3">
                    <div className="img-frame ms-1 me-2 mb-2">
                      <img
                        src={images.Chatroomimg}
                        className="img_Avatar "
                        alt="ChatroomAvater"
                      ></img>
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="img-frame ms-1 me-2 mb-2">
                      <img
                        src={images.Chatroomimg8}
                        className="img_Avatar "
                        alt="ChatroomAvater"
                      ></img>
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="img-frame ms-1 me-2 mb-2">
                      <img
                        src={images.Chatroomimg2}
                        className="img_Avatar "
                        alt="ChatroomAvater"
                      ></img>
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="img-frame ms-1 me-2 mb-2">
                      <img
                        src={images.Chatroomimg3}
                        className="img_Avatar "
                        alt="ChatroomAvater"
                      ></img>
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="img-frame ms-1 me-2 mb-2">
                      <img
                        src={images.Chatroomimg4}
                        className="img_Avatar "
                        alt="ChatroomAvater"
                      ></img>
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="img-frame ms-1 me-2 mb-2">
                      <img
                        src={images.Chatroomimg5}
                        className="img_Avatar "
                        alt="ChatroomAvater"
                      ></img>
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="img-frame ms-1 me-2 mb-2">
                      <img
                        src={images.Chatroomimg6}
                        className="img_Avatar "
                        alt="ChatroomAvater"
                      ></img>
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="img-frame ms-1 me-2 mb-2">
                      <img
                        src={images.Chatroomimg7}
                        className="img_Avatar "
                        alt="ChatroomAvater"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              {/* btn_Section */}
              <div className="btn_Section">
                <div>
                  <p className="Paragh-2 pt-2 ps-4 pe-2">Upload Image</p>
                </div>
                <div className="cam ps-4">
                  <div onClick={onButtonClick}>
                    <input
                      type="file"
                      id="file"
                      ref={inputFile}
                      style={{ display: "none" }}
                    />

                    <FileUploadIcon className="text-white font_40" />
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <button
                    className=" text-white   pt-2 pb-2 create_btn_update text-white mt-2 mb-4"
                    onClick={() => navigate("/own-chatroom")}
                  >
                    {btn}
                  </button>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserChatroom;
