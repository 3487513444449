import { Row, Col } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
const CVProfileBio = ({ cvdata }) => {
  const now1 = 80;
  const now2 = 65;
  const now3 = 100;
  const now4 = 70;

  return (
    <Row>
      <Col md={8} lg={7} xl={6}>
        <div className="profile-bio-scroll-2 noscroll">
          <div>
            <div className="CV-green-back">
              <h6 className="py-3 text-white ps-2">
                Previous Employement Details
              </h6>
            </div>
            {cvdata?.preemphistory?.map((item) => (
              <Row>
                <Col md={6} lg={6}>
                  <div>
                    <p className="updateSize mb-0 mb-0">Company Name</p>
                    <p className="text-designation-color">
                      {item?.companyname}
                    </p>
                  </div>
                  <div>
                    <p className="updateSize mb-0">Start Date</p>
                    <p className="text-designation-color">{item?.startdate}</p>
                  </div>
                  <div>
                    <p className="updateSize mb-0">Industry</p>
                    <p className="text-designation-color">{item?.industry}</p>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div>
                    <p className="updateSize mb-0">Designation</p>
                    <p className="text-designation-color">
                      {item?.designation}
                    </p>
                  </div>
                  <div>
                    <p className="updateSize mb-0">End Date</p>
                    <p className="text-designation-color">{item?.enddate}</p>
                  </div>
                </Col>
              </Row>
            ))}

            <hr />
          </div>

          <div>
            <div className="CV-green-back">
              <h6 className="py-3 text-white ps-2">Formal Education</h6>
            </div>
            {cvdata?.formaleducation?.map((item) => (
              <Row>
                <Col md={6} lg={6}>
                  <div>
                    <p className="updateSize mb-0">Institute Name</p>
                    <p className="text-designation-color">
                      {item?.institutename}
                    </p>
                  </div>
                  <div>
                    <p className="updateSize mb-0">Qualification</p>
                    <p className="text-designation-color">
                      {item?.qualification}
                    </p>
                  </div>
                  <div>
                    <p className="updateSize mb-0">Start Date</p>
                    <p className="text-designation-color"> {item?.startdate}</p>
                  </div>
                  <div>
                    <p className="updateSize mb-0">Degree Status</p>
                    <p className="text-designation-color">
                      {item?.degreestatus}
                    </p>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div>
                    <p className="updateSize mb-0">Major</p>
                    <p className="text-designation-color">{item?.major}</p>
                  </div>
                  <div>
                    <p className="updateSize mb-0">Type of Study</p>
                    <p className="text-designation-color">
                      {item?.typeofstudy}
                    </p>
                  </div>
                  <div>
                    <p className="updateSize mb-0">End Date</p>
                    <p className="text-designation-color">{item?.enddate}</p>
                  </div>
                </Col>
              </Row>
            ))}
          </div>
          <div>
            <div className="CV-green-back">
              <h6 className="py-3 text-white ps-2">Professional Skills</h6>
            </div>
            <Row>
              <Col md={6} lg={6}>
              {
  cvdata?.profesionalskils?.map((item) => (
    <div>
      <p>{item}</p>
    </div>
  ))
} 
              </Col>
              {/* <Col md={6} lg={6}>
                <div className="pb-4">
                  <ProgressBar
                    now={now1}
                    label={`${now1}%`}
                    variant="success"
                  />
                </div>
                <div className="pb-3">
                  <ProgressBar
                    now={now2}
                    label={`${now2}%`}
                    variant="success"
                  />
                </div>
                <div className="pb-3">
                  <ProgressBar
                    now={now3}
                    label={`${now3}%`}
                    variant="success"
                  />
                </div>
                <div>
                  <ProgressBar
                    now={now4}
                    label={`${now4}%`}
                    variant="success"
                  />
                </div>
              </Col> */}
            </Row>
          </div>
          <div>
            <div className="CV-green-back">
              <h6 className="py-3 text-white ps-2">Language Skills</h6>
            </div>
            {cvdata?.languageskils?.map((item) => (
              <Row>
                <Col md={6} lg={6}>
                  <div>
                    <p className="updateSize mb-0">Language</p>
                    <p className="text-designation-color">{item?.language}</p>
                  </div>
                  <div>
                    <p className="updateSize mb-0">Reading proficiency</p>
                    <p className="text-designation-color">
                      {item?.readingproficiency}
                    </p>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div>
                    <p className="updateSize mb-0">Speaking Proficiency</p>
                    <p className="text-designation-color">
                      {item?.speakingproficiency}
                    </p>
                  </div>
                  <div>
                    <p className="updateSize mb-0">Writing Proficiency</p>
                    <p className="text-designation-color">
                      {item?.writingproficiency}
                    </p>
                  </div>
                </Col>
              </Row>
            ))}

            <hr />
          </div>

          <div>
            <div className="CV-green-back">
              <h6 className="py-3 text-white ps-2">Certifications/Licences</h6>
            </div>
            {cvdata?.certification?.map((item) => (
              <Row>
                <Col md={6} lg={6}>
                  <div>
                    <p className="updateSize mb-0">Name of Certifications</p>
                    <p className="text-designation-color">
                      {item?.nameofcertificate}
                    </p>
                  </div>
                  <div>
                    <p className="updateSize mb-0">Issuing Authority</p>
                    <p className="text-designation-color">
                      {item?.issuingauthority}
                    </p>
                  </div>
                  <div>
                    <p className="updateSize mb-0">Expiring Date</p>
                    <p className="text-designation-color">
                      {item?.expirationdate}
                    </p>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div>
                    <p className="updateSize mb-0">Institution</p>
                    <p className="text-designation-color">
                      {item?.institution}
                    </p>
                  </div>
                  <div>
                    <p className="updateSize mb-0">Effective Date</p>
                    <p className="text-designation-color">
                      {item?.effectivedate}
                    </p>
                  </div>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default CVProfileBio;
