import "../../components/Suggestionbox/suggest.css";
import { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Col,Row,Card } from "react-bootstrap";
import ClearIcon from "@mui/icons-material/Clear";
import PersonalImage from "../../helpers/fileUpload";
import { images } from "../../constants";
import {useState } from "react";
import { publishImage } from "../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
import { getAsset } from "../../helpers/helper";
import { FailedNotify } from "../../constants/tostify";

const Reportselectezibox = ({ selectezireport,setSelectezireport }) => {

  const [displayimg,setDisplayimg]=useState();

   const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      // setUserData((userData) => ({
      //   ...userData,
      //   images: abc
      // }));

      setDisplayimg(abc);
    
    }catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });
  const removeImage = (index) => {
    const updatedImages = [...displayimg];
    updatedImages.splice(index, 1);
    setDisplayimg(updatedImages);
  };
 
  return (
    <>
      <Modal
        size="lg"
        show={selectezireport}
        onHide={() => setSelectezireport(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton className="bg-blue-lg">
          <Modal.Title id="example-modal-sizes-title-lg">
          <h5 className="text-center text-white">Report to Selectezi Team</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="col-11 mx-auto">
          <Row>
            <Col lg="6" className="text-start">
            <h6>Please Select a Problem to Continue</h6>
             <p className="shop-profile-para">Select to continue</p>
             <Col lg="8">
             <select className="form-select p-1 input-style" aria-label="Default select example">
                <option selected>Select Option</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
             </Col>
            </Col>
            <Col lg="6" className="text-end">
            <h6 className="text-white">/</h6>
            <p className="shop-profile-para mt-1">You can report after selecting problem</p>
             <Col lg="8 ms-auto">
             <select className="form-select p-1 input-style" aria-label="Default select example">
                <option selected>Select Option</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
             </Col>
            </Col>
          </Row>
            
             


              <label for="exampleFormControlTextarea1" class="form-label mt-2 font-weight-bold font_11">Report Description*</label>
              <textarea class="form-control input-style" id="exampleFormControlTextarea1" rows="8" style={{ border: "1px solid #C4C4C4" }} placeholder="Type your message here.."></textarea>
              <div className="row mt-2">

                <div className="col-6 ps-3">
                  <h6>Add Screenshots*</h6>
                  <div className="row mt-2">
                    
                  <Col lg="9" className="me-auto">
                    <Row>
                      <Col lg={3} className="mt-2 ms-1">
                        <Card
                          style={{ width: "6rem", height: "6rem" }}
                          {...getRootProps()}
                          className="dropzone"
                        >
                          <Card.Img
                            className="imet-fit"
                            src={images.AddImage}
                          />
                          <Card.Body>
                            <input
                              type="file"
                              id="file"
                              style={{ display: "none" }}
                              {...getInputProps()}
                              multiple
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                      {
displayimg?.length > 0 && (
<Col lg={7} className=" mt-1 d-flex">
                      

                      {displayimg.map((image,index) => (
                        <Card
                          className="me-2 mt-1"
                          style={{
                            width: "6rem",
                            height: "6rem",
                            border: "2px solid",
                          }}
                          
                        >
                          <Card.Img
                            className="imet-fit upload-img mt-0"
                            src={getAsset(image)}
                            alt=""
                            crossOrigin="anonymous"
                          />
                          <div
                            className="close-btnone"
                            onClick={() => removeImage(index)}
                          >
                            <ClearIcon />
                          </div>
                        </Card>
                      ))}
                    </Col>	
)

                      }
                      
                    </Row>
                  </Col>

                  </div>
                </div>

                <div className="col-6 text-end pr-0 mt-5">
                  <button className="btn-red-sm  mt-5" onClick={()=>setSelectezireport(false)}>Cancle</button>
                  <button className="btn-blue-sm  ms-2 mt-5">Send</button>
                </div>

              </div>


            </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Reportselectezibox;
