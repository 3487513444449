import React from "react";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import BoxData from "../Box";
import CardNav from "../CardNav";
import { BusinessDatathree } from "../../../../constants/jsonConstant";
import { Col, Container, Row } from "react-bootstrap";
import { images } from "../../../../constants";

const Shops = ({ btn }) => {
  return (
    <div>
      <Row className="set-one">
        {BusinessDatathree.map((data) => (

          < Col lg={4} md={4} sm={data.id === 3 ? (12) : (6)} xs={data.id === 3 ? (12) : (6)}>
            <CardNav Header={data.title} para={data.para} myinfo={data.info} myid={data.id} />

            <Card className="shadow-main mt-2">
              {(data.id === 1 && (
                <BoxData
                  Title="Gems & Jewelry"
                  Butonn={btn}
                  imgg={images.GemStone}
                  url="/gems-integrate"
                  link="/buy-Minerals"
                  ms="eleven-element"
                  buy="eleven"
                />
              )) ||
                (data.id === 2 && (
                  <BoxData
                    Title="Used Items"
                    Butonn={btn}
                    imgg={images.UsedItems}
                    url="/useditems-integrate"
                    link="/buy-used-products"
                    ms="twelve-element"
                    buy="twelve"
                  />
                )) ||
                (data.id === 3 && (
                  <BoxData
                    Title="Homemade"
                    Butonn={btn}
                    imgg={images.Homemade}
                    url="/homemade-integrate"
                    link="/buy-homemade-products"
                    ms="thirteen-element"
                    buy="thirteen"
                  />
                ))}
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Shops;
