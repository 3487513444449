import React from 'react'
import { Container } from 'react-bootstrap'
import "./style.css"

const Sendperchase = () => {
  return (
    <div>
       <Container>
        <div className='mt-2 mb-2'>
        <table class="tables table-bordered">
  <thead>
    <tr className='TABLE'>
    
      <th className='headtxts  text-center '>Requisition</th>
      <th className='headtxts  text-center '>Ship Via</th>
      <th className='headtxts  text-center '>F.O.B</th>
      <th className='headtxts  text-center '> Shipping Terms</th>
     
    </tr>
  </thead>
  <tbody className='tablebody'>
    <tr >
    <td className='Table-font'></td>
      <td className='Table-font'> </td>
      <td className='Table-font'></td>
      <td className='Table-font'></td>
      
   </tr>
  
   
  </tbody>
</table>
        </div>

       </Container>
    </div>
  )
}

export default Sendperchase
