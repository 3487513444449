import { Col, Row, Container } from "react-bootstrap";
import ServicesTransportFilter from "../../../../components/filteration/ServiceTransport";
import ShopAdds from "../../../../components/shopAds/ShopAdds";
import Shops from "../../../../components/Multipleshopprofile/Components/Shops/Shops";
import { useEffect, useState } from "react";
// import axios from "axios";
import url from "../../../../config/axios";
import ShareModel from "../../../../components/Sharemodel";
import ShareOption from "../../../../components/Shareoption";
import BackArrow from "../../../../components/backArrow";

const ServiceFilter = () => {
  const [data, setData] = useState(0);
  const [isloading, setIsloading] = useState();
  const [shopdata, setShopdata] = useState();
  const [showmodal, setShowmodal] = useState(false);
  const handleClosemodal = () => setShowmodal(false);
  const handleShow = () => setShowmodal(true);
  const [dataCategory, setDataCategory] = useState({
    categoryid: "",
  });

  const [shopCategory, setShopCategory] = useState();
  const [shopCategorytwo, setShopCategorytwo] = useState();
  const [showshare, setShowshare] = useState(false);
  const handleCloseshare = () => {
    setShowshare(false);
    setShowmodal(true);
  };

  const handleShowshare = () => setShowshare(true);

  const Handledata = (e) => {
    setDataCategory((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split("."),
    }));
  };

  const categoryName = dataCategory?.categoryid[1];
  const categoryidone = dataCategory.categoryid[0];

  useEffect(() => {
    setAdditionalShopCategoryData((additionalShopCategoryData) => ({
      ...additionalShopCategoryData,
      categoryid: categoryidone !== undefined ? categoryidone : "",
    }));
  }, [categoryidone]);


  useEffect(() => {
    url
      .get("/category/type/?type=services&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });
  }, []);

  const Mydata = () => {
    url
      .get(
        `/category/type/?type=services&parentCategory=${categoryName}`
      )
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategorytwo(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });
  };

  const [additionalShopCategoryData, setAdditionalShopCategoryData] = useState({
    businessType: "services",
    serviceid: "",
    profileName: "",
    categoryid: "",
    subCategoryid: "",
    country: "",
    state: "",
    city: "",
  });

  const Handleadditioanalshop = (e) => {
    setAdditionalShopCategoryData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const FilterHandler = () => {
    setIsloading(true);
    url
      .get(
        `/service/filter/?categoryid=${additionalShopCategoryData?.categoryid}&subCategoryid=${additionalShopCategoryData?.subCategoryid}&country=${additionalShopCategoryData?.country}&state=${additionalShopCategoryData?.state}&city=${additionalShopCategoryData?.city}&serviceid=${additionalShopCategoryData?.serviceid}&businessType=${additionalShopCategoryData?.businessType}&profileName=${additionalShopCategoryData?.profileName}`
      )
      .then(async (response) => {
        if (response?.data?.success) {
          setIsloading(false);
          console.log("dataservicesssss", response);
          // setAdditionalShopCategoryData(response?.data?.data?.Shops);
          setShopdata(response?.data?.data);
        }
      })
      .catch((error) => {
        // console.log("servicesCategories", error);
        setIsloading(false);
      });
  };

  return (
    <>
      <ShareOption showshare={showshare} handleCloseshare={handleCloseshare} />
      <ShareModel
        showmodal={showmodal}
        handleClosemodal={handleClosemodal}
        handleShowshare={handleShowshare}
      />
      <Container>
        <Row>
          <Col lg="4" className="screenHeight background-image">
            <div className="border b-radius height_100">
              <ServicesTransportFilter
                shopCategory={shopCategory}
                Handleadditioanalshop={Handleadditioanalshop}
                FilterHandler={FilterHandler}
                dataCategory={dataCategory}
                title="Search Services by Category & Location"
                category="Search by Category"
                search="Service Category"
                search2="Service Sub-Category"
                subcategory="Search by Sub-Category"
                Handledata={Handledata}
                shopCategorytwo={shopCategorytwo}
                Mydata={Mydata}
              />
            </div>
          </Col>

          <Col lg="8" className="screenHeight ">
            <div className="border b-radius ps-3 pe-3">
              <BackArrow back="/BuyingSide" mystyle="pt-1" />
              <ShopAdds />
              <Shops
                isloading={isloading}
                link="/services-online"
                title="Service Profile"
                data={data}
                handleShow={handleShow}
                shopdata={shopdata}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ServiceFilter;
