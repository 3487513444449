import Button from "react-bootstrap/Button";
import { images } from "../../constants";
import Card from "react-bootstrap/Card";
import { useLocation, useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import BackArrow from "../backArrow";
import { Formik, Form } from "formik";
import ClearIcon from "@mui/icons-material/Clear";
import PersonalImage from "../../helpers/fileUpload";
import { useRef, useState } from "react";
import * as Yup from "yup";
import "../../components/forms/form.css";

const IntegrateForm = ({
  backurl,
  setIntegrateshop,
  shopCategoryone,
  HandlePost,
}) => {
  const navigate = useNavigate();

  const inputfile = useRef(null);
  const upload_img = () => {
    inputfile.current.click();
  };

  const InputChange = (event) => {
    setIntegrateshop((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const validationSchema = Yup.object({
    shopUrl: Yup.string().required("shopUrl is required*"),
    shopname: Yup.string().required("shopname is required*"),
    shopCategory: Yup.string().required("shopCategory is required*"),
    shopaddress: Yup.string().required("shopaddress is required*"),
    description: Yup.string().required("description is required*"),
    image: Yup.string().required("image is required*"),
  });

  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      console.log("mtfile", e.target.files[0]);
      setSelectedImage(e.target.files[0]);
      // PersonalImage(e.target.files[0]); 
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
    }

    if (e.target.files[0]) {
      console.log("mtfile", e.target.files[0]);
      setSelectedImage(e.target.files[0]);
      // PersonalImage(e.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
    }
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setUploadedImages([reader.result]);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const removeImage = (index) => {
    const updatedImages = [...uploadedImages];
    updatedImages.splice(index, 1);
    setUploadedImages(updatedImages);
  };

  return (
    <Row>
      {selectedImage && <PersonalImage image={selectedImage} />}
      <Formik
        initialValues={{
          shopUrl: "",
          shopname: "",
          shopCategory: "",
          shopaddress: "",
          description: "",
          image: [""],
        }}
        // validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={(values) => { }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
          isValid,
          dirty,
        }) => (
          <Form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className="screenHeight d-flex justify-content-center align-items-center">
              <div className="Form-Shadow  col-lg-6 col-md-7 col-sm-9 col-xs-12   pb-3">
                <div className="arrowback ps-3">
                  <BackArrow back={backurl} black="black" mystyle="p-1 ps-4" />
                </div>
                <div className="ps-5 pe-5 pt-1 ms-3 me-3">
                  <div className="pt-4 ">
                    <h4 className="createHeading">
                      Fill To Create Shop Profile
                    </h4>
                  </div>

                  <div className="d-flex justify-content-between pt-1">
                    <div className="d-flex flex-column pt-3 col-6 pe-2">
                      <label className="custm-lble-2 pb-2">Shop Name</label>
                      <input
                        name="shopname"
                        onChange={(e) => {
                          handleChange(e);
                          InputChange(e);
                        }}
                        className="input-style"
                        placeholder="Name"
                        onBlur={handleBlur}
                      />
                      {errors.shopname && touched.shopname ? (
                        <div>
                          <p className="error_text_color">{errors.shopname}</p>
                        </div>
                      ) : null}
                    </div>

                    <div className="d-flex flex-column pt-3 col-6 ps-2 bluedrop2">
                      <label className="custm-lble-2 pb-2">
                        Select Shop Category
                      </label>
                      <div>
                        <select
                          name="shopCategory"
                          placeholder="Select Category"
                          className="input-style w-100 bblue "
                          onChange={(e) => {
                            handleChange(e);
                            InputChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          <option> Shop category </option>
                          {shopCategoryone?.map((category) => (
                            <option key={category._id} value={category?._id}>
                              {category?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.shopCategory && touched.shopCategory ? (
                        <div>
                          <p className="error_text_color">
                            {errors.shopCategory}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="d-flex flex-column pt-3 col-6 pe-2">
                      <label className="custm-lble-2 pb-2">Shop Url</label>
                      <input
                        name="shopUrl"
                        onChange={(e) => {
                          handleChange(e);
                          InputChange(e);
                        }}
                        className="input-style"
                        placeholder="Shop Url"
                        onBlur={handleBlur}
                      />
                      {errors.shopUrl && touched.shopUrl ? (
                        <div>
                          <p className="error_text_color">{errors.shopUrl}</p>
                        </div>
                      ) : null}
                    </div>
                    <div className="d-flex flex-column pt-3 col-6 ">
                      <label className="custm-lble-2 pb-2">Shop Address</label>
                      <input
                        name="shopaddress"
                        onChange={(e) => {
                          handleChange(e);
                          InputChange(e);
                        }}
                        className="input-style"
                        placeholder="Add Address with maps"
                        onBlur={handleBlur}
                      />
                      <div className="position-relative">
                        <img className="iMg" src={images.FindClinic} />
                      </div>

                      {errors.shopaddress && touched.shopaddress ? (
                        <div>
                          <p className="error_text_color">
                            {errors.shopaddress}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div>
                    <div className="d-flex flex-column pt-2">
                      <label className="custm-lble-2 pb-2">Description</label>
                      <textarea
                        name="description"
                        onChange={(e) => {
                          handleChange(e);
                          InputChange(e);
                        }}
                        placeholder="Description"
                        className="input-style pt-1 ps-2 pb-5 pe-2 "
                        onBlur={handleBlur}
                      />
                      {errors.description && touched.description ? (
                        <div>
                          <p className="error_text_color">
                            {errors.description}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <Col>
                    <Row>
                      <Col lg={4} xl={3} className=" mt-2 pe-0">
                        <Card
                          style={{ width: "6rem", height: "6rem" }}
                          onClick={upload_img}
                        >
                          <Card.Img
                            className="imet-fit"
                            src={images.AddImage}
                          />
                          <Card.Body>
                            <input
                              type="file"
                              id="file"
                              ref={inputfile}
                              style={{ display: "none" }}
                              name="image"
                              onChange={(e) => {
                                onChangePicture(e);
                                handleChange(e);
                                InputChange(e);
                              }}
                              onBlur={handleBlur}
                            />
                          </Card.Body>
                        </Card>
                        {errors.image && touched.image ? (
                          <div>
                            <p className="error_text_color">{errors.image}</p>
                          </div>
                        ) : null}
                      </Col>
                      <Col
                        lg={6}
                        xl={7}
                        className=" mt-1 d-flex justify-content-start gap-2 ps-md-3 pe-md-0 ps-0"
                      >
                        {uploadedImages.map((image, index) => (
                          <Card
                            style={{
                              width: "6rem",
                              height: "6rem",
                              border: "2px solid",
                            }}
                            key={index}
                          >
                            <Card.Img
                              className="imet-fit upload-img"
                              src={image}
                              alt={`Uploaded ${index}`}
                            />
                            <div
                              className="close-btnone"
                              onClick={() => removeImage(index)}
                            >
                              <ClearIcon />
                            </div>
                          </Card>
                        ))}
                      </Col>
                    </Row>
                  </Col>

                  <div className="btns">
                    <button
                      className="btn1"
                      onClick={() => navigate("/restaurant-integrate")}
                    >
                      Cancel
                    </button>
                    <Button
                      className="btn2 disable-btn p-1"
                      variant="primary"
                      onClick={HandlePost}
                      disabled={!(isValid && dirty)}
                      data-title={
                        !(isValid && dirty)
                          ? "Please fill out all Fields to enable button!"
                          : null
                      }
                    >
                      Create
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Row>
  );
};
export default IntegrateForm;
