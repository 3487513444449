import { Container,Row,Col } from "react-bootstrap";
import Shopintegrate from "../../components/shop_integrate";


const Gemsintegrate=()=>{

return(<>
<Container>
    <div className="screenHeight">
<Shopintegrate newshop="/mineral-mining-form" url="/gems-integrate-form"/>
    </div>
</Container>

</>)

}

export default Gemsintegrate