import React from "react";
import BackArrow from "../../components/backArrow";
import { Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./Style.css";
const DeliveryForm = (back) => {
  return (
    <div>
      <Container>
        <form>
          <div className="screenHeight d-flex justify-content-center align-items-center">
            <div className="Form-Shadow  col-lg-6 col-md-7 col-sm-9 col-xs-12   pb-5 mb-5">
              <div className="arrowback ps-3">
                <BackArrow
                  back="/RestaurantAlldata"
                  black="black"
                  mystyle="ps-3 p-1"
                />
              </div>
              <Col className="ms-5 ps-5" md={4} lg={4} xl={4}>
                <h5 className="mt-4">Delivery Time</h5>
                <Form.Select
                  className="input-style form-control-seller"
                  aria-label="Default select example"
                >
                  <option>Select Category</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Col>
              <div className="ms-5 ps-5">
                <h5 className="mt-4">Delivery areas </h5>
              </div>
              <Form.Label className="ms-5 mt-4 custm-lble-0">
                Free Delivery
              </Form.Label>
              <Form>
                {["radio"].map((type) => (
                  <div
                    key={`inline-${type}`}
                    className="d-flex ms-5 mt-2 mb-5 pb-5"
                  >
                    <div>
                      <Form.Check
                        inline
                        label="Un Available"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        className="labelText-0"
                      />
                    </div>
                    <div>
                      <Form.Check
                        inline
                        label="Available"
                        name="group1"
                        type={type}
                        id={`inline-${type}-2`}
                        className="labelText-0 "
                      />
                    </div>
                  </div>
                ))}
              </Form>
            </div>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default DeliveryForm;
