import ShopItemsView from "../../../components/shopItemsView/shopItemsView";

const SingleItemOwnerView = () => {
  return (
    <div>
      <ShopItemsView
        update="/single-item-update"
      
        urlone="/singleadd-show"
      />
    </div>
  );
};
export default SingleItemOwnerView;
