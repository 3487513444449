import { Row, Col } from "react-bootstrap";
import StoreIcon from "@mui/icons-material/Store";
import { useNavigate, useLocation } from "react-router-dom";
const Businessprofiletitle = ({ name, linkurl, id, designurl }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <Row className="ps-2 pe-2">
        <Col lg="4" xl="4" className="mt-2">
          <h4 className="mb-0">BusinessProfile</h4>
          <p className="font_small font-grey ">{name}</p>
        </Col>
        <Col lg="8" className="d-flex justify-content-evenly">
          <div>
            <button
              onClick={() => navigate("/BuyingSide")}
              className="d-none d-md-block bussines_connect btn text-white bg-btn-primary font-sm  b-radius mt-2  ps-2 pe-2"
            >
              Connect to Bussiness World
            </button>
          </div>
          <div>
            <button
            onClick={() => navigate(`/connection-request/${id}`)}
              className="btn text-white bg-btn-primary font-sm  b-radius mt-2 ps-2 pe-2"
            >
              View Connection Request
            </button>
          </div>
          <div>
            {location.pathname === "/CreateRestaurantProfile" ? (
              <button
                className=" bussines_connect btn text-white bg-btn-primary font-sm  b-radius mt-2  ps-2 pe-2 pb-1"
                onClick={() => navigate(designurl)}
              >
                <StoreIcon className="icon_f" />
                &nbsp;<b>Visit Shop</b>
              </button>
            ) : (
              <button
                className="btn text-white bg-btn-primary font-sm b  b-radius mt-2  ps-2 pe-2 pb-1"
                onClick={() => navigate(`${linkurl}/${id}`)}
              >
                <StoreIcon className="icon_f" />
                &nbsp;
                <span>
                  {location.pathname === `/company-business-Profile/${id}`
                    ? "Visit Profile"
                    : "Visit Shop"}
                </span>
              </button>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};
export default Businessprofiletitle;
