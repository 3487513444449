import SalePerosonalProperty from "../../../components/PersonalProperty_ADD/SalePerosonalProperty"

const Singleitems=()=>{

return(<>

<SalePerosonalProperty />
</>)

}

export default  Singleitems