import React from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { images } from "../../constants";
const CashModal = ({ cashmdl, handleClose }) => {
  return (
    <>
      <Modal show={cashmdl} onHide={handleClose} animation={false}>
        <Modal.Header closeButton className="clrhead1 py-3">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="ps-3">
            <div>
              <div className="d-flex justify-content-start mt-3">
                <h5>Available payment methods :</h5>
              </div>

              <div className="d-flex justify-content-between mt-3 pe-5 ">
                <img src={images.payment_one} />
                <img src={images.payment_two} />
                <img src={images.payment_three} />
                <img src={images.payment_four} />
                <img src={images.payment_five} />
              </div>
            </div>

            <div className="d-flex justify-content-start mt-3">
              <h5>Delivery Details :</h5>
            </div>
            <Row className="mx-auto">
              <Col lg={6}>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id=""
                    value=""
                    checked
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    Deliver only in Pakistan
                  </label>
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name=""
                    id=""
                    value=""
                    checked
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    Same day delivery
                  </label>
                </div>
              </Col>
            </Row>
            <Row className="mx-auto">
              <Col lg={6}></Col>
              <Col lg={6}>
                <p className="ms-4 mb-0"> Local pickup</p>
                <a className="ms-4 small"> click to view location</a>
              </Col>
            </Row>
            <Row className="mx-auto d-flex flex-column">
              <Col className="mb-2">
                <span className="small">
                  Delivery service : &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                </span>
                <input
                  type="text"
                  className="input-style iiiinputmdl"
                  value="TCS"
                  disabled
                />
              </Col>
              <Col className="">
                <span className="small">
                  Estimated time for develiry : &nbsp; &nbsp;
                </span>
                <input
                  type="text"
                  className="input-style iiiinputmdl"
                  value="7 DAYS"
                  disabled
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default CashModal;
