import React, { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./massengermodalmobile.module.css";
import { IconButton, Menu, MenuItem, ListItemIcon } from "@mui/material";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReplyIcon from "@mui/icons-material/Reply";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ForwardIcon from "@mui/icons-material/Forward";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PushPinIcon from "@mui/icons-material/PushPin";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import ShareIcon from "@mui/icons-material/Share";
import EmojiPicker from "emoji-picker-react";



const MassengerModalMobile = ({ setLgMsgShow, lgMsgShow }) => {
  const messageInputRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const toggleButtonRef = useRef(null);

  const [selectedUser, setSelectedUser] = useState(null);
  const [chatHistory, setChatHistory] = useState({});
  const [ChatStart, setChatStart] = useState(false);
// center section

  const [showEmojiPicker, setShowEmojiPicker] = useState(false); // State to control emoji picker visibility
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeMessage, setActiveMessage] = useState(null);
  const onEmojiClick = (emojiObject) => {
    // Append the emoji to the input field's value
    if (messageInputRef.current) {
      messageInputRef.current.value += emojiObject.emoji;
      messageInputRef.current.focus(); // Keep focus on the input
    }
  };



  // handle clickoutside to close the emojis
  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target) &&
      toggleButtonRef.current &&
      !toggleButtonRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  

  const handleFileUpload = (files) => {
    if (files.length > 0) {
      const file = files[0];
      console.log("Selected file:", file);

      // You can add your file upload logic here, e.g., uploading to a server or showing a preview.
      // Example: Upload file to server or Firebase
      // const formData = new FormData();
      // formData.append('file', file);
      // axios.post('/upload', formData).then(response => {
      //   console.log('File uploaded successfully:', response.data);
      // }).catch(error => {
      //   console.error('Error uploading file:', error);
      // });
    } else {
      console.log("No file selected");
    }
  };

  const handleMenuOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setActiveMessage(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setActiveMessage(null);
  };
  const formatTime = (date) => {
    console.log("Formatting time:", date);
    if (!date) return "Now"; // Return "Now" if date is not provided
    return new Date(date).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };
  

  const sendMessage = (text) => {
    if (text.trim() === "" || !selectedUser) return;

    const newMessage = {
      sender: "me",
      text,
      timestamp: new Date(), // Store the current date and time
    };

    console.log("newMessage", newMessage.timestamp);

    
    
    
    setChatHistory({
      ...chatHistory,
      [selectedUser._id]: [...chatHistory[selectedUser._id], newMessage],
    });
  };













  const users = [
    {
      _id: "1",
      name: "Jones Corporation",
      position: "CEO",
      chatMessage: "Last message from Jones...",
      location: "RWP",
      businessType: "retailerShop",
      profilePic:
        "https://www.shutterstock.com/image-photo/nice-bald-30s-man-look-260nw-2382047591.jpg",
    },
    {
      _id: "2",
      name: "Mark",
      position: "Manager",
      chatMessage: "Last message from Mark...",
      location: "Lahore",
      businessType: "retailerShop",
      profilePic:
        "https://www.shutterstock.com/image-photo/nice-bald-30s-man-look-260nw-2382047591.jpg",
    },
    // More users...
  ];

  const handleClose = () => setLgMsgShow(false);

  const handleUserClick = (user) => {
    setSelectedUser(user);
    if (!chatHistory[user._id]) {
      setChatHistory({
        ...chatHistory,
        [user._id]: [
          { sender: "other", text: "Hello!", timestamp: new Date() },
        ],
      });
    }
  };

  return (
    <>
      {lgMsgShow && (
        <div className={styles.modalOverlay} style={{ width: "100%" }}>
          <div className={`modal-dialog ${styles.modalDialog}`} role="document">
            <div className={`modal-content`}>
            <div className={styles.header}>
  

  {ChatStart === true ? (
    <>
  <span className={styles.backbutton} onClick={()=>setChatStart(false)}><ArrowBackIcon /></span>
    </>
  ):null}
  
  <h2>Messenger Modal</h2>
  
  <div>
    <button className={styles.closeButton} onClick={handleClose}>    
      ×
    </button>
  </div>
</div>



              <div className={`modal-body ${styles.modalBody}`}>
               
               {ChatStart === true ? (
                 <>
                 <div className={styles.centerSection}>
                  {selectedUser ? (
                    <>
                      <div
                        className={styles.personInfo}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={selectedUser.profilePic}
                            alt="User"
                            className={styles.userprofileImage}
                          />
                          <div className={styles.personDetails}>
                            <h6 className={styles.leftinfop}>
                              {selectedUser.name}
                            </h6>
                            <p className={styles.leftinfop}>
                              {selectedUser.position}
                            </p>
                          </div>
                        </div>
                        <div className={styles.rightInfo}>
                          <p className={styles.rightinfop}>
                            {selectedUser.businessType}
                          </p>
                          <p className={styles.rightinfop}>
                            {selectedUser.location}
                          </p>
                        </div>
                      </div>

                      <div className={styles.messageArea}>
                        {chatHistory[selectedUser._id]?.map(
                          (message, index) => (
                            <div
                              key={index}
                              className={`${styles.messageBox} ${
                                message.sender === "me"
                                  ? styles.myMessage
                                  : styles.otherMessage
                              }`}
                            >
                              <div className={styles.messageContent}>
                                <IconButton
                                  onClick={(e) => handleMenuOpen(e, index)}
                                  size="small"
                                  sx={{ marginRight: "8px" }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                <p className={styles.messageText}>
                                  {message.text}
                                </p>
                                <span className={styles.timestamp}>
                                  {formatTime(message.timestamp)}
                                </span>
                              </div>

                              {/* Dropdown Menu */}
                              <Menu
                                anchorEl={anchorEl}
                                open={activeMessage === index}
                                onClose={handleMenuClose}
                                PaperProps={{
                                  style: {
                                    maxHeight: 600,
                                    width: "250px",
                                  },
                                }}
                              >
                                <MenuItem onClick={handleMenuClose}>
                                  <ListItemIcon>
                                    <ReplyIcon fontSize="small" />
                                  </ListItemIcon>
                                  Reply
                                </MenuItem>
                                <MenuItem onClick={handleMenuClose}>
                                  <ListItemIcon>
                                    <ContentCopyIcon fontSize="small" />
                                  </ListItemIcon>
                                  Copy
                                </MenuItem>
                                <MenuItem onClick={handleMenuClose}>
                                  <ListItemIcon>
                                    <SaveAltIcon fontSize="small" />
                                  </ListItemIcon>
                                  Save As
                                </MenuItem>
                                <MenuItem onClick={handleMenuClose}>
                                  <ListItemIcon>
                                    <ForwardIcon fontSize="small" />
                                  </ListItemIcon>
                                  Forward
                                </MenuItem>
                                <MenuItem onClick={handleMenuClose}>
                                  <ListItemIcon>
                                    <StarBorderIcon fontSize="small" />
                                  </ListItemIcon>
                                  Star
                                </MenuItem>
                                <MenuItem onClick={handleMenuClose}>
                                  <ListItemIcon>
                                    <PushPinIcon fontSize="small" />
                                  </ListItemIcon>
                                  Pin
                                </MenuItem>
                                <MenuItem onClick={handleMenuClose}>
                                  <ListItemIcon>
                                    <DeleteIcon fontSize="small" />
                                  </ListItemIcon>
                                  Delete
                                </MenuItem>
                                <MenuItem onClick={handleMenuClose}>
                                  <ListItemIcon>
                                    <SelectAllIcon fontSize="small" />
                                  </ListItemIcon>
                                  Select
                                </MenuItem>
                                <MenuItem onClick={handleMenuClose}>
                                  <ListItemIcon>
                                    <ShareIcon fontSize="small" />
                                  </ListItemIcon>
                                  Share
                                </MenuItem>
                              </Menu>
                            </div>
                          )
                        )}
                      </div>

                      {showEmojiPicker && (
                        <div
                          className={styles.emojiPicker}
                          ref={emojiPickerRef}
                        >
                          <EmojiPicker onEmojiClick={onEmojiClick} />
                        </div>
                      )}

                      <div className={styles.messageInput}>
                        <div className={styles.inputWrapper}>
                          <button
                            className={styles.attachmentButton}
                            onClick={() =>
                              document.getElementById("fileInput").click()
                            }
                          >
                            <AttachFileIcon />
                          </button>
                          <input
                            id="fileInput"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => handleFileUpload(e.target.files)}
                          />
                          <input
                            type="text"
                            className={styles.messageFieldInput}
                            ref={messageInputRef}
                            autoFocus
                            placeholder="Type your message here"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                sendMessage(e.target.value);
                                e.target.value = "";
                              }
                            }}
                          />

                          <InsertEmoticonIcon
                            style={{ color: "#007bff", cursor: "pointer" }}
                            ref={toggleButtonRef}
                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                          />
                          <SendIcon style={{ color: "#007bff" }} />
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Please select a chat to view the messages.</p>
                  )}
                </div>
                 </>
               ):(
                <div className={styles.leftSection}>
                  <div className={styles.searchContainer}>
                    <input
                      type="text"
                      placeholder="Search Contact Here"
                      className={styles.searchInput}
                    />
                  </div>

                  <h6>Recent Chats</h6>
                  <div className={styles.chatList}>
                    {users.map((user) => (
                      <div
                        key={user._id}
                        className={styles.chatItem}
                        onClick={() => {
                          handleUserClick(user)
                          setChatStart(true)
                        }}
                      >
<div className={styles.chatInfo}>
  <img
    src={user.profilePic}
    alt="User"
    className={styles.leftprofileImage}
  />
  <div className={styles.chatDetails}>
    <div className={styles.chatHeader}>
      <span className={styles.userName}>{user.name}</span>
      <span className={styles.timeStampOffChat}>
        {formatTime(chatHistory[user._id]?.[chatHistory[user._id].length - 1]?.timestamp)}
      </span>
    </div>

    <p className={styles.chatMessage}>
      {chatHistory[user._id]?.[chatHistory[user._id].length - 1]?.text}
    </p>
  </div>
</div>

                      </div>
                    ))}
                  </div>
                </div>
              )}
              </div>   
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MassengerModalMobile;