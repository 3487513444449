import { Container, Button, Modal, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import VideocamIcon from "@mui/icons-material/Videocam";
import PersonalImage from "../../helpers/fileUpload";
import Card from "react-bootstrap/Card";
import { publishImage } from "../../helpers/imageupload";
import { FailedNotify, SuccessNotify } from "../../constants/tostify";
import { useDropzone } from "react-dropzone";
import url from "../../config/axios";
import { useSelector } from "react-redux";

const Photovideoupdate = ({
  show,
  CreatePostSubmit,
  createPost,
  setCreatePost,
  setUpdatepost,
  timelineid,
  postId,
  id,
  UpdatePost,
  setUpdatedata,
  updatedata,
}) => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const { name } = useSelector((state) => state.user);
  const [businesstypes, setBusinessTypes] = useState();

  const handleValue = (e) => {
    setUpdatedata((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onDropport = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setUpdatedata((prevState) => ({
        ...prevState,
        img: abc, // Update img with the new array of images
      }));
    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  };

  const onDropvideo = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setUpdatedata((prevState) => ({
        ...prevState,
        video: abc, // Update img with the new array of images
      }));
    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  };

  const { getRootProps: getRootPropsPort, getInputProps: getInputPropsPort } =
    useDropzone({
      accept: "image/*",
      onDrop: onDropport,
    });

  const { getRootProps: getRootPropsVideo, getInputProps: getInputPropsVideo } =
    useDropzone({
      accept: "video/*",
      onDrop: onDropvideo,
    });

  useEffect(() => {
    if (postId) {
      url
        .get(
          `timeline/getSingleTimeline?userid=${name._id}&shopid=${id}&postid=${postId}`
        )
        .then((response) => {
          setUpdatedata(response?.data);
          setBusinessTypes(response?.data[0]?.businessType);
        });
    }
  }, [id, name, timelineid, postId]);

  return (
    <>
      {selectedImage && <PersonalImage image={selectedImage} />}
      <Container>
        <Modal
          show={show}
          onHide={() => setUpdatepost(false)}
          animation={false}
        >
          <Modal.Header closeButton className="font-sm">
            <Modal.Title>
              <h6 className="m-0">Update Photos/Videos</h6>
            </Modal.Title>
          </Modal.Header>

          <Form.Group className="mt-3 ps-3 pe-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              onChange={(e) => handleValue(e)}
              name="heading"
              defaultValue={updatedata?.heading}
            />
          </Form.Group>
          <Modal.Body>
            <div className="form-group">
              <textarea
                className="form-control"
                rows="3"
                name="content"
                placeholder="Write something...."
                onChange={(e) => handleValue(e)}
                defaultValue={updatedata?.content}
              />
            </div>
            <div className="d-flex justify-content-end">
              <div className="text-warning me-2" {...getRootPropsPort()}>
                <CameraAltIcon />
                <input
                  type="file"
                  id="file"
                  name="img"
                  style={{ display: "none" }}
                  {...getInputPropsPort()}
                  multiple
                />
              </div>{" "}
              <div className="text-success" {...getRootPropsVideo()}>
                <VideocamIcon />
                <input
                  type="file"
                  style={{ display: "none" }}
                  name="video"
                  {...getInputPropsVideo()}
                />
              </div>
            </div>
            <div>
              {uploadedImages.map((image, index) => (
                <Card
                  style={{
                    width: "6rem",
                    height: "6rem",
                    border: "2px solid",
                  }}
                  key={index}
                >
                  <Card.Img
                    className="imet-fit upload-img"
                    src={image}
                    alt={`Uploaded ${index}`}
                  />
                </Card>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <Button onClick={() => setUpdatepost(false)} className="cancle-btn">
              Cancel
            </Button>
            <Button className="post-btn" onClick={UpdatePost}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default Photovideoupdate;
