import React from "react";
import { Container } from "react-bootstrap";
import { images } from "../../../../constants";
import "./style.css";

const Chatonline = () => {
  return (
    <div className="mb-3">
      <div className="input-styleone">
        <div className=" d-flex justify-content-center align-items-center mb-2">
          <img className="img_four-div" variant="top" src={images.img_four} />
        </div>
        <div className="d-flex justify-content-center ">
          <h4 className="cht-online">Chat Online with us</h4>
        </div>
        <div className="d-flex justify-content-center fw-bold">
          <p className="m-0 phr">
            Chat online with the Selectezi
            <br />
            <span className="d-flex justify-content-center">
              team, available 24/7.{" "}
            </span>{" "}
          </p>
        </div>
        <div className="d-flex justify-content-center fw-bold fs-4 link-primary mt-4">
          <p>Chat Now</p>
        </div>
      </div>
    </div>
  );
};

export default Chatonline;
