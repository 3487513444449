import React from 'react'
import { Col,  Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import { images } from '../../../../constants/index'
import { JuliaCards } from '../../../../constants/jsonConstant';
import './style.css'

const MainChats = () => {
    return (
        <div>

            <div className='scroll-card-Juli noscroll' >

                <Card className='  border-white rounded-0  ' >
                    {JuliaCards.map((Juli) => (
                        <Card.Body className='p-0'>
                             <div>
                                <hr className='mb-2 '/>
                            </div>
                            <div className='d-flex'>

                                <div className='me-3 ms-2 d-flex justify-content-center align-items-center'>
                                    <Card.Img variant='left' width='30' height='30' src={images.JuliaSideCard} />
                                </div>

                                <div>

                                    <Row>
                                        <Col lg='5' md='5'>
                                            <p className=' Juli-font-name mb-0'>{Juli.name}</p>
                                        </Col>

                                        <Col lg='6' md='5' className=' p-0 d-flex justify-content-end'>

                                            <p className=' Juli-date mb-0'>{Juli.ChatDate}</p>

                                        </Col>

                                    </Row>

                                    <p className='juli-titttle'>{Juli.title}</p>
                                    <Card.Text className='Juli-para'>
                                        {Juli.JuliaPara}
                                    </Card.Text>

                                </div>
                             

                            </div>
                           

                        </Card.Body>
                        


                    ))}
                    
                    

                </Card>

            </div>

        </div>
    )
}

export default MainChats;
