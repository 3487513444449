import "../businessmen-platform/businessmenPlatform.css"
import {Row,Col} from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import { BuyingCard } from "../../../../constants/jsonConstant"
import { useNavigate } from "react-router-dom";
const BuyingCards = () => {
  const navigate = useNavigate()
  return (

    <Row className="ps-3">

      {BuyingCard?.map((cards) => (
        <Col className="  pe-0 ps-0 " >
          <Card
            className=" serviceColmn   mb-3 pe-0 ps-0 " style={{cursor:"pointer"}}
            key={cards.id}
          >
            <div onClick={() => navigate(cards.path)}>

              <div className=" ps-0 pe-0 mt-2 p-2 ">

                <div className={`   ${cards.id === 3 ? 'c_images' : 's_images'}`}>
                  <img src={cards.img} alt="" />
                </div>
                <h5 className="  font-down-cards ">
                  {cards.title}
                </h5>

              </div>

            </div>
          </Card>
        </Col>



      ))}

    </Row>




  )
}
export default BuyingCards;