import "./Post.css";
import {
  Col,
  Row,
  FormControl,
  Button,
  InputGroup,
  Pagination,
  Form,
} from "react-bootstrap";
import url from "../../../../config/axios"
import { useState } from "react";
import { useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { useSelector } from "react-redux";

import {
  Statusmodal,
  Livemodal,
  Photovideo,
  Uploadpost,
} from "../../../../pages/index";
import {
  generalFriendsList,
  timelinemodal,
} from "../../../../constants/jsonConstant";
import { getAsset } from "../../../../helpers/helper";

const Post = ({
  mysetting,
  data,
  CreatePostSubmit,
  setCreatePost,
  createPost,
  show,
  setShow,
  id
}) => {
  console.log("idtesting",id)
  console.log("datatest", data);

  const { name } = useSelector((state) => state.user);
  let active = 2;
  let items = [];
  for (let number = 1; number <= 10; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const ConnectShop = async (profile) => {
    console.log("profile",profile)
    const requestBody = {
      senderid: name._id, // Replace with dynamic user data
      sendershopid: id, // Replace with dynamic shop data
      businessType: profile?.businessType,
      recieverid: profile?.userid,
      recievershopid: profile?._id , // Dynamic or fallback
    };

    try {
      const response = await url.post(
        "conection/sendrequestcoonect/",
        requestBody
      );
      console.log("Connection request sent:", response.data);
      alert("Connection request sent successfully!");
    } catch (error) {
      console.log("Error sending connection request:", error);
      alert("Failed to send connection request.");
    }
  };

  const handleClose = () => setShow(0);
  const location = useLocation();
  return (
    <>
    
      {show === 2 && <Livemodal show={show} handleClose={handleClose} />}
      {show === 4 && (
        <Uploadpost
          show={show}
          handleClose={handleClose}
          CreatePostSubmit={CreatePostSubmit}
          setCreatePost={setCreatePost}
          createPost={createPost}
        />
      )}

      <Col lg="11" md="11">
        <Form inline>
          <div className="search-container">
            <FormControl
              type="text"
              placeholder="Search"
              className="mr-sm-2 mt-2"
            />
            <SearchIcon className="search-icon link-light text-white serachSEt" />
          </div>
        </Form>
        <Row className="pt-xl-3 pt-lg-1 pt-md-3 pt-sm-3 pt-xs-3 display-setting scroll-container">
          {data && data?.length > 0 ? (
            data?.map((profile) => (
              <Col
                lg="2"
                md="2"
                sm="2"
                xs="2"
                className="ps-2 pe-2"
                key={profile?._id}
                onClick={() => ConnectShop(profile)}
              >
                <div className="personImgSize">
                  <img
                    src={
                      profile?.images?.[0]
                        ? getAsset(profile.images[0])
                        : profile?.image?.[0]
                        ? getAsset(profile.image[0])
                        : ""
                    }
                    alt=""
                    crossOrigin="anonymous"
                  />
                </div>
                <p className="font_11 mt-3 mb-0 text-center">
                  {(profile?.shopname || profile?.dishname).length > 8
                    ? (profile?.shopname || profile?.dishname).slice(0, 7) +
                      "..."
                    : profile?.shopname || profile?.dishname}
                </p>
                <button
                  type="button"
                  className="folow-btn mt-0"
                
                >
                  Connect
                </button>
              </Col>
            ))
          ) : (
            <div className="d-flex justify-content-between">
              {[...Array(4)].map((_, index) => (
                <div key={index}>
                  <p>User</p>
                  <button type="button" className="folow-btn mt-0">
                    Connect
                  </button>
                </div>
              ))}
            </div>
          )}
        </Row>

        <div className="line-width">
          <hr></hr>
        </div>
      </Col>
      {mysetting?.length === 4 ? (
        <Col lg="11" md="10" sm="11" xs="11" className="d-flex text-center">
          {timelinemodal.map((e) => (
            <div style={{ width: "33%" }} className="p-0" key={e.id}>
              <button
                className="btn font_small p-0 m-0"
                onClick={() => setShow(e?.id)}
              >
                <span style={{ color: e.color }}>{e.icon}</span> {e.text}
              </button>
            </div>
          ))}
        </Col>
      ) : (
        ""
      )}
    </>
  );
};

export default Post;
