import { Row } from "react-bootstrap";

const CVProfileInformation = (props) => {
  return (
    <Row>
      <div className="pt-1">
        <div>
          <h6 className={props.header}>Profile Information</h6>
        </div>
        <div>
          {console.log(
            "cvvvvvvvvvvvvvdataaaaaaaaaaa2323",
            props.cvdata?.profileInformation
          )}
          <p className={props.title}>Email</p>
          <p className={props.desc}>
            {props.cvdata?.profileInformation?.email}
          </p>
          <p className={props.title}>Contact Number</p>
          <p className={props.desc}>
            {props.cvdata?.profileInformation?.primaryNumber}
          </p>
          <p className={props.title}>Address Line 1</p>
          <p className={props.desc}>
            {props.cvdata?.profileInformation?.addressLine1}
          </p>
          <p className={props.title}>Address Line 2</p>
          <p className={props.desc}>
            {props.cvdata?.profileInformation?.addressLine2}
          </p>
          <p className={props.title}>City</p>
          <p className={props.desc}>{props.cvdata?.profileInformation?.pob}</p>
          <p className={props.title}>Permanent Address</p>
          <p className={props.desc}>
            {props.cvdata?.profileInformation?.permanentResidence}
          </p>
          <p className={props.title}>Postal Code</p>
          <p className={props.desc}>
            {props.cvdata?.profileInformation?.postalcode}
          </p>
          <p className={props.title}>Gender</p>
          <p className={props.desc}>
            {props.cvdata?.profileInformation?.gender}
          </p>
          <p className={props.title}>Date of Birth</p>
          <p className={props.desc}>{props.cvdata?.profileInformation?.dob}</p>
          <p className={props.title}>Country of birth</p>
          <p className={props.desc}>{props.cvdata?.profileInformation?.cob}</p>
          <p className={props.title}>place of birth</p>
          <p className={props.desc}>{props.cvdata?.profileInformation?.pob}</p>
          <p className={props.title}>Nationality</p>
          <p className={props.desc}>
            {props.cvdata?.profileInformation?.nationality}
          </p>
          <p className={props.title}>ID Type</p>
          <p className={props.desc}>
            {props.cvdata?.profileInformation?.idType}
          </p>
          <p className={props.title}>ID Number</p>
          <p className={props.desc}>
            {props.cvdata?.profileInformation?.idNumber}
          </p>
          <p className={props.title}>Marital Status</p>
          <p className={props.desc}>
            {props.cvdata?.profileInformation?.martialStatus}
          </p>
          <p className={props.title}>Visa Status</p>
          <p className={props.desc}>
            {props.cvdata?.profileInformation?.visaStatus}
          </p>
          <p className={props.title}>Portfolio Link</p>
          <p className={props.desc}>
            {props.cvdata?.profileInformation?.portfolioLink}
          </p>
        </div>
      </div>
    </Row>
  );
};
export default CVProfileInformation;
