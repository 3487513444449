import Routing from "./routes/index";
import PrivateRoutes from "./pages/privateRoutes/privateRoutes";
import ErrorPage from "./pages/404Error/404Error";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Withnavbar from "./components/Navbar/Withnavbar/withnavbar";
import EmailVerify from "./pages/Auth/components/EmailVerify";
import Verifycode from "./components/Verfiydigits";
import {
  Login,
  Signup,
  Forgotpassword,
  Verification,
  Resetpassword,
} from "./pages";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "./style/Button.css";
import "./style/Fonts.css";
import "./style/Theme.css";
import "./style/style.css";
import "./style/Icons.css";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot" element={<Forgotpassword />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/reset" element={<Resetpassword />} />
          <Route path="/404" element={<ErrorPage />} />
          <Route path="/email-verify" element={<EmailVerify />} />
          <Route path="/verify-code/:email" element={<Verifycode />} />
          <Route element={<PrivateRoutes />}>
            <Route element={<Withnavbar />}>
              <Route path="*" element={<Routing />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>

      <ToastContainer />
    </>
  );
}

export default App;
