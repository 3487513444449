import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const SuccessNotify = (text) => {
  toast.success(text, {
    position: "top-center",
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: true,
    autoClose: 5000,
  });
};

export const FailedNotify = (faild) => {
  toast.error(faild, {
    position: "top-center",
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: true,
    autoClose: 5000,
  });
};
