import { useParams, useNavigate } from "react-router-dom";
import PropertyAdForm from "../../../../components/forms/propertyAdform";
import { useState, useEffect } from "react";
import url from "../../../../config/axios";
import { SuccessNotify } from "../../../../constants/tostify";
import { FailedNotify } from "../../../../constants/tostify";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader";

const UpdateBusinessAd = () => {
  const { name } = useSelector((state) => state.user);
  const { index } = useParams();
  const [isloading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const [propertyadd, setPropertyadd] = useState({
    companyid: "",
    userid: name?._id,
    plottype: "",
    country: "",
    city: "",
    state: "",
    area: "",
    contactnumber: "",
    email: "",
    price: "",
    saletype: "",
    description: "",
    image: [""],
    status: true,
  });
  const Updatefunction = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .put(`property/id/?id=${index}`, propertyadd)
      .then(async (response) => {
        if (response.data.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Add Update Successfully");
          }, 1000);
          navigate(`/personal-add/${index}`);
        }
      })
      .catch((e) => {
        setIsloading(false);
        FailedNotify("Failed To Update Try Again!");
      });
  };

  useEffect(() => {
    url.get(`property/id/?id=${index}`).then(async (response) => {
      setPropertyadd({
        ...propertyadd,
        companyid: response?.data?.data?.Property?.companyid,
        plottype: response?.data?.data?.Property?.plottype,
        country: response?.data?.data?.Property?.country,
        city: response?.data?.data?.Property?.city,
        state: response?.data?.data?.Property?.state,
        area: response?.data?.data?.Property?.area,
        contactnumber: response?.data?.data?.Property?.contactnumber,
        email: response?.data?.data?.Property?.email,
        price: response?.data?.data?.Property?.price,
        saletype: response?.data?.data?.Property?.saletype,
        description: response?.data?.data?.Property?.description,
        image: response?.data?.data?.Property?.image,
      });
    });
  }, []);

  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }
  return (
    <div>
      <PropertyAdForm
        title="Update Business Property Category,Location,Price,Description"
        butn="Update"
        Updatehandler={Updatefunction}
        setPropertyadd={setPropertyadd}
        propertyadd={propertyadd}
        index={index}
        navigateTo="/Sale-Bussines-Property"
        link="/update-business-Ad/"
      />
    </div>
  );
};
export default UpdateBusinessAd;
