import UserShopProfile from "../../components/UserShopProfile/UserShopProfile"
import { Row, Container } from "react-bootstrap"
import UserItemShop from "../../components/UserShopItem/UserItemShop"
import UserShopViewModel from '../../components/ShopViewModel/OwnerShopViewModel';
const HomeMadeProfile = () => {
    return (
        <Container>
            <div className='screenHeight'>
                <Row className="d-flex justify-content-center">
                    <UserShopProfile />
                    <UserShopViewModel />
                    <UserItemShop />
                </Row>
            </div>

        </Container>

    )
}

export default HomeMadeProfile