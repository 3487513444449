import { useState } from "react";
import { Modal} from "react-bootstrap";
import { Rating } from "@mui/material";
const RatingModel = ({setSmShow,smShow}) => {
  const [value, setValue] = useState(0);


  return (
    <>
        <Modal
          size="sm"
          show={smShow}
          onHide={()=>setSmShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
          className="mt-5 pt-5"
        >
          <div className="row">
            <div className="col-11 text-center pe-0 ">
              <h6 className="ps-3 mt-4">Rate Me</h6>
            </div>
            <div className="col-1 ps-0">
              <i
                className="fa fa-times"
                onClick={() => {
                setSmShow(false);
                }}
              ></i>
            </div>
          </div>

          <div className="col-8 pe-0 ms-auto me-auto d-flex justify-content-center">
            <Rating
              name="simple-controlled"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            />
          </div>
          
          <div className="text-center">
            <p>{value} out of 5</p>
          </div>
          <div className="buttonRate d-flex justify-content-center mb-4">
            <button className="rateme">Rate</button>
          </div>
        </Modal>
     
    </>
  );
};
export default RatingModel;
