import { Container, Col, Row } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GroupIcon from "@mui/icons-material/Group";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Title, Timeline } from "../../../../../pages/index";
import { FriendsProfiles } from "../../../../../constants/jsonConstant";
import BackArrow from "../../../../../components/backArrow";
import { useNavigate } from "react-router-dom";
import url from "../../../../../config/axios";
import {SuccessNotify, FailedNotify} from "../../../../../constants/tostify";
import axios from "axios";
import { useEffect } from "react";
import {useSelector} from "react-redux";
import { useLocation } from "react-router-dom";

const Viewfriends = () => {
  const [leftSide, setLeftSidewidth] = useState(7);
  const [rightSide, setRightSide] = useState(5);
  const { name } = useSelector((state) => state.user);
  const [tabsData, setTabsData] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [infooo, setInfooo] = useState();
  const [info, setInfo] = useState();
  const location = useLocation();

  const [createShop, setCreateShop] = useState({
    text: "",
    privacy: "",
  });

  const [loading, setLoading] = useState(true);

  const [createPost, setCreatePost] = useState({
    shopid: "",
    userid: name?._id,
    businessType: "homemadeShop",
    my: [
      {
        heading: "",
        content: "",
        img: [""],
        video: [""],
      },
    ],
  });
  
  console.log("createPost", createPost);
   useEffect(() => {
    axios
      .get(
        `conection/publicOrShopAgainstPublic/?businessType=homemadeShop&shopid=&userid=${name?._id}`
      )
      .then(async (res) => {
        const responseData = res.data.data;
        const dynamicTabs = Object.keys(responseData).map((key) => ({
          eventKey: key,
          title:
            key.charAt(0).toUpperCase() +
            key.slice(1).replace(/([A-Z])/g, " $1"),
          data: responseData[key],
        }));
        setTabsData(dynamicTabs);
        if (dynamicTabs.length > 0) {
          setActiveTab(dynamicTabs[0].eventKey);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("API call error:", err);
        setLoading(false);
      });
  }, []);

  const handleTabSelect = (eventKey) => {
    setActiveTab(eventKey);
  };

  const getActiveTabData = () => {
    const activeTabData = tabsData.find((tab) => tab.eventKey === activeTab);
    return activeTabData ? activeTabData.data : [];
  };

  const inputChange = (event) => {
    setCreateShop((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const userDatahandler = () => {
    url
      .put(`/user/id/?id=${name?._id}`, {
        description: {
          text: createShop.text,
          privacy: createShop.privacy,
        },
      })
      .then(async (response) => {
        console.log("data", response);
        if (response.data.success) {
          SuccessNotify("Successfully!");
          setInfo(response?.data?.data?.User);
          url.get(`/user/id?id=${name?._id}`).then((response) => {
            setInfooo(response?.data?.data?.User);
          });
        }
      })
      
      .catch((e) => {
        console.log("error", e);
        FailedNotify("Failed! Try Again");
      });
  };

  useEffect(() => {
    url.get(`/user/id?id=${name?._id}`).then((response) => {
      setInfooo(response?.data?.data?.User);
    });
  }, []);

  const widthHandler = () => {
    if (leftSide === 7 && rightSide === 5) {
      setLeftSidewidth(2);
      setRightSide(10);
    } else {
      setLeftSidewidth(7);
      setRightSide(5);
    }
  };
                                         
  console.log("createShop", createShop);
  console.log("infoooo name", infooo);

  const [key, setKey] = useState("home");
  const navigate = useNavigate();


  
  return (
    <>
      <Container className="mt-1">
        <Row className="gx-3">
          <Col lg="7" className=" screenHeight " sm="12">
            <div className="border ps-0 pe-0 height_100">
              <BackArrow back="/user-profile" mystyle="p-1 ps-2" />
              <Row>
                <Col lg="12">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3 display-setting font_sm d-flex justify-content-center"
                  >
                    <Tab eventKey="home" title="My Friend's">
                      <Title
                        name="General Friend's"
                        title="105 General friends"
                      />
                      <div className="noscroll request_height">
                        <Row className="ps-4 pe-4 pt-0 pb-0">
                          {FriendsProfiles.map((e) => (
                            <Col
                              lg="2"
                              md="2"
                              sm="6"
                              className="border mt-3 p-2 col-6"
                            >
                              <div className="d-flex align-items-center flex-column">
                                <img
                                  src={e.img}
                                  alt=""
                                  className="profile_img"
                                />
                                <h6 className="font-sm">{e.name}</h6>
                                <div
                                  className="view_profileset font_11  mob_f font-grey "
                                  style={{ cursor: "pointer" }}
                                  onClick={() => navigate("/other-profile")}
                                >
                                  <VisibilityIcon
                                    style={{ fontSize: "14px" }}
                                  />
                                  View Profile
                                </div>
                                <button
                                  type="button"
                                  className=" folow-btn mt-1"
                                >

                                  Remove
                                </button>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </Tab>
                    <Tab eventKey="profile" title="Random Poeple's">
                      <Title
                        name="Random Friend's"
                        title="110 Random friends"
                      />
                      <div className="noscroll request_height">
                        <Row className="ps-4 pe-4 pt-0 pb-0">
                          {FriendsProfiles.map((e) => (
                            <Col
                              lg="2"
                              md="2"
                              sm="12"
                              className="border mt-3 p-2"
                            >
                              <div className="d-flex align-items-center flex-column">
                                <img
                                  src={e.img}
                                  alt=""
                                  className="profile_img"
                                />
                                <h6 className="font-sm">{e.name}</h6>
                                <div
                                  className="font_11  mob_f font-grey "
                                  style={{ cursor: "pointer" }}
                                >
                                  <VisibilityIcon
                                    style={{ fontSize: "14px" }}
                                  />
                                  View Profile
                                </div>
                                <button
                                  type="button"
                                  className=" folow-btn mt-1"
                                >
                                  {" "}
                                  Connect{" "}
                                </button>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </Tab>
                  </Tabs>
                </Col>
            
              </Row>
            </div>
          </Col>
          <Col lg="5" sm="12" className="ps-4 screenHeight position_r ">
            <div className="border b-radius">
            <Timeline
                tabsData={tabsData}
                activeTab={activeTab}
                onTabSelect={handleTabSelect}
                tabData={getActiveTabData()}
                loading={loading}
                createPost={createPost}
                setCreatePost={setCreatePost}
                setLoading={setLoading}
                // id={id}
                rightSide={rightSide}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Viewfriends;
