import { Row, Col, Container } from "react-bootstrap";
import OwnerShopProfile from "../../components/OwnerShopProfile/OwnerShopProfile";
import ShopItemsOwner from "../../components/ShopItemsOwner/ShopItems";
import { useParams } from "react-router-dom";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useState, useEffect } from "react";
import url from "../../config/axios";
import { useLocation } from "react-router-dom";
import BackArrow from "../../components/backArrow";
import ShopManagebuttons from "../../components/Shops_manage_buttons";
import { useSelector } from "react-redux";

const NewItembussinesProfile = () => {
  const [suggest, setSuggest] = useState(0);
  const [suggestdetail, setSuggestdetail] = useState(0);
  const [Report, Setreport] = useState(0);
  const [Reportdetail, SetReportdetail] = useState(0);
  const location = useLocation();
  const [suggestdata, setSuggestdata] = useState();
  const stateSetter = location.state && location.state.stateSetter;
  const { name } = useSelector((state) => state.user);
  useEffect(() => {
    url
      .get(`shop/user/?userid=${name._id}&businessType=retailerShop`)
      .then(async (res) => {
        if (res.data.success) {

          localStorage.setItem("mycategory", (res?.data?.data?.Shops[0]?.shopCategory?.name));
        }
      });
  }, []);
  const updateState = () => {
    if (stateSetter) {
      stateSetter(1);
    }
  };
  const { id } = useParams();
  const [shopData, setShopData] = useState();

  useEffect(() => {
    url.get(`shop/id?id=${id}`).then(async (res) => {
      setShopData(res?.data?.data?.Shop);

    });
  }, []);
  const suggestdatta = () => {
    url
      .get(`suggestion/getShopSuggestion/vendorid?vendorid=${shopData?.shopid}`)
      .then(async (res) => {

        setSuggestdata(res?.data?.data[0]?.suggestions);
      });
  };


  return (
    <Container>
      <Col md={12} lg={11} className="mx-auto">
        <Row>
          <Col md={4} lg={3} className="p-0">
            <OwnerShopProfile
              shopid={id}
              Add="Create Items"
              Name="Update New Items Profile"
              navigateTo="/new-items-update"
              shopData={shopData}
              suggestdatta={suggestdatta}
              setSuggest={setSuggest}
              Setreport={Setreport}
              Reportdetail={Reportdetail}
              salespromotion="sales promotion"
              icon={<LocalOfferIcon className="silver-icon" />}
            />
          </Col>

          <Col md={8} lg={9} className=" screenHeight custom_ps-4">
            <div className="Form-Shadow h-100">
              <Row>
                <Col lg="4">
                  <BackArrow
                    back={`/rawmaterial-newitem/${id}`}
                    mystyle="ps-2 pt-1"
                  />
                </Col>
                <Col lg="8" className="mt-2 pe-4">
                  <ShopManagebuttons businessurl="/RetailerShop" />
                </Col>
              </Row>
              <ShopItemsOwner
                suggest={suggest}
                suggestdata={suggestdata}
                shopData={shopData}
                setSuggest={setSuggest}
                suggestdetail={suggestdetail}
                setSuggestdetail={setSuggestdetail}
                urlPass="/new-item-owner-view"
                shopid={id}
                post={`/newitems-Postadd/${id}`}
                navigateto="/new-items-form"
                updateState={updateState}
                Setreport={Setreport}
                Report={Report}
                SetReportdetail={SetReportdetail}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default NewItembussinesProfile;
