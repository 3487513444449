import { Col, Row, FormControl } from "react-bootstrap";
import "./jobs.css";
import Tab from "react-bootstrap/Tab";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tabs from "react-bootstrap/Tabs";
import JobapplyCandidates from "../../../../components/Jobs_Candidates";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import url from "../../../../config/axios";
import Deletemodal from "../../../../components/DeleteModal";
import { FailedNotify, SuccessNotify } from "../../../../constants/tostify";
import Loader from "../../../../components/Loader";
import SearchIcon from "@mui/icons-material/Search";
const Jobs = ({ id }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const [getJobdata, setGetJobdata] = useState([]);
  const [appliedJobdata, setappliedJobdata] = useState([]);
  const [index, setIndex] = useState();
  const [shows, setShows] = useState(false);
  const [show, setShow] = useState(0);
  const handleClose = () => setIndex(0);
  useEffect(() => {
    setIsloading(true);
    url.get(`job/company/?companyId=${id}`).then(async (response) => {
      if (response.data.success) {
        setIsloading(false);
        setGetJobdata(response?.data?.data?.Jobs);
      }
    });

    url.get(`jobapplied/totalPersonOnCompany?companyid=${id}`).then(async (response) => {
      console.log("totalPersonOnCompany", response);
      if (response.data.success) {
        setIsloading(false);
        setappliedJobdata(response?.data?.data);
      }
    })
  }, []);
  const Deletedata = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .delete(`job/?id=${index}`)
      .then(async (response) => {
        if (response?.data?.success) {
          setIsloading(false);
          SuccessNotify("Deleted Successfully!");
          setIndex(0);
          window.location.reload(false);
        }
        setTimeout(() => {
          navigate(`/company-profile/${id}`);
        }, 1000);
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed to Delete !");
      });
  };

  

  const handleSelect = (key) => {
    if (key === "Newjobs") {
      navigate(`/create-job/${id}`);
    } else {
    }
  };
 
  return (
    <Col lg={12} md={12} style={{}}>
      {index && (
        <Deletemodal
          shows={index}
          title="Profile"
          handleClose={handleClose}
          handledelete={Deletedata}
        />
      )}
      <Tabs
        defaultActiveKey="jobs"
        id="uncontrolled-tab-example"
        className="mb-3 ps-lg-2 ps-md-2 ps-sm-2 ps-2  groundset"
        onSelect={(k) => {
          handleSelect(k);
        }}
      >
        <Tab eventKey="jobs" title="Posted Jobs" className="jobviewhead">
          {isloading ? (
            <Loader heightsetting="scroll-company" />
          ) : (
            <div className="scroll-company noscroll ">
              {getJobdata?.map((jobs) => (
                <div>
                  <Row className="ps-lg-5 ps-md-2 ps-sm-2 ps-2  ">
                    <Col md={4} xl={4} lg={4} sm={4} xs={4}>
                      <div>
                        <p className="mb-1 jobPara">Designation</p>
                        <h6 className="jobsDesc">{jobs?.title}</h6>
                      </div>
                    </Col>
                    <Col
                      md={2}
                      xl={2}
                      lg={2}
                      sm={2}
                      xs={2}
                      className="d-flex justify-content-center ps-0 ps-md-0"
                    >
                      <div>
                        <p className="mb-1 jobPara">Posted date</p>
                        <h6 className="jobsDesc">
                          {jobs?.createdAt.slice(0, 10)}
                        </h6>
                      </div>
                    </Col>
                    <Col
                      md={2}
                      xl={2}
                      lg={2}
                      sm={2}
                      xs={2}
                      className="d-flex justify-content-center p-0 "
                    >
                      <div>
                        <p className="mb-1 jobPara">Last date of apply</p>
                        <h6 className="jobsDesc">{jobs?.duration}</h6>
                      </div>
                    </Col>
                    <Col
                      md={2}
                      xl={2}
                      lg={2}
                      sm={2}
                      xs={2}
                      className="d-flex justify-content-center ps-0 "
                    >
                      <div>
                        <p className="mb-1 jobPara">View details</p>
                        <div className="d-flex justify-content-center">
                          <VisibilityIcon
                            className="viewBlue"
                            onClick={() =>
                              navigate("/job-details/" + jobs?._id)
                            }
                          />
                        </div>
                      </div>
                    </Col>
                    <Col
                      md={2}
                      xl={2}
                      lg={2}
                      sm={2}
                      xs={2}
                      className="d-flex justify-content-center ps-0 "
                    >
                      <div>
                        <p className="mb-1 jobPara">Delete</p>
                        <div
                          className="d-flex justify-content-center"
                          onClick={() => {
                            setIndex(jobs?._id);
                          }}
                        >
                          <DeleteIcon className="deleteRed" />
                        </div>
                      </div>
                     
                    </Col>
                  </Row>
                  <hr className="m-0" />
                </div>
              ))}
            </div>
          )}
        </Tab>
        <Tab eventKey="Followers" title="Candidates Who Applied">
          <div className=" noscroll ps-3 pe-3">
            <div class="search-container">
              <FormControl
                type="text"
                placeholder="Search"
                className="mt-1   mb-2 w-100 pe-0"
              />
              <SearchIcon className="search-icon text-white sset" />
            </div>

            <JobapplyCandidates appliedJobdata={appliedJobdata} isloading={isloading} />
          </div>
        </Tab>
        <Tab
          eventKey="Newjobs"
          title="Post New Job"
          onSelect={handleSelect}
        ></Tab>
      </Tabs>
    </Col>
  );
};
export default Jobs;
