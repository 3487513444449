import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Style.css";

const Joinmeeting = ({ mettingn, handleClose }) => {
  const navigate = useNavigate();

  return (
    <div>
      <Container>
        <Modal show={mettingn} onHide={handleClose}>
          <Modal.Header>
            <KeyboardBackspaceIcon className="Arrow" />
          </Modal.Header>

          <div className="MODLBTTON mt-3">
            <Button variant="outline-primary ">
              Paste link here to join meeting
            </Button>
          </div>

          <div className="MODLBTTON1 mt-3 mb-3">
            <Button
              onClick={() => navigate("/GoogleMeet")}
              className="pt-2 pb-2 ps-5 pe-5 fs-6"
              variant="primary"
            >
              Join
            </Button>
          </div>
        </Modal>
      </Container>
    </div>
  );
};

export default Joinmeeting;
