import React from "react";
import Card from "react-bootstrap/Card";
import { CardGroup } from "react-bootstrap";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { images } from "../../../../constants";
import { useSelector } from "react-redux";
export default function BoxData({
  imgg,
  Title,
  Butonn,
  url,
  link,
  ms,
  buy,
  handleShow,
  cvform,
}) {
  const location = useLocation();
  const { name } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: -7,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  }));
  return (
    <div>
      <CardGroup className="width-box">
        <Card
          className={`${
            link === "/user-dashboard" || link === "/user-profile"
              ? ""
              : "Main-card"
          }`}
        >
          <div
            className={`${
              link === "/user-profile" ? "" : "mt-3"
            } card-second-div`}
            style={{ position: "relative" }}
          >
            {location.pathname === "/SellingSide" ? (
              <div
                className="hei-adj"
                style={{ position: "absolute", top: "-4px", right: "24px" }}
              >
                <MailOutlineOutlinedIcon className="mail_icon" />
                <NotificationsOutlinedIcon className="mail_icon" />
                <StyledBadge badgeContent={3} color="primary"></StyledBadge>
              </div>
            ) : (
              ""
            )}

            <Card.Img
              className={`card-img dashboard_card mt-1 ${
                link === "/user-profile" ? "dashboard_card_r" : ""
              }`}
              src={imgg}
              crossOrigin="anonymous"
            />
            <Card.Body className="p-2">
              <Card.Title className="title-size d-flex justify-content-center mb-2">
                {link === "/user-profile"
                  ? `${name.fullname}`
                  : link === "/user-dashboard"
                  ? null
                  : Title}
              </Card.Title>

              {link === "/user-dashboard" || link === "/user-profile" ? (
                <div className="d-flex justify-content-center">
                  <Card.Text className="verify text-center">
                    <img src={images.Verified} width="18" height="18" />{" "}
                    Verified
                  </Card.Text>
                </div>
              ) : null}

              {Butonn === "Post" ? (
                <button
                  className={`${
                    link === "/user-profile" ? "mt-2" : "mt-1"
                  } btn-clr d-block mx-auto`}
                  onClick={
                    cvform && cvform?.length > 0
                      ? () => navigate("/create-job-app")
                      : handleShow
                  }
                >
                  {Butonn}
                </button>
              ) : (
                <button
                  className={`${
                    link === "/user-dashboard" || link === "/user-profile"
                      ? "btn-clr-plus"
                      : "btn-clr"
                  } d-block mx-auto mt-1`}
                  onClick={() =>
                    navigate(
                      `${location.pathname === "/SellingSide" ? url : link}`
                    )
                  }
                >
                  {Butonn}
                </button>
              )}
            </Card.Body>
          </div>
        </Card>
      </CardGroup>
    </div>
  );
}
