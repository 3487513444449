import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "../../config/axios";
import ShopAdds from "../../components/shopAds/ShopAdds";
import {
  Businessprofiletitle,
  Rawmaterialcard,
  Timeline,
} from "../../pages/index";
import BackArrow from "../../components/backArrow";
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux'
const CompanyBusinessProfile = () => {
  const { id } = useParams();
  const { name } = useSelector((state) => state.user)
  const [tabsData, setTabsData] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [loading, setLoading] = useState(true);
  const [createPost, setCreatePost] = useState(
    {
      shopid: id,
      userid: name?._id,
      businessType: "jobs",
      my: [{
        heading: "",
        content: "",
        img: [""],
        video: [""],

      }]

    }
  )
  useEffect(() => {
    axios.get(`conection/publicOrShopAgainstPublic/?businessType=jobs&shopid=${id}&userid=${name?._id}`)
      .then(async (res) => {
        const responseData = res.data.data;
        const dynamicTabs = Object.keys(responseData).map((key) => ({
          eventKey: key,
          title: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'),
          data: responseData[key],
        }));
        setTabsData(dynamicTabs);
        if (dynamicTabs.length > 0) {
          setActiveTab(dynamicTabs[0].eventKey);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("API call error:", err);
        setLoading(false);
      });
  }, [id]);

  const handleTabSelect = (eventKey) => {
    setActiveTab(eventKey);
  };

  const getActiveTabData = () => {
    const activeTabData = tabsData.find(tab => tab.eventKey === activeTab);
    return activeTabData ? activeTabData.data : [];
  };
  return (
    <>
      <Container>
        <Row>
          <Col lg="7" className="screenHeight p-0">
            <div className="border b-radius">
              <BackArrow back="/company-form" />
              <ShopAdds />
              <Businessprofiletitle linkurl="/company-profile" id={id} />
              <Rawmaterialcard />
            </div>
          </Col>

          <Col
            lg="5"
            sm="12"
            className="ps-4 screenHeight"
            style={{ position: "relative" }}
          >
            <div className="border b-radius">
              <Timeline
                tabsData={tabsData}
                activeTab={activeTab}
                onTabSelect={handleTabSelect}
                tabData={getActiveTabData()}
                loading={loading}
                createPost={createPost}
                setCreatePost={setCreatePost}
                setLoading={setLoading}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CompanyBusinessProfile;
