import axios from "axios";

const customInstance = axios.create({
  // baseURL: "http://localhost:8080/api/",
  // img: "http://localhost:8080/",

  // img: "https://backend.selectezi.com/",
  baseURL: "https://backend.selectezi.com/api/",
  headers: { Accept: "application/json" },
});

export default customInstance;
