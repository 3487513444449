import { Row, Col, Container } from "react-bootstrap";
import "../cvtemplates.css";
import { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CVTemplate1 from "../CVtemplate1/components/CVImg";
import CVTemplate2 from "../CVtemplate2/components/CVImg2";
import CVTemplate3 from "../CVtemplate3/components/CVImg3";
import CVTemplate4 from "../CVtemplate4/components/CVImg4";
import CVTemplate5 from "../CVtemplate5/components/CVImg5";
import { useSelector } from "react-redux";
import url from "../../../config/axios";
import BackArrow from "../../../components/backArrow";

const CVTemplatesbuyside = () => {
  const [cvdata, setCvdata] = useState(null);
  const { name } = useSelector((state) => state.user);

  useEffect(() => {
    if (name?._id) {
      url.get(`/cvform?id=${name._id}`).then((res) => {
        setCvdata(res?.data?.cvform);
      });
    }
  }, [name]);

  const getCVTemplateComponent = (template) => {    
    switch (template) {
      case "0":
        return <CVTemplate1 cvdata={cvdata} />;
      case "1":
        return <CVTemplate2 cvdata={cvdata} />;
      case "2":
        return <CVTemplate3 cvdata={cvdata} />;
      case "3":
        return <CVTemplate4 cvdata={cvdata} />;
      case "4":
        return <CVTemplate5 cvdata={cvdata} />;
      default:
        return <div>No template found</div>;
    } 
  };

  return (
    <Row>
      <div className="screenHeight">
        <Container>
          <Row>
            <Col lg="1">
              <BackArrow />
            </Col>
            <Col md={10} lg={9} className="ms-auto">
              {cvdata ? (
                <div>{getCVTemplateComponent(cvdata?.template)}</div>
              ) : (
                <h5 className="text-center text-primary mt-5">Loading...</h5>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </Row>
  );
};
export default CVTemplatesbuyside;
