import React from 'react'
import PaymentMethods from './components/PaymentMethods';
import TableEmail from '../../components/TableEmail';
import Emailpic from './components/Emailpic';
import Tablebutton from './components/Tablebutton';
import "./style.css";
import Sendperchase from '../../components/Sendperchase';
import { useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import TableEmail2 from '../../components/TableEmail2';
import Reciveinvoice from '../../components/Reciveinvoice';
import PurchaseTable from './components/purchase-table';

const EmailData = ({btn_1, btn_2, btn_3, btn_4, btn_5, btn_6}) => {
    const location = useLocation()
    return (
        <div>
            <Container>
                <div className=' mt-4 ALL-DATA noscroll'>
                    <Emailpic />

                    {location.pathname === '/purchase-order-buying' &&
                    (<Sendperchase/>)}

                    { location.pathname === '/quotation-buying' || location.pathname === '/quotation-selling' || location.pathname === '/purchase-order-buying' || location.pathname === '/purchase-order-selling' || location.pathname === '/invoice-buying' || location.pathname === ('/invoice-selling') ?
                    (<TableEmail />) : ("") }

                    {location.pathname === '/inquiry-selling' || location.pathname === '/invoice'  || location.pathname === '/all-inquiry-buying' ?
                    (<TableEmail2 />) : ("")
                    }

                    {location.pathname === '/purchase-order-buying' || location.pathname === '/purchase-order-selling' ? (<PurchaseTable/>) : ("")}

                    {location.pathname === '/quotation-buying' || location.pathname === '/quotation-selling' ?
                        (<PaymentMethods />) : ("")}

                        {location.pathname === '/invoice-buying'  || location.pathname === ('/invoice-selling') && 
                        (<Reciveinvoice/>)}
                        
                    <Tablebutton btn_1={btn_1} btn_2={btn_2} btn_3={btn_3} btn_4={btn_4} btn_5={btn_5} btn_6={btn_6}/>

                </div>
            </Container>
        </div>
    )
}

export default EmailData
