import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import Form from "react-bootstrap/Form";
import AttachFileSharpIcon from "@mui/icons-material/AttachFileSharp";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import TextRotationNoneOutlinedIcon from "@mui/icons-material/TextRotationNoneOutlined";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "./STyle.css";
const Mailsinput = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Container>
        <div className="screenHeight pt-2">
          <div className="headerss ms-4 me-4">
            <div className="heading">
              <Row>
                <Col lg={11} md={10} sm={9} xs={9}>
                  <h5 className="massage ps-3 pt-2">New Message</h5>
                </Col>

                <Col lg={1} md={2} sm={3} xs={3}>
                  <ClearSharpIcon
                    onClick={() => navigate("/inbox")}
                    className="closeicon mt-1 ms-4"
                    style={{ width: "30px", height: "40px" }}
                  />
                </Col>
              </Row>
            </div>

            <div>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  className="inputs"
                  type="email"
                  placeholder="From"
                />
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  className="inputs"
                  type="email"
                  placeholder="To"
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  className="inputs"
                  type="email"
                  placeholder="cc"
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  className="inputs"
                  type="email"
                  placeholder="bcc"
                />
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  className="inputs"
                  type="email"
                  placeholder="Subject"
                />
              </Form.Group>
            </div>

            <div>
              <div className="d-flex flex-column ">
                <textarea
                  style={{ height: "380px" }}
                  placeholder="Write here...."
                  className="inputss "
                />
              </div>
              <Row>
                <Col className="d-flex justify-content-start ps-5" lg={6}>
                  <p>Email Signature</p>
                </Col>

                <Col
                  className="d-flex justify-content-end gap-4 pe-5 pb-2"
                  lg={6}
                >
                  <Button className="mt-1" variant="primary">
                    Edit
                  </Button>
                  <Button className="mt-1" variant="primary">
                    Save
                  </Button>
                </Col>
              </Row>
            </div>

            <div className="bottomicon">
              <Row className="pb-2">
                <Col lg={11} md={10} sm={9} xs={9}>
                  <div className="mt-2">
                  <TextRotationNoneOutlinedIcon className="ms-2 me-1" />

                  <AttachFileSharpIcon />

                  <ImageOutlinedIcon />
                  </div>
                </Col>
                <Col lg={1} md={2} sm={3} xs={3}>
                  <Button className="mt-2 pt-1 pb-1" variant="primary">
                    Send
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Mailsinput;
