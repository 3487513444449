import { Container } from "react-bootstrap";
import IntegrateForm from "../../components/Integrate-form";
import { useState, useEffect } from "react";
import { FailedNotify, SuccessNotify } from "../../constants/tostify";
import url from "../../config/axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const UseditemIntegrateform = () => {
    const navigate=useNavigate();
  const { name } = useSelector((state) => state.user);
  const [shopCategoryone, setShopCategoryone] = useState();
  const imageUrl = useSelector((state) => state.imgurl);
  const [integrateshop, setIntegrateshop] = useState({
    userid: name._id,
    shopaddress: "",
    shopUrl: "",
    isIntegrated: true,
    image: [""],
    shopCategory: "",
    businessType: "usedItemShop",
    description: "",
    status: true,
  });

  useEffect(() => {
    setIntegrateshop((integrateshop) => ({
      ...integrateshop,
      image: imageUrl?.imgurl || "",
    }));
    // ...
  }, [imageUrl]);


const HandlePost=()=>{

    url.post("/shop",integrateshop).then(async(res)=>{
if(res.data.success){
SuccessNotify("Shop Integrated Successfully");
navigate("/useditems-integrate-form")
}

    }).catch(()=>{
    FailedNotify("Failed to create Try Again!")
    })
}

  useEffect(() => {
    url
      .get("/category/type/?type=usedItemShop")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategoryone(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
      });
  }, []);
  return (
    <>
      <Container>
        <div className="screenHeight">
          <IntegrateForm backurl="/useditems-integrate" setIntegrateshop={setIntegrateshop} shopCategoryone={shopCategoryone} HandlePost={HandlePost} />
        </div>
      </Container>
    </>
  );
};

export default UseditemIntegrateform;