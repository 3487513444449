import ServicesTransportFilter from "../../../../components/filteration/ServiceTransport";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import url from "../../../../config/axios";
import BackArrow from "../../../../components/backArrow";
import ShopAdds from "../../../../components/shopAds/ShopAdds";
import Shops from "../../../../components/Multipleshopprofile/Components/Shops/Shops";

const TransportFilter = () => {
  const [shopCategory, setShopCategory] = useState();
  const [data, setData] = useState(0);
  const [showmodal, setShowmodal] = useState(false);
  const handleClosemodal = () => setShowmodal(false);
  const handleShow = () => setShowmodal(true);
  const [shopdata, setShopdata] = useState();
  const [isloading, setIsloading] = useState();
  const [itemmdata, setItemmdata] = useState();
  console.log("itemmdata /////////", itemmdata)
  console.log("shopdata  ////////", shopdata)
  useEffect(() => {
    url
      .get("category/type/?type=transport&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });
  }, []);
  const HandleShop = (e) => {
    setFiltershop((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const Handleitem = (e) => {
    setFilteritem((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };



  const [filtershop, setFiltershop] = useState(
    {
      shopid: "",
      shopname: "",
      shopCategory: "",
    }
  );
  const [filteritem, setFilteritem] = useState(
    {
      min: "",
      max: "",
      brandName: "",
      model: "",
      country: "",
      state: "",
      city: "",
    }
  );

  console.log("filtershop checkkkkkkkkkk", filtershop);
  // console.log("filteritem checkkkkkkkkkk", filteritem);

  const FilterHandler = () => {
    setIsloading(true);
    url.get(`/tranportvehicle/filter?shopname=${filtershop?.shopname}&shopid=${filtershop?.shopid}&shopCategory=${filtershop?.shopCategory}&filtermethod=shop`)
      .then(async (response) => {
        if (response?.data?.success) {
          console.log("shopdataaaaaaaaaaaaaaaaaaaaa", response)
          setIsloading(false);
          setItemmdata(response?.data?.transportVehicle[0])
          setShopdata(response?.data?.Shop);
        }
      })
      .catch((error) => {
        // console.log("servicesCategories", error);
        setIsloading(false);
      });
  }
  const FilterJobDetails = () => {
    setIsloading(true);
    url
      .get(`/tranportvehicle/filter?brandName=${filteritem?.brandName}&min=${filteritem?.min}&max=${filteritem?.max}&model=${filteritem?.model}&country=${filteritem?.country}&state=${filteritem?.state}&city=${filteritem?.city}&filtermethod=transportvehicle`)
      .then(async (response) => {
        if (response?.data?.success) {
          console.log("itemmdataaaaaaaaaaaaaaaaaaaa", response)
          setIsloading(false);
          setItemmdata(response?.data?.transportVehicle)
          setShopdata(response?.data?.Shop);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
        setIsloading(false);
      });
  };


  // console.log("shopdataaaaaaaaaaaaaaaaaaaaa", shopdata)
  // console.log("itemmdataaaaaaaaaaaaaaaaaaaa", itemmdata)

  return (
    <>
      <Container>
        <Row>
          <Col lg="4" className="screenHeight ">
            <div className="border b-radius height_100 background-image">
              <ServicesTransportFilter
                shopCategory={shopCategory}
                title="Search Transport by Category & Location"
                category="Search by Transport Category"
                search="Transport Category"
                HandleShop={HandleShop}
                FilterHandler={FilterHandler}
                Handleitem={Handleitem}
                FilterJobDetails={FilterJobDetails}

              />
            </div>
          </Col>

          <Col lg="8" className="screenHeight">
            <div className="border b-radius ps-2 pe-2">
              <BackArrow back="/BuyingSide" mystyle="ps-1 pt-1" /> <ShopAdds />
              <Shops
                link="/buying-transport"
                title="Transport"
                data={data}
                handleShow={handleShow}
                shopdata={shopdata}
                itemmdata={itemmdata}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default TransportFilter;
