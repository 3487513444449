import React, { useEffect } from "react";
import { images } from "../../constants";
import Suggestionbox from "../Suggestionbox";
import Reportbox from "../Reportbox";
import Reportselectezibox from "../Report to selectezi";
import StarRateIcon from "@mui/icons-material/StarRate";
import StoreIcon from "@mui/icons-material/Store";
import { RatingModel } from "../../pages/index";
import { useState } from "react";
import ShareIcon from "@mui/icons-material/Share";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import StreamIcon from "@mui/icons-material/Stream";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { Col, Row } from "react-bootstrap";
import Connectmodal from "../Connect_modal";
import FiberManualRecordSharpIcon from "@mui/icons-material/FiberManualRecordSharp";
import url from "../../config/axios";
import { SuccessNotify, FailedNotify } from "../../constants/tostify";
import { useSelector } from "react-redux";
import CashModal from "../cash-deliveryModal/CashModal";
import QRCode from "qrcode.react";
import "./UserShopProfile.css";
import { getAsset } from "../../helpers/helper";
import { useLocation } from "react-router-dom";
import Desmodal from "../Des_Modal";
const UserShopProfile = ({
  PostShopDatas,
  setCreateShop,
  setLgShowreport,
  lgShowreport,
  shopid,
  handleLikeDislike,
  shopData,
  id,

}) => {

  const { name } = useSelector((state) => state.user);
  const [lgShow, setLgShow] = useState(false);
  const [active, setActive] = useState('');
  const [showconnect, setShowconnect] = useState(false);
  const [showmodal, setShowmodal] = useState(false);
  const [tLike, setTLike] = useState(null);
  const [tDislike, setTDislike] = useState(null);
  const [status, setStatus] = useState(null);
  const closemodal = () => setShowmodal(false);
  const [cashmdl, setCashmdl] = useState(false);
  const [showqr, setShowqr] = useState(0);
  const handleClose = () => setCashmdl(false);
  const handleShow = () => setCashmdl(true);
  const location = useLocation();
  const [isloading, setIsloading] = useState(false);
  const [suggestion, setSuggestion] = useState({
    vendorid: shopData?.shopid,
    userid: name._id,
    username: name.fullname,
    useremail: name.email,
    role: "",
    imageUrl: "",
    message: "",
  });
  const [desshow, setDesshow] = useState(false);

  useEffect(() => {
    url.get(`user/id?id=${name?._id}`).then((res) => {
      setSuggestion((prevState) => ({
        ...prevState,
        imageUrl: res?.data?.data?.User?.imageUrl
      }))
    })
  }, []);
  useEffect(() => {
    if (active !== "") {

      likeSystem();
    }
  }, [active]);

  const handleClosedes = () => setDesshow(false);
  const handleShowdes = () => setDesshow(true);

  const suggestHandel = (event) => {
    setSuggestion((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const suggestPost = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post(`/suggestion/`, suggestion)
      .then((res) => {
        SuccessNotify("Suggestion Data is posted successfully");
        setIsloading(false);
      })
      .catch((err) => {
        FailedNotify("Failed to  post suggestion Data");
      });
  };

  const handleCloseconnect = () => setShowconnect(false);
  const [selectezireport, setSelectezireport] = useState(false);
  const [smShow, setSmShow] = useState(0);

  useEffect(() => {
    setShowqr(1);
  }, [setShowqr]);

  const handleClick = (action) => {
    const newState = {
      userid: name._id,
      vendorid: shopid,
      likedislike: action
    };

    likeSystem(newState);
    setActive(action);
  };

  const likeSystem = (newState) => {
    url.put(`likedislike/`, newState).then((res) => {

    })
    url.get(`likedislike/vendorid?vendorid=${shopid}`)
      .then(response => {
        const data = response.data;
        if (data.success && data.data.length > 0) {
          const item = data.data[0];
          setTLike(item.Tlike);
          setTDislike(item.Tdislike);
          setStatus(item.likedislike[0].status);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
  useEffect(() => {
    url.get(`likedislike/vendorid?vendorid=${shopid}`)
      .then(response => {
        const data = response.data;
        if (data.success && data.data.length > 0) {
          const item = data.data[0];
          setTLike(item.Tlike);
          setTDislike(item.Tdislike);
          setStatus(item.likedislike[0].status);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <>
      <Desmodal
        desshow={desshow}
        handleClosedes={handleClosedes}
        des={shopData?.description}
      />
      <CashModal cashmdl={cashmdl} handleClose={handleClose} />

      <Connectmodal
        showconnect={showconnect}
        handleCloseconnect={handleCloseconnect}
      />
      <Suggestionbox
        lgShow={lgShow}
        setLgShow={setLgShow}
        suggestion={suggestion}
        suggestHandel={suggestHandel}
        suggestPost={suggestPost}
      />
      <RatingModel setSmShow={setSmShow} smShow={smShow} />
      <Reportbox
        setCreateShop={setCreateShop}
        PostShopDatas={PostShopDatas}
        lgShowreport={lgShowreport}
        setLgShowreport={setLgShowreport}
      />
      <Reportselectezibox
        selectezireport={selectezireport}
        setSelectezireport={setSelectezireport}
      />
      <div className="p-3">
        <div className="relate_div">
          <div className="d-flex availble_bt0n">
            <FiberManualRecordSharpIcon
              style={{ color: "rgb(106 235 64)", fontSize: "19px" }}
            />
            <span>Available</span>
          </div>

          <img
            className="w-100 shop-profile-img"
            src={getAsset(shopData?.image)}
            alt="image"
            crossOrigin="anonymous"
          />
        </div>


        <div className="box-container pb-1 mt-2">
          <Row>
            <Col lg="9">
              <div className="pt-2 pb-1">
                <h4 className="shop-Profile-font mb-0">{location.pathname === `/find-jobs-online/${id}` || location.pathname === `/user-peronal-property/${id}` ? shopData?.companyname : shopData?.shopname}</h4>
                <div className="d-flex">
                  <div>   <p className="shop-profile-para mb-0">
                    {shopData?.description.slice(0, 25)}
                  </p></div>
                  <div>     <p className="shop-profile-para mb-0 ">
                    {shopData?.description.length > 25 && (
                      <span
                        onClick={handleShowdes}
                        className="mb-0 cursor_P font_11 text-dark"
                        style={{ marginTop: "3px" }}
                      >
                        &nbsp;<b>ReadMore...</b>
                      </span>
                    )}

                  </p></div>
                </div>


              </div>
              <div className="d-flex align-items-center pb-2">
                <img className="w-img" src={images.Building} alt="image" />
                <p className="mb-0 font-sm pt-1 pb-1 ps-2">
                  {location.pathname === `/find-jobs-online/${id}` || location.pathname === `/user-peronal-property/${id}` ? shopData?.companyaddress : shopData?.shopaddress}
                </p>
              </div>
              <div className="d-flex align-items-center">
                <StoreIcon style={{ color: "#434343" }} />
                <p className="mb-0 font-sm pt-1 pb-1 ps-2">{location.pathname === `/find-jobs-online/${id}` || location.pathname === `/user-peronal-property/${id}` ? shopData?.marketplace : shopData?.marketPlace}</p>
              </div>
            </Col>
            <Col lg="3">

              <div className="d-flex justify-content-end">

                {
                  showqr == 1 && (<QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={`https://selectezi.com/${shopid}`}
                    viewBox={`0 0 256 256`} />)
                }

              </div>
            </Col>
          </Row>
        </div>
        {/* details */}
        <div className="pt-2 pb-2">

          <div>
            {/* <div className="d-flex align-items-center">
              <img className="w-img" src={images.Building} alt="" />
              <p className="mb-0 font-sm pt-1 pb-1 ps-2">
                tempor incidila bore et dolore magna quis{" "}
              </p>
            </div> */}
            {/* <div className="d-flex align-items-center">
                  <StoreIcon style={{ color: "#434343" }} />
                  <p className="mb-0 font-sm pt-1 pb-1 ps-2">Jadoon Plaza</p>
                </div> */}
            <div
              className="d-flex align-items-center cursor_P"
              onClick={() => setSmShow(1)}
            >
              <StarRateIcon style={{ color: "#434343" }} />
              <p className="mb-0 font-sm pt-1 pb-1 ps-2 mt-1">Rate This Shop </p>
            </div>

            <div className="d-flex">
              <div className="">

                <div
                  className="d-flex align-items-center pt-2 pb-2 cursor_P"
                  onClick={() => handleClick('like')}
                >
                  {status === "like" ? (
                    <img
                      className="w-img cursor_P"
                      src={images.Activelike}
                      alt="image"
                    />
                  ) : (
                    <img className="w-img" src={images.Liked} alt="image" />
                  )}

                  <p className="mb-0 font-sm ps-2">
                    Like This Shop
                    <span style={{ fontSize: "12px" }}>
                      (Liked by {tLike})
                    </span>{" "}
                  </p>

                </div>
                <div
                  className="d-flex align-items-center pt-2 pb-1 cursor_P pb-2"
                  onClick={() => handleClick('dislike')}
                >

                  {status === "dislike" ? (
                    <img
                      className="w-img"
                      src={images.ActiveDislike}
                      alt="image"
                    />
                  ) : (
                    <img className="w-img" src={images.DisLiked} alt="image" />
                  )}

                  <p className="mb-0 font-sm ps-2">
                    Dislike This Shop
                    <span style={{ fontSize: "12px" }}>
                      (Disliked by {tDislike})
                    </span>
                  </p>
                </div>
                <div className="d-flex align-items-center pb-2">
                  <ShareIcon />
                  <p
                    className="mb-0 font-sm pt-1 pb-1 ps-2 pointer_clsss"
                    onClick={() => {
                      setShowconnect(true);
                    }}
                  >
                    Send connection request
                  </p>
                </div>
                <div
                  className="d-flex align-items-center cursor_P"
                  onClick={() => setLgShow(true)}
                >
                  <img src={images.suggest} alt="logo" />
                  <p className="mb-0 font-sm pt-1 pb-1 ps-2">
                    Give suggestion to Shop owner
                  </p>
                </div>
                <div className="d-flex align-items-center pb-2">
                  <img src={images.report} alt="logo" width="14%" />
                  <p
                    className="mb-0 font-sm pt-1 pb-1 ps-2 cursor_P mt-2"
                    onClick={() => setLgShowreport(true)}
                  >
                    Report to Shop owner
                  </p>
                </div>
                <div className="d-flex align-items-center pb-2">
                  <ReportProblemIcon style={{ color: "red" }} />
                  <p
                    className="mb-0 font-sm pt-1 pb-1 ps-2 cursor_P"
                    onClick={() => setSelectezireport(true)}
                  >
                    Report to Selectezi
                  </p>
                </div>
                <div className="d-flex align-items-center cursor_P">
                  <img src={images.live} alt="logo" />
                  <p className="mb-0 font-sm pt-1 pb-1 ps-2">
                    Join Shop owner Live stream
                  </p>
                </div>
                <div
                  className="d-flex align-items-center cursor_P"
                  onClick={handleShow}
                >
                  <img className="width10" src={images?.CASH} alt="logo" />
                  <p className="mb-0 font-sm pt-1 pb-1 ps-2">
                    Payment and delivery
                  </p>
                </div>
              </div>
              {/* <div className="div-width-1">
                <img className="w-100" src={images.Maps} />
              </div> */}
            </div>
          </div>
        </div>
        {/* btns */}

      </div >
    </>
  );
};

export default UserShopProfile;
