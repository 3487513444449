import React from "react";
import Card from "react-bootstrap/Card";
import BoxData from "../../Box";
import CardNav from "../../CardNav";
import { Generaluser } from "../../../../../constants/jsonConstant";
import { Col, Row } from "react-bootstrap";
import { images } from "../../../../../constants";
import "../../BussinessMode/style.css";
import { getAsset } from "../../../../../helpers/helper";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import url from "../../../../../config/axios";

const Generaluserprofile = ({ btn, titleone, titletwo }) => {
  const { name } = useSelector((state) => state.user);
  const [userimg, setUserimg] = useState();
  useEffect(() => {
    url.get(`/user/id?id=${name?._id}`).then((response) => {
      setUserimg(response?.data?.data?.User?.imageUrl);
    });
  }, [])


  return (
    <div>
      <Row>
        {Generaluser.map((data) => (
          <Col lg="6" md="6" sm="6" xs="6">
            <CardNav
              Header={data.title}
              para={data.para}
              myinfo={data.info}
              myid={data.id}
            />
            <Card className="shadow-joboprt mt-lg-2 mt-md-3 mt-sm-3">
              {(data.id === 1 && (
                <BoxData
                  Title={titleone}
                  Butonn={data.info}
                  imgg={userimg && userimg?.length>0 ? getAsset(userimg) : images.noprofile}
                  ms="nine-element"
                  buy="nine"
                  link="/user-profile"
                />
              )) ||
                (data.id === 2 && (
                  <BoxData
                    Title={titletwo}
                    Butonn={data.info}
                    imgg={images.Businessdashboard}
                    link="/user-dashboard"
                    ms="ten-element"
                    buy="ten"
                  />
                ))}
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Generaluserprofile;
