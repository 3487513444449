import { Col, Container, Row } from "react-bootstrap";
import React, { useState } from "react";
import { Modal, Button, Card } from "react-bootstrap";
import { images } from "../../constants/index";
import { ModalCards } from "../../constants/jsonConstant";
import TextField from "@mui/material/TextField";

import "./style.css";
import InputAdornment from "@mui/material/InputAdornment";

const Addtocartmodal = ({ onChange, showModal, handleCloseModal, SetisOpenone }) => {
  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value);
    setQuantity(newQuantity);
    onChange(newQuantity);
  };

  return (
    <div className="bg-clrr">


      <Modal
        show={showModal}
        onHide={handleCloseModal}
        className=" right-to-left-modal"
      >
        <Modal.Header className="shadow-sm mb-1 p-2">
          <Modal.Title className="fs-6">
            <img
              className=" back-icon me-3 ms-2"
              onClick={handleCloseModal}
              width="16"
              height="13"
              src={images.BackArrow}
            />
            Shopping Cart
          </Modal.Title>
        </Modal.Header>

        <div className="modal-div">
          <Modal.Body >
            <div className="modal-height noscroll">
              {ModalCards.map((ModalData) => (
                <Row className=" bg-clr-card mb-3 ps-3 ">
                  <Col lg="4">
                    <Card.Img
                      variant="top"
                      width="100"
                      height="100"
                      src={ModalData.img}
                    />
                  </Col>

                  <Col lg="8" className="pb-2">
                    <Card.Body className="p-0">
                      <Card.Text className="title-sizze mb-1">
                        {ModalData.title}
                      </Card.Text>
                      <Card.Text className="price">{ModalData.price}</Card.Text>

                      {/* <Card.Text className=" Qty ">
                        Qty : {ModalData.quantity}
                      </Card.Text> */}
                      <label className="font_13">Qty : </label> &nbsp;
                      <input
                        className="Qty"
                        type="number"
                        placeholder="1"
                        min="1"
                        max="100"
                        onChange={(e) => {
                          // Handle input changes here
                          const newQuantity = parseInt(e.target.value);
                          // Update ModalData.quantity with the new value
                          // You'll need to handle the state update according to your component's logic
                        }}
                      />
                    </Card.Body>
                    <button className="delete-btn-three mt-2 ps-3 pe-2">
                      {" "}
                      <img
                        className="me-2"
                        width="12"

                        src={images.Delete}
                      />
                      Remove
                    </button>
                  </Col>
                </Row>
              ))}
            </div>

            <div>
              <Container fluid>
                <p className="d-flex align-items-center">
                  <img
                    className="me-1"
                    src={images.Location}
                    width="15"
                    height="15"
                  />{" "}
                  Use Current Location
                </p>

                <TextField
                  size="small"
                  sx={{ width: "100%" }}
                  defaultValue="Add Address Shipping Address"
                  label="supply bazzar abbottabad"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <button className="change-btn">change</button>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  size="small"
                  sx={{ mt: 1, width: "100%" }}
                  label="Add Address Billing Address"
                  defaultValue="supply bazzar abbottabad"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <button className="change-btn">change</button>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  size="small"
                  sx={{ mt: 1, width: "100%" }}
                  label="Mobile Number"
                  defaultValue="+923409316399"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <button className="change-btn">change :</button>
                      </InputAdornment>
                    ),
                  }}
                />

                <p className="mt-3">Order Details:</p>

                <Row>
                  <Col className="bag-t" lg="6">
                    bag total :
                  </Col>

                  <Col className=" bag-t d-flex justify-content-end" lg="6">
                    $220.00
                  </Col>
                </Row>
                <div className="bag-t-2">
                  <Row>
                    <Col className="bag-t" lg="6">
                      Delivery
                    </Col>

                    <Col className=" bag-t d-flex justify-content-end" lg="6">
                      $50.00
                    </Col>
                  </Row>
                </div>

                <hr className="mt-2 mb-2" />

                <Row>
                  <Col className="t-amount" lg="6">
                    Total Amount
                  </Col>

                  <Col
                    className=" t-amount-2 d-flex justify-content-end"
                    lg="6"
                  >
                    $270.00
                  </Col>
                </Row>
                <Row className=" d-flex justify-content-center align-items-center mt-2 ">
                  <Col
                    className="d-flex flex-column justify-content-center align-items-center"
                    lg="4"
                    md="4"
                    sm="4"
                    xs="4"
                  >
                    <img src={images.Return} />
                    <p className="fs-imgs">7 days return</p>
                  </Col>

                  <Col
                    className="d-flex flex-column justify-content-center align-items-center"
                    lg="4"
                    md="4"
                    sm="4"
                    xs="4"
                  >
                    <img src={images.Hours} />
                    <p className="fs-imgs">24/7 support</p>
                  </Col>

                  <Col
                    className="d-flex flex-column justify-content-center align-items-center"
                    lg="4"
                    md="4"
                    sm="4"
                    xs="4"
                  >
                    <img src={images.Wallet} />
                    <p className="fs-imgs">Secure payment</p>
                  </Col>
                </Row>

                <hr className="m-0" />

                <Row>
                  <Col style={{ fontWeight: 700 }} className="pt-2 ps-3" lg="6">
                    <p>$270.00</p>
                  </Col>
                  <Col
                    className="d-flex justify-content-end align-items-center"
                    lg="6"
                  >
                    <button className="place-order" onClick={() => { handleCloseModal(); SetisOpenone(true) }}>
                      Proceed
                    </button>
                  </Col>
                </Row>

                <hr className="mt-0 mb-3" />
              </Container>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default Addtocartmodal;
