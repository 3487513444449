import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import { images } from "../../../constants";
import axios from "axios";
import { useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import url from "../../../config/axios"

const ServicesTransportFilter = ({
  shopCategory,
  Handledata,
  shopCategorytwo,
  Mydata,
  HandleShop,
  FilterHandler,
  title,
  Handleitem,
  FilterJobDetails,
  Handleadditioanalshop
 
}) => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSegment, setSelectedSegment] = useState("custom");
  const [selectedstateone, setSelectedstateone] = useState("");
  const [searchTermone, setSearchTermone] = useState("");
  const [selectedcity, setSelectedcity] = useState("");
  const [searchTermtwo, setSearchTermtwo] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle the country selection
  const handleCountryChange = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
  };

  const handleSearchChangestate = (event) => {
    setSearchTermone(event.target.value);
  };

  const handleSegmentChange = (value) => {
    setSelectedSegment(value);
  };

  const handleSearchChangecity = (event) => {
    setSearchTermtwo(event.target.value);
  };

  // Function to handle the state selection
  const handleCountryChangestate = (event) => {
    const { value } = event.target;
    setSelectedstateone(value);
  };

  // Function to handle the city selection
  const handleCountryChangecity = (event) => {
    const { value } = event.target;
    setSelectedcity(value);
  };
  const [hidefield, setHidefield] = useState(1);
  const [hidestate, setHidestate] = useState(1);
  const [hidecity, setHidecity] = useState(1);

  const location = useLocation();
  const [countrydata, SetCountrydata] = useState([]);
  const [getcountry] = useState();
  const [selectedState] = useState();
  const [getState, setState] = useState([]);
  const [getcity, setGetcity] = useState([]);
  const [statetwo, setStatetwo] = useState(getcountry);
  const [city, setCity] = useState(selectedState);
  const [open, setOpen] = useState(getcountry);
  const [transportc, setTransportc] = useState();

  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => SetCountrydata(res.data))

      .catch((error) => { });
  }, []);

  const country = [...new Set(countrydata.map((item) => item.country))];

  country.sort();

  const handleCountry = (e) => {
    let states = countrydata.filter(
      (state) => state.country === e.target.value
    );
    states = [...new Set(states.map((item) => item.subcountry))];
    states.sort();
    setState(states);
  };

  const handleState = (e) => {
    let cities = countrydata.filter(
      (city) => city.subcountry === e.target.value
    );
    setGetcity(cities);
  };

  const HandleCategory = () => { url.get("category/consumer/?type=transportShopItem").then(async (res) => { setTransportc(res.data.data.Category); }); };

  return (
    <div>
      <div className="d-flex pb-2 bg-primary-one pt-2 mb-2 ps-3">
        <div>
          <img className="w-imgone" src={images.WorldSearchtwo} alt="web" />
        </div>
        <div>
          <h6 className="titleText pt-1 ps-2 mb-0">{title}</h6>
        </div>
      </div>
      {location.pathname === "/find-Transport" ? (
        <>

          <div className=" ps-xl-3 pe-xl-3 ps-lg-1 pe-lg-1 ps-md-2 pe-md-2 ps-sm-2 pe-sm-2" >
            <div className=" segment-container ">
              <button
                className={`segment-button pt-1 pb-1 ${selectedSegment === "custom" ? "active" : ""
                  }`}
                onClick={() => handleSegmentChange("custom")}
              >
                Search by item details
              </button>
              <button
                className={`segment-button ${selectedSegment === "segment" ? "active" : ""
                  }`}
                onClick={() => handleSegmentChange("segment")}
              >
                Search by shop details
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      <div className=" px-3 pt-2">
        {location.pathname === "/find-services" ? (
          <>

<div>
              <Form.Label className="custm-lble">
                Search By Profile Name
              </Form.Label>
              <Form.Control
                className="custom-fix-one p-2"
                placeholder="profile name"
                name="profileName"
                onChange={(e) => {
                  Handleadditioanalshop(e)
                }}
              />
            </div>

            <div>
              <Form.Label className="custm-lble">
                Search By Service ID
              </Form.Label>
              <Form.Control
                className="custom-fix-one p-2"
                placeholder="Service Id"
                name="serviceid"
                onChange={(e) => {
                  Handleadditioanalshop(e)
                }}
              />
            </div>

            <div>
              <Form.Label className="custm-lble">Search by Category</Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="categoryid"
                onChange={(e) => {
                  Handleadditioanalshop(e);
                  Handledata(e);
                }}
              >
                <option>Category</option>
                {shopCategory?.map((category) => (
                  <>
                    <option
                      key={category._id}
                      value={`${category?._id}.${category?.name}`}
                    >
                      {category?.name}
                    </option>
                  </>
                ))}
              </Form.Select>
            </div>

            <div>
              <Form.Label className="custm-lble">
                Search by Sub-Category
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="subCategoryid"
                onChange={(e) => {
                  Handleadditioanalshop(e)
                }}
                onClick={Mydata}
              >
                <option>Select Sub Category</option>

                {shopCategorytwo?.map((subcategory) => (
                  <>
                    <option key={subcategory._id} value={subcategory?._id}>
                      {subcategory?.name}
                    </option>
                  </>
                ))}
              </Form.Select>
            </div>

            <div>
              <Form.Label className="custm-lble pt-3">
                Search by Location
              </Form.Label>
            </div>

            <div className="col-md-12">
              <label
                htmlFor="inputPassword4"
                placeholder="First name"
                className="form-label labelSize"
              >
                Country*
              </label>
              <div className="blood-type custom-fix-one">
                <div className="d-flex">
                  <div className="w-100">
                    <input
                      type="search"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      placeholder="Search country here...."
                      className="w-100 search-input"
                      onClick={() => setHidefield(0)}
                    />
                  </div>
                  <div>
                    <span>
                      <SearchIcon className="search-icon-fs" />
                    </span>
                  </div>
                </div>

                <select
                  onClick={() => setHidefield(1)}
                  value={selectedCountry}
                  onChange={(e) => {
                    handleCountryChange(e);
                    handleCountry(e);
                    Handleadditioanalshop(e);
                    Handledata(e);

                    HandleShop(e);
                  }}
                  className="b-radius selectSize w-100"
                  name="country"
                >
                  {hidefield && <option>Select country</option>}

                  {country
                    .filter((country) =>
                      country.toLowerCase().includes(searchTerm.toLowerCase())
                    )
                    .map((country, index) => (
                      <option key={country} value={getcountry}>
                        {country}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="col-md-12">
              <label
                htmlFor="inputPassword4"
                placeholder="First name"
                className="form-label labelSize"
              >
                State*
              </label>
              <div className="blood-type custom-fix-one">
                <div className="d-flex">
                  <div className="w-100">
                    <input
                      type="search"
                      value={searchTermone}
                      onChange={handleSearchChangestate}
                      placeholder="Search state here...."
                      className="w-100 search-input"
                      onClick={() => setHidestate(0)}
                    />
                  </div>
                  <div>
                    <span>
                      <SearchIcon className="search-icon-fs" />
                    </span>
                  </div>
                </div>

                <select
                  onClick={() => setHidestate(1)}
                  className="b-radius selectSize w-100"
                  value={selectedstateone}
                  aria-label="Default select example "
                  name="state"
                  onChange={(e) => {
                    handleState(e);
                    handleCountryChangestate(e);
                    Handledata(e);
                    Handleadditioanalshop(e);
                    HandleShop(e);
                  }}
                >
                  {hidestate && <option>Select state</option>}


                  {getState
                    .filter((country) =>
                      country
                        .toLowerCase()
                        .includes(searchTermone.toLowerCase())
                    )
                    .map((country) => (
                      <option key={country} value={selectedState}>
                        {country}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <label
                htmlFor="inputPassword4"
                placeholder="First name"
                className="form-label labelSize"
              >
                City
              </label>
              <div className="blood-type custom-fix-one">
                <div className="d-flex">
                  <div className="w-100">
                    <input
                      type="search"
                      value={searchTermtwo}
                      onChange={handleSearchChangecity}
                      placeholder="Search city here...."
                      className="w-100 search-input"
                      onClick={() => setHidecity(0)}
                    />
                  </div>
                  <div>
                    <span>
                      <SearchIcon className="search-icon-fs" />
                    </span>
                  </div>
                </div>

                <select
                  onClick={() => setHidecity(1)}
                  value={selectedcity}
                  className="b-radius selectSize w-100"
                  aria-label="Default select example"
                  name="city"
                  onChange={(e) => {
                    handleCountryChangecity(e);
                    Handledata(e);
                    Handleadditioanalshop(e);
                    HandleShop(e);
                  }}
                >


                  {hidecity && <option>Select city</option>}

                  {getcity
                    .filter((country) =>
                      country.name
                        .toLowerCase()
                        .includes(searchTermtwo.toLowerCase())
                    )
                    .map((country) => (
                      <option key={country.name}>{country.name}</option>
                    ))}
                </select>
              </div>
            </div>
            <div className="pt-2 pb-2 d-flex justify-content-center ">
                <button className="btn-lg bg-blue text-white" onClick={FilterHandler}>
                  Search
                </button>
              </div>
          </>
        ) : (
          selectedSegment === "custom" ?
            (
              <>
                <div>
                  <Form.Label className="custm-lble">Vehicle Brand Name</Form.Label>{" "}
                  <Form.Control
                    className="custom-fix-one"
                    placeholder="Name"
                    name="brandName"
                    onChange={(e) => Handleitem(e)}

                  />
                </div>

                <div>
                  <Form.Label className="custm-lble">Vehicle Modal</Form.Label>
                  <Form.Control
                    className="custom-fix-one"
                    placeholder="Modal"
                    name="model"
                    onChange={(e) => Handleitem(e)}
                  />
                </div>

                <div>
                  <Form.Label className="custm-lble pt-3">
                    Search by Location
                  </Form.Label>
                </div>
                <div className="col-md-12">
                  <label
                    htmlFor="inputPassword4"
                    placeholder="First name"
                    className="form-label labelSize"
                  >
                    Country*
                  </label>
                  <div className="blood-type custom-fix-one">
                    <div className="d-flex">
                      <div className="w-100">
                        <input
                          type="search"
                          value={searchTerm}
                          onChange={handleSearchChange}
                          placeholder="Search country here...."
                          className="w-100 search-input"
                          onClick={() => setHidefield(0)}
                        />
                      </div>
                      <div>
                        <span>
                          <SearchIcon className="search-icon-fs" />
                        </span>
                      </div>
                    </div>
                    <select
                      onClick={() => setHidefield(1)}
                      value={selectedCountry}
                      name="country"
                      onChange={(e) => {
                        handleCountryChange(e);
                        handleCountry(e);

                        Handleitem(e);
                      }}
                      className="b-radius selectSize w-100"

                    >
                      {hidefield && <option>Select country</option>}

                      {country
                        .filter((country) =>
                          country.toLowerCase().includes(searchTerm.toLowerCase())
                        )
                        .map((country, index) => (
                          <option key={country} value={getcountry}>
                            {country}
                          </option>
                        ))}

                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <label
                    htmlFor="inputPassword4"
                    placeholder="First name"
                    className="form-label labelSize"
                  >
                    State*
                  </label>
                  <div className="blood-type custom-fix-one">
                    <div className="d-flex">
                      <div className="w-100">
                        <input
                          type="search"
                          value={searchTermone}
                          onChange={handleSearchChangestate}
                          placeholder="Search state here...."
                          className="w-100 search-input"
                          onClick={() => setHidestate(0)}
                        />
                      </div>
                      <div>
                        <span>
                          <SearchIcon className="search-icon-fs" />
                        </span>
                      </div>
                    </div>
                    <select
                      onClick={() => setHidestate(1)}
                      className="b-radius selectSize w-100"
                      value={selectedstateone}
                      aria-label="Default select example"
                      name="state"
                      onChange={(e) => {
                        handleState(e);
                        handleCountryChangestate(e);
                        Handleitem(e);
                      }}
                    >
                      {hidestate && <option>Select state</option>}
                      {getState
                        .filter((country) =>
                          country
                            .toLowerCase()
                            .includes(searchTermone.toLowerCase())
                        )
                        .map((country) => (
                          <option key={country} value={selectedState}>
                            {country}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <label
                    htmlFor="inputPassword4"
                    placeholder="First name"
                    className="form-label labelSize"
                  >
                    City
                  </label>
                  <div className="blood-type custom-fix-one">
                    <div className="d-flex">
                      <div className="w-100">
                        <input
                          type="search"
                          value={searchTermtwo}
                          onChange={handleSearchChangecity}
                          placeholder="Search city here...."
                          className="w-100 search-input"
                          onClick={() => setHidecity(0)}
                        />
                      </div>
                      <div>
                        <span>
                          <SearchIcon className="search-icon-fs" />
                        </span>
                      </div>
                    </div>
                    <select
                      onClick={() => setHidecity(1)}
                      value={selectedcity}
                      className="b-radius selectSize w-100"
                      aria-label="Default select example"
                      name="city"
                      onChange={(e) => {
                        handleCountryChangecity(e);
                        Handleitem(e);
                      }}
                    >
                      {/* <option>
                              {city === undefined
                                ? "Select State First"
                                : "Select City"}
                            </option> */}

                      {hidecity && <option>Select city</option>}

                      {getcity
                        .filter((country) =>
                          country.name
                            .toLowerCase()
                            .includes(searchTermtwo.toLowerCase())
                        )
                        .map((country) => (
                          <option key={country.name}>{country.name}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="mt-4 mb-4">
                  <Form.Label className="custm-lble pt-2 mb-0">Price Filter</Form.Label>
                  <Row className="d-flex">
                    <Col xl={3} lg={4} md={2} sm={2} xs={3} className="d-flex">
                      <Form.Label className="custm-lble pt-1 pe-1">Min</Form.Label>
                      <div className="inputSetting">
                        <input
                          placeholder=""
                          type="number"
                          name="min"
                          onChange={(e) => Handleitem(e)}
                        />
                      </div>
                    </Col>
                    <Col xl={3} lg={4} md={2} sm={2} xs={3} className="d-flex">
                      <Form.Label className="custm-lble pt-1 pe-1">Max</Form.Label>
                      <div className="inputSetting">
                        <input
                          placeholder=""
                          type="number"
                          name="max"
                          onChange={(e) => Handleitem(e)}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="pt-2 pb-2 d-flex justify-content-center ">
                  <button className="btn-lg bg-blue text-white" onClick={FilterJobDetails}>
                    Search
                  </button>
                </div>
              </>
            ) : (<>
              <div>
                <Form.Label className="custm-lble">Search By ID</Form.Label>
                <Form.Control className="custom-fix-one" placeholder="ID"
                  name="shopid"
                  onChange={(e) => {
                    HandleShop(e);
                  }}
                />
              </div>
              <div>
                <Form.Label className="custm-lble">
                  Search By Name
                </Form.Label>
                <Form.Control
                  className="custom-fix-one"
                  placeholder="Name"
                  name="shopname"
                  onChange={(e) => {
                    HandleShop(e);
                  }}
                />
              </div>
              <div>
                <Form.Label className="custm-lble">Search by Category</Form.Label>
                <Form.Select aria-label="Default select example"
                  name="shopCategory"
                  onClick={HandleCategory}
                  onChange={(e) => {
                    HandleShop(e);

                  }}
                >
                  <option disabled selected>Category</option>
                  {
                    transportc?.map((e) => (
                      <>
                        <option value={`${e?._id}`}  >{e.name}</option >
                      </>
                    ))
                  }

                </Form.Select>
              </div>
              <div className="pt-2 pb-2 d-flex justify-content-center ">
                <button className="btn-lg bg-blue text-white" onClick={FilterHandler}>
                  Search
                </button>
              </div>
            </>)

        )
        }

      </div>
    </div >
  );
};
export default ServicesTransportFilter;
