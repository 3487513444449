import { Col, Form } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useLocation } from "react-router-dom";
import ProfileInformationForm from "../profilrinformationForm";
import PreviuosEmployementHistory from "../PreviuosEmployementHistory";
import FormalEducation from "../FormalEducation";
import ProfessionalSkills from "../professionalSkills";
import LanguageSkills from "../LanguageSkills";
import Certifications from "../Certifications";

//Setting components
import Setbankaccount from "../../../Main_setting/components/Set_Baankaccount";
import Acceptmethod from "../../../Main_setting/components/Accept_method";
import Changeshopname from "../../../Main_setting/components/Change_shopname";
import Shippinganddelivering from "../../../Main_setting/components/Ship_and_deliver";
import RequestDeleteshop from "../../../Main_setting/components/Request_to_deleteshop";
const CVAccordions = ({ one, two, three, four, five, six, handelvalues, shopid }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const prevPath = searchParams.get("prevPath");

  return (
    <Col>
      <Accordion>
        <Accordion.Item eventKey="1" className="mb-2">
          <div>
            <Accordion.Header className="accordion-border ">
              <p className="font-blue-text m-0">{one}</p>
            </Accordion.Header>
          </div>

          <Accordion.Body className="accordion-body-open noscroll ">
            {location.pathname === "/CV-Form" ? (
              <ProfileInformationForm />
            ) : (
              <Setbankaccount shopid={shopid} />
            )}
          </Accordion.Body>
        </Accordion.Item>

        {location.pathname === "/CV-Form" ? (
          <Accordion.Item eventKey="2" className="mb-2">
            <Accordion.Header className="accordion-border">
              <p className="font-blue-text m-0">{two}</p>
            </Accordion.Header>

            <Accordion.Body className="accordion-body-open noscroll">
              <PreviuosEmployementHistory />
            </Accordion.Body>
          </Accordion.Item>
        ) : (
          ""
        )}

        <Accordion.Item eventKey="3" className="mb-2">
          <Accordion.Header className="accordion-border">
            <p className="font-blue-text m-0">{three}</p>
          </Accordion.Header>
          <Accordion.Body className="accordion-body-open noscroll">
            {location.pathname === "/CV-Form" ? (
              <FormalEducation />
            ) : (
              <Changeshopname shopid={shopid} />
            )}
          </Accordion.Body>
        </Accordion.Item>
        {prevPath === "/RestaurantAlldata" ? (
          ""
        ) : (
          <Accordion.Item eventKey="4" className="mb-2">
            <Accordion.Header className="accordion-border">
              <p className="font-blue-text m-0">{four}</p>
            </Accordion.Header>
            <Accordion.Body className="accordion-body-open noscroll">
              {location.pathname === "/CV-Form" ? (
                <ProfessionalSkills />
              ) : (
                <Shippinganddelivering shopid={shopid} />
              )}
            </Accordion.Body>
          </Accordion.Item>
        )}

        <Accordion.Item eventKey="5" className="mb-2">
          <Accordion.Header className="accordion-border">
            <p className="font-blue-text m-0">{five}</p>
          </Accordion.Header>
          <Accordion.Body className="accordion-body-open noscroll">
            {location.pathname === "/CV-Form" ? (
              <LanguageSkills />
            ) : (
              <RequestDeleteshop shopid={shopid} />
            )}
          </Accordion.Body>
        </Accordion.Item>

        {location.pathname === "/CV-Form" ? (
          <Accordion.Item eventKey="6" className="mb-2">
            <Accordion.Header className="accordion-border">
              <p className="font-blue-text m-0">{six}</p>
            </Accordion.Header>
            <Accordion.Body className="accordion-body-open noscroll">
              <Certifications handelvalues={handelvalues} />
            </Accordion.Body>
          </Accordion.Item>
        ) : (
          ""
        )}
      </Accordion>
    </Col>
  );
};
export default CVAccordions;
