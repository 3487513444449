import { useState } from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import { addProfessionalSkills } from "../../../../constants/jsonConstant";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import url from "../../../../config/axios";
import { FailedNotify, SuccessNotify } from "../../../../constants/tostify";
import { useSelector } from "react-redux";
const ProfessionalSkills = () => {
  const [addMore, setAddMore] = useState(addProfessionalSkills);
  const [isloading, setIsloading] = useState(false);
  const { name } = useSelector((state) => state.user);
  const AddmoreFields = () => {
    setAddMore([...addMore, addMore]);
  };

  const [cvdata, setCvdata] = useState({
    user: name._id,
   
    profesionalskils: ["", "", "", "", "", ""],
  });

  console.log("_+_+", cvdata);
  const handleRemoveClick = (index) => {
    const list = [...addMore];
    list.splice(index, 1);
    setAddMore(list);
  };

  const handleSkillChange = (index, event) => {
    const newSkills = [...cvdata.profesionalskils];
    newSkills[index] = event.target.value;
    setCvdata({ ...cvdata, profesionalskils: newSkills });
  };

  const handelvalues = (e) => {
    setCvdata((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const PostCvData = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("cvform/postNew", cvdata)
      .then(async (response) => {
        if (response?.data?.message.length > 0) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify(" Successfully Save CV Data");
          }, 1000);
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed to Save CV Data");
      });
  };
  return (
    <div className="px-2">
      {addMore?.map((addnew, index) => (
        <>
          <Row>
            {cvdata?.profesionalskils.map((skill, index) => (
              <>
              <Col lg="4">
              <Form.Label className="custm-lble ">{`Skill ${
                  index + 1
                }`}</Form.Label>
                <Form.Control
                  key={index}
                  type="text"
                  value={skill}
                  onChange={(e) => handleSkillChange(index, e)}
                  placeholder={`Skill ${index + 1}`}
                  className="labelText"
                  name="profesionalskils"
                />
              </Col>
               
              </>
            ))}
          </Row>
        
          {index === 0 ? (
            " "
          ) : (
            <div className="d-flex justify-content-end cursor_P">
              <div>
                <DeleteIcon className="color-red" />
              </div>
              <div
                className="d-flex align-items-center"
                onClick={() => handleRemoveClick()}
              >
                <p className="color-remove-red mb-0">Remove</p>
              </div>
            </div>
          )}

          <div class="line-width">
            <hr className="mb-2"></hr>
          </div>
          <div>
            <p className="color-text cursor_P" onClick={() => AddmoreFields()}>
              <AddCircleIcon style={{ fontSize: "large" }} /> Add More
            </p>
          </div>
        </>
      ))}
     

      <div className="d-flex justify-content-end pt-3 pb-4 ">
        <div>
          <button className="m-0 btn-color" onClick={PostCvData}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
export default ProfessionalSkills;
