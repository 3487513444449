import "./viewchatcomment.css"
import {commentsLiveChatRoom} from "../../constants/jsonConstant"
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

const LiveChatRoomComments = () => {
    return (
        <div>
            <div className=" ps-0 pe-0 commentsBackground">
           <div className="col-2 ">
      
           </div> 
            
                <div  className="">
                    <h6 className="text-white p-3">Title </h6>
                    <h6 className="text-white p-3 pt-0">Photography</h6>
                    <h5 className="text-white p-3 pt-0">Comments</h5>
                    <div className="scrollingComments noscroll">
                        {commentsLiveChatRoom?.map((comments)=>
                            <div className="d-flex ps-2 pe-2">
                            <div className="commentsImg">
                                <img src={comments.img} alt="" />
                            </div>
                            <div className="commentsText">
                                <h6>{comments.personName}</h6>
                                <p>{comments.personComment}</p>
                            </div>
                        </div>
                        )}
                    
                    </div>
                    <div className="row p-3 pb-2">
                    <div class="form-group d-flex bg-white col-9 search">
            <SentimentSatisfiedAltIcon className="mt-1 ms-1 pt-1" />
            <textarea
              class="form-control custom_form_Control"
              id="exampleFormControlTextarea1"
              placeholder="Write Message..."
              rows="1"
            ></textarea>
          </div>
                        <div className="col-3 ps-0">
                            <div>
                                <button className="sendButton">Send</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LiveChatRoomComments;