import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./style.css";
import { languages } from "../../../../constants/jsonConstant";
import { images } from "../../../../constants";
import LocationModal from "../../../../components/locationmodal/LocationModal";
import { Card } from "react-bootstrap";
import PersonalImage from "../../../../helpers/fileUpload";
import { useRef } from "react";
import ClearIcon from '@mui/icons-material/Clear';
const Promotionleftside = () => {
  const [mshow, setMshow] = useState(false);
  const inputfile = useRef(null);
  const upload_img = () => {
    inputfile.current.click();
  };

  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");


  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      console.log("mtfile", e.target.files[0]);
      setSelectedImage(e.target.files[0]);
      // PersonalImage(e.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
    }
    // if (e.target.files[0]) {
    //   console.log("mtfile", e.target.files[0]);
    //   setSelectedImage(e.target.files[0]);
    //   // PersonalImage(e.target.files[0]);
    //   const reader = new FileReader();
    //   reader.readAsDataURL(e.target.files[0]);
    // }
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setUploadedImages([reader.result]);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const removeImage = (index) => {
    const updatedImages = [uploadedImages];
    updatedImages.splice(index, 1);
    setUploadedImages(updatedImages);
  };


  const handleClose = () => setMshow(false);
  const handleShow = () => setMshow(true);
  return (
    <div>
      {selectedImage && <PersonalImage image={selectedImage} />}
      <LocationModal mshow={mshow} handleClose={handleClose} />
      <Container>
        <div className="ps-3">
        <h5 className="heading-top mt-2">Upload Image or Video</h5>
        <Col>
                      <Row>
                        <Col lg={4} xl={3} className=" mt-2 cursor_P">
                          <Card
                            style={{ width: "6rem", height: "6rem" }}
                            onClick={upload_img}
                          >
                            <Card.Img
                              className="imet-fit"
                              src={images.AddImage}
                            />
                            <Card.Body>
                              <input
                                type="file"
                                id="file"
                                ref={inputfile}
                                style={{ display: "none" }}
                                name="image"
                                onChange={(e) => {
                                  onChangePicture(e);
                                 
                                }}
                           
                              />
                            </Card.Body>
                          </Card>
                        
                        </Col>
                        <Col
                          lg={9}
                          xl={8}
                          className=" mt-1 d-flex justify-content-start gap-2 ms-xl-3 "
                        >
                          {uploadedImages.map((image, index) => (
                            <Card
                              style={{
                                width: "6rem",
                                height: "6rem",
                                border: "2px solid",
                              }}
                              key={index}
                            >
                              <Card.Img
                                className="imet-fit upload-img"
                                src={image}
                                alt={`Uploaded ${index}`}
                              />
                              <div
                                className="close-btnone"
                                onClick={() => removeImage(index)}
                              >
                                <ClearIcon />
                              </div>
                            </Card>
                          ))}
                        </Col>
                      </Row>
                    </Col>

                    <Col lg="10" xl="10" className="mt-3 des-placeholder">
                      <h6 className="mb-2">Description</h6>
                      <textarea className="promote-inputs-style w-100" placeholder="Add Description"></textarea>
                    </Col>

          <div className="pt-2">
            <h5 className="heading-top">Enter detail of targeted audience</h5>
          </div>
          <h6 className="pt-2">Audience Location</h6>
          <div className="maprel">
            <div className="form-group">
              <div className="col-sm-10">
                <p
                  type="text"
                  className=" promote-inputs-style mb-0 p-2 font_12"
                  placeholder="Select your audience Location"
                  
                >Select Location</p>
              </div>
            </div>
            {/* <input
              className="w-75 "
              type="text"
              placeholder="Select your audience Location "
            /> */}
            <img
              className="mapset cursor_P"
              src={images.Mapmaker}
              alt=""
              onClick={handleShow}
            />
          </div>

          {/* <Form.Select
            className="border_3 "
            style={{ width: "55%" }}
            aria-label="Default select example"
          >
            <option>location</option>
          </Form.Select> */}
          <div className="pt-4">
            <h6 className="heading-two">Age</h6>
          </div>
          <div>
            <input
              className="me-2 border_3 promote-inputs-style font_12 p-2"
              style={{ width: "18%" }}
              type="number"
              min="1"
            />
            <input
              className="ms-3 border_3 promote-inputs-style font_12 p-2"
              style={{ width: "18%" }}
              type="number"
              min="1"
            />
          </div>
          <h6 className="pt-3 ">Gender</h6>
          <div>
            <Form>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    className="labelone "
                    inline
                    label="All"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    className="labelone "
                    inline
                    label="Men"
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                  <Form.Check
                    className="labelone "
                    inline
                    label="Women"
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </div>
              ))}
            </Form>
          </div>

          <h6 className="pt-1">Language </h6>

          <Form.Select
            className="border_3  promote-inputs-style"
            style={{ width: "55%" }}
            aria-label="Default select example"
          >
            {languages?.map((e) => (
              <option>{e.lang}</option>
            ))}
          </Form.Select>
        </div>
      </Container>
    </div>
  );
};

export default Promotionleftside;
