import { Col, Row } from "react-bootstrap";
import "./offerJobForm.css";
import Form from "react-bootstrap/Form";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import BackArrow from "../../backArrow";
import { useState, useEffect } from "react";
import url from "../../../config/axios";
import axios from "axios";
import { FailedNotify, SuccessNotify } from "../../../constants/tostify";
import { useSelector } from "react-redux";
import Loader from "../../Loader";
import SearchIcon from "@mui/icons-material/Search";
import { Formik } from "formik";
import * as Yup from "yup";

const OfferJobForm = (props) => {
  const { name } = useSelector((state) => state.user);
  const { id } = useParams();
  const [isloading, setIsloading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedstateone, setSelectedstateone] = useState("");
  const [searchTermone, setSearchTermone] = useState("");
  const [selectedcity, setSelectedcity] = useState("");
  const [searchTermtwo, setSearchTermtwo] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  // Function to handle the country selection
  const handleCountryChange = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
  };
  const handleSearchChangestate = (event) => {
    setSearchTermone(event.target.value);
  };
  const handleSearchChangecity = (event) => {
    setSearchTermtwo(event.target.value);
  };
  // Function to handle the state selection
  const handleCountryChangestate = (event) => {
    const { value } = event.target;
    setSelectedstateone(value);
  };
  // Function to handle the city selection
  const handleCountryChangecity = (event) => {
    const { value } = event.target;
    setSelectedcity(value);
  };
  const [hidefield, setHidefield] = useState(1);
  const [hidestate, setHidestate] = useState(1);
  const [hidecity, setHidecity] = useState(1);
  const [countrydata, SetCountrydata] = useState([]);
  const [getcountry] = useState();
  const [selectedState] = useState();
  const [getState, setState] = useState([]);
  const [getcity, setGetcity] = useState([]);
  const [statetwo, setStatetwo] = useState(getcountry);
  const [companydata, setCompanydata] = useState();
  const [city, setCity] = useState(selectedState);
  const [shopCategory, setShopCategory] = useState();
  const [jobdataone, SetJobdataone] = useState({
    categoryid: "",
    title: "",
  });
  const [jobdatatwo, SetJobdatatwo] = useState({
    subCategoryid: "",
  });

  const [jobcategory, SetJobcategory] = useState();
  const [jobcategorytwo, SetJobcategorytwo] = useState();

  const Handlejobdata = (e) => {
    SetJobdataone((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split("."),
    }));
  };
  const Handlejobdatatwo = (e) => {
    SetJobdatatwo((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split("."),
    }));
  };

  const jobtitle = jobdataone?.title[1];
  const jobtitleid = jobdataone?.title[0];
  console.log("jobtitle11111111", jobtitle)
  const categoryName = jobdataone?.categoryid[1];
  const categoryidtwo = jobdataone?.categoryid[0];
  const subcategoryName = jobdatatwo?.subCategoryid[1];
  const subcategoryidtwo = jobdatatwo?.subCategoryid[0];
  const [jobdata, setJobdata] = useState({
    companyId: id,
    title: "",
    companyname: "",
    category: "",
    categoryid: "",
    subCategoryid: "",
    subCategory: "",
    country: "",
    state: "",
    city: "",
    duration: "",
    jobtype: "",
    siteremote: "",
    description: "",
    email: "",
    contact: "",
    businessType: "jobsCompany"
  });
  console.log("check the post data", jobdata)
  useEffect(() => {
    setJobdata((jobdata) => ({
      ...jobdata,
      categoryid: categoryidtwo,
      category: categoryName,
      subCategoryid: subcategoryidtwo,
      subCategory: subcategoryName,
      companyname: companydata?.companyname,
      title: jobtitleid
    }
    ))
  }, [categoryidtwo, categoryName, subcategoryidtwo, subcategoryName]);
  const Mydataone = () => {
    url
      .get("/category/type/?type=jobs&parentCategory=")
      .then(async (response) => {

        if (response?.data?.success) {
          console.log(response, "response33344444")
          setShopCategory(response?.data?.data?.Category);

        }
      })
      .catch((error) => {
      });
  }
  const Mydatatwo = () => {
    url
      .get(`/category/type/?type=jobs&parentCategory=${jobtitle}`)
      .then(async (response) => {
        if (response?.data?.success) {
          SetJobcategory(response?.data?.data?.Category);
        }
      });
  }
  const Mydatathree = () => {
    url
      .get(`/category/type/?type=jobSubItem&parentCategory=${categoryName}`)
      .then(async (response) => {
        if (response?.data?.success) {
          SetJobcategorytwo(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("jobcategory", error);
      });
  };
  const PostData = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("/job/", jobdata)
      .then(async (response) => {
        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Job Posted SucessFully");
            navigate(`/company-profile/${id}`);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed to post");
      });
  };
  const JobHandler = (event) => {
    setJobdata((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));

  };
  // Countries functioncallity 
  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => SetCountrydata(res.data))
      .catch((error) => { });
  }, []);

  const country = [...new Set(countrydata.map((item) => item.country))];
  country.sort();
  const handleCountry = (e) => {
    let states = countrydata.filter(
      (state) => state.country === e.target.value
    );
    states = [...new Set(states.map((item) => item.subcountry))];
    states.sort();
    setState(states);
  };
  const handleState = (e) => {
    let cities = countrydata.filter(
      (city) => city.subcountry === e.target.value
    );
    setGetcity(cities);
  };


  useEffect(() => {
    url.get(`company/id/?id=${id}`).then(async (response) => {
      setCompanydata(response?.data?.data?.Company)
    })
  }, [id]);



  useEffect(() => {
    url
      .get(`job/id/?id=${id}`)
      .then(async (response) => {
        console.log("response__", response);
        setJobdata(response?.data?.data?.job);
      })
      .catch((error) => { });
  }, [id]);


  const validationSchema = Yup.object({
    title: Yup.string().required("title is required*"),
    // category: Yup.string().required("category is required*"),
    // subcategory: Yup.string().required("subcategory is required*"),
    country: Yup.string().required("country is required*"),
    state: Yup.string().required("state is required*"),
    city: Yup.string().required("city is required*"),
    // jobtype: Yup.string().required("jobtype is required*"),
    // siteremote: Yup.string().required("siteremote is required*"),
    // lastdate: Yup.string().required("lastdate is required*"),
    description: Yup.string().required("description is required*"),
  });
  const updateData = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .put(`job/id/?id=${id}`, jobdata)
      .then(async (response) => {
        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Job Update SucessFully");
            navigate("/job-details/" + id);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed to Update job");
      });
  };
  if (isloading) {
    return (
      <div>
        <Loader heightsetting="screenHeight" />
      </div>
    );
  }
  return (
    <div>
      <Formik
        initialValues={{
          title: "",

          // category: "",
          // subcategory: "",
          country: "",
          state: "",
          city: "",
          duration: "",
          // jobtype: "",
          // siteremote: "",
          // lastdate: "",
          description: "",
          email: "",
          contact: "",
        }}
        // validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={(values) => { }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
          isValid,
          dirty,
        }) => (
          <Form>
            <Row>
              <div className="screenHeight d-flex justify-content-center align-items-center">
                <Col lg={9} md={9} className="Form-Shadow">
                  {location.pathname === `/update-job/${id}` ? (
                    <div className="arrowback ps-3">
                      <BackArrow
                        back={`/job-details/${id}`}
                        black="black"
                        mystyle="ps-2 p-1"
                      />
                    </div>
                  ) : (
                    <div className="arrowback ps-3">
                      <BackArrow
                        back={`${props.back}/${id}`}
                        black="black"
                        mystyle="ps-4 p-1 "
                      />
                    </div>
                  )}

                  <Row className="pt-3 px-5">

                    <Col md={3} lg={3} xl={3}>
                      <Form.Label
                        id="placeholderClorset"
                        className="custm-lble mt-1 "
                      >
                        Company Name
                      </Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          handleChange(e);
                          JobHandler(e);
                        }}
                        onBlur={handleBlur}
                        defaultValue={jobdata?.companyname}
                        className="form-control-seller placeholderClorset"
                        name="companyname"
                        value={companydata?.companyname}
                      />

                    </Col>
                    <Col md={3} lg={3} xl={3}>
                      <Form.Label className="custm-lble">Job Title</Form.Label>
                      <Form.Select
                        className="form-select-seller"
                        aria-label="Default select example"
                        // value={jobdata?.country}
                        name="title"
                        onChange={(e) => {

                          handleChange(e);
                          Handlejobdata(e);
                        }}
                        onClick={Mydataone}
                        onBlur={handleBlur}
                      >
                        <option selected disabled>Select Title</option>
                        {shopCategory?.map((category) => (
                          <option key={category._id} value={`${category?._id}.${category?.name}`}>
                            {category?.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>

                    <Col md={3} lg={3} xl={3}>
                      <Form.Label className="custm-lble">
                        Job Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="form-select-seller"
                        name="categoryid"
                        onChange={(e) => {
                          Handlejobdata(e);
                        }}
                        onClick={Mydatatwo}
                      >
                        <option>job category</option>
                        {jobcategory?.map((category) => (
                          <>
                            <option
                              key={category._id}
                              value={`${category?._id}.${category?.name}`}
                            >
                              {category?.name}
                            </option>
                          </>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md={3} lg={3} xl={3}>
                      <div className="comapnyselectdiv">
                        <Form.Label className="custm-lble">
                          Job Sub-Category
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          className="form-select-seller"
                          name="subCategoryid"
                          onChange={(e) => {
                            JobHandler(e);
                            Handlejobdatatwo(e);
                          }}
                          onClick={Mydatathree}
                        >
                          <option>job sub category</option>
                          {jobcategorytwo?.map((subcategory) => (
                            <>
                              <option
                                key={subcategory._id}
                                value={`${subcategory?._id}.${subcategory?.name}`}
                              >
                                {subcategory?.name}
                              </option>
                            </>
                          ))}
                        </Form.Select>
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-3 pb-2 px-5">
                    <Col md={3} lg={3} xl={3}>
                      <Form.Label className="custm-lble mt-1">
                        Last Date Of Apply
                      </Form.Label>
                      <Form.Control
                        placeholder="Last Date Of Apply"
                        className="form-control-seller placeholderClorset"
                        name="duration"
                        onChange={(e) => {
                          handleChange(e);
                          JobHandler(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.duration && touched.duration ? (
                        <div>
                          <p className="error_text_color">{errors.duration}</p>
                        </div>
                      ) : null}
                    </Col>

                    <div className="col-md-3">
                      <label
                        htmlFor="inputPassword4"
                        placeholder="First name"
                        className="form-label labelSize"
                      >
                        Country*
                      </label>
                      <div className="blood-type">
                        <div className="d-flex">
                          <div className="w-100">
                            <input
                              type="search"
                              value={searchTerm}
                              onChange={handleSearchChange}
                              placeholder="Search country here...."
                              className="w-100 search-input"
                              onClick={() => setHidefield(0)}
                            />
                          </div>

                          <div>
                            <span>
                              <SearchIcon className="search-icon-fs" />
                            </span>
                          </div>
                        </div>

                        <select
                          onClick={() => setHidefield(1)}
                          value={selectedCountry}
                          onChange={(e) => {
                            handleCountryChange(e);
                            handleCountry(e);
                            JobHandler(e);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          className="b-radius selectSize w-100"
                          name="country"
                        >
                          {hidefield && <option>Select country</option>}

                          {country
                            .filter((country) =>
                              country
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            )
                            .map((country, index) => (
                              <option key={country} value={getcountry}>
                                {country}
                              </option>
                            ))}
                        </select>
                      </div>
                      {errors.country && touched.country ? (
                        <div>
                          <p className="error_text_color">{errors.country}</p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-3">
                      <label
                        htmlFor="inputPassword4"
                        placeholder="First name"
                        className="form-label labelSize"
                      >
                        State*
                      </label>
                      <div className="blood-type">
                        <div className="d-flex">
                          <div className="w-100">
                            <input
                              type="search"
                              value={searchTermone}
                              onChange={handleSearchChangestate}
                              placeholder="Search state here...."
                              className="w-100 search-input"
                              onClick={() => setHidestate(0)}
                            />
                          </div>
                          <div>
                            <span>
                              <SearchIcon className="search-icon-fs" />
                            </span>
                          </div>
                        </div>

                        <select
                          onClick={() => setHidestate(1)}
                          className="b-radius selectSize w-100"
                          value={selectedstateone}
                          aria-label="Default select example "
                          name="state"
                          onChange={(e) => {
                            JobHandler(e);
                            handleChange(e);

                            handleState(e);
                            handleCountryChangestate(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {hidestate && <option>Select state</option>}
                          {/* <option>
                            {Open === undefined
                              ? "Select Country First"
                              : "select state"}
                          </option> */}

                          {getState
                            .filter((country) =>
                              country
                                .toLowerCase()
                                .includes(searchTermone.toLowerCase())
                            )
                            .map((country) => (
                              <option key={country} value={selectedState}>
                                {country}
                              </option>
                            ))}
                        </select>
                      </div>
                      {errors.state && touched.state ? (
                        <div>
                          <p className="error_text_color">{errors.state}</p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-3">
                      <label
                        htmlFor="inputPassword4"
                        placeholder="First name"
                        className="form-label labelSize"
                      >
                        City
                      </label>
                      <div className="blood-type">
                        <div className="d-flex">
                          <div className="w-100">
                            <input
                              type="search"
                              value={searchTermtwo}
                              onChange={handleSearchChangecity}
                              placeholder="Search city here...."
                              className="w-100 search-input"
                              onClick={() => setHidecity(0)}
                            />
                          </div>
                          <div>
                            <span>
                              <SearchIcon className="search-icon-fs" />
                            </span>
                          </div>
                        </div>

                        <select
                          onClick={() => setHidecity(1)}
                          value={selectedcity}
                          className="b-radius selectSize w-100"
                          aria-label="Default select example"
                          name="city"
                          onChange={(e) => {
                            JobHandler(e);
                            handleChange(e);
                            handleCountryChangecity(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {/* <option>
                              {city === undefined
                                ? "Select State First"
                                : "Select City"}
                            </option> */}

                          {hidecity && <option>Select city</option>}

                          {getcity
                            .filter((country) =>
                              country.name
                                .toLowerCase()
                                .includes(searchTermtwo.toLowerCase())
                            )
                            .map((country) => (
                              <option key={country.name}>{country.name}</option>
                            ))}
                        </select>
                      </div>
                    </div>
                    {errors.city && touched.city ? (
                      <div>
                        <p className="error_text_color">{errors.city}</p>
                      </div>
                    ) : null}
                  </Row>
                  <Row className="pt-0 px-5">
                    <Col md={3} lg={3} xl={3}>
                      <Form.Label className="custm-lble mt-1">Email</Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          handleChange(e);
                          JobHandler(e);
                        }}
                        onBlur={handleBlur}
                        defaultValue={jobdata?.email}
                        className="form-control-seller"
                        name="email"
                        placeholder="Email"
                      />
                      {errors.email && touched.email ? (
                        <div>
                          <p className="error_text_color">{errors.email}</p>
                        </div>
                      ) : null}
                    </Col>
                    <Col md={3} lg={3} xl={3}>
                      <Form.Label className="custm-lble mt-1">
                        Contact Number
                      </Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          handleChange(e);
                          JobHandler(e);
                        }}
                        onBlur={handleBlur}
                        defaultValue={jobdata?.contact}
                        className="form-control-seller placeholderClorset"
                        name="contact"
                        placeholder="Contact Number"
                      />
                      {errors.contact && touched.contact ? (
                        <div>
                          <p className="error_text_color">
                            {errors.contact}
                          </p>
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  <div className="d-flex px-5">
                    <div className="pe-0 me-3">
                      <Form.Label
                        className="custm-lble"
                        style={{ color: "#0d6efd" }}
                      >
                        Post Duration
                      </Form.Label>
                      <Form>
                        {["radio"].map((type) => (
                          <div key={`inline-${type}`} className="mb-2">
                            <div>
                              <Form.Check
                                inline
                                label="7 days"
                                name="duration"
                                value={jobdata?.duration}
                                type={type}
                                id={`inline-${type}-1`}
                                className="labelText2"
                                onChange={(e) => {
                                  JobHandler(e);
                                }}
                              />
                            </div>
                            <div>
                              <Form.Check
                                inline
                                label="14 days"
                                name="duration"
                                value={jobdata?.duration}
                                type={type}
                                id={`inline-${type}-2`}
                                className="labelText2"
                                onChange={(e) => {
                                  JobHandler(e);
                                }}
                              />
                            </div>
                            <div>
                              <Form.Check
                                inline
                                label="1 month"
                                name="duration"
                                value={jobdata?.duration}
                                type={type}
                                id={`inline-${type}-2`}
                                className="labelText2"
                                onChange={(e) => {
                                  JobHandler(e);
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </Form>
                    </div>
                    <div className="px-0 me-4">

                      <Form.Label
                        className="custm-lble"
                        style={{ color: "#0d6efd" }}
                      >
                        On-Site/Remotely/Hybrid
                      </Form.Label>
                      <Form>
                        {["radio"].map((type) => (
                          <div key={`inline-${type}`} className="mb-2">
                            <div>
                              <Form.Check
                                inline
                                label="On-Site"
                                name="siteremote"
                                value="On Site"
                                type={type}
                                id={`inline-${type}-1`}
                                className="labelText2"
                                onChange={(e) => {
                                  JobHandler(e);
                                }}
                              />
                            </div>
                            <div>
                              <Form.Check
                                inline
                                label="Remotely"
                                name="siteremote"
                                value="remote"
                                type={type}
                                id={`inline-${type}-2`}
                                className="labelText2"
                                onChange={(e) => {
                                  JobHandler(e);
                                }}
                              />
                            </div>
                            <div>
                              <Form.Check
                                inline
                                label="Hybrid"
                                name="siteremote"
                                value="hybrid"
                                type={type}
                                id={`inline-${type}-2`}
                                className="labelText2"
                                onChange={(e) => {
                                  JobHandler(e);
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </Form>
                    </div>
                    <div className="px-0 ">
                      <Form.Label
                        className="custm-lble"
                        style={{ color: "#0d6efd" }}
                      >
                        Job type
                      </Form.Label>
                      <Form>
                        {["radio"].map((type) => (
                          <div key={`inline-${type}`} className="mb-2">
                            <div>
                              <Form.Check
                                inline
                                label="Full Time"
                                name="jobtype"
                                value="full time"
                                type={type}
                                id={`inline-${type}-1`}
                                className="labelText2"
                                onChange={(e) => {
                                  JobHandler(e);
                                }}
                              />
                            </div>
                            <div>
                              <Form.Check
                                inline
                                label="Part Time"
                                value="part time"
                                name="jobtype"
                                type={type}
                                id={`inline-${type}-2`}
                                className="labelText2"
                                onChange={(e) => {
                                  JobHandler(e);
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </Form>
                    </div>
                  </div>

                  <div className="px-5">
                    <Form.Label className="custm-lble pb-2">
                      Job Description
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => JobHandler(e)}
                      value={jobdata?.description}
                      name="description"
                      as="textarea"
                      rows={9}
                    />
                    {errors.description && touched.description ? (
                      <div>
                        <p className="error_text_color">{errors.description}</p>
                      </div>
                    ) : null}
                  </div>

                  <div className="d-flex justify-content-end py-3 px-5 ">
                    <div>
                      <button
                        className="cbtn1 "
                        onClick={() => navigate(`/company-profile/${id}`)}
                      >
                        Cancel
                      </button>

                      <button
                        type="submit"
                        className="btn-blue-sm  pt-2 pb-2 px-3"
                        onClick={(e) => {
                          props.btn === "Post Job"
                            ? PostData(e)
                            : updateData(e);
                        }}
                      >
                        {props.btn}
                      </button>
                    </div>
                  </div>
                </Col>
              </div>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default OfferJobForm;
