import "./chatRoomsuerView.css";
import { images } from "../../../../../constants";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import MicIcon from '@mui/icons-material/Mic';
import VideocamIcon from '@mui/icons-material/Videocam';
import { useNavigate } from "react-router-dom";

const ChatRoomOwnView = () => {
  const navigate=useNavigate();
  return (
    <div>
      <div className="liveOwnChatRoomBackground ">
        <div className="col-2 pl-4"></div>
        <div className="d-flex col-4 justify-content-center  pt-4">
          <div className="inviteChatroomButton cursor_P" onClick={()=>navigate('/invite-people')}>
          <GroupAddIcon className="text-white mt-1" />
            <p className="mb-0 ms-1 ">Invite</p>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center pt-2 mt-2">
          <img src={images.LiveChatroom} alt="" className="ownChatroomImg" />
        </div>
        <div className="d-flex justify-content-between pt-3 col-10 mx-auto pb-3">
          <div className=" ps-0">
            <div className="d-flex justify-content-end pe-4 ">
      <div  className="iconsBackgroundVideo">  <VideocamIcon className="icon_f" /></div>
      <div  className="iconsBackgroundVoice ms-3">  <MicIcon className="icon_f" /></div>
           
              
            </div>
          </div>
          <div className="chatTime pt-2">
            <p>1 : 11</p>
          </div>
          <div className=" ps-0 ms-3 pt-2">
            <div className="d-flex justify-content-end ">
              <buton className="liveClose cursor_P" onClick={()=>navigate('/joinown-chatroom')}>End Live</buton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChatRoomOwnView;
