import BuyingFilter from "../../../components/filteration/buyingFilter";
import ShopAdds from "../../../components/shopAds/ShopAdds";
import Shops from "../../../components/Multipleshopprofile/Components/Shops/Shops";
import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import ShareModel from "../../../components/Sharemodel";
import ShareOption from "../../../components/Shareoption";

const BuyNewProducts = () => {
  const [data, setData] = useState(0);

  const [showmodal, setShowmodal] = useState(false);
  const handleClosemodal = () => setShowmodal(false);
  const handleShow = () => setShowmodal(true);
  const [showshare, setShowshare] = useState(false);
  const handleCloseshare = () => {
    setShowshare(false);
    setShowmodal(true);
  };
  const handleShowshare = () => setShowshare(true);

  return (
    <>
      <ShareOption showshare={showshare} handleCloseshare={handleCloseshare} />
      <ShareModel
        showmodal={showmodal}
        handleClosemodal={handleClosemodal}
        handleShowshare={handleShowshare}
      />
      <Container>
        <Row>
          <Col lg="4" className="screenHeight background-image">
            <div className="border b-radius height_100 ">
              <BuyingFilter />
            </div>
          </Col>
          <Col lg="8" className="screenHeight">
            <div className="border b-radius ">
              <ShopAdds back="/SellingSide" />
              <Shops
                link="/NewItems-UserProfile"
                title="New Items"
                data={data}
                handleShow={handleShow}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default BuyNewProducts;
