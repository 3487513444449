import React, { useState } from 'react'
import { Col,Row } from 'react-bootstrap'
import MessageIcon from '@mui/icons-material/Message';
import "./style.css"
import { useNavigate } from 'react-router-dom';

const Searchicon = () => {
  const navigate = useNavigate();
  
  return (

        <div >
        
          <Row>
            <Col lg={9} md={9}>
            <h3 className='ms-5 mt-4'>Customer Support</h3>
            </Col>
            <Col lg={3} md={3}>
              
            <button className='btns-sty mt-4 ms-2 p-1' onClick={()=>navigate("/ReportSelectezi")}> <MessageIcon fontSize='small' /> Report To selectezi</button>
            </Col>
          </Row>
      
        </div>
 
  )
}

export default Searchicon