import { Row, Col } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
const CVProfileBio = ({ cvdata }) => {
  const now1 = 80;
  const now2 = 65;
  const now3 = 100;
  const now4 = 70;

  return (
    <Row>
      <Col md={8} lg={7} xl={6}>
        <div className="cv-4-bio py-4 d-flex flex-column align-items-center justify-content-center">
          <h5 className="text-bio  fs-2 fw-bold mb-0 ps-3">
            {cvdata?.profileInformation?.firstName}
          </h5>
          <h6 className="text-bio  fs-5 ">
            {cvdata?.profileInformation?.jobDesignation}
          </h6>
        </div>
        <div className="d-flex ">
          <div>
            <hr className="cv-4-line" />
          </div>
          <div>
            <div className="profile-bio-scroll-4 noscroll">
              <div>
                <h6 className="cv-header-4 ">Previous Employement Details</h6>
                {cvdata?.preemphistory?.map((item) => (
                  <Row>
                    <Col md={6} lg={6} className="pe-0">
                      <div>
                        <p className="updateSize mb-0 mb-0">Company Name</p>
                        <p className="text-designation-color">
                          {item?.companyname}
                        </p>
                      </div>
                      <div>
                        <p className="updateSize mb-0">Start Date</p>
                        <p className="text-designation-color">
                          {" "}
                          {item?.startdate}
                        </p>
                      </div>
                      <div>
                        <p className="updateSize mb-0">Industry</p>
                        <p className="text-designation-color">
                          {item?.industry}
                        </p>
                      </div>
                    </Col>
                    <Col md={6} lg={6}>
                      <div>
                        <p className="updateSize mb-0">Designation</p>
                        <p className="text-designation-color">
                          {item?.designation}
                        </p>
                      </div>
                      <div>
                        <p className="updateSize mb-0">End Date</p>
                        <p className="text-designation-color">
                          {" "}
                          {item?.enddate}
                        </p>
                      </div>
                    </Col>
                  </Row>
                ))}

                <hr />
              </div>

              <div>
                <h6 className="cv-header-4  ">Formal Education</h6>
                {cvdata?.formaleducation?.map((item) => (
                  <Row>
                    <Col md={6} lg={6}>
                      <div>
                        <p className="updateSize mb-0">Institute Name</p>
                        <p className="text-designation-color">
                          {item?.institutename}
                        </p>
                      </div>
                      <div>
                        <p className="updateSize mb-0">Qualification</p>
                        <p className="text-designation-color">
                          {item?.qualification}
                        </p>
                      </div>
                      <div>
                        <p className="updateSize mb-0">Start Date</p>
                        <p className="text-designation-color">
                          {item?.startdate}
                        </p>
                      </div>
                      <div>
                        <p className="updateSize mb-0">Degree Status</p>
                        <p className="text-designation-color">
                          {item?.degreestatus}
                        </p>
                      </div>
                    </Col>
                    <Col md={6} lg={6}>
                      <div>
                        <p className="updateSize mb-0">Major</p>
                        <p className="text-designation-color">{item?.major}</p>
                      </div>
                      <div>
                        <p className="updateSize mb-0">Type of Study</p>
                        <p className="text-designation-color">
                          {item?.typeofstudy}
                        </p>
                      </div>
                      <div>
                        <p className="updateSize mb-0">End Date</p>
                        <p className="text-designation-color">
                          {item?.enddate}
                        </p>
                      </div>
                    </Col>
                  </Row>
                ))}
              </div>
              <div>
                <h6 className="cv-header-4  ">Professional Skills</h6>

                <Row>
                  <Col md={6} lg={6}>
                  {
  cvdata?.profesionalskils?.map((item) => (
    <div>
      <p>{item}</p>
    </div>
  ))
} 
                  </Col>
                  {/* <Col md={6} lg={6}>
                    <div className="pb-4">
                      <ProgressBar now={now1} label={`${now1}%`} />
                    </div>
                    <div className="pb-3">
                      <ProgressBar now={now2} label={`${now2}%`} />
                    </div>
                    <div className="pb-3">
                      <ProgressBar now={now3} label={`${now3}%`} />
                    </div>
                    <div>
                      <ProgressBar now={now4} label={`${now4}%`} />
                    </div>
                  </Col> */}
                </Row>
              </div>
              <div>
                <h6 className="cv-header-4 ">Language Skills</h6>
                {cvdata?.languageskils?.map((item) => (
                  <Row>
                    <Col md={6} lg={6}>
                      <div>
                        <p className="updateSize mb-0">Language</p>
                        <p className="text-designation-color">
                          {item?.language}
                        </p>
                      </div>
                      <div>
                        <p className="updateSize mb-0">Reading proficiency</p>
                        <p className="text-designation-color">
                          {item?.readingproficiency}
                        </p>
                      </div>
                    </Col>
                    <Col md={6} lg={6}>
                      <div>
                        <p className="updateSize mb-0">Speaking Proficiency</p>
                        <p className="text-designation-color">
                          {item?.speakingproficiency}
                        </p>
                      </div>
                      <div>
                        <p className="updateSize mb-0">Writing Proficiency</p>
                        <p className="text-designation-color">
                          {item?.writingproficiency}
                        </p>
                      </div>
                    </Col>
                  </Row>
                ))}

                <hr />
              </div>

              <div>
                <h6 className="cv-header-4 ">Certifications/Licences</h6>
                {cvdata?.certification?.map((item) => (
                  <Row>
                    <Col md={6} lg={6}>
                      <div>
                        <p className="updateSize mb-0">
                          Name of Certifications
                        </p>
                        <p className="text-designation-color">
                          {item?.nameofcertificate}
                        </p>
                      </div>
                      <div>
                        <p className="updateSize mb-0">Issuing Authority</p>
                        <p className="text-designation-color">
                          {item?.issuingauthority}
                        </p>
                      </div>
                      <div>
                        <p className="updateSize mb-0">Expiring Date</p>
                        <p className="text-designation-color">
                          {item?.expirationdate}
                        </p>
                      </div>
                    </Col>
                    <Col md={6} lg={6}>
                      <div>
                        <p className="updateSize mb-0">Institution</p>
                        <p className="text-designation-color">
                          {item?.institution}
                        </p>
                      </div>
                      <div>
                        <p className="updateSize mb-0">Effective Date</p>
                        <p className="text-designation-color">
                          {item?.effectivedate}
                        </p>
                      </div>
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default CVProfileBio;
