import { Col, Container, Row, Form } from "react-bootstrap";
import BackArrow from "../../components/backArrow/index";
import { images } from "../../constants";
import { useRef, useState } from "react";
import PersonalImage from "../../helpers/fileUpload";
import Button from "react-bootstrap/Button";
import { useNavigate, useLocation } from "react-router-dom";
import Card from "react-bootstrap/Card";
import ClearIcon from "@mui/icons-material/Clear";
import { currencies } from "../../constants/jsonConstant";
import { Formik } from "formik";
import * as Yup from "yup";
import "./usedvehicle.css";
import { getAsset } from "../../helpers/helper";
const Usedvehicleform = ({
  setUsedvehicle,
  PostVehicleData,
  UpdateVehicleData,
  butn,
  usedvehicle,
  PostItem,
  updateurl,
  index,
  id,
  getInputProps,
  getRootProps,
  displayimg,
  setDisplayimg
}) => {
  const navigate = useNavigate();
  const location = useLocation();


  const InputChange = (e) => {
    setUsedvehicle((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");

  const removeImage = (index) => {
    const updatedImages = [...displayimg];
    updatedImages.splice(index, 1);
    setDisplayimg(updatedImages);
  };
  const validationSchema = Yup.object({
    modelYear: Yup.string().required("modelYear is required*"),
    brandName: Yup.string().required("model is required*"),
    make: Yup.string().required("make is required*"),
    version: Yup.string().required("version is required*"),
    registeredIn: Yup.string().required("registeredIn is required*"),
    color: Yup.string().required("color is required*"),
    assembly: Yup.string().required("assembly is required"),
    city: Yup.string().required("city is required"),
    cityArea: Yup.string().required("cityArea is required"),
    engineType: Yup.string().required("engineType is required"),
    engineCapactiy: Yup.string().required("engineCapactiy is required"),
    currency: Yup.string().required("currency is required"),
    mileage: Yup.string().required("mileage is required"),
    transmission: Yup.string().required("transmission is required"),
    price: Yup.string().required("price is required"),
    contact: Yup.string().required("contact is required"),
    description: Yup.string().required("description is required"),

  });
  return (
    <>
      {selectedImage && <PersonalImage image={selectedImage} />}
      <Container>
        <Formik
          initialValues={{
            modelYear: "",
            brandName: "",
            make: "",
            version: "",
            registeredIn: "",
            color: "",
            assembly: "",
            city: "",
            cityArea: "",
            engineType: "",
            engineCapactiy: "",
            mileage: "",
            transmission: "",
            price: "",
            contact: "",
            description: "",
            currency: "",
          }}
          // validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            // same shape as initial values
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            isValid,
            dirty,
          }) => (
            <Form
              onSubmit={(e) => {
                // userDatahandler(e);
                handleSubmit(e);
              }}
            >
              <div className="mt-lg-4 d-flex justify-content-center align-items-center ">
                <Col lg={10} md={12} sm={12} className="Form-Shadow  pb-3">
                  <div className="arrowback ">
                    <BackArrow black="black" back="/SellingSide" mystyle="ps-3" />
                  </div>

                  <div className=" mt-2 p-4">
                    <Row className="d-flex justify-content-around ">
                      <Col md={2} lg={2} className="p-0">
                        <Form.Label className="custm-lble">
                          Model Year
                        </Form.Label>
                        <Form.Control
                          placeholder="model year"
                          type="number"
                          name="modelYear"
                          className="labelText input-style p-2"
                          defaultValue={usedvehicle?.modelYear}
                          onChange={(e) => {
                            InputChange(e);
                            handleChange(e);
                          }}
                        />
                      </Col>
                      <Col md={2} lg={2} className="p-0">
                        <Form.Label className="custm-lble">Make</Form.Label>
                        <Form.Control
                          placeholder="make"
                          type="number"
                          name="make"
                          className="labelText input-style p-2"
                          defaultValue={usedvehicle?.make}
                          onChange={(e) => {
                            InputChange(e);
                            handleChange(e);
                          }}
                        />
                      </Col>
                      <Col md={2} lg={2} className="p-0">
                        <Form.Label className="custm-lble ">Brand Name</Form.Label>
                        <Form.Control
                          placeholder="brandName"
                          type="text"
                          name="brandName"
                          className="labelText input-style p-2"
                          defaultValue={usedvehicle?.model}
                          onChange={(e) => {
                            InputChange(e);
                            handleChange(e);
                          }}
                        />
                      </Col>
                      <Col md={2} lg={2} className="p-0">
                        <Form.Label className="custm-lble ">Version</Form.Label>
                        <Form.Control
                          placeholder="version"
                          type="text"
                          name="version"
                          defaultValue={usedvehicle?.version}
                          className="labelText input-style p-2"
                          onChange={(e) => {
                            InputChange(e);
                            handleChange(e);
                          }}
                        />
                      </Col>

                      <Col md={2} lg={2} className="p-0">
                        <Form.Label className="custm-lble">
                          Registered In
                        </Form.Label>
                        <Form.Control
                          placeholder="registered"
                          type="text"
                          name="registeredIn"
                          className="labelText input-style p-2"
                          defaultValue={usedvehicle?.registeredIn}
                          onChange={(e) => {
                            InputChange(e);
                            handleChange(e);
                          }}
                        />
                      </Col>
                    </Row>

                    <Row className="d-flex justify-content-around">
                      <Col md={2} lg={2} className="p-0">
                        <Form.Label className="custm-lble ">City</Form.Label>
                        <Form.Control
                          placeholder="city"
                          type="text"
                          name="city"
                          className="labelText input-style p-2"
                          defaultValue={usedvehicle?.city}
                          onChange={(e) => {
                            InputChange(e);
                            handleChange(e);
                          }}
                        />
                      </Col>
                      <Col md={2} lg={2} className="p-0">
                        <Form.Label className="custm-lble">
                          City Area
                        </Form.Label>
                        <Form.Control
                          placeholder="area"
                          type="text"
                          name="cityArea"
                          defaultValue={usedvehicle?.cityArea}
                          className="labelText input-style p-2"
                          onChange={(e) => {
                            InputChange(e);
                            handleChange(e);
                          }}
                        />
                      </Col>

                      <Col md={2} lg={2} className="p-0">
                        <Form.Label className="custm-lble ">
                          Exterior Color
                        </Form.Label>
                        <Form.Control
                          placeholder="color"
                          type="text"
                          name="color"
                          defaultValue={usedvehicle?.color}
                          className="labelText input-style p-2"
                          onChange={(e) => {
                            InputChange(e);
                            handleChange(e);
                          }}
                        />
                      </Col>

                      <Col md={2} lg={2} className="p-0">
                        <Form.Label className="custm-lble">
                          Assembly
                        </Form.Label>
                        <Form.Control
                          placeholder="assembly"
                          type="text"
                          name="assembly"
                          defaultValue={usedvehicle?.assembly}
                          className="labelText input-style p-2"
                          onChange={(e) => {
                            InputChange(e);
                            handleChange(e);
                          }}
                        />
                      </Col>
                      <Col md={2} lg={2} className="p-0">
                        <Form.Label className="custm-lble">
                          Engine Type
                        </Form.Label>
                        <Form.Control
                          placeholder="type"
                          type="text"
                          name="engineType"
                          defaultValue={usedvehicle?.engineType}
                          className="labelText input-style p-2"
                          onChange={(e) => {
                            InputChange(e);
                            handleChange(e);
                          }}
                        />
                      </Col>
                    </Row>

                    <Row className="d-flex justify-content-around">
                      <Col md={2} lg={2} className="p-0">
                        <Form.Label className="custm-lble">
                          Engine Capacity*(cc)
                        </Form.Label>
                        <Form.Control
                          placeholder="capacity"
                          type="number"
                          name="engineCapactiy"
                          defaultValue={usedvehicle?.engineCapactiy}
                          className="labelText input-style p-2"
                          onChange={(e) => {
                            InputChange(e);
                            handleChange(e);
                          }}
                        />
                      </Col>
                      <Col md={2} lg={2} className="p-0">
                        <Form.Label className="custm-lble">
                          Mileage * (km)
                        </Form.Label>
                        <Form.Control
                          placeholder="mileage"
                          type="number"
                          name="mileage"
                          defaultValue={usedvehicle?.mileage}
                          className="labelText input-style p-2"
                          onChange={(e) => {
                            InputChange(e);
                            handleChange(e);
                          }}
                        />
                      </Col>
                      <Col md={2} lg={2} className="p-0">
                        <Form.Label className="custm-lble">
                          Transmission
                        </Form.Label>
                        <Form.Control
                          placeholder="transmission"
                          type="text"
                          name="transmission"
                          defaultValue={usedvehicle?.transmission}
                          className="labelText input-style p-2"
                          onChange={(e) => {
                            InputChange(e);
                            handleChange(e);
                          }}
                        />
                      </Col>

                      <Col md={2} lg={2} className="p-0">
                        <Form.Label className="custm-lble">
                          Price* (Rs)
                        </Form.Label>
                        <Form.Control
                          placeholder="price"
                          type="number"
                          name="price"
                          defaultValue={usedvehicle?.price}
                          className="labelText input-style p-2"
                          onChange={(e) => {
                            InputChange(e);
                            handleChange(e);
                          }}
                        />
                      </Col>
                      <Col md={2} lg={2} className="p-0">
                        <Form.Label className="custm-lble">
                          Select Currency
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="currency"
                          className="labelText input-style p-2"
                          onChange={(e) => {
                            handleChange(e);
                            InputChange(e);
                          }}
                        >
                          <option selected disabled>
                            Select
                          </option>
                          {currencies.map((e) => (
                            <option>
                              {e.abbreviation} {e.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>

                    <Row className="ps-3">
                      <Col md={2} lg={2} className="p-0">
                        <Form.Label className="custm-lble">
                          Contact Number
                        </Form.Label>
                        <Form.Control
                          placeholder="number"
                          type="number"
                          name="contact"
                          defaultValue={usedvehicle?.contact}
                          className="labelText input-style p-2"
                          onChange={(e) => {
                            InputChange(e);
                            handleChange(e);
                          }}
                        />
                      </Col>
                    </Row>

                    <div className=" pt-3 pb-2 ps-2 pe-2">
                      <Form.Label className="custm-lble pb-1">
                        Description
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={6}
                        name="description"
                        placeholder="Description"
                        className="input-style"
                        defaultValue={usedvehicle?.description}
                        onChange={(e) => {
                          InputChange(e);
                          handleChange(e);
                        }}
                      />
                    </div>

                    {/* <Col className="ps-2"  md={6} lg={6} xl={6}>
               
              <Col className='mt-2' md={5} lg={4} xl={4}   onClick={upload_img}   >
                <Card style={{ width: '6rem', height: "6rem" }} >
                  <Card.Img src={images.AddImage} />
                  <Card.Body>
                  <input
                        type="file"
                        id="file"
                        ref={inputfile}
                        style={{ display: "none" }}
                        name="images"
                        onChange={onChangePicture}
                      />
                  </Card.Body>
                </Card>
              </Col>
              </Col>  */}

                    <Col lg="9" className="me-auto">
                      <Row>
                        <Col lg={2} className="mt-2 ms-2">
                          <Card
                            style={{ width: "6rem", height: "6rem" }}
                            {...getRootProps()}
                            className="dropzone"
                          >
                            <Card.Img
                              className="imet-fit"
                              src={images.AddImage}
                            />
                            <Card.Body>
                              <input
                                type="file"
                                id="file"
                                style={{ display: "none" }}
                                {...getInputProps()}
                                multiple
                              />
                            </Card.Body>
                          </Card>
                        </Col>
                        {
                          displayimg?.length > 0 && (
                            <Col lg={8} className="mt-1 d-flex">

                              {displayimg.map((image) => (
                                <Card
                                  className="me-2"
                                  style={{
                                    width: "6rem",
                                    height: "6rem",
                                    border: "2px solid",
                                  }}
                                >
                                  <Card.Img
                                    className="imet-fit upload-img"
                                    src={getAsset(image)}
                                    alt=""
                                    crossOrigin="anonymous"
                                  />
                                  <div
                                    className="close-btnone"
                                    onClick={() => removeImage(index)}
                                  >
                                    <ClearIcon />
                                  </div>
                                </Card>
                              ))}
                            </Col>
                          )
                        }
                      </Row>
                    </Col>
                    <div className="btns pe-3">
                      <button
                        className="btn1 "
                        onClick={() => navigate("/used-vehicle-profile/" + id)}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn2 disable-btn"
                        onClick={
                          (butn === "Post" && PostItem) ||
                          (butn === "Update" && UpdateVehicleData) ||
                          (butn === "Create" ? PostVehicleData : "")
                        }
                        data-title={
                          location.pathname === `${updateurl}${index}`
                            ? null
                            : !(isValid && dirty)
                              ? "Please fill out all Fields to enable button!"
                              : null
                        }
                        disabled={
                          location.pathname === `${updateurl}${index}`
                            ? ""
                            : !(isValid && dirty)
                        }
                      >
                        {butn}
                      </button>
                    </div>
                  </div>
                </Col>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default Usedvehicleform;
