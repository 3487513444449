
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { getAsset } from "../../../../helpers/helper";
import { responsive } from '../../../../constants/jsonConstant';
const ServicesOnlinePortfolio = ({getService}) => {
    return (
      <>
             <h6 className="fs-5 py-1 mt-5">Portfolio</h6>
      {getService?.portfolioImages?.length > 0 && (
  <Carousel responsive={responsive} autoPlay={true} infinite={true}>
  {getService.portfolioImages.map((portfolio, index) => (
    <div className="portfolioImg" key={index}>
      <img src={getAsset(portfolio)} alt="" crossOrigin="anonymous" />
    </div>
  ))}
</Carousel>
      )}
      </>
      

    
)}
export default ServicesOnlinePortfolio;