import { createSlice } from '@reduxjs/toolkit';

export const itemdataSlice = createSlice({
    name: 'itemdata',
    initialState: {
        name: ""
    },
    reducers: {
        updateItemName: (state, action) => {
            state.itemdata = action.payload
        },      
}
});

// this is for dispatch
export const { updateItemName} = itemdataSlice.actions;

// this is for configureStore
export default itemdataSlice.reducer;