import CreateCompany from "../../../../components/forms/propertyForm/index";
import { useSelector } from "react-redux";
import url from "../../../../config/axios";
import { SuccessNotify, FailedNotify } from "../../../../constants/tostify";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { CompanycreateShop } from "../../..";
import Loader from "../../../../components/Loader";
import { publishImage } from "../../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
const CreateCompanyForm = () => {
  const navigate = useNavigate();
  const [displayimg, setDisplayimg] = useState();
  const { name } = useSelector((state) => state.user);
  const [shopCategoryone, setShopCategoryone] = useState();
  const [isloading, setIsloading] = useState(false);
  const userId = name._id;

  const [data, setData] = useState({
    shopCategory: "",
  });

  const Handledata = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split("."),
    }));
  };

  const categoryName = data?.shopCategory[1];
  const categoryid = data.shopCategory[0];
  localStorage.setItem('mycategory', categoryName);


  useEffect(() => {
    setValues((values) => ({
      ...values,
      shopCategoryid: categoryid,
      shopCategory: categoryName,

    }));
  }, [categoryid, categoryName]);
  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setValues((values) => ({
        ...values,
        image: abc
      }));

      setDisplayimg(abc);

    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });


  const [values, setValues] = useState({
    userid: userId,
    companyname: "",
    companyaddress: "",
    companylicense: "",
    contactnumber: "",
    shopCategory: "",
    shopCategoryid: "",
    description: "",
    marketPlace: "",
    image: [""],
    isBusinessProperty: "false",
    isJobsCompany: "true",
    businessType: "jobsCompany"
  });

  useEffect(() => {
    url

      .get("category/type/?type=company&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategoryone(response?.data?.data?.Category);
        }
      })
      .catch((error) => { });
  }, []);

  const formSubmit = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("/company", values)
      .then(async (response) => {
        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Company-form Created SuccesFully...");
            navigate(`/company-business-Profile/${response?.data?.data?._id}`);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed to Create Company!");
      });
  };
  if (isloading) {
    return (
      <div>
        <Loader heightsetting="screenHeight" />
      </div>
    );
  }

  return (
    <div>
      <CreateCompany
        heading="Fill to Create Company Profile"
        Buttn="Create"
        name="Company Name"
        address="Company Address"
        Handledata={Handledata}
        url="/company-profile"
        back="/SellingSide"
        setValues={setValues}
        dataHandler={formSubmit}
        shopCategoryone={shopCategoryone}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        displayimg={displayimg}
        setDisplayimg={setDisplayimg}
      />
    </div>
  );
};
export default CreateCompanyForm;
