import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Reportmodal from "../Reportmodal/index";
import url from "../../config/axios";
import "../../pages/suggestionmodal/Style.css";
const Report = ({ setSuggest }) => {
  const [openBox, setOpenBox] = useState(1);
  const [reportM, setreportM] = useState();
  const [reporting, setReporting] = useState([]);
  useEffect(() => {
    url.get("/help/").then((res) => {
      setReporting(res?.data?.data?.help);
    });
  }, []);
  const stateHandler = (e) => {
    console.log("e", e);
    setOpenBox(0);
    setreportM(e);
  };
  useEffect(() => {
    if (openBox === 1) {
      setreportM(false);
    }
  });
  const statezero = (e) => {
    setSuggest(false);
  };
 
  return (
    <>
      {reportM && (
        <Reportmodal
          setOpenBox={setOpenBox}
          reportM={reportM}
          setreportM={setreportM}
        />
      )}

      {openBox === 1 && (
        <div>
          <Modal.Header
            className="me-3 suggst-btn"
            closeButton
            onClick={() => setSuggest(0)}
          >
            <Modal.Title className="ms-3">Report</Modal.Title>
          </Modal.Header>
          <hr />
          <>
            <div className=" report-hgt noscroll">
              {reporting?.map((e, index) => (
                <Row
                  className={`${
                    index % 2 === 0 ? "bg-light" : "bg-white"
                  } p-0 m-0 mb-2 pt-2 pb-2`}
                >
                  <Col className="mt-2 m-0 p-0" lg="1">
                    <img className="ms-3 pi-ck" src={e?.image} alt="" />
                  </Col>
                  <Col className="mt-2" lg="9">
                    <h6 className="-size m-0">Alex</h6>
                    <p className="PHERA-0 mb-0">{e?.problem}</p>
                    <p className="PHERA mb-0">{e?.desciription}</p>
                  </Col>
                  <Col className="p-0 m-0" lg="2">
                    <div className=" d-flex">
                      <Col>
                        <button
                          type="button"
                          class="button-01 ms-4 mt-5"
                          onClick={() => {
                            stateHandler(e._id);
                            // statezero();
                          }}
                        >
                          Read
                        </button>
                      </Col>
                    </div>
                  </Col>
                </Row>
              ))}
            </div>
          </>
        </div>
      )}
    </>
  );
};
export default Report;
