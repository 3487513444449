import { Row, Col, Container } from "react-bootstrap";
import OwnerShopProfile from "../../components/OwnerShopProfile/OwnerShopProfile";
import ShopItemsOwner from "../../components/ShopItemsOwner/ShopItems";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import url from "../../config/axios";
import BackArrow from "../../components/backArrow";
import ShopManagebuttons from "../../components/Shops_manage_buttons";
import { useSelector } from "react-redux";
const UsedItemsBussinesProfile = () => {
  const { id } = useParams();
  const [shopData, setShopData] = useState();
  const [suggest, setSuggest] = useState(0);
  const [suggestdetail, setSuggestdetail] = useState(0);
  const { name } = useSelector((state) => state.user);

  useEffect(() => {
    url
      .get(`shop/user/?userid=${name._id}&businessType=usedShop`)
      .then(async (res) => {
        if (res.data.success) {

          localStorage.setItem("mycategory", (res?.data?.data?.Shops[0]?.shopCategory?.name));
        }
      });
  }, []);


  useEffect(() => {
    url.get(`shop/id?id=${id}`).then(async (res) => {
      setShopData(res?.data?.data?.Shop);
    });
  }, []);

  return (
    <Container>
      <Col md={11} lg={11} className="mx-auto ">
        <Row>
          <Col md={4} lg={3} className="p-0">
            {" "}
            <OwnerShopProfile
              shopid={id}
              Add="Create Items"
              navigateTo="/used-items-update"
              Name="Update Used Items Profile"
              update="/used-items-update"
              // post="/useditems-postAdd"
              shopData={shopData}
              setSuggest={setSuggest}
              salespromotion="sales promotion"
              icon={<LocalOfferIcon className="silver-icon" />}
            />
          </Col>
          <Col md={8} lg={9} className=" screenHeight custom_ps-4">
            <div className="Form-Shadow h-100">
              <Row>
                <Col lg="4">
                  <BackArrow back={`/rawmaterial-useditem/` + id} />
                </Col>
                <Col lg="8" className="mt-2 pe-4">
                  <ShopManagebuttons businessurl="/used-items-shops" />
                </Col>
              </Row>
              <ShopItemsOwner
                urlPass="/used-item-owner-view"
                navigateto="/used-items-form"
                shopid={id}
                post={`/useditems-postAdd/${id}`}
                suggest={suggest} setSuggest={setSuggest} suggestdetail={suggestdetail} setSuggestdetail={setSuggestdetail}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default UsedItemsBussinesProfile;
