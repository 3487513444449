import React from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PropertyItems } from "../../constants/jsonConstant";
import { images } from "../../constants";
import OwnerPropertyViewModel from "../../components/ShopViewModel/OwnerPropertyViewModel";
import Deletemodal from "../../components/DeleteModal";

import BackArrow from "../../components/backArrow";
const UsedSingleAddPost = () => {
  const [show, setShow] = useState(0);
  const [showmodal, setShowmodal] = useState(0);
  const [shows, setShows] = useState(false);
  const handleClose = () => setShows(0);
  const handleClosetwo = () => setShowmodal(0);
  const handleShow = () => setShowmodal(1);
  const navigate = useNavigate();
  return (
    <>
      <Container>
        {showmodal === 1 && (
          <OwnerPropertyViewModel
            handleShow={handleShow}
            handleClosetwo={handleClosetwo}
          />
        )}
        {shows === 1 && (
          <Deletemodal
            shows={shows}
            title="Profile"
            handleClose={handleClose}
            animation={true}
          />
        )}
        <Row>
          <Col lg={11} className="mx-auto">
            <div className=" screenHeight">
              <div className="Form-Shadow  ms-3 ps-0 pe-0 ms-0 me-0 pt-2 pb-2 ">
                <Row className="ms-3 me-3  align-Items-center pt-2 pb-2">
                  <Col lg={6}>
                    <div
                      className="cursor"
                      onClick={() => navigate("/dashboard")}
                    >
                      <BackArrow />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="d-flex justify-content-end ">
                      <button
                        className="border ps-3 pe-3 pt-1 pb-1  bg-blue text-white"
                        onClick={() => navigate("/post-items")}
                      >
                        Post add
                      </button>
                    </div>
                  </Col>
                </Row>
                {/* <UserShopViewModel /> */}

                <div className="ms-3 me-3  noscroll Property-scroll  ">
                  <div className="row ps-2 pe-2">
                    {PropertyItems?.map((Items) => (
                      <div className="col-lg-2 p-2 ">
                        <div key={Items?.id} className="ShopsCards  mt-3 mb-3">
                          <img
                            className="card-img"
                            src={Items?.Propertimg}
                            alt=""
                          />
                          <div className="border">
                            <div className="pt-1  ">
                              <p className="mb-0 ps-2 pe-2 items-card-font fw-bold">
                                {Items?.Name}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center pt-1 pb-1 ps-2 pe-2 ">
                              <p className="mb-0 pt-1  items-card-font font-blue">
                                {Items?.Pkr}
                              </p>
                              <img src={Items?.Rating} />
                            </div>
                            <div className="d-flex justify-content-around mb-2">
                              <p
                                className="mb-0  view-btn"
                                onClick={() => setShowmodal(1)}
                              >
                                {Items?.ViewButton}
                              </p>
                              <p
                                className="mb-0  delete-btn "
                                onClick={() => setShow(1)}
                              >
                                {Items?.DeleteButton}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
         </Col>
        </Row>
      </Container>
    </>
  );
};

export default UsedSingleAddPost;
