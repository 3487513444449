import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Form from "../../../../components/forms/index"
import url from "../../../../config/axios"
import { FailedNotify, SuccessNotify } from "../../../../constants/tostify";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
import { publishImage } from "../../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
const HomeMadeItemsUpdate = () => {
  const navigate = useNavigate();
  const { name } = useSelector((state) => state.user);
  const [displayimg, setDisplayimg] = useState();
  const [shopCategoryone, setShopCategoryone] = useState();
  const [isloading, setIsloading] = useState(false);
  const { id } = useParams();


  const [selectshop, setSelectshop] = useState({
    shopCategory: ""
  });

  const Shopdatas = (e) => {
    setSelectshop((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split("."),
    }));
  };

  const categoryName = selectshop?.shopCategory[1];
  const categoryid = selectshop?.shopCategory[0];

  localStorage.setItem('mycategory', categoryName);
  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setCreateShop((createShop) => ({
        ...createShop,
        image: abc
      }));

      setDisplayimg(abc);

    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const [createShop, setCreateShop] = useState({
    userid: name?._id,
    shopname: "",
    shopaddress: "",
    shoplicense: "",
    contact: "",
    image: [""],
    shopCategory: categoryid,
    marketPlace: "",
    businessType: "homemadeShop",
    description: "",
    status: true,

  });
  console.log("createShop12322", createShop?.shopCategory)
  useEffect(() => {
    setIsloading(true);
    url
      .get(`shop/id?id=${id}`)
      .then(async (response) => {
        if (response.data.success) {
          setIsloading(false);
          setCreateShop(response?.data?.data?.Shop);
        }

      })
      .catch((error) => {
        setIsloading(false);
        console.log("error", error);
      });
  }, []);


  useEffect(() => {
    url
      .get("category/type/?type=homemadeShop&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategoryone(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
      });
  }, []);
  const UpdateShop = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .put(`shop/id?id=${id}`, createShop)
      .then(async (response) => {
        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Form Update Successfully");
            navigate("/homemadeownerprofile/" + id);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed to Update");
      });
  };
  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }
  return (
    <Form
      title="Fill to update Home Made Items Shop"
      butn="Update"
      back="/homemadeownerprofile/"
      navigateTo="/homemadeownerprofile"
      UpdateShop={UpdateShop}
      createShop={createShop}
      link="/homemade-update/"
      setCreateShop={setCreateShop}
      shopCategoryone={shopCategoryone}
      id={id}
      getInputProps={getInputProps}
      getRootProps={getRootProps}
      displayimg={displayimg}
      setDisplayimg={setDisplayimg}
      Shopdatas={Shopdatas}
    />
  );
};
export default HomeMadeItemsUpdate;
