import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const ShopManagebuttons = ({ businessurl }) => {
  const navigate = useNavigate();

  const goliveTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Coming Soon
    </Tooltip>
  );

  return (
    <>
      <div className="d-flex justify-content-end">
        {/* <div>
          <button className="btn-primary pt-1 pb-1" onClick={() => navigate("/OrderHistory")}>
            Order History
          </button>
        </div> */}
        <div>
          <button
            className="btn-primary pt-1 pb-1"
            onClick={() => navigate(businessurl)}
          >
            Business Dashboard
          </button>
        </div>
        <div>
          &nbsp;&nbsp;
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={goliveTooltip}
          >
            <button
              className="btn-primary pt-1 pb-1"
              // onClick={() => navigate("/ShopManage")}
            >
              Give Admin Rights For This Shop
            </button>
          </OverlayTrigger>
        </div>
      </div>
    </>
  );
};

export default ShopManagebuttons;
