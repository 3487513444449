import JobCandidateFilter from "../../../../components/filteration/JobCandiditeFilter";
import { Container, Row, Col } from "react-bootstrap";
import ShopAdds from "../../../../components/shopAds/ShopAdds";
import Shops from "../../../../components/Multipleshopprofile/Components/Shops/Shops";
import { useEffect, useState } from "react";
import url from "../../../../config/axios";
import ShareModel from "../../../../components/Sharemodel";
import ShareOption from "../../../../components/Shareoption";
import BackArrow from "../../../../components/backArrow";

const FindJobFilter = () => {
  const [data, setData] = useState(0);
  const [jobWantedfilter, setJobwantedfilter] = useState([]);
  const [isloading, setIsloading] = useState();
  const [shopCategoryone, setShopCategoryone] = useState();
  const [shopdata, setShopdata] = useState();
  const [itemmdata, setItemmdata] = useState();
  const [dataCategory, setDataCategory] = useState({
    categoryid: "",
    title: "",
  });

  const FilterJobWantedData = () => {
    url
      .get(
        `https://dark-tan-betta-tam.cyclic.app/api/employee/filter/?category=6392e00cbc259312c75a8f61`
      )
      .then(async (res) => {
        setJobwantedfilter(res?.data?.data?.Employee);
      });
  };
  useEffect(() => {
    FilterJobWantedData();
  }, []);

  useEffect(() => {
    url

      .get("category/type/?type=company&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategoryone(response?.data?.data?.Category);
        }
      })
      .catch((error) => { });
  }, []);
  console.log("check123", shopCategoryone)
  const JobHandler = (e) => {
    setJobwantedfilter((prev) => ({
      ...prev,
      [e.target.name]: e.targte.value,
    }));
  };
  const categoryName = dataCategory?.categoryid[1];
  const categoryidone = dataCategory.categoryid[0];
  const titleName = dataCategory?.title[1];
  const titleid = dataCategory.title[0];


  const Handledata = (e) => {
    setDataCategory((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split(".")

    }))
  }
  console.log("shoppppppp catagoryyyyy", shopCategoryone)

  const [showmodal, setShowmodal] = useState(false);
  const handleClosemodal = () => setShowmodal(false);
  const handleShow = () => setShowmodal(true);

  const [showshare, setShowshare] = useState(false);
  const handleCloseshare = () => {
    setShowshare(false);
    setShowmodal(true);
  };
  const [companydata, setCompanydata] = useState({
    companyid: "",
    companyname: "",
    shopCategoryid: "",
    marketplace: "",

  });
  const [jobsdata, setJobsdata] = useState({
    title: "",
    categoryid: "",
    subCategoryid: "",
    siteremote: "",
    jobtype: "",
    country: "",
    state: "",
    city: "",
  });
  useEffect(() => {
    setJobsdata((jobsdata) => ({
      ...jobsdata,
      categoryid: categoryidone !== undefined ? categoryidone : "",
      title: titleid,

    }));

  }, [categoryidone, titleid]);


  console.log("company data ", companydata);
  console.log("jobsssssss dattttttta", jobsdata);

  const Handlecompany = (e) => {
    setCompanydata((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };
  const Handlejob = (e) => {
    setJobsdata((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };


  const FilterCompanydetails = () => {
    setIsloading(true);
    url
      .get(`/job/filter/?companyname=${companydata?.companyname}&companyid=${companydata?.companyid}&shopCategoryid=${companydata?.shopCategoryid}&marketplace=${companydata?.marketplace}`)
      .then(async (response) => {
        console.log("*********", response)
        if (response?.data?.success) {
          setIsloading(false);
          setShopdata(response?.data?.AllCompanies)
          setItemmdata(response?.data?.Jobs)
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
        setIsloading(false);
      });
  };
  console.log("itemmmssssss,,,,,", itemmdata)
  const FilterJobDetails = () => {
    setIsloading(true);
    url
      .get(`/job/filter/?title=${jobsdata?.title}&categoryid=${jobsdata?.categoryid}&subCategoryid=${jobsdata?.subCategoryid}&siteremote=${jobsdata?.siteremote}&jobtype=${jobsdata?.jobtype}&country=${jobsdata?.country}&state=${jobsdata?.state}&city=${jobsdata?.city}`)
      .then(async (response) => {
        if (response?.data?.success) {
          console.log("filterjobdetails 1", response)
          setIsloading(false);
          setItemmdata(response?.data?.Jobs)
          setShopdata(response?.data?.Jobs)
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
        setIsloading(false);
      });
  };




  const handleShowshare = () => setShowshare(true);

  return (
    <>
      <ShareOption showshare={showshare} handleCloseshare={handleCloseshare} />
      <ShareModel
        showmodal={showmodal}
        handleClosemodal={handleClosemodal}
        handleShowshare={handleShowshare}
      />
      <Container>
        <Row>
          <Col lg="4" className="screenHeight">
            <div className="border b-radius height_100">
              <JobCandidateFilter
                JobHandler={JobHandler}
                FilterJobWantedData={FilterJobWantedData}
                title="Search  Category, Area & Desired Location"
                category="Search By Title"
                search="Search by Job Type"
                Handlejob={Handlejob}
                Handlecompany={Handlecompany}
                setJobsdata={setJobsdata}
                FilterCompanydetails={FilterCompanydetails}
                shopCategoryone={shopCategoryone}
                FilterJobDetails={FilterJobDetails}
                Handledata={Handledata}
                categoryName={categoryName}
                titleName={titleName}

              />
            </div>
          </Col>
          <Col lg="8" className="screenHeight">
            <div className="border b-radius ps-2 pe-2 ">
              <BackArrow back="/BuyingSide" mystyle="ps-2 pt-1" />
              <ShopAdds />
              <Shops
                link="/find-jobs-online"
                title="Jobs"
                data={data}
                handleShow={handleShow}
                shopdata={shopdata}
                itemmdata={itemmdata}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default FindJobFilter;
