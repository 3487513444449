import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";

const DashboardinfoModal = ({ setLgShow, lgShow, heading, info }) => {
  return (
    <>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{info}</Modal.Body>
      </Modal>
    </>
  );
};

export default DashboardinfoModal;
