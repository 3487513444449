import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Businessprofiletitle from "../../components/BusinessProfile/Component/Title/businessprofiletitle";
import Rawmaterialcard from "../../components/BusinessProfile/Component/Rawmaterialcard/Rawmaterialcard";
import Timeline from "../../components/Timeline/timeline";
import ShopAdds from "../../components/shopAds/ShopAdds";
import BackArrow from "../../components/backArrow";
const Servicebusinessprofile = () => {
  const { id } = useParams();

  return (
    <>
      <Container>
        <Row>
          <Col lg="7" className="screenHeight p-0">
            <div className="border b-radius">
              <BackArrow back="/retailer-form" />
              <ShopAdds />
              <Businessprofiletitle
                linkurl="/services-profile"
                id={id}
                name="Services"
              />
              <Rawmaterialcard />
            </div>
          </Col>

          <Col
            lg="5"
            sm="12"
            className="ps-4 screenHeight"
            style={{ position: "relative" }}
          >
            <div className="border b-radius">
              <Timeline />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Servicebusinessprofile;
