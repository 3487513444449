import "./Forgotpassword.css";
import { NavLink } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import url from "../../../../config/axios";
import { useNavigate } from "react-router-dom";
import { SuccessNotify, FailedNotify } from "../../../../constants/tostify";
import { useSelector } from "react-redux";

const Verification = () => {
  const navigate = useNavigate();
  const email = localStorage.getItem("useremail");
  const resultemail = JSON.parse(email);
  console.log("myemail", resultemail);
  const [getotp, setGetotp] = useState({
    one1: "",
  });
  const handleOtp = () => {
    url
      .put(`user/verifyEmail/${getotp.one1}/${resultemail}`)
      .then(async (res) => {
        if (res.data.success) {
          SuccessNotify("OTP match Successfully");
          navigate("/reset");
        }
      })
      .catch((err) => {
        FailedNotify(err.response.data.message);
      });
  };
  return (
    <>
      <Container fluid className="f-bg-color">
        <Row>
          <Col lg="6" className="mx-auto  background-white f-modal-style">
            <div className="ps-5 pe-5">
              <h5 className="text-center">Forgot password</h5>
              <p className="text-center mt-2 font-ss">
                Enter the code from the email or <br></br> phone number we just
                send you
              </p>

              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="labelSize">
                    Verification Code*
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Code"
                    className="p-3 input-style"
                    name="one1"
                    onChange={(e) =>
                      setGetotp({ ...getotp, [e.target.name]: e.target.value })
                    }
                  />
                </Form.Group>{" "}
                <Button
                  type="button"
                  className=" bg-blue  w-100 mt-2 next-btn-s b-radius p-2"
                  onClick={handleOtp}
                >
                  NEXT 
                </Button>
                <div className="font-blue text-center  mt-lg-3 mt-xl-3 ">
                  <NavLink to="/forgot">
                    Back to Previous
                    <span style={{ color: "black" }}> page</span>
                  </NavLink>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Verification;
