import { Row, Col, Container } from "react-bootstrap";
import OwnerShopProfile from "../../components/OwnerShopProfile/OwnerShopProfile";
import ShopItemsOwner from "../../components/ShopItemsOwner/ShopItems";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import BackArrow from "../../components/backArrow";
import Managebuttons from "../../components/Manage_Buttons";
import url from "../../config/axios";
import { useSelector } from "react-redux";
const WholeSalerOwnerProfile = () => {
  const [suggest, setSuggest] = useState(0);
  const [suggestdetail, setSuggestdetail] = useState(0);
  const { name } = useSelector((state) => state.user);
  const { id } = useParams();
  const [shopData, setShopData] = useState();
  const [suggestdata, setSuggestdata] = useState();
  useEffect(() => {
    url
      .get(`shop/user/?userid=${name._id}&businessType=wholesellerShop`)
      .then(async (res) => {
        if (res.data.success) {

          localStorage.setItem("mycategory", (res?.data?.data?.Shops[0]?.shopCategory?.name));
        }
      });
  }, []);

  useEffect(() => {
    url.get(`shop/id?id=${id}`).then(async (res) => {
      setShopData(res?.data?.data?.Shop);
    });
  }, []);
  // call this  api on click on suggest.....................................................
  const suggestdatta = () => {
    url
      .get(`suggestion/getShopSuggestion/vendorid?vendorid=${shopData?.shopid}`)
      .then(async (res) => {

        setSuggestdata(res?.data?.data[0]?.suggestions);
      });
  };

  return (
    <Container>
      <Col md={12} lg={11} className="mx-auto">
        <Row>
          <Col md={12} lg={3} className="p-0">
            <OwnerShopProfile
              Add="Create Items"
              navigateTo="/update-wholesaler-form"
              Name="Update Wholesaler Profile"
              update="/update-wholesaler-form"
              post="/wholesaler-postadd"
              setSuggest={setSuggest}
              shopData={shopData}
              suggestdatta={suggestdatta}
            // promote= "promote shops"
            />
          </Col>
          <Col md={12} lg={9} className="screenHeight custom_ps-4">
            <div className="Form-Shadow h-100">
              <Row>
                <Col sm={3} md={4} lg={5}>
                  <BackArrow
                    mystyle="ms-0 ps-2 pt-1"
                    back={`/wholsaler-bussines-Profile/${id}`}
                  />
                </Col>
                <Col sm={9} md={8} lg={7} className="mt-2 pe-4">
                  <Managebuttons id={id} businessurl="/WholesalerShop" />
                </Col>
              </Row>
              <ShopItemsOwner
                suggest={suggest}
                setSuggest={setSuggest}
                suggestdetail={suggestdetail}
                setSuggestdetail={setSuggestdetail}
                urlPass="/wholesaler-owner-view"
                navigateto="/wholeseller-form"
                shopid={id}
                post={`/wholesaler-postadd/${id}`}
                suggestdata={suggestdata}
                shopData={shopData}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default WholeSalerOwnerProfile;
