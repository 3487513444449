import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import BackArrow from "../../../components/backArrow";
import ShopAdds from "../../../components/shopAds/ShopAdds";
import url from "../../../config/axios";
import Viewbusinessfriend from "../../../pages/UserProfile/Components/Profile_connectivity/Viewbusiness/index";
import {
  Businessprofiletitle,
  Rawmaterialcard,
  Timeline,
} from "../../../pages/index";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
const RawmaterialHomemade = () => {
  const { id } = useParams();
  const [profileshowhide, setProfileshowhide] = useState(false);
  const { name } = useSelector((state) => state.user);
  const [tabsData, setTabsData] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [loading, setLoading] = useState(true);  
  const [createPost, setCreatePost] = useState({
    shopid: id,
    userid: name?._id,
    businessType: "homemadeShop",
    my: [
      {
        heading: "",
        content: "",
        img: [""],
        video: [""],
      },
    ],
  });
  console.log("createPost", createPost);
  useEffect(() => {
    url
      .get(
        `conection/publicOrShopAgainstPublic/?businessType=homemadeShop&shopid=${id}&userid=${name?._id}`
      )
      .then(async (res) => {
        const responseData = res.data.data;
        const dynamicTabs = Object.keys(responseData).map((key) => ({
          eventKey: key,
          title:
            key.charAt(0).toUpperCase() +
            key.slice(1).replace(/([A-Z])/g, " $1"),
          data: responseData[key],
          
        }));
        setTabsData(dynamicTabs);
        if (dynamicTabs.length > 0) {
          setActiveTab(dynamicTabs[0].eventKey);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("API call error:", err);
        setLoading(false);
      });
  }, [id]);

  const handleTabSelect = (eventKey) => {
    setActiveTab(eventKey);
  };

  const getActiveTabData = () => {
    const activeTabData = tabsData.find((tab) => tab.eventKey === activeTab);
    return activeTabData ? activeTabData.data : [];
  };

  return (
      <>     
    <Container>
        <Row>
          <Col lg="7" className="screenHeight p-0">
            {profileshowhide === true ? (
              <Viewbusinessfriend
                id={id}
                profileurl="/Home-Made-Item-Userprofile"
                setProfileshowhide={setProfileshowhide}
              />
            ) : (
              <div className="border b-radius">
                <div className="ms-3">
                  <BackArrow back="/Home-Made-Form" />
                </div>

                <ShopAdds />
                <Businessprofiletitle
                  linkurl="/home-made-owner-profile"
                  id={id}
                />
                <Rawmaterialcard
                  id={id}
                  profileurl="/Home-Made-Item-Userprofile"
                  setProfileshowhide={setProfileshowhide}
                />
              </div>
            )}
          </Col>

          <Col
            lg="5"
            sm="12"
            className="ps-4 screenHeight"
            style={{ position: "relative" }}
          >
            <div className="border b-radius">
              <Timeline
                tabsData={tabsData}
                activeTab={activeTab}
                onTabSelect={handleTabSelect}
                tabData={getActiveTabData()}
                loading={loading}
                createPost={createPost}
                setCreatePost={setCreatePost}
                setLoading={setLoading}
                id={id}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RawmaterialHomemade;
