import React from "react";
import BoxData from "../Box";
import CardNav from "../CardNav";
import { Col, Container, Row, Card } from "react-bootstrap";
import { images } from "../../../../constants";
import { useState } from "react";
const ChatRoom = ({ Butonn, Title, Header }) => {
  const [id, setIs] = useState(1);
  const [myinfo, setMyinfo] = useState("chatroom info")
  return (
    <div>
      <Row className="">
        <Col lg="12">
          <CardNav Header="Chat Room" myid={id} myinfo={myinfo} />
          <Card className="shadow-main mt-2">
            <BoxData
              Title="Chat Room"
              Butonn="Start Chating"
              imgg={images.ChatRoom}
              ms="twentyfive-element"
              buy="twentythree"
              link="/chat-room-modal"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ChatRoom;
