import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import url from "../../../../config/axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { images } from "../../../../constants";

const SellVehicle = () => {
  const navigate=useNavigate();
  return (
    <>
      <Row>
        <Col className="screenHeight">
          <div className="h-100 d-flex justify-content-center align-items-center">
            <Row className="">
              <Col lg={6} md={6} sm={6} xs="12 d-flex justify-content-center">
                <div className="me-0 me-sm-3 me-md-3 me-lg-3 mb-3 mb-sm-0 mb-md-0 mb-lg-0">
                  <Card style={{ width: "17rem" }}>
                    <div className="fix_img">
                      <img
                      
                        src={images.Usedvehicleshop}
                        className="mt-2 "
                      />
                    </div>

                    <Card.Body className="">
                      <Card.Title className="text-center mt-5 pt-4">
                        Create Shop
                      </Card.Title>

                      <div className="d-flex justify-content-center">
                        {/* <button className="btn-dash-sm  bg-blue-lg text-white d-flex justify-content-center align-items-center mt-1">
                          View
                        </button> */}
                        <button className="btn-dash-sm  bg-blue-lg text-white d-flex justify-content-center align-items-center mt-1" onClick={()=>navigate("/used-vehicle")} >
                          Create
                        </button>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
              <Col lg={6} md={6} sm={6} xs="12 d-flex justify-content-center">
        
                <div>
                  <Card style={{ width: "17rem" }}>
                    <div className="fix_img">
                      <img src={images.Usedvehiclesingle} className="mt-2" />
                    </div>

                    <Card.Body className="">
                      <Card.Title className="text-center mt-5 pt-4">
                      Post Single Add
                      </Card.Title>
                      <div className="d-flex justify-content-center">
                        {/* <button
                          className="btn-dash-sm  bg-blue-lg text-white d-flex justify-content-center align-items-center mt-1"
                        >
                          View
                        </button> */}
                        <button className="btn-dash-sm  bg-blue-lg text-white d-flex justify-content-center align-items-center mt-1" onClick={()=>navigate("/sell-single")}>
                          Create
                        </button>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SellVehicle;
