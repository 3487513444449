import { Container, Col, Row } from "react-bootstrap";
import "./servicebio.css";
import BackArrow from "../../../../components/backArrow";
import { images } from "../../../../constants/index";
import Rating from "@mui/material/Rating";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import CandidateBox from "../../../../components/candidatesBox/index";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PinDropIcon from "@mui/icons-material/PinDrop";
import SyncIcon from "@mui/icons-material/Sync";
import DeleteIcon from "@mui/icons-material/Delete";
import ServicesVideo from "../servicesVideo/index";
import ServicesImages from "../servicesImage/index";
import ServicesPortfolio from "../servicesPortfolio/index";
import { useNavigate } from "react-router-dom";
import url from "../../../../config/axios";
import { useSelector } from "react-redux";
import AvailabilityModel from "../Avalibilitymodel";
import CandidatesBox from "../../../../components/candidatesBox/index";
import JobsFollowers from "../../../createJobsProfile/components/jobsFollowers";
import ReportIcon from "@mui/icons-material/Report";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PlaceIcon from "@mui/icons-material/Place";
import MessageIcon from "@mui/icons-material/Message";
import CampaignIcon from "@mui/icons-material/Campaign";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import Qrmodal from "../../../../components/QRmodal/Qrmodal";  
import { getAsset } from "../../../../helpers/helper";
const ServicesBio = ({ id, setShow }) => {
  const { name } = useSelector((state) => state.user);

  const [smshow,setSmShow] =useState(false);
  const handleShowtwo = () => setSmShow(1);
  const onHide=() => setSmShow(false);
  const [getService, setGetService] = useState();
  const navigate = useNavigate();
  const [value] = useState(3);
  const [key, setKey] = useState("home");
  useEffect(() => {
    url.get(`service/id/?id=${id}`).then(async (response) => {
      setGetService(response?.data?.data?.Service);
    });
  }, []);
  const [showavaliable, setShowavaliable] = useState(false);
  const handleClose = () => setShowavaliable(false);
  const handleShow = () => setShowavaliable(true);
  const handleSelect = (k) => {
    if (k === "tab1") {
      handleShow();
      setKey("home");
    } else {
      if (k === "tab2") {
        handleShow();
        setKey("Connects");
      }
    }
  };
  const [showoneModal, setShowoneModal] = useState(false);
  const handleTabSelect = () => {
    setShowoneModal(true);
  };
  const handleCloses = () => {
    setShowoneModal(false);
  };
  return (
    <>
     <Qrmodal show={smshow} handleClosemodal={onHide} />
      <AvailabilityModel
        handleClose={handleClose}
        showavaliable={showavaliable}
        setShowavaliable={setShowavaliable}
      />
      <Container>
        <div className="border b-radius mx-auto servicesHeight">
          <div className="arrowback ps-3">
            <BackArrow back="/create-service" black="black" />
          </div>
          {/* <CandidatesBox showoneModal={showoneModal} handleClose={handleCloses} /> */}
          <Row className="ps-4 pe-4">
            <Col lg="4" xl="4">
              <div className="img_fix mt-2">
                <img
                  src={getAsset(getService?.images)}
                  alt=""
                  className="transport_img"
                  crossOrigin="anonymous"
                />
              </div>
            </Col>

            <Col lg="8" xl="8">
              <div className="d-flex justify-content-between">
                <div>
                  <h2 className="font-bold mt-4">{getService?.profileName}</h2>
                </div>
                <div className="mt-4 cursor_P" onClick={handleShowtwo}>
                  <QrCode2Icon fontSize="large"  />
                </div>
              </div>

              <h6 className="d-flex font_11">
                <b>Service ID :</b>
                <span style={{ color: "grey" }}>{getService?.serviceid}</span>
              </h6>
              <p className="mb-1 font_13">
                {getService?.description}
              </p>
              <p className="mb-1 font_12">
                <PlaceIcon style={{ fontSize: "16px" }} /> {getService?.country}, {getService?.state}, {getService?.city}
              </p>
              <p className="mb-1 font-sm-15 spaces">
                {getService?.subcategory}
              </p>

              <div className="d-flex">
                <p className="mb-1 font_13">
                  <Typography component="legend" className="font_small mt-1">
                    <b>3.6</b>
                  </Typography>
                </p>
                <Rating name="read-only" value={value} readOnly />
              </div>
              <div className="d-flex justify-content-between">
                <div className="d-flex" onClick={()=>navigate(`/service-suggestion/${getService?.serviceid}`)}>
                  <MessageIcon
                    className="report-icn"
                    style={{ marginTop: "4%" }}
                  />
                  &nbsp;&nbsp;
                  <p className="font_12 mb-0 cursor_P">Suggestion</p>
                </div>

                <div className="d-flex">
                  <div
                    className="btn-application me-3"
                    onClick={() => navigate("/update-service/" + id)}
                  > 
                    <SyncIcon fontSize="small" />
                    <button className="btn-application me-2">
                      Update
                    </button>
                  </div>

                  <button
                    className="btns-applicationss"
                    onClick={() => navigate("/services-shops")}
                  >
                    Business Dashboard
                  </button>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div className="d-flex" onClick={()=>navigate("/service-report")}>
                  <ReportIcon className="report-icn" />
                  &nbsp;&nbsp;
                  <p className="font_12 mb-0  cursor_P">Report</p>
                </div>
                <div className="d-flex">
                  <div className="d-flex btn-application-red me-3 mt-2 pe-2 ps-1">
                    <DeleteIcon fontSize="small" className="mt-1 ms-1" />
                    <button
                      className="btn-application-red"
                      onClick={() => setShow(1)}
                    >
                      Delete
                    </button>
                  </div>
                  
                    <button
                      className="btn-applications mt-2 pe-lg-3 ps-lg-3 pe-md-3 ps-md-3 ps-sm-3 pe-sm-3"
                      onClick={() => setShowavaliable(true)}
                    >
                      Set Availability
                    </button>
                 
                </div>
              </div>

              <div className="d-flex custom-negative-m">
                <CampaignIcon
                  className="report-icn"
                />
                &nbsp;&nbsp;{" "}
                <p className="font_12 mb-0  cursor_P " onClick={() => navigate("/promote-service")}>
                  Promote Service
                </p>
              </div>
              <Row>
                <div className="d-flex justify-content-start">
                  {/* <div className="d-flex btn-application me-2 ">
                    <PinDropIcon className="mt-2" />
                    <button className="btn-application">
                      Share Live Location
                    </button>
                  </div> */}

                  {/* <div
                    className="d-flex btn-application me-2"
                    onClick={() => navigate("/update-service/" + id)}
                  >
                    <SyncIcon className="mt-2" />
                    <button className="btn-application ">Update</button>
                  </div> */}

                
                </div>
              </Row>
          
            </Col>
            
            <Col lg="12 mt-4">
            <div className="position-relative">
              <div className="live-btn-absolute">
                <button className="location-live ps-1 pe-1"><LocationOnIcon style={{color:"#0353A"}} fontSize="small" />Share Live Location</button>
              </div>
            </div>
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => {
                  setKey(k);
                  handleSelect(k);
                }}
                className="mb-3 ms-1 font_small"
              >
                <Tab eventKey="home" title="Service">
                  <Row className="services-scroll noscroll">
                    <ServicesVideo getService={getService} />
                    {/* <ServicesImages /> */}
                    <ServicesPortfolio getService={getService} />
                  </Row>
                </Tab>
                
              </Tabs>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
export default ServicesBio;
