import React from "react";
import { ItemsImg } from "../../../../../../constants/jsonConstant";
import { images } from "../../../../../../constants";
import { Row, Col } from "react-bootstrap";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";

import "./style.css";
const HeaderOne = () => {
  return (
    <>
      <Row>
        <div className="d-flex justify-content-between card-dld pt-4">
          {ItemsImg.map((image) => (
            <Col lg={2} md={2} >
              <div class="card-dld">
                <img
                  class="card-img-top"
                  src={image.imgone}
                  alt="Card image cap"
                />
                <div class="card-body p-0">
                  <div className="position-relative">
                    <div className="position-absolute d-flex justify-content-between btr-om gap-2">
                      {/* <img className="back-clr"  alt={image.title} /> */}
                      {image.id === 4 || image.id === 5 ? (
                        <>
                          <MicOffIcon className="back-off rounded-circle" />
                        </>
                      ) : (
                        <>
                          <MicIcon className="back-clred rounded-circle" />
                        </>
                      )}

                      <h3 className="intor-on d-flex justify-content-center pt-1">
                        {image.description}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            
            </Col>
          ))}
        </div>
      </Row>
    </>
  );
};

export default HeaderOne;
