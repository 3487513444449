import React from "react";
import BoxData from "../Box";
import CardNav from "../CardNav";
import { Col, Row, Card } from "react-bootstrap";
import { images } from "../../../../constants";
import { useState } from "react";
const Email = () => {
  const [id, setIs] = useState(1);
  const [myinfo, setMyinfo] = useState("Email info")
  return (
    <div>
      <Row>
        <Col lg="12 mt-sm-1 mt-md-2 mt-lg-0">

          <CardNav Header="Email" myid={id} myinfo={myinfo} />

          <Card className="shadow-main mt-2">
            <BoxData
              Title="Email"
              Butonn="Send Emails"
              url="/inbox"
              imgg={images.Email}
              ms="twentyfour-element"
              buy="twentytwo"
              link="/inbox"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Email;
