import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { images } from "../../../../constants";
import "./Style.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { SellCards } from "../../../../constants/jsonConstant";
export default function Cards({ btn, text, ms, profileurl, imgsrc, myclass, myclasstwo, btntwo }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [isBlue, setIsBlue] = useState(1);
  console.log("button", isBlue);
  const { name } = useSelector((state) => state.user);
  function handleDragStart(event) {
    const img = new Image();
    img.src = "image.jpg";
    event.dataTransfer.setDragImage(img, 0, 0);
  }

  return (
    <div>
      <Card className="" style={{ borderRadius: "26px" }}>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <div className="d-flex justify-content-center">
              <Card.Img
                className={`avataar  dashboardbussiness mb-0 ${myclass} ${myclasstwo} ${ms === "four" ? "mt-5 mb-5" : null}`}
                variant="left"
                src={imgsrc}
                width="100"
                height="100"
              />
            </div>

            <Card.Body className="mt-0 pt-1 ps-1 pb-1 pe-0 ">
              {
                myclasstwo === "mt-4 mb-3" ? (<Card.Title className="Rosie text-center mt-2 mb-2">{text}</Card.Title>) : null
              }

              <div className="karachi-main  mb-2">
                <Card.Text className="verify text-center mt-2">
                  <img src={images.Verified} width="18" height="18" /> Verified
                </Card.Text>
              </div>
              <div className="d-xl-flex d-lg-flex d-md-flex justify-content-center dashbtnnn"> <button
                className={` dash-btn ${ms} text-center mb-1 mt-1 `}

                onClick={() => navigate(profileurl)}
              >
                {(location.pathname === "/BuyingSide") && (ms === "four") ? btntwo : btn}
              </button></div>


            </Card.Body>
          </Col>


        </Row>
      </Card>
    </div>
  );
}
