import { configureStore } from '@reduxjs/toolkit';
import userReducer from './modules/userData';
import urlReducer from './modules/firebaseimg';
import itemdataReducer from "./modules/itemdata"
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const persistConfig = {
  key: 'root',
  version: 1,
  storage
};
const persistedReducer = persistReducer(persistConfig, userReducer);
const persistedReducertwo = persistReducer(persistConfig, urlReducer);
const persistedReducerthree = persistReducer(persistConfig, itemdataReducer);
export const store = configureStore({
  reducer: {
    user: persistedReducer, // Assuming your userReducer's state key is 'user'
    imgurl: persistedReducertwo,
    itemdata: persistedReducerthree
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});
let persistor = persistStore(store);
export { persistor };
