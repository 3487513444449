import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Suggestionmdl } from "../../constants/jsonConstant";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import "./Style.css";
import url from "../../config/axios";
import { getAsset } from "../../helpers/helper";
const Suggestionmodal = ({
  setSuggest,
  suggest,
  suggestdata,
  shopData,
  setSuggestId,
  setOpen,
}) => {
  const location = useLocation();



  return (
    <>
      <Container>
        <Modal.Header
          className="suggst-btn"
          closeButton
          onClick={() => setSuggest(0)}
        >
          <Modal.Title>Suggestions</Modal.Title>
        </Modal.Header>
        <hr />
        <div
          className={
            location.pathname === "/user-dashboard"
              ? "scrll1 noscroll"
              : "scrll noscroll"
          }
        >
          {suggestdata?.map((e) => (
            <Row>
              <Col className="mt-2" lg="1">
                <img className="ms-3 pi-ck" src={getAsset(e?.imageUrl)} alt="" crossOrigin="anonymous" />
              </Col>
              <Col className="mt-2" lg="9">
                <h6 className="-size m-0">{e?.username}</h6>
                <p className="PHERA-0 mb-0">{e?.role}</p>
                <p className="PHERA mb-0">{e?.message}</p>
              </Col>
              <Col lg="2">
                <div className=" d-flex">
                  <Col>
                    <button
                      type="button"
                      class="button-01 ms-4 mt-4"
                      onClick={() => {
                        setSuggestId(e?._id);
                        setSuggest(3);
                      }}
                    >
                      Read
                    </button>
                  </Col>
                </div>
              </Col>
              <hr />
            </Row>
          ))}
        </div>
      </Container>
    </>
  );
};
export default Suggestionmodal;
