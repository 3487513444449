import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import url from "../../config/axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { images } from "../../constants";

import { useSelector } from "react-redux";

const SellPropertyCards = () => {
  const { name } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [propertyview, setPropertyview] = useState();
  const [singlepropertyview, setSinglePropertyview] = useState();
  const id = name._id;
  useEffect(() => {
    url
      .get(`/company/?userid=${id}&isBusinessProperty=true&isJobsCompany=false`)
      .then((response) => {
        console.log("propertyres", response);
        setPropertyview(response?.data?.data?.Companies.at(-1));
      });

    url.get(`/property/user/?userid=${id}`).then((response) => {
      console.log("singlepropertyres", response);
      setSinglePropertyview(response?.data?.data?.Companies.at(-1));
    });
  }, [id]);
  return (
    <Row>
      <Col className="screenHeight">
        <div className="h-100 d-flex justify-content-center align-items-center">
          <Row className="">
            <Col lg={6} md={6} sm={6} xs="12 d-flex justify-content-center">
              <div className="me-0 me-sm-3 me-md-3 me-lg-3 mb-3 mb-sm-0 mb-md-0 mb-lg-0">
                <Card style={{ width: "17rem" }}>
                  <div className="fix_img">
                    <Card.Img variant="top" src={images.Singlebusinessimage} />
                  </div>

                  <Card.Body className="">
                    <Card.Title className="text-center mt-5">
                      Sell Personal Property
                    </Card.Title>

                    <div className="d-flex justify-content-center">
                      {singlepropertyview?.userid ? (
                        <button
                          className="btn-dash-sm  bg-blue-lg text-white d-flex justify-content-center align-items-center mt-1"
                          onClick={() =>
                            navigate(
                              `/SalePerosonalProperty/${singlepropertyview?._id}`
                            )
                          }
                        >
                          View
                        </button>
                      ) : (
                        <button className="btn-dash-sm  bg-blue-lg text-white d-flex justify-content-center align-items-center mt-1">
                          Create
                        </button>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col lg={6} md={6} sm={6} xs="12 d-flex justify-content-center">
              {" "}
              <div>
                <Card style={{ width: "17rem" }}>
                  <div className="fix_img">
                    <Card.Img variant="top" src={images.Businessimage} />
                  </div>

                  <Card.Body className="">
                    <Card.Title className="text-center mt-5">
                      Sell Business Property
                    </Card.Title>
                    <div className="d-flex justify-content-center">
                      {propertyview?.userid ? (
                        <button
                          className="btn-dash-sm  bg-blue-lg text-white d-flex justify-content-center align-items-center mt-1"
                          onClick={() =>
                            navigate(
                              `/Sale-Bussines-Property/${propertyview?._id}`
                            )
                          }
                        >
                          View
                        </button>
                      ) : (
                        <button className="btn-dash-sm  bg-blue-lg text-white d-flex justify-content-center align-items-center mt-1">
                          Create
                        </button>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};
export default SellPropertyCards;
