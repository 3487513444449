import React from 'react'
import { Container } from 'react-bootstrap'
import './style.css'
import { images } from '../../constants'
const TableEmail = () => {
  return (
    <div>
      <Container>


        <div>
          <div class="table-responsive ">
            <table class="table table-bordered border_3">
              <thead>
                <tr className='TABLE'>

                  <th className='headtxt  text-center '>Items</th>
                  <th className='headtxt tablerow text-center '>Descripition</th>
                  <th className='headtxt  text-center '>Quantity</th>
                  <th className='headtxt  text-center '>Unit Price</th>
                  <th className='headtxt  text-center '>Total cost</th>

                </tr>
              </thead>
              <tbody className='bg-clr-class'>
                <tr>
                  <td className='Table-font text-center pt-1 pb-0 fw-bold'>Bolts</td>
                  <td className='Table-font text-center pt-1 pb-0 fw-bold'>Steal M10 Bolts </td>
                  <td className='Table-font text-center pt-1 pb-0 fw-bold'>10</td>
                  <td className='Table-font text-center pt-1 pb-0 fw-bold'>10</td>
                  <td className='Table-font text-center pt-1 pb-0 fw-bold'>100</td>

                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>

                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>

                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>

                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>

                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>

                </tr>
                <tr>
                
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>

                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default TableEmail
