import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { publishImage } from "../../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
import { SuccessNotify, FailedNotify } from "../../../../constants/tostify";
import { useSelector } from "react-redux";
import url from "../../../../config/axios";
const Applynowform = ({ setShow, show, jobid, companyid }) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { name } = useSelector((state) => state.user);
  const [displaytext, setDisplaytext] = useState("");
  const [displaytexttwo, setDisplaytexttwo] = useState("");
  const [lgShow, setLgShow] = useState(false);
  const [applyjob, setApplyjob] = useState({
    designation: "",
    coverletter: "",
    userid: name?._id,
    name: "",
    image: [""],
    cv: [""],
    companyid: companyid,
    jobid: jobid,
  });



  const onDrop = async (acceptedFiles) => {
    setDisplaytext("Please wait...");
    try {
      const abc = await publishImage(acceptedFiles);
      if (abc?.length > 0) {
        setDisplaytext("");
        SuccessNotify("Image Uploaded Successfully");
      }
      setApplyjob((applyjob) => ({
        ...applyjob,
        image: abc,
      }));
    } catch (error) {
      FailedNotify("Failed! Try Again");
      setDisplaytext("");
    }
  };

  const onDropport = async (acceptedFiles) => {
    setDisplaytexttwo("Please wait...");
    try {
      const abc = await publishImage(acceptedFiles);
      if (abc?.length > 0) {
        setDisplaytexttwo("");
        SuccessNotify("File Uploaded Successfully");
      }
      setApplyjob((applyjob) => ({
        ...applyjob,
        cv: abc,
      }));
    } catch (error) {
      FailedNotify("Failed! Try Again");
      setDisplaytexttwo("");
    }
  };

  const { getRootProps: getRootPropsImage, getInputProps: getInputPropsImage } =
    useDropzone({
      accept: "image/*",
      onDrop,
    });

  const { getRootProps: getRootPropsPort, getInputProps: getInputPropsPort } =
    useDropzone({
      accept: "file/*",
      onDrop: onDropport,
    });

  const inputChange = (e) => {
    setApplyjob((applyjob) => ({
      ...applyjob,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    url
      .post(`jobapplied/applyjob`, applyjob)
      .then((res) => {
        if (res.data.success) {

          SuccessNotify("Applied Successfully");
          setShow(false);
        }
      })
      .catch((e) => {
        FailedNotify("Failed! Try Again");
        setShow(false);
      });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Apply</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Your Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                autoFocus
                name="name"
                onChange={(e) => inputChange(e)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Designation Applying For</Form.Label>
              <Form.Control
                type="text"
                placeholder="Designation"
                autoFocus
                name="designation"
                onChange={(e) => inputChange(e)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Add Cover Letter</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="coverletter"
                onChange={(e) => inputChange(e)}
              />
            </Form.Group>

            <Row>
              <Col lg="6">
                <div className="text-primary me-2" {...getRootPropsPort()}>
                  <AttachFileIcon className="cursor_P" />
                  <label htmlFor="file" className="cursor_P font_13">
                    Attach Cv
                  </label>
                  <input
                    type="file"
                    id="fileInputPort"
                    style={{ display: "none" }}
                    {...getInputPropsPort()}
                  />
                </div>
                <p className="font_13 mb-0 ms-2">{displaytexttwo}</p>
              </Col>
              <Col lg="6" className="text-end">
                <div className="text-warning me-2" {...getRootPropsImage()}>
                  <CameraAltIcon className="cursor_P" />
                  <label htmlFor="file" className="cursor_P font_13">
                    Upload Image
                  </label>
                  <input
                    type="file"
                    id="fileInputImage"
                    style={{ display: "none" }}
                    {...getInputPropsImage()}
                  />
                </div>
                <p className="font_13 mb-0 ms-2">{displaytext}</p>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            className="pt-1 pb-1 ps-4 pe-4 bg-danger text-white "
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="pt-1 pb-1 ps-4 pe-4 bg-blue"
            onClick={(e) => handleSubmit(e)}
          >
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Applynowform;
