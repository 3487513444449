import "./Coverimg.css";
import PersonalImage from "../../../../helpers/fileUpload";
import { Input } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Button from "@mui/material/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { images } from "../../../../constants";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { publishImage } from "../../../../helpers/imageupload";
import url from "../../../../config/axios";
import { useDropzone } from "react-dropzone";
import { FailedNotify, SuccessNotify } from "../../../../constants/tostify";
import { getAsset } from "../../../../helpers/helper";
import { useLocation } from "react-router-dom";
const Coverimg = ({
  widthHandler,
  inputChange,
  userDatahandler,
  info,
  setCreateShop,
  infooo,
  userimghandler,
  uploadcoverphoto,
  name,
  setInfooo,

}) => {

  const location = useLocation();
  const onDrop = async (acceptedFiles) => {

    try {
      const abc = await publishImage(acceptedFiles);
      await url
        .put(`/user/id/?id=${name?._id}`, { coverUrl: abc })
        .then((response) => {
          if (response.data.success) {
            url.get(`/user/id?id=${name?._id}`).then((response) => {
              setInfooo(response?.data?.data?.User);
            });
          }
        });
    } 
     catch (error) {
      FailedNotify("Failed! Try Again");
    }
  };
  
  
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

   return (
    <>
      <Col sm="12" lg="12" md="12" className="Coverimage bg-btn-primary p-0">
       
        {/* <div className="position-relative">
          <div className="position-absolute end-0 absolute-setting">
            <div className="dragImg" onClick={() => widthHandler()}>            
              <img src={images.Arrowright} alt="" />
            </div>
          </div>
        </div> */}

        <img
          crossorigin="anonymous"
          src={getAsset(infooo?.coverUrl[0])}
               className="coverImgSize"
          alt="error"
        />
      </Col> 

      <Row className="position_r">
        <Col
          lg="3"
          sm="5"
          className="Coverimage_dp p-0 smallWidth"
          name="coverUrl"
        >
          {location.pathname === "/user-profile" ? (
            <div {...getRootProps()} className="dropzone">
              <input {...getInputProps()} />
              <Button component="span" className="font_small text-dark">
                <CameraAltIcon style={{ color: "black" }} className="icon_f" />
                &nbsp;&nbsp; Add Cover Photo
              </Button>
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>

      {/* {imagefront.length > 0 && (
        <div className="image-preview">
          {imagefront.map((file) => (
            <img key={file.name} src={file.preview} alt="preview" className="preview-img" />
          ))}
        </div>
      )} */}
    </>
  );
};
export default Coverimg;
