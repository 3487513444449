import { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { images } from "../../constants/index";
import ShopAdds from "../shopAds/ShopAdds";
import OwnerPropertyViewModel from "../ShopViewModel/OwnerPropertyViewModel";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Following from "../../pages/createJobsProfile/components/jobsFollowing";
import Followers from "../../pages/createJobsProfile/components/jobsFollowers";
import Deletemodal from "../DeleteModal";
import Suggestion from "../../pages/suggestion";
import url from "../../config/axios";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RedoIcon from "@mui/icons-material/Redo";
import { SuccessNotify, FailedNotify } from "../../constants/tostify";
import Suggestionmodal from "../../pages/suggestionmodal";
import ShareModel from "../Sharemodel";

import ShareOption from "../Shareoption";
import { useSelector } from "react-redux";
import { getAsset } from "../../helpers/helper";
import ReportBox from "../Report/index";
const PropertyItemsCard = ({
  urlPass,
  shopid,
  post,
  suggest,
  setSuggest,
  suggestdata,
  shopData,
}) => {
  const { name } = useSelector((state) => state.user);
  const { id } = useParams();
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);
  const [showshare, setShowshare] = useState(0);
  const [shows, setShows] = useState(false);
  const [show, setShow] = useState(0);
  const [suggestId, setSuggestId] = useState();
  const [suggestid, setSuggestid] = useState("");
  const handleClose = () => setShows(0);

  localStorage.setItem("suggestid", suggestId);

  useEffect(() => {
    url
      .get(`/property/user/?userid=${name._id}&companyid=${id}`)
      .then(async (response) => {
     
        setProperties(response?.data?.data?.Companies);
      });
  }, []);
  const handleSelect = (key) => {
    if (key === "ps") {
      navigate(post);
    }
  };


  const handleClosemodal = () => {
    setShow(0);
  };
  const handleShowshare = () => {
    setShow(0);
    setShowshare(1);
  };
  const handleCloseshare = () => {
    setShowshare(0);
    setShow(1);
  };
  const Deleteitem = () => {
    url
      .delete(`property/?id=${suggestid}`)
      .then(async (response) => {
        if (response.data.success) {
          SuccessNotify("Item Deleted Successfully");
          setShows(0);

          url.get(`/property/user/?companyid=${id}`).then(async (response) => {
            setProperties(response?.data?.data?.Companies);
          });
        }
      })
      .catch((e) => {
        FailedNotify("Failed to Delete Try Again!");
        setShows(0);
      });
  };
  
  const suggestsingleid = localStorage.getItem("suggestid");

  return (
    <>
      {shows === 1 && (
        <Deletemodal
          shows={shows}
          title="Item"
          handleClose={handleClose}
          animation={true}
          handledelete={Deleteitem}
        />
      )}
      <ShareOption showshare={showshare} handleCloseshare={handleCloseshare} />
      <ShareModel
        handleClosemodal={handleClosemodal}
        show={show}
        handleShowshare={handleShowshare}
      />
      <div className=" ms-3 ps-0 pe-0 ms-0 me-0">
        <ShopAdds back="/SellingSide" />
        <div className="">
          {suggest === 1 ? (
            <Suggestionmodal
              setSuggestId={setSuggestId}
              setSuggest={setSuggest}
              suggest={suggest}
              suggestdata={suggestdata}
              shopid={shopid}
            />
          ) : suggest === 2 ? (
            <ReportBox setSuggest={setSuggest} suggest={suggest} />
          ) : suggest === 3 ? (
            <Suggestion
              suggestsingleid={suggestsingleid}
              suggestdata={suggestdata}
              shopData={shopData}
                            setSuggest={setSuggest}
            />
          ) : (
            <Tabs
              defaultActiveKey="Items"
              id="uncontrolled-tab-example"
              className="px-4 pooost"
              onSelect={handleSelect}
            >
              <Tab eventKey="Items" title="Items" className="jobviewhead">
                <div className="  noscroll shop-scroll ">
                  <div className="row ps-2 pe-4">
                    {properties?.map((Items) => (
                      <div className="col-lg-3  col-md-6 col-sm-6 p-2">
                        <div className="border_2">
                          <div key={Items?.id}>
                            <div className="fix-img">
                              <img
                                className="c_img"
                                src={getAsset(Items?.image[0])}
                                alt=""
                                crossOrigin="anonymous"
                              />
                            </div>
                            <Row className="">
                              <Col lg={11}>
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="pt-1 ">
                                    <p className="mb-0 ps-2 pe-2 items-card-font fw-bold">
                                      {Items?.subCategory}
                                    </p>
                                  </div>
                                  <div>
                                    <button
                                      className="text-white bg-blue border-0 font_11 rounded mt-2"
                                      onClick={() => setShow(1)}
                                    >
                                      Share{" "}
                                      <RedoIcon className="share_icon_f" />
                                    </button>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <div className="d-flex justify-content-between pt-1 pb-1 ps-2 pe-2 ">
                              <div>
                                <p className="mb-0 pt-1  items-card-font font-blue">
                                  {Items?.currency.slice(0,3)} : {Items?.price}
                                </p>
                              </div>
                              {/* <div>
                                {" "}
                                <img src={images.StarImg} />
                              </div> */}
                            </div>
                            <div className="d-flex justify-content-around mb-2 mt-1">
                              <p
                                className="mb-0 view-btn  text-center ms-1 me-1 mt-1"
                                onClick={
                                  () => {
                                    navigate(
                                      `${urlPass}/${shopid}/${Items._id}`
                                    );
                                    setSuggestid(Items?._id);
                                  }
                                  // setShowmodal(1) + setIndex(Items._id)
                                }
                              >
                                View
                              </p>
                              <Button
                                className="mb-0 view-btnone text-white  text-center ms-1 me-1 mt-1"
                                variant="danger"
                                onClick={() => {
                                  setSuggestid(Items._id);
                                  setShows(1);
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Tab>
            

              <Tab
                eventKey="ps"
                title="&#10133; Post Item"
                onClick={() => navigate(`${post}/` + id)}
              ></Tab>
            </Tabs>
          )}
        </div>
      </div>
    </>
  );
};
export default PropertyItemsCard;
