import Restaurantform from "../../../components/Resturent_form";
import { useState } from "react";
import { SuccessNotify } from "../../../constants/tostify";
import { FailedNotify } from "../../../constants/tostify";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import url from "../../../config/axios";
import { publishImage } from "../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
const ResturentPostform = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { name } = useSelector((state) => state.user);
  const [isloading, setIsloading] = useState();
  const [displayimg, setDisplayimg] = useState();
  const [data, setData] = useState({
    categoryid: "",
  });

  const Handledata = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split("."),
    }));
  };

  const categoryName = data?.categoryid[1];
  const categoryidone = data?.categoryid[0];

  const [shopCategory, setShopCategory] = useState();
  const [shopCategorytwo, setShopCategorytwo] = useState();
  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setFooddata((fooddata) => ({
        ...fooddata,
        images: abc
      }));

      setDisplayimg(abc);

    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });


  const [fooddata, setFooddata] = useState({
    businessType: "foodShopItem",
    userid: "",
    shopid: "",
    dishname: "",
    category: "",
    categoryid: "",
    subCategoryid: "",
    price: "",
    preparationTime: "",
    country: "",
    city: "",
    state: "",
    contact: "",
    email: "",
    salesPromotion: "",
    specialOffers: "",
    currency: "",
    duration: "",
    images: [""],
    status: true,
  });



  useEffect(() => {
    setFooddata((fooddata) => ({
      ...fooddata,
      category: categoryName,
      categoryid: categoryidone ? categoryidone : "",
    }));
  }, [categoryidone, categoryName]);

  useEffect(() => {
    url
      .get("/category/type/?type=foodShop&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });
  }, []);

  const Mydata = () => {
    url
      .get(
        `/category/type/?type=foodShopItem&parentCategory=${categoryName}`
      )
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategorytwo(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });
  };
  const userDatahandler = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("fooditem/", fooddata)
      .then(async (response) => {
        console.log("data", response);

        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Add Post Successfully");
          }, 1000);

          navigate(`/RestaurantAlldata/${id}`);
        }
      })
      .catch((e) => {
        setIsloading(false);
        FailedNotify("Failed To Post Try Again!");
      });
  };
  useEffect(() => {
    url.get(`/shop/user/?userid=${name?._id}`).then((response) => {
      setFooddata({
        ...fooddata,
        userid: response?.data?.data?.Shops[0].userid,
        // shopid: response?.data?.data.Shops.at(-1)._id,
        shopid: id,
      });
    });
  }, []);
  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }

  const handlevalue = (e) => {
    setFooddata((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <Restaurantform
        btntext="Post"
        handlevalue={handlevalue}
        Handledata={Handledata}
        Mydata={Mydata}
        userDatahandler={userDatahandler}
        shopCategory={shopCategory}
        shopCategorytwo={shopCategorytwo}
        id={id}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        displayimg={displayimg}
        setDisplayimg={setDisplayimg}
      />
    </>
  );
};

export default ResturentPostform;
