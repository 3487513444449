import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./style.css";
import { currencies } from "../../../../constants/jsonConstant";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Input from "@mui/material/Input";
import { useState } from "react";

const Promotionrightside = () => {
  const [val, setVal] = useState(68);
  const handleSliderInput = (e, newValue) => {
    setVal(newValue);
  };

  const handleInputChange = (e) => {
    setVal(e.target.value === "" ? "" : Number(e.target.value));
  };

  return (
    <div>
      <Container>
        <div className="pe-2 pt-2">
          <h5 className="heading-top">Enter detail of your ad.</h5>
          <h6 className="pt-2">
            Select your platform you want to display your ad
          </h6>

          <Row>
            <Col lg="4" md="4" sm="4" xs="6">
              <div className="d-flex align-items-center">
                <input type="checkbox" />
                <p className=" check-text m-1 ">Retailer</p>
              </div>
              <div className="d-flex align-items-center">
                <input type="checkbox" />
                <p className=" check-text m-1">Create job offer</p>
              </div>
              <div className="d-flex align-items-center">
                <input type="checkbox" />
                <p className=" check-text m-1">Property Online</p>
              </div>
              <div className="d-flex align-items-center">
                <input type="checkbox" />
                <p className=" check-text m-1">Transportation</p>
              </div>
              <div className="d-flex align-items-center">
                <input type="checkbox" />
                <p className=" check-text m-1">Sell scrap</p>
              </div>
              <div className="d-flex align-items-center">
                <input type="checkbox" />
                <p className=" check-text m-1">Used Items</p>
              </div>
            </Col>

            <Col lg="4" md="4" sm="4" xs="6">
              <div className="d-flex align-items-center">
                <input type="checkbox" />
                <p className=" check-text m-1 ">Wholesaler</p>
              </div>
              <div className="d-flex align-items-center">
                <input type="checkbox" />
                <p className=" check-text m-1  ">Post your CV</p>
              </div>
              <div className="d-flex align-items-center">
                <input type="checkbox" />
                <p className=" check-text m-1  ">Single item</p>
              </div>
              <div className="d-flex align-items-center">
                <input type="checkbox" />
                <p className=" check-text m-1  ">Farming</p>
              </div>

              <div className="d-flex align-items-center">
                <input type="checkbox" />
                <p className=" check-text m-1  ">Raw Material</p>
              </div>
            </Col>
            <Col
              lg="4"
              md="4"
              sm="4"
              xs="6"
              className="px-sm-2 px-md-0 px-lg-0"
            >
              <div className="d-flex align-items-center">
                <input type="checkbox" />
                <p className=" check-text m-1">Manufacturer</p>
              </div>
              <div className="d-flex align-items-center">
                <input type="checkbox" />
                <p className="pt-1 check-text m-1  ">Gems and Jewelry</p>
              </div>
              <div className="d-flex align-items-center">
                <input type="checkbox" />
                <p className=" check-text m-1  ">Used Vehicles</p>
              </div>
              <div className="d-flex align-items-center">
                <input type="checkbox" />
                <p className=" check-text m-1  ">Recycled Material</p>
              </div>
              <div className="d-flex align-items-center">
                <input type="checkbox" />
                <p className=" check-text m-1  ">Services</p>
              </div>
            </Col>
            {/* <Col lg='4 ' md="3">

                        </Col> */}
          </Row>
          <h5 className="pt-4">Select your ad duration</h5>
          <Row className="justify-content-start">
            <Col lg="4">
              <div className="d-flex flex-column">
                <label className="h6">Start Date</label>
                <input
                  className="me-2 border_3 promote-inputs-style p-1"
                  type="text"
                  placeholder="March-8-2023"
                />
              </div>
            </Col>

            <Col lg="4">
              <div className="d-flex flex-column">
                <label className="h6">End Date</label>
                <input
                  className="border_3 promote-inputs-style p-1"
                  placeholder="April-8-2023"
                />
              </div>
            </Col>
          </Row>
          <div className="pt-3 col-5 ">
            <h5 className="bluedrop8">Select country currency</h5>
            <Form.Select
              className="border_3 promote-inputs-style "
              aria-label="Default select example"
            >
              {currencies?.map((e) => (
                <option>
                  {e.name}&nbsp;({e.abbreviation})
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="pt-3 w-75">
            <h5>Daily budget</h5>
            <div className="d-flex">
              <p className="font_14 mb-0" style={{ color: "#1976d2" }}>
                <b>RS:</b>
              </p>{" "}
              &nbsp;
              <Input
                value={val}
                size="small"
                className="font_13 pb-0"
                onChange={handleInputChange}
                inputProps={{
                  step: 5,
                  min: 10,
                  max: 100000,
                  type: "number",
                  "aria-labelledby": "slider-with-input",
                }}
                style={{ color: "#1976d2" }}
              />
            </div>

            <Slider
              value={typeof val === "number" ? val : 0}
              onChange={handleSliderInput}
              aria-labelledby="slider-with-input"
              color="primary"
              min={10}
              max={100000}
            />
          </div>

          <h5 className="pt-1">Estimated audience</h5>
          <Row className="d-flex justify-content-end">
            <Col lg="9" md="6" sm="6" xs="6">
              <input
                className="text-center border_3 promote-inputs-style"
                style={{ width: "20%" }}
                value="18 K"
                readOnly
              />
            </Col>
            <Col lg="3 flex-end" md="6" sm="6" xs="6">
              <button className="btns-end">Place ad</button>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Promotionrightside;
