import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Url from "../../config/axios"; 
import Planmodal from "../Plan_modal";

const ShareOption = ({ handleCloseshare, showshare, sharetoOthers, timelineid, postId, id }) => {
  const [checkboxes, setCheckboxes] = useState([]);
  const [isShareDisabled, setIsShareDisabled] = useState(true); // State to disable/enable button
  const { name } = useSelector((state) => state.user);

  const [modalShow, setModalShow] = useState(false);
  // Set initial state for checkboxes based on the sharetoOthers prop
  useEffect(() => {
    if (sharetoOthers && sharetoOthers.length > 0) {
      const initialCheckboxes = sharetoOthers.map((label, index) => ({
        id: index,
        label: label, // Assume sharetoOthers contains labels
        isSelected: false, // Initially unselected
      }));
      setCheckboxes(initialCheckboxes);
    }
  }, [sharetoOthers]);

  // Handle select all checkboxes
  const handleSelectAll = (event) => {
    const selectedAll = event.target.checked;
    const updatedCheckboxes = checkboxes.map((checkbox) => ({
      ...checkbox,
      isSelected: selectedAll,
    }));
    setCheckboxes(updatedCheckboxes);
    setIsShareDisabled(!selectedAll); // Enable/disable button based on selection
  };

  // Handle single checkbox select
  const handleSelectSingle = (event) => {
    const id = parseInt(event.target.value);
    const updatedCheckboxes = checkboxes.map((checkbox) => {
      if (checkbox.id === id) {
        return { ...checkbox, isSelected: !checkbox.isSelected };
      }
      return checkbox;
    });
    setCheckboxes(updatedCheckboxes);
    setIsShareDisabled(!updatedCheckboxes.some((checkbox) => checkbox.isSelected)); // Enable button if any checkbox is selected
  };

  // Handle Share button click (API call)
  const handleShare = async () => {
    // Get the selected checkbox labels (business types)
    const selectedBusinessTypes = checkboxes
      .filter((checkbox) => checkbox.isSelected)
      .map((checkbox) => checkbox.label);

    // Create the API body
    const requestBody = {
      shopid: id, // You can dynamically change this
      userid: name?._id, // Use the id passed in props
      businessType: selectedBusinessTypes, // Selected checkboxes
      timelineid: timelineid,
      postid: postId,
    };

    try {
      // Make the PATCH request using Axios
      const response = await Url.patch("timeline/timelineShare", requestBody);
      console.log("API Response:", response.data);
      handleCloseshare(); // Close modal on successful share
    } catch (error) {
      console.error("Error sharing the post:", error);
    }
  };

  return (
    <>

    <Planmodal  show={modalShow} setModalShow={setModalShow}
         />
      <Modal show={showshare} onHide={handleCloseshare} animation={false}>
        <Modal.Header closeButton>
          <h6>Share on</h6>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex justify-content-end">
            <Col lg="3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="selectAllCheckbox"
                  checked={checkboxes.every((checkbox) => checkbox.isSelected)}
                  onChange={handleSelectAll}
                />
                <label
                  className="form-check-label font_11"
                  htmlFor="selectAllCheckbox"
                >
                  Select all
                </label>
              </div>
            </Col>
          </Row>
          <Row>
            {checkboxes.map((checkbox) => (
              <Col lg="6" key={checkbox.id}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={checkbox.id}
                    id={`checkbox-${checkbox.id}`}
                    checked={checkbox.isSelected}
                    onChange={handleSelectSingle}
                  />
                  <label
                    className="form-check-label font_11"
                    htmlFor={`checkbox-${checkbox.id}`}
                  >
                    {checkbox.label}
                  </label>
                </div>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary"
            onClick={ ()=> setModalShow(true)}
            disabled={isShareDisabled} // Disable the button if no checkboxes are selected
          >
            Share
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShareOption;
