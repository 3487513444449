import Form from "../../../../components/forms/index";
import { SuccessNotify, FailedNotify } from "../../../../constants/tostify";
import url from "../../../../config/axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const NewItemsForm = () => {
  const navigate = useNavigate();
  const { name } = useSelector((state) => state.user);
  const [shopCategory, setShopCategory] = useState();
  const [createShop, setCreateShop] = useState({
    userid: name._id,
    shopname: "",
    shopaddress: "",
    shoplicense: "",
    contact: "",
    image: [""],
    shopCategory: "",
    marketPlace:"",
    businessType: "newShop",
    description: "",
    status: true,
  });
  useEffect(() => {
    url
      .get("/category/type/?type=newShop")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
      });
  }, []);

  const PostShopData = (e) => {
    e.preventDefault();
    url
      .post("shop/", createShop)
      .then(async (response) => {
        if (response?.data?.success) {
          setTimeout(() => {
            SuccessNotify("New-items Form Created Successfully");
            navigate(`/rawmaterial-newitem/${response?.data?.data?._id}`);
          }, 1000);
        }
      })
      .catch((error) => {
        FailedNotify("Failed To Create Shop");
      });
  };

  return (
    <>
      <Form
        title="Fill to create NewItems Shop"
        butn="Create"
        back="/SellingSide"
        CreateHandler={PostShopData}
        setCreateShop={setCreateShop}
        createShop={createShop}
        shopCategory={shopCategory}
      />
    </>
  );
};

export default NewItemsForm;
