import React, { useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import "./style.css";

const VerificationModal = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <Container>
      <div>
        <div className="screenHeight">
          <Button variant="primary" onClick={handleShow}>
            Open Modal
          </Button>

          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Verification Modal</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <h6 className="d-flex justify-content-center">
                Thank you for your purchase
              </h6>
              <div className="animation">
                {/* Your tick animation here */}
                <span className="tick mt-5">&#10004;</span>
              </div>

              <h5 className="d-flex justify-content-center">
                Order #123RGR231567Y Confirmed
              </h5>
              <button
                variant="primary"
                size="lg"
                className="mt-4 button-settingdone text-light ms-5"
              >
                Track Order
              </button>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </Container>
  );
};

export default VerificationModal;
