import { Col, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import ApplyNowModal from "../applyNowform"
import BackArrow from "../../../../components/backArrow";
import { useParams } from "react-router-dom";
import url from "../../../../config/axios";
const ViewJobOnlineDetails = ({ Childern }) => {

    const [show, setShow] = useState(false);
    const [singeldata, setSingeldata] = useState();
    const { id } = useParams();
    const [readmore, setReadMore] = useState(true)
    const text = Childern

    useEffect(() => {
        url.get(`job/id/?id=${id}`).then(async (res) => {
            setSingeldata(res?.data?.data?.job)

        });
    }, []);


    return (
        <Row>
            {show === true &&
                <ApplyNowModal show={show} setShow={setShow} jobid={id} companyid={singeldata?.companyId} />
            }
            <div className="screenHeight ">
                <Row className="justify-content-center align-items-center">
                    <Col lg={5} md={10} className="Form-Shadow px-3 mt-3 mb-3 pt-3 pb-3">
                        <div className="ps-1 pe-1 pt-1 pb-1">
                            <div >
                                <BackArrow back="/find-jobs-online" />
                            </div>
                        </div>
                        <div>
                            <h4 className="fs-5 mb-1">
                                {singeldata?.title}
                            </h4>
                            <p className="titleText mb-0">Citrus Group pvt ltd</p>
                            <p className="mb-2">{singeldata?.country}&nbsp;,{singeldata?.state}&nbsp;,&nbsp;{singeldata?.city}</p>
                        </div>
                        <div>
                            <h5 className="font-sm">Job Type</h5>
                            <Form>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} >
                                        <div>
                                            <Form.Check
                                                checked
                                                inline
                                                label={singeldata?.jobtype}
                                                name="group1"
                                                type={type}
                                                className="labelText applicationText"
                                            />
                                        </div>
                                    </div>
                                ))}
                            </Form>
                        </div>
                        <div>
                            <h5 className="font-sm">On Site</h5>
                            <Form>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} >
                                        <div>
                                            <Form.Check
                                                checked
                                                inline
                                                label={singeldata?.siteremote}
                                                name="group1"
                                                type={type}
                                                className="labelText applicationText"
                                            />
                                        </div>
                                    </div>
                                ))}
                            </Form>
                        </div>

                        <div>
                            <h6 className="mb-1 pt-1 font-sm">
                                Email
                            </h6>
                            <p className="applicationText mb-1">{singeldata?.email}</p>
                        </div>
                        <div>
                            <h6 className="mb-1 pt-1 font-sm">
                                Contact Number
                            </h6>
                            <p className="applicationText mb-1">{singeldata?.contact}</p>
                        </div>
                        <div>
                            <h6 className="mb-1 pt-1 font-sm">
                                Last Date Of Apply
                            </h6>
                            <p className="applicationText mb-1">{singeldata?.duration}</p>
                        </div>

                        <div>
                            <h6 className="mb-1 pt-1 font-sm">
                                Job Description
                            </h6>
                            <p className="applicationText mb-1">
                                {singeldata?.description}

                            </p>
                        </div>

                        <div className="d-flex  pt-2">

                            <div className="d-flex bg-btn-primary px-2 py-1 border-radius-btns">
                                <button className="btn-application px-2 " onClick={() => setShow(true)}>Apply Now</button>
                            </div>
                        </div>


                    </Col>
                </Row>


            </div>
        </Row>
    )
}
export default ViewJobOnlineDetails;