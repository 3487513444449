import React from "react";
import PropertyForm from "../../../../components/forms/propertyForm/index";
import { SuccessNotify, FailedNotify } from "../../../../constants/tostify";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import url from "../../../../config/axios";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../components/Loader";
import { publishImage } from "../../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
const CreateForm = () => {
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const { name } = useSelector((state) => state.user);
  const [displayimg, setDisplayimg] = useState();

  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setValues((values) => ({
        ...values,
        image: abc
      }));

      setDisplayimg(abc);

    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });
  const [values, setValues] = useState({
    userid: name?._id,
    companyname: "",
    companyaddress: "",
    companylicense: "",
    contactnumber: "",
    description: "",
    image: [""],
    isBusinessProperty: "true",
    isJobsCompany: "false",
    businessType:"businessPropertyCompany"
  });


  const postBussinesProperty = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("/company/", values)
      .then(async (response) => {
        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Business Property Created Successfully");
            navigate(`/Propertybusiness/${response?.data?.data?._id}`);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed To Create Shop");
      });
  };

  if (isloading) {
    return (
      <div>
        <Loader heightsetting="screenHeight" />
      </div>
    );
  }

  return (
    <PropertyForm
      heading="Fill to Create Business Property Profile"
      Buttn="Create"
      name="Property Name"
      address="Property Address"
      back="/property-integrate"
      dataHandler={postBussinesProperty}
      setValues={setValues}
      values={values}
      getInputProps={getInputProps}
      getRootProps={getRootProps}
      displayimg={displayimg}
      setDisplayimg={setDisplayimg}
    />
  );
};

export default CreateForm;
