import PropertyAdForm from "../../../../components/forms/propertyAdform";
import url from "../../../../config/axios";
import { SuccessNotify, FailedNotify } from "../../../../constants/tostify";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CreateSingleProperty = () => {
  const { name } = useSelector((state) => state.user);
  const img_name = localStorage.getItem("firebaseImage");
  const [propertyadd, setPropertyadd] = useState({
    companyid: "",
    userid: name?._id,
    country: "",
    city: "",
    state: "",
    area: "",
    contactnumber: "",
    email: "",
    price: "",
    saletype: "",
    description: "",
    image: img_name,
    status: true,
  });
  const navigate = useNavigate();
  const formSubmit = (e) => {
    e.preventDefault();
    url
      .post("property/", propertyadd)
      .then(async (response) => {
        if (response?.data?.success) {
          setTimeout(() => {
            SuccessNotify("Add Posted SuccesFully...");
            navigate(`/SalePerosonalProperty/${response?.data?.data?._id}`);
          }, 3000);
        }
      })
      .catch((error) => {
        FailedNotify("Failed to Post Try Again!");
      });
  };

  return (
    <div>
      <PropertyAdForm
        title="Create Personal Property Category,Location,Price,Description"
        butn="Post"
        navigateTo="/SalePerosonalProperty"
        back="/SellingSide"
        propertyadd={propertyadd}
        setPropertyadd={setPropertyadd}
        handlerFunction={formSubmit}
      />
    </div>
  );
};
export default CreateSingleProperty;
