import VehicalAdd from "../../../../components/Vehical_Add_Form";
import url from "../../../../config/axios";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { SuccessNotify } from "../../../../constants/tostify";
import { FailedNotify } from "../../../../constants/tostify";

const UpdateVehical = () => {
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const { name } = useSelector((state) => state.user);
  const { id } = useParams();
  const [userData, setUserData] = useState({
    userid: name._id,
    shopid: "",
    brandName: "",
    model: "",
    noPlate: "",
    color: "",
    country: "",
    state: "",
    city: "",
    contact: "",
    description: "",
    images: [""],
  });
  useEffect(() => {
    url.get(`/tranportvehicle/id/?id=${id}`).then(async (res) => {
      setUserData(res?.data?.data?.TransportVehicle);
     
    });
  }, []);
  const handelupdate = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .put(`/tranportvehicle/id?id=${id}`, userData)
      .then(async (res) => {
        if (res.data.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Add Update Successfully");
          }, 1000);
          navigate(`/vehicaldetail/${id}/${userData.shopid}`);
        }
      })
      .catch((e) => {
        setIsloading(false);
        FailedNotify("Failed To Update Try Again!");
      });
  };

  const inputChange = (event) => {
    setUserData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  useEffect(() => {
    setUserData(() => ({
      ...userData,
      shopid: id,
    }));
  }, []);

  return (
    <>
      <div>
        <VehicalAdd
          back="/transport-profile"
          butn="Update"
          userData={userData}
          id={id}
          updatefunction={handelupdate}
          inputChange={inputChange}
        />
      </div>
    </>
  );
};

export default UpdateVehical;
