import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import UserShopProfile from "../../components/UserShopProfile/UserShopProfile";
import UserPropertyItems from "../../components/UserProperty/UserPropertyItems";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { FailedNotify, SuccessNotify } from "../../constants/tostify";
import url from "../../config/axios";
import { useState, useEffect } from "react";
const UserPeronalProperty = () => {
  const navigate = useNavigate();
  const imageUrl = useSelector((state) => state.imgurl);
  const [loading, setloading] = useState(false);
  const [shopData, setShopData] = useState();
  const { name } = useSelector((state) => state.user);
  const itemData = useSelector((state) => state.itemdata);
  const { id } = useParams();
  const [lgShowreport, setLgShowreport] = useState(false);

  console.log("ggghjhjhjhjh,,,,,,,,,,,,,,,,,,,,", itemData)
  useEffect(() => {
    url.get(`company/id/?id=${id}`).then(async (res) => {
      setShopData(res?.data?.data?.Company);
    });
  }, []);

  const [createShop, setCreateShop] = useState({
    userid: name._id,
    problem: "",
    desciription: "",
    image: [""],
  });
  useEffect(() => {
    setCreateShop((createShop) => ({
      ...createShop,
      image: imageUrl?.imgurl || "",
    }));
  }, [imageUrl]);

  const PostShopDatas = (e) => {
    setloading(true);
    e.preventDefault();
    url
      .post("help/?", createShop)
      .then(async (response) => {
        if (response?.data?.success) {
          setloading(false);
          setLgShowreport(false);
          setTimeout(() => {
            SuccessNotify("Form Retailer Shop Created Successfully");
            navigate(`/retailer-userprofile`);
          }, 1000);
        }
      })
      .catch((error) => {
        setloading(false);
        FailedNotify("Failed To Create Shop");
      });
  };
  return (
    <>
      <Container>
        <Row>
          <Col lg="4" className="screenHeight">
            <div className="border b-radius height_100">
              <UserShopProfile
                PostShopDatas={PostShopDatas}
                setCreateShop={setCreateShop}
                lgShowreport={lgShowreport}
                setLgShowreport={setLgShowreport}
                shopData={shopData}
                id={id}
              />
            </div>
          </Col>
          <Col lg="8" className="screenHeight">
            <div className="border b-radius">
              <UserPropertyItems
                itemData={itemData}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserPeronalProperty;