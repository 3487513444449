import { Container, Row, Col, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { SuccessNotify, FailedNotify } from "../../../../constants/tostify";
import url from "../../../../config/axios"
import { useState } from "react";
const Changeshopname = ({shopid}) => {
const location = useLocation();
const[newshop,setNewshop]=useState({
  oldname:"",
  shopname:""
});

  const handleChange = (e) => {
    setNewshop((prevState)=>({
      ...prevState,
      [e.target.name]:e.target.value
    }))
  }

const handleSubmit=(e)=>{
  e.preventDefault();
url.put(`shop/namechange/shopid?shopid=${shopid}`,newshop).then(async(res)=>{
if(res.data.success){
  SuccessNotify(res.data.message);
}
}).catch((e)=>{
  FailedNotify(e.response.data.message)
})
}


  return (
    <>
      <Container>
        <Row>
          <Col md={5} lg={5} className="mt-2">
            {location.pathname === "/company-setting" ? (
              <Form.Label className="custm-lble ">
                Current Company Name
              </Form.Label>
            ) : (
              <Form.Label className="custm-lble ">Current Shop Name</Form.Label>
            )}

            <Form.Control
              placeholder="Enter Current  Name"
              type="text"
              name="oldname"
              className="labelText"
              onChange={(e)=>handleChange(e)}
            />
          </Col>
        </Row>

        <Row>
          <Col md={5} lg={5} className="mt-2">
            {location.pathname === "/company-setting" ? (
              <Form.Label className="custm-lble ">New Company Name</Form.Label>
            ) : (
              <Form.Label className="custm-lble ">New Shop Name</Form.Label>
            )}

            <Form.Control
              placeholder="Enter New Name"
              type="text"
              name="shopname"
              className="labelText"
              onChange={(e)=>handleChange(e)}
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Col lg="2 mt-3 text-end">
            <button className="btn-blue-sm ps-0 pe-0 pt-1 pb-1" onClick={(e)=>handleSubmit(e)}>
              Submit Request
            </button>
          </Col>
        </div>
      </Container>
    </>
  );
};

export default Changeshopname;
