import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BackArrow from "../../components/backArrow";
import Promotionleftside from "./component/promotionleftside";
import Promotionrightside from "./component/promotionrightside";
import { useParams, useNavigate } from "react-router-dom";
import { SuccessNotify, FailedNotify } from "../../constants/tostify";

import url from "../../config/axios";
const Promotionmerge = () => {
  const { shopid } = useParams();
  const [promoteShop, setPromoteShop] = useState({
    location: "",
    age: "",
    gender: "",
    languages: "",
    plateforms: [],
    startDate: "",
    endDate: "",
    currency: "",
    dailyBudget: "",
    estimateAudience: "",
    status: true,
  });

  const promotData = () => {
    url
      .put(
        `https://backend.selectezi.com/api/shop/promoteShop/shopid?shopid=${shopid}`,
        promoteShop
      )
      .then((res) => {
        console.log("resssssssssssssssssss", res);
      })
      .catch((err) => {
        FailedNotify("Something went wrong!");
      });
  };

  const { id } = useParams();
  const navigate = useNavigate();
  return (
    <Row>
      <Container>
        <from>
          <div className="screenHeight d-flex justify-content-center">
            <div className="Form-Shadow col-lg-9 col-md-7 col-sm-9 col-xs-12 pb-4">
              <div className="arrowback ps-3">
                <BackArrow
                  back="/RestaurantAlldata"
                  black="black"
                  mystyle="p-1 ps-3"
                />
              </div>
              <Row>
                <Col lg="5" md="12">
                  <Promotionleftside />
                </Col>
                <Col lg="7" md="12">
                  <Promotionrightside />
                </Col>
              </Row>
            </div>
          </div>
        </from>
      </Container>
    </Row>
  );
};

export default Promotionmerge;
