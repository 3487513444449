import "../../components/Timeline/timeline.css";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import CloseIcon from "@mui/icons-material/Close";
const Reportboxprofile = ({ setModalopen }) => {
  return (
    <>
      <div className="dottsBox border_3 background-white b-radius font-sm font-grey pt-0">
        <div className="d-flex justify-content-end">
          <CloseIcon className="icon_f" onClick={() => setModalopen(0)} />
        </div>
        <div className="d-flex ps-2 pe-2" style={{cursor:"pointer"}}>
          <div>
            <ReportGmailerrorredIcon className="icon_f" />
          </div>
          <p >Report</p>
        </div>
       
      </div>
    </>
  );
};

export default Reportboxprofile;
