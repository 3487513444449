import React from "react";
import { images } from "../../constants";
import "./style.css";
const Nodata = () => {
  return (
    <div>
      <div className="d-flex justify-content-center align-items-center">
        <img className="img-data" src={images.Nodata} alt="First slide " />
      </div>
    </div>
  );
};

export default Nodata;
