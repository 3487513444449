import { Row, Col, Container } from "react-bootstrap";
import { images } from "../../../constants";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation } from "react-router-dom";
import url from "../../../config/axios";

const JobCandidateFilter = ({ category, titleName, FilterCandidate, title, Handlejob, FilterCompanydetails, shopCategoryone, Handlecompany, FilterJobDetails, Handlecandidate, Handledata, categoryName }) => {
  const location = useLocation();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSegment, setSelectedSegment] = useState("custom");
  const [selectedstateone, setSelectedstateone] = useState("");
  const [searchTermone, setSearchTermone] = useState("");
  const [selectedcity, setSelectedcity] = useState("");
  const [searchTermtwo, setSearchTermtwo] = useState("");
  const [shopCategory, setShopCategory] = useState();
  const [jobcategory, SetJobcategory] = useState();
  const [jobcategorytwo, SetJobcategorytwo] = useState();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSegmentChange = (value) => {
    setSelectedSegment(value);
  };


  useEffect(() => {
    url
      .get("category/type/?type=jobs&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => { });
  }, []);

  const mydatatwo = () => {
    url
      .get(`category/type/?type=jobs&parentCategory=${titleName}`)
      .then(async (response) => {
        if (response?.data?.success) {
          SetJobcategory(response?.data?.data?.Category);
        }
      });
  };

  const Mydatathree = () => {
    url
      .get(`/category/type/?type=jobSubItem&parentCategory=${categoryName}`)
      .then(async (response) => {
        if (response?.data?.success) {
          SetJobcategorytwo(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("jobcategory", error);
      });
  };

  // Function to handle the country selection
  const handleCountryChange = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
  };

  const handleSearchChangestate = (event) => {
    setSearchTermone(event.target.value);
  };

  const handleSearchChangecity = (event) => {
    setSearchTermtwo(event.target.value);
  };

  // Function to handle the state selection
  const handleCountryChangestate = (event) => {
    const { value } = event.target;
    setSelectedstateone(value);
  };

  // Function to handle the city selection
  const handleCountryChangecity = (event) => {
    const { value } = event.target;
    setSelectedcity(value);
  };
  const [hidefield, setHidefield] = useState(1);
  const [hidestate, setHidestate] = useState(1);
  const [hidecity, setHidecity] = useState(1);

  const [countrydata, SetCountrydata] = useState([]);
  const [getcountry] = useState();
  const [selectedState] = useState();
  const [getState, setState] = useState([]);
  const [getcity, setGetcity] = useState([]);
  const [statetwo, setStatetwo] = useState(getcountry);
  const [city, setCity] = useState(selectedState);
  const [open, setOpen] = useState(getcountry);

  const [count, setCount] = useState(1);
  const Inc = () => {
    if (count < 100000) {
      setCount(count + 1);
    }
  };

  const Dec = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const HandleEvent = (e) => {
    setCount(e.targte.value);
  };

  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => SetCountrydata(res.data))

      .catch((error) => { });
  }, []);

  const country = [...new Set(countrydata.map((item) => item.country))];
  country.sort();
  const handleCountry = (e) => {
    let states = countrydata.filter(
      (state) => state.country === e.target.value
    );
    states = [...new Set(states.map((item) => item.subcountry))];
    states.sort();
    setState(states);
  };

  const handleState = (e) => {
    let cities = countrydata.filter(
      (city) => city.subcountry === e.target.value
    );
    setGetcity(cities);
  };
  return (
    <div className="  padding-responsive background-image">
      <div className=" d-flex align-items-center bg-primary-one pt-2 pb-2 ps-2  mb-2">
        <Col lg="1">
          <img className="w-img" src={images.WorldSearchtwo} alt="web" />
        </Col>
        <Col lg="11">
          <h6 className="titleText pt-1 ps-2 mb-0">{title}</h6>
        </Col>
      </div>
      {location.pathname === "/find-candidates" ? (
        ////////////////candidate data/////
        <>
          <div className=" pt-2">
            <div>
              <div className="px-3 pt-2">
                <div>
                  <Form.Label className="custm-lble ">
                    {category}
                  </Form.Label>

                  <Form.Select className="custom-fix-one"
                    name="title"
                    onChange={(e) => Handledata(e)}
                  >
                    <option selected disabled>
                      Select Job Title
                    </option>

                    {shopCategory?.map((category) => (
                      <option key={category._id} value={`${category?._id}.${category?.name}`}>
                        {category?.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>

                <div className="col-md-12">
                  <label
                    htmlFor="inputPassword4"
                    placeholder="First name"
                    className="form-label labelSize"
                  >
                    Category
                  </label>
                  <div className="blood-type custom-fix-one p-2">
                    <select
                      className="b-radius selectSize w-100"
                      name="categoryid"
                      onChange={(e) => {
                        Handledata(e);
                      }}
                      onClick={mydatatwo}
                    >
                      <option selected disabled>
                        Select category
                      </option>

                      {jobcategory?.map((category) => (
                        <>
                          <option
                            key={category._id}
                            value={`${category?._id}.${category?.name}`}
                          >
                            {category?.name}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-12">
                  <label
                    htmlFor="inputPassword4"
                    placeholder="First name"
                    className="form-label labelSize"
                  >
                    Sub-Category
                  </label>
                  <div className="blood-type custom-fix-one p-2">
                    <select
                      className="b-radius selectSize w-100"
                      name="subCategoryid"
                      onChange={(e) => Handlejob(e)}
                      onClick={Mydatathree}

                    >
                      <option selected disabled>
                        Select Sub- Category
                      </option>

                      {jobcategorytwo?.map((subcategory) => (
                        <>
                          <option
                            key={subcategory._id}
                            value={subcategory?._id}
                          >
                            {subcategory?.name}
                          </option>
                        </>
                      ))}

                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <Form.Label className="custm-lble mb-0 ">
                      Job Type
                    </Form.Label>
                    <br></br>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="flexRadioDefault1"
                        name="jobtype"
                        value="full time"
                        onChange={(e) => Handlecandidate(e)}
                      />
                      <label
                        class="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Full Time
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="flexRadioDefault1"
                        name="jobtype"
                        value="part time"
                        onChange={(e) => Handlecandidate(e)}
                      />
                      <label
                        class="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Part Time
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <Form.Label className="custm-lble mb-0 ">
                      On-Site/Remote
                    </Form.Label>
                    <br></br>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="flexRadioDefault1"
                        name="siteremote"
                        value="onsite"
                        onChange={(e) => Handlecandidate(e)}
                      />
                      <label
                        class="form-check-label"
                        for="flexRadioDefault1"
                      >
                        On-Site
                      </label>
                    </div>

                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="flexRadioDefault1"
                        name="siteremote"
                        value="remote"
                        onChange={(e) => Handlecandidate(e)}
                      />
                      <label
                        class="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Remote
                      </label>
                    </div>
                  </div>
                </div>

                <div>
                  <Form.Label className="custm-lble mb-0 ">
                    Search by Location
                  </Form.Label>
                </div>

                <div className="col-md-12">
                  <label
                    htmlFor="inputPassword4"
                    placeholder="First name"
                    className="form-label labelSize"
                  >
                    Country*
                  </label>
                  <div className="blood-type custom-fix-one">
                    <div className="d-flex">
                      <div className="w-100">
                        <input
                          type="search"
                          value={searchTerm}
                          onChange={handleSearchChange}
                          placeholder="Search country here...."
                          className="w-100 search-input"

                          onClick={() => setHidefield(0)}
                        />
                      </div>
                      <div>
                        <span>
                          <SearchIcon className="search-icon-fs" />
                        </span>
                      </div>
                    </div>

                    <select
                      onClick={() => setHidefield(1)}
                      value={selectedCountry}
                      name="country"
                      onChange={(e) => {
                        handleCountryChange(e);
                        handleCountry(e);
                        Handlecandidate(e);
                      }}
                      className="b-radius selectSize w-100"

                    >
                      {hidefield && <option>Select country</option>}

                      {country
                        .filter((country) =>
                          country
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                        .map((country, index) => (
                          <option key={country} value={getcountry}>
                            {country}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-12">
                  <label
                    htmlFor="inputPassword4"
                    placeholder="First name"
                    className="form-label labelSize"
                  >
                    State*
                  </label>
                  <div className="blood-type custom-fix-one">
                    <div className="d-flex">
                      <div className="w-100">
                        <input
                          type="search"
                          value={searchTermone}
                          onChange={handleSearchChangestate}
                          placeholder="Search state here...."
                          className="w-100 search-input"
                          onClick={() => setHidestate(0)}
                        />
                      </div>
                      <div>
                        <span>
                          <SearchIcon className="search-icon-fs" />
                        </span>
                      </div>
                    </div>

                    <select
                      onClick={() => setHidestate(1)}
                      className="b-radius selectSize w-100"
                      value={selectedstateone}
                      aria-label="Default select example "
                      name="state"
                      onChange={(e) => {
                        handleState(e);
                        handleCountryChangestate(e);
                        Handlecandidate(e);
                      }}
                    >
                      {hidestate && <option>Select state</option>}


                      {getState
                        .filter((country) =>
                          country
                            .toLowerCase()
                            .includes(searchTermone.toLowerCase())
                        )
                        .map((country) => (
                          <option key={country} value={selectedState}>
                            {country}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <label
                    htmlFor="inputPassword4"
                    placeholder="First name"
                    className="form-label labelSize"
                  >
                    City
                  </label>
                  <div className="blood-type custom-fix-one">
                    <div className="d-flex">
                      <div className="w-100">
                        <input
                          type="search"
                          value={searchTermtwo}
                          onChange={handleSearchChangecity}
                          placeholder="Search city here...."
                          className="w-100 search-input"
                          onClick={() => setHidecity(0)}
                        />
                      </div>
                      <div>
                        <span>
                          <SearchIcon className="search-icon-fs" />
                        </span>
                      </div>
                    </div>

                    <select
                      onClick={() => setHidecity(1)}
                      value={selectedcity}
                      className="b-radius selectSize w-100"
                      aria-label="Default select example"
                      name="city"
                      onChange={(e) => {
                        handleCountryChangecity(e);
                        Handlecandidate(e);
                      }}
                    >


                      {hidecity && <option>Select city</option>}

                      {getcity
                        .filter((country) =>
                          country.name
                            .toLowerCase()
                            .includes(searchTermtwo.toLowerCase())
                        )
                        .map((country) => (
                          <option key={country.name}>{country.name}</option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-2 ps-3 pe-3 pt-1 pb-1">
            <button
              onClick={FilterCandidate}
              className="btn-lg bg-blue text-white"
            >
              Search
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="ps-xl-3 pe-xl-3 ps-lg-1 pe-lg-1 ps-md-2 pe-md-2 ps-sm-2 pe-sm-2">
            <div className="segment-container mt-2">
              <button
                className={`findjobsetbtn segment-button pt-1 pb-1 ${selectedSegment === "custom" ? "active" : ""
                  }`}
                onClick={() => handleSegmentChange("custom")}
              >
                Search by Job details
              </button>
              <button
                className={`findjobsetbtn segment-button ${selectedSegment === "segment" ? "active" : ""
                  }`}
                onClick={() => handleSegmentChange("segment")}
              >
                Search by Company details
              </button>
            </div>
          </div>
          {selectedSegment === "custom" ? (
            <>
              <div className=" pt-2">
                <div>
                  <div className="px-3 pt-2">
                    <div>
                      <Form.Label className="custm-lble ">
                        {category}
                      </Form.Label>

                      <Form.Select className="custom-fix-one"
                        name="title"
                        onChange={(e) => Handledata(e)}
                      >
                        <option selected disabled>
                          Select Job Title
                        </option>

                        {shopCategory?.map((category) => (
                          <option key={category._id} value={`${category?._id}.${category?.name}`}>
                            {category?.name}
                          </option>
                        ))}
                      </Form.Select>
                    </div>

                    <div className="col-md-12">
                      <label
                        htmlFor="inputPassword4"
                        placeholder="First name"
                        className="form-label labelSize"
                      >
                        Category
                      </label>
                      <div className="blood-type custom-fix-one p-2">
                        <select
                          className="b-radius selectSize w-100"
                          name="categoryid"
                          onChange={(e) => {
                            Handledata(e);

                          }}
                          onClick={mydatatwo}
                        >
                          <option selected disabled>
                            Select category
                          </option>

                          {jobcategory?.map((category) => (
                            <>
                              <option
                                key={category._id}
                                value={`${category?._id}.${category?.name}`}
                              >
                                {category?.name}
                              </option>
                            </>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label
                        htmlFor="inputPassword4"
                        placeholder="First name"
                        className="form-label labelSize"
                      >
                        Sub-Category
                      </label>
                      <div className="blood-type custom-fix-one p-2">
                        <select
                          className="b-radius selectSize w-100"
                          name="subCategoryid"
                          onChange={(e) => Handlejob(e)}
                          onClick={Mydatathree}

                        >
                          <option selected disabled>
                            Select Sub- Category
                          </option>

                          {jobcategorytwo?.map((subcategory) => (
                            <>
                              <option
                                key={subcategory._id}
                                value={subcategory?._id}
                              >
                                {subcategory?.name}
                              </option>
                            </>
                          ))}

                        </select>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <Form.Label className="custm-lble mb-0 ">
                          Job Type
                        </Form.Label>
                        <br></br>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            id="flexRadioDefault1"
                            name="jobtype"
                            value="full time"
                            onChange={(e) => Handlejob(e)}
                          />
                          <label
                            class="form-check-label"
                            for="flexRadioDefault1"
                          >
                            Full Time
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            id="flexRadioDefault1"
                            name="jobtype"
                            value="part time"
                            onChange={(e) => Handlejob(e)}
                          />
                          <label
                            class="form-check-label"
                            for="flexRadioDefault1"
                          >
                            Part Time
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <Form.Label className="custm-lble mb-0 ">
                          On-Site/Remote
                        </Form.Label>
                        <br></br>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            id="flexRadioDefault1"
                            name="siteremote"
                            value="On Site"
                            onChange={(e) => Handlejob(e)}
                          />
                          <label
                            class="form-check-label"
                            for="flexRadioDefault1"
                          >
                            On-Site
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            id="flexRadioDefault1"
                            name="siteremote"
                            value="remote"
                            onChange={(e) => Handlejob(e)}
                          />
                          <label
                            class="form-check-label"
                            for="flexRadioDefault1"
                          >
                            Remote
                          </label>
                        </div>
                      </div>
                    </div>

                    <div>
                      <Form.Label className="custm-lble mb-0 ">
                        Search by Location
                      </Form.Label>
                    </div>

                    <div className="col-md-12">
                      <label
                        htmlFor="inputPassword4"
                        placeholder="First name"
                        className="form-label labelSize"
                      >
                        Country*
                      </label>
                      <div className="blood-type custom-fix-one">
                        <div className="d-flex">
                          <div className="w-100">
                            <input
                              type="search"
                              value={searchTerm}
                              onChange={handleSearchChange}
                              placeholder="Search country here...."
                              className="w-100 search-input"

                              onClick={() => setHidefield(0)}
                            />
                          </div>
                          <div>
                            <span>
                              <SearchIcon className="search-icon-fs" />
                            </span>
                          </div>
                        </div>

                        <select
                          onClick={() => setHidefield(1)}
                          value={selectedCountry}
                          name="country"
                          onChange={(e) => {
                            handleCountryChange(e);
                            handleCountry(e);
                            Handlejob(e);
                          }}
                          className="b-radius selectSize w-100"

                        >
                          {hidefield && <option>Select country</option>}

                          {country
                            .filter((country) =>
                              country
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            )
                            .map((country, index) => (
                              <option key={country} value={getcountry}>
                                {country}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label
                        htmlFor="inputPassword4"
                        placeholder="First name"
                        className="form-label labelSize"
                      >
                        State*
                      </label>
                      <div className="blood-type custom-fix-one">
                        <div className="d-flex">
                          <div className="w-100">
                            <input
                              type="search"
                              value={searchTermone}
                              onChange={handleSearchChangestate}
                              placeholder="Search state here...."
                              className="w-100 search-input"
                              onClick={() => setHidestate(0)}
                            />
                          </div>
                          <div>
                            <span>
                              <SearchIcon className="search-icon-fs" />
                            </span>
                          </div>
                        </div>

                        <select
                          onClick={() => setHidestate(1)}
                          className="b-radius selectSize w-100"
                          value={selectedstateone}
                          aria-label="Default select example "
                          name="state"
                          onChange={(e) => {
                            handleState(e);
                            handleCountryChangestate(e);
                            Handlejob(e);
                          }}
                        >
                          {hidestate && <option>Select state</option>}


                          {getState
                            .filter((country) =>
                              country
                                .toLowerCase()
                                .includes(searchTermone.toLowerCase())
                            )
                            .map((country) => (
                              <option key={country} value={selectedState}>
                                {country}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label
                        htmlFor="inputPassword4"
                        placeholder="First name"
                        className="form-label labelSize"
                      >
                        City
                      </label>
                      <div className="blood-type custom-fix-one">
                        <div className="d-flex">
                          <div className="w-100">
                            <input
                              type="search"
                              value={searchTermtwo}
                              onChange={handleSearchChangecity}
                              placeholder="Search city here...."
                              className="w-100 search-input"
                              onClick={() => setHidecity(0)}
                            />
                          </div>
                          <div>
                            <span>
                              <SearchIcon className="search-icon-fs" />
                            </span>
                          </div>
                        </div>

                        <select
                          onClick={() => setHidecity(1)}
                          value={selectedcity}
                          className="b-radius selectSize w-100"
                          aria-label="Default select example"
                          name="city"
                          onChange={(e) => {
                            handleCountryChangecity(e);
                            Handlejob(e);
                          }}
                        >


                          {hidecity && <option>Select city</option>}

                          {getcity
                            .filter((country) =>
                              country.name
                                .toLowerCase()
                                .includes(searchTermtwo.toLowerCase())
                            )
                            .map((country) => (
                              <option key={country.name}>{country.name}</option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" d-flex justify-content-center mt-2 ps-3 pe-3 pt-1 pb-1">
                <button
                  onClick={FilterJobDetails}
                  className="btn-lg bg-blue text-white"
                >
                  Search
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="px-3 pt-2">
                <div>
                  <Form.Label className="custm-lble">
                    Search by Company Name
                  </Form.Label>
                  <Form.Control
                    className="custom-fix-one"
                    placeholder="Company Name"
                    name="companyname"
                    onChange={(e) => Handlecompany(e)}
                  />
                </div>

                <div>
                  <Form.Label className="custm-lble">

                    Search by Company ID
                  </Form.Label>
                  <Form.Control
                    className="custom-fix-one"
                    placeholder="Company ID"
                    name="companyid"
                    onChange={(e) => Handlecompany(e)}
                  />
                </div>
                <div>
                  <Form.Label className="custm-lble">

                    Search by Company Category
                  </Form.Label>
                  <Form.Select
                    className="custom-fix-one"
                    placeholder="Company-Category"
                    name="shopCategoryid"
                    onChange={(e) => Handlecompany(e)}

                  >
                    <option disabled selected> select Category</option>
                    {shopCategoryone?.map((category) => (
                      <>
                        <option
                          key={category._id}
                          value={category?._id}
                        >
                          {category?.name
                          }
                        </option>
                      </>
                    ))}
                  </Form.Select>
                </div>

                <div>
                  <Form.Label className="custm-lble"> Market Place </Form.Label>
                  <Form.Control
                    className="custom-fix-one"
                    placeholder="Market Place"
                    name="marketplace"
                    onChange={(e) => Handlecompany(e)}
                  />
                </div>

              </div>
              <div className=" d-flex justify-content-center mt-2 ps-3 pe-3 pt-1 pb-1">
                <button
                  onClick={FilterCompanydetails}
                  className="btn-lg bg-blue text-white"
                >
                  Search
                </button>
              </div>
            </>
          )}
        </>
      )
      }

    </div >
  );
};
export default JobCandidateFilter;