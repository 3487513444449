import React from "react";
import BoxData from "../Box";
import CardNav from "../CardNav";
import { Col, Row, Card } from "react-bootstrap";
import { images } from "../../../../constants";
import { useState } from "react";
const Farmming = ({ btn }) => {
  const [id, setIs] = useState(1);
  const [myinfo, setMyinfo] = useState("farming info")
  return (
    <div>
      <Row>
        <Col lg="12 mt-md-4 mt-lg-1">
          <CardNav Header="Farming" myid={id} myinfo={myinfo} />
          <Card className="shadow-main mt-2 ">
            <BoxData
              Title="Farming Shop"
              Butonn={btn}
              imgg={images.Farming}
              url="/farming-integrate"
              link="/Farming-online"
              ms="twentytwo-element"
              buy="twentyone"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Farmming;
