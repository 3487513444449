import { Container, Row, Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { images } from "../../constants";

const Connectmodal = ({showconnect,handleCloseconnect}) => {

  return (
    <>
      <Container>
   
          <Modal show={showconnect} onHide={handleCloseconnect} animation={false}>
            <Modal.Header closeButton className="custom-modal-header">
              <Modal.Title className="text-white">Connect</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
              <div className="createHeading text-center">Your Connect Request has been sent successfully</div>
              <div className="d-flex justify-content-center">
                <img src={images.Connect} />
              </div>
            </Modal.Body>
          </Modal>
      
      </Container>
    </>
  );
};

export default Connectmodal;
