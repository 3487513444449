import React from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { images } from '../../../../constants/index'
import { JuliaCards } from '../../../../constants/jsonConstant';
import './style.css'

const Trash = () => {
    return (
        <div>



            <div className=' class-for-scroll-juli noscroll'>

                

                    <Card className='border-0' >
                        {JuliaCards.map((Juli) => (
                            <Card.Body className=' p-0'>
                            {
                                <div>
                                    <hr className=' mb-2 mt-3' />
                                </div> 
                                }
                                <Row>
                                <div className='d-flex'>

                                    <div className='me-3 d-flex justify-content-center align-items-center'>
                                        <Card.Img variant='left' width='30' height='30' src={images.JuliaSideCard} />
                                    </div>
                                    <Col lg='8' md='8' sm='6' xs='7'>
                                        <div>

                                            <Row>
                                                <Col lg='2' md='3' sm='6' xs='4'>
                                                    <Card.Title className=' Juli-font-name fs-6 mb-0'>{Juli.name}</Card.Title>
                                                </Col>

                                                <Col lg='3' md='5' sm='4' xs='6' className=' p-0 d-flex justify-content-center ms-4'>

                                                    <p className=' Juli-date mb-0'>{Juli.ChatDate}</p>

                                                </Col>

                                            </Row>

                                            <p className='juli-title'>{Juli.title}</p>
                                            <Card.Text className='Juli-para'>
                                                {Juli.JuliaPara}
                                            </Card.Text>

                                        </div>
                                    </Col>
                                    <Col lg='3' md='3' sm='4' xs='4' className='d-flex justify-content-end align-items-center'>
                                        <button className='delete-trash'>Delete</button>
                                        <button className='restore-trash'>Restore</button>
                                    </Col>
                                </div>
                                </Row>
                            </Card.Body>


                        ))}

                    </Card>

                

            </div>



        </div>
    )
}

export default Trash
