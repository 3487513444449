import VehicalAdd from "../../../../components/Vehical_Add_Form";
import { useState, useEffect } from "react";
import url from "../../../../config/axios";
import { useNavigate, useParams } from "react-router-dom";
import { SuccessNotify } from "../../../../constants/tostify";
import { FailedNotify } from "../../../../constants/tostify";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
const PostVehical = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const imageUrl = useSelector((state) => state.imgurl);  
  const { name } = useSelector((state) => state.user);
  const [userData, setUserData] = useState({
    userid: "",
    shopid: "",
    brandName: "",
    model: "",
    noPlate: "",
    color: "",
    country: "",
    state: "",
    city: "",
    contact: "",
    description: "",
    images: "",
    status: false,
  });

  useEffect(() => {
    setUserData((userData) => ({
      ...userData,
      images: imageUrl?.imgurl || "",  
    }));
    // ...
  }, [imageUrl]);
  
  const userDatahandler = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("/tranportvehicle/", userData)
      .then(async (response) => {
        console.log("data", response);

        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Add Post Successfully");
          }, 1000);
          navigate(`/transport-profile/${response?.data?.data?.shopid}`);
        }
      })
      .catch((e) => {
        setIsloading(false);
        FailedNotify("Failed To Post Try Again!");
      });
  };
  useEffect(() => {
    url.get(`/shop/user/?userid=${name?._id}`).then((response) => {
      setUserData({
        ...userData,
        userid: response?.data?.data?.Shops[0].userid,
        shopid: response?.data?.data.Shops.at(-1)._id,
      });
    });
  }, []);

  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }
  return (
    <>
      <div>
        <VehicalAdd
          back="/transport-profile/"
          butn="Post"
          postbtn="/transport-profile"
          cancel="/transport-profile"
          setUserData={setUserData}
          handlerFunction={userDatahandler}
          userData={userData}
          id={id}
        />
      </div>
    </>
  );
};

export default PostVehical;
