import React, { useState } from 'react'
import { Container, Row, Col } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import RefreshIcon from '@mui/icons-material/Refresh';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button } from "react-bootstrap"
import Modal from 'react-bootstrap/Modal';
import "./style.css"


const HeaderNav = (props) => {
  const [Open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
 

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setOpen(false)
  }



  return (
    <div >

      <div>
        <Row>
          <Navbar className='pt-0 pb-0'>
            <Container >
              <Col lg='1' md='1'>
                {['bottom',].map((placement) => (

                  <OverlayTrigger
                    key={placement}
                    placement={placement}
                    overlay={
                      <Tooltip id={`tooltip-${placement}`}> Refresh
                      </Tooltip>
                    }
                  >
                    <RefreshIcon />

                  </OverlayTrigger>
                ))}
              </Col>

              <Col className='d-flex justify-content-center' lg='10' md='10'>


                <h5 className=" MALS fs-6 mb-0">{props.Mails}</h5>
              </Col>


              <Col className='d-flex justify-content-end' lg='1' md='1' onClick={()=>setOpen(true)}>
                <MoreVertIcon className='mt-2 '  />
              </Col>

              {Open===true && (
                <div className=' dropdownss' >


                  <p className=' txt ps-2' onClick={handleShow} >Delete</p>

                </div>
              )}



              <Modal size='sm' show={show} onHide={handleClose}>
                <div className=' modl d-flex pt-2'>


                  <h5 className='ps-2 pe-1'>Mail moved to trash</h5>


                  <button className='btnclose ' onClick={handleClose} >Cancel</button>


                </div>

              </Modal>




              <Navbar.Collapse id="basic-navbar-nav">
              </Navbar.Collapse>

            </Container>
          </Navbar>
        </Row>

        {/* <Row>
    
        <input type='search' placeholder='Search'/>
       
        </Row> */}
      </div>

    </div>
  )
}

export default HeaderNav;
