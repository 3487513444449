import { images } from "../../constants";
import Card from "react-bootstrap/Card";
import { useLocation, useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import BackArrow from "../backArrow";
import { Formik, Form } from "formik";
import ClearIcon from "@mui/icons-material/Clear";
import { useRef, useState } from "react";
import * as Yup from "yup";
import LocationModal from "../locationmodal/LocationModal";
import "./form.css";
import { getAsset } from "../../helpers/helper";
const CreateForm = ({
  CreateHandler,
  butn,
  id,
  setCreateShop,
  back,
  title,
  link,
  UpdateShop,
  createShop,
  shopCategoryone,
  Buttn,
  dataHandler,
  cancleurl,
  getInputProps,
  getRootProps,
  displayimg,
  setDisplayimg,
  Shopdatas
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const ShopHandler = (event) => {
    setCreateShop((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const validationSchema = Yup.object({
    shopname: Yup.string().required("shopname is required*"),
    shopCategory: Yup.string().required("shopCategory is required*"),
    marketPlace: Yup.string().required("Market Place is required*"),
    shoplicense: Yup.string().required("shoplicense is required*"),
    shopaddress: Yup.string().required("shopaddress is required*"),
    description: Yup.string().required("description is required*"),

  });

  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [imagecarry, setImagecarry] = useState();
  const [mshow, setMshow] = useState(false);

  const handleClose = () => setMshow(false);
  const handleShow = () => setMshow(true);

  const removeImage = (index) => {
    const updatedImages = [...displayimg];
    updatedImages.splice(index, 1);
    setDisplayimg(updatedImages);
  };

  return (
    <>
      <LocationModal mshow={mshow} handleClose={handleClose} />

      <Row>
        <Formik
          initialValues={{
            shopname: "",
            shopCategory: "",
            shoplicense: "",
            shopaddress: "",
            marketPlace: "",
            description: "",

          }}

          // validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
          onSubmit={(values) => { }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            isValid,
            dirty,
          }) => (
            <Form
              onSubmit={(e) => {
                CreateHandler(e);
                handleSubmit(e);
              }}
            >
              <div className="screenHeight d-flex justify-content-center align-items-center">
                <div className="Form-Shadow  col-lg-6 col-md-7 col-sm-9 col-xs-12   pb-3">
                  <div className="arrowback ">
                    <BackArrow back={back} black="black" mystyle="ps-4 p-1" />
                  </div>
                  <div className="ps-3 pe-1 pe-sm-3 pt-1 ms-3   me-sm-3">
                    <div className="pt-4 ">
                      <h4 className="createHeading">{title}</h4>
                    </div>

                    <div className="row">
                      <div className="d-flex flex-column pt-3 col-lg-6 col-md-6 col-12 pe-lg-2 pe-md-2">
                        <label className="custm-lble-2 pb-2">Shop Name</label>
                        {location.pathname === `${link}${id}` ? (
                          <input
                            style={{ color: "black" }}
                            defaultValue={createShop?.shopname}
                            name="shopname"
                            onChange={(e) => {
                              ShopHandler(e);
                              handleChange(e);
                            }}
                            className="input-style"
                            placeholder="Name"
                            onBlur={handleBlur}
                            disabled
                          />
                        ) : (
                          <input
                            style={{color: "black" }}
                            defaultValue={createShop?.shopname}
                            name="shopname"
                            onChange={(e) => {
                              ShopHandler(e);
                              handleChange(e);
                            }}
                            className="input-style"
                            placeholder="Name"
                            onBlur={handleBlur}
                          />
                        )}

                        {errors.shopname && touched.shopname ? (
                          <div>
                            <p className="error_text_color">
                              {errors.shopname}
                            </p>
                          </div>
                        ) : null}
                      </div>
                      <div className="d-flex flex-column pt-3 col-lg-6 col-md-6 col-12 pe-lg-2 pe-md-2">
                        <label className="custm-lble-2 pb-2">
                          Select Shop Catagory
                        </label>

                        <div class="selectdiv">
                          <label>
                            <select
                              name="shopCategory"
                              placeholder="Select Catagory"
                              style={{ color: "black" }}
                              className=""
                              onChange={(e) => {

                                handleChange(e);
                                Shopdatas(e);
                              }}

                              onBlur={handleBlur}
                            >
                              <option> Select Category </option>
                              {shopCategoryone?.map((category) => (
                                <option
                                  key={category._id}
                                  value={`${category?._id}.${category?.name}`}
                                >
                                  {category?.name}
                                </option>
                              ))}
                            </select>
                          </label>
                        </div>
                        {errors.shopCategory && touched.shopCategory ? (
                          <div>
                            <p className="error_text_color">
                              {errors.shopCategory}
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row ">
                      <div className="d-flex flex-column pt-3 col-lg-6 col-md-6 col-12 pe-lg-2 pe-md-2">
                        <label className="custm-lble-2 pb-2">
                          Market Place
                        </label>
                        <input
                          style={{ color: "black" }}
                          name="marketPlace"
                          className="input-style"
                          placeholder="Market Place"
                          onChange={(e) => {
                            ShopHandler(e);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.marketPlace && touched.marketPlace ? (
                          <div>
                            <p className="error_text_color cursor_P">
                              {errors.marketPlace}
                            </p>
                          </div>
                        ) : null}
                      </div>
                      <div className="d-flex flex-column pt-3 col-lg-6 col-md-6 col-12  ps-2">
                        <label className="custm-lble-2 pb-2">
                          CNIC or Licence Number
                        </label>
                        {location.pathname === `${link}${id}` ? (
                          <input
                            style={{ color: "black" }}
                            defaultValue={createShop?.shoplicense}
                            name="shoplicense"
                            onChange={(e) => {
                              ShopHandler(e);
                              handleChange(e);
                            }}
                            className="input-style"
                            placeholder="Licence Number/CNIC"
                            onBlur={handleBlur}
                            disabled
                          />
                        ) : (
                          <input
                            style={{ color: "black" }}
                            defaultValue={createShop?.shoplicense}
                            name="shoplicense"
                            onChange={(e) => {
                              ShopHandler(e);
                              handleChange(e);
                            }}
                            className="input-style"
                            placeholder="Licence Number/CNIC"
                            onBlur={handleBlur}
                          />
                        )}
                        {errors.shoplicense && touched.shoplicense ? (
                          <div>
                            <p className="error_text_color">
                              {errors.shoplicense}
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row ">
                      <div className="d-flex flex-column pt-lg-3 pt-3 col-12 ">
                        <label className="custm-lble-2 pb-2">Shop Address</label>
                        <input
                          defaultValue={createShop?.shopaddress}
                          name="shopaddress"
                          onChange={(e) => {
                            ShopHandler(e);
                            handleChange(e);
                          }}
                          className="input-style"
                          placeholder="Add Address with maps"
                          onBlur={handleBlur}
                          style={{ color: "black" }}
                        />
                        <div className="position-relative">
                          <img
                            onClick={handleShow}
                            className="iMg cursor_P"
                            src={images.FindClinic}
                            alt=""
                          />
                        </div>
                        {errors.shopaddress && touched.shopaddress ? (
                          <div>
                            <p className="error_text_color">
                              {errors.shopaddress}
                            </p>
                          </div>
                        ) : null}
                      </div>

                    </div>

                    <div>
                      <div className="d-flex flex-column pt-2">
                        <label className="custm-lble-2 pb-2">Description</label>
                        <textarea
                          style={{ color: "black" }}
                          defaultValue={createShop?.description}
                          name="description"
                          onChange={(e) => {
                            ShopHandler(e);
                            handleChange(e);
                          }}
                          placeholder="Description"
                          className="input-style pt-1 ps-2 pb-5 pe-2 "
                          onBlur={handleBlur}
                        />
                        {errors.description && touched.description ? (
                          <div>
                            <p className="error_text_color">
                              {errors.description}
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <Col>
                      <Row>
                        <Col lg="9" className="me-auto">
                          <Row>
                            <Col lg={3} className="mt-2 ms-1">
                              <Card
                                style={{ width: "6rem", height: "6rem" }}
                                {...getRootProps()}
                                className="dropzone"
                              >
                                <Card.Img
                                  className="imet-fit"
                                  src={images.AddImage}
                                />
                                <Card.Body>
                                  <input
                                    type="file"
                                    id="file"
                                    style={{ display: "none" }}
                                    {...getInputProps()}
                                    multiple
                                  />
                                </Card.Body>
                              </Card>
                            </Col>
                            {
                              displayimg?.length > 0 && (
                                <Col lg={7} className=" mt-1 d-flex">


                                  {displayimg.map((image, index) => (
                                    <Card
                                      className="me-2 mt-1"
                                      style={{
                                        width: "6rem",
                                        height: "6rem",
                                        border: "2px solid",
                                      }}

                                    >
                                      <Card.Img
                                        className="imet-fit upload-img mt-0"
                                        src={getAsset(image)}
                                        alt=""
                                        crossOrigin="anonymous"
                                      />
                                      <div
                                        className="close-btnone"
                                        onClick={() => removeImage(index)}
                                      >
                                        <ClearIcon />
                                      </div>
                                    </Card>
                                  ))}
                                </Col>
                              )
                            }
                          </Row>
                        </Col>
                      </Row>
                    </Col>

                    <div className="btns">
                      <button
                        className="btn1"
                        onClick={
                          location.pathname === `${link}${id}`
                            ? () => navigate(`${back}${id}`)
                            : () => navigate(back)
                        }
                      >
                        Cancel
                      </button>

                      <button
                        className="btn2 disable-btn"
                        onClick={butn === "Create" ? CreateHandler : UpdateShop}
                        disabled={
                          location.pathname === `${link}${id}`
                            ? ""
                            : !(isValid && dirty)
                        }
                        data-title={
                          location.pathname === `${link}${id}`
                            ? null
                            : !(isValid && dirty)
                              ? "Please fill out all Fields to enable button!"
                              : null
                        }
                      >
                        {butn}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Row>
    </>
  );
};
export default CreateForm;
