import OwnerPropertyViewModel from "../../components/ShopViewModel/OwnerPropertyViewModel";

const businessPropertyItemsCard = () => {
  return (
    <>
      <OwnerPropertyViewModel
        update="/update-bussiness-profile"
        deleteurl="/Sale-Bussines-Property"
      />
    </>
  );
};
export default businessPropertyItemsCard;
