import { Row, Col, Container } from "react-bootstrap";
import OwnerShopProfile from "../../components/OwnerShopProfile/OwnerShopProfile";
import ShopItemsOwner from "../../components/ShopItemsOwner/ShopItems";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import url from "../../config/axios";
import BackArrow from "../../components/backArrow";
import ShopManagebuttons from "../../components/Shops_manage_buttons";
import { useSelector } from "react-redux";
const RecycleOwnerProfile = () => {
  const { id } = useParams();
  const [shopData, setShopData] = useState();
  const [suggest, setSuggest] = useState(0);
  const [suggestdata, setSuggestdata] = useState();
  const [suggestdetail, setSuggestdetail] = useState(0);
  const { name } = useSelector((state) => state.user);
  useEffect(() => {
    url
      .get(`shop/user/?userid=${name._id}&businessType=recycledShop`)
      .then(async (res) => {
        if (res.data.success) {
          localStorage.setItem("mycategory", (res?.data?.data?.Shops[0]?.shopCategory?.name));
        }
      });
  }, []);
  useEffect(() => {
    url.get(`shop/id?id=${id}`).then(async (res) => {
      setShopData(res?.data?.data?.Shop);
    });
  }, []);

  const suggestdatta = () => {
    url
      .get(`suggestion/getShopSuggestion/vendorid?vendorid=${shopData?.shopid}`)
      .then(async (res) => {

        setSuggestdata(res?.data?.data[0]?.suggestions);
      });
  };
  return (
    <Container>
      <Col md={11} lg={11} className="mx-auto ">
        <Row>
          <Col md={4} lg={3} className="p-0">
            {" "}
            <OwnerShopProfile
              shopid={id}
              Add="Create Items"
              salespromotion="sales promotion"
              icon={<LocalOfferIcon className="silver-icon" />}
              Name="Update Recycle Profile"
              navigateTo="/recycle-update"
              post="/recycle-postadd"
              myid={id}
              shopData={shopData}
              setSuggest={setSuggest}
              suggestdatta={suggestdatta}
            />
          </Col>
          <Col md={8} lg={9} className=" screenHeight custom_ps-4">
            <div className="Form-Shadow h-100">

              <Row>
                <Col lg="4">
                  <BackArrow back={`/rawmaterial-recycle/${id}`} />
                </Col>
                <Col lg="8" className="mt-2 pe-4">
                  <ShopManagebuttons businessurl="/recycle-shops" />
                </Col>
              </Row>
              <ShopItemsOwner
                urlPass="/recycle-item-owner-view"
                navigateto="/recycle-shop"
                shopid={id}
                suggest={suggest}
                setSuggest={setSuggest}
                suggestdetail={suggestdetail}
                setSuggestdetail={setSuggestdetail}
                post={`/recycle-postadd/${id}`}
                suggestdata={suggestdata}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default RecycleOwnerProfile;
