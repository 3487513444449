import "../Join_livechat/joinlive.css";
import "../Create_Profile/ownchatroom.css";
import { useNavigate } from "react-router-dom";
import { Joinlobbies } from "../../../constants/jsonConstant";
import { useState } from "react";
import { Helmet } from "react-helmet";
import SearchIcon from '@mui/icons-material/Search';
import Chatroombackarrow from "../../../components/Chatroom-backarrow";

const JoinLobby = () => {
  const navigate = useNavigate();
  const [livechat, Livechat] = useState(Joinlobbies);

  return (
    <div>
      <Helmet>
        <body style="background:#FFFFF"></body>
      </Helmet>
      <div className="container">
        <div className="screenHeight">
          <div className=" bg_purple  p-3">
            <div className="col-2 mt-1"></div>
            <div className="row">
              <div className="col-3"><Chatroombackarrow url="/own-chatroom"/></div>
              <div className="col-6 mt-2 text-center">
                {" "}
                <button type="button" className=" livechat-btn text-white">
                  Lobbies
                </button>
              </div>
              <div className="col-lg-3 pe-lg-3">
                
                  {" "}
                  <div class="input-group">
                    {" "}
                    <span class="input-group-addon bg-white border-0">
                      {" "}
                      <SearchIcon />{" "}
                    </span>{" "}
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search"
                    />{" "}
                  </div>{" "}
               
              </div>
            </div>
            <div className="row live_chat_height noscroll ps-2 pe-2">
              {livechat.map((e) => (
                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 mt-4">
                  <div class="card" style={{ border: "none" }}>
                    <img
                      class="card-img-top"
                      src={e.image}
                      alt="Card image cap"
                    />
                    <div class="card-body p-0 ownchatroombody">
                      <h6 class="card-title text-white text-center mt-2">
                        {e.name}
                      </h6>
                      <div className="d-flex justify-content-center">
                        <button
                          type="button"
                          className=" own-btn mb-2"
                          onClick={() => navigate("/lobby-chatroom")}
                        >
                          Join
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinLobby;
