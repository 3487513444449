import { images } from "../../constants";
import { useNavigate } from "react-router-dom";
const BackArrow = (props) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div
      className={` ${props.mystyle}`}
    // onClick={() => props.setViewCandidate(false)}
    >
      <img
        src={props.black === "black" ? images.goback : images.goback2}
        alt="left Arrow"
        className="left-arrow-w cursor_P"
        onClick={handleGoBack}
      />
    </div>
  );
};

export default BackArrow;
