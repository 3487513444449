import { images } from "../../constants";
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import ShopAdds from "../shopAds/ShopAdds";
import { useNavigate, useLocation } from "react-router-dom";
import "./userShopItem.css";
import { Form, FormControl, Dropdown } from "react-bootstrap";
import UserbuyShopViewModel from "../../components/ShopViewModel/UserbuyShopViewModel";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axios from "axios";
import { Categories } from "../../constants/jsonConstant";
import { OrderSumary, ShopCategory } from "../../pages/index";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Addtocartmodal from "../Addtocartmodal";
import Connectmodal from "../Connect_modal";
import url from "../../config/axios";
import { getAsset } from "../../helpers/helper";
import SalesPromotion from "../../components/SalesPromotion/SalesPromotion";
import SpecialOffer from "../../components/specialoffer/SpecialOffer";
const UserItemShop = ({
  shopid,
  itemData,
  shopdata,
  regular,
  back,
  salespromotion,
  specialoffer,
  newItemdata,
}) => {
  useEffect(() => {
    url.get("/shopitem/filter/").then(async (res) => {
      setData(res.data.data.ShopItemScrapFiltered);
    });
  }, []);

console.log("ssssssssssssssssssssssssss",itemData)

  const [data, setData] = useState([]);
  const location = useLocation();
  const Navigate = useNavigate();
  const [showModel, setShowModel] = useState(1);
  const [itemshow, setItemshow] = useState();
  const [show, setShow] = useState(false);
  const [shopview, setShopview] = useState();
  const [itemshowshop, setItemshowshop] = useState();
  const handleClosetwo = () => setShow(0);
  const [key, setKey] = useState("home");
  const [showcategory, setShowcategory] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isOpenone, SetisOpenone] = useState(false);
  const [hideshow, setHideshow] = useState();
  const [filterregular, setFilterregular] = useState();
  const [newsearchdata, setNewsearchdata] = useState();
  const [filterspecial, setFilterspecial] = useState();
  const [filtersales, setFiltersales] = useState();
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [searchItems] = useState(["1", "2", "3", "4", "5"]);
  const [vehiclesearchdata, setVehiclesearchdata] = useState(0);
  const handleFocus = () => {
    setShowDropdown(true);
  };

  const handleBlur = () => {
    // Delay the dropdown hide to allow clicks to register on dropdown items
    setTimeout(() => setShowDropdown(false), 100);
  };

  const handleShowModal = () => {
    setShowModal(true);
    setShow(0);
  };

  const UserShopView = (e) => {
    url
      .get(
        location.pathname === "/buy-single-property"
          ? `/property/id/?id=${e}`
          : `/shopitem/id/?id=${e}`
      )
      .then(async (res) => {
        setShopview(
          location.pathname === "/buy-single-property"
            ? res?.data?.data?.Property
            : res?.data?.data?.ShopItem
        );
      })
      .catch((err) => {
        console.log("error 2");
      });
  };

  const filterData = (e) => {
    url
      .get(
        `shopitem/filterbyname?itemname=${e}&businessType=${
          itemData?.itemdata[0]?.businessType || newItemdata[0]?.businessType
        }`
      )
      .then((res) => {
        setNewsearchdata(res?.data?.data?.ShopItems);
        setFilterregular(res?.data?.regular);
        setFiltersales(res?.data?.salespromotion);
        setFilterspecial(res?.data?.specialoffer);
      });
  };

  const filterDatavehicle = (e) => {
    url
      .get(
        `sellvehicle/searchbybrand?brandName=${e}&businessType=usedVehicleShop`
      )
      .then((res) => {
       
        setVehiclesearchdata(res?.data?.data);
      });
  };

  return (
    <>
      <Addtocartmodal
        handleCloseModal={handleCloseModal}
        showModal={showModal}
        SetisOpenone={SetisOpenone}
      />
      {isOpenone === true && (
        <OrderSumary SetisOpenone={SetisOpenone} isOpenone={isOpenone} />
      )}
      {location.pathname === "/buy-single-property" ? "" : <ShopAdds />}
      <div
        className="border-top pt-2 me-lg-4 ms-lg-4 me-md-1 ms-md-1"
        onBlur={() => setShowcategory(false)}
      >
        <UserbuyShopViewModel
          show={show}
          handleClosetwo={handleClosetwo}
          handleShowModal={handleShowModal}
          shopview={shopview}
        />
        <Row>
          <Col className="position-relative">
            {newItemdata && newItemdata?.length > 0 ? (
              <Tabs id="uncontrolled-tab-example" className="mb-1">
                <Tab
                  eventKey="jobs"
                  title="Regular items"
                  className="jobviewhead "
                >
                  {showcategory === 1 && <ShopCategory />}
                  <div className="shop-scroll noscroll ">
                    <div className="items_scroll noscroll">
                      <div className=" row ps-2 pe-2">
                        {filterregular && filterregular.length > 0
                          ? filterregular?.map((Items) => (
                              <div
                                key={Items?._id}
                                className="col-lg-4 col-xl-3 col-md-6 p-2"
                              >
                                <div className="ShopsCards mt-2 mb-2">
                                  <img
                                    className="card-img"
                                    src={getAsset(
                                      Items?.images
                                        ? Items?.images[0]
                                        : Items?.image[0]
                                    )}
                                    alt=""
                                    crossOrigin="anonymous"
                                  />
                                  <div className="border">
                                    <div>
                                      <Row className="align-items-center pt-2">
                                        <Col>
                                          <p className="mb-0 ps-2 pe-2 items-card-font fw-bold">
                                            {Items?.itemname ||
                                              Items?.subCategory}
                                          </p>
                                        </Col>
                                        {location.pathname ===
                                        "/buy-single-property" ? (
                                          ""
                                        ) : (
                                          <Col>
                                            <p className="mb-0 ps-2 pe-2 stock">
                                              {Items?.stock} Stock
                                            </p>
                                          </Col>
                                        )}
                                      </Row>
                                      <Row className="ps-2 pe-3">
                                        <Col lg={6} className="ps-1 pe-0">
                                          <p className="mb-0 ps-2 pe-2 pt-1 items-card-font font-blue">
                                            {Items?.price}
                                          </p>
                                        </Col>
                                        <Col lg={6} className="p-0">
                                          <img
                                            className=""
                                            src={images?.StarsShop}
                                            alt="Rating stars"
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                    <div className="d-flex justify-content-around pt-1 pb-1 mb-1 mt-1">
                                      <p
                                        className="mb-0 ps-lg-4 pe-lg-4 view_btn-User"
                                        onClick={(e) => {
                                          setShow(true);
                                          UserShopView(Items?._id);
                                        }}
                                      >
                                        View
                                      </p>
                                    </div>
                                    <div className="margin-Boxes">
                                      <Row className="row bg-blue">
                                        <Col lg={6} md={4} className="p-0">
                                          <div className="border-end cursor">
                                            <p className="mb-0 ps-1 d-flex justify-content-center p-1">
                                              <img
                                                className="w-img-card"
                                                src={images?.Mail}
                                                alt="Mail icon"
                                              />
                                              <span className="btns text-white ps-1">
                                                Message
                                              </span>
                                            </p>
                                          </div>
                                        </Col>
                                        <Col lg={6} md={4} className="p-0">
                                          <div className="cursor">
                                            <p className="mb-0 ps-1 d-flex justify-content-center p-1">
                                              <img
                                                className="w-img-card"
                                                src={images?.Phone}
                                                alt="Phone icon"
                                              />
                                              <span className="btns text-white ps-1">
                                                Call
                                              </span>
                                            </p>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          : regular?.map((Items) => (
                              <div
                                key={Items?._id}
                                className="col-lg-4 col-xl-3 col-md-6 p-2"
                              >
                                <div className="ShopsCards mt-2 mb-2">
                                  <img
                                    className="card-img"
                                    src={getAsset(
                                      Items?.images
                                        ? Items?.images[0]
                                        : Items?.image[0]
                                    )}
                                    alt=""
                                    crossOrigin="anonymous"
                                  />
                                  <div className="border">
                                    <div>
                                      <Row className="align-items-center pt-2">
                                        <Col>
                                          <p className="mb-0 ps-2 pe-2 items-card-font fw-bold">
                                            {Items?.itemname ||
                                              Items?.subCategory}
                                          </p>
                                        </Col>
                                        {location.pathname ===
                                        "/buy-single-property" ? (
                                          ""
                                        ) : (
                                          <Col>
                                            <p className="mb-0 ps-2 pe-2 stock">
                                              {Items?.stock} Stock
                                            </p>
                                          </Col>
                                        )}
                                      </Row>
                                      <Row className="ps-2 pe-3">
                                        <Col lg={6} className="ps-1 pe-0">
                                          <p className="mb-0 ps-2 pe-2 pt-1 items-card-font font-blue">
                                            {Items?.price}
                                          </p>
                                        </Col>
                                        <Col lg={6} className="p-0">
                                          <img
                                            className=""
                                            src={images?.StarsShop}
                                            alt="Rating stars"
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                    <div className="d-flex justify-content-around pt-1 pb-1 mb-1 mt-1">
                                      <p
                                        className="mb-0 ps-lg-4 pe-lg-4 view_btn-User"
                                        onClick={(e) => {
                                          setShow(true);
                                          UserShopView(Items?._id);
                                        }}
                                      >
                                        View
                                      </p>
                                    </div>
                                    <div className="margin-Boxes">
                                      <Row className="row bg-blue">
                                        <Col lg={6} md={4} className="p-0">
                                          <div className="border-end cursor">
                                            <p className="mb-0 ps-1 d-flex justify-content-center p-1">
                                              <img
                                                className="w-img-card"
                                                src={images?.Mail}
                                                alt="Mail icon"
                                              />
                                              <span className="btns text-white ps-1">
                                                Message
                                              </span>
                                            </p>
                                          </div>
                                        </Col>
                                        <Col lg={6} md={4} className="p-0">
                                          <div className="cursor">
                                            <p className="mb-0 ps-1 d-flex justify-content-center p-1">
                                              <img
                                                className="w-img-card"
                                                src={images?.Phone}
                                                alt="Phone icon"
                                              />
                                              <span className="btns text-white ps-1">
                                                Call
                                              </span>
                                            </p>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                      </div>
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="sales" title="Sales Promotion">
                  <SalesPromotion
                    salespromotion={salespromotion}
                    filtersales={filtersales}
                    UserShopView={UserShopView}
                    setShow={setShow}
                    shopid={shopid}
                    newItemdata={newItemdata}
                  />
                </Tab>
                <Tab eventKey="special" title="Special Offers">
                  <SpecialOffer
                    specialoffer={specialoffer}
                    UserShopView={UserShopView}
                    filterspecial={filterspecial}
                    setShow={setShow}
                    shopid={shopid}
                    newItemdata={newItemdata}
                  />
                </Tab>
              </Tabs>
            ) : (
              <Tabs id="uncontrolled-tab-example" className="mb-1">
                <Tab eventKey="home" title="Shop Items" className="jobviewhead">
                  <div className="noscroll shop-scroll">
                    {location.pathname === "/single-item-buy" ||
                    location.pathname === "/buy-single-property" ? (
                      <div className="row ps-2 pe-2">
                        {shopdata?.map((Items) => (
                          <div
                            key={Items?._id}
                            className="col-lg-4 col-xl-3 col-md-6 p-2"
                          >
                            <div className="ShopsCards mt-2 mb-2">
                              <img
                                className="card-img"
                                src={getAsset(
                                  Items?.images
                                    ? Items?.images[0]
                                    : Items?.image[0]
                                )}
                                alt={"Item image"}
                                crossOrigin="anonymous"
                              />
                              <div className="border">
                                <div>
                                  <Row className="align-items-center pt-2">
                                    <Col>
                                      <p className="mb-0 ps-2 pe-2 items-card-font fw-bold">
                                        {Items?.itemname || Items?.subCategory}
                                      </p>
                                    </Col>
                                    {location.pathname ===
                                    "/buy-single-property" ? (
                                      ""
                                    ) : (
                                      <Col>
                                        <p className="mb-0 ps-2 pe-2 stock">
                                          {Items?.stock} Stock
                                        </p>
                                      </Col>
                                    )}
                                  </Row>

                                  <Row className="ps-2 pe-3">
                                    <Col lg={6} className="ps-1 pe-0">
                                      <p className="mb-0 ps-2 pe-2 pt-1 items-card-font font-blue">
                                        {Items?.price}
                                      </p>
                                    </Col>
                                    <Col lg={6} className="p-0">
                                      <img
                                        className=""
                                        src={images?.StarsShop}
                                        alt="Rating stars"
                                      />
                                    </Col>
                                  </Row>
                                </div>
                                <div className="d-flex justify-content-around pt-1 pb-1 mb-1 mt-1">
                                  <p
                                    className="mb-0 ps-lg-4 pe-lg-4 view_btn-User"
                                    onClick={(e) => {
                                      setShow(true);
                                      UserShopView(Items?._id);
                                    }}
                                  >
                                    View
                                  </p>
                                </div>
                                <div className="margin-Boxes">
                                  <Row className="row bg-blue">
                                    <Col lg={6} md={4} className="p-0">
                                      <div className="border-end cursor">
                                        <p className="mb-0 ps-1 d-flex justify-content-center p-1">
                                          <img
                                            className="w-img-card"
                                            src={images?.Mail}
                                            alt="Mail icon"
                                          />
                                          <span className="btns text-white ps-1">
                                            Message
                                          </span>
                                        </p>
                                      </div>
                                    </Col>
                                    <Col lg={6} md={4} className="p-0">
                                      <div className="cursor">
                                        <p className="mb-0 ps-1 d-flex justify-content-center p-1">
                                          <img
                                            className="w-img-card"
                                            src={images?.Phone}
                                            alt="Phone icon"
                                          />
                                          <span className="btns text-white ps-1">
                                            Call
                                          </span>
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="row ps-2 pe-2">
                        {
                        vehiclesearchdata && vehiclesearchdata?.length > 0 ?

                        vehiclesearchdata?.map((Items) => (
                          <div className="col-lg-4 col-xl-3 col-md-6 p-2">
                            <div
                              key={Items?.id}
                              className="ShopsCards   mt-2 mb-2"
                            >
                              <div className="fix-img position-relative">
                                {Items?.specialoffer?.length > 0 ? (
                                  <p className="mb-0  position-absolute p-1 bg-danger text-white font_12 ">
                                    {Items?.specialoffer}
                                  </p>
                                ) : Items?.salespromotion?.length > 0 ? (
                                  <p className="mb-0  position-absolute p-1 bg-danger text-white font_12 ">
                                    {Items?.salespromotion}
                                  </p>
                                ) : null}

                                <img
                                  className="card-img"
                                  src={getAsset(Items?.images)}
                                  alt=""
                                  crossOrigin="anonymous"
                                />
                              </div>
                              <div className="border">
                                <div className="">
                                  <Row className="align-items-center pt-2 pe-3">
                                    <Col>
                                      <div>
                                        <p className="mb-0 ps-2 pe-2 items-card-font fw-bold">
                                          {Items?.brandName}
                                        </p>
                                      </div>
                                    </Col>
                                    <Col>
                                      <div>
                                        <p className="mb-0 ps-2 pe-2  stock me-2 ">
                                          {Items?.stock} stock
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="ps-2 pe-3">
                                    <Col lg={6} className="ps-1 pe-0">
                                      <div>
                                        <p className="mb-0 ps-2 pe-2 pt-1  items-card-font font-blue">
                                          {Items?.currency.slice(0, 3)}{" "}
                                          {Items?.price}
                                        </p>
                                      </div>
                                    </Col>
                                    <Col lg={6} className="p-0">
                                      <div>
                                        <img
                                          className=""
                                          src={images?.StarsShop}
                                          alt=""
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                                <div className="d-flex justify-content-around pt-1 pb-1 mb-1 mt-1">
                                  <p
                                    className="mb-0 ps-lg-4 pe-lg-4 view_btn-User"
                                    onClick={(e) => {
                                      setShow(true);
                                      UserShopView(Items?._id);
                                    }}
                                  >
                                    View
                                  </p>
                                </div>
                                <div className="margin-Boxes">
                                  <Row className="row bg-blue ">
                                    <Col lg={6} md={4} className="p-0">
                                      <div className="border-end cursor">
                                        <p className="mb-0 ps-1 d-flex justify-content-center p-1">
                                          <img
                                            className="w-img-card"
                                            src={images?.Mail}
                                          />
                                          <span className="btns text-white ps-1">
                                            Message
                                          </span>
                                        </p>
                                      </div>
                                    </Col>
                                    <Col lg={6} md={4} className="p-0">
                                      <div className="cursor ">
                                        <p className="mb-0 ps-1 d-flex justify-content-center p-1">
                                          <img
                                            className="w-img-card"
                                            src={images?.Phone}
                                          />
                                          <span className="btns text-white ps-1">
                                            Call
                                          </span>
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                        
                        :
                        
                        newsearchdata && newsearchdata?.length > 0



                          ? newsearchdata?.map((Items) => (
                              <div className="col-lg-4 col-xl-3 col-md-6 p-2">
                                <div
                                  key={Items?.id}
                                  className="ShopsCards   mt-2 mb-2"
                                >
                                  <div className="fix-img position-relative">
                                    {Items?.specialoffer?.length > 0 ? (
                                      <p className="mb-0  position-absolute p-1 bg-danger text-white font_12 ">
                                        {Items?.specialoffer}
                                      </p>
                                    ) : Items?.salespromotion?.length > 0 ? (
                                      <p className="mb-0  position-absolute p-1 bg-danger text-white font_12 ">
                                        {Items?.salespromotion}
                                      </p>
                                    ) : null}

                                    <img
                                      className="card-img"
                                      src={getAsset(Items?.images)}
                                      alt=""
                                      crossOrigin="anonymous"
                                    />
                                  </div>
                                  <div className="border">
                                    <div className="">
                                      <Row className="align-items-center pt-2 pe-3">
                                        <Col>
                                          <div>
                                            <p className="mb-0 ps-2 pe-2 items-card-font fw-bold">
                                              {Items?.itemname}
                                            </p>
                                          </div>
                                        </Col>
                                        <Col>
                                          <div>
                                            <p className="mb-0 ps-2 pe-2  stock me-2 ">
                                              {Items?.stock} stock
                                            </p>
                                          </div>
                                        </Col>
                                      </Row>

                                      <Row className="ps-2 pe-3">
                                        <Col lg={6} className="ps-1 pe-0">
                                          <div>
                                            <p className="mb-0 ps-2 pe-2 pt-1  items-card-font font-blue">
                                              {Items?.currency.slice(0, 3)}{" "}
                                              {Items?.price}
                                            </p>
                                          </div>
                                        </Col>
                                        <Col lg={6} className="p-0">
                                          <div>
                                            <img
                                              className=""
                                              src={images?.StarsShop}
                                              alt=""
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                    <div className="d-flex justify-content-around pt-1 pb-1 mb-1 mt-1">
                                      <p
                                        className="mb-0 ps-lg-4 pe-lg-4 view_btn-User"
                                        onClick={(e) => {
                                          setShow(true);
                                          UserShopView(Items?._id);
                                        }}
                                      >
                                        View
                                      </p>
                                    </div>
                                    <div className="margin-Boxes">
                                      <Row className="row bg-blue ">
                                        <Col lg={6} md={4} className="p-0">
                                          <div className="border-end cursor">
                                            <p className="mb-0 ps-1 d-flex justify-content-center p-1">
                                              <img
                                                className="w-img-card"
                                                src={images?.Mail}
                                              />
                                              <span className="btns text-white ps-1">
                                                Message
                                              </span>
                                            </p>
                                          </div>
                                        </Col>
                                        <Col lg={6} md={4} className="p-0">
                                          <div className="cursor ">
                                            <p className="mb-0 ps-1 d-flex justify-content-center p-1">
                                              <img
                                                className="w-img-card"
                                                src={images?.Phone}
                                              />
                                              <span className="btns text-white ps-1">
                                                Call
                                              </span>
                                            </p>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))


                          : itemData?.itemdata?.map((Items) => (
                              <div className="col-lg-4 col-xl-3 col-md-6 p-2">
                                <div
                                  key={Items?.id}
                                  className="ShopsCards   mt-2 mb-2"
                                >
                                  <div className="fix-img position-relative">
                                    {Items?.specialoffer?.length > 0 ? (
                                      <p className="mb-0  position-absolute p-1 bg-danger text-white font_12 ">
                                        {Items?.specialoffer}
                                      </p>
                                    ) : Items?.salespromotion?.length > 0 ? (
                                      <p className="mb-0  position-absolute p-1 bg-danger text-white font_12 ">
                                        {Items?.salespromotion}
                                      </p>
                                    ) : null}

                                    <img
                                      className="card-img"
                                      src={getAsset(Items?.images)}
                                      alt=""
                                      crossOrigin="anonymous"
                                    />
                                  </div>
                                  <div className="border">
                                    <div className="">
                                      <Row className="align-items-center pt-2 pe-3">
                                        <Col>
                                          <div>
                                            <p className="mb-0 ps-2 pe-2 items-card-font fw-bold">
                                              {location.pathname===`/Byvehicleuserprofile/${shopid}`? Items?.brandName : Items?.itemname}
                                            </p>
                                          </div>
                                        </Col>
                                        <Col>
                                          <div>
                                            <p className="mb-0 ps-2 pe-2  stock me-2 ">
                                              {Items?.stock} stock
                                            </p>
                                          </div>
                                        </Col>
                                      </Row>

                                      <Row className="ps-2 pe-3">
                                        <Col lg={6} className="ps-1 pe-0">
                                          <div>
                                            <p className="mb-0 ps-2 pe-2 pt-1  items-card-font font-blue">
                                              {Items?.currency.slice(0, 3)}{" "}
                                              {Items?.price}
                                            </p>
                                          </div>
                                        </Col>
                                        <Col lg={6} className="p-0">
                                          <div>
                                            <img
                                              className=""
                                              src={images?.StarsShop}
                                              alt=""
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                    <div className="d-flex justify-content-around pt-1 pb-1 mb-1 mt-1">
                                      <p
                                        className="mb-0 ps-lg-4 pe-lg-4 view_btn-User"
                                        onClick={(e) => {
                                          setShow(true);
                                          UserShopView(Items?._id);
                                        }}
                                      >
                                        View
                                      </p>
                                    </div>
                                    <div className="margin-Boxes">
                                      <Row className="row bg-blue ">
                                        <Col lg={6} md={4} className="p-0">
                                          <div className="border-end cursor">
                                            <p className="mb-0 ps-1 d-flex justify-content-center p-1">
                                              <img
                                                className="w-img-card"
                                                src={images?.Mail}
                                              />
                                              <span className="btns text-white ps-1">
                                                Message
                                              </span>
                                            </p>
                                          </div>
                                        </Col>
                                        <Col lg={6} md={4} className="p-0">
                                          <div className="cursor ">
                                            <p className="mb-0 ps-1 d-flex justify-content-center p-1">
                                              <img
                                                className="w-img-card"
                                                src={images?.Phone}
                                              />
                                              <span className="btns text-white ps-1">
                                                Call
                                              </span>
                                            </p>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                      </div>
                    )}
                  </div>
                </Tab>
              </Tabs>
            )}
            {location.pathname === "/buy-single-property" ||
            location.pathname === "/single-item-buy" ? (
              ""
            ) : (
              <>
                <div className="position-absolute search-absolute">
                  <Form
                    className="position-relative"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  >
                    <FormControl
                      type="text"
                      placeholder="Search"
                      className="mr-sm-2 search-buing-area"
                      name="itemname"
                      onChange={(e) =>
                        back === "/buy-used-cars"
                          ? filterDatavehicle(e.target.value)
                          : filterData(e.target.value)
                      }
                    />
                  </Form>
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
export default UserItemShop;
