import PropertyForm from "../../../../components/forms/propertyForm/index";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SuccessNotify, FailedNotify } from "../../../../constants/tostify";
import url from "../../../../config/axios";
import Loader from "../../../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { FlashAutoOutlined } from "@mui/icons-material";
import { publishImage } from "../../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
const UpdatePropertyForm = () => {
  const { id } = useParams();
  const [isloading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const { name } = useSelector((state) => state.user);

  const [displayimg, setDisplayimg] = useState();
  useEffect(() => {
    setIsloading(true);
    url.get(`/company/id/?id=${id}`).then(async (response) => {
      if (response.data.success) {
        setIsloading(false);
        setValues(response?.data?.data?.Company);
      }
    });
  }, []);

  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setValues((values) => ({
        ...values,
        image: abc
      }));

      setDisplayimg(abc);

    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const [values, setValues] = useState({
    userid: name?._id,
    companyname: "",
    companyaddress: "",
    companylicense: "",
    contactnumber: "",
    description: "",
    image: [""],
    isBusinessProperty: true,
    isJobsCompany: false,
  });
  const updateBussinesProperty = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .put(`/company/id/?id=${id}`, values)
      .then(async (response) => {
        console.log("response", response);
        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Business Property Updated Successfully");
            navigate(`/Sale-Bussines-Property/${id}`);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed To Update Business Property");
      });
  };

  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }
  return (
    <>
      <PropertyForm
        heading="Update Business Property Form"
        Buttn="Update"
        name="Property Name"
        address="Property Address"
        back="/Sale-Bussines-Property"
        url="/Sale-Bussines-Property"
        setValues={setValues}
        values={values}
        id={id}
        Updatedata={updateBussinesProperty}
        link="/update-property-form/"
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        displayimg={displayimg}
        setDisplayimg={setDisplayimg}
      />
    </>
  );
};

export default UpdatePropertyForm;
