import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { images } from "../../../../constants";
import AddIcon from "@mui/icons-material/Add";
import MinimizeIcon from "@mui/icons-material/Minimize";
import "./style.css";
import ModalEmail from "../ModalEmail";
import PostCvModal from "../../../NewDashboard/Component/PostCvModal";
import RemoveModal from "../RemoveModal";
import BackArrow from "../../../../components/backArrow";
import { useParams } from "react-router-dom";
import ModalEmailone from "../ModalEmailone";
import RemoveEmailone from "../RemoveEmailone";

const ShopManage = () => {
  const { shopid } = useParams();
  const [members, setMembers] = useState([]);
  const [users, setusers] = useState([]);
  const addMember = () => {
    setMembers([
      ...members,
      <div className="d-flex justify-content-start ps-4 " key={members.length}>
        <img width="50" height="50" src={images.CircularAvatar} alt="Avatar" />
        <div className="ms-3">
          <p className="mb-0 fw-bold">Rose Gabrial</p>
          <p>can manage items and post on timeline</p>
        </div>
      </div>,
    ]);
  };

  const addUsers = () => {
    setusers([
      ...users,
      <div className="d-flex justify-content-start ps-4 " key={members.length}>
        <img width="50" height="50" src={images.CircularAvatar} alt="Avatar" />
        <div className="ms-3">
          <p className="mb-0 fw-bold">Rose Gabrial</p>
          <p>can manage items and post on timeline</p>
        </div>
      </div>,
    ]);
  };

  const handleRemoveClick = (index) => {
    const list = [...members];
    list.splice(index, 1);
    setMembers(list);
  };

  const handleRemoveUsers = (index) => {
    const list = [...users];
    list.splice(index, 1);
    setusers(list);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [secondmodal, setSecondmodal] = useState(false);
  const handleClosesecond = () => setSecondmodal(false);
  const handleShowsecond = () => setSecondmodal(true);

  const [isShown, setIsShown] = useState(false);
  const closeHandler = () => setIsShown(false);
  const showHandler = () => setIsShown(true);

  const [isShownsecond, setIsShownsecond] = useState(false);
  const closeHandlersecond = () => setIsShownsecond(false);
  const showHandlersecond = () => setIsShownsecond(true);
  return (
    <Row>
      <div className=" ">
        <Container>
          <ModalEmail
            show={show}
            handleClose={handleClose}
            addMember={addMember}
          />
          <RemoveModal
            isShown={isShown}
            closeHandler={closeHandler}
            handleRemoveClick={handleRemoveClick}
          />
          <ModalEmailone
            secondmodal={secondmodal}
            handleClosesecond={handleClosesecond}
            addUsers={addUsers}
          />
          <RemoveEmailone
            isShownsecond={isShownsecond}
            closeHandlersecond={closeHandlersecond}
            handleRemoveUsers={handleRemoveUsers}
          />
          <form>
            <div className="screenHeight classset-hr noscroll pt-4 ms-5 me-5  borderone_2">
              <Row>
                <Col lg="5">
                  <BackArrow
                    back={`/home-made-owner-profile/${shopid}`}
                    mystyle="ps-3"
                  />
                </Col>
                <Col lg="7">
                  <p className="manage-shopone">Manage your shops here</p>
                </Col>
              </Row>

              <div className="d-flex justify-content-between">
                <p className="fw-bold pt-4 ps-4 pe-4 mt-2">Admin</p>
                <p
                  className="mt-4 link-primary pe-5 curor-poin"
                  onClick={handleShow}
                >
                  <AddIcon /> Add Admin
                </p>
              </div>
              <hr className="start-4" />

              <div className="d-flex justify-content-between ">
                <div className="d-flex justify-content-start ps-4 pe-4 ">
                  <img width="50" height="50" src={images.CircularAvatar}></img>
                  <div className="ms-3 ">
                    <p className="mb-0 fw-bold">Rose Gabrial</p>
                    <p>All Accounts Access</p>
                  </div>
                </div>

                <div
                  className="d-flex link-danger me-5 curor-poin"
                  onClick={showHandler}
                >
                  <p>
                    <MinimizeIcon className="mb-3" />
                    Remove
                  </p>
                </div>
              </div>
              {members.map((member, index) => (
                <div key={index}>{member} </div>
              ))}
              <hr />

              <div className="d-flex justify-content-between">
                <p className="fw-bold ps-4 pe-4">
                  People with management access
                </p>
                <div
                  className="d-flex link-primary pe-3 curor-poin"
                  onClick={handleShowsecond}
                >
                  <AddIcon />
                  <p>Add another member</p>
                </div>
              </div>
              <hr />

              <div className="d-flex justify-content-between  ">
                <div className="d-flex justify-content-start ps-4 pe-4 ">
                  <img width="50" height="50" src={images.CircularAvatar}></img>
                  <div className="ms-3 ">
                    <p className="mb-0 fw-bold">Rose Gabrial</p>
                    <p>can manage items and post on timeline </p>
                  </div>
                </div>

                <div className="curor-poin">
                  <div
                    className="d-flex link-danger pe-5"
                    onClick={showHandlersecond}
                  >
                    <p>
                      <MinimizeIcon className="mb-3" />
                      Remove
                    </p>
                  </div>
                </div>
              </div>
              {users.map((member, index) => (
                <div key={index}>{member} </div>
              ))}
              <hr />
            </div>
          </form>
        </Container>
      </div>
    </Row>
  );
};

export default ShopManage;
