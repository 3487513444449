import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import PersonalImage from "../../../helpers/fileUpload";
import { useRef, useState } from "react";
import { images } from "../../../constants";
import BackArrow from "../../backArrow";
import { Col, Row } from "react-bootstrap";
import ClearIcon from "@mui/icons-material/Clear";
import { useLocation, useNavigate } from "react-router-dom";
import "./propertyForm.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import LocationModal from "../../locationmodal/LocationModal";
import { getAsset } from "../../../helpers/helper";
const PropertyForm = ({
  back,
  heading,
  name,
  address,
  Buttn,
  setValues,
  values,
  dataHandler,
  Updatedata,
  id,
  link,
  Handledata,
  shopCategoryone,
  getInputProps,
  getRootProps,
  displayimg,
  setDisplayimg
}) => {
  const [selectedImage, setSelectedImage] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const handleValue = (event) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };



  // const onChangePicture = (e) => {
  //   if (e.target.files[0]) {
  //     PersonalImage(e.target.files[0]);
  //     const reader = new FileReader();
  //     reader.readAsDataURL(e.target.files[0]);
  //   }
  // };

  const [uploadedImages, setUploadedImages] = useState([]);
  const [mshow, setMshow] = useState(false);
  const handleClose = () => setMshow(false);
  const handleShow = () => setMshow(true);
  const removeImage = (index) => {
    const updatedImages = [...displayimg];
    updatedImages.splice(index, 1);
    setDisplayimg(updatedImages);
  };
  const validationSchema = Yup.object({
    companyname: Yup.string().required("Property name is required*"),
    companyaddress: Yup.string().required("Property name is required*"),
    companylicense: Yup.string().required("Property license is required*"),
    description: Yup.string().required("description is required*"),

  });
  return (
    <Row>
      <LocationModal mshow={mshow} handleClose={handleClose} />

      <Formik
        initialValues={{
          companyname: "",
          companyaddress: "",
          companylicense: "",
          description: "",

        }}
        // validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={(values) => { }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
          isValid,
          dirty,
        }) => (
          <Form
            onSubmit={(e) => {
              dataHandler(e);
              handleSubmit(e);
            }}
          >
            <div className="screenHeight d-flex justify-content-center align-items-center">
              <div className="Form-Shadow  col-lg-6 col-md-8 col-sm-12 col-xs-12 ">
                <div className="arrowback ">
                  <BackArrow back={back} black="black" mystyle="ps-4 p-1" />
                </div>
                <div className="ps-0 pe-0 ps-sm-1 pe-sm-1 ps-md-2 pe-md-2 ps-lg-3 pe-lg-3 pt-1">
                  <div>
                    <h4 className="createHeading ps-3 pt-3 ">{heading}</h4>
                  </div>
                  {/* Inputs  */}
                  {location.pathname === `${link}${id}` ? (
                    <div className="d-flex flex-column ps-3 pe-3 ">
                      <label className="custm-lble-2 pb-2">{name}</label>
                      <input
                        disabled
                        defaultValue={values?.companyname}
                        className="input-style"
                        placeholder="Name"
                        name="companyname"
                        onChange={(e) => {
                          handleValue(e);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.companyname && touched.companyname ? (
                        <div>
                          <p className="error_text_color">
                            {errors.companyname}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div className="row pe-3 ps-3">
                      <div className={`d-flex flex-column ${location.pathname === "/business-property-form" ? "col-12" : "col-6"}`}>
                        <label className="custm-lble-2 pb-2">{name}</label>
                        <input
                          defaultValue={values?.companyname}
                          className="input-style"
                          placeholder="Name"
                          name="companyname"
                          onChange={(e) => {
                            handleValue(e);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.companyname && touched.companyname ? (
                          <div>
                            <p className="error_text_color">
                              {errors.companyname}
                            </p>
                          </div>
                        ) : null}
                      </div>

                      {
                        location.pathname === "/business-property-form" ? "" : (<div className="d-flex flex-column  col-6">
                          <label className="custm-lble-2 pb-2"> Select Category </label>
                          <div class="selectdiv pt-2 pb-2">

                            <select
                              name="shopCategory"
                              placeholder="Select Catagory"
                              style={{ color: "black" }}
                              className=""

                              onChange={(e) => {
                                Handledata(e);

                              }}

                            >
                              <option> Select Category </option>
                              {shopCategoryone?.map((category) => (
                                <option
                                  key={category._id}
                                  value={`${category?._id}.${category?.name}`}
                                >
                                  {category?.name}
                                </option>
                              ))}
                            </select>

                          </div>


                        </div>)
                      }


                    </div>
                  )}

                  <div className="row ps-3 pe-3">
                    <div className="d-flex flex-column pt-3 col-6">
                      <label className="custm-lble-2 pb-2 pt-1">
                        Market Place name
                      </label>
                      <input
                        defaultValue={""}
                        className="input-style"
                        placeholder="Market Place name"
                        name="marketPlace"
                        onChange={(e) => {
                          handleValue(e);

                        }}

                      />
                      {errors.companylicense && touched.companylicense ? (
                        <div>
                          <p className="error_text_color">
                            {errors.companylicense}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    {location.pathname === `${link}${id}` ? (
                      <div className="d-flex flex-column pt-3 col-6">
                        <label className="custm-lble-2 pb-2 pt-1">
                          CNIC or license Number
                        </label>
                        <input
                          disabled
                          defaultValue={values?.companylicense}
                          className="input-style"
                          placeholder="CNIC or license Number"
                          name="companylicense"
                          onChange={(e) => {
                            handleValue(e);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.companylicense && touched.companylicense ? (
                          <div>
                            <p className="error_text_color">
                              {errors.companylicense}
                            </p>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div className="d-flex flex-column pt-3 col-6">
                        <label className="custm-lble-2 pb-2 pt-1">
                          CNIC or license Number
                        </label>
                        <input
                          defaultValue={values?.companylicense}
                          className="input-style"
                          placeholder="CNIC or license Number"
                          name="companylicense"
                          onChange={(e) => {
                            handleValue(e);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.companylicense && touched.companylicense ? (
                          <div>
                            <p className="error_text_color">
                              {errors.companylicense}
                            </p>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>

                  <Row className="ps-3 pe-3">
                    <Col lg="12 d-flex flex-column  mt-3">
                      <label className="custm-lble-2 pb-2 ">{address}</label>
                      <input
                        defaultValue={values?.companyaddress}
                        className="input-style"
                        placeholder="Address"
                        name="companyaddress"
                        onChange={(e) => {
                          handleValue(e);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                      <div className="position-relative">
                        {" "}
                        <img
                          onClick={handleShow}
                          className="iMg cursor_P"
                          src={images.FindClinic}
                          alt=""
                        />{" "}
                      </div>
                      {errors.companyaddress && touched.companyaddress ? (
                        <div>
                          <p className="error_text_color">
                            {errors.companyaddress}
                          </p>
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  {/* Description */}
                  <div>
                    <div className="d-flex flex-column pt-2 ps-3 pe-3">
                      <label className="custm-lble-2 pb-2">Description</label>
                      <textarea
                        defaultValue={values?.description}
                        placeholder="Description"
                        className="input-style pt-1 ps-2 pb-5 pe-2 "
                        name="description"
                        onChange={(e) => {
                          handleValue(e);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.description && touched.description ? (
                        <div>
                          <p className="error_text_color">
                            {errors.description}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <Col lg="9" className="me-auto">
                    <Row>
                      <Col lg={3} className="mt-2 ms-3">
                        <Card
                          style={{ width: "6rem", height: "6rem" }}
                          {...getRootProps()}
                          className="dropzone"
                        >
                          <Card.Img
                            className="imet-fit"
                            src={images.AddImage}
                          />
                          <Card.Body>
                            <input
                              type="file"

                              id="file"
                              style={{ display: "none" }}
                              {...getInputProps()}
                              multiple

                            />
                          </Card.Body>
                        </Card>
                      </Col>
                      {
                        displayimg?.length > 0 && (
                          <Col lg={7} className=" mt-1 d-flex">


                            {displayimg.map((image, index) => (
                              <Card
                                className="me-2 mt-1"
                                style={{
                                  width: "6rem",
                                  height: "6rem",
                                  border: "2px solid",
                                }}

                              >
                                <Card.Img
                                  className="imet-fit upload-img mt-0"
                                  src={getAsset(image)}
                                  alt=""
                                  crossOrigin="anonymous"
                                />
                                <div
                                  className="close-btnone"
                                  onClick={() => removeImage(index)}
                                >
                                  <ClearIcon />
                                </div>
                              </Card>
                            ))}
                          </Col>
                        )

                      }

                    </Row>
                  </Col>

                  <div className="btns pe-4 pb-4">
                    {location.pathname === `${link}${id}` ? (
                      <button
                        className="btn1"
                        onClick={() =>
                          navigate(`/Sale-Bussines-Property/${id}`)
                        }
                      >
                        Cancel
                      </button>
                    ) : (
                      <button
                        className="btn1"
                        onClick={() => navigate("/property-integrate")}
                      >
                        Cancel
                      </button>
                    )}

                    <Button
                      className="btn2 disable-btn p-1"
                      variant="primary"
                      onClick={Buttn === "Create" ? dataHandler : Updatedata}
                      disabled={
                        location.pathname === `${link}${id}`
                          ? ""
                          : !(isValid && dirty)
                      }
                      data-title={
                        location.pathname === `${link}${id}`
                          ? null
                          : !(isValid && dirty)
                            ? "Please fill out all Fields to enable button!"
                            : null
                      }
                    >
                      {Buttn}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Row>
  );
};

export default PropertyForm;
