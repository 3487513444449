import React, { useState } from "react";
import "./DropdownButtons.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
const DropdownButtons = (props) => {
  const [DropdownOpen, setDropdownOpen] = useState(false);
  const displaybtn = () => {
    setDropdownOpen(!DropdownOpen);
  };

  return (
    <>
      <div class="main-dropdown">
        <button
          class="mainButtonStyl mt-2"
          onClick={displaybtn}
          style={{ backgroundColor: props.color1 }}
        >
          {props.delevered}
          <KeyboardArrowDownIcon />
        </button>
        {DropdownOpen && (
          <div class="main-contentdata">
            <a

              class="Button-styleset"
              style={{ backgroundColor: props.color2 }}
            >
              {props.outgoing}
            </a>
            <a
              class="Button-styleset"
              style={{ backgroundColor: props.color3 }}
            >
              {props.pending}
            </a>
            {/* <a class="Button-styleset" style={{ backgroundColor: "green" }}>
              Green
            </a> */}
          </div>
        )}
      </div>
    </>
  );
};

export default DropdownButtons;
