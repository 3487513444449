import "./share.css";
import { Container, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Sharemodal } from "../../constants/jsonConstant";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ShareModel = ({ show, handleClosemodal, handleShowshare }) => {
  return (
    <>
      <Modal show={show} onHide={handleClosemodal} animation={false}>
        <Modal.Header closeButton>
          <h6>Share with</h6>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {Sharemodal.map((e) => (
              <Col lg="2" md="2" sm="2" xs="2">
                <div
                  className="img_hover_box cursor_P"
                  onClick={e.id === 1 && handleShowshare}
                >
                  <img src={e.icon} />
                </div>
                <p className="font_small text-center">{e.text}</p>
              </Col>
            ))}
          </Row>
          <p className="font_11 text-center ">
            <b>Or Share with</b>
          </p>
          <Row><Col lg="12" xs="12" md="12"> <p className="font_14 p-2 link_bg col-12" style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
            https://www.figma.com/file/NlfVhYygR9mAQasassdsada/Share...
            <ContentCopyIcon className="cursor_P" color="primary" />
          </p></Col></Row>
         
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShareModel;
