import PropertyAdForm from "../../../../components/forms/propertyAdform";
import { useState, useEffect } from "react";
import url from "../../../../config/axios";
import { SuccessNotify, FailedNotify } from "../../../../constants/tostify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux/es/exports";
import { publishImage } from "../../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
import Loader from "../../../../components/Loader";
const AddPersonalProperty = () => {
  const { name } = useSelector((state) => state.user);
  const [displayimg, setDisplayimg] = useState();

  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const [itemid, setItemid] = useState();
  const [shopCategory, setShopCategory] = useState();
  const [shopCategorytwo, setShopCategorytwo] = useState();
  const [data, setData] = useState({
    category: "",
    subCategoryid: ""
  })

  const Handledata = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split("."),
    }));
  };

  const categoryName = data?.category[1];
  const categoryid = data.category[0];
  const subcategoryName = data?.subCategoryid[1];
  const subcategoryid = data.subCategoryid[0];

  const Firstcategory = () => {
    url
      .get("category/consumer/?type=property&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });
  };

  const Mydata = () => {
    url
      .get(`category/consumer/?type=property&parentCategory=${categoryName}`)
      .then(async (response) => {
        console.log("jksahdf", response);
        if (response?.data?.success) {
          setShopCategorytwo(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });
  };
  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setPropertyadd((propertyadd) => ({
        ...propertyadd,
        image: abc
      }));

      setDisplayimg(abc);

    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });
  const [propertyadd, setPropertyadd] = useState({
    userid: name?._id,
    plottype: "",
    category: "",
    categoryid: "",
    subCategory: "",
    subCategoryid: "",
    country: "",
    city: "",
    state: "",
    area: "",
    price: "",
    saletype: "",
    currency: "",
    description: "",
    image: [""],
    status: true,
  });

  useEffect(() => {
    setPropertyadd((propertyadd) => ({
      ...propertyadd,
      category: categoryName,
      categoryid: categoryid,
      subCategory: subcategoryName,
      subCategoryid: subcategoryid

    }));

  }, [categoryid, categoryName, subcategoryName, subcategoryid]);


  const formSubmit = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("property/", propertyadd)
      .then(async (response) => {
        setItemid(response?.data?.data?._id);

        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Add Posted SuccesFully...");
            navigate(`/personal-add`);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed to Post Try Again!");
      });
  };
  if (isloading) {
    const formSubmit = (e) => {
      e.preventDefault();
      url
        .post("property/", propertyadd)
        .then(async (response) => {
          if (response?.data?.success) {
            setTimeout(() => {
              SuccessNotify("Add Posted SuccesFully...");
              navigate(`/personal-add/${response?.data?.data?._id}`);
            }, 3000);
          }
        })
        .catch((error) => {
          FailedNotify("Failed to Create Shop!");
        });
    };

    console.log("categoryName", categoryName);

    return (
      <div>
        <Loader heightsetting="screenHeight" />
      </div>
    );
  }
  return (
    <div>
      <PropertyAdForm
        title="Add Personal Property Category,Location,Price,Description"
        butn="Post"
        navigateTo="/SalePerosonalProperty"
        back="/SalePerosonalProperty"
        propertyadd={propertyadd}
        setPropertyadd={setPropertyadd}
        handlerFunction={formSubmit}
        itemid={itemid}
        shopCategory={shopCategory}
        shopCategorytwo={shopCategorytwo}
        Mydata={Mydata}
        Handledata={Handledata}
        Firstcategory={Firstcategory}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        displayimg={displayimg}
        setDisplayimg={setDisplayimg}
      />
    </div>
  );
};
export default AddPersonalProperty;
