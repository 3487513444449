import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/storage";
// import {getStorage} from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyDPS0J-Nx8dRou70hEhJ_twMEMeHC5PP9Y",
  authDomain: "profilepicture-ca678.firebaseapp.com",
  projectId: "profilepicture-ca678",
  storageBucket: "profilepicture-ca678.appspot.com",
  messagingSenderId: "457419543298",
  appId: "1:457419543298:web:5f1ae2eb7fd8f74d66681b",
  measurementId: "G-WN6SD6YKXQ",
};
export default firebase.initializeApp(firebaseConfig);
// export const app=firebase.initializeApp(firebaseConfig);
// Get a reference to the storage service, which is used to create references in your storage bucket
