import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "../ModalEmail/style.css";

const AccessModal = ({ handleCloseaccess, showaccess }) => {
  return (
    <>
      <Modal
        className="modal-setone" 
        centered
        show={showaccess}
        onHide={handleCloseaccess}
      >
        <Modal.Header className="modalcolor" closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body> 
          <p>
            <b>Set details for members/Admin login</b>
          </p>
          <label>
            <b>Shop ID</b>
          </label>
          <Form.Control
            className="fw-bolder number-disapper"
            placeholder="Shop ID"
            type="text"
          />
          <label>
            <b>Enter Password for Your Shop Access</b>
          </label>
          <Form.Control
            className="fw-bolder"
            placeholder="Enter Password"
            type="password"
          />

          <div className="d-flex justify-content-end mt-2 pt-1">
            <Button className="btn-primary">Give Access</Button>
          </div>
        </Modal.Body>

        {/* <Form.Control size="lg" type="text" placeholder="Large text" /> */}
      </Modal>
    </>
  );
};

export default AccessModal;
