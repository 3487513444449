import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import UserShopProfile from "../../components/UserShopProfile/UserShopProfile";
import UserItemShop from "../../components/UserShopItem/UserItemShop";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { FailedNotify, SuccessNotify } from "../../constants/tostify";
import url from "../../config/axios";
import { useState, useEffect } from "react";
const Farminguserprofile = () => {
  const navigate = useNavigate();
  const { shopid } = useParams();
  const imageUrl = useSelector((state) => state.imgurl);
  const [loading, setloading] = useState(false);
  const { name } = useSelector((state) => state.user);
  const itemData = useSelector((state) => state.itemdata);

  const newItemdata = itemData?.itemdata;
  const regular = [];
  const specialoffer = [];
  const salespromotion = [];

  // Iterate through ShopItems and categorize them
  newItemdata?.forEach((item) => {
    if (
      (!item.specialoffer || item.specialoffer === "") &&
      (!item.salespromotion || item.salespromotion === "")
    ) {
      regular.push(item);
    } else if (!item.specialoffer || item.specialoffer === "") {
      salespromotion.push(item);
    } else if (!item.salespromotion || item.salespromotion === "") {
      specialoffer.push(item);
    } else {
      specialoffer.push(item);
      salespromotion.push(item);
    }
  });


  const [lgShowreport, setLgShowreport] = useState(false);
  const [shopData, setShopData] = useState();
  const [createShop, setCreateShop] = useState({
    userid: name._id,
    problem: "",
    desciription: "",
    image: [""],
  });
  useEffect(() => {
    setCreateShop((createShop) => ({
      ...createShop,
      image: imageUrl?.imgurl || "",
    }));
  }, [imageUrl]);

  useEffect(() => {
    url.get(`shop/id?id=${shopid}`).then(async (res) => {
      setShopData(res?.data?.data?.Shop);
    });
  }, []);


  const PostShopDatas = (e) => {
    setloading(true);
    e.preventDefault();
    url
      .post("help/?", createShop)
      .then(async (response) => {
        if (response?.data?.success) {
          setloading(false);
          setLgShowreport(false);
          setTimeout(() => {
            SuccessNotify("Form Retailer Shop Created Successfully");
            navigate(`/retailer-userprofile`);
          }, 1000);
        }
      })
      .catch((error) => {
        setloading(false);
        FailedNotify("Failed To Create Shop");
      });
  };

  return (
    <Container>
      <Row>
        <Col lg="4" md="3" className="screenHeight">
          <div className="border b-radius height_100">
            <UserShopProfile
              PostShopDatas={PostShopDatas}
              setCreateShop={setCreateShop}
              lgShowreport={lgShowreport}
              setLgShowreport={setLgShowreport}
              shopData={shopData}
              shopid={shopid}
            />
          </div>
        </Col>
        <Col lg="8" md="7" className="screenHeight">
          <div className="border b-radius">
            <UserItemShop back="/Farming-online"
              shopid={shopid}
              itemData={itemData}
              regular={regular}
              specialoffer={specialoffer}
              salespromotion={salespromotion}
              newItemdata={newItemdata}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Farminguserprofile;