import "./style.css";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import { getAsset } from "../../helpers/helper";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const Candidateshowmodal = ({ lgShow, setLgShow, coverletter, cvdata }) => {
  return (
    <>
      <Modal
        size="xl"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Row>
            <Col lg="5">
              <h2 className="text-center mb-5">Cover Letter</h2>
              <p className="mb-0 font_14 text-center" style={{ wordBreak: "break-word", fontWeight: "100" }}>
                {coverletter}
              </p>
            </Col>

            <Col lg="7">
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js`}
              >
                <div>
                  <Viewer fileUrl={getAsset(cvdata)} />
                </div>
              </Worker>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Candidateshowmodal;
