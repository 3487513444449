import React from "react";
import PostAddForm from "../../../../components/forms/postItemsForm/index";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import url from "../../../../config/axios";
import { SuccessNotify } from "../../../../constants/tostify";
import { FailedNotify } from "../../../../constants/tostify";
import { useSelector } from "react-redux";
const SIngleAddPostForm = () => {
  const { name } = useSelector((state) => state.user);
  const { index } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    userid: "",
    shopid: "",
    isSingleItem: false,
    isScrapItem: false,
    itemname: "",
    category: "",
    subCategory: "",
    price: "",
    country: "",
    city: "",
    state: "",
    quantity: "",
    unitOfMeasurement: "",
    currency: {
      currencyName: "",
    },
    images: [""],
    status: true,
  });
  const Updatefunction = (e) => {
    e.preventDefault();
    url
      .put(`shopitem/id/?id=${index}`, userData)
      .then(async (response) => {
        if (response.data.success) {
          setTimeout(() => {
            SuccessNotify("Add Update Successfully");
          }, 1000);
          navigate(
            `/NewItem-bussines-Profile/${response?.data?.data?.ShopItem?.shopid}`
          );
        }
      })
      .catch((e) => {
        FailedNotify("Failed To Update Try Again!");
      });
  };

  useEffect(() => {
    url.get(`shopitem/id/?id=${index}`).then(async (response) => {
      setUserData(response?.data?.data?.ShopItem);
    });
  }, []);

  return (
    <PostAddForm
      butn="Update"
      Updatehandler={Updatefunction}
      setUserData={setUserData}
      userData={userData}
    />
  );
};

export default SIngleAddPostForm;
