import React, { useState, useEffect } from "react";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import BackArrow from "../backArrow";
import CandidatesBox from "../candidatesBox";
import { useSelector } from "react-redux";
import ShopAdds from "../shopAds/ShopAdds";
import { useParams } from "react-router-dom";
import Url from "../../config/axios"
const Connectionrequest = () => {
  const { id } = useParams();
  const [pendingData, setPendingData] = useState([]);
  const { name } = useSelector((state) => state.user);
  useEffect(() => {
   
    const fetchPendingConnections = async () => {
      try {
        const response = await Url.get(
          "conection/getAllConnected/?", 
          {
            params: {
              userid: name._id, 
              shopid:id, 
            },
          }
        );

        setPendingData(response.data);
        console.log("responseconnection", response.data);
      } catch (err) {}
    };

    fetchPendingConnections();
  }, []);
  console.log("pendingData",pendingData)
  return (
    <div>
      <Container>
        <div className="screenHeight">
          <Col lg="9" className="mx-auto">
            <Col lg="12">
              <div className="d-flex justify-content-start ">
                <BackArrow />
              </div>
              <div className="ps-2 pe-2 ">
                <ShopAdds />
              </div>
            </Col>
            <Col lg="12">
              <div className="ps-3 pe-3">
                <CandidatesBox  pendingData={pendingData}/>
              </div>
            </Col>
          </Col>
        </div>
      </Container>
    </div>
  );
};

export default Connectionrequest;
