import { Container,Form } from "react-bootstrap";
import {Paymentmethod} from "../../../../constants/jsonConstant"
import Jazzcashform from "../../../../components/Jazcash_modal";
import Easypaisaform from "../../../../components/Easypaisa_modal";
import Creditcardform from "../../../../components/shop_credit_modal";
import Bankmodalform from "../../../../components/bankmodal_form";
import Cashdeliveryform from "../../../../components/cashdelivery_modal";
import { useState } from "react";

const Setbankaccount = ({shopid}) => {

  const [openmodal,setOpenmodal]=useState(0);
  const [datavalue,setDatavalue]=useState('');

  return (
    <>
    {
      (openmodal===1 && (<Creditcardform openmodal={openmodal} setOpenmodal={setOpenmodal} shopid={shopid} datavalue={datavalue}  />)) ||
      (openmodal===2 && (<Bankmodalform openmodal={openmodal} setOpenmodal={setOpenmodal} shopid={shopid} datavalue={datavalue}  />)) ||
      (openmodal===3 && (<Cashdeliveryform openmodal={openmodal} setOpenmodal={setOpenmodal} shopid={shopid} datavalue={datavalue} />)) ||
      (openmodal===4 && (<Easypaisaform openmodal={openmodal} setOpenmodal={setOpenmodal} shopid={shopid} datavalue={datavalue}  />)) ||
       (openmodal===5 && (<Jazzcashform openmodal={openmodal} setOpenmodal={setOpenmodal} shopid={shopid} datavalue={datavalue}  />)) 
    }
      <Container>
    <Form>
    {
      Paymentmethod.map((e)=>(
        <Form.Check type="radio" aria-label="radio 1" name="method" label={e.label} value={`${e.labelvalue}`} onClick={()=>setOpenmodal(e.id)} onChange={(event)=>setDatavalue(event.target.value)} />

      ))
    }
  
    </Form>
      </Container>
    </>
  );
};

export default Setbankaccount;
