import { ShopModelCarousal } from "../../constants/jsonConstant";
import { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import Modal from "react-bootstrap/Modal";
import { SuccessNotify, FailedNotify } from "../../constants/tostify";
import "./shopViewModel.css";
import { Row, Col, Container } from "react-bootstrap";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { useNavigate, useParams } from "react-router-dom";
import Deletemodal from "../DeleteModal";
import url from "../../config/axios";
import moment from "moment";
import Loader from "../Loader";
import BackArrow from "../backArrow";
import { useSelector } from "react-redux";
import ShareModel from "../Sharemodel";
import ShareOption from "../Shareoption";
import RedoIcon from "@mui/icons-material/Redo";
import { getAsset } from "../../helpers/helper";

const OwnerPropertyViewModel = ({
  update,
  deleteurl,
  setItemshow,
  setShowmodal,
}) => {
  const { id } = useParams();
  const [showshare, setShowshare] = useState(0);
  const [show, setShow] = useState(0);
  const [shows, setShows] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const handleClose = () => setShows(0);
  const navigate = useNavigate();
  const [singleitem, setSingleitem] = useState();
  const { name } = useSelector((state) => state.user);
  const handleCloseshare = () => {
    setShowshare(0);
    setShow(1);
  };

  const handleClosemodal = () => {
    setShow(0);
  };

  const handleShowshare = () => {
    setShow(0);
    setShowshare(1);
  };

  useEffect(() => {
    const img = document.querySelectorAll(".image-gallery-image");

    img.forEach((element) => {
      element?.setAttribute("crossorigin", "anonymous");
    });
  }, []);

  useEffect(() => {
    const thumimgs = document.querySelectorAll(
      ".image-gallery-thumbnail-image"
    );

    thumimgs.forEach((element) => {
      element?.setAttribute("crossorigin", "anonymous");
    });
  }, []);

  const ShopModelCarousal = [
    {
      id: 1,
      original: getAsset(singleitem?.image[0]),
      thumbnail: getAsset(singleitem?.image[0]),
    },
    {
      id: 2,
      original: getAsset(singleitem?.image[1]),
      thumbnail: getAsset(singleitem?.image[1]),
    },
    {
      id: 3,
      original: getAsset(singleitem?.image[2]),
      thumbnail: getAsset(singleitem?.image[2]),
    },
    {
      id: 4,
      original: getAsset(singleitem?.image[3]),
      thumbnail: getAsset(singleitem?.image[3]),
    },
    {
      id: 5,
      original: getAsset(singleitem?.image[4]),
      thumbnail: getAsset(singleitem?.image[4]),
    },
  ];  

  useEffect(() => {
    url.get(`property/id/?id=${id}`).then(async (response) => {
      setSingleitem(response?.data?.data?.Property);
    });
  }, []);

  const Deleteitem = () => {
    url
      .delete(`property/?id=${id}`)
      .then(async (response) => {
        if (response.data.success) {
          SuccessNotify("Item Deleted Successfully");
          setShows(0);
          setShowmodal(0);
          navigate(`${deleteurl}${id}`);
          window.location.reload(false);
        }
      })
      .catch((e) => {
        FailedNotify("Failed to Delete Try Again!");
        setShows(0);
      });
  };

  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }

  return (
    <>
      {shows === 1 && (
        <Deletemodal
          shows={shows}
          title="Item"
          handleClose={handleClose}
          animation={true}
          handledelete={Deleteitem}
        />
      )}

      <ShareOption showshare={showshare} handleCloseshare={handleCloseshare} />
      <ShareModel
        handleClosemodal={handleClosemodal}
        show={show}
        handleShowshare={handleShowshare}
      />

      <Container>
        <div className="screenHeight">
          <div className="ps-5">
            <p>Posted on {moment(singleitem?.duration).format("DD-MM-YYYY")}</p>
          </div>
          <Row>
            <Col lg="6">
              <div className="row">
                <ImageGallery
                  showPlayButton={false}
                  showFullscreenButton={false}
                  items={ShopModelCarousal}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="ps-4 pe-4 pt-4 pb-3 bg-vilot View-Model-Shadow">
                <Row>
                  <Col lg="6">
                    <div className="ms-5">
                      <BackArrow />
                    </div>
                  </Col>
                  <Col lg="6" className="text-center">
                    <button
                      className="text-white bg-blue border-0 font_12 rounded mt-2"
                      onClick={() => setShow(1)}
                    >
                      Share <RedoIcon className="share_icon_f" />
                    </button>
                  </Col>
                </Row>

                <div className="px-5">
                  <h4 className="fw-bold">{singleitem?.subCategory}</h4>
                  <p className="col-10 fw-lighter description-font-size">
                    {singleitem?.description}
                  </p>

                  <div>
                    <h3 className="font-blue">
                      Price: {singleitem?.currency.slice(0, 3)}{" "}
                      {singleitem?.price}
                    </h3>
                  </div>

                  <div>
                    <div>
                      <p className="mb-0 fw-bold pt-1 pb-1">
                        Residential/Plot/Commercial
                      </p>
                      <Row>
                        <Col lg={1}>
                          <input type="radio" checked />
                        </Col>
                        <Col lg={10} className="ps-1">
                          <label className="fw-light">
                            {singleitem?.plottype}
                          </label>
                        </Col>
                      </Row>
                      <div>
                        <p className="mb-0 fw-bold pt-1 pb-1">
                          {" "}
                          Rent/Buy/Lease
                        </p>
                      </div>
                      <Row>
                        <Col lg={10} className="ps-1">
                          <label className="fw-light">
                            {singleitem?.saletype}
                          </label>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <h5>Contact-Us On</h5>
                        <h6>{name.phonenumber}</h6>
                        <h6>{name.email}</h6>
                      </Row>
                    </div>
                  </div>
                  <div className="pt-4 d-flex">
                    <button
                      className="d-flex align-items-center border rounded ps-3 pe-3 pt-1 pb-1 bg-red text-white"
                      onClick={() => setShows(1)}
                    >
                      <DeleteOutlineOutlinedIcon />
                      <span className="ps-1">Delete</span>
                    </button>
                    <button
                      className="d-flex align-items-center border rounded ps-4 pe-4 pt-1 pb-1 bg-blue ms-3 me-3 text-white"
                      onClick={() => navigate(`${update}/${id}`)}
                    >
                      <CreateOutlinedIcon />
                      <span className="ps-1">Edit</span>
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default OwnerPropertyViewModel;
