import React, { useState } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Col,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { images } from "../../../../constants";
import { MenuLeftbar } from "../../../../constants/jsonConstant";
import { MenuLeftbar2 } from "../../../../constants/jsonConstant";
import { MenuLeftbar3 } from "../../../../constants/jsonConstant";
import { MenuLeftbar4 } from "../../../../constants/jsonConstant";
import { MenuLeftbar5 } from "../../../../constants/jsonConstant";
import { useNavigate } from "react-router-dom"
import "./style.css";
import CloseIcon from '@mui/icons-material/Close';
function Leftbar({handleShow,handleShowm,handleShowc}) {
  const [openDropdownId, setOpenDropdownId] = useState(null);

  const handleDropdownToggle = (itemId) => {
    setOpenDropdownId(openDropdownId === itemId ? null : itemId);
  };


  const handleOptionClick = (option) => {
  };

  const [isOpen, setIsOpen] = useState(false);

  function handleClick() {
    setIsOpen(true);
  }

  const [hamburgeropen, setHamburgeropen] = useState(false);
  function handleClick() {
    setIsOpen(true);
  }

  const navigate = useNavigate();

  return (
    <>
     
   
          <div className="desktop-version">
            <div className="class-height-leftbar ">
              <Row lg='12'>
                <Col lg="12" md="6">
                  <div onClick={() => navigate("/compose-mail")} className="compose mb-0 d-flex align-items-center justify-content-center">
                    <img
                      className="text-bg-light"
                      src={images.plusicon}
                      alt="Compose icon"
                    />
                    <p className="pt-3">Compose</p>
                  </div>
                  <div className="colr-one ">
                    <div className="leftScroll noscroll">
                      <div className=" pt-1  ">
                        <h4 className="ps-3 pt-3 Colr">Emails :</h4>
                        {MenuLeftbar.map((item) =>

                          item.id === 12 ? (

                            <Row className="menu-item-cursor" onClick={() => navigate(item.route)}>

                              <Col lg="1" md="2" >
                                <img className="menu-item" src={item.icon} />
                              </Col>

                              <Col lg='10 pe-0'>
                                <div><p className="imt-set ps-3 pt-2">{item.text}</p></div>
                              </Col>
                              <div className="atd-cla">
                                <hr className="m-1" />
                              </div>
                            </Row>
                          ) : (
                            <>
                              <Row className="menu-item-cursor" onClick={() => navigate(item.route)}>
                                <Col lg="1" md="2">
                                  <img className="menu-item" src={item.icon} />
                                </Col>
                                <Col className="pe-0 ms-3" lg="7" md="7">
                                  <p className="imt-set pt-2">{item.text}</p>


                                </Col>
                                <Col className="act-one ps-0 pe-0" lg="1" md="1">
                                  {
                                    item.id === 10 || item.id === 11 || item.id === 12 ? ("") : (<div> 23 </div>)
                                  }


                                </Col>


{/* 
                                <Col className="ps-0" lg="1" md="1" >
                                  {item.id === 2 ||
                                    item.id === 3
                                    ? (
                                      <div
                                        style={{
                                          position: "relative",
                                          display: "inline-block",
                                        }}
                                      >

                                      </div>

                                    ) : (
                                      ""
                                    )}
                                </Col> */}
                              </Row>



                              <Col lg="12" md="12">

                                {item.id === 9 || item.id === 12 ? (
                                  <div className="atd-cla">
                                    <hr className="mt-1 mb-1" />
                                  </div>
                                ) : null}
                              </Col>
                            </>
                          ))}

                      </div>

                      <div className=" pt-1  ">
                        <h4 className="ps-3 pt-3 Colr">B2B Buying :</h4>
                        {MenuLeftbar2.map((item) =>

                        (
                          <>
                            <Row className="menu-item-cursor" onClick={() => navigate(item.route)}>
                              <Col lg="1" md="2">
                                <img className="menu-item mb-2" src={item.icon} />
                              </Col>
                              <Col className="pe-0 ms-3" lg="7" md="7">
                                <p className="imt-sets pt-2 d-flex align-items-center">{item.text}</p>
                                <p className="imtx-sets pt-2">{item.btn}</p>
                              </Col>
                              <Col className="act-one ps-0 pe-0" lg="1" md="1">
                                <div> 23 </div>

                              </Col>

                            </Row>


                            <Col lg="12" md="12">

                            </Col>

                          </>
                        ))}
                        <h4 className="ps-3 pt-2 Colr">B2B Selling :</h4>
                        {MenuLeftbar3.map((item) =>

                        (
                          <>
                            <Row className="menu-item-cursor" onClick={() => navigate(item.route)}>
                              <Col lg="1" md="2">
                                <img className="menu-item mb-2" src={item.icon} />
                              </Col>
                              <Col className="pe-0 ms-3" lg="7" md="7">
                                <p className="imt-sets pt-2">{item.text}</p>
                                <p className="imtx-sets pt-2">{item.btn}</p>
                              </Col>
                              <Col className="act-one ps-0 pe-0" lg="1" md="1">
                                <div> 23 </div>

                              </Col>

                            </Row>


                            <Col lg="12" md="12">

                            </Col>

                          </>
                        ))}
                        <div className="atd-cla">
                          <hr className="mt-1 mb-1" />
                        </div>
                        {MenuLeftbar4.map((item) =>

                        (
                          <>
                            <Row className="menu-item-cursor" onClick={(item.id===1 && handleShow) || (item.id===2 && handleShowm) || (item.id===3 && handleShowc)}  >
                              <Col lg="1" md="2">
                                <img className="menu-item" src={item.icon} />
                              </Col>
                              <Col className="pe-0 ms-3" lg="7" md="7">
                                <p className="imts-sets pt-2">{item.text}</p>

                                {/* <p className="imt-sets pt-2">{item.btn}</p> */}
                              </Col>
                              {/* <Col className="act-one ps-0 pe-0" lg="1" md="1">
        <div> 23 </div>

      </Col> */}

                            </Row>


                            <Col lg="12" md="12">

                            </Col>

                          </>
                        ))}
                        <div className="atd-cla">
                          <hr className="mt-1 mb-1" />
                        </div>
                        {MenuLeftbar5.map((item) =>

                        (
                          <>
                               <Row className="menu-item-cursor" onClick={() => navigate(item.route)}>
                              <Col lg="1" md="2">
                                <img className="menu-item" src={item.icon} />
                              </Col>
                              <Col className="pe-0 ms-3" lg="7" md="7">
                                <p className="imt-sets pt-2">{item.text}</p>





                                <p className="imt-sets pt-2">{item.btn}</p>
                              </Col>
                              <Col className="act-one ps-0 pe-0" lg="1" md="1">
                                <div>  </div>

                              </Col>

                            </Row>


                            <Col lg="12" md="12">
                            
                            </Col>

                          </>
                        ))}

                      </div>
                    </div>


                  </div>

                </Col>
              </Row>
            </div>
          </div>


          <div className="mob-version">
            <button className="openbtn" onClick={() => setHamburgeropen(true)}>☰ Sidebar</button>
            {hamburgeropen === true &&
              <div id="mySidebar" class="">

                <div className="class-height-leftbar  sidebar">
                  <Row >
                    <Col lg="12" md="12" onSubmit="12">
                      <div className="compose mb-0 d-flex align-items-center justify-content-start">
                      <CloseIcon onClick={() => setHamburgeropen(false)} className="ICON me-3" />
                        <img
                          className="text-bg-light"
                          src={images.plusicon}
                          alt="Compose icon"
                        />
                        <p className="pt-3">Compose</p>
                       
                      </div>

                      <div className="colr-one">
                        <div className=" pt-1  ">
                          <h4 className="ps-3 pt-3 Colr">Emails :</h4>
                          {MenuLeftbar.map((item) =>

                            item.id === 12 ? (

                              <Row className="menu-item-cursor" onClick={() => navigate(item.route)}>

                                <Col lg="1" md="1" sm="1" xs="1" >
                                  <img className="menu-item" src={item.icon} />
                                </Col>

                                <Col lg='10 pe-0' md="10" sm="10" xs="10">
                                  <div><p className="imt-set ps-3 pt-2">{item.text}</p></div>
                                </Col>
                                <div className="atd-cla">
                                  <hr className="m-1" />
                                </div>
                              </Row>
                            ) : (
                              <>
                                <Row className="menu-item-cursor" onClick={() => navigate(item.route)}>
                                  <Col lg="1" md="1" sm="1" xs="1">
                                    <img className="menu-item" src={item.icon} />
                                  </Col>
                                  <Col className="pe-0 ms-3" lg="8" md="8" sm="8" xs="8">
                                    <p className="imt-set pt-2">{item.text}</p>


                                  </Col>
                                  <Col className="act-one ps-0 pe-0" lg="1" md="1" sm="1" xs="1">
                                    {
                                      item.id === 10 || item.id === 11 || item.id === 12 ? ("") : (<div> 23 </div>)
                                    }


                                  </Col>



                                  {/* <Col className="ps-0" lg="1" md="1" >
                                    {item.id === 2 ||
                                      item.id === 3
                                      ? (
                                        <div
                                          style={{
                                            position: "relative",
                                            display: "inline-block",
                                          }}
                                        >

                                        </div>

                                      ) : (
                                        ""
                                      )}
                                  </Col> */}
                                </Row>



                                <Col lg="12" md="12">

                                  {item.id === 9 || item.id === 12 ? (
                                    <div className="atd-cla">
                                      <hr className="mt-1 mb-1" />
                                    </div>
                                  ) : null}
                                </Col>
                              </>
                            ))}

                        </div>

                        <div className=" pt-1  ">
                          <h4 className="ps-3 pt-3 Colr">B2B Buying :</h4>
                          {MenuLeftbar2.map((item) =>

                          (
                            <>
                              <Row className="menu-item-cursor" onClick={() => navigate(item.route)}>
                                <Col lg="1" md="1" sm="1" xs="1">
                                  <img className="menu-item" src={item.icon} />
                                </Col>
                                <Col className="pe-0 ms-3" lg="7" md="8" sm="8" xs="8">
                                  <p className="imt-sets pt-2">{item.text}</p>





                                  <p className="imtx-sets pt-2">{item.btn}</p>
                                </Col>
                                <Col className="act-one ps-0 pe-0" lg="1" md="1" sm="1" xs="1">
                                  <div> 23 </div>

                                </Col>

                              </Row>


                              <Col lg="12" md="12">

                              </Col>

                            </>
                          ))}
                          <h4 className="ps-3 pt-2 Colr">B2B Selling :</h4>
                          {MenuLeftbar3.map((item) =>

                          (
                            <>
                              <Row className="menu-item-cursor" onClick={() => navigate(item.route)}>
                                <Col lg="1" md="1" sm="1" xs="1">
                                  <img className="menu-item" src={item.icon} />
                                </Col>
                                <Col className="pe-0 ms-3" lg="7" md="8" sm="8" xs="8">
                                  <p className="imt-sets pt-2">{item.text}</p>





                                  <p className="imtx-sets pt-2">{item.btn}</p>
                                </Col>
                                <Col className="act-one ps-0 pe-0" lg="1" md="1" sm="1" xs="1">
                                  <div> 23 </div>

                                </Col>

                              </Row>


                              <Col lg="12" md="12">

                              </Col>

                            </>
                          ))}
                          <div className="atd-cla">
                            <hr className="mt-1 mb-1" />
                          </div>
                          {MenuLeftbar4.map((item) =>

                          (
                            <>
                              <Row className="menu-item-cursor" onClick={() => navigate(item.route)}>
                                <Col lg="1" md="1" sm="1" xs="1">
                                  <img className="menu-item" src={item.icon} />
                                </Col>
                                <Col className="pe-0 ms-3" lg="7" md="8" sm="8" xs="8">
                                  <p className="imts-sets pt-2">{item.text}</p>

                                  {/* <p className="imt-sets pt-2">{item.btn}</p> */}
                                </Col>
                                {/* <Col className="act-one ps-0 pe-0" lg="1" md="1">
        <div> 23 </div>

      </Col> */}

                              </Row>


                              <Col lg="12" md="12">

                              </Col>

                            </>
                          ))}
                          <div className="atd-cla">
                            <hr className="mt-1 mb-1" />
                          </div>
                          {MenuLeftbar5.map((item) =>

                          (
                            <>
                                <Row className="menu-item-cursor" onClick={() => navigate(item.route)}>
                              <Col lg="1" md="1" sm="1" xs="1">
                                <img className="menu-item" src={item.icon} />
                              </Col>
                              <Col className="pe-0 ms-3" lg="7" md="8" sm="8" xs="8" >
                                <p className="imt-sets pt-2">{item.text}</p>





                                <p className="imt-sets pt-2">{item.btn}</p>
                              </Col>
                              <Col className="act-one ps-0 pe-0" lg="1" md="1">
                                <div>  </div>

                              </Col>

                            </Row>
                           

                              <Col lg="12" md="12">

                              </Col>

                            </>
                          ))}

                        </div>

                      </div>

                    </Col>
                  </Row>
                </div>
              </div>
            }
          </div>



       
     

    </>
  );
}

export default Leftbar;


