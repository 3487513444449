import { images } from "../../../../constants";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import CallIcon from "@mui/icons-material/Call";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { useState, useEffect } from "react";
import url from "../../../../config/axios";
import { useSelector } from "react-redux";
import ReportIcon from "@mui/icons-material/Report";
import SettingsIcon from "@mui/icons-material/Settings";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import "./companySidebar.css";
import Qrmodal from "../../../../components/QRmodal/Qrmodal";
import StarIcon from "@mui/icons-material/Star";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Desmodal from "../../../../components/Des_Modal";
import { getAsset } from "../../../../helpers/helper";
const CompanySideBar = () => {
  const [showmodal, setShowmodal] = useState(false);
  const closemodal = () => setShowmodal(false);
  const { name } = useSelector((state) => state.user);
  const [companyData, setCompanyData] = useState();
  const [desshow, setDesshow] = useState(false);

  const handleClosedes = () => setDesshow(false);
  const handleShowdes = () => setDesshow(true);
  const { id } = useParams();
  const navigate = useNavigate();


  useEffect(() => {
    url.get(`/company/id/?id=${id}`).then(async (response) => {
      console.log("Data", response);
      setCompanyData(response?.data?.data?.Company);
    });
  }, []);
  localStorage.getItem("contactnumber");
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Click on address to view map
    </Tooltip>
  );

  return (
    <Row>
   <Desmodal
        desshow={desshow}
        handleClosedes={handleClosedes}
        des={companyData?.description}
      />
      <Qrmodal show={showmodal} handleClosemodal={closemodal} />
      <Col
        className="shop-profile-box  pt-1 px-3  ms-0 me-0 screenHeight"
        md={12}
        lg={12}
      >
        <div
          className="d-flex justify-content-center pb-2"
          onClick={() => navigate("/update-company/" + id)}
        >
          <img className="w-img" src={images.UpdateShop} alt="UpdateShop" />
          <h6 className="mb-0 ps-2 pt-1 updateSize">Update Company Profile</h6>
        </div>
        <div>
          <img
            className="w-100 shop-profile-img"
            src={getAsset(companyData?.image)}
            alt=""
            crossOrigin="anonymous"
          />
        </div>
        <div className="box-container mt-1">
          <Row>
            <Col lg="10" className="pe-0">
              <div className="pt-2 pb-1">
                <h4 className="shop-Profile-font mb-0 pb-1 ">
                  {companyData?.companyname}
                </h4>
                <Col lg="8">
              <p className="shop-profile-para mb-0 d-flex" >
                <div>{companyData?.description.slice(0, 25)}</div>
                <div>
                  {companyData?.description.length > 25 && (
                    <p
                      onClick={handleShowdes}
                      className="mb-0 cursor_P font_11 text-dark" style={{marginTop:"3px"}}
                    >
                   &nbsp;<b>ReadMore...</b>
                    </p>
                  )}
                </div>
              </p>
            </Col>
              </div>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <div className="d-flex align-items-center">
                  <LocationCityIcon className="silver-icon" />
                  <p className="mb-0 ViewlabelText pt-1 pb-1 ps-2">
                    {companyData?.companyaddress}
                  </p>
                </div>
              </OverlayTrigger>
            </Col>
            <Col lg="2">
              <div className="d-flex justify-content-end">
                <QrCodeScannerIcon onClick={() => setShowmodal(true)} />
              </div>
            </Col>
          </Row>
        </div>
        {/* details */}
        <div>
          <div>
            <div className="d-flex">
              <div className="">
                <div className="d-flex align-items-center pt-1 pb-2">
                  <StarIcon className="silver-icon" />
                  <p className="mb-0 ViewlabelText ps-2">4.5</p>
                </div>
                <div className="d-flex align-items-center pt-1 pb-2">
                  <CallIcon className="silver-icon" />
                  <p className="mb-0 ViewlabelText ps-2">{name?.phonenumber}</p>
                </div>
                <div className="d-flex align-items-center pt-1 pb-2 ">
                  <ThumbUpIcon className="silver-icon" />
                  <p className="mb-0 ViewlabelText ps-2">Liked by 250 </p>
                </div>
                <div className="d-flex align-items-center pt-1 pb-2">
                  <ThumbDownIcon className="silver-icon" />
                  <p className="mb-0 ViewlabelText ps-2">Unliked by 45 </p>
                </div>
                {/* <div className="d-flex align-items-center pt-1 pb-2">
                  <NotificationsIcon className="silver-icon" />
                  <p className="mb-0 ViewlabelText ps-2">Suggestions</p>
                </div> */}
                <div
                  className="d-flex align-items-center cursor_P pb-2"
                  // onClick={() => setSuggest(2)}
                >
                  <ReportIcon className="silver-icon" />
                  <p className="mb-0 ViewlabelText ps-2">Report</p>
                </div>
                <div
                  className="d-flex align-items-center pb-2 cursor_P"
                  onClick={() => navigate("/company-setting")}
                >
                  <SettingsIcon className="silver-icon" />
                  <p className="mb-0 ViewlabelText ps-2">Setting</p>
                </div>
                {/* <div>
                  <h4>Available payment methods</h4>
                  <div className="d-flex justify-content-between">

                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="img-height">
          <img src={images.ShopProfile} alt="" className="h-100 w-100" />
        </div> */}
      </Col>
    </Row>
  );
};
export default CompanySideBar;
