import { Container, Col, Row } from "react-bootstrap";
// import { useState } from "react";
import { images } from "../../constants";
import Rating from "@mui/material/Rating";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { transportbtn, Transportdetail } from "../../constants/jsonConstant";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import BackArrow from "../../components/backArrow";
import Modal from "react-bootstrap/Modal";
import { Vehicaldetail, Deletemodal } from "../../pages/index";
import { useNavigate, useParams } from "react-router-dom";
import url from "../../config/axios";
import { useSelector } from "react-redux";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import MessageIcon from "@mui/icons-material/Message";
import ErrorIcon from "@mui/icons-material/Error";
import CampaignIcon from "@mui/icons-material/Campaign";
import LoopIcon from "@mui/icons-material/Loop";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { SuccessNotify } from "../../constants/tostify";
import { FailedNotify } from "../../constants/tostify";
import "./Forms/form.css";
import Qrmodal from "../../components/QRmodal/Qrmodal";
import { getAsset } from "../../helpers/helper";
const Transportprofile = () => {
  const [isloading, setIsloading] = useState(false);
  const { id } = useParams();
  const [shopData, setShopData] = useState();
  const [carsData, setCarData] = useState();
  const [smShow, setSmShow] = useState(false);
  const { name } = useSelector((state) => state.user);

  useEffect(() => {
    url.get(`shop/id?id=${id}`).then(async (res) => {
      setShopData(res?.data?.data?.Shop);

    });
  }, []);
  useEffect(() => {
    url.get(`/tranportvehicle/?shopid=${id}`).then(async (res) => {
      setCarData(res?.data?.data?.TransportVehicles);

    });
  }, []);
  const handeldelete = () => {
    setIsloading(true);
    url
      .delete(`shop/?id=${id}`)
      .then((res) => {
        if (res.data.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify(" profile Deleted ");
          }, 1000);
          navigate("/create-transport");
        }
      })
      .catch((e) => {
        FailedNotify("Failed to delete");
      });
  };
  const navigate = useNavigate();
  const [value] = useState(3);
  const [key, setKey] = useState("home");
  const [lgShow, setLgShow] = useState(0);

  const [show, setShow] = useState(0);
  const handleShow = () => setShow(1);
  const handleClose = () => setShow(0);
  const onHide = () => setSmShow(false);

  return (
    <>
      <Qrmodal show={smShow} handleClosemodal={onHide} />
      {show === 1 && (
        <Deletemodal
          show={show}
          title="Profile"
          handleClose={handleClose}
          handledelete={handeldelete}
          id={id}
        />
      )}
      <Container>
        {/* <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(0)}
          aria-labelledby="example-modal-sizes-title-lg"
          className="p-2"
        >
          <Vehicaldetail singleheight="" />
        </Modal> */}

        <Col
          lg="9"
          xl="8"
          className="border_2 b-radius mx-auto screenHeight ps-4 pe-4"
        >
          <div className="pt-1 backarrowSet">
            <BackArrow back={`/business-transport/${id}`} mystyle="ps-4" />
          </div>

          <Row className="main_rowset">
            <Col lg="4" xl="4">
              <div className="img_fixxx mt-2">
                <img
                  src={getAsset(shopData?.image)}
                  alt=""
                  style={{ width: "100%" }}
                  className="transport_imgggg"
                  crossOrigin="anonymous"
                />
              </div>
            </Col>
            {/* <Col lg="8 ms-2">
              <h2 className="font-bold mt-5">{shopData?.shopname}</h2>
              <p className="mb-1 font-sm-15 spaces">{shopData?.shopaddress}</p>
              <p className="mb-1 font-sm spaces">{name?.phonenumber}</p>
              <p className="mb-1 font-sm spaces">{name?.email}</p>
              <h6 className="mb-1 spaces">{shopData?.shopCategory}</h6>

              <Rating name="read-only" value={value} readOnly />
              <p className="mb-1 font_small">
                <Typography component="legend" className="font_small">
                  <b>3.6</b>
                </Typography>
              </p>
              <Row>
                {transportbtn.map((e) => (
                  <Col lg="3 ps-0 pe-0" md="3">
                    <button
                      className="font_small transport-btn text-white b-radius"
                      onClick={() =>
                        (e.id === 1 ? handleShow(1) : "") ||
                        (e.id === 4 ? navigate(`/post-vehicle/` + id) : "") ||
                        (e.id === 2 ? navigate(`/update-transport/` + id) : "")
                      }
                      style={{ background: e.color }}
                    >
                      {e.icon} {e.title}
                    </button>
                  </Col>
                ))}
              </Row>
            </Col> */}
            <Col lg="8 mt-4" xl="8">
              <div className="main-div">
                <div className="raja_divset">
                  <h1 className="marginset raja_m">{shopData?.shopname}</h1>
                  <p className="marginset">
                    <b>Shop ID:</b>
                    {shopData?.shopid}
                  </p>
                  <p className="marginset mb-1">{shopData?.shopaddress}</p>
                  <p className="marginset d-flex  mb-1">
                    <b>3.6</b>
                    <StarIcon style={{ color: "#E9A71D" }} />
                    <StarIcon style={{ color: "#E9A71D" }} />
                    <StarIcon style={{ color: "#E9A71D" }} />
                    <StarBorderIcon />
                    <StarBorderIcon />
                  </p>
                  {/* <p className="marginset d-flex ">
                    <StarIcon />
                    <span className="mx-2">4.5 </span>
                  </p> */}
                </div>
                <div className="cursor_P">
                  <QrCode2Icon
                    style={{ fontSize: "39px" }}
                    onClick={() => setSmShow(true)}
                  />
                </div>
              </div>
              <div className="main-div2">
                <div className="suggestion-div">
                  <p
                    className="marginset  mb-1 cursor_P"
                    onClick={() => navigate(`/transport-suggestion/${shopData?.shopid}`)}
                  >
                    <MessageIcon style={{ fontSize: "16px" }} />
                    <span className="mx-2">Suggetions</span>
                  </p>
                  <p
                    className="marginset  mb-1 cursor_P"
                    onClick={() => navigate("/Transport-Report-section")}
                  >
                    <ErrorIcon style={{ fontSize: "19px" }} />
                    <span className="mx-2 ">Reports</span>
                  </p>
                  <p
                    className="marginset cursor_P"
                    onClick={() => navigate("/promote-transport")}
                  >
                    <CampaignIcon style={{ fontSize: "21px" }} />
                    <span className="mx-2">Promote Shop</span>
                  </p>
                </div>
                <div className="d-flex flex-column align-items-center small_screenset">
                  <button
                    className="update_buttn"
                    onClick={() => navigate(`/update-transport/${id}`)}
                  >
                    <LoopIcon style={{ fontSize: "20px" }} /> &nbsp; Update
                  </button>
                  <button
                    className="addmore_buttn"
                    onClick={() => navigate(`/transport-addmore-form/${id}`)}
                  >
                    <AddCircleIcon style={{ fontSize: "20px" }} /> &nbsp; add
                    more
                  </button>
                </div>
                <div className="d-flex flex-column align-items-center  mt-1  ">
                  <button className="delete_buttn" onClick={() => setShow(1)}>
                    <DeleteIcon style={{ fontSize: "20px" }} /> &nbsp; Delete
                  </button>
                  <button
                    className="dashboard_buttn"
                    onClick={() => navigate("/homemade-items-shops")}
                  >
                    Bussiness Dashboard
                  </button>
                </div>
              </div>
            </Col>
          </Row>

          <Col className="" lg="12 mt-2">
            <span className="live_lcc">
              <button className="sharelctnbtn">
                <LocationOnIcon style={{ color: "#0353A4" }} /> share live
                location
              </button>
            </span>
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3 ms-1 display-setting font_small"
            >
              <Tab eventKey="home" title="Service">
                <Row className="t-scroll noscroll">
                  <Row className="pe-0">
                    {carsData?.map((cars) => (
                      <>
                        <Col lg="4" className="mt-2 imageheightset">
                          <div className="img_container">
                            <img
                              src={getAsset(cars?.images)}
                              alt=""
                              className="food_img"
                              crossOrigin="anonymous"
                            />
                          </div>
                        </Col>

                        <Col lg="8" className="border_2 b-radius mt-2">
                          <Row className="mt-2">
                            <Col lg="5">
                              <h4 className="font-bold">{cars?.brandName}</h4>
                            </Col>
                            <Col lg="5">{/* <h5>{e.title}</h5> */}</Col>
                            <Col lg="2">
                              <button
                                className="viewshop"
                                onClick={() =>
                                  navigate(
                                    `/vehicaldetail/${cars?._id}/${cars?.shopid}`
                                  )
                                }
                              >
                                View
                              </button>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col lg="8">
                              <p>
                                <b>Description</b>
                              </p>
                              <p className="font-grey">{cars?.description}</p>
                            </Col>
                            <Col lg="4" className="d-flex align-items-end mb-3">
                              <Typography component="legend">Rating</Typography>{" "}
                              <Rating name="read-only" value={value} readOnly />
                              <p className="mb-1 font_small"></p>
                            </Col>
                          </Row>
                        </Col>
                      </>
                    ))}
                  </Row>
                </Row>
              </Tab>
              {/* <Tab eventKey="profile" title="Followers">
                  Followers
                </Tab>
                <Tab eventKey="contact" title="Following">
                  Following
                </Tab> */}
            </Tabs>
          </Col>
        </Col>
      </Container>
    </>
  );
};
export default Transportprofile;
