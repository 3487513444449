import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Calendar from "react-calendar";
import { useRef, useEffect, useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { images } from "../../../../constants";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./style.css";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

function ModalSchedule({ mettingb, handleClose }) {
  const [value, onChange] = useState(new Date());
  const [open, setOpen] = useState(true);
  const calendarRef = useRef();
  const handleDateChange = (date) => {
    onChange(date);
    setOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarRef]);

  const [inputValue, setInputValue] = useState("");
  const [myvalue, setMyvalue] = useState();
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const [showTable, setShowTable] = useState(false);

  const [showdate, setShowdate] = useState(false);
  const tableRef = useRef(null);

  function handleButtonClick() {
    setShowdate(true);
  }

  function handleClickOutside(event) {
    if (tableRef.current && !tableRef.current.contains(event.target)) {
      setShowTable(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //second Time

  const [showTimePicker, setShowTimePicker] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const timePickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        timePickerRef.current &&
        !timePickerRef.current.contains(event.target)
      ) {
        setShowTimePicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [timePickerRef]);

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const toggleTimePicker = () => {
    setShowTimePicker((prevState) => !prevState);
    setShowDate(false);
  };

  // const hours = Array.from({length: 25}, (_, i) => i);
  // const minutes = Array.from({length: 61}, (_, i) => i);

  // const [selectedHour, setSelectedHour] = useState('00');
  // const [selectedMinute, setSelectedMinute] = useState('00');

  // const handleHourChange = (event) => {
  //   setSelectedHour(event.target.value);
  // };

  // const handleMinuteChange = (event) => {
  //   setSelectedMinute(event.target.value);
  // };

  const hours = Array.from({ length: 25 }, (_, i) => i);
  const minutes = Array.from({ length: 61 }, (_, i) => i);

  const [selectedHour, setSelectedHour] = useState("00");
  const [selectedMinute, setSelectedMinute] = useState("00");
  const [showDate, setShowDate] = useState(false);

  const handleHourChange = (event) => {
    setSelectedHour(event.target.value);
  };

  const handleMinuteChange = (event) => {
    setSelectedMinute(event.target.value);
  };

  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowDate(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [containerRef]);

  const [activeone, setActiveone] = useState(1);
  const [activetwo, setActivetwo] = useState(0);
  const [activethree, setActivethree] = useState(0);

  const Activehandler = () => {
    setActiveone(1);
    setActivetwo(0);
    setActivethree(0);
  };

  const Activehandlerone = () => {
    setActiveone(0);
    setActivetwo(1);
    setActivethree(0);
  };

  const Activehandlertwo = () => {
    setActiveone(0);
    setActivetwo(0);
    setActivethree(1);
  };

  return (
    <Container>
      <div
        className="modal-show "
        style={{ display: "block", position: "center" }}
        // onBlur={()=>setShowdate(false)}
      >
        <div className="modal-showw">
          <Modal show={mettingb} onHide={handleClose}>
            <KeyboardBackspaceIcon onClick={handleClose} className="Arrow" />
            <div className="mdol-seet">
              <Modal.Dialog>
                <Modal.Header>
                  <div className="d-flex justify-content-center align-items-center m-auto">
                    <img className="pe-4" src={images.Meting}></img>

                    <Modal.Title>Schedule Meeting</Modal.Title>
                  </div>
                </Modal.Header>

                <Modal.Header>
                  <div className="d-flex align-items-center ms-3">
                    <img className="pe-4" src={images.Edit_pen} />
                    <p className="mb-0 ">Title</p>
                  </div>
                </Modal.Header>
                <Row>
                  <Col
                    onClick={() => {
                      setOpen(true);
                      setShowDate(false);
                    }}
                    className="d-flex justify-content-center align-items-center pb-2 mt-2"
                    lg="4"
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      className={
                        activeone === 1
                          ? "schedule-icon-active"
                          : "schedule-icon"
                      }
                      onClick={Activehandler}
                    >
                      <CalendarMonthIcon />
                      <span style={{ fontSize: "14px" }} className="ms-3">
                        Date
                      </span>
                    </div>
                  </Col>

                  <Col
                    onClick={toggleTimePicker}
                    className="d-flex justify-content-center align-items-center pb-2 mt-2 "
                    lg="4"
                    xs={12}
                    md={4}
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      className={
                        activetwo === 1
                          ? "schedule-icon-active"
                          : "schedule-icon"
                      }
                      onClick={Activehandlerone}
                    >
                      <AccessTimeFilledIcon />
                      <span style={{ fontSize: "14px" }} className="ms-3">
                        Time
                      </span>
                    </div>
                  </Col>

                  <Col
                    onClick={() => setShowDate(true)}
                    className="d-flex justify-content-center align-items-center pb-2"
                    lg="4"
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      className={
                        activethree === 1
                          ? "schedule-icon-active"
                          : "schedule-icon"
                      }
                      onClick={Activehandlertwo}
                    >
                      <NotificationsActiveIcon />
                      <span style={{ fontSize: "14px" }} className="ms-3">
                        Alert
                      </span>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col
                    className="d-flex justify-content-center align-items-center pb-5"
                    lg="4"
                  >
                    <p className="and-twn ps-1">{value.toLocaleDateString()}</p>
                  </Col>
                  <Col
                    className="d-flex justify-content-center align-items-center pb-5"
                    lg="4"
                  >
                    <p className="and-twn ">
                      {selectedTime
                        ? selectedTime.format("hh:mm A")
                        : "00 : 00 AM"}
                    </p>
                    {/* <button onClick={toggleTimePicker}>Select Time</button> */}
                  </Col>
                  <Col
                    className="d-flex justify-content-center align-items-center pb-5"
                    lg="4"
                  >
                    <p className="and-twn ps-3">{`${selectedHour}:${selectedMinute}`}</p>
                  </Col>
                </Row>

                {open === true && (
                  <div ref={calendarRef}>
                    <Calendar
                      onSelectDate={onChange}
                      onChange={onChange}
                      value={value}
                      on
                    />
                  </div>
                )}

                {showTimePicker && (
                  <div
                    className="d-flex justify-content-center mb-4"
                    ref={timePickerRef}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimeField
                        label="Select time "
                        value={selectedTime}
                        onChange={handleTimeChange}
                      />
                    </LocalizationProvider>
                  </div>
                )}
                {showDate === true && (
                  <div className="container-be d-flex justify-content-center">
                    <div className="box-eem noscroll">
                      <p className="fw-bold  d-flex justify-content-center  ">
                        Hours
                      </p>
                      <ul className="scroll">
                        {hours.map((hour) => (
                          <li key={`hour-${hour}`}>
                            <button
                              className={
                                hour === parseInt(selectedHour)
                                  ? "selected"
                                  : ""
                              }
                              style={{ border: "transparent" }}
                              onClick={() =>
                                setSelectedHour(
                                  `${hour < 10 ? "0" : ""}${hour}`
                                )
                              }
                            >
                              {`${hour < 10 ? "0" : ""}${hour}:`}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="box-eem noscroll">
                      <p className="fw-bold d-flex justify-content-center">
                        Minutes
                      </p>
                      <ul className="scroll">
                        {minutes.map((minute) => (
                          <li key={`minute-${minute}`}>
                            <button
                              className={
                                minute === parseInt(selectedMinute)
                                  ? "selected"
                                  : ""
                              }
                              style={{ border: "transparent" }}
                              onClick={() =>
                                setSelectedMinute(
                                  `${minute < 10 ? "0" : ""}${minute}`
                                )
                              }
                            >
                              {`${minute < 10 ? "0" : ""}${minute}`}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </Modal.Dialog>
            </div>
            <Modal.Footer className="gap-4">
              <Button onClick={handleClose} variant="danger">
                Cancel
              </Button>
              <Button variant="primary " className="fs-6 oon-et">
                Done
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </Container>
  );
}

export default ModalSchedule;
