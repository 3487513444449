import { Container, Row, Col, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import url from "../../config/axios";
import { SuccessNotify, FailedNotify } from "../../constants/tostify";
const Bankmodalform = ({ setOpenmodal, openmodal, shopid, datavalue }) => {
  const [details, setDetails] = useState({
    swiftCode: "",
    firstname: "",
    lastname: "",
    IBANnumber: "",
    postcode: "",
    DOB: "",
    bankaccountType: "",
    address: "",
    bankname: "",
    branchcode: "",
    cityandstate: "",
    nameonAccount: "",
    country: "",
    phnumber: "",
    branchaddress: "",
  });

  const inputChange = (e) => {
    setDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    url.put(`shop/paymentMethod/shopid?shopid=${shopid}`, {
      paymentMethod: [
        {
          method: datavalue,
          details: {
            bankTransfer: {
              swiftCode: details.swiftCode ,
              firstname: details.firstname ,
              lastname:details.lastname ,
              IBANnumber: details.IBANnumber ,
              postcode: details.postcode ,
              DOB: details.DOB,
              bankaccountType: details.bankaccountType ,
              address: details.address,
              bankname: details.bankname ,
              branchcode: details.branchcode ,
              cityandstate: details.cityandstate ,
              nameonAccount: details.nameonAccount ,
              country: details.country,
              phnumber: details.phnumber,
              branchaddress: details.branchaddress ,
            },
          },
        },
      ],
    }).then(async(res)=>{
      if(res.data.success){
        SuccessNotify(res.data.message);
        setOpenmodal(0);
      }
    }).catch((e)=>{
      FailedNotify(e.response.data.message);
    })
  };
  return (
    <>
      <Modal
        size="lg"
        show={openmodal}
        onHide={() => setOpenmodal(0)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Enter Your Bank Account Details:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <Container>
            <Row>
              <Col md={6} lg={6}>
                <Form.Label className="custm-lble ">SWIFT Code</Form.Label>
                <Form.Control
                  placeholder="Enter SWIFT code for your bank"
                  type="text"
                  name="swiftCode"
                  className="labelText "
                  onChange={(e) => inputChange(e)}
                />
              </Col>
              <Col md={6} lg={6}>
                <Form.Label className="custm-lble ">First Name</Form.Label>
                <Form.Control
                  placeholder="First Name, 2 to 26 characters in length"
                  type="text"
                  name="firstname"
                  className="labelText "
                  onChange={(e) => inputChange(e)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={6}>
                <Form.Label className="custm-lble ">IBAN Number</Form.Label>
                <Form.Control
                  placeholder="IBAN is different than account number and vary by country"
                  type="text"
                  name="IBANnumber"
                  className="labelText "
                  onChange={(e) => inputChange(e)}
                />
              </Col>
              <Col md={6} lg={6}>
                <Form.Label className="custm-lble ">Last Name</Form.Label>
                <Form.Control
                  placeholder="Last Name, 2 to 26 characters in length"
                  type="text"
                  name="lastname"
                  className="labelText "
                  onChange={(e) => inputChange(e)}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6} lg={6}>
                <Form.Label className="custm-lble ">Post Code</Form.Label>
                <Form.Control
                  placeholder="Please enter the post code associated with your account."
                  type="number"
                  name="postcode"
                  className="labelText "
                  onChange={(e) => inputChange(e)}
                />
              </Col>
              <Col md={6} lg={6}>
                <Form.Label className="custm-lble ">
                  Enter date of birth in YYYY-MM-DD format
                </Form.Label>
                <Form.Control
                  placeholder="Enter dat of birth"
                  type="number"
                  name="DOB"
                  className="labelText "
                  onChange={(e) => inputChange(e)}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6} lg={6}>
                <Form.Label className="custm-lble ">
                  Bank Account Type
                </Form.Label>
                <Form.Control
                  placeholder="Type of bank account"
                  type="text"
                  name="bankaccountType"
                  className="labelText "
                  onChange={(e) => inputChange(e)}
                />
              </Col>
              <Col md={6} lg={6}>
                <Form.Label className="custm-lble ">Address</Form.Label>
                <Form.Control
                  placeholder="Please enter the physical address associated with this account."
                  type="text"
                  name="address"
                  className="labelText "
                  onChange={(e) => inputChange(e)}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6} lg={6}>
                <Form.Label className="custm-lble ">Bank Name</Form.Label>
                <Form.Control
                  placeholder="Name of the bank"
                  type="text"
                  name="bankname"
                  className="labelText "
                  onChange={(e) => inputChange(e)}
                />
              </Col>
              <Col md={6} lg={6}>
                <Form.Label className="custm-lble ">
                  City and state/province{" "}
                </Form.Label>
                <Form.Control
                  placeholder="Enter"
                  type="text"
                  name="cityandstate"
                  className="labelText "
                  onChange={(e) => inputChange(e)}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6} lg={6}>
                <Form.Label className="custm-lble ">Branch Address</Form.Label>
                <Form.Control
                  placeholder="Enter complete address accurately to prevent delays in processing your payment"
                  type="text"
                  name="branchaddress"
                  className="labelText "
                  onChange={(e) => inputChange(e)}
                />
              </Col>
              <Col md={6} lg={6}>
                <Form.Label className="custm-lble ">Country</Form.Label>
                <Form.Control
                  placeholder="Country"
                  type="text"
                  name="country"
                  className="Country"
                  onChange={(e) => inputChange(e)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={6}>
                <Form.Label className="custm-lble ">Name on account</Form.Label>
                <Form.Control
                  placeholder="Account name"
                  type="text"
                  name="nameonAccount"
                  className="labelText"
                  onChange={(e) => inputChange(e)}
                />
              </Col>
              <Col md={6} lg={6}>
                <Form.Label className="custm-lble ">Phone number</Form.Label>
                <Form.Control
                  placeholder="Phone Number"
                  type="number"
                  name="phnumber"
                  className="labelText"
                  onChange={(e) => inputChange(e)}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6} lg={6}>
                <Form.Label className="custm-lble ">Branch Code</Form.Label>
                <Form.Control
                  placeholder="Branch Code"
                  type="number"
                  name="branchcode"
                  className="labelText"
                  onChange={(e) => inputChange(e)}
                />
              </Col>
            </Row>
            <Row>
              {" "}
              <div className="d-flex justify-content-end">
                <Col lg="2 mt-3 text-end">
                  <button
                    className="btn-blue-sm ps-0 pe-0"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Submit
                  </button>
                </Col>
              </div>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Bankmodalform;
