import { Container, Row } from "react-bootstrap";
import Leftmenubar from "./Components/Left_menubar";
import Displayshops from "./Components/Display_shops";
import url from "../../config/axios";
import { useState } from "react";
import { useSelector } from "react-redux";

const BusinessDashboard = () => {
  const [companyData, setCompanyData] = useState();
  const [newitemraw, setNewitemraw] = useState();
  const [newitemfinish, setNewitemfinish] = useState();
  const [newitemmanufacture, setNewitemmanufacture] = useState();
  const [newitemwhole, setNewitemwhole] = useState();
  const [newitemretailer, setNewitemretailer] = useState();
  const [homemaderaw, setHomemaderaw] = useState();

  const [seconditem, setSeconditem] = useState();
  const [showdata, setShowdata] = useState();
  const [mineralraw, setMineralraw] = useState();
  const [mineralfinish, setMineralfinish] = useState();
  const [mineralmanufacture, setMineralmanufacture] = useState();
  const [mineralwhole, setMineralwhole] = useState();
  const [mineralretailer, setMineralretailer] = useState();
  const [serviceit, setServiceit] = useState();
  const [servicetraining, setServicetraining] = useState();
  const [consultingservice, setConsultingservice] = useState();
  const [marketingservice, setMarketingservice] = useState();
  const [constructionservice, setConstructionservice] = useState();
  const [wasteservices, setWasteservices] = useState();
  const [legalservice, setLegalservice] = useState();
  const [healthservice, setHealthservice] = useState();
  const [securityservice, setSecurityservice] = useState();
  const [travelservices, setTravelservices] = useState();
  const [researchservice, setResearchservice] = useState();
  const [financeservices, setFinanceservices] = useState();
  const [deliveryservices, setDeliveryservices] = useState();
  const [childcareservices, setChildcareservices] = useState();
  const [personalservices, setPersonalservices] = useState();


  const { name } = useSelector((state) => state.user);
  const Getdata = () => {
    setShowdata(1);
    url
      .get(`company/?userid=${name._id}&businessType=company`)
      .then(async (res) => {
        setCompanyData(res?.data?.data?.Companies);
      });
  };

  const Newitemdataraw = () => {
    setShowdata(2);
    url
      .get(`shop/user/?userid=${name._id}&businessType=newShop`)
      .then(async (res) => {
        setNewitemraw(res?.data?.data?.Shops);
      });
  };

  const Newitemdatasupplier = () => {
    setShowdata(3);
    url
      .get(
        `shop/user/?userid=${name._id}&businessCategory=Finished Raw Materials&businessType=newShop`
      )
      .then(async (res) => {
        setNewitemfinish(res?.data?.data?.Shops);
      });
  };

  const Newitemdatamanufacture = () => {
    setShowdata(4);
    url
      .get(
        `shop/user/?userid=${name._id}&businessCategory=Manufacturer&businessType=newShop`
      )
      .then(async (res) => {
        setNewitemmanufacture(res?.data?.data?.Shops);
      });
  };

  const Newitemdatawholesaler = () => {
    setShowdata(5);
    url
      .get(
        `shop/user/?userid=${name._id}&businessCategory=Wholeseller&businessType=newShop`
      )
      .then(async (res) => {
        setNewitemwhole(res?.data?.data?.Shops);
      });
  };

  const Newitemdataretailer = () => {
    setShowdata(6);
    url
      .get(
        `shop/user/?userid=${name._id}&businessCategory=Retailer&businessType=newShop`
      )
      .then(async (res) => {
        setNewitemretailer(res?.data?.data?.Shops);
      });
  };

  const Secondhanditem = () => {
    setShowdata(7);
    url
      .get(`shop/user/?userid=${name._id}&businessType=oldShop`)
      .then(async (res) => {
        setSeconditem(res?.data?.data?.Shops);
      });
  };

  const Homemaderaw = () => {
    setShowdata(8);
    url
      .get(`shop/user/?userid=${name._id}&businessType=homeShop`)
      .then(async (res) => {
        setHomemaderaw(res?.data?.data?.Shops);
      });
  };

  const Mineralraw = () => {
    setShowdata(13);
    url
      .get(`shop/user/?userid=${name._id}&businessType=gemsShop`)
      .then(async (res) => {
        setMineralraw(res?.data?.data?.Shops);
      });
  };

  const Mineralfinish = () => {
    setShowdata(14);
    url
      .get(
        `shop/user/?userid=${name._id}&businessCategory=Finished Raw Materials&businessType=gemsShop`
      )
      .then(async (res) => {
        setMineralfinish(res?.data?.data?.Shops);
      });
  };

  const Mineralmanufacture = () => {
    setShowdata(15);
    url
      .get(
        `shop/user/?userid=${name._id}&businessCategory=Manufacturer&businessType=gemsShop`
      )
      .then(async (res) => {
        setMineralmanufacture(res?.data?.data?.Shops);
      });
  };

  const Mineralwhole = () => {
    setShowdata(16);
    url
      .get(
        `shop/user/?userid=${name._id}&businessCategory=Wholeseller&businessType=gemsShop`
      )
      .then(async (res) => {
        setMineralwhole(res?.data?.data?.Shops);
      });
  };

  const Mineralretailer = () => {
    setShowdata(17);
    url
      .get(
        `shop/user/?userid=${name._id}&businessCategory=Retailer&businessType=gemsShop`
      )
      .then(async (res) => {
        setMineralretailer(res?.data?.data?.Shops);
      });
  };

  const Serviceit = () => {
    setShowdata(18);

    url.get(`service/user/?userid=${name._id}`).then(async (res) => {
      setServiceit(res?.data?.data?.Services);
    });
  };

  const ServiceTraining = () => {
    setShowdata(19);
    url
      .get(`service/user/?userid=${name._id}&category=Training services`)
      .then(async (res) => {
        setServicetraining(res?.data?.data?.Services);
      });
  };
  const Consultingservices = () => {
    setShowdata(20);
    url
      .get(`service/user/?userid=${name._id}&category=Consulting services`)
      .then(async (res) => {
        setConsultingservice(res?.data?.data?.Services);
      });
  };

  const Marketingservices = () => {
    setShowdata(21);
    url
      .get(`service/user/?userid=${name._id}&category=Marketing services`)
      .then(async (res) => {
        setMarketingservice(res?.data?.data?.Services);
      });
  };

  const Constructionservices = () => {
    setShowdata(22);
    url
      .get(`service/user/?userid=${name._id}&category=Construction services`)
      .then(async (res) => {
        setConstructionservice(res?.data?.data?.Services);
      });
  };

  const Wastemanagementservices = () => {
    setShowdata(23);
    url
      .get(
        `service/user/?userid=${name._id}&category=Waste management services`
      )
      .then(async (res) => {
        setWasteservices(res?.data?.data?.Services);
      });
  };

  const Legalservices = () => {
    setShowdata(24);
    url
      .get(`service/user/?userid=${name._id}&category=Legal services`)
      .then(async (res) => {
        setLegalservice(res?.data?.data?.Services);
      });
  };

  const Healthservice = () => {
    setShowdata(25);
    url
      .get(
        `service/user/?userid=${name._id}&category=Health and wellness services`
      )
      .then(async (res) => {
        setHealthservice(res?.data?.data?.Services);
      });
  };

  const Securityservices = () => {
    setShowdata(26);
    url
      .get(`service/user/?userid=${name._id}&category=Security services`)
      .then(async (res) => {
        setSecurityservice(res?.data?.data?.Services);
      });
  };

  const Travelservices = () => {
    setShowdata(27);
    url
      .get(`service/user/?userid=${name._id}&category=Travel services`)
      .then(async (res) => {
        setTravelservices(res?.data?.data?.Services);
      });
  };

  const Researchservices = () => {
    setShowdata(28);
    url
      .get(`service/user/?userid=${name._id}&category=Research services`)
      .then(async (res) => {
        setResearchservice(res?.data?.data?.Services);
      });
  };

  const Financeservices = () => {
    setShowdata(29);
    url
      .get(`service/user/?userid=${name._id}&category=Finance services`)
      .then(async (res) => {
        setFinanceservices(res?.data?.data?.Services);
      });
  };

  const Deliveryservices = () => {
    setShowdata(30);
    url
      .get(`service/user/?userid=${name._id}&category=Delivery services`)
      .then(async (res) => {
        setDeliveryservices(res?.data?.data?.Services);
      });
  };

  const Childcareservices = () => {
    setShowdata(31);
    url
      .get(`service/user/?userid=${name._id}&category=Child care services`)
      .then(async (res) => {
        setChildcareservices(res?.data?.data?.Services);
      });
  };

  const Personalservices = () => {
    setShowdata(31);
    url
      .get(`service/user/?userid=${name._id}&category=Personal services`)
      .then(async (res) => {
        setPersonalservices(res?.data?.data?.Services);
      });
  };

  return (
    <>
      <Container fluid className="ps-0">
        <Row>
          <Leftmenubar
            Getdata={Getdata}
            setShowdata={setShowdata}
            Newitemdataraw={Newitemdataraw}
            Secondhanditem={Secondhanditem}
            Homemaderaw={Homemaderaw}
            Mineralraw={Mineralraw}
            Serviceit={Serviceit}
            ServiceTraining={ServiceTraining}
            Consultingservices={Consultingservices}
            Marketingservices={Marketingservices}
            Constructionservices={Constructionservices}
            Wastemanagementservices={Wastemanagementservices}
            Legalservices={Legalservices}
            Healthservice={Healthservice}
            Securityservices={Securityservices}
            Travelservices={Travelservices}
            Researchservices={Researchservices}
            Financeservices={Financeservices}
            Deliveryservices={Deliveryservices}
            Childcareservices={Childcareservices}
            Personalservices={Personalservices}
          />
          <Displayshops
            url="/business-dashboard"
            companyData={companyData}
            showdata={showdata}
            newitemraw={newitemraw}
            newitemfinish={newitemfinish}
            newitemmanufacture={newitemmanufacture}
            newitemwhole={newitemwhole}
            newitemretailer={newitemretailer}
            seconditem={seconditem}
            homemaderaw={homemaderaw}
            Newitemdataraw={Newitemdataraw}
            mineralraw={mineralraw}
            mineralfinish={mineralfinish}
            mineralmanufacture={mineralmanufacture}
            mineralwhole={mineralwhole}
            mineralretailer={mineralretailer}
            serviceit={serviceit}
            servicetraining={servicetraining}
            consultingservice={consultingservice}
            marketingservice={marketingservice}
            constructionservice={constructionservice}
            wasteservices={wasteservices}
            legalservice={legalservice}
            healthservice={healthservice}
            securityservice={securityservice}
            travelservices={travelservices}
            researchservice={researchservice}
            financeservices={financeservices}
            deliveryservices={deliveryservices}
            childcareservices={childcareservices}
            personalservices={personalservices}
          />
        </Row>
      </Container>
    </>
  );
};

export default BusinessDashboard;
