import "./Forgotpassword.css";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import url from "../../../../config/axios"
import { useState } from "react";
import { FailedNotify,SuccessNotify } from "../../../../constants/tostify";
import { useNavigate } from "react-router-dom";
const Forgotpassword = () => {
  const navigate=useNavigate();
const [email,setEmail]=useState({
  email:""
});

localStorage.setItem("useremail",JSON.stringify(email?.email))
 const Emailverifycode=()=>{
url.get(`user/forgotPassword/${email.email}`).then(async(res)=>{
  if(res.data.success){
    SuccessNotify("OTP sent to your email")
    navigate("/Verification")
  }
}).catch((err)=>{
  FailedNotify(`${err.response.data.message}`)
})

 } 

 const Getemail=(e)=>{
  setEmail({
    ...email,
    [e.target.name]:e.target.value
  })
 }
  return (
    <>
      <Container fluid className="f-bg-color">
        <Row>
          <Col lg="6" className="mx-auto  background-white f-modal-style">
            <div className="ps-5 pe-5">
              <h5 className="text-center">Forgot password</h5>
              <p className="text-center mt-2 font-ss">
                Provide your account email to reset your password!
              </p>

              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="labelSize">Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email address or phone no"
                    className="p-3 input-style"
                    name="email"
                    onChange={(e)=>Getemail(e)}
                  />
                </Form.Group>
               
                  {" "}
                  <Button
                    type="button"
                    className="bg-blue  w-100 mt-2 next-btn-s b-radius p-2"
                    onClick={Emailverifycode}
                  >
                    NEXT
                  </Button>
          
                <div
                  className="font-blue text-center  mt-lg-3 mt-xl-3 "
                  
                >
                    <NavLink to="/" >
                  Back to login
              
                    
                    <span  style={{ color: "black" }}> Page</span>
                  </NavLink>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Forgotpassword;
