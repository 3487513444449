import { Container, Row, Col } from "react-bootstrap";
import ShopAdds from "../shopAds/ShopAdds";
import {
  Businessprofiletitle,
  Connectivity,
  Timeline,
} from "../../pages/index";

const Businessprofile = () => {
  return (
    <>
      <Container>
        <Row>
          <Col lg="7" className="screenHeight p-0">
            <div className="border b-radius">
              <ShopAdds />
              <Businessprofiletitle />
              <Connectivity />
            </div>
          </Col>
          
          <Col
            lg="5"
            sm="12"
            className="ps-4 screenHeight"
            style={{ position: "relative" }}
          >
            <div className="border b-radius">
              <Timeline />
            </div>
          </Col>

        </Row>
      </Container>
    </>
  );
};

export default Businessprofile;
