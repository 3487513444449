import React from "react";
import FilterIcon from "@mui/icons-material/Filter";
import { Button, Container, Row,Col } from "react-bootstrap";
import MicIcon from '@mui/icons-material/Mic';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useNavigate } from "react-router-dom";
import './style.css'

const LeaveSchedule = () => {
  const navigate = useNavigate();
  return (
    <div>
     
        <div className="pt-2 d-flex justify-content-between gap-3 lmcf-one">

          <div className="d-flex justify-content-center pt-2 aln-ftr">
            <p>Uzy-jpg-pngx</p>
            <span className="ps-3">
              <FilterIcon />
            </span>
          </div>

          <div className="d-flex justify-content-between gap-2" >
            <span className="d-flex justify-content-center and-olm pt-3" ><MicIcon/></span>
            <span className="and-olm d-flex justify-content-center pt-3">
              <VideocamOffOutlinedIcon />
            </span>
            <span className="and-olm d-flex justify-content-center pt-3"><FileUploadOutlinedIcon/></span>
          </div>

          <div>
            <Button onClick={() => navigate("/inbox")} variant="danger">Leave Meeting</Button>
          </div>

        </div>
      
    </div>

  );
};

export default LeaveSchedule;
