import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BackArrow from "../../components/backArrow";
import Businessprofiletitle from "../../components/BusinessProfile/Component/Title/businessprofiletitle";
import Rawmaterialcard from "../../components/BusinessProfile/Component/Rawmaterialcard/Rawmaterialcard";
import Timeline from "../../components/Timeline/timeline";
import ShopAdds from "../../components/shopAds/ShopAdds";
import { useNavigate, useParams, useLocation } from "react-router-dom";
const CompanycreateShop = () => {
  const { id } = useParams();
  return (
    <>
      <Container>
        <Row>
          <Col lg="7" className="screenHeight p-0">
            <div className="border b-radius">
              <BackArrow back="/retailer-form" />
              <ShopAdds />
              <Businessprofiletitle
                name="Create job offer"
                linkurl="/company-profile"
                  id={id}
              />
              <Rawmaterialcard />
            </div>
          </Col>

          <Col
            lg="5"
            sm="12"
            className="ps-4 screenHeight  "
            style={{ position: "relative" }}
          >
            <div className="border b-radius">
              <Timeline />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CompanycreateShop;
