import { Container } from "react-bootstrap";
import { Col, Row, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import BackArrow from "../../components/backArrow";
import { useRef } from "react";
import PersonalImage from "../../helpers/fileUpload";
import { useState } from "react";
import { useEffect } from "react";
import Card from "react-bootstrap/Card";
import SearchIcon from "@mui/icons-material/Search";
import { images } from "../../constants";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Measurementunit, currencies } from "../../constants/jsonConstant";
import { saleoffer, salepromotion } from "../../constants/jsonConstant";
import { getAsset } from "../../helpers/helper";

const Restaurantform = ({
  btntext,
  handlevalue,
  Handledata,
  Mydata,
  userDatahandler,
  shopCategory,
  shopCategorytwo,
  updatedata,
  updateDatahandler,
  getInputProps,
  getRootProps,
  displayimg,
  setDisplayimg,
  id,
}) => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const removeImage = (index) => {
    const updatedImages = [...displayimg];
    updatedImages.splice(index, 1);
    setDisplayimg(updatedImages);
  }

  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedstateone, setSelectedstateone] = useState("");
  const [searchTermone, setSearchTermone] = useState("");

  const [selectedcity, setSelectedcity] = useState("");
  const [searchTermtwo, setSearchTermtwo] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle the country selection
  const handleCountryChange = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
  };

  const handleSearchChangestate = (event) => {
    setSearchTermone(event.target.value);
  };

  const handleSearchChangecity = (event) => {
    setSearchTermtwo(event.target.value);
  };

  // Function to handle the state selection
  const handleCountryChangestate = (event) => {
    const { value } = event.target;
    setSelectedstateone(value);
  };

  // Function to handle the city selection
  const handleCountryChangecity = (event) => {
    const { value } = event.target;
    setSelectedcity(value);
  };
  const [hidefield, setHidefield] = useState(1);
  const [hidestate, setHidestate] = useState(1);
  const [hidecity, setHidecity] = useState(1);

  const [countrydata, SetCountrydata] = useState([]);
  const [getcountry] = useState();
  const [selectedState] = useState();
  const [getState, setState] = useState([]);
  const [getcity, setGetcity] = useState([]);
  const [statetwo, setStatetwo] = useState(getcountry);
  const [city, setCity] = useState(selectedState);
  const [open, setOpen] = useState(getcountry);

  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => SetCountrydata(res.data))

      .catch((error) => { });
  }, []);

  const country = [...new Set(countrydata.map((item) => item.country))];

  country.sort();

  const handleCountry = (e) => {
    let states = countrydata.filter(
      (state) => state.country === e.target.value
    );
    states = [...new Set(states.map((item) => item.subcountry))];
    states.sort();
    setState(states);
  };

  const handleState = (e) => {
    let cities = countrydata.filter(
      (city) => city.subcountry === e.target.value
    );
    setGetcity(cities);
  };
  console.log("sdjsdljslsdajklsdaksdjjkjksdakjsdak", id);

  return (
    <>
      {selectedImage && <PersonalImage image={selectedImage} />}
      <Container>
        <Row>
          <div className="screenHeight d-flex justify-content-center align-items-center">
            <Col lg={10} md={12} sm={12} xs={12} className="Form-Shadow">
              <Row>
                <Col className="">
                  <div className="arrowback ps-3">
                    <BackArrow
                      back="/RestaurantAlldata"
                      black="black"
                      mystyle="ps-3 p-1"
                    />
                  </div>
                  {/* <div className="pe-3  pb-2"> */}
                  {/* </div> */}
                </Col>
              </Row>
              <div className="p-4">
                <Row className="pt-2 pb-2 px-3">
                  <Col md={3} lg={3} xl={3}>
                    <Form.Label className="custm-lble">Dish Name</Form.Label>
                    <Form.Control
                      placeholder="Name"
                      className="input-style form-control-seller placeholderClorset"
                      name="dishname"
                      onChange={(e) => handlevalue(e)}
                      defaultValue={updatedata?.dishname}
                    />
                  </Col>

                  <Col md={3} lg={3} xl={3} className="bluedrop3">
                    <Form.Label className="custm-lble ">Category</Form.Label>
                    <Form.Select
                      className="input-style form-control-seller"
                      aria-label="Default select example"
                      name="categoryid"
                      onChange={(e) => {
                        Handledata(e);
                      }}
                    >
                      <option>Select Category</option>
                      {shopCategory?.map((category) => (
                        <>
                          <option
                            key={category._id}
                            value={`${category?._id}.${category?.name}`}
                          >
                            {category?.name}
                          </option>
                        </>
                      ))}
                    </Form.Select>
                  </Col>

                  <Col md={3} lg={3} xl={3} className="bluedrop3">
                    <Form.Label className="custm-lble">SubCategory</Form.Label>
                    <Form.Select
                      onChange={(e) => handlevalue(e)}
                      onClick={Mydata}
                      name="subCategoryid"
                      className="input-style form-control-seller"
                      aria-label="Default select example"
                    >
                      <option>Select Sub Category</option>
                      {shopCategorytwo?.map((subcategory) => (
                        <>
                          <option
                            key={subcategory._id}
                            value={subcategory?._id}
                          >
                            {subcategory?.name}
                          </option>
                        </>
                      ))}
                    </Form.Select>
                  </Col>

                  <Col md={3} lg={3} xl={3}>
                    <Form.Label className="custm-lble">Price</Form.Label>
                    <Form.Control
                      placeholder="PKR"
                      name="price"
                      className="input-style form-control-seller placeholderClorset"
                      type="number"
                      onChange={(e) => handlevalue(e)}
                      defaultValue={updatedata?.price}
                    />
                  </Col>
                </Row>

                <Row className="pt-2 pb-2  px-3">
                  <Col md={3} lg={3} xl={3} className="bluedrop3">
                    <Form.Label className="custm-lble">Currency </Form.Label>
                    <Form.Select
                      placeholder="Select Currency"
                      aria-label="Default select example"
                      name="currency"
                      className="input-style form-control-seller"
                      onChange={(e) => handlevalue(e)}
                    // onChange={(e) => {
                    //   handleChange(e);
                    //   inputChange(e);
                    // }}
                    >
                      {location.pathname === `/resturent-update-form/${id}` ? (
                        <option>{updatedata?.currency}</option>
                      ) : (
                        ""
                      )}
                      <option>Select Currency</option>
                      {currencies.map((e) => (
                        <option>
                          {e.abbreviation} {e.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>

                  <Col md={3} lg={3} xl={3}>
                    <Form.Label className="custm-lble">
                      Preparation Time
                    </Form.Label>
                    <Form.Control
                      placeholder="Enter Preparation Time"
                      className="input-style form-control-seller placeholderClorset"
                      name="preparationTime"
                      onChange={(e) => handlevalue(e)}
                      defaultValue={updatedata?.preparationTime}
                    />
                  </Col>
                  <Col md={3} lg={3} xl={3}>
                    <Form.Label className="custm-lble">Contact</Form.Label>
                    <Form.Control
                      placeholder="Contact"
                      className="input-style form-control-seller placeholderClorset"
                      name="contact"
                      onChange={(e) => handlevalue(e)}
                      defaultValue={updatedata?.contact}
                    />
                  </Col>

                  <div className="col-md-3">
                    <label
                      htmlFor="inputPassword4"
                      placeholder="First name"
                      className="form-label labelSize"
                    >
                      Country*
                    </label>
                    <div className="blood-type input-style">
                      <div className="d-flex">
                        <div className="w-100">
                          <input
                            type="search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder="Search country here...."
                            className="w-100 search-input"
                            onClick={() => setHidefield(0)}
                          />
                        </div>
                        <div>
                          <span>
                            <SearchIcon className="search-icon-fs" />
                          </span>
                        </div>
                      </div>

                      <select
                        onClick={() => setHidefield(1)}
                        value={selectedCountry}
                        onChange={(e) => {
                          handleCountryChange(e);
                          handleCountry(e);
                          handlevalue(e);
                        }}
                        className="b-radius selectSize w-100"
                        name="country"
                      >
                        {hidefield && <option>Select country</option>}

                        {country
                          .filter((country) =>
                            country
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          .map((country, index) => (
                            <option key={country} value={getcountry}>
                              {country}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </Row>

                <Row className="pt-2 pb-2  px-3">
                  <div className="col-md-3">
                    <label
                      htmlFor="inputPassword4"
                      placeholder="First name"
                      className="form-label labelSize"
                    >
                      State*
                    </label>
                    <div className="blood-type input-style">
                      <div className="d-flex">
                        <div className="w-100">
                          <input
                            type="search"
                            value={searchTermone}
                            onChange={handleSearchChangestate}
                            placeholder="Search state here...."
                            className="w-100 search-input"
                            onClick={() => setHidestate(0)}
                          />
                        </div>
                        <div>
                          <span>
                            <SearchIcon className="search-icon-fs" />
                          </span>
                        </div>
                      </div>

                      <select
                        onClick={() => setHidestate(1)}
                        className="b-radius selectSize w-100"
                        value={selectedstateone}
                        aria-label="Default select example "
                        name="state"
                        onChange={(e) => {
                          handleState(e);
                          handleCountryChangestate(e);
                          handlevalue(e);
                        }}
                      >
                        {hidestate && <option>Select state</option>}
                        {/* <option>
                            {Open === undefined
                              ? "Select Country First"
                              : "select state"}
                          </option> */}

                        {getState
                          .filter((country) =>
                            country
                              .toLowerCase()
                              .includes(searchTermone.toLowerCase())
                          )
                          .map((country) => (
                            <option key={country} value={selectedState}>
                              {country}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3 ">
                    <label
                      htmlFor="inputPassword4"
                      placeholder="First name"
                      className="form-label labelSize"
                    >
                      City
                    </label>
                    <div className="blood-type input-style">
                      <div className="d-flex">
                        <div className="w-100">
                          <input
                            type="search"
                            value={searchTermtwo}
                            onChange={handleSearchChangecity}
                            placeholder="Search city here...."
                            className="w-100 search-input"
                            onClick={() => setHidecity(0)}
                          />
                        </div>
                        <div>
                          <span>
                            <SearchIcon className="search-icon-fs" />
                          </span>
                        </div>
                      </div>

                      <select
                        onClick={() => setHidecity(1)}
                        value={selectedcity}
                        className="b-radius selectSize w-100"
                        aria-label="Default select example"
                        name="city"
                        onChange={(e) => {
                          handleCountryChangecity(e);
                          handlevalue(e);
                        }}
                      >
                        {/* <option>
                              {city === undefined
                                ? "Select State First"
                                : "Select City"}
                            </option> */}

                        {hidecity && <option>Select city</option>}

                        {getcity
                          .filter((country) =>
                            country.name
                              .toLowerCase()
                              .includes(searchTermtwo.toLowerCase())
                          )
                          .map((country) => (
                            <option key={country.name}>{country.name}</option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <Col md={3} lg={3} xl={3} className="bluedrop3">
                    <Form.Label className="custm-lble">
                      Sales Promotion
                    </Form.Label>
                    <Form.Select
                      className="input-style form-control-seller"
                      aria-label="Default select example"
                      name="salesPromotion"
                      onChange={(e) => handlevalue(e)}
                    >
                      {location.pathname === `/resturent-update-form/${id}` ? (
                        <option>{updatedata?.salesPromotion}</option>
                      ) : (
                        ""
                      )}
                      <option>Select Sales</option>
                      {salepromotion.map((e) => (
                        <option>{e.label}</option>
                      ))}
                    </Form.Select>
                  </Col>

                  <Col md={3} lg={3} xl={3} className="bluedrop3">
                    <Form.Label className="custm-lble">
                      Special Offers
                    </Form.Label>
                    <Form.Select
                      className="input-style form-control-seller"
                      aria-label="Default select example"
                      name="specialOffers"
                      onChange={(e) => handlevalue(e)}
                    >
                      {location.pathname === `/resturent-update-form/${id}` ? (
                        <option>{updatedata?.specialOffers}</option>
                      ) : (
                        ""
                      )}
                      <option>Select Offers</option>
                      {saleoffer.map((e) => (
                        <option>{e.label}</option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>

                <div className="ps-3 pe-3 pt-4">
                  <Form.Label className="custm-lble">Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    name="description"
                    className="input-style placeholderClorset"
                    placeholder="Description"
                    onChange={(e) => handlevalue(e)}
                    defaultValue={updatedata?.description}
                  />
                </div>
                <div className="d-flex justify-content-between pt-2">
                  <Col lg="9" className="me-auto">
                    <Row>
                      <Col lg={2} className="mt-2 ms-3">
                        <Card
                          style={{ width: "6rem", height: "6rem" }}
                          {...getRootProps()}
                          className="dropzone"
                        >
                          <Card.Img
                            className="imet-fit"
                            src={images.AddImage}
                          />
                          <Card.Body>
                            <input
                              type="file"
                              id="file"
                              style={{ display: "none" }}
                              {...getInputProps()}
                              multiple
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                      {
                        displayimg?.length > 0 && (
                          <Col lg={8} className=" mt-1 d-flex">


                            {displayimg.map((image, index) => (
                              <Card
                                className="me-2"
                                style={{
                                  width: "6rem",
                                  height: "6rem",
                                  border: "2px solid",
                                }}

                              >
                                <Card.Img
                                  className="imet-fit upload-img"
                                  src={getAsset(image)}
                                  alt=""
                                  crossOrigin="anonymous"
                                />
                                <div
                                  className="close-btnone"
                                  onClick={() => removeImage(index)}
                                >
                                  <ClearIcon />
                                </div>
                              </Card>
                            ))}
                          </Col>
                        )

                      }

                    </Row>
                  </Col>

                  <div className="d-flex align-items-end pb-3 pe-3">
                    <div className="btns">
                      <button
                        className="btn1"
                        onClick={() => navigate(`/RestaurantAlldata/${id}`)}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn2"
                        onClick={
                          btntext === "Post"
                            ? userDatahandler
                            : updateDatahandler
                        }
                      >
                        {btntext}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Restaurantform;
