import { Row, Col, Card, Button } from "react-bootstrap";
import Leftmenubar from "../Components/Left_menubar";
import BackArrow from "../../../components/backArrow";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RedoIcon from "@mui/icons-material/Redo";
import url from "../../../config/axios";
import { useSelector } from "react-redux";
import Nodata from "../../../components/Nodata";
import { getAsset } from "../../../helpers/helper";
import Loader from "../../../components/Loader";
import ShareModel from "../../../components/Sharemodel";
import ShareOption from "../../../components/Shareoption";
import { useLocation } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import OrderHistory from "../../../components/OrderHistory.js/OrderHistory";
import { UserDashboard } from "../../../constants/jsonConstant";
import Suggestionmodal from "../../suggestionmodal";

const HomeMadeShops = () => {
  const location = useLocation();
  const { name } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [homemadeShop, setHomemadeShop] = useState();
  const [isloading, setIsloading] = useState(false);
  const [showshare, setShowshare] = useState(0);
  const [show, setShow] = useState(0);
  const [userdash, setUserdash] = useState(1);
  const [userimg, setUserimg] = useState();

  const handleCloseshare = () => {
    setShowshare(0);
    setShow(1);
  };
  const handleClosemodal = () => {
    setShow(0);
  };

  const handleShowshare = () => {
    setShow(0);
    setShowshare(1);
  };

  useEffect(() => {
    setIsloading(true);
    url
      .get(`shop/user/?userid=${name._id}&businessType=homemadeShop`)
      .then(async (res) => {
        if (res.data.success) {
          setIsloading(false);
          setHomemadeShop(res?.data?.data?.Shops);
          localStorage.setItem("mycategory", (res?.data?.data?.Shops[0]?.shopCategory?.name));
        }
      });
  }, []);


  useEffect(() => {
    url.get(`/user/id?id=${name?._id}`).then((response) => {
      setUserimg(response?.data?.data?.User?.imageUrl);
    });
  }, [])

  return (
    <>
      <Row >
        <ShareOption
          showshare={showshare}
          handleCloseshare={handleCloseshare}
        />
        <ShareModel
          handleClosemodal={handleClosemodal}
          show={show}
          handleShowshare={handleShowshare}
        />
        <Col xl={3} lg={3} md={3} >
          <Leftmenubar setUserdash={setUserdash} userimg={userimg} />
        </Col>
        <Col xl={8} lg={8} md={8} className="screenHeight b-radius border_2 ms-3">
          <Row className={`${location.pathname === "/user-dashboard" ? "" : "justify-content-sm-around justify-content-md-start"}`}>

            {location.pathname === "/user-dashboard" ? (
              <>
                {userdash === 1 && (
                  <>
                    <Row className="userdash ms-3 mt-2">
                      <Col className="" lg={11} md={11} sm="" xs="">
                        <Tabs
                          id="uncontrolled-tab-example"
                          className=""
                          onSelect={""}
                        >
                          <Tab
                            eventKey=""
                            title="Activity History"
                            className=""
                          >
                            {UserDashboard.map((item) => (
                              <Row className=" mt-0 mt-xl-3 justify-content-xl-start justify-content-lg-around justify-content-md-around ">
                                <Col lg={2} md={2} sm={2} xs={2}>
                                  <div className="dashboardiv">
                                    <img
                                      src={item?.image}
                                      alt=""
                                      className="dashboardimg"
                                    />
                                  </div>
                                </Col>
                                <Col
                                  lg={8} md={10} sm={8} xs={9}
                                  className="d-flex justify-content-between align-items-center small user_sett"
                                >
                                  <h9>{item?.Username}</h9>
                                  <h9>{item?.login}</h9>
                                  <h9>{item?.mobile}</h9>
                                  <h9>{item?.date}</h9>
                                  <h9>{item?.time}</h9>
                                </Col>
                              </Row>
                            ))}
                          </Tab>
                        </Tabs>
                      </Col>
                    </Row>
                  </>
                )}
                {userdash === 2 && (
                  <>
                    <OrderHistory />
                  </>
                )}
                {userdash === 3 && (
                  <>
                    <Suggestionmodal />
                  </>
                )}
              </>
            ) : (
              <>
                <Col xl="4" lg="3" md="3" sm="3" xs="6">
                  <div className="ms-4 ms-sm-2 ms-md-0 pt-4 pb-2">
                    <BackArrow back="/SellingSide" />
                  </div>
                </Col>
                <Col xl="4" lg="5" md="6" sm="5" xs="6">
                  <div>
                    <h5 className="font-primary mt-4 res-manage-btn">
                      Manage Your Shops Here
                    </h5>
                  </div>
                </Col>
                <Col lg="4" md="3" sm="3" xs="5" className="text-center text-lg-end text-md-end text-sm-center">
                  <div className="mt-4 ps-lg-4 ps-md-2 p-xs-2 pe-sm-2 ">
                    <Button className="" onClick={() => navigate("/Home-Made-Form")}>
                      Create New
                    </Button>
                  </div>
                </Col>

              </>
            )}
          </Row>
          {location.pathname === "/user-dashboard" ? (
            ""

          ) 
          : isloading ? (
            <Loader heightsetting="loaderHeight" />
          ) : (
            <Row className="scroll-shops-business noscroll justify-content-center justify-content-sm-around justify-content-md-start">
              {homemadeShop && homemadeShop.length > 0 ? (
                homemadeShop?.map((e) => (
                  <Col lg="4" xl="3" md="6" sm="5" xs="11" className="mt-1">
                    <Card className="ShopsCards_business">
                      <div className="fix-img">
                        <img className="c_img" src={getAsset(e?.image)} alt="" crossOrigin="anonymous" />
                      </div>
                      <Card.Body className="p-2">
                        <Card.Title className="font-sm-15 mb-0 pb-1">
                          <div className="d-flex justify-content-between">
                            {e?.shopname.length >= 12 ? e?.shopname.substring(0, 12) + `...` : e?.shopname}

                            <button
                              className="text-white bg-blue share_font"
                              onClick={() => setShow(1)}
                            >
                              Share <RedoIcon className="share_icon_f" />
                            </button>
                          </div>
                        </Card.Title>
                        <Card.Text className="font-sm font-primary mb-2">
                          {e?.shopaddress.length >= 12 ? e?.shopaddress.substring(0, 12) + `...` : e?.shopaddress}
                        </Card.Text>
                        <button
                          className="viewshop w-100 text-white bg-blue"
                          onClick={() =>
                            navigate(`/rawmaterial-homemade/${e._id}`)
                          }
                        >
                          Manage Your Shop
                        </button>
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              ) : (
                <div>
                  <Nodata />
                </div>
              )}
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default HomeMadeShops;
