import Usedvehicleform from "../../../../components/Used_Vehicle_shop";
import { Container, Row } from "react-bootstrap";
import url from "../../../../config/axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { SuccessNotify, FailedNotify } from "../../../../constants/tostify";
import Loader from "../../../../components/Loader";
import { publishImage } from "../../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
const Usedvehicleshopadd = () => {
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const { id } = useParams();
  const [displayimg, setDisplayimg] = useState();

  const { name } = useSelector((state) => state.user);

  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setUsedvehicle((usedvehicle) => ({
        ...usedvehicle,
        images: abc
      }));

      setDisplayimg(abc);

    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });
  const [usedvehicle, setUsedvehicle] = useState({
    businessType: "usedVehicleShop",
    userid: name._id,
    shopid: "",
    brandName: "",
    modelYear: "",
    model: "",
    make: "",
    version: "",
    registeredIn: "",
    color: "",
    assembly: "",
    city: "",
    cityArea: "",
    engineType: "",
    engineCapactiy: "",
    mileage: "",
    transmission: "",
    price: "",
    contact: "",
    description: "",
    images: [""],
    currency: "",
    status: true,
  });

  const PostItem = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("sellvehicle/", usedvehicle)
      .then(async (response) => {
        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Add Posted Successfully");
            navigate(`/used-vehicle-profile/${id}`);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed To Post Add");
      });
  };
  useEffect(() => {
    url.get(`/shop/user/?userid=${name?._id}`).then((response) => {
      setUsedvehicle({
        ...usedvehicle,
        userid: response?.data?.data?.Shops[0].userid,
        // shopid: response?.data?.data.Shops.at(-1)._id,
        shopid: id
      });
    });
  }, []);

  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }
  return (
    <>
      <Container>
        <Row className="screenHeight">
          <Usedvehicleform
            back="/used-vehicle-profile/"
            butn="Post"
            postbtn="/used-vehicle-profile"
            cancel="/used-vehicle-profile/"
            id={id}
            PostItem={PostItem}
            setUsedvehicle={setUsedvehicle}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            displayimg={displayimg}
            setDisplayimg={setDisplayimg}
          />
        </Row>
      </Container>
    </>
  );
};

export default Usedvehicleshopadd;
