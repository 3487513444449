import React from 'react'
import { Container } from 'react-bootstrap'
import './style.css'
import { useLocation } from 'react-router-dom'

const Tablebutton = (props) => {

  const location = useLocation()

  return (
    <div>
      <Container>
        <div className="pt-2 pb-3 ms-5">

          <div className="button-table ">

            {location.pathname === '/invoice-selling' ? ("") : 
            (<button className='btns-table ms-3'>{props.btn_1}</button>)}


            <button className='btns-table ms-3'>{props.btn_2}</button>

            {location.pathname === '/quotation-buying' || location.pathname === '/invoice-buying' ||location.pathname === '/inquiry-selling' ||location.pathname === '/purchase-order-selling' ? 

              (<button className='btns-tables ms-3'>{props.btn_3}</button>)  
              : ("")
            }
            {location.pathname === '/quotation-buying' || location.pathname === '/invoice-buying' ||location.pathname === '/purchase-order-selling' ? 
            (<button className='btns-table ms-3'>{props.btn_4}</button>) : ("")
          }

            {location.pathname === '/quotation-buying' || location.pathname === '/all-inquiry-buying' || location.pathname === '/inquiry-selling' ||location.pathname === '/purchase-order-selling' ?
            (<button className='btns-table ms-3'>{props.btn_5}</button>) : ("")}


            {location.pathname === '/quotation-buying' || location.pathname === '/all-inquiry-buying' || location.pathname === '/inquiry-selling' || location.pathname === '/purchase-order-buying' || location.pathname === '/purchase-order-selling' ?
            (<button className='btns-table ms-3'>{props.btn_6}</button>):("")}

          </div>

        </div>
      </Container>
    </div>
  )
}

export default Tablebutton
