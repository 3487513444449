import { Row, Col, Container } from "react-bootstrap";
import "./createJobsProfile.css";
import CompanySideBar from "./components/companySidebar";
import Jobs from "../createJobsProfile/components/jobs";
import BackArrow from "../../components/backArrow";
import { useParams } from "react-router-dom";
import ShopAdds from "../../components/shopAds/ShopAdds";
import Managebuttons from "../../components/Manage_Buttons";
const CompanyProfile = () => {
  const { id } = useParams();

  return (
    <Container>
      <Col md={12} lg={11} className="mx-auto">
        <Row>
          <Col md={3} lg={3} className="p-0">
            <CompanySideBar />
          </Col>
          <Col md={9} lg={9} className="screenHeight custom_ps-4">
            <div className="Form-Shadow h-100">
              <Row>
                <Col lg="5">
                  <BackArrow
                    back={`/rawmaterial-business-property/${id}`}
                    mystyle="ps-2 pt-1"
                  />
                </Col>
                <Col lg="7" className="mt-2 pe-4">
                  <Managebuttons id={id} businessurl="/job-companies" />
                </Col>
              </Row>
              <ShopAdds />
              <div>
                <Jobs id={id} />
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};
export default CompanyProfile;
