import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { listpic } from '../../../constants/jsonConstant';
import { Container } from 'react-bootstrap';
// import {images} from '../../../constants/jsonConstant';
import { listspics } from '../../../constants/jsonConstant';
import { viewlist} from '../../../constants/jsonConstant';
import {Col,Row} from 'react-bootstrap';
import "./modal.css";

const Modals = ({setLgShow,lgShow}) => {

 
  
  return (
    <>
 
<Modal
        
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className='ms-auto' id="example-modal-sizes-title-lg">
          Notifications
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='header noscroll'>

       {listpic.map((e) => ( 
          <Row>
            <Col lg={2}>
            <img className='pick' src={e.imgM}  alt=""  />
            
            </Col>
            <Col  lg={10}>
              <Row><Col lg="10" className='text-start'> <h6 className='text-start mb-3'>{e.Name} <p className='PHARA mb-0'>
                {e.title}</p></h6></Col><Col lg="2" className='text-end'>  <p><p className='time'>11:56am</p></p></Col></Row>
    
            </Col>
            <hr/>
          </Row>
        
        ))} 


          {/* {listspics.map((e) => ( 
            <Row>
              <Col lg={1}>
            <img className='pick' src={e.imgM}  alt="" />
            
            </Col>
            
            <Col  lg={11}>
              <Row>
                <Col lg="11" className='text-start'>
                <h6 className='mb-0'>{e.Name}
             <p className='PHARA mb-0'>{e.title}</p>
               </h6>
               </Col>
               <Col lg="1" className='text-end'>  
               <p className='time'>11:56am</p>
               </Col>
               
               </Row>
    
            </Col>
            <hr/>
          </Row>
        
        ))}  */}

        {listspics.map((e) => (
          <Row>
            <Col lg="2">
             <img className='pick' src={e.imgM}  alt="" />
            </Col>
            <Col lg='7'>
            <h6 className='qe'>{e.Name}</h6>
            <p className='PHARA mb-0'>{e.title}</p>
            </Col>
            <Col lg="3">
            <p className='time ms-auto text-end'>11:56am</p>
            <div className='butns'>
            {/* <Button className='butns1' >Accept</Button> */}
            <button className='butns1' >Accept</button>
            <button className='butns2' >Reject</button> 
            </div>
            </Col>
            <hr/>
          </Row>
        ))}

      {viewlist.map((e) => (
          <Row>
            <Col lg="2">
             <img className='pick' src={e.imgM}  alt="" />
            </Col>
            <Col lg='7'>
            <h6 className='qe'>{e.Name}</h6>
            <p className='PHARA mb-0'>{e.title}</p>
            </Col>
            <Col lg="3">
              <div>
                <p className="time  ms-auto text-end">11:56am</p>
                <button className='butns3 ms-5 mb-1' >View</button>     
              </div>
            </Col>
            {/* <Col lg="3">
              <div>

            <p className='time ms-auto text-end'>11:56am</p>
            <button className='butns2' >Reject</button> 
              </div>
            </Col> */}
            <hr/>
          </Row>
        ))}

        </Modal.Body>



      </Modal>
   
      
   
  </>
  )
}

export default Modals
