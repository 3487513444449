import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import DashboardButtons from "../../../../components/Dashboardbtn/dashboardButtons";
import BuyingBusinessModes from "../../../../components/Buying_businessmodes";
import JobOpportunities from "../../Component/JobOppurtunity";
import Shops from "../../Component/Shops";
import SellProperties from "../../Component/SellProperties";
import BuyVehicles from "../../Component/BuyVehicle";
import Transport from "../../Component/Transport";
import Services from "../../Component/Services";
import ScrapMaterial from "../../Component/ScrapMaterial";
import Recycled from "../../Component/Recycled";
import Farmming from "../../Component/Farmming";
import { Helmet } from "react-helmet";
import Email from "../../Component/Email";
import ChatRoom from "../../Component/ChatRoom";
import HelpCenter from "../../Component/HelpCenter";
import { images } from "../../../../constants";
import SellSingleitem from "../../Component/SellSingleitem";
import { BuyingSteps } from "../../../../constants/jsonConstant";
import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import Tour from "reactour";
import Steps from "reactour";
import Generaluserprofile from "./General_user_profile";
import FoodRestaurantComp from "../Food_Restaurantcomp";

const BuyingSide = () => {
  // const [isTourOpen, setIsTourOpen] = useState(false);
  // const [isTourFinished, setIsTourFinished] = useState(false);

  // const [showModal, setShowModal] = useState(false);
  // useEffect(() => {
  //   const hasTourFinished = localStorage.getItem("dashboardTourFinished2");
  //   setIsTourFinished(!!hasTourFinished);
  //   if (!hasTourFinished) {
  //     setShowModal(true);
  //   }
  // }, []);
  // const tourHandler = () => {
  //   if (showModal === true) {
  //     setIsTourOpen(true);
  //     setShowModal(false);
  //     localStorage.setItem("dashboardTourFinished2", "true");
  //   }
  // };

  // const closeTour = () => {
  //   setShowModal(false);
  //   setIsTourOpen(false);
  //   setIsTourFinished(true);
  //   localStorage.setItem("dashboardTourFinished2", "true");
  // };

  // const closeHandler = () => {
  //   localStorage.setItem("dashboardTourFinished2", "true");
  // };

  return (
    <div className="specificpage" style={{ overflow: "hidden" }}>
      <Helmet>
        <body
          style={`background: linear-gradient(119.72deg, #15751F 5.7%, #0D3C82 93.64%);`}
        ></body>
      </Helmet>
      {/* <Modal
        show={showModal}
        onHide={() => {
          closeHandler();
          setShowModal(false);
        }}
      > */}
      {/* <Modal.Header closeButton>
          <Modal.Title>Selectezi Tour</Modal.Title>
        </Modal.Header>
        <Modal.Body>Welcome to Selectezi tour.</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModal(false);
              closeHandler();
            }}
          >
            Close
          </Button>

          <Button variant="primary py-2 tourSize" onClick={() => tourHandler()}>
            Start tour
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Container fluid className=" ps-lg-5 pe-lg-5 ps-md-5 pe-md-5 pt-3 ">
        <div className="screenHeight">
          <div>
            {/* <Tour
              steps={BuyingSteps}
              isOpen={isTourOpen && !isTourFinished}
              onRequestClose={closeTour}
            />
            <Steps steps={BuyingSteps} /> */}

            <div className="pb-4 ">
              <DashboardButtons
                textone=""
                texttwo="Switch To Selling Side"
                linkurl="/SellingSide"
              />
            </div>
            <div className="noscroll dashboard-scroll pt-lg-2 pb-lg-2 ps-lg-3 pe-lg-3 p-md-3 ">
              <Row>
                <Col lg="4" md="12" sm="12">
                  <Generaluserprofile />
                </Col>
                <Col lg="8" className="mt-md-3 mt-sm-3 mt-lg-0">
                  <BuyingBusinessModes
                    btntext="Buy Now"
                    title="Buy From Business Shop"
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col lg="4">
                  <JobOpportunities
                    titleone="Job Offers"
                    titletwo="Candidates Avaliable"
                    btn="View"
                  />
                </Col>

                <Col lg="6" className="mt-md-3 mt-lg-0 mt-sm-3">
                  <Shops btn="Buy Now" title="Buy From Shops" />
                </Col>

                <Col lg="2" className="mt-md-3 mt-lg-0 mt-sm-3">
                  <SellSingleitem btn="Buy Now" title="Buy Single Item" />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col lg="2" md="4" xs="6">
                  <SellProperties title="Buy Property" />
                </Col>

                <Col lg="2" md="4" xs="6" className="mt-sm-3 mt-md-0 mt-lg-0">
                  <BuyVehicles title="Buy Vehicle" />
                </Col>

                <Col lg="2" md="4" xs="6" className="mt-sm-3 mt-md-0 mt-lg-0">
                  <Transport btn="Book Now" />
                </Col>

                <Col lg="2" md="4" xs="6" className="mt-md-4 mt-lg-0 mt-sm-3">
                  <Recycled btn="Buy Now" />
                </Col>

                <Col lg="2" md="4" xs="6" className="mt-md-3 mt-lg-0 mt-sm-3">
                  <Services btn="Hire Now" />
                </Col>

                <Col lg="2" md="4" xs="6" className="mt-sm-3 mt-md-0">
                  <Farmming btn="Buy Now" />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg="2" md="4" xs="6" className="mt-md-0 mt-lg-0 mt-sm-3">
                  <Email />
                </Col>
                <Col lg="2" md="4" xs="6" className="mt-md-0 mt-lg-0 mt-sm-3">
                  <ChatRoom />
                </Col>

                <Col lg="2" md="4" className="mt-md-0 mt-lg-0 mt-sm-3">
                  <ScrapMaterial
                    btn="Buy Now"
                    titleone="Property Adds"
                    titletwo="Property Adds"

                  />
                </Col>
                {/* <Col lg="2" md="4" className="mt-md-3 mt-lg-0 mt-sm-3">
  <SingleAds btn="Check Now" />
</Col> */}
                <Col lg="2" md="4" className="mt-md-3 mt-lg-0 mt-sm-3">
                  <FoodRestaurantComp
                    btn="Buy Now"
                    titleone="Food and Restaurant"
                    titletwo="Food and Restaurant"
                  />
                </Col>

                <Col lg="2" md="4" xs="6" className="mt-md-3 mt-lg-0 mt-sm-3">
                  <HelpCenter btn="Check Now"
                    titleone="User Guide"
                    titletwo="User Guide"
                    img_h={images.Manual} />
                </Col>
                <Col lg="2" md="4" xs="6" className="mt-md-3 mt-lg-0 mt-sm-3">
                  <HelpCenter
                    btn="Contact"
                    titleone="Help Center"
                    titletwo="Helpline"
                    img_h={images.HelpLine}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default BuyingSide;
