import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Row, Col } from "react-bootstrap";
import "../ModalEmail/style.css";
import Form from "react-bootstrap/Form";
import AccessModal from "../Access_modal";
import { useState } from "react";

const SpecificModal = ({ handleCloseaccesstwo, showaccesstwo }) => {
  const [showaccess, setShowaccess] = useState(false);

  const handleCloseaccess = () => setShowaccess(false);
  const handleShowaccess = () => setShowaccess(true);
  return (
    <>
      <AccessModal
        handleCloseaccess={handleCloseaccess}
        showaccess={showaccess}
      />
      <Modal
        className="modal-setone"
        centered
        show={showaccesstwo}
        onHide={handleCloseaccesstwo}
      >
        <Modal.Header className="modalcolor" closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            <b>Give Access To Specific Area</b>
          </p>
          <label>Select Area you want to give access of :</label>
          <Row>
            <Col lg="7">
              {" "}
              <Form>
                {["checkbox"].map((type) => (
                  <div key={`default-${type}`} className="mb-3">
                    <Form.Check
                      type={type}
                      id={`default-${type}`}
                      label={`TimeLine ${type}`}
                    />

                    <Form.Check
                      type={type}
                      label={`Business Dashboard ${type}`}
                      id={`disabled-default-${type}`}
                    />

                    <Form.Check
                      type={type}
                      label={`Shops Report And Suggestions ${type}`}
                      id={`disabled-default-${type}`}
                    />
                  </div>
                ))}
              </Form>
            </Col>

            <Col lg="5">
              {" "}
              <Form>
                {["checkbox"].map((type) => (
                  <div key={`default-${type}`} className="mb-3">
                    <Form.Check
                      type={type}
                      id={`default-${type}`}
                      label={`Order Management ${type}`}
                    />

                    <Form.Check
                      type={type}
                      label={`Post And Edit Items ${type}`}
                      id={`disabled-default-${type}`}
                    />
                  </div>
                ))}
              </Form>
            </Col>
          </Row>

          <div className="d-flex justify-content-end mt-2">
            <Button className="btn-primary" onClick={handleShowaccess}>
              Next
            </Button>
          </div>
        </Modal.Body>

        {/* <Form.Control size="lg" type="text" placeholder="Large text" /> */}
      </Modal>
    </>
  );
};

export default SpecificModal;
