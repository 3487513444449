import React from "react";
import PostAddForm from "../../../../components/forms/postItemsForm/index";
import { useState, useEffect } from "react";
import url from "../../../../config/axios";
import { useNavigate, useParams } from "react-router-dom";
import { SuccessNotify } from "../../../../constants/tostify";
import { FailedNotify } from "../../../../constants/tostify";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
import { publishImage } from "../../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
const WholesalerPostAddForm = () => {
  const { id } = useParams();

  const [mygetitem, setMygetitem] = useState();
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const [shopCategory, setShopCategory] = useState()
  const [displayimg, setDisplayimg] = useState();
  const [shopCategorytwo, setShopCategorytwo] = useState()
  const [data, setData] = useState({
    category: "",
    subCategory: ""
  })

  const Handledata = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split(".")

    }))
  }
  const categoryName = data?.category[1];
  const categoryid = data.category[0];
  const subcategoryName = data?.subCategory[1];
  const subcategoryid = data.subCategory[0];

  useEffect(() => {
    setMygetitem(localStorage.getItem('mycategory'))
  }, [])

  const { name } = useSelector((state) => state.user);

  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setUserData((userData) => ({
        ...userData,
        images: abc
      }));

      setDisplayimg(abc);

    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const [userData, setUserData] = useState({
    businessType: "wholesellerShop",
    userid: "",
    shopid: "",
    isSingleItem: false,
    isScrapItem: false,
    itemname: "",
    category: "",
    categoryid: "",
    subCategory: "",
    subCategoryid: "",
    price: "",
    country: "",
    city: "",
    state: "",
    quantity: "",
    unitOfMeasurement: "",
    currency: "",
    unitPrice: "",
    minOrderQuantity: "",
    images: [""],
    status: true
  });

  useEffect(() => {
    setUserData((userData) => ({
      ...userData,
      category: categoryName,
      categoryid: categoryid,
      subCategory: subcategoryName,
      subCategoryid: subcategoryid

    }));

  }, [categoryid, categoryName, subcategoryName, subcategoryid]);


  const FirstCategory = () => {
    url.get(`/category/type/?type=wholesellerShop&parentCategory=${mygetitem}`)
      .then(async (response) => {
        console.log("response1230---", response)
        if (response?.data?.success) {
          setShopCategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error)
      });
  }


  const Mydata = () => {
    url
      .get(`/category/type/?type=wholesellerShopItem&parentCategory=${categoryName}`)
      .then(async (response) => {
        if (response?.data?.success) {
      
          setShopCategorytwo(response?.data?.data?.Category)
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error)
      });


  }
  const userDatahandler = (e) => {
    setIsloading(true);
    e.preventDefault();
    url.post("/shopitem", userData).then(async (response) => {

      if (response?.data?.success) {
        setIsloading(false);
        setTimeout(() => {
          SuccessNotify("Add Post Successfully")
        }, 1000)
        navigate(`/wholesaler-owner-profile/${id}`)
      }
    }).catch(e => {
      setIsloading(false);
      FailedNotify("Failed To Post Try Again!")
    })



  };
  useEffect(() => {
    url.get(`/shop/user/?userid=${name?._id}`).then((response) => {
      setUserData({
        ...userData,
        userid: response?.data?.data?.Shops[0].userid,
        // shopid: response?.data?.data.Shops.at(-1)._id,
        shopid: id
      });
    });
  }, []);
  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }
  return (
    <PostAddForm
      back="/wholesaler-owner-profile/"
      butn="Post"
      postbtn="/wholesaler-owner-profile"
      cancel="/wholesaler-owner-profile"
      setUserData={setUserData}
      handlerFunction={userDatahandler}
      userData={userData}
      id={id}
      shopCategory={shopCategory}
      shopCategorytwo={shopCategorytwo}
      Mydata={Mydata}
      Handledata={Handledata}
      getInputProps={getInputProps}
      getRootProps={getRootProps}
      displayimg={displayimg}
      setDisplayimg={setDisplayimg}
      FirstCategory={FirstCategory}
    />
  );
};

export default WholesalerPostAddForm;
