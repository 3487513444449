import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/Col";
import { images } from "../../constants";
import { NavLink } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from "react-router";
import { SuccessNotify } from "../../constants/tostify";
import { FailedNotify } from "../../constants/tostify";
import url from "../../config/axios";
import { getAsset } from "../../helpers/helper";
import "./userProfile.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
  Coverimg,
  Profileimg,
  Profiletitle,
  Profilesection,
  Timeline,
} from "../../pages/index";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import BackArrow from "../../components/backArrow";
const Userprofile = (props) => {
  const [leftSide, setLeftSidewidth] = useState(7);
  const [rightSide, setRightSide] = useState(5);
  const { name } = useSelector((state) => state.user);
  const [tabsData, setTabsData] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [infooo, setInfooo] = useState();
  const [info, setInfo] = useState();
  const location = useLocation();

  const [createShop, setCreateShop] = useState({
    text: "",
    privacy: "",
  });

  const [loading, setLoading] = useState(true);

  const [createPost, setCreatePost] = useState({
    shopid: "",
    userid: name?._id,
    businessType: "homemadeShop",
    my: [
      {
        heading: "",
        content: "",
        img: [""],
        video: [""],
      },
    ],
  });
  
  console.log("createPost", createPost);
   useEffect(() => {
    axios
      .get(
        `conection/publicOrShopAgainstPublic/?businessType=homemadeShop&shopid=&userid=${name?._id}`
      )
      .then(async (res) => {
        const responseData = res.data.data;
        const dynamicTabs = Object.keys(responseData).map((key) => ({
          eventKey: key,
          title:
            key.charAt(0).toUpperCase() +
            key.slice(1).replace(/([A-Z])/g, " $1"),
          data: responseData[key],
        }));
        setTabsData(dynamicTabs);
        if (dynamicTabs.length > 0) {
          setActiveTab(dynamicTabs[0].eventKey);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("API call error:", err);
        setLoading(false);
      });
  }, []);

  const handleTabSelect = (eventKey) => {
    setActiveTab(eventKey);
  };

  const getActiveTabData = () => {
    const activeTabData = tabsData.find((tab) => tab.eventKey === activeTab);
    return activeTabData ? activeTabData.data : [];
  };

  const inputChange = (event) => {
    setCreateShop((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const userDatahandler = () => {
    url
      .put(`/user/id/?id=${name?._id}`, {
        description: {
          text: createShop.text,
          privacy: createShop.privacy,
        },
      })
      .then(async (response) => {
        console.log("data", response);
        if (response.data.success) {
          SuccessNotify("Successfully!");
          setInfo(response?.data?.data?.User);
          url.get(`/user/id?id=${name?._id}`).then((response) => {
            setInfooo(response?.data?.data?.User);
          });
        }
      })
      
      .catch((e) => {
        console.log("error", e);
        FailedNotify("Failed! Try Again");
      });
  };

  useEffect(() => {
    url.get(`/user/id?id=${name?._id}`).then((response) => {
      setInfooo(response?.data?.data?.User);
    });
  }, []);

  const widthHandler = () => {
    if (leftSide === 7 && rightSide === 5) {
      setLeftSidewidth(2);
      setRightSide(10);
    } else {
      setLeftSidewidth(7);
      setRightSide(5);
    }
  };
                                         
  console.log("createShop", createShop);
  console.log("infoooo name", infooo);

  return (
    <>
      <Container>
        <Row className="gx-3">
          {leftSide === 7 && rightSide === 5 ? (
            <Col lg={leftSide} sm="12" className="ps-0 pe-0 screenHeight">
              <div className="b-radius border">
                <BackArrow back="/SellingSide" mystyle="ps-2 p-1" />
                <Coverimg
                  widthHandler={widthHandler}
                  inputChange={inputChange}
                  userDatahandler={userDatahandler}
                  setCreateShop={setCreateShop}
                  infooo={infooo}
                  name={name}
                  setInfooo={setInfooo}
                />

                <Profileimg infooo={infooo} name={name} setInfooo={setInfooo} />

                <Profiletitle
                  userDatahandler={userDatahandler}
                  inputChange={inputChange}
                  infooo={infooo}
                />
                <div className="bg-vilot">
                  <Profilesection
                    userDatahandler={userDatahandler}
                    infooo={infooo}
                    setInfooo={setInfooo}
                  />
                </div>
              </div>
            </Col>
          ) : (
            <Col lg={2} sm="12" className="ps-0 pe-0 screenHeight">
              <div className="miniProfile">
                {/* <div className="position-relative">
                  <div className="position-absolute end-0 absolute-setting">
                    <div className="dragImg" onClick={() => widthHandler()}>
                      <img src={images.Arrowleft} alt="" />
                    </div>
                  </div>
                </div> */}
                <div className="Profile-img-mini">
                  <img
                    className="ms-3"
                    src={getAsset(infooo?.imageUrl[0])}
                    alt=""
                    crossOrigin="anonymous"
                  />
                </div>
                <Col
                  lg="12"
                  md="12"
                  className="d-flex justify-content-between mt-2"
                >
                  <div>
                    <h4 className="ms-3">{name?.fullname}</h4>
                    <h5 className="ms-3" style={{ fontSize: "12px" }}>
                      <p>{name?.description?.text}</p>
                    </h5>
                  </div>
                </Col>
                <div className="bg-vilot">
                  <div>
                    <div className=" bg-white pr-0 mt-2 ">
                      <h4 className="ms-3">Intro</h4>
                      <ListGroup
                        className={`introBox list-unstyled font_small_profile ${
                          leftSide === 1 ? "lessWidthData" : ""
                        }`}
                      >
                        <ListGroup.Item className="d-flex ms-3 border-0 p-1 ">
                          <div>{name?.address?.text}</div>
                        </ListGroup.Item>
                        {infooo?.education?.map((item) => (
                          <ListGroup.Item className="d-flex ms-3 border-0 p-1">
                            <div>{item?.text}</div>
                          </ListGroup.Item>
                        ))}

                        <ListGroup.Item className="d-flex ms-3 border-0 p-1">
                          <div>{infooo?.contactNumber?.text}</div>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex ms-3 border-0 p-1">
                          <div> {infooo?.email}</div>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex ms-3 border-0 p-1">
                          <div> {infooo?.createdAt.slice(0, 10)}</div>
                        </ListGroup.Item>
                      </ListGroup>
                      {location.pathname === "/user-profile" ? (
                        <Col lg="12">
                          <NavLink to="/user-cv-form">
                            <div className="d-flex justify-content-center">
                              <Button
                                type="button"
                                className="btn ms-1 py-2 px-2 btn-primary-mini  font_small_profile mb-4 mt-4"
                              >
                                Create Cv
                              </Button>
                            </div>
                          </NavLink>
                        </Col>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}
          <Col
            lg={rightSide}
            sm="12"
            className="ps-4 screenHeight"
            style={{ position: "relative" }}
          >
            <div className="border b-radius">
              <Timeline
                tabsData={tabsData}
                activeTab={activeTab}
                onTabSelect={handleTabSelect}
                tabData={getActiveTabData()}
                loading={loading}
                createPost={createPost}
                setCreatePost={setCreatePost}
                setLoading={setLoading}
                // id={id}
                rightSide={rightSide}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Userprofile;
