import BuyingFilter from "../../../components/filteration/buyingFilter";
import ShopAdds from "../../../components/shopAds/ShopAdds";
import Shops from "../../../components/Multipleshopprofile/Components/Shops/Shops";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import ShareModel from "../../../components/Sharemodel";
import ShareOption from "../../../components/Shareoption";
import BackArrow from "../../../components/backArrow";
import url from "../../../config/axios";

const BuyRawMaterial = () => {
  const [data, setData] = useState(0);
  const [isloading, setIsloading] = useState();
  const [createshopcategory, setCreateshopcategory] = useState();
  const [shopdata, setShopdata] = useState();
  const [showmodal, setShowmodal] = useState(false);
  const handleClosemodal = () => setShowmodal(false);
  const handleShow = () => setShowmodal(true);
  const [dataCategory, setDataCategory] = useState({
    categoryid: "",
  });
  const [itemmdata, setItemmdata] = useState();
  const [shopCategory, setShopCategory] = useState();
  const [shopCategorytwo, setShopCategorytwo] = useState();
  const [showshare, setShowshare] = useState(false);
  const handleCloseshare = () => {
    setShowshare(false);
    setShowmodal(true);
  };
  const handleShowshare = () => setShowshare(true);

  const Handledata = (e) => {
    setDataCategory((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split("."),
    }));
  };

  const categoryName = dataCategory?.categoryid[1];
  const categoryidone = dataCategory.categoryid[0];

  useEffect(() => {
    setAdditionalShopCategoryData((additionalShopCategoryData) => ({
      ...additionalShopCategoryData,
      categoryid: categoryidone !== undefined ? categoryidone : "",
    }));
  }, [categoryidone]);

  useEffect(() => {
    url
      .get("category/type/?type=rawMaterialShop&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setCreateshopcategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });
  }, []);

  useEffect(() => {
    url
      .get("category/type/?type=rawMaterialShop&parentCategory=true")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });
  }, []);

  const Mydata = () => {
    url
      .get(
        `category/type/?type=rawMaterialShopItem&parentCategory=${categoryName}`
      )
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategorytwo(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });
  };

  const [additionalShopCategoryData, setAdditionalShopCategoryData] = useState({
    id: "",
    shopid: "",
    shopname: "",
    itemname: "",
    marketPlace: "",
    categoryid: "",
    subCategoryid: "",
    shopCategory: "",
    country: "",
    state: "",
    city: "",
    specialOffer: "",
    sale: "",
    min: "",
    max: "",
  });
  console.log("additionalShopCategoryData", additionalShopCategoryData);
  const Handleadditioanalshop = (e) => {
    setAdditionalShopCategoryData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const FilterHandler = () => {
    setIsloading(true);
    url
      .get(`/shop/filter?shopname=${additionalShopCategoryData?.shopname}&filtermethod=shop&shopCategory=${additionalShopCategoryData?.shopCategory}&marketPlace=${additionalShopCategoryData?.marketPlace}&shopid=${additionalShopCategoryData?.shopid}&specialOffer=${additionalShopCategoryData?.specialOffer}&sale=${additionalShopCategoryData?.sale}&businessType=rawmaterialShop`)
      .then(async (response) => {
        console.log("search 2", response)
        if (response?.data?.success) {
          setIsloading(false);
          setShopdata(response?.data?.Shop);
          setItemmdata(response?.data?.ShopItem);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
        setIsloading(false);
      });
  };

  const FilterHandleritem = () => {
    setIsloading(true);
    url
      .get(`/shop/filter?itemname=${additionalShopCategoryData?.itemname}&filtermethod=shopitem&categoryid=${additionalShopCategoryData?.categoryid}&subCategoryid=${additionalShopCategoryData?.subCategoryid}&country=${additionalShopCategoryData?.country}&state=${additionalShopCategoryData?.state}&city=${additionalShopCategoryData?.city}&specialOffer=${additionalShopCategoryData?.specialOffer}&min=${additionalShopCategoryData?.min}&max=${additionalShopCategoryData?.max}&businessType=rawmaterialShop`)
      .then(async (response) => {
        console.log("search 1", response)
        if (response?.data?.success) {
          setIsloading(false);
          setShopdata(response?.data?.Shop);
          setItemmdata(response?.data?.ShopItem);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
        setIsloading(false);
      });
  };
  return (
    <>
      <ShareOption showshare={showshare} handleCloseshare={handleCloseshare} />
      <ShareModel
        showmodal={showmodal}
        handleClosemodal={handleClosemodal}
        handleShowshare={handleShowshare}
      />
      <Container>
        <Row>
          <Col lg="4" className="screenHeight background-image">
            <div className="border b-radius height_100">
              <BuyingFilter
                Handleadditioanalshop={Handleadditioanalshop}
                FilterHandler={FilterHandler}
                dataCategory={dataCategory}
                createshopcategory={createshopcategory}
                shopCategory={shopCategory}
                Handledata={Handledata}
                shopCategorytwo={shopCategorytwo}
                Mydata={Mydata}
                FilterHandleritem={FilterHandleritem}
              />
            </div>
          </Col>
          <Col lg="8" className="screenHeight">
            <div className="border b-radius ps-2 pe-2 ">
              <BackArrow back="/BuyingSide" mystyle="ps-1 pt-1" />
              <ShopAdds />
              <Shops
                link="/rawmaterial-userprofile"
                title="Raw Material"
                isloading={isloading}
                data={data}
                handleShow={handleShow}
                shopdata={shopdata}
                itemmdata={itemmdata}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default BuyRawMaterial;
