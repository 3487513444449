import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { images } from '../../../../constants'
import './style.css'
import { useLocation } from 'react-router-dom'

const ChatingSide = () => {

    const location = useLocation()

    return (
        <div>

            <Container>

                <div className='classs' >

                    <Row className=' mt-3 text-secondary d-flex align-items-center justify-content-center'>

                        <Row>
                            <Col lg='6'>
                                <h6 className='text-dark'>Interviews</h6>
                            </Col>
                            {location.pathname === '/email-spam' && (
                            <Col className='d-flex justify-content-end' lg='6'>
                                <h6 className='text-dark'> <img src={images.Spamm} width='20' height='20' /> Remove From Spam Folder</h6>
                            </Col>)}

                        </Row>
                        <Col className='d-flex align-items-center' lg='2' md='2' sm='2' xs='2'>
                            <img className='' width='35' height='35' src={images.JuliaSideCard} />
                            <p className='fw-normal fs-5 mt-3 ms-3'>Julia</p>
                        </Col>

                        <Col className=' fs-6 mt-3 d-flex justify-content-end' lg='10' md='10' sm='10' xs='10'>
                            <p>May 17 , 2022 , 2 : 30 pm</p>
                        </Col>

                    </Row>

                    <p className='fs-6 fw-normal text-dark mt-1'>Hi Dear,</p>

                    <p className=' fs-5 mt-1 text-center text-primary'> “When you sit yourself down and commit to drawing.”</p>

                    <img className='two-hands-img' src={images.TwoHands} />

                    <p className=' fs-5 text-center mt-1'> “When you sit yourself down and commit to drawing.”</p>

                </div>

            </Container>

        </div>
    )
}

export default ChatingSide
