import BuyingFilter from "../../components/filteration/buyingFilter";
import UserItemShop from "../../components/UserShopItem/UserItemShop";
import ShareModel from "../../components/Sharemodel";
import ShareOption from "../../components/Shareoption";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import BackArrow from "../../components/backArrow";
import url from "../../config/axios";
const ScrapOnline = () => {
  const [data, setData] = useState(0);
  const [showmodal, setShowmodal] = useState(false);
  const handleClosemodal = () => setShowmodal(false);
  const handleShow = () => setShowmodal(true);
  const [filterdata, setFilterdata] = useState({
    isSingleItem: false,
    scrapType: "",
    country: "",
    state: "",
    city: "",
    min: "",
    max: "",
  });
  console.log("filterdataaaaaaaa", filterdata)

  const [scrapdata, setScrapdata] = useState();
  const [showshare, setShowshare] = useState(false);
  const handleCloseshare = () => {
    setShowshare(false);
    setShowmodal(true);
  };
  const handleShowshare = () => setShowshare(true);

  //integration of scrap material

  const [createscrapcategory, setCreatescrapcategory] = useState();
  console.log("", createscrapcategory)

  const Mydataone = () => {
    url
      .get("category/consumer/?type=scrapMaterial")
      .then(async (response) => {
        console.log("response", response)
        if (response?.data?.success) {
          setCreatescrapcategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("scrapcategory", error);
      });
  };
  const Handleadditioanalshop = (e) => {
    setFilterdata((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const FilterHandler = () => {
    url
      .get(
        `/shop/filter?scrapType=${filterdata?.scrapType}&country=${filterdata?.country}&state=${filterdata?.state}&city=${filterdata?.city}&min=${filterdata?.min}&max=${filterdata?.max}&isSingleItem=false&isScrapItem=true`
      )
      .then(async (res) => {
        // setScrapdata(res);
        console.log("scrapppp datttttta", res)
      });
  };
  console.log(scrapdata, ";;;;;;;;;;;")


  return (
    <>
      <ShareOption showshare={showshare} handleCloseshare={handleCloseshare} />
      <ShareModel
        showmodal={showmodal}
        handleClosemodal={handleClosemodal}
        handleShowshare={handleShowshare}
      />
      <Container>
        <Row>
          <Col lg="4" className="screenHeight background-image">
            <div className="border b-radius height_100">
              <BuyingFilter
                FilterHandler={FilterHandler}
                Mydataone={Mydataone}
                createscrapcategory={createscrapcategory}
                Handleadditioanalshop={Handleadditioanalshop}
              />
            </div>
          </Col>
          <Col lg="8" className="screenHeight">
            <div className="border b-radius ps-2 pe-2">
              <BackArrow back="/buy-used-products" mystyle=" pt-1 ps-1" /> <UserItemShop />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ScrapOnline;
