import React, { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import url from "../../../../config/axios";
import { FailedNotify, SuccessNotify } from "../../../../constants/tostify";
import { publishImage } from "../../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";

const ProfileInformationForm = () => {
  const { name } = useSelector((state) => state.user);
  const [isloading, setIsloading] = useState(false);
  const [displaytext, setDisplaytext] = useState("");


  const [cvpostdata, setCvpostdata] = useState({
    user: name._id,
    profileInformation: {
      firstName: "",
      lastName: "",
      jobDesignation: "",
      email: "",
      primaryNumber: "",
      secondaryPhone: "",
      addressLine1: "",
      addressLine2: "",
      permanentResidence: "",
      postalcode: "",
      gender: "",
      dob: "",
      cob: "",
      pob: "",
      nationality: "",
      idType: "",
      idNumber: "",
      martialStatus: "",
      visaStatus: "",
      jobTitle: "",
      portfolioLink: "",
      photo: "",
    },
  });

  const onDrop = async (acceptedFiles) => {
    setDisplaytext("Please wait...");
    try {
      const abc = await publishImage(acceptedFiles);
      setCvpostdata((prevState) => ({
        ...prevState,
        profileInformation: {
          ...prevState.profileInformation,
          photo: abc,
        },
      }));
      setDisplaytext("")
    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  };

  const { getRootProps: getRootPropsPort, getInputProps: getInputPropsPort } =
    useDropzone({
      accept: "image/*",
      onDrop,
    });
  console.log("", cvpostdata)

  const handelvalues = (e) => {
    setCvpostdata((prevState) => ({
      ...prevState,
      profileInformation: {
        ...prevState.profileInformation,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const navigate = useNavigate();
  const handleKeyPress = (event) => {
    const charCode = event.charCode;
    // Allow only alphabetical characters
    if (
      !(charCode >= 65 && charCode <= 90) &&
      !(charCode >= 97 && charCode <= 122)
    ) {
      event.preventDefault();
    }
  };

  const inputfile = useRef(null);
  const upload_img = () => {
    inputfile.current.click();
  };


  const PostCvData = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("cvform/postNew", cvpostdata)
      .then(async (response) => {
        if (response?.data?.message.length > 0) {
          setIsloading(false);
          SuccessNotify("Successfully Save Data");
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed to Save CV Data");
      });
  };

  return (
    <div>
      <Row>

        <Col md={3} lg={3}>
          <Form.Label className="custm-lble ">First Name</Form.Label>
          <Form.Control
            placeholder="First Name"
            type="text"
            name="firstName"
            className="labelText"
            onKeyPress={handleKeyPress}
            onChange={(e) => handelvalues(e)}
          />
        </Col>
        <Col md={3} lg={3}>
          <Form.Label className="custm-lble ">Last Name</Form.Label>
          <Form.Control
            placeholder="Last Name"
            type="text"
            name="lastName"
            className="labelText"
            onChange={(e) => handelvalues(e)}
          />
        </Col>
        <Col md={3} lg={3}>
          <Form.Label className="custm-lble ">Job Designation</Form.Label>
          <Form.Control
            placeholder="Job Designation"
            type="text"
            name="jobDesignation"
            className="labelText"
            onChange={(e) => handelvalues(e)}
          />
        </Col>
        <Col md={3} lg={3}>
          <Form.Label className="custm-lble ">Email</Form.Label>
          <Form.Control
            placeholder="Email"
            type="text"
            name="email"
            className="labelText"
            onChange={(e) => handelvalues(e)}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3} lg={3}>
          <Form.Label className="custm-lble">Primary Number</Form.Label>
          <Form.Control
            placeholder="Primary Number"
            type="number"
            name="primaryNumber"
            className="labelText"
            onChange={(e) => handelvalues(e)}
          />
        </Col>
        <Col md={3} lg={3}>
          <Form.Label className="custm-lble ">Secondary Number</Form.Label>
          <Form.Control
            placeholder="Secondary Number"
            type="number"
            name="secondaryPhone"
            className="labelText"
            onChange={(e) => handelvalues(e)}
          />
        </Col>
        <Col md={3} lg={3}>
          <Form.Label className="custm-lble ">Address line 1</Form.Label>
          <Form.Control
            placeholder="Address line 1"
            type="text"
            name="addressLine1"
            className="labelText"
            onChange={(e) => handelvalues(e)}
          />
        </Col>
        <Col md={3} lg={3}>
          <Form.Label className="custm-lble">Address line 2</Form.Label>
          <Form.Control
            placeholder="Address line 2"
            type="text"
            name="addressLine2"
            className="labelText"
            onChange={(e) => handelvalues(e)}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3} lg={3}>
          <Form.Label className="custm-lble ">Permanent Address</Form.Label>
          <Form.Control
            placeholder="Permanent Address"
            type="text"
            name="permanentResidence"
            className="labelText"
            onChange={(e) => handelvalues(e)}
          />
        </Col>
        <Col md={3} lg={3}>
          <Form.Label className="custm-lble ">Postal Code</Form.Label>
          <Form.Control
            placeholder="Postal Code"
            type="text"
            name="postalcode"
            className="labelText"
            onChange={(e) => handelvalues(e)}
          />
        </Col>
        <Col md={3} lg={3}>
          <Form.Label className="custm-lble ">Gender</Form.Label>
          <Form.Control
            placeholder="Gender"
            type="text"
            name="gender"
            className="labelText"
            onChange={(e) => handelvalues(e)}
          />
        </Col>
        <Col md={3} lg={3}>
          <Form.Label className="custm-lble ">Date of Birth</Form.Label>
          <Form.Control
            placeholder="Date of Birth"
            type="text"
            name="dob"
            className="labelText"
            onChange={(e) => handelvalues(e)}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3} lg={3}>
          <Form.Label className="custm-lble ">Country of Birth</Form.Label>
          <Form.Control
            placeholder="Country of Birth"
            type="text"
            name="cob"
            className="labelText"
            onChange={(e) => handelvalues(e)}
          />
        </Col>
        <Col md={3} lg={3}>
          <Form.Label className="custm-lble ">Place of Birth</Form.Label>
          <Form.Control
            placeholder="Place of Birth"
            type="text"
            name="pob"
            className="labelText"
            onChange={(e) => handelvalues(e)}
          />
        </Col>
        <Col md={3} lg={3}>
          <Form.Label className="custm-lble ">Nationality</Form.Label>
          <Form.Control
            placeholder="Nationality"
            type="text"
            name="nationality"
            className="labelText"
            onChange={(e) => handelvalues(e)}
          />
        </Col>
        <Col md={3} lg={3}>
          <Form.Label className="custm-lble ">ID Type</Form.Label>
          <Form.Control
            placeholder="ID Type"
            type="text"
            name="idType"
            className="labelText"
            onChange={(e) => handelvalues(e)}
          />
        </Col>
      </Row>
      <Row>
        {/* <Col md={3} lg={3}>
          <Form.Label className="custm-lble ">ID Type</Form.Label>
          <Form.Control
            placeholder="ID Type"
            type="text"
            name=""
            className="labelText "
          />
        </Col> */}
        <Col md={3} lg={3}>
          <Form.Label className="custm-lble ">ID Number</Form.Label>
          <Form.Control
            placeholder="ID Number"
            type="text"
            name="idNumber"
            className="labelText"
            onChange={(e) => handelvalues(e)}
          />
        </Col>
        <Col md={3} lg={3}>
          <Form.Label className="custm-lble ">Marital Status</Form.Label>
          <Form.Control
            placeholder="Marital Status"
            type="text"
            name="martialStatus"
            className="labelText"
            onChange={(e) => handelvalues(e)}
          />
        </Col>
        <Col md={3} lg={3}>
          <Form.Label className="custm-lble ">Visa Status</Form.Label>
          <Form.Control
            placeholder="Visa Status"
            type="text"
            name="visaStatus"
            className="labelText"
            onChange={(e) => handelvalues(e)}
          />
        </Col>
        <Col md={3} lg={3}>
          <Form.Label className="custm-lble ">Job Title</Form.Label>
          <Form.Control
            placeholder="Marital Status"
            type="text"
            name="jobTitle"
            className="labelText"
            onChange={(e) => handelvalues(e)}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3} lg={3}>
          <Form.Label className="custm-lble ">Portfolio Link</Form.Label>
          <Form.Control
            placeholder="Sub Category"
            type="text"
            name="portfolioLink"
            className="labelText"
            onChange={(e) => handelvalues(e)}
          />
        </Col>
        <Col md={3} lg={2}>
          <Form.Label className="custm-lble">Upload Photo</Form.Label>
          <div {...getRootPropsPort()}>
            <CameraAltIcon />
            <input
              type="file"
              id="file"
              {...getInputPropsPort()}
              style={{ display: "none" }}
              name="photo"
            />
          </div>
          <p className="font_13 mb-0 ms-2">{displaytext}</p>
        </Col>
        {/* <Col
          lg={3}
          xl={3}
          className="mt-1 d-flex justify-content-start gap-2 p-lg-0 "
        >
          {uploadedImages.map((image, index) => (
            <Card
              style={{
                width: "6rem",
                height: "6rem",
                border: "2px solid",
              }}
              key={index}
            >
              <Card.Img
                className="imet-fit upload-img"
                src={image}
                alt={`Uploaded ${index}`}
              />
              <div className="close-btnone" onClick={() => removeImage(index)}>
                <ClearIcon />
              </div>
            </Card>
          ))}
        </Col> */}
      </Row>
      <div className="d-flex justify-content-end pt-3 pb-4 ">
        <div
          className={
            cvpostdata?.profileInformation?.portfolioLink === ""
              ? "d-none"
              : "d-block"
          }
        >
          <button className="m-0 btn-color" onClick={PostCvData}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
export default ProfileInformationForm;
