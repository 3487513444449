import ServicesForm from "../../../../components/forms/servicesform/index";
const CreateService = () => {
  return (
    <div>
      <ServicesForm
        title="Fill to Create Services Profile"
        btn="Create"
        url="/services-profile"
        back="/service-integrate"
      />
    </div>
  );
};
export default CreateService;
