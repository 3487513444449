import React from "react";
import BoxData from "../Box";
import CardNav from "../CardNav";
import { Col, Row, Card } from "react-bootstrap";
import { useState } from "react";
const HelpCenter = ({ btn, titleone, titletwo, img_h }) => {
  const [id,setIs]=useState(1);
  const [myinfo,setMyinfo]=useState("single info")
  return (
    <div>
      <Row className="set-one">
        <Col lg="12">
        <CardNav Header={titleone} myid={id} myinfo={myinfo} />
          <Card className="shadow-main mt-2">
            <BoxData
              Title={titletwo}
              Butonn={btn}
              imgg={img_h}
              url="/customer-support"
              link="/customer-support"
              ms="twentysix-element"
              buy="twenty-four"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HelpCenter;
