import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormalEducation } from "../../../../constants/jsonConstant";
import { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import url from "../../../../config/axios";
import { FailedNotify, SuccessNotify } from "../../../../constants/tostify";
import { useSelector } from "react-redux";
const FormalEducationForm = () => {
  const [addeducation, setEducation] = useState(FormalEducation);
  const { name } = useSelector((state) => state.user);
  const [isloading, setIsloading] = useState(false);
  const AddmoreFields = () => {
    setEducation([...addeducation, addeducation]);
  };
  const handleRemoveClick = (index) => {
    const list = [...addeducation];
    list.splice(index, 1);
    setEducation(list);
  };
  
  const [cvdata, setCvdata] = useState({
    user: name._id,
    
    formaleducation: [
      {
        institutename: "",
        startdate: "",
        enddate: "",
        major: "",
        qualification: "",
        typeofstudy: "",
        degreestatus: "",
      },
    ],
  });

  const handleKeyPress = (event) => {
    const charCode = event.charCode;
    // Allow only alphabetical characters
    if (
      !(charCode >= 65 && charCode <= 90) &&
      !(charCode >= 97 && charCode <= 122)
    ) {
      event.preventDefault();
    }
  };
  const handelvalues = (e) => {
    setCvdata((prevState) => ({
      ...prevState,
      formaleducation: {
        ...prevState.formaleducation,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const PostCvData = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("cvform/postNew", cvdata)
      .then(async (response) => {
        if (response?.data?.message.length > 0) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify(" Successfully Save CV Data");
          }, 1000);
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed to Save CV Data");
      });
  };
  return (
    <div className="px-2">
      {addeducation?.map((addformalform, index) => (
        <>
          <Row>
            <Col md={4} lg={4}>
              <Form.Label className="custm-lble ">
                {addformalform?.institutename}
              </Form.Label>
              <Form.Control
                placeholder="Institute Name"
                type="text"
                name="institutename"
                className="labelText "
                onKeyPress={handleKeyPress}
                onChange={(e) => handelvalues(e)}
              />
            </Col>
            <Col md={4} lg={4}>
              <Form.Label className="custm-lble ">
                {addformalform?.Startdate}
              </Form.Label>
              <Form.Control
                placeholder="Start Date"
                type="text"
                name="startdate"
                className="labelText "
                onChange={(e) => handelvalues(e)}
              />
            </Col>
            <Col md={4} lg={4}>
              <Form.Label className="custm-lble ">
                {addformalform?.enddate}
              </Form.Label>
              <Form.Control
                placeholder="End Date"
                type="text"
                name="enddate"
                className="labelText "
                onChange={(e) => handelvalues(e)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} lg={4}>
              <Form.Label className="custm-lble ">
                {addformalform?.major}
              </Form.Label>
              <Form.Control
                placeholder="Major"
                type="text"
                name="major"
                className="labelText "
                onChange={(e) => handelvalues(e)}
              />
            </Col>
            <Col md={4} lg={4}>
              <Form.Label className="custm-lble ">
                {addformalform?.qualification}
              </Form.Label>
              <Form.Control
                placeholder="Qualification"
                type="text"
                name="qualification"
                className="labelText "
                onChange={(e) => handelvalues(e)}
              />
            </Col>
            <Col md={4} lg={4}>
              <Form.Label className="custm-lble ">
                {addformalform?.typeofstudy}
              </Form.Label>
              <Form.Control
                placeholder="Type of Study"
                type="text"
                name="typeofstudy"
                className="labelText "
                onChange={(e) => handelvalues(e)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} lg={4}>
              <Form.Label className="custm-lble ">
                {addformalform?.degreestatus}
              </Form.Label>
              <Form.Control
                placeholder="Degree Status"
                type="text"
                name="degreestatus"
                className="labelText "
                onChange={(e) => handelvalues(e)}
              />
            </Col>
          </Row>
          {index === 0 ? (
            " "
          ) : (
            <div className="d-flex justify-content-end cursor_P">
              <div>
                <DeleteIcon className="color-red" />
              </div>
              <div
                className="d-flex align-items-center"
                onClick={() => handleRemoveClick()}
              >
                <p className="color-remove-red mb-0">Remove</p>
              </div>
            </div>
          )}
          <div class="line-width">
            <hr className="mb-2"></hr>
          </div>
          <div onClick={() => AddmoreFields()}>
            <p className="color-text cursor_P">
              <AddCircleIcon style={{ fontSize: "large" }} /> Add More
            </p>
          </div>
        </>
      ))}
      {cvdata?.formaleducation?.degreestatus?.length > 0 ? (
        <div className="d-flex justify-content-end pt-3 pb-4 ">
          <div>
            <button className="m-0 btn-color" onClick={PostCvData}>
              Save
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default FormalEducationForm;
