import { Col, Row, Button } from "react-bootstrap";
import BackArrow from "../../backArrow";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import { useRef } from "react";
import PersonalImage from "../../../helpers/fileUpload";
import { useState } from "react";
import { useEffect } from "react";
import Card from "react-bootstrap/Card";
import { images } from "../../../constants";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import { currencies } from "../../../constants/jsonConstant";
import { getAsset } from "../../../helpers/helper";
import { Formik } from "formik";
import * as Yup from "yup";
const PropertyAdForm = ({
  butn,
  id,
  back,
  setPropertyadd,
  propertyadd,
  title,
  Updatehandler,
  handlerFunction,
  index,
  itemid,
  shopCategorytwo,
  shopCategory,
  Handledata,
  Mydata,
  Firstcategory,
  link,
  getInputProps,
  getRootProps,
  displayimg,
  setDisplayimg
}) => {
  const [selectedImage, setSelectedImage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const inputfile = useRef(null);
  const upload_img = () => {
    inputfile.current.click();
  };



  const removeImage = (index) => {
    const updatedImages = [...displayimg];
    updatedImages.splice(index, 1);
    setDisplayimg(updatedImages);
  };

  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedstateone, setSelectedstateone] = useState("");
  const [searchTermone, setSearchTermone] = useState("");

  const [selectedcity, setSelectedcity] = useState("");
  const [searchTermtwo, setSearchTermtwo] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle the country selection
  const handleCountryChange = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
  };

  const handleSearchChangestate = (event) => {
    setSearchTermone(event.target.value);
  };

  const handleSearchChangecity = (event) => {
    setSearchTermtwo(event.target.value);
  };

  // Function to handle the state selection
  const handleCountryChangestate = (event) => {
    const { value } = event.target;
    setSelectedstateone(value);
  };

  // Function to handle the city selection
  const handleCountryChangecity = (event) => {
    const { value } = event.target;
    setSelectedcity(value);
  };
  const [hidefield, setHidefield] = useState(1);
  const [hidestate, setHidestate] = useState(1);
  const [hidecity, setHidecity] = useState(1);

  const [countrydata, SetCountrydata] = useState([]);
  const [getcountry] = useState();
  const [selectedState] = useState();
  const [getState, setState] = useState([]);
  const [getcity, setGetcity] = useState([]);
  const [statetwo, setStatetwo] = useState(getcountry);
  const [city, setCity] = useState(selectedState);
  const [open, setOpen] = useState(getcountry);

  const handleValue = (event) => {
    setPropertyadd((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => SetCountrydata(res.data))

      .catch((error) => { });
  }, []);

  const country = [...new Set(countrydata.map((item) => item.country))];

  country.sort();

  const handleCountry = (e) => {
    let states = countrydata.filter(
      (state) => state.country === e.target.value
    );
    states = [...new Set(states.map((item) => item.subcountry))];
    states.sort();
    setState(states);
  };

  const handleState = (e) => {
    let cities = countrydata.filter(
      (city) => city.subcountry === e.target.value
    );
    setGetcity(cities);
  };

  const validationSchema = Yup.object({
    category: Yup.string().required("category is required*"),
    subCategoryid: Yup.string().required("subCategory is required*"),
    country: Yup.string().required("country is required*"),
    state: Yup.string().required("state is required*"),
    city: Yup.string().required("city is required*"),
    price: Yup.string().required("price is required*"),
    currency: Yup.string().required("currency is required*"),
    area: Yup.string().required("area is required*"),
    description: Yup.string().required("description is required*"),
    email: Yup.string().required("Email is required*"),
  });

  return (
    <div>

      <Row>
        <div className="screenHeight d-flex justify-content-center align-items-center">
          <Col
            lg={9}
            md={11}
            sm={12}
            xs={12}
            className="Form-Shadow  pb-3 col-lg-10 col-md-12 col-sm-12"
          >
            {location.pathname === `/update-business-Ad/${itemid}` ? (
              <div className="arrowback ps-3">
                <BackArrow back="/SellingSide" black="black" />
              </div>
            ) : (
              <div className="arrowback ps-3">
                <BackArrow
                  back={`/personal-add/${itemid}`}
                  black="black"
                  mystyle="p-1 ps-3"
                />
              </div>
            )}

            <div className="p-3">
              <Formik
                initialValues={{
                  category: "",
                  subCategoryid: "",
                  country: "",
                  state: "",
                  city: "",
                  price: "",
                  currency: "",
                  area: "",
                  description: "",

                }}
                // validateOnChange={false}
                validateOnBlur={false}
                validationSchema={validationSchema}
                onSubmit={(values) => { }}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  errors,
                  touched,
                  isValid,
                  dirty,
                }) => (
                  <Form
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <Row>

                      <Col className="ps-4" md={3} lg={3} xl={3}>
                        <Form.Label className="custm-lble">
                          Select Category
                        </Form.Label>
                        <div className="bluedrop"> <Form.Select
                          className="input-style form-control-seller"
                          aria-label="Default select example"
                          name="category"
                          onChange={(e) => {
                            Handledata(e);
                            handleChange(e);
                          }}
                          onClick={Firstcategory}
                        >
                          <option>Select Category</option>
                          {shopCategory?.map((category) => (
                            <>
                              <option
                                key={category._id}
                                value={`${category?._id}.${category?.name}`}
                              >
                                {category?.name}
                              </option>
                            </>
                          ))}
                        </Form.Select></div>

                      </Col>



                      <Col className="ps-4" md={3} lg={3} xl={3}>
                        <Form.Label className="custm-lble">
                          Select Sub-Category
                        </Form.Label>
                        <div className="bluedrop">
                          <Form.Select
                            className="input-style form-control-seller"
                            aria-label="Default select example"
                            name="subCategoryid"
                            defaultValue={propertyadd?.category}
                            onChange={(e) => {
                              Handledata(e);
                              handleChange(e);
                            }}
                            onClick={Mydata}
                          >
                            <option  >Select Sub-Category</option>
                            {shopCategorytwo?.map((category) => (
                              <>
                                <option
                                  key={category._id}
                                  value={`${category?._id}.${category?.name}`}
                                >
                                  {category?.name}
                                </option>
                              </>
                            ))}
                          </Form.Select>
                        </div>

                      </Col>

                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword4"
                          placeholder="First name"
                          className="form-label labelSize"
                        >
                          Country*
                        </label>
                        <div className="blood-type input-style">
                          <div className="d-flex">
                            <div className="w-100">
                              <input
                                type="search"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Search country here...."
                                className="w-100 search-input"
                                onClick={() => setHidefield(0)}
                              />
                            </div>
                            <div>
                              <span>
                                <SearchIcon className="search-icon-fs" />
                              </span>
                            </div>
                          </div>

                          <select
                            onClick={() => setHidefield(1)}
                            value={selectedCountry}
                            onChange={(e) => {
                              handleCountryChange(e);
                              handleCountry(e);
                              handleValue(e);
                              handleChange(e);
                            }}
                            className="b-radius selectSize w-100 "
                            name="country"
                          >
                            {hidefield && <option>Select country</option>}

                            {country
                              .filter((country) =>
                                country
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                              )
                              .map((country, index) => (
                                <option key={country} value={getcountry}>
                                  {country}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword4"
                          placeholder="First name"
                          className="form-label labelSize"
                        >
                          State*
                        </label>
                        <div className="blood-type  input-style">
                          <div className="d-flex">
                            <div className="w-100">
                              <input
                                type="search"
                                value={searchTermone}
                                onChange={handleSearchChangestate}
                                placeholder="Search state here...."
                                className="w-100 search-input"
                                onClick={() => setHidestate(0)}
                              />
                            </div>
                            <div>
                              <span>
                                <SearchIcon className="search-icon-fs" />
                              </span>
                            </div>
                          </div>

                          <select
                            onClick={() => setHidestate(1)}
                            className="b-radius selectSize w-100"
                            value={selectedstateone}
                            aria-label="Default select example "
                            name="state"
                            onChange={(e) => {
                              handleState(e);
                              handleCountryChangestate(e);
                              handleValue(e);
                              handleChange(e);
                            }}
                          >
                            {hidestate && <option>Select state</option>}

                            {getState
                              .filter((country) =>
                                country
                                  .toLowerCase()
                                  .includes(searchTermone.toLowerCase())
                              )
                              .map((country) => (
                                <option key={country} value={selectedState}>
                                  {country}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3 pt-3 ps-4 ">
                        <label
                          htmlFor="inputPassword4"
                          placeholder="First name"
                          className="form-label labelSize pe-2"
                        >
                          City
                        </label>
                        <div className="blood-type  input-style ">
                          <div className="d-flex">
                            <div className="w-100">
                              <input
                                type="search"
                                value={searchTermtwo}
                                onChange={handleSearchChangecity}
                                placeholder="Search city here...."
                                className="w-100 search-input"
                                onClick={() => setHidecity(0)}
                              />
                            </div>
                            <div>
                              <span>
                                <SearchIcon className="search-icon-fs" />
                              </span>
                            </div>
                          </div>

                          <select
                            onClick={() => setHidecity(1)}
                            value={selectedcity}
                            className="b-radius selectSize w-100"
                            aria-label="Default select example"
                            name="city"
                            onChange={(e) => {
                              handleCountryChangecity(e);
                              handleValue(e);
                              handleChange(e);
                            }}
                          >
                            {hidecity && <option>Select city</option>}

                            {getcity
                              .filter((country) =>
                                country.name
                                  .toLowerCase()
                                  .includes(searchTermtwo.toLowerCase())
                              )
                              .map((country) => (
                                <option key={country.name}>
                                  {country.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <Col md={3} lg={3} xl={3}>
                        <div className="ps-3">
                          <Form.Label className="custm-lble pt-4 ps-2">
                            Actual Area
                          </Form.Label>
                          <Form.Control
                            placeholder="Actual Area"
                            name="area"
                            className="input-style form-control-seller"
                            defaultValue={propertyadd?.area}
                            onChange={(e) => {
                              handleValue(e);
                              handleChange(e);
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={3} lg={3} xl={3}>
                        <Form.Label className="custm-lble pt-4">
                          Email
                        </Form.Label>
                        <Form.Control
                          placeholder="Email"
                          name="email"
                          className="input-style form-control-seller"
                          defaultValue={propertyadd?.email}
                          onChange={(e) => {
                            handleValue(e);
                            handleChange(e);
                          }}
                        />
                      </Col>

                      <Col md={3} lg={3} xl={3}>
                        <Form.Label className="custm-lble pt-4">
                          Select Currency
                        </Form.Label>
                        <div className="bluedrop">
                          {" "}
                          <Form.Select
                            className="input-style form-control-seller"
                            aria-label="Default select example"
                            name="currency"
                            onChange={(e) => {
                              handleValue(e);
                              handleChange(e);
                            }}
                          >
                            <option disabled selected>
                              Select Currency
                            </option>
                            {currencies?.map((e) => (
                              <>
                                <option key={e.id}>
                                  {e?.abbreviation}&nbsp;{e?.name}
                                </option>
                              </>
                            ))}
                          </Form.Select>
                        </div>
                      </Col>
                    </Row>
                    <Row className=" ps-2 ">
                      <Col md={3} lg={3} xl={3}>
                        <Form.Label className="custm-lble pt-4">
                          Price
                        </Form.Label>
                        <Form.Control
                          placeholder="Price"
                          name="price"
                          className="input-style form-control-seller"
                          defaultValue={propertyadd?.price}
                          onChange={(e) => {
                            handleValue(e);
                            handleChange(e);
                          }}
                        />
                      </Col>
                    </Row>

                    <Form.Label className="custm-lble pt-4 ps-4">
                      <h6>Sale/Rent/Lease</h6>
                    </Form.Label>
                    <Form className="ps-4">
                      {["radio"].map((type) => (
                        <div key={`inline-${type}`} className="mb-2">
                          <Form.Check
                            inline
                            label="Sale"
                            value="Sale"
                            name="saletype"
                            defaultValue={propertyadd?.saletype}
                            type={type}
                            id={`inline-${type}-1`}
                            className="labelText "
                            onChange={(e) => {
                              handleValue(e);
                            }}
                          />
                          <Form.Check
                            inline
                            label="Rent"
                            value="Rent"
                            name="saletype"
                            defaultValue={propertyadd?.saletype}
                            type={type}
                            id={`inline-${type}-2`}
                            className="labelText"
                            onChange={(e) => {
                              handleValue(e);
                            }}
                          />
                          <Form.Check
                            inline
                            label="Lease"
                            value="Lease"
                            name="saletype"
                            defaultValue={propertyadd?.saletype}
                            type={type}
                            id={`inline-${type}-2`}
                            className="labelText"
                            onChange={(e) => {
                              handleValue(e);
                            }}
                          />
                        </div>
                      ))}
                    </Form>

                    <div className="ps-2 pe-2">
                      <Form.Label className="custm-lble">
                        Description
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        name="description"
                        className="input-style"
                        defaultValue={propertyadd?.description}
                        onChange={(e) => {
                          handleValue(e);
                          handleChange(e);
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <Col lg="9" className="me-auto">
                        <Row>
                          <Col lg={2} className="mt-2 ms-2">
                            <Card
                              style={{ width: "6rem", height: "6rem" }}
                              {...getRootProps()}
                              className="dropzone"
                            >
                              <Card.Img
                                className="imet-fit"
                                src={images.AddImage}
                              />
                              <Card.Body>
                                <input
                                  type="file"
                                  id="file"
                                  style={{ display: "none" }}
                                  {...getInputProps()}
                                  multiple
                                />
                              </Card.Body>
                            </Card>
                          </Col>
                          {
                            displayimg?.length > 0 && (
                              <Col lg={8} className=" mt-2 d-flex">


                                {displayimg.map((image) => (
                                  <Card
                                    className="me-2"
                                    style={{
                                      width: "6rem",
                                      height: "6rem",
                                      border: "2px solid",
                                    }}

                                  >
                                    <Card.Img
                                      className="imet-fit upload-img"
                                      src={getAsset(image)}
                                      alt=""
                                      crossOrigin="anonymous"
                                    />
                                    <div
                                      className="close-btnone"
                                      onClick={() => removeImage(index)}
                                    >
                                      <ClearIcon />
                                    </div>
                                  </Card>
                                ))}
                              </Col>
                            )

                          }

                        </Row>
                      </Col>

                      <div className="d-flex align-items-end pb-3 pe-3">
                        <div className="btns">
                          <button
                            className="btn1"
                            onClick={() =>
                              navigate(`/Sale-Bussines-Property/${id}`)
                            }
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn2 disable-btn"
                            onClick={
                              butn === "Post" ? handlerFunction : Updatehandler
                            }
                            disabled={
                              location.pathname === `${link}${index}`
                                ? ""
                                : !(isValid && dirty)
                            }
                            data-title={
                              location.pathname === `${link}${index}`
                                ? null
                                : !(isValid && dirty)
                                  ? "Please fill out all Fields to enable button!"
                                  : null
                            }
                          >
                            {butn}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </div>
      </Row>
    </div>
  );
};
export default PropertyAdForm;
