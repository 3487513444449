import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Profileconnectivity, Editdilogbox } from "../../../../pages/index";
import { useState } from "react";
import PublicIcon from "@mui/icons-material/Public";
import SchoolIcon from "@mui/icons-material/School";
import ModeIcon from "@mui/icons-material/Mode";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { FailedNotify, SuccessNotify } from "../../../../constants/tostify";
import "./Profilesection.css";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import url from "../../../../config/axios";
import ProfileDel from "../../../../components/ProfileDel-modal";
import { useLocation } from "react-router-dom";
const Profilesection = ({ infooo, setInfooo }) => {
  const { name } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [privacyOpen, setPrivacyOpen] = useState(false);
  const [editbox, setEditbox] = useState('');
  const handleClose = () => setShows(0);
  const [shows, setShows] = useState(false);
  const location = useLocation();
  const [createShop, setCreateShop] = useState({
    atext: "",
    aprivacy: "",
    btext: "",
    bprivacy: "",
    bdegree: "",
    ctext: "",
    cprivacy: "",
    email: "",
    createdAt: "",
  });
  const boxOpen = () => {
    if (privacyOpen === true) {
      setPrivacyOpen(false);
    } else {
      setPrivacyOpen(true);
    }
  };
  const inputChange = (event) => {
    setCreateShop((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const userDatahandler = () => {
    url
      .put(`/user/id/?id=${name?._id}`, {
        address: {
          text: createShop.atext,
          privacy: createShop.aprivacy,
        },
      })
      .then(async (response) => {
        if (response.data.success) {
          setEditbox(false);
          url.get(`/user/id?id=${name?._id}`).then((response) => {
            setInfooo(response?.data?.data?.User);
          });
          SuccessNotify("Successfully!");
        }
      })
      .catch((e) => {
        console.log("error", e);
        setEditbox(false);
        FailedNotify("Failed! Try Again");
      });
  };
  const handledelete = () => {
    // idr user delte ki api lgani 
  };
  const userDatahandler1 = () => {
    url
      .put(`/user/id/?id=${name?._id}`, {
        education: [
          {
            text: createShop.btext,
            privacy: createShop.bprivacy,
            degree: createShop.bdegree,
          },
        ],
      })
      .then(async (response) => {
        if (response.data.success) {
          setEditbox(false);

          SuccessNotify("Successfully!");
        }
      })
      .catch((e) => {
        console.log("error", e);
        setEditbox(false);
        FailedNotify("Failed! Try Again");
      });
  };
  const userDatahandler2 = () => {
    url
      .put(`/user/id/?id=${name?._id}`, {
        contactNumber: {
          text: createShop.ctext,
          privacy: createShop.cprivacy,
        },
      })
      .then(async (response) => {
        if (response.data.success) {
          setEditbox(false);
          SuccessNotify("Successfully!");
        }
      })
      .catch((e) => {
        console.log("error", e);
        setEditbox(false);
        FailedNotify("Failed! Try Again");
      });
  };
  const userDatahandler3 = () => {
    url
      .put(`/user/id/?id=${name?._id}`, {
        email: createShop.email,
      })
      .then(async (response) => {
        if (response.data.success) {
          setEditbox(false);
          SuccessNotify("Successfully!");
        }
      })
      .catch((e) => {
        console.log("error", e);
        setEditbox(false);
        FailedNotify("Failed! Try Again");
      });
  };
  const userDatahandler4 = () => {
    url
      .put(`/user/id/?id=${name?._id}`, {
        createdAt: createShop.createdAt,
      })
      .then(async (response) => {
        if (response.data.success) {
          setEditbox(false);
          SuccessNotify("Successfully!");
        }
      })
      .catch((e) => {
        console.log("error", e);
        setEditbox(false);
        FailedNotify("Failed! Try Again");
      });
  };
  return (
    <>
      <Container>
        <ProfileDel
          shows={shows}
          handleClose={handleClose}
          handledelete={handledelete}
        />
        <Row>
          <Col
            lg="3"
            className="pe-xl-0 ps-xl-2 ps-lg-1 pe-lg-0 ps-sm-2 pe-sm-2 ps-xs-2 pe-xs-2 "
          >
            {editbox && (
              <Editdilogbox
                setEditbox={setEditbox}
                editbox={editbox}
                inputChange={inputChange}
                userDatahandler={userDatahandler}
                userDatahandler1={userDatahandler1}
                userDatahandler2={userDatahandler2}
                userDatahandler3={userDatahandler3}
                userDatahandler4={userDatahandler4}
              />
            )}

            <div className=" bg-white  pr-0 mt-2 ">
              <h4 className="ms-2"> Intro</h4>
              <ListGroup className="introBox list-unstyled font_small_profile">
                <ListGroup.Item className="d-flex justify-content-between border-0 p-1 ">
                  <div>
                    <PublicIcon className="icon_f_sm" /> {infooo?.address?.text}
                  </div>
                  {
                    location.pathname === "/user-profile" ? ( <div>
                      <ModeIcon
                        className="icon_f_sm"
                        onClick={() => setEditbox(1)}
                      />
                    </div>) : ("")
                  }
                  
                </ListGroup.Item>
                <div className="d-flex flex-column mt">
                  <ListGroup.Item className="border-0 p-1">
                    {infooo?.education?.map((item) => (
                      <div className="d-flex justify-content-between ">
                        <div>
                          <SchoolIcon className="icon_f_sm me-1" />
                          {item?.text}
                        </div>
{
location.pathname === "/user-profile" ? ( <div>
  <ModeIcon
    className="icon_f_sm "
    onClick={() => setEditbox(2)}
  />
</div>):("")
}
                        

                      </div>
                    ))}
                  </ListGroup.Item>
                </div>
                <ListGroup.Item className="d-flex justify-content-between border-0 p-1">
                  <div>
                    <CallIcon className="icon_f_sm me-1" />
                    {infooo?.contactNumber?.text}
                  </div>
{
  location.pathname === "/user-profile" ? (   <div>
    <ModeIcon
      className="icon_f_sm"
      onClick={() => setEditbox(3)}
    />
  </div>) :("")
}
                

                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between border-0 p-1">
                  <div>
                    <EmailIcon className="icon_f_sm me-1" />
                    {infooo?.email}
                  </div>
                  {
                    location.pathname === "/user-profile" ? ( <div>
                      <ModeIcon
                        className="icon_f_sm"
                        onClick={() => setEditbox(4)}
                      />
                    </div>):("")
                  }
                 
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between border-0 p-1">
                  <div>
                    <WatchLaterIcon className="icon_f_sm me-1" />
                    {infooo?.createdAt.slice(0, 10)}
                  </div>
                  {
                    location.pathname === "/user-profile" ? ( <div>
                      <ModeIcon
                        className="icon_f_sm"
                        onClick={() => setEditbox(5)}
                      />
                    </div>):("")
                  }
                 
                </ListGroup.Item>
              </ListGroup>

              {
                location.pathname === "/user-profile" ? (<><div className="btnns">
                  <Button
                    onClick={() => navigate("/CV-Form")}
                    type="button"
                    className="btn text-center  btn-primary  font_small_profile  mt-2"
                  >
                    Update Cv
                  </Button>
                </div>
                <div className="btnns">
                  <Button
                    onClick={() => navigate("/post-single-ad")}
                    type="button"
                    className="btn text-center  btn-primary  font_small_profile  mt-1"
                  >
                    Signal Item Ads
                  </Button>
                </div></>):("")
              }
              

            </div>

{
  location.pathname === "/user-profile" ? (<><Col lg="12" className="ps-0 pe-0 mt-2 mb-3 privacySettings bg-white">
    <div className="d-flex justify-content-md-between">
      
      <div className="">
        <h6 className="ps-1 privacyText">Privacy Settings</h6>
      </div>
      <div
        className="ms-4"
        onClick={() => {
          setPrivacyOpen(true);
          boxOpen();
        }}
      >
        <ArrowDropDownIcon className="icon_f" />
      </div>
    </div>

    {privacyOpen === true && (
      <div className="overflowData p-2 noscroll ">
        <div className="privatePara pt-2 pb-2 font_small_profile ">
          <p>Select an options which you want to private</p>
        </div>
        <div>
          <Form>
            {["switch"].map((type) => (
              <div
                key={`default-${type}`}
                className="mb-3 font_small_profile"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Label>General Friends</Form.Label>
                  <Form.Check
                    type={type}
                    id={`default-${type}`}
                    name="formHorizontalRadios"
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Label>Connected Services</Form.Label>
                  <Form.Check
                    type={type}
                    id={`default-${type}`}
                    name="formHorizontalRadios"
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Label>Favourite Recruiters</Form.Label>
                  <Form.Check
                    type={type}
                    id={`default-${type}`}
                    name="formHorizontalRadios"
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Label>Interested Property</Form.Label>
                  <Form.Check
                    type={type}
                    id={`default-${type}`}
                    name="formHorizontalRadios"
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Label>SecondHand Items</Form.Label>
                  <Form.Check
                    type={type}
                    id={`default-${type}`}
                    name="formHorizontalRadios"
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Label>HomeMade Items</Form.Label>
                  <Form.Check
                    type={type}
                    id={`default-${type}`}
                    name="formHorizontalRadios"
                  />
                </div>
              </div>
            ))}
          </Form>
        </div>
      </div>
    )}
  </Col></>) :("")
}
            

            {/* <button class="btn btn-danger profilebset w-100" onClick={() => setShows(true)}>
              <PersonOffIcon style={{ fontSize: "small" }} /> &nbsp;
              <span className="x-smallsize">Delete user account</span>
            </button> */}

          </Col>
          <Col
            lg="9"
            className="pe-2 ps-1 pe-md-0 ps-md-0 pe-lg-0 ps-lg-0 pe-xl-0 ps-xl-0 pe-sm-2 ps-sm-1"
          >
            <Profileconnectivity />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profilesection;
