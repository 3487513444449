import {Container,Row,Col,Form} from "react-bootstrap"
import Modal from 'react-bootstrap/Modal';
import url from "../../config/axios";
import { useState } from "react";
import { SuccessNotify,FailedNotify } from "../../constants/tostify";
const Creditcardform=({setOpenmodal,openmodal,shopid,datavalue})=>{
  const [details,setDetails]=useState({
    cardholderName: "",
    creditCardNumber: "",
    expirationDate: "",
    cvcCode: "",
    billingAddress: "",
    country: "",
    zipCode: ""
  })

const inputChange=(e)=>{
  setDetails((prevState)=>({
    ...prevState,
    [e.target.name]:e.target.value
  }))
}

 const handleSubmit=(e)=>{
  e.preventDefault();
  url.put(`shop/paymentMethod/shopid?shopid=${shopid}`,{
    paymentMethod: [
      {
          method: datavalue,
          details: {
              creditCard: {
                  cardholderName: details.cardholderName,
                  creditCardNumber: details.creditCardNumber,
                  expirationDate: details.expirationDate,
                  cvcCode: details.cvcCode,
                  billingAddress: details.billingAddress,
                  country: details.country,
                  zipCode: details.zipCode
              }
          }
      }
  ]
  }).then(async(res)=>{
    if(res.data.success){
      SuccessNotify(res.data.message);
      setOpenmodal(0);
    }
  }).catch((e)=>{
    FailedNotify(e.response.data.message);
  })
}
return(<>

  <Modal
        size="lg"
        show={openmodal}
        onHide={() => setOpenmodal(0)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
          Enter Your Credit Card Details:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body> <Container>

<Row>
  <Col md={6} lg={6}>
    <Form.Label className="custm-lble ">Cardholder Name:</Form.Label>
    <Form.Control
      placeholder="Name of the person or entity that the credit card is registered to"
      type="text"
      name="cardholderName"
      className="labelText "
      onChange={(e)=>inputChange(e)}
    />
  </Col>
  <Col md={6} lg={6}>
    <Form.Label className="custm-lble ">Credit Card Number:</Form.Label>
    <Form.Control
      placeholder="The full 16-digit number on the credit card"
      type="number"
      name="creditCardNumber"
      className="labelText "
      onChange={(e)=>inputChange(e)}
    />
  </Col>
</Row>
<Row>
  <Col md={6} lg={6}>
    <Form.Label className="custm-lble ">Expiration Date:</Form.Label>
    <Form.Control
      placeholder="The month and year in this formate 02-2003"
      type="number"
      name="expirationDate"
      className="labelText "
      onChange={(e)=>inputChange(e)}
    />
  </Col>
  <Col md={6} lg={6}>
    <Form.Label className="custm-lble ">CVV/CVC Code:</Form.Label>
    <Form.Control
      placeholder="This is a three- or four-digit security code located on the back of the card"
      type="number"
      name="cvcCode"
      className="labelText "
      onChange={(e)=>inputChange(e)}
    />
  </Col>
</Row>

<Row>
  <Col md={6} lg={6}>
    <Form.Label className="custm-lble "> Billing Address:</Form.Label>
    <Form.Control
      placeholder="The address associated with the credit card's billing statement"
      type="text"
      name="billingAddress"
      className="labelText "
      onChange={(e)=>inputChange(e)}
    />
  </Col>
  <Col md={6} lg={6}>
    <Form.Label className="custm-lble ">
    Country:
    </Form.Label>
    <Form.Control
      placeholder="The country where the credit card is issued"
      type="text"
      name="country"
      className="labelText "
      onChange={(e)=>inputChange(e)}
    />
  </Col>
</Row>

<Row>

  <Col md={6} lg={6}>
    <Form.Label className="custm-lble ">Zip Code:</Form.Label>
    <Form.Control
      placeholder="The ZIP or postal code of the billing address"
      type="number"
      name="zipCode"
      className="labelText"
      onChange={(e)=>inputChange(e)}
    />
  </Col>

</Row>

<Row>
  {" "}
  <div className="d-flex justify-content-end">
    <Col lg="2 mt-3 text-end">
      <button className="btn-blue-sm ps-0 pe-0" onClick={(e)=>handleSubmit(e)}>Submit</button>
    </Col>
  </div>
</Row>
</Container></Modal.Body>
      </Modal>
 



</>)

}

export default Creditcardform