import "./Forgotpassword.css";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import url from "../../../../config/axios";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { SuccessNotify, FailedNotify } from "../../../../constants/tostify";
import { useSelector } from "react-redux";

const Resetpassword = () => {
  const navigate = useNavigate();
  const id= localStorage.getItem("userid")
const userid= JSON.parse(id);
  const resetHandle = (values) => {
    url
      .patch(`/user/changePassword/${userid}`, values)
      .then((res) => {
        if (res.data.success) {
          SuccessNotify("Password Reset Successfully");
          navigate("/");
        }
      })
      .catch((err) => {
        FailedNotify("Password Not Reset");
      });
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is Required*"),
    conpassword: Yup.string()
      .required("Confirm Password is Required*")
      .oneOf([Yup.ref("password"), null], "Passwords Must Match*"),
  });

  return (
    <>
      <Container fluid className="f-bg-color">
        <Row>
          <Col lg="6" className="mx-auto  background-white f-modal-style">
            <div className="ps-5 pe-5">
              <h5 className="text-center">Forgot password</h5>
              <p className="text-center mt-2 font-ss">
                Your identity has been verified<br></br>
                Set your new password
              </p>
          
              <Formik
                initialValues={{
                  password: "",
                  conpassword: "",
                }}
                validateOnBlur={false}
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting, setErrors }) => {
                  validationSchema
                    .validate(values, { abortEarly: false })
                    .then(() => {
                      resetHandle(values);
                      setSubmitting(false);
                    })
                    .catch((errors) => {
                      setErrors(errors);
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  errors,
                  touched,
                }) => (
                  <Form>
                    <div className="d-flex flex-column">
                      <label className="labelSize">Enter New Password*</label>
                      <Field
                        type="text"
                        className="p-3 input-style"
                        name="password"
                        id="password"
                        placeholder="Enter New Password"
                        aria-describedby="emailHelp"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.password && touched.password}
                      />
                      {errors.password && touched.password && (
                        <div>
                          <p className="error_text_color">{errors.password}</p>
                        </div>
                      )}
                    </div>

                    <div className=" mt-2 d-flex flex-column">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label labelSize"
                      >
                        Confirm Password*
                      </label>
                      <Field
                        type="password"
                        className="p-3 input-style"
                        placeholder="Enter confirm password"
                        id="conpassword"
                        name="conpassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.conpassword && touched.conpassword}
                      />
                      {errors.conpassword && touched.conpassword && (
                        <div>
                          <p className="error_text_color">
                            {errors.conpassword}
                          </p>
                        </div>
                      )}
                    </div>
                    <Button
                      type="submit"
                      className="bg-blue  w-100 mt-2 next-btn-s b-radius p-2"
                    >
                      Reset Password
                    </Button>
                    <div className="font-blue text-center  mt-lg-3 mt-xl-3">
                      <NavLink to="/verification">
                        Back to Previous
                        <span style={{ color: "black" }}> Page</span>
                      </NavLink>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Resetpassword;
