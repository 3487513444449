import React from "react";
import BoxData from "../Box";
import CardNav from "../CardNav";
import { Col, Container, Row, Card } from "react-bootstrap";
import { images } from "../../../../constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useState } from "react";

const Services = ({ btn }) => {
  const [id, setIs] = useState(1);
  const [myinfo, setMyinfo] = useState("service info")
  return (
    <div>
      <Row className="">
        <Col lg="12">

          <CardNav Header="Services" myid={id} myinfo={myinfo} />
          <Card className="shadow-main mt-2">
            <BoxData
              Title="Services"
              Butonn={btn}
              imgg={images.Services}
              url="/service-integrate"
              link="/find-services"
              ms="nineteen-element"
              buy="nineteen"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Services;
