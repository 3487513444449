import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const Desmodal = ({ desshow, handleClosedes, des }) => {
  return (
    <>
      <Container>
        <Row>
          <Modal show={desshow} onHide={handleClosedes}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{ maxHeight: "500px", overflowX: "hidden", overflowY: "scroll" }}
              className="noscroll"
            >
              <p className="mb-0">{des}</p>
            </Modal.Body>
          </Modal>
        </Row>
      </Container>
    </>
  );
};

export default Desmodal;
