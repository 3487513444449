import "./ShoppingCart.css";
import { Col, Container, Row } from "react-bootstrap";
import React, { useState } from "react";
import { Modal, Button, Card } from "react-bootstrap";
import { images } from "../../constants/index";
import { ModalCards } from "../../constants/jsonConstant";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import DropdownButtons from "../ButtonDropdown/DropdownButtons";
import { useLocation } from "react-router-dom";

const ShoppingCart = ({
  onChange,
  showModal1,
  handleCloseModal,
  delevered,
  pending,
  outgoing,
  showModal,
  color1,
  color2,
  color3,  
}) => {
  const [quantity, setQuantity] = useState(1);
  const location = useLocation();
  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value);
    setQuantity(newQuantity);
    onChange(newQuantity);
  };

  return (
    <div className="bg-clrr">
      <Modal
        show={showModal === 1 || showModal === 2 || showModal === 3}
        onHide={handleCloseModal}
        className=" right-to-left-modal"
      >
        <Modal.Header className="shadow-sm mb-1 p-2">
          <Modal.Title className="fs-6">
            <img
              className=" back-icon me-3 ms-2"
              onClick={handleCloseModal}
              width="16"
              height="13"
              src={images.BackArrow}
            />
            Order Details
          </Modal.Title>
        </Modal.Header>

        <div className="modal-div">
          <Modal.Body>
            <Row className=" bg-clr-card mb-3 ">
              <Col lg="4">
                <Card.Img
                  variant="top"
                  width="100"
                  height="100"
                  src={images.Img1}
                />
              </Col>

              <Col lg="8" className="pb-2">
                <Card.Body className="p-0">
                  <Card.Text className="title-sizze mb-1">
                    Item Name : Hand Bag
                  </Card.Text>
                  <Card.Text className="price">Price : Rs 500</Card.Text>
                  <Card.Text className="price">Quantity : 5</Card.Text>
                </Card.Body>
              </Col>

            </Row>

            <div>
              <Container fluid>
                <TextField
                  size="small"
                  sx={{ mt: 1, width: "100%" }}
                  label="Name"
                  defaultValue="Jamal Khan"
                  variant="outlined"
                  disabled
                />
              
                <TextField
                  size="small"
                  sx={{ mt: 1, width: "100%" }}
                  label="Email Address"
                  defaultValue="jamal@gmail.com"
                  variant="outlined"
                  disabled
                  className="mt-3"
                />

                <TextField
                  size="small"
                  sx={{ mt: 1, width: "100%" }}
                  label="Mobile Number"
                  defaultValue="+923409316399"
                  variant="outlined"
                  disabled
                  className="mt-3"
                 />

                <TextField
                  size="small"
                  sx={{ mt: 1, width: "100%" }}
                  label="Shipping Address"
                  defaultValue="supply bazar Abbottabad"
                  variant="outlined"
                  disabled
                  className="mt-3"
                />

                <TextField
                  size="small"
                  sx={{ mt: 1, width: "100%" }}
                  label="Billign Address"
                  defaultValue="supply bazar Abbottabad"
                  variant="outlined"
                  disabled
                  className="mt-3"
                />
                
                <p className="mt-2">
                  <b>Order Details:</b>
                </p>

                <Row>
                  <Col className="bag-t" lg="6">
                    bag total :
                  </Col>

                  <Col className=" bag-t d-flex justify-content-end" lg="6">
                    $220.00
                  </Col>
                </Row>
                <div className="bag-t-2">
                  <Row>

                    <Col className="bag-t" lg="6">
                      Delivery : 
                    </Col>

                    <Col className=" bag-t d-flex justify-content-end" lg="6">
                      $50.00
                    </Col>

                  </Row>
                </div>

                <hr className="mt-2 mb-2" />

                <Row>
                  <Col className="t-amount" lg="6">
                    Total Amount
                  </Col>

                  <Col className="d-flex justify-content-end" lg="6">
                    <p className="mb-0 t-amount-2">$270.00</p>
                  </Col>
                </Row>

                <Row className="pt-1 pb-1">
                  <Col lg="6" className="text-start font-14">
                    Payment Method:
                    
                  </Col>{" "}
                  <Col lg="6" className="text-end font-14">
                    Cash on Delivery
                  </Col>
                </Row>

                <hr className="m-0" />

                <Row>
                  <Col style={{ fontWeight: 700 }} className="pt-2 ps-3" lg="6">
                    <p className="mb-0 t-amount-2">Delivery Status</p>
                  </Col>
                  <Col className=" text-end pb-2" lg="6">
                    {/* <button
                      className="place-order"
                      onClick={() => {
                        handleCloseModal();
                        SetisOpenone(true);
                      }}
                    >
                      Proceed
                    </button> */}
                    <DropdownButtons
                      delevered={delevered}
                      outgoing={outgoing}
                      pending={pending}
                      color1={color1}
                      color2={color2}
                      color3={color3}
                    />
                  </Col>
                </Row>

                <hr className="mt-0 mb-3" />
              </Container>
            </div>
          </Modal.Body>
        </div>
        
      </Modal>
    </div>
  );
};

export default ShoppingCart;
