import React, { useState } from "react";
import { Container } from "react-bootstrap";

import Modal from "react-bootstrap/Modal";

import SearchIcon from "@mui/icons-material/Search";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import "./style.css";
import AccessModal from "../Access_modal";

const ModalEmail = ({ handleClose, show, addMember, }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [showaccess, setShowaccess] = useState(false);

  const handleCloseaccess = () => setShowaccess(false);
  const handleShowaccess = () => setShowaccess(true);

  return (
    <>
    <AccessModal showaccess={showaccess} handleCloseaccess={handleCloseaccess} />
      <Modal className="modal-setone" centered show={show} onHide={handleClose}>
        <Modal.Header className="modalcolor" closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Who should have task access to this Shop?</p>
          <p>Enter Email of person you want to add</p>

          <InputGroup className="mb-3">
            <Form.Control
              className="fw-bolder"
              placeholder="Search by Email"
              aria-label="Search by Email "
              aria-describedby="basic-addon2"
            />

            <div>
              <div className="position-relative">
                <div className="position-absolute custom-butn-set">
                  <Button
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    className="p-1"
                  >
                    <SearchIcon />
                  </Button>
                </div>
              </div>
            </div>
          </InputGroup>
          <div className="d-flex justify-content-end">
          <Button className="btn-primary" onClick={handleShowaccess}>
            Next
          </Button>
          </div>
        </Modal.Body>

        {/* <Form.Control size="lg" type="text" placeholder="Large text" /> */}
      </Modal>
    </>
  );
};

export default ModalEmail;
