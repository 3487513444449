import { Container, Row, Col } from "react-bootstrap";
import ShopAdds from "../../components/shopAds/ShopAdds";
import { useParams } from "react-router-dom";
import {
  Businessprofiletitle,
  Rawmaterialcard,
  Timeline,
} from "../../pages/index";
const Transportbusiness = () => {
  const { id } = useParams();

  return (
    <>
      <Container>
        <Row>
          <Col lg="7" className="screenHeight p-0 ">
            <div className="border b-radius">
              <ShopAdds />
              <Businessprofiletitle linkurl="/recycle-profile" id={id} />
              <Rawmaterialcard />
            </div>
          </Col>

          <Col
            lg="5"
            sm="12"
            className="ps-4 screenHeight  "
            style={{ position: "relative" }}
          >
            <div className="border b-radius">
              <Timeline />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Transportbusiness;
