import React, { useState } from 'react'
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './style.css'
import {  useNavigate } from 'react-router-dom';

const PostCvModal = ({handleClose,show}) => {

   const navigate = useNavigate()

    return (
        <div>
        
                   
                
      <Modal aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose} animation={false}>
        <Modal.Header className=' cv-modal-head border-0 p-2 text-white' closeButton>
          <Modal.Title className='fs-5 '>Post Your CV</Modal.Title>
          
        </Modal.Header>
        <div className='cv-modaal-main'>

        <p className='fs-4 pb-3 text-center w-50 fw-bold'>First Create Your Cv To Proceed</p>
              <div className='w-50 pb-3 d-flex justify-content-between' >  
          <button className='cv-modal-btn1' onClick={handleClose}>
            Back
          </button>
          <button className='cv-modal-btn2' onClick={()=>navigate('/CV-Form')}>
           Create
          </button>
          </div>
      </div>
      </Modal>
         
        </div>
    )
}

export default PostCvModal
