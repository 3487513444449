import React from "react";
import Promotionmerge from "../addpromotion";
const TransportPromotion = () => {
  return (
    <>
      <Promotionmerge />
    </>
  );
};

export default TransportPromotion;
