import "../../pages/Auth/components/Forgot password/Forgotpassword.css";
import { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import url from "../../config/axios";
import { SuccessNotify, FailedNotify } from "../../constants/tostify";
const Verifycode = () => {
  const { email } = useParams();
  const navigate = useNavigate();
  const ref = useRef();

  const [getotp, setGetotp] = useState({
    one1: "",
    one2: "",
    one3: "",
    one4: "",
    one5: "",
    one6: "",
  });

  const one2 = useRef();
  const one3 = useRef();
  const one4 = useRef();
  const one5 = useRef();
  const one6 = useRef();

  const handleValue = (e) => {
    setGetotp((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name === "one1" && e.target.value.length === 1) {
      one2.current.focus();
    }

    else if (e.target.name === "one2" && e.target.value.length === 1) {
      one3.current.focus();
    }

    else if (e.target.name === "one3" && e.target.value.length === 1) {
      one4.current.focus();
    }

    else if (e.target.name === "one4" && e.target.value.length === 1) {
      one5.current.focus();
    }

    else if (e.target.name === "one5" && e.target.value.length === 1) {
      one6.current.focus();
    }
  };

  const handleOtp = () => {
    url
      .put(
        `user/verifyEmail/${getotp.one1}${getotp.one2}${getotp.one3}${getotp.one4}${getotp.one5}${getotp.one6}/${email}`
      )
      .then(async (res) => {
        if (res.data.success) {
          SuccessNotify("Email Verified Successfully");
          navigate("/");
        }
      })
      .catch((err) => {
        FailedNotify(`${err.response.data.message}`);
      });
  };

  return (
    <>
      <Container fluid className="f-bg-color">
        <Row>
          <Col
            lg="4"
            className="mx-auto  background-white f-modal-style rounded cursor_P"
          >
            <div className="ps-5 pe-5">
              <h5 className="text-center mt-2">Verification</h5>
              <p className="text-center mt-2 font-ss">
                We have send you verification Code on your given email Enter
                code to continue
              </p>
            </div>
            <Col lg="7" className="mx-auto">
              <Row>
                <Col lg="2 ps-0">
                  <input
                    type="text"
                    name="one1"
                    class="form-control verify-input text-center"
                    min="0"
                    max="9"
                    pattern="[0-9]" jhdsafjkdh
                    maxlength="1"
                    step="1"
                    onChange={(e) => handleValue(e)}
                  />
                </Col>
                <Col lg="2 ps-0">
                  <input
                    type="text"
                    name="one2"
                    class="form-control verify-input text-center"
                    min="0"
                    max="9"
                    pattern="[0-9]"
                    maxlength="1"
                    step="1"
                    onChange={(e) => handleValue(e)}
                    ref={one2}
                  />
                </Col>


                <Col lg="2 ps-0">
                  <input
                    type="text"
                    name="one3"
                    class="form-control verify-input text-center"
                    min="0"
                    max="9"
                    pattern="[0-9]"
                    maxlength="1"
                    step="1"
                    onChange={(e) => handleValue(e)}
                    ref={one3}
                  />
                </Col>
                <Col lg="2 ps-0">
                  <input
                    type="text"
                    name="one4"
                    class="form-control verify-input text-center"
                    min="0"
                    max="9"
                    pattern="[0-9]"
                    maxlength="1"
                    step="1"
                    onChange={(e) => handleValue(e)}
                    ref={one4}
                  />
                </Col>
                <Col lg="2 ps-0">
                  <input
                    type="text"
                    name="one5"
                    class="form-control verify-input text-center"
                    min="0"
                    max="9"
                    pattern="[0-9]"
                    maxlength="1"
                    step="1"
                    onChange={(e) => handleValue(e)}
                    ref={one5}
                  />
                </Col>
                <Col lg="2 ps-0">
                  <input
                    type="text"
                    name="one6"
                    class="form-control verify-input text-center"
                    min="0"
                    max="9"
                    pattern="[0-9]"
                    maxlength="1"
                    step="1"
                    onChange={(e) => handleValue(e)}
                    ref={one6}
                  />
                </Col>
              </Row>
            </Col>
            <Row>
              <div className="d-flex justify-content-center mt-3">
                <button
                  className="btn btn-primary ps-5 pe-5 pt-1 pb-1"
                  onClick={handleOtp}
                >
                  Verify
                </button>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Verifycode;
