import "./chatRoomInvitePeople.css";
import { commentsLiveChatRoom } from "../../../../../constants/jsonConstant";
import DoneIcon from "@mui/icons-material/Done";
import { Col } from "react-bootstrap";
import { useState } from "react";
import Editbox from "../../../../../components/Timeline/Component/ViewPost/Component/Editbox/editbox";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
const ChatRoomInvitePeople = () => {
  const [modalopen, setModalopen] = useState(0);
  return (
    <div>
      <div className="ps-0 pe-0 commentsBackground">
        <div className="ps-3 pt-3 pb-3 pe-3">
          <h6 className="text-white pb-3">Title </h6>
          <div className="searchInput d-flex">
            <input
              type="text"
              className="form-control pb-2"
              placeholder="Write here ..."
            />
            <div className="position-relative">
              <button class="">
                <DoneIcon className="text-white" />
              </button>
            </div>
          </div>
          <h5 className="text-white pb-3 pt-2">Comments</h5>
          <div className="scrollinginvitePeople noscroll">
            {commentsLiveChatRoom?.map((comments) => (
              <div className="d-flex">
                <div className="commentsImg">
                  <img src={comments.img} alt="" />
                </div>
                <div className="commentsText">
                  <h6>{comments.personName}</h6>
                  <p>{comments.personComment}</p>
                </div>

                <MoreHorizIcon className="text-white cursor_P" onClick={() => setModalopen(comments?.id)} />
                {
                  modalopen===comments?.id && (
                    <div className="position-relative">
                  <div className="dottsBoxAbsolute">
                    <div className="d-flex">
                      <p className="removeBlock pe-3 mb-0 cursor_P">Remove</p>
                      <h6 className="crossStyle cursor_P" onClick={()=>setModalopen(0)}>X</h6>
                    </div>
                    <div>
                      <p className="removeBlock mb-0 cursor_P">Block</p>
                    </div>
                  </div>
                </div>
                  )
                }
               
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChatRoomInvitePeople;
