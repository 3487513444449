import "./profileimg.css";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import url from "../../../../config/axios";
import { publishImage } from "../../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
import { getAsset } from "../../../../helpers/helper";
import { FailedNotify} from "../../../../constants/tostify";
import { useLocation } from "react-router-dom";

const Profileimg = ({  infooo,name,setInfooo }) => {
  const location=useLocation();
  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      await url.put(`/user/id/?id=${name?._id}`, {imageUrl:abc} ).then((response) => {
        if (response.data.success) {
          url.get(`/user/id?id=${name?._id}`).then((response) => {
            setInfooo(response?.data?.data?.User);
            
          });
        }
      })
    
    }catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });
  return (
    <>
      <div className="position_r">
        <div className="Profile_dp">
          <img crossorigin="anonymous"  src={getAsset(infooo?.imageUrl[0])} alt="" className="profile_img2"  />
        </div>
{
  location.pathname==="/user-profile" ? ( <div className="P_camera">
    <label htmlFor="icon-button-file">
    <div {...getRootProps()} className="dropzone">
    <input {...getInputProps()} />
    <IconButton color="primary" component="span">
        <PhotoCameraIcon style={{ color: "black" }} className="icon_f" />
      </IconButton>
      </div>
     
     
    </label>
  </div>) : ("")
}
       
      </div>
    </>
  );
};

export default Profileimg;
