import { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axios from "../../config/axios";
import { Timelineflow } from "../../pages";
import { SuccessNotify, FailedNotify } from "../../constants/tostify";
import url from "../../config/axios"
import { useSelector } from "react-redux";
const Timeline = ({ rightSide, loading, tabsData,setCreatePost,createPost,setLoading,id }) => {
  console.log("tabsData",tabsData)
  const [key, setKey] = useState(tabsData?.length > 0 ? tabsData[0].eventKey : "home");
  const [mysetting, setMysetting] = useState(key);
  const [activeTabData, setActiveTabData] = useState([]);
  const [show, setShow] = useState(0);
  const [getmyTimeline, setGetmyTimeline] = useState([]);
  const [timelineid,setTimelineId]=useState()
const {name}=useSelector((state)=>state.user);
  useEffect(() => {
 
      getTimelinePosts();
    
    
  }, []);
const getTimelinePosts=()=>{
  if (mysetting === 'home') {
    url.get(`timeline/getAll?userid=${name?._id}&shopid=${id}`)
      .then((response) => {
     
        const timelineID=response.data[0]?._id
        const timelineData = response.data[0]?.my || [];
        setTimelineId(timelineID)
        setGetmyTimeline(timelineData);
        console.log("timelineID",timelineID)
      
       
      })
      .catch((err) => {
        console.error('Failed to fetch timeline data', err);
      });
  }
}


  useEffect(() => {
    setMysetting(key);
    const selectedTab = tabsData.find(tab => tab.eventKey === key);
    setActiveTabData(selectedTab ? selectedTab?.data : []);
  }, [key, tabsData]);
  const CreatePostSubmit = (e) => {
    setLoading(true);
    // e.preventDefault();
    axios.post("timeline/", createPost)
      .then(async (response) => {
       console.log("timeline",response)
          setLoading(false);
          setShow(0)
          SuccessNotify("Post Created SuccesFully...");
          getTimelinePosts();
      
      })
      .catch((error) => {
        setLoading(false);
        FailedNotify("Failed to Create Form");
      });
  };
  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className={`mb-3 ms-3 font_small ${rightSide === 11
        ? "display-setting-2 scroll-container"
        : "display-setting scroll-container"
        }`}
    >
      <Tab eventKey="home" title="My Timeline">
        <Timelineflow rightSide={rightSide} getTimelinePosts={getTimelinePosts} mysetting={mysetting} timelineid={timelineid}  data={activeTabData} CreatePostSubmit={CreatePostSubmit} 
              setCreatePost={setCreatePost} id={id}
              createPost={createPost} show={show} setShow={setShow} getmyTimeline={getmyTimeline} setGetmyTimeline={setGetmyTimeline}/>
      </Tab>
      {loading ? (
        <div>Loading...</div>
      ) : (
        tabsData?.map(tab => (
          <Tab eventKey={tab.eventKey} title={tab.title} key={tab.eventKey}>
            <Timelineflow rightSide={rightSide} getTimelinePosts={getTimelinePosts} mysetting={mysetting} data={tab.data} timelineid={timelineid} CreatePostSubmit={CreatePostSubmit} 
              setCreatePost={setCreatePost} id={id}
              createPost={createPost}    show={show} setShow={setShow} />
           
          </Tab>
        ))
      )}
    </Tabs>
  );
};

export default Timeline;
