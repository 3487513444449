import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Form from "react-bootstrap/Form";
import "./profiletitle.css";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import Qrmodal from "../../../../components/QRmodal/Qrmodal";
import LocationModal from "../../../../components/locationmodal/LocationModal";
import Desmodal from "../../../../components/Des_Modal";
import { useLocation } from "react-router-dom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EmailIcon from "@mui/icons-material/Email";

const Profiletitle = ({ inputChange, userDatahandler, infooo }) => {
const [isOpen, setOpen] = useState(0);
const [mshow, setMshow] = useState(false);
const [showmodal, setShowmodal] = useState(false);
  const location = useLocation();
  const closemodal = () => setShowmodal(false);
  const handleClose = () => {
    setMshow(false);
  };

  function handleVisibility(value) {
        if (isOpen === 0) {
      setOpen(value);
    } else {
      setOpen(0);
    }
  }
    
  const closeModal = () => {
    setOpen(0);
  };

  const [desshow, setDesshow] = useState(false);
  const handleClosedes = () => setDesshow(false);
  const handleShowdes = () => setDesshow(true);

  return (
    <>
      <Desmodal
        desshow={desshow}
        handleClosedes={handleClosedes}
        des={infooo?.description?.text}
      />
      <LocationModal mshow={mshow} handleClose={handleClose}  />
      <Qrmodal show={showmodal} handleClosemodal={closemodal} />
      <Row className="gb">
        <Col xl={3} lg="3" md={3} sm={4} xs={6}>
          <div className="emptydiv"></div>
        </Col>
        <Col xl={9} lg="9" md="9" sm="8" xs="8">
          <Row className="bb">
            <Col xl={location.pathname==="/other-profile" ? 5 : 9} lg={location.pathname==="/other-profile" ? 5 : 9} md={9} sm={9} xs={10}>
              
              <div className="">
                <div className=" extraset userid_setting align-items-lg-center align-items-xl-start">
                  <div className="d-flex text-center  mt-2">
                    <div className="textSmallShow">
                      <h4 className=" m-0 ">{infooo?.fullname}</h4>
                      <h5 style={{ fontSize: "12px" }}>
                        <p className="shop-profile-para mb-0">
                          {infooo?.description?.text.slice(0, 17)}
                        </p>
                        <p className="d-flex shop-profile-para mb-0 ">
                          {infooo?.description?.text.length > 17 && (
                            <span
                              onClick={handleShowdes}
                              className="mb-0 cursor_P font_11 text-dark"
                              style={{ marginTop: "3px" }}
                            >
                              &nbsp;<b>ReadMore...</b>
                            </span>
                          )}
                        </p>
                      </h5>
                    </div>
                             {location.pathname === "/user-profile" ? (
                      <div className="pen_cls">
                        <EditIcon onClick={() => handleVisibility(1)} />
                      </div>
                    ) : (
                      ""
                    )
                    }
                  </div>
                  <p className="extrauseridset">
                    <b className="uuswerid">User ID :</b> {infooo?._id}
                  </p>
                </div>
              </div>
            </Col>
            <Col xl={location.pathname==="/other-profile" ? 7 : 3} lg={location.pathname==="/other-profile" ? 7 : 3} md={3} sm={3} xs={1}>
            
              {location.pathname === "/user-profile" ? (
                <>
                  <div className="QRandbtn  me-lg-3 me-md-3">
                    <QrCode2Icon
                      style={{ fontSize: "32px" }}
                      onClick={() => setShowmodal(true)}
                    />

                   
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-center mt-3">
                  <div>
                    <button className="text-white font_14 btn-primary p-2 pt-1 pb-1 b-radius">
                      <PersonAddIcon
                        style={{ fontSize: "14px" }}
                        className="mb-1"
                      />
                      &nbsp; Send Request
                    </button>
                  </div>
                  &nbsp;{" "}
                  <div>
                    <button className="text-white font_14 btn-silver p-2 pt-1 pb-1 b-radius">
                      <EmailIcon
                        fontSize="small"
                        className="mb-1"
                        style={{ fontSize: "14px" }}
                      />
                      &nbsp; Send Message
                    </button>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Col>
        
        <div className="position-relative">
          <Col lg="8" sm="7" md="8" xs="7" className="DialogBox">
            {isOpen === 1 && (
              <div className="form-row  Backgroundbox p-2">
                <div className="col-md-12 ps-2">
                  <label for="validationTooltip03"></label>
                  <input
                    type="text"
                    classname="form-control largeBoxSize"
                    id="validationTooltip03"
                    placeholder="Write"
                    name="text"
                    defaultValue={infooo?.description?.text}
                    onChange={(e) => {
                      inputChange(e);
                    }}
                    style={{ border: "none" }}
                  />
                </div>
                <div className="col-12">
                  <div className="modal-body boxes ">
                    <Form.Select
                      aria-label="Default select example"
                      className="select_box_W"
                      name="privacy"
                      onChange={(e) => {
                        inputChange(e);
                      }}
                    >
                      <option>Public</option>
                      <option value="1">Friends</option>
                      <option value="2">Only me</option>
                    </Form.Select>

                    <div className="pt-1">
                      <button
                        type="button"
                        class="btn  text-white font_small bg-dark pt-0 pb-0"
                        onClick={() => {
                          setOpen(0);
                        }}
                      >
                        Cancel
                      </button>
                      &nbsp;&nbsp;
                      <button
                        type="button"
                        className="btn btn-primary text-white font_small bg-blue  pt-0 pb-0 p-3"
                        onClick={() => {
                          userDatahandler();
                          closeModal();
                        }}
                      >
                        Save
                      </button>
                      &nbsp;
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </div>
      </Row>
    </>
  );
};

export default Profiletitle;
