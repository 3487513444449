import { useEffect, useState } from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { subscriptionmdl } from "../../constants/jsonConstant";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Subscriptionmdl = ({ lgShow, setLgShow,setPurchase }) => {
  return (
    <>
      <Container classname="screenHeight mt-5">
        <Row>
          <Modal
            size="xl"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header
              style={{ backgroundColor: "#0353A4" }}
              closeButton
            ></Modal.Header>
            <Modal.Body className="p-5 pb-2">
              <Row className="justify-content-center align-items-center">
                
                {subscriptionmdl.map((e) => (
                  <Col lg={4} xl={4}  className="p-4">
                    <Card
                      className="cardsettingss"
                      style={{ borderRadius: "20px" }}
                    >
                      <Card.Body>
                        <div className="text-end">
                          <button
                            className="w-50 py-1"
                            style={{
                              backgroundColor: "#003559",
                              borderRadius: "15px",
                              color: "white",
                              border: "none",
                            }}
                          >
                            {e?.trail}
                          </button>
                        </div>
                        <div className="ps-3 pe-3">
                          <div className="mt-3">
                            <h3
                              style={{
                                color: "#0353A4",
                                fontFamily: "monospace",
                              }}
                            >
                              Rs:{e?.price}
                            </h3>
                            <h4 style={{ color: "#003559" }}>{e?.package}</h4>
                            <span
                              style={{ color: "#0353A4", fontSize: "x-small" }}
                            >
                              {e?.description}
                            </span>
                          </div>
                          <div className="mt-3">
                            <div className="d-flex align-items-start justify-content-start">
                              <CheckCircleIcon
                                style={{
                                  color: "rgb(0, 53, 89)",
                                  width: "10%",
                                }}
                              />
                              <span
                                className="ms-2"
                                style={{
                                  color: "#0353A4",
                                  fontSize: "small",
                                  wordWrap: "break-word",
                                  maxWidth: "150px",
                                }}
                              >
                                {e?.checkdata}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-start mt-2">
                              <CheckCircleIcon
                                style={{
                                  color: "rgb(0, 53, 89)",
                                  width: "10%",
                                }}
                              />

                              <span
                                className="ms-2"
                                style={{
                                  color: "#0353A4",
                                  fontSize: "small",
                                  wordWrap: "break-word",
                                  maxWidth: "150px",
                                }}
                              >
                                {e?.checkdata}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-start mt-2">
                              <CheckCircleIcon
                                style={{
                                  color: "rgb(0, 53, 89)",
                                  width: "10%",
                                }}
                              />

                              <span
                                className="ms-2"
                                style={{
                                  color: "#0353A4",
                                  fontSize: "small",
                                  wordWrap: "break-word",
                                  maxWidth: "150px",
                                }}
                              >
                                {e?.checkdata}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-start mt-2">
                              <CheckCircleIcon
                                style={{
                                  color: "rgb(0, 53, 89)",
                                  width: "10%",
                                }}
                              />
                              <span
                                className="ms-2"
                                style={{
                                  color: "#0353A4",
                                  fontSize: "small",
                                  wordWrap: "break-word",
                                  maxWidth: "150px",
                                }}
                              >
                                {e?.checkdata}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-start mt-2">
                              <CheckCircleIcon
                                style={{
                                  color: "rgb(0, 53, 89)",
                                  width: "10%",
                                }}
                              />
                              <span
                                className="ms-2"
                                style={{
                                  color: "#0353A4",
                                  fontSize: "small",
                                  wordWrap: "break-word",
                                  maxWidth: "150px",
                                }}
                              >
                                {e?.checkdata}
                              </span>
                            </div>
                          </div>
                          <div className="mt-5 mb-3">
                            <button
                              className="w-100 py-2"
                              style={{
                                backgroundColor: "#267DCF",
                                borderRadius: "30px",
                                color: "#FFFFFF",
                                border: "none",
                                fontSize: "small",
                              }}

                              onClick={() => {setPurchase(true); setLgShow(false)}}
                            >
                              {e?.btn}
                            </button>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
                <div className="mt-5"></div>
              </Row>
            </Modal.Body>
          </Modal>
        </Row>
      </Container>
    </>
  );
};

export default Subscriptionmdl;
