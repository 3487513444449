import PropertyAdForm from "../../../../components/forms/propertyAdform";
import { useState, useEffect } from "react";
import url from "../../../../config/axios";
import { SuccessNotify, FailedNotify } from "../../../../constants/tostify";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
import { publishImage } from "../../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
const PostBusinessAd = () => {
  const { id } = useParams();
  const { name } = useSelector((state) => state.user);
  const [isloading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const [displayimg, setDisplayimg] = useState();
  const [shopCategory, setShopCategory] = useState();
  const [shopCategorytwo, setShopCategorytwo] = useState();
  const [data, setData] = useState({
    category: "",
    subCategoryid: "",
  });

  const Handledata = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split("."),
    }));
  };

  const categoryName = data?.category[1];
  const categoryid = data.category[0];
  const categoryNametwo = data?.subCategoryid[1];
  const categoryidtwo = data.subCategoryid[0];


  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setPropertyadd((propertyadd) => ({
        ...propertyadd,
        image: abc
      }));

      setDisplayimg(abc);

    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });



  const Firstcategory = () => {
    url
      .get("category/consumer/?type=property&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });
  };

  const Mydata = () => {
    url
      .get(`category/consumer/?type=property&parentCategory=${categoryName}`)
      .then(async (response) => {
        console.log("jksahdf", response);
        if (response?.data?.success) {
          setShopCategorytwo(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });
  };



  const [propertyadd, setPropertyadd] = useState({
    companyid: id,
    userid: name._id,
    plottype: "",
    country: "",
    categoryid: "",
    category: "",
    subCategoryid: "",
    subCategory: "",
    city: "",
    state: "",
    area: "",
    contactnumber: "",
    email: "",
    price: "",
    saletype: "",
    description: "",
    image: [],
    status: true,
    businessType:"businessPropertyCompany"
  });


  useEffect(() => {
    setPropertyadd((propertyadd) => ({
      ...propertyadd,
      categoryid: categoryid,
      category: categoryName,
      subCategoryid: categoryidtwo,
      subCategory: categoryNametwo,
    }));
  }, [categoryid, categoryName, categoryidtwo, categoryNametwo]);
  const formSubmit = (e) => {

    setIsloading(true);
    e.preventDefault();
    url
      .post("property/", propertyadd)
      .then(async (response) => {
        console.log("propertyadd", response);

        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Add Posted SuccesFully...");
            navigate(`/Sale-Bussines-Property/${id}`);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed to Post Try Again!");
      });
  };


  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }

  return (
    <div>
      <PropertyAdForm
        title="Create Business Property Category,Location,Price,Description"
        butn="Post"
        navigateTo="/Sale-Bussines-Property"
        back="/Sale-Bussines-Property"
        propertyadd={propertyadd}
        setPropertyadd={setPropertyadd}
        handlerFunction={formSubmit}
        shopCategory={shopCategory}
        shopCategorytwo={shopCategorytwo}
        Mydata={Mydata}
        Handledata={Handledata}
        Firstcategory={Firstcategory}
        id={id}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        displayimg={displayimg}
        setDisplayimg={setDisplayimg}

      />
    </div>
  );
};
export default PostBusinessAd;
