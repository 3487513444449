import React from 'react'
import { Container, Row,Col} from "react-bootstrap";
import "./style.css"
import { useLocation } from 'react-router-dom';
import Reciveinvoice from '../../../../components/Reciveinvoice';

const PaymentMethods = () => {

  const location = useLocation()

  return (
    <div>
      
      <Container>
        <div >
          <Row>
          {location.pathname === '/quotation-buying' || location.pathname === '/quotation-selling' || location.pathname === '/purchase-order-selling' ?
          (<Col className='pe-lg-5' lg="7" md="6" sm="6">
       
            <p className='payment ps-4 rounded'> PaymentMethods </p>
            <input  className='paymentinput rounded ps-4' placeholder='Masters card'/>
            <div className="mt-1 fw-bold Description ">
             
              <p className='ps-2 mb-0'>Descripition</p>
            <textarea 
                  placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis nisi leo, eu aliquet nisl ornare eget. Praesent in urna quis massa gravida cursus nec vel libero..... READ MORE "
                  className="inputarea pt-0 pb-0" 
                  
                  />
                  </div>
      
          </Col>) : ("")}


          {/* <Col>
          
          <Reciveinvoice/>

          </Col> */}

          <Col className='ps-3' lg="5" md="6" sm="6" >
          
           
           <p className='pay text-center'> Amount Due </p>

           <table class="tablee table-bordered">
            
    <tbody className='tablebody'>
   
      <tr>
        <td className='ps-3 pt-0 pb-1 hgt'>Subtotal</td>
        <td className='ps-3 pt-0 pb-1 hgt'>500</td>
       
      </tr>
   
      <tr>
        <td className='ps-3 pt-0 pb-1 hgt'>Tax 10%</td>
        <td className='ps-3 pt-0 pb-1 hgt'>100</td>
      </tr>
      <tr>
        <td className='ps-3 pt-0 pb-1 hgt'>Delivery</td>
        <td className='ps-3 pt-0 pb-1 hgt'>600</td>
    
      </tr>
      <tr>
        <td className='ps-3 pt-0 pb-1 hgt'>Others</td>
        <td className='ps-3 pt-0 pb-1 hgt'>600</td>
 
      </tr>
      <tr>
        <td className='ps-3 pt-0 pb-1 hgt'>Total</td>
        <td className='ps-3 pt-0 pb-1 hgt'>600</td>
 
      </tr>
    </tbody>
  </table>
  
          </Col>
          
          <div>

          </div>
          
          <Container>
          <div className=" ps-2 pt-1 pe-1 pb-1 mb-2 mt-2 TermsConditionss  ">
            <p className='mb-0 terms-and-conditions-class'>Terms and Conditions</p>
            <textarea 
                  placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis nisi leo, eu aliquet nisl ornare eget. Praesent in urna quis massa gravida cursus nec vel libero. Nulla vel augue id elit sodales luctus eget vitae mauris. Etiam sed commodo est, sed tempor tortor. Morbi elit odio Fusce sollicitudin dapibus ex sed sodales. Integer dignissim dictum felis.... READ MORE "
                  className="inputareabottom fw-bold"
                  
                  />
                  </div>
                  </Container>
          </Row>
         </div>
      </Container>

    </div>
  )
}

export default PaymentMethods
