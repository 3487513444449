import React from "react";
import { images } from "../../../../constants";
import "./style.css";

const BuyerOne = ({ setShowopen }) => {
  return (
    <div>
      <div className="input-styleone" onClick={() => setShowopen(1)}>
        <div className=" d-flex justify-content-center align-items-center mb-2">
          <img className="img_one-div" variant="top" src={images.img_two} />
        </div>
        <div className="d-flex justify-content-center fw-bold">
          <h2>Buyer</h2>
        </div>
        <div className="d-flex justify-content-center fw-bold">
          <p>Need help on buying side</p>
        </div>
      </div>
    </div>
  );
};

export default BuyerOne;
