import { Row, Col } from "react-bootstrap";
import "./cvtemplates.css";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CVTemplate1 from "./CVtemplate1/components/CVImg";
import CVTemplate2 from "./CVtemplate2/components/CVImg2";
import CVTemplate3 from "./CVtemplate3/components/CVImg3";
import CVTemplate4 from "./CVtemplate4/components/CVImg4";
import CVTemplate5 from "./CVtemplate5/components/CVImg5";
import { useSelector } from "react-redux";
import url from "../../config/axios";
import { SuccessNotify, FailedNotify } from "../../constants/tostify";

const CVTemplates = () => {
  const [cvdata, setCvdata] = useState();
  const { name } = useSelector((state) => state.user);
  useEffect(() => {
    url.get(`/cvform?id=${name?._id}`).then(async (res) => {
      setCvdata(res?.data?.cvform);
    });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    button: true,
  };

  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  const Selecttemplate = () => {
    url
      .put(`cvform/update?user=${name?._id}&template=${currentIndex}`)
      .then((res) => {
        if (res) {
          SuccessNotify("CV Template Selected");

          navigate("/create-job-app");
        }
      })
      .catch((err) => {
        FailedNotify("Something went wrong");
      });
  };

  return (
    <Row>
      <div className="screenHeight">
        <Row>
          <Col md={2} lg={2} className="d-flex justify-content-center">
            <div className="d-flex w-100 justify-content-center">
              <div className="leftSideCenter d-flex justify-content-center">
                <div className="CVtexts h-100 d-flex flex-column  justify-content-center ">
                  <h6>Select CV</h6>
                  <h5>Template</h5>
                  <div>
                    <button
                      className="m-0 btn-color "
                      onClick={() => Selecttemplate()}
                    >
                      Select
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className=" ps-4">
                            <hr className="centerline"/>
                            </div> */}
            </div>
          </Col>
          <Col md={10} lg={10}>
            <Row>
              <Col md={2} lg={2} className="pe-0">
                <hr className="cvcenterline " />
              </Col>
              <Col md={10} lg={10} className="mx-auto ">
                <div className=" ">
                  <Slider
                    {...settings}
                    afterChange={(newIndex) => {
                      setCurrentIndex(newIndex);
                    }}
                  >
                    <div>
                      <CVTemplate1 cvdata={cvdata} />
                    </div>
                    <div>
                      <CVTemplate2 cvdata={cvdata} />
                    </div>
                    <div>
                      <CVTemplate3 cvdata={cvdata} />
                    </div>
                    <div>
                      <CVTemplate4 cvdata={cvdata} />
                    </div>
                    <div>
                      <CVTemplate5 cvdata={cvdata} />
                    </div>
                  </Slider>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Row>
  );
};
export default CVTemplates;
