import { Container, Row, Col } from "react-bootstrap";
import Shopintegrate from "../../../../components/shop_integrate";

const Retailerintegrate = () => {
  return (
    <>
      <Container>
        <div className="screenHeight">
          <Shopintegrate
            newshop="/retailer-form"
            url="/retailer-integrate-form"
          />
        </div>
      </Container>
    </>
  );
};

export default Retailerintegrate;
