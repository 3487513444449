import React, { useState } from "react";
import { Col, Row, Button, Container } from "react-bootstrap";
import { images } from "../../constants";
import BackArrow from "../backArrow";
import ShopAdds from "../shopAds/ShopAdds";
import "./FoodReport.css";
import Suggestion from "../../pages/suggestion";
import { ReportBox } from "../../constants/jsonConstant";
import { getAsset } from "../../helpers/helper";
const ReportSection = ({ title,suggestdata }) => {
  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(true);
  const ReadClick = () => {
    setOpen(true);
    setHide(false);
  };
 
  return (
    <>
      <div className="container-fluid">
        <Container className="Container_w">
          <div className="screenHeight">
            <Row className="justify-content-center border row_div border_3 ">
              <Col md={10} lg={10} className="col_adjust col_border webkit">
                <div>
                  <BackArrow />
                </div>
                <div className="padding_adds">
                  <ShopAdds />
                </div>
                <h6>
                  <b>{title}</b>
                </h6>
              </Col>
              {open === true && (
                <Col lg={12} className="mx-auto">
                  <Suggestion setOpen={setOpen} setHide={setHide} />
                </Col>
              )}
            </Row>
            {hide === true && (
              <Row className="justify-content-center border row_div screen_height noscroll mt-2">
                {suggestdata?.map((items,index) => (
                  <Col
                    key={index}
                    md={10}
                    lg={10}
                    className={`col_border border_3 sec_col webkit ${
                      index % 2 === 1 ? "odd-background" : ""
                    }`}
                  >
                    <Col md={2} lg={2}>
                      <img src={getAsset(items?.imageUrl[0])} alt="image" className="img_s" crossOrigin="anonymous" />
                    </Col>
                    <Col md={8} lg={8}>
                      <b>{items?.role}</b>
                    
                      <p className="title breakword">{items?.message}</p>
                    </Col>

                    <Col md={2} lg={2} className="button_padding ">
                      <button
                        type="button"
                        class="btn btn-primary read_btn"
                        onClick={ReadClick}
                      >
                        Read
                      </button>
                    </Col>
                  </Col>
                ))}
              </Row>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default ReportSection;
