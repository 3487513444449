import { Container, Col, Row } from "react-bootstrap";
import { useState } from "react";
import url from "../../config/axios"
import ServicesBio from "./components/servicesBio"
import { useParams, useNavigate } from "react-router-dom";
import { Deletemodal } from "../../pages/index";
import { SuccessNotify, FailedNotify } from "../../constants/tostify";
const ServicesProfile = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [show, setShow] = useState(0);
  const handleClose = () => setShow(0);
  const Deletedata = () => {
    url.delete(`/service/?id=${id}`)
      .then(async (response) => {
        if (response?.data?.success) {
          SuccessNotify("Delete Service SuccessFully")
        }
        setTimeout(() => {
          setShow(0)
          setTimeout(() => {
            navigate("/dashboard")
          }, 4000)
        })
      })
      .catch((error) => {
        FailedNotify()
      })
  }
  return (
    <Container>
      <Col md={10} lg={12} xl={9} className="screenHeight  mx-auto">
        {show === 1 && (
          <Deletemodal
            show={show}
            title="Service"
            handleClose={handleClose}
            DeleteHnadler={Deletedata}
          />
        )}
        <Row className="d-flex justify-content-center">
          <Col md={10} lg={10} ><ServicesBio id={id} setShow={setShow} /></Col>
        </Row>
      </Col>
    </Container>
  )
}
export default ServicesProfile;