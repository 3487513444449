import ReportSection from "../../../components/Food_Repot/ReportSection"

const Servicereport=()=>{
return(<>
<ReportSection title="Report"/>
</>)


}

export default Servicereport