import "../../../../timeline.css";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
const Editbox = ({
  setModalopen,
  postId,
  Deleteitem,
  setShows,
  setUpdatepost,
}) => {

  return (
    <>
      <div className="dottsBox border_3 background-white b-radius font-sm font-grey pt-0">
        <div className="d-flex justify-content-end">
          <CloseIcon className="icon_f" onClick={() => setModalopen(0)} />
        </div>
        <div className="ps-2 pe-2">
          <div className="d-flex " onClick={() => setUpdatepost(true)}>
            <div>
              <ModeIcon className="icon_f" />
            </div>
            <p className="cursor_P">Edit Post</p>
          </div>
          
          <div
            className="d-flex"
            onClick={() => {
              setShows(1);
            }}
          >
            <DeleteIcon className="icon_f" />
            <p className="cursor_P"> Delete Post</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Editbox;
