import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ReplyIcon from "@mui/icons-material/Reply";
import Popover from "@mui/material/Popover";
import ReactPlayer from "react-player";
import "../../timeline.css";
import Reportboxprofile from "../../../Report box profile";
import { Editbox } from "../../../../pages/index";
import ShareOption from "../../../Shareoption";
import Deletemodal from "../../../../components/DeleteModal";
import Commentmodal from "../../../../components/commentmodal/index.js";
import ShareModel from "../../../Sharemodel";
import { useSelector } from "react-redux";
import url from "../../../../config/axios";
import { images } from "../../../../constants";
import { getAsset } from "../../../../helpers/helper";
import { SuccessNotify, FailedNotify } from "../../../../constants/tostify";
import Photovideoupdate from "../../../Update_timelinepost/index.js";
import Subscriptionmdl from "../../../subscription_modal/index.js";
import Purchasemodal from "../../../purchaseplan/purchasemodal.js";

const Viewpost = ({
  rightSide,
  mysetting,
  createPost,
  timelineid,
  getTimelinePosts,
  getmyTimeline,
  setGetmyTimeline,
  id,
}) => {
  const { name } = useSelector((state) => state.user);
  const [comment, setComment] = useState("");
  const [modalopen, setModalopen] = useState(0);
  const [shows, setShows] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [postId, setPostId] = useState();
  const [likes, setLikes] = useState({});
  const [likeCounts, setLikeCounts] = useState({});
  const [dislikeCounts, setDislikeCounts] = useState({});
  const [showshare, setShowshare] = useState();
  const [clickopen, setClickopen] = useState(false);
  const [showmodal, setShowmodal] = useState(false);
  const [comments, setComments] = useState();
  const [sharetoOthers, setShareToOthers] = useState([]);
  const [updatepost, setUpdatepost] = useState(false);

  const [updatedata, setUpdatedata] = useState({
    shopid: id,
    userid: name?._id,
    
        heading: "",
        content: "",
        img: [""],
        video: [""],
    
  });

  const handleClosemodal = () => setShowmodal(false);

  const [lgShow, setLgShow] = useState(false);
  const [purchase, setPurchase] = useState(false);
  const handleclose = () => setClickopen(0);

  const handleShowshare = () => {
    setShowshare(true);
  };
  const handleCloseshare = () => {
    setShowshare(false);
    setShowmodal(true);
  };
  

  console.log("name", name);
  const getTimelineComments = (e) => {
    url
      .get(`/timeline/getShopSuggestion/?postid=${e}&timelineid=${timelineid}`)
      .then((response) => {
        setComments(response?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  
  console.log("getmyTimeline", getmyTimeline);

  useEffect(() => {
    getmyTimeline?.forEach((post) => {
      url
        .get(
          `/timeline/getAllonPost?postid=${post._id}&timelineid=${timelineid}`
        )
        .then((response) => {
          const { likedislike, likeCount, dislikeCount } = response.data;
          setLikes((prevLikes) => ({
            ...prevLikes,
            [post._id]: likedislike,
          }));
          setLikeCounts((prevLikeCounts) => ({
            ...prevLikeCounts,
            [post._id]: likeCount,
          }));
          setDislikeCounts((prevDislikeCounts) => ({
            ...prevDislikeCounts,
            [post._id]: dislikeCount,
          }));
        })
        .catch((err) => {
          console.error("Failed to fetch like/dislike status", err);
        });
    });
  }, [getmyTimeline, timelineid]);

  const handleClose = () => {
    setShows(false);
  };
  
  const handleLikeDislike = (postId, status) => {
    url
      .put(`/timeline/likedislike`, {
        postid: postId,
        timelineid: timelineid,
        likedislike: status,
      })
      .then((response) => {
        if (response.data.success) {
          if (status === "like") {
            setLikes((prevLikes) => ({
              ...prevLikes,
              [postId]: "like",
            }));
            setLikeCounts((prevCounts) => ({
              ...prevCounts,
              [postId]: (prevCounts[postId] || 0) + 1,
            }));

            // Decrease dislike count if previously disliked
            if (likes[postId] === "dislike") {
              setDislikeCounts((prevCounts) => ({
                ...prevCounts,
                [postId]: (prevCounts[postId] || 0) - 1,
              }));
            }
          } else if (status === "dislike") {
            setLikes((prevLikes) => ({
              ...prevLikes,
              [postId]: "dislike",
            }));
            setDislikeCounts((prevCounts) => ({
              ...prevCounts,
              [postId]: (prevCounts[postId] || 0) + 1,
            }));

            // Decrease like count if previously liked
            if (likes[postId] === "like") {
              setLikeCounts((prevCounts) => ({
                ...prevCounts,
                [postId]: (prevCounts[postId] || 0) - 1,
              }));
            }
          }
          SuccessNotify(
            `Post ${status === "like" ? "liked" : "disliked"} successfully`
          );
        }
      })
      .catch((err) => {
        FailedNotify("Failed to update like status. Try again!");
        console.error("Failed to update like status", err);
      });
  };

  const Deleteitem = (postId) => {
    url
      .delete(`/timeline/?postId=${postId}&timelineId=${timelineid}`)
      .then((response) => {
        setGetmyTimeline((prevTimeline) =>
          prevTimeline.filter((post) => post._id !== postId)
        );
        setShows(false);
        SuccessNotify("Post Deleted Successfully");
        getTimelinePosts();
      })
      .catch((e) => {
        FailedNotify("Failed to Delete. Try Again!");
      });
  };
  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = () => {
    if (!comment.trim()) {
      FailedNotify("Comment cannot be empty");
      return;
    }

    url
      .post(`/timeline/addcomment`, {
        timelineid: timelineid,
        postid: postId,
        vendorid: id, // Replace with actual vendor ID if needed
        suggestions: [
          {
            userId: name._id, // Ensure user.id is available in your state
            shopid: id, // Replace with actual shop ID if needed
            username: name.fullname, // Ensure user.fullname is available in your state
            userEmail: name.email, // Ensure user.email is available in your state
            role: name.bio.text, // Ensure user.role is available in your state
            imageUrl: name.imageUrl[0], // Add image URLs if needed
            message: comment,
            replies: [], // Add replies if needed
          },
        ],
      })
      .then((response) => {
        if (response.data.success) {
          SuccessNotify("Comment added successfully");
          handleclose(); // Close the modal
        }
      })
      .catch((err) => {
        FailedNotify("Failed to add comment. Try again!");
      });
  };
  const getShareTimeline = () => {
    url
      .get(`timeline/getAllConnectedShopstype?shopid=${id}&userid=${name._id}`)
      .then((response) => {
        console.log("response", response);
        setShareToOthers(response?.data?.shareto);
      })
      .catch((err) => {
        FailedNotify("Failed to share post. Try again!");
      });
  };

  const UpdatePost = () => {
    url
      .patch(
        `timeline/updateMyPost?timelineId=${timelineid}&postId=${postId}`,
        updatedata
      )
      .then((response) => {
       
        if (response?.status === 200) {
          SuccessNotify("Post Updated Successfully");
          setUpdatepost(false);
          getTimelinePosts();
          
        }
      })
      .catch((error) => {
        FailedNotify("Failed to update post");
      });
  };


  return (
    <>


      <Subscriptionmdl lgShow={lgShow} setLgShow={setLgShow} setPurchase={setPurchase} />
      <Purchasemodal purchase={purchase} setPurchase={setPurchase} />

      <ShareOption
        id={id}
        postId={postId}
        timelineid={timelineid}
        sharetoOthers={sharetoOthers}
        showshare={showshare}
        handleCloseshare={handleCloseshare}
      />

      <ShareModel
        show={showmodal}
        handleClosemodal={handleClosemodal}
        handleShowshare={handleShowshare}
      />

      <Photovideoupdate UpdatePost={UpdatePost} show={updatepost}  setUpdatepost={setUpdatepost}  timelineid={timelineid} id={id} postId={postId} updatedata={updatedata} setUpdatedata={setUpdatedata} />

      {shows && (
        <Deletemodal
          shows={shows}
          title="Timeline Post"
          handleClose={handleClose}
          animation={true}
          handledelete={Deleteitem}
          postId={postId}
        />
      )}
      <Col lg="10">
        <div className="d-flex justify-content-center position-relative mt-2 mb-2 ps-2 pe-2 ps-lg-0 pe-lg-0">
          {" "}
          <img className="subscriptionimg" src={images?.gh}></img>
          <button
            className="btn btn-primary w-100"
            onClick={() => setLgShow(true)}
          >
            View Subscription plan
          </button>
        </div>
        <div
          className={`noscroll ${
            rightSide === 11 ? "timelinescrollTwo" : "timelinescroll"
          }`}
        >
          {getmyTimeline?.map((post) => (
            <Row className="mt-2" key={post._id}>
              <Col lg="8">
                <div className="d-flex">
                  <img
                    src={images.ProfileImg}
                    alt=""
                    className="personImgSize"
                  />
                  <div>
                    <h4 className="font-sm mb-0 mt-2 ">
                      &nbsp;&nbsp;{name?.fullname}
                    </h4>
                    <p className="font_small">&nbsp;&nbsp;{post.category}</p>
                  </div>
                </div>
              </Col>

              <Col
                lg="4"
                className="d-flex justify-content-end"
                style={{ position: "relative" }}
              >
                <MoreHorizIcon
                  onClick={() => {
                    setModalopen(post._id);
                    setPostId(post._id);
                  }}
                />
                {modalopen === post._id &&
                  (mysetting.length === 4 ? (
                    <Editbox
                      setModalopen={setModalopen}
                      onClick={() => setPostId(post._id)}
                      Deleteitem={Deleteitem}
                      setShows={setShows}
                      setUpdatepost={setUpdatepost}
                    />
                  ) : (
                    <Reportboxprofile setModalopen={setModalopen} />
                  ))
                  }

              </Col>

              <h5 className="font-navy font-bold">{post.heading}</h5>
              <p className="font_small">{post.content}</p>

              {post.img.map(
                (imgSrc, index) =>
                  imgSrc && (
                    <img
                      key={index}
                      src={getAsset(imgSrc)}
                      crossOrigin="anonymous"
                      alt=""
                      className="w-100"
                    />
                  )
              )}

              {post.video.map(
                (videoSrc, index) =>
                  videoSrc && (
                    <ReactPlayer
                      key={index}
                      url={videoSrc}
                      controls={true}
                      width="100%"
                    />
                  )
              )}

              <div className="d-flex justify-content-between font-sm mt-1">
                <div>
                  <div style={{ display: "flex", cursor: "pointer" }}>
                    <p className="pe-2 mb-0">
                      {likeCounts[post._id] || 0} Like
                    </p>
                  </div>
                </div>
                <div>
                  <div style={{ display: "flex", cursor: "pointer" }}>
                    <p className="mb-0">
                      {dislikeCounts[post._id] || 0} Dislike
                    </p>
                  </div>
                </div>
                <div>
                  <p className="pe-3 mb-0">44 Comments</p>
                </div>
                <div>
                  <p className="mb-0">6 Shares</p>
                </div>
              </div>
              <div className="d-flex justify-content-between font-sm mt-2">
                <div style={{ display: "flex", cursor: "pointer" }}>
                  <ThumbUpAltIcon
                    style={{
                      color: likes[post._id] === "like" ? "blue" : "gray",
                    }}
                    onClick={() => handleLikeDislike(post._id, "like")}
                  />
                </div>
                <div style={{ display: "flex", cursor: "pointer" }}>
                  <ThumbDownAltIcon
                    style={{
                      color: likes[post._id] === "dislike" ? "red" : "gray",
                    }}
                    onClick={() => handleLikeDislike(post._id, "dislike")}
                  />
                </div>
                <div
                  className="d-flex font-grey"
                  onClick={() => {
                    setClickopen(post?.id);
                    getTimelineComments(post?._id);
                    setPostId(post?._id);
                  }}
                >
                  <ChatBubbleIcon />
                  <p className="pe-2">Comment</p>
                </div>
                <div
                  className="d-flex font-grey"
                  onClick={() => {
                    getShareTimeline();
                    setShowmodal(true);
                    setPostId(post?._id);
                  }}
                >
                  <ReplyIcon />
                  <p>Share</p>
                </div>
              </div>
              {clickopen === post?.id && (
                <Commentmodal
                  id={id}
                  comments={comments}
                  handleclose={handleclose}
                  handleCommentChange={handleCommentChange}
                  handleSubmit={handleSubmit}
                />
              )}
            </Row>
          ))}
        </div>
      </Col>
    </>
  );
};

export default Viewpost;
