import { Container} from "react-bootstrap";
import Shopintegrate from "../../components/shop_integrate";

const Serviceintegrate = () => {
  return (
    <>
      <Container>
        <div className="screenHeight">
          <Shopintegrate
            newshop="/create-service"
            url="/service-integrate-form"
          />
        </div>
      </Container>
    </>
  );
};

export default Serviceintegrate;
