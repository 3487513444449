import { Row, Col, Card, Pagination } from "react-bootstrap";
import { images } from "../../../../constants";
import { multipleshops } from "../../../../constants/jsonConstant";
import { useNavigate, useLocation } from "react-router-dom";
import RedoIcon from "@mui/icons-material/Redo";
import { useEffect } from "react";
import { useState, useRef } from "react";
import { ShopManage } from "../../../../pages";
import GradeIcon from "@mui/icons-material/Grade";
import Loader from "../../../Loader";
import ShareModel from "../../../Sharemodel";
import ShareOption from "../../../Shareoption";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { updateItemName } from "../../../../store/modules/itemdata"
import { getAsset } from "../../../../helpers/helper";

const Shops = ({
  link,
  title,
  handleShow,
  Getdata,
  shopdata,
  Newitemdataraw,
  isloading,
  itemmdata,
}) => {


  const dispatch = useDispatch();
  const [showmodal, setShowmodal] = useState(false);
  const [showshare, setShowshare] = useState(false);
  const [show, setShow] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {

    if (location.pathname === "/business-dashboard") {
      Newitemdataraw();
    }
  }, []);

  const closemodal = () => setShowmodal(false);
  const myStateRef = useRef(0);
  const setMyState = (newValue) => {
    myStateRef.current = newValue;
  };
  const handleCloseshare = () => {
    setShowshare(0);
    setShow(1);
  };
  const handleShowshare = () => {
    setShow(0);
    setShowshare(1);
  };
  const Navigatefun = async (e) => {


    const databyshopid = await itemmdata?.filter(item => location.pathname === "/find-property" ? item?.companyid === e : location.pathname === "/find-job" ? item.companyId
      === e : item?.shopid === e);

    dispatch(updateItemName(databyshopid));



    navigate(`${link}/${e}`);

  }
  return (
    <>
      <ShareOption showshare={showshare} handleCloseshare={handleCloseshare} />
      <ShareModel
        show={showmodal}
        handleClosemodal={closemodal}
        handleShowshare={handleShowshare}
      />
      <div className="me-lg-4 ms-lg-4 me-md-4 ms-md-4 me-sm-4 ms-sm-4 me-4 ms-5 ">
        <h6 className="font-primary ms-2">{title}</h6>
        <div className="shops_buying_h noscroll">
          <Row>
            <>
              {isloading ? (
                <Loader heightsetting="screenHeight" />
              ) : shopdata?.length > 0 ? (

                shopdata?.map((e) => (
                  <Col
                    lg="4"
                    xl="3"
                    md="4"
                    sm="4"
                    xs="12"
                    className="mt-1 position-relative"
                  >
                    <Card
                      className={
                        location.pathname === `/business-dashboard`
                          ? "ShopsCards_2"
                          : "ShopsCards"
                      }
                    >
                      <div className="fix-img">
                        <img className="c_img" src={getAsset(e?.image)} alt="" crossOrigin="anonymous" />
                      </div>

                      <Card.Body className="p-2">
                        <button
                          className="text-white bg-blue share_font btn-position-absolute"
                          onClick={() => {
                            handleShow();
                            setShowmodal(true);
                          }}
                        >
                          Share <RedoIcon className="share_icon_f" />
                        </button>
                        <Card.Title className="font-sm-15 mb-0 pb-1">
                          <div className="d-flex justify-content-between">
                            {
                              location.pathname === "/find-job" ?
                                (
                                  <div>{e?.companyname.slice(0, 12)}</div>
                                )
                                :
                                location.pathname === "/buy-used-cars" ?
                                  (
                                    <div>{e?.shopname.slice(0, 12)}</div>
                                  )
                                  : location.pathname === "/find-property" ? (
                                    <div>{e?.companyname.slice(0, 12)}</div>
                                  ) :
                                    location.pathname === "/find-services" ? (
                                      <div>{e?.profileName.slice(0, 12)}</div>

                                    ) : (<div>{e?.shopname.slice(0, 12)}</div>)
                            }

                            <div className="font_12">(Views:51)</div>
                          </div>
                        </Card.Title>

                        <Card.Text className="font-sm font-primary mb-2">
                          <div className="d-flex justify-content-between">
                            <div>{e?.shopaddress}</div>
                            <div style={{ color: "black" }}>
                              <b>
                                <GradeIcon
                                  style={{
                                    fontSize: "medium",
                                    color: "#FFE507",
                                  }}
                                />
                                (4.7)
                              </b>
                            </div>
                          </div>
                        </Card.Text>

                        <div className="d-flex justify-content-between ">
                          <button
                            className=" viewshop w-50  pe-1 ps-2 text-white bg-blue"

                            onClick={() => Navigatefun(e?._id)
                            }
                          >
                            View
                          </button>
                          <button
                            style={{ color: "#0353A4" }}
                            className="viewshop ps-3 pe-3 ms-1 fw-bold"
                          >
                            Connect
                          </button>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              )

                : (<h3 className="text-center mb-3 mt-2">No Data Found</h3>)
              }
              {location.pathname === "/business-dashboard" ? (
                ""
              ) : (
                <>
                  <Col lg="4 mt-2">
                    <Card>
                      <Card.Img src={images.Shopadone} />
                    </Card>
                  </Col>

                  <Col lg="4 mt-2">
                    <Card>
                      <Card.Img src={images.Shopadtwo} />
                    </Card>
                  </Col>

                  <Col lg="4 mt-2">
                    <Card>
                      <Card.Img src={images.Shopadthree} />
                    </Card>
                  </Col>
                </>
              )}
            </>
          </Row>
        </div>


      </div >
    </>
  );
};

export default Shops;
