import { Row, Col } from "react-bootstrap";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
const Chatroombackarrow = ({url}) => {
  const navigate = useNavigate();
  return (
    <>
      <Row>
        <Col lg="3" className="cursor_P">
          <KeyboardBackspaceIcon
            className="text-white back-arrow-font"
            onClick={() => navigate(url)}
          />
        </Col>
      </Row>
    </>
  );
};

export default Chatroombackarrow;
