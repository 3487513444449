import React from "react";
import { Col, Row } from "react-bootstrap";
import { images } from "../../../constants";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation } from "react-router-dom";
import BackArrow from "../../backArrow";
import { useRef, useEffect, useState } from "react";
import PersonalImage from "../../../helpers/fileUpload";
import Card from "react-bootstrap/Card";
import { currencies } from "../../../constants/jsonConstant";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Measurementunit } from "../../../constants/jsonConstant";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { salepromotion } from "../../../constants/jsonConstant";
import { saleoffer } from "../../../constants/jsonConstant";
import { getAsset } from "../../../helpers/helper";

const PostAddForm = ({
  handlerFunction,
  setUserData,
  userData,
  back,
  cancel,
  id,
  butn,
  Updatehandler,
  index,
  updateurl,
  backurl,
  shopCategorytwo,
  shopCategory,
  Handledata,
  data,
  Mydata,
  getInputProps,
  getRootProps,
  displayimg,
  setDisplayimg,
  FirstCategory
}) => {
  const location = useLocation();

  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedstateone, setSelectedstateone] = useState("");
  const [searchTermone, setSearchTermone] = useState("");

  const [selectedcity, setSelectedcity] = useState("");
  const [searchTermtwo, setSearchTermtwo] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle the country selection
  const handleCountryChange = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
  };

  const handleSearchChangestate = (event) => {
    setSearchTermone(event.target.value);
  };

  const handleSearchChangecity = (event) => {
    setSearchTermtwo(event.target.value);
  };

  // Function to handle the state selection
  const handleCountryChangestate = (event) => {
    const { value } = event.target;
    setSelectedstateone(value);
  };

  // Function to handle the city selection
  const handleCountryChangecity = (event) => {
    const { value } = event.target;
    setSelectedcity(value);
  };
  const [hidefield, setHidefield] = useState(1);
  const [hidestate, setHidestate] = useState(1);
  const [hidecity, setHidecity] = useState(1);
  const [countrydata, SetCountrydata] = useState([]);
  const [getcountry] = useState();
  const [selectedState] = useState();
  const [getState, setState] = useState([]);
  const [getcity, setGetcity] = useState([]);
  const [statetwo, setStatetwo] = useState(getcountry);
  const [city, setCity] = useState(selectedState);
  const [currency, setCurrency] = useState();

  const inputfile = useRef(null);

  // const onChangePicture = (e) => {
  //   if (e.target.files[0]) {
  //     console.log("mtfile", e.target.files[0]);
  //     setSelectedImage(e.target.files[0]);
  //     // PersonalImage(e.target.files[0]);
  //     const reader = new FileReader();
  //     reader.readAsDataURL(e.target.files[0]);
  //   }
  // };

  const inputChange = (event) => {
    setUserData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => SetCountrydata(res.data))

      .catch((error) => { });
  }, []);

  const country = [...new Set(countrydata.map((item) => item.country))];

  country.sort();
  const handleCountry = (e) => {
    let states = countrydata.filter(
      (state) => state.country === e.target.value
    );
    states = [...new Set(states.map((item) => item.subcountry))];
    states.sort();
    setState(states);
  };

  const handleState = (e) => {
    let cities = countrydata.filter(
      (city) => city.subcountry === e.target.value
    );
    setGetcity(cities);
  };

  const navigate = useNavigate();

  const validationSchema = Yup.object({
    itemname: Yup.string().required("itemname is required*"),
    country: Yup.string().required("Country is required*"),
    state: Yup.string().required("State is required*"),
    city: Yup.string().required("City is required*"),
    price: Yup.string().required("price is required*"),
    category: Yup.string().required("category is required*"),
    subCategory: Yup.string().required("subcategory is required"),
    quantity: Yup.string().required("totalquantity is required"),
    // orderquantity: Yup.string().required("Minimum order quantity is required"),
    // unitprice: Yup.string().required("unitprice is required"),
    // measurement: Yup.string().required("measurement is required"),
    // currency: Yup.string().required("currency is required"),
    description: Yup.string().required("description is required"),
  });

  const removeImage = (index) => {
    const updatedImages = [...displayimg];
    updatedImages.splice(index, 1);
    setDisplayimg(updatedImages);
  };
  return (
    <>
      <Formik
        initialValues={{
          itemname: "",
          country: "",
          state: "",
          city: "",
          price: "",
          category: "",
          subCategory: "",
          quantity: "",
          // orderquantity: "",
          // unitprice: "",
          // measurement: "",
          // currency: "",
          description: "",
        }}
        // validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          // same shape as initial values
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
          isValid,
          dirty,
        }) => (
          <Form
            onSubmit={(e) => {
              // userDatahandler(e);
              handleSubmit(e);
            }}
          >
            <Row>
              <div className="screenHeight d-flex justify-content-center align-items-center">
                <Col lg={9} md={11} className="Form-Shadow  pb-3">
                  <div className="arrowback ps-3">
                    {location.pathname === `${updateurl}${index}` ? (
                      <BackArrow
                        back={`${backurl}/${userData?.shopid}/${index}`}
                        black="black"
                        mystyle="p-1 ps-4"
                      />
                    ) : (
                      <BackArrow
                        back={back + id}
                        black="black"
                        mystyle="ps-4 p-1"
                      />
                    )}
                  </div>

                  <Row className="pt-3 ps-5 pe-5">
                    <Col className="" md={3} lg={3}>
                      <Form.Label className="custm-lble ">Item Name</Form.Label>
                      <Form.Control
                        placeholder="Item Name"
                        type="text"
                        name="itemname"
                        className="labelText form-control-seller"
                        onChange={(e) => {
                          handleChange(e);
                          inputChange(e);
                        }}
                        onBlur={handleBlur}
                        defaultValue={userData?.itemname}
                      />
                      {errors.itemname && touched.itemname ? (
                        <div>
                          <p className="error_text_color">{errors.itemname}</p>
                        </div>
                      ) : null}
                    </Col>

                    {location.pathname === `/recycle-postadd/${id}` ||
                      location.pathname === `/rawmaterial-postAdd/${id}` ? (
                      ""
                    ) : (
                      <Col className="" md={3} lg={3}>
                        <Form.Label className="custm-lble ">
                          Brand Name
                        </Form.Label>
                        <Form.Control
                          placeholder="Optional"
                          type="text"
                          name="brandname"
                          className="labelText form-control-seller"
                          // onChange={(e) => {
                          //   handleChange(e);
                          //   inputChange(e);
                          // }}
                          onBlur={handleBlur}
                          defaultValue={userData?.brandname}
                        />
                        {errors.brandname && touched.brandname ? (
                          <div>
                            <p className="error_text_color">
                              {errors.brandname}
                            </p>
                          </div>
                        ) : null}
                      </Col>
                    )}

                    <Col md={3} lg={3}>
                      <Form.Label className="custm-lble ">Category</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="category"
                        onChange={(e) => {
                          Handledata(e);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        className="form-select-seller"
                        onClick={FirstCategory}
                      >
                        <option>Select Category</option>
                        {shopCategory?.map((category) => (
                          <>
                            <option
                              key={category._id}
                              value={`${category?._id}.${category?.name}`}
                            >
                              {category?.name}
                            </option>
                          </>
                        ))}
                      </Form.Select>
                      {errors.category && touched.category ? (
                        <div>
                          <p className="error_text_color">{errors.category}</p>
                        </div>
                      ) : null}
                    </Col>
                    <Col className="" md={3} lg={3}>
                      <Form.Label className="custm-lble ">
                        Sub Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="subCategory"
                        className="form-select-seller"
                        onChange={(e) => {
                          Handledata(e);
                          handleChange(e);
                        }}
                        onClick={Mydata}
                        onBlur={handleBlur}
                      >
                        <option>Select Sub Category</option>
                        {shopCategorytwo?.map((subcategory) => (
                          <>
                            <option
                              key={subcategory._id}
                              value={`${subcategory?._id}.${subcategory?.name}`}
                            >
                              {subcategory?.name}
                            </option>
                          </>
                        ))}
                      </Form.Select>
                      {errors.subCategory && touched.subCategory ? (
                        <div>
                          <p className="error_text_color">
                            {errors.subCategory}
                          </p>
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="pt-3 ps-5 pe-5">
                    <Col md={3} lg={3}>
                      <Form.Label className="custm-lble  ">Price</Form.Label>
                      <Form.Control
                        placeholder="Price"
                        type="number"
                        name="price"
                        onChange={(e) => {
                          handleChange(e);
                          inputChange(e);
                        }}
                        className="labelText form-control-seller"
                        onBlur={handleBlur}
                        defaultValue={userData?.price}
                      />
                      {errors.price && touched.price ? (
                        <div>
                          <p className="error_text_color">{errors.price}</p>
                        </div>
                      ) : null}
                    </Col>

                    <Col className="" md={3} lg={3}>
                      <Form.Label className="custm-lble ">
                        Total Quantity
                      </Form.Label>
                      <Form.Control
                        placeholder="Total Quantity"
                        type="number"
                        className="labelText form-control-seller"
                        onChange={(e) => {
                          handleChange(e);
                          inputChange(e);
                        }}
                        name="quantity"
                        onBlur={handleBlur}
                      />
                      {errors.totalquantity && touched.totalquantity ? (
                        <div>
                          <p className="error_text_color">
                            {errors.totalquantity}
                          </p>
                        </div>
                      ) : null}
                    </Col>

                    <div className="col-md-3">
                      <label
                        htmlFor="inputPassword4"
                        placeholder="First name"
                        className="form-label labelSize"
                      >
                        Country*
                      </label>
                      <div className="blood-type">
                        <div className="d-flex">
                          <div className="w-100">
                            <input
                              type="search"
                              value={searchTerm}
                              onChange={handleSearchChange}
                              placeholder="Search country here...."
                              className="w-100 search-input"
                              onClick={() => setHidefield(0)}
                            />
                          </div>
                          <div>
                            <span>
                              <SearchIcon className="search-icon-fs" />
                            </span>
                          </div>
                        </div>

                        <select
                          onClick={() => setHidefield(1)}
                          value={selectedCountry}
                          onChange={(e) => {
                            handleCountryChange(e);
                            handleCountry(e);
                            inputChange(e);
                            handleChange(e);
                          }}
                          className="b-radius selectSize w-100"
                          name="country"
                          onBlur={handleBlur}
                        >
                          {hidefield && <option>Select country</option>}

                          {country
                            .filter((country) =>
                              country
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            )
                            .map((country, index) => (
                              <option key={country} value={getcountry}>
                                {country}
                              </option>
                            ))}
                        </select>
                      </div>
                      {errors.country && touched.country ? (
                        <div>
                          <p className="error_text_color">{errors.country}</p>
                        </div>
                      ) : null}
                    </div>

                    <div className="col-md-3">
                      <label
                        htmlFor="inputPassword4"
                        placeholder="First name"
                        className="form-label labelSize"
                      >
                        State*
                      </label>
                      <div className="blood-type">
                        <div className="d-flex">
                          <div className="w-100">
                            <input
                              type="search"
                              value={searchTermone}
                              onChange={handleSearchChangestate}
                              placeholder="Search state here...."
                              className="w-100 search-input"
                              onClick={() => setHidestate(0)}
                            />
                          </div>
                          <div>
                            <span>
                              <SearchIcon className="search-icon-fs" />
                            </span>
                          </div>
                        </div>

                        <select
                          onClick={() => setHidestate(1)}
                          className="b-radius selectSize w-100 "
                          value={selectedstateone}
                          aria-label="Default select example "
                          name="state"
                          onChange={(e) => {
                            inputChange(e);
                            handleChange(e);
                            handleState(e);
                            handleCountryChangestate(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {hidestate && <option>Select state</option>}
                          {/* <option>
                            {Open === undefined
                              ? "Select Country First"
                              : "select state"}
                          </option> */}

                          {getState
                            .filter((country) =>
                              country
                                .toLowerCase()
                                .includes(searchTermone.toLowerCase())
                            )
                            .map((country) => (
                              <option key={country} value={selectedState}>
                                {country}
                              </option>
                            ))}
                        </select>
                      </div>
                      {errors.state && touched.state ? (
                        <div>
                          <p className="error_text_color">{errors.state}</p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-3 mt-3">
                      <label
                        htmlFor="inputPassword4"
                        placeholder="First name"
                        className="form-label labelSize"
                      >
                        City
                      </label>
                      <div className="blood-type">
                        <div className="d-flex">
                          <div className="w-100">
                            <input
                              type="search"
                              value={searchTermtwo}
                              onChange={handleSearchChangecity}
                              placeholder="Search city here...."
                              className="w-100 search-input"
                              onClick={() => setHidecity(0)}
                            />
                          </div>
                          <div>
                            <span>
                              <SearchIcon className="search-icon-fs" />
                            </span>
                          </div>
                        </div>

                        <select
                          onClick={() => setHidecity(1)}
                          value={selectedcity}
                          className="b-radius selectSize w-100"
                          aria-label="Default select example"
                          name="city"
                          onChange={(e) => {
                            inputChange(e);
                            handleChange(e);
                            handleCountryChangecity(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {/* <option>
                              {city === undefined
                                ? "Select State First"
                                : "Select City"}
                            </option> */}

                          {hidecity && <option>Select city</option>}

                          {getcity
                            .filter((country) =>
                              country.name
                                .toLowerCase()
                                .includes(searchTermtwo.toLowerCase())
                            )
                            .map((country) => (
                              <option key={country.name}>{country.name}</option>
                            ))}
                        </select>
                      </div>
                      {errors.city && touched.city ? (
                        <div>
                          <p className="error_text_color">{errors.city}</p>
                        </div>
                      ) : null}
                    </div>

                    <Col className=" mt-3" md={3} lg={3} xl={3}>
                      <Form.Label className="custm-lble">
                        Unit of Measurement{" "}
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="unitOfMeasurement"
                        className="form-select-seller"
                        onChange={(e) => {
                          handleChange(e);
                          inputChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        <option disabled selected>
                          Select Unit
                        </option>
                        {Measurementunit.map((e) => (
                          <option>{e.name}</option>
                        ))}
                      </Form.Select>
                      {errors.measurement && touched.measurement ? (
                        <div>
                          <p className="error_text_color">
                            {errors.measurement}
                          </p>
                        </div>
                      ) : null}
                    </Col>

                    <Col md={3} lg={3} xl={3} className="mt-3">
                      <Form.Label className="custm-lble">Currency </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="currency"
                        className="form-select-seller"
                        onChange={(e) => {
                          handleChange(e);
                          inputChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        <option disabled selected>
                          Select Currency
                        </option>
                        {currencies.map((e) => (
                          <option>
                            {e.abbreviation} {e.name}
                          </option>
                        ))}
                      </Form.Select>
                      {errors.currency && touched.currency ? (
                        <div>
                          <p className="error_text_color">{errors.currency}</p>
                        </div>
                      ) : null}
                    </Col>

                    {location.pathname === `/wholesaler-postadd/${id}` ||
                      location.pathname === `/manufacturer-postAdd/${id}` ||
                      location.pathname === `/rawmaterial-owner-profile/${id}` ? (
                      <>
                        <Col className="mt-3" md={3} lg={3}>
                          <Form.Label className="custm-lble">
                            Unit Price
                          </Form.Label>
                          <Form.Control
                            placeholder="Unit Price"
                            type="number"
                            name="unitPrice"
                            className="labelText form-control-seller"
                            onChange={(e) => {
                              handleChange(e);
                              inputChange(e);
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.unitprice && touched.unitprice ? (
                            <div>
                              <p className="error_text_color">
                                {errors.unitprice}
                              </p>
                            </div>
                          ) : null}
                        </Col>

                        <Col className="mt-3" md={3} lg={3}>
                          <Form.Label className="custm-lble ">
                            Minimum Order Quantity
                          </Form.Label>
                          <Form.Control
                            placeholder="Minimum Order Quantity"
                            type="number"
                            name="minOrderQuantity"
                            className="labelText form-control-seller"
                            onChange={(e) => {
                              handleChange(e);
                              inputChange(e);
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.orderquantity && touched.orderquantity ? (
                            <div>
                              <p className="error_text_color">
                                {errors.orderquantity}
                              </p>
                            </div>
                          ) : null}
                        </Col>
                      </>
                    ) : (
                      ""
                    )}

                    {location.pathname === `/rawmaterial-postAdd/${id}` ||
                      location.pathname === `/manufacturer-postAdd/${id}` ||
                      location.pathname === `/wholesaler-postadd/${id}` ||
                      location.pathname === `/recycle-postadd/${id}` ? (
                      ""
                    ) : (
                      <>
                        <Col className=" mt-3" md={3} lg={3} xl={3}>
                          <Form.Label className="custm-lble">
                            Sale promotion (optional)
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="salespromotion"
                            className="form-select-seller"
                            onChange={(e) => {
                              handleChange(e);
                              inputChange(e);
                            }}
                          >
                            <option disabled selected hidden>
                              Select an option
                            </option>
                            {salepromotion.map((e) => (
                              <option>{e.label}</option>
                            ))}
                          </Form.Select>
                        </Col>
                        <Col className=" mt-3" md={3} lg={3} xl={3}>
                          <Form.Label className="custm-lble">
                            Special offer (optional)
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="specialoffer"
                            className="form-select-seller"
                            onChange={(e) => {
                              handleChange(e);
                              inputChange(e);
                            }}
                          >
                            <option disabled selected hidden>
                              Select an option
                            </option>
                            {saleoffer.map((e) => (
                              <option>{e.label}</option>
                            ))}
                          </Form.Select>
                        </Col>
                      </>
                    )}
                  </Row>
                  <div className=" pt-3 pb-2 ps-5 pe-5">
                    <Form.Label className="custm-lble pb-1">
                      Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={6}
                      name="description"
                      placeholder="Description"
                      onChange={(e) => {
                        handleChange(e);
                        inputChange(e);
                      }}
                      defaultValue={userData?.description}
                      onBlur={handleBlur}
                    />
                    {errors.description && touched.description ? (
                      <div>
                        <p className="error_text_color">{errors.description}</p>
                      </div>
                    ) : null}
                  </div>

                  <Col lg="9" className="me-auto">
                    <Row>
                      <Col lg={2} className="mt-2 ms-5">
                        <Card
                          style={{ width: "6rem", height: "6rem" }}
                          {...getRootProps()}
                          className="dropzone"
                        >
                          <Card.Img
                            className="imet-fit"
                            src={images.AddImage}
                          />
                          <Card.Body>
                            <input
                              type="file"
                              id="file"
                              style={{ display: "none" }}
                              {...getInputProps()}
                              multiple
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                      {displayimg?.length > 0 && (
                        <Col lg={8} className=" mt-2 d-flex">
                          {displayimg.map((image) => (
                            <Card
                              className="me-2"
                              style={{
                                width: "6rem",
                                height: "6rem",
                                border: "2px solid",
                              }}
                            >
                              <Card.Img
                                className="imet-fit upload-img"
                                src={getAsset(image)}
                                alt=""
                                crossOrigin="anonymous"
                              />
                              <div
                                className="close-btnone"
                                onClick={() => removeImage(index)}
                              >
                                <ClearIcon />
                              </div>
                            </Card>
                          ))}
                        </Col>
                      )}
                    </Row>
                  </Col>

                  <div className="d-flex justify-content-end mt-2 mb-2 align-items-center ">
                    <div>
                      <button
                        className="btn-red-sm"
                        onClick={() => navigate(`${cancel}${id}`)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="ms-3 me-4 ">
                      <button
                        className="btn-blue-sm disable-btn"
                        onClick={
                          butn === "Post" ? handlerFunction : Updatehandler
                        }
                        data-title={
                          location.pathname === `${updateurl}${index}`
                            ? null
                            : !(isValid && dirty)
                              ? "Please fill out all Fields to enable button!"
                              : null
                        }
                        disabled={
                          location.pathname === `${updateurl}${index}`
                            ? ""
                            : !(isValid && dirty)
                        }
                      >
                        {butn}
                      </button>
                    </div>
                  </div>
                </Col>
              </div>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PostAddForm;
