import React,  { useState } from 'react'
import { Container } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import StarBorderPurple500SharpIcon from '@mui/icons-material/StarBorderPurple500Sharp';
import ReplyIcon from '@mui/icons-material/Reply';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import "./Style.css"

const HeaderNav2 = () => {

  const [isOpen, setIsOpen] = useState(false);

  function handleClick() {
    setIsOpen(true);
  }



  return (
    <div>
     
      <div>
      <Navbar>
        <DeleteOutlineSharpIcon className='ms-2'/>
 
      <Container fluid>
       
         
       
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto"
            navbarScroll
          >
           <StarBorderPurple500SharpIcon />
            <ReplyIcon className='ms-3'/>

          </Nav>
         
          <MoreVertIcon onClick={handleClick}/>
    {isOpen && (
      <div className=' dropdownone' >
      <div className='mb-2' >

        <ClearSharpIcon className='ms-5' onClick={() =>setIsOpen(false)} />
        <p className='ps-1 '>block</p>
        <p className='ps-1'>Mute</p>
      </div>
      </div>
    )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
      </div>
      
    </div>
  )
}

export default HeaderNav2
