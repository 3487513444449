import { Container, Row, Col } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Rating from "@mui/material/Rating";
import { useState } from "react";
import { images } from "../../constants";
import Typography from "@mui/material/Typography";
import { transportbtn } from "../../constants/jsonConstant";
import { Vehicaldetail } from "../../pages/index";
import "./singleTransport.css";
const Singletransport = () => {
  const [value] = useState(3);

  return (
    <>
      <Container>
        <Row>
          <Col lg="8" className="mx-auto screenHeight border">
            <ArrowBackIcon />
            <Row>
              <Col lg="3">
                <div className="img_fix mt-2">
                  <img
                    src={images.Transportprofile}
                    alt=""
                    className="transport_img"
                  />
                </div>
              </Col>
              <Col lg="8 ms-4">
                <h2 className="font-bold mt-5">Fajar Saddiq</h2>
                <p className="mb-1 font-sm-15 spaces">
                  Pakitan, Kpk, Abbottabad
                </p>
                <p className="mb-1 font-sm spaces">+92312-9089012</p>
                <p className="mb-1 font-sm spaces">james.le23@gmail.com </p>

                <Rating name="read-only" value={value} readOnly />
                <p className="mb-1 font_small">
                  <Typography component="legend" className="font_small">
                    <b>3.6</b>
                  </Typography>
                </p>
                <Row>
                  {transportbtn.map((e) => (
                    <Col lg="3">
                      <button
                        className={`font_small transport-btn text-white b-radius ${
                          e.id === 4 ? "none" : "block"
                        }`}
                        style={{ background: e.color }}
                      >
                        {e.icon} {e.title}
                      </button>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Vehicaldetail heightclass="singleheight" />
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Singletransport;
