import Singleaddshow from "../../../components/Singleitem_show";
import { useEffect, useState } from "react";
import url from "../../../config/axios";
import { SuccessNotify } from "../../../constants/tostify";
import { FailedNotify } from "../../../constants/tostify";
import { useSelector } from "react-redux";
const Scrapitemview = () => {
  const [singleitemadd, setSingleitemadd] = useState();
  const [shows, setShows] = useState(0);
  const [loading, setLoading] = useState(false);
const {name}=useSelector((state)=>state.user)
  useEffect(() => {
    setLoading(true);
    url
      .get(`shopitem/getscrapItemByUser/${name._id}`)
      .then(async (res) => {
      
        if (res.data.success) {
          console.log("simple", res);

          setLoading(false);
          setSingleitemadd(res.data.data.ShopItems);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Singleaddshow
        postlink="/scrape-material"
        singleitemadd={singleitemadd}
        navurl="/scrap-single-item/"
        loading={loading}
        setShows={setShows}
        shows={shows}
      />
    </>
  );
};

export default Scrapitemview;
