import { Row, Col } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { images } from "../../../../../constants/index";
const CVProfileBio = ({ cvdata }) => {
  const now1 = 80;
  const now2 = 65;
  const now3 = 100;
  const now4 = 70;

  return (
    <Row>
      <Col md={12} lg={12}>
        <div className="yellow-back me-5 "></div>
        <div className="d-flex ">
          <div className="position-relative">
            <div className="position-absolute pt-4 mt-3">
              {" "}
              <img src={images.DownLine} />
            </div>
          </div>

          <div className="pt-4 ps-4">
            <h5 className="text-white  fs-2 fw-bold mb-0 ">
              {cvdata?.profileInformation?.firstName}
            </h5>
            <h6 className="text-white  fs-5 ">
              {cvdata?.profileInformation?.jobDesignation}
            </h6>
          </div>
        </div>

        <div className="d-flex pt-2">
          <div className="sideLine">
            <img src={images.SideLine} />
          </div>
          <div>
            <div className="profile-bio-scroll-5 noscroll">
              <div>
                <h6 className="cv-header-5 ">Previous Employement Details</h6>
                {cvdata?.preemphistory?.map((item) => (
                  <Row>
                    <Col md={5} lg={5} className="pe-0">
                      <div>
                        <p className="title-margin-5 mb-0 mb-0">Company Name</p>
                        <p className="desc-margin">{item?.companyname}</p>
                      </div>
                      <div>
                        <p className="title-margin-5 mb-0">Start Date</p>
                        <p className="desc-margin">{item?.startdate}</p>
                      </div>
                      <div>
                        <p className="title-margin-5 mb-0">Industry</p>
                        <p className="desc-margin">{item?.industry}</p>
                      </div>
                    </Col>
                    <Col md={7} lg={7} className="ps-0">
                      <div>
                        <p className="title-margin-5 mb-0">Designation</p>
                        <p className="desc-margin">{item?.designation}</p>
                      </div>
                      <div>
                        <p className="title-margin-5 mb-0">End Date</p>
                        <p className="desc-margin">{item?.enddate}</p>
                      </div>
                    </Col>
                  </Row>
                ))}

                <div>
                  <img src={images.HRLine} alt="" />
                </div>
              </div>

              <div>
                <h6 className="cv-header-5 ">Formal Education</h6>
                {cvdata?.formaleducation?.map((item) => (
                  <Row>
                    <Col md={5} lg={5}>
                      <div>
                        <p className="title-margin-5 mb-0">Institute Name</p>
                        <p className="desc-margin">{item?.institutename}</p>
                      </div>
                      <div>
                        <p className="title-margin-5 mb-0">Qualification</p>
                        <p className="desc-margin">{item?.qualification}</p>
                      </div>
                      <div>
                        <p className="title-margin-5 mb-0">Start Date</p>
                        <p className="desc-margin">{item?.startdate}</p>
                      </div>
                      <div>
                        <p className="title-margin-5 mb-0">Degree Status</p>
                        <p className="desc-margin">{item?.degreestatus}</p>
                      </div>
                    </Col>
                    <Col md={7} lg={7} className="ps-0">
                      <div>
                        <p className="title-margin-5 mb-0">Major</p>
                        <p className="desc-margin">{item?.major}</p>
                      </div>
                      <div>
                        <p className="title-margin-5 mb-0">Type of Study</p>
                        <p className="desc-margin">{item?.typeofstudy}</p>
                      </div>
                      <div>
                        <p className="title-margin-5 mb-0">End Date</p>
                        <p className="desc-margin">{item?.enddate}</p>
                      </div>
                    </Col>
                  </Row>
                ))}
              </div>
              <div>
                <h6 className="cv-header-5 ">Professional Skills</h6>

                <Row>
                  <Col md={5} lg={5} className="pe-0">
                  {
  cvdata?.profesionalskils?.map((item) => (
    <div>
      <p>{item}</p>
    </div>
  ))
} 
                  </Col>
                  {/* <Col md={7} lg={7} className="ps-0">
                    <div className="pb-4">
                      <ProgressBar
                        now={now1}
                        label={`${now1}%`}
                        variant="warning"
                      />
                    </div>
                    <div className="pb-3">
                      <ProgressBar
                        now={now2}
                        label={`${now2}%`}
                        variant="warning"
                      />
                    </div>
                    <div className="pb-3">
                      <ProgressBar
                        now={now3}
                        label={`${now3}%`}
                        variant="warning"
                      />
                    </div>
                    <div>
                      <ProgressBar
                        now={now4}
                        label={`${now4}%`}
                        variant="warning"
                      />
                    </div>
                  </Col> */}
                </Row>
              </div>
              <div>
                <h6 className="cv-header-5 ">Language Skills</h6>
                {cvdata?.languageskils?.map((item) => (
                  <Row>
                    <Col md={5} lg={5}>
                      <div>
                        <p className="title-margin-5 mb-0">Language</p>
                        <p className="desc-margin">{item?.language}</p>
                      </div>
                      <div>
                        <p className="title-margin-5 mb-0">
                          Reading proficiency
                        </p>
                        <p className="desc-margin">
                          {item?.readingproficiency}
                        </p>
                      </div>
                    </Col>
                    <Col md={7} lg={7} className="ps-0">
                      <div>
                        <p className="title-margin-5 mb-0">
                          Speaking Proficiency
                        </p>
                        <p className="desc-margin">
                          {item?.speakingproficiency}
                        </p>
                      </div>
                      <div>
                        <p className="title-margin-5 mb-0">
                          Writing Proficiency
                        </p>
                        <p className="desc-margin">
                          {item?.writingproficiency}
                        </p>
                      </div>
                    </Col>
                  </Row>
                ))}

                <div>
                  <img src={images.HRLine} alt="" />
                </div>
              </div>

              <div>
                <h6 className="cv-header-5 ">Certifications/Licences</h6>
                {cvdata?.certification?.map((item) => (
                  <Row>
                    <Col md={5} lg={5}>
                      <div>
                        <p className="title-margin-5 mb-0">
                          Name of Certifications
                        </p>
                        <p className="desc-margin">{item?.nameofcertificate}</p>
                      </div>
                      <div>
                        <p className="title-margin-5 mb-0">Issuing Authority</p>
                        <p className="desc-margin">{item?.issuingauthority}</p>
                      </div>
                      <div>
                        <p className="title-margin-5 mb-0">Expiring Date</p>
                        <p className="desc-margin">{item?.expirationdate}</p>
                      </div>
                    </Col>
                    <Col md={7} lg={7} className="ps-0">
                      <div>
                        <p className="title-margin-5 mb-0">Institution</p>
                        <p className="desc-margin">{item?.institution}</p>
                      </div>
                      <div>
                        <p className="title-margin-5 mb-0">Effective Date</p>
                        <p className="desc-margin">{item?.effectivedate}</p>
                      </div>
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default CVProfileBio;
