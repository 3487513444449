import { Row, Col } from "react-bootstrap";
import "./CVimage.css";
import { images } from "../../../../../constants/index";
import ProfileInformationCV from "../profileInformation5";
import CVProfileBio from "../CVProfileBio5";
const CVImage = ({ cvdata }) => {
  return (
    <Row>
      <Col md={9} lg={8} className="px-4">
        <div className=" cv-5-bio ">
          <Row>
            <Col md={4} lg={4} xl={4} className="ps-0 pe-0">
              <div className=" d-flex justify-content-center">
                <div>
                  <Col
                    md={12}
                    lg={12}
                    className=" pt-3 d-flex justify-content-center"
                  >
                    <div className="img-5-Background ">
                      <img src={cvdata?.profileInformation?.photo} alt="" className="img-5-Background " />
                    </div>
                  </Col>

                  <div className="profilescroll-5 noscroll ps-3">
                    <ProfileInformationCV cvdata={cvdata} />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={8} lg={8} xl={8} className="pe-0 ps-0">
              <CVProfileBio cvdata={cvdata} />
            </Col>
          </Row>
          <Col md={11} lg={11} className="mx-auto yellowdown-margin">
            <div className="yellowdown-back"></div>
          </Col>
        </div>
      </Col>
    </Row>
  );
};
export default CVImage;
