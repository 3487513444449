import React from 'react'
import { useNavigate } from 'react-router-dom'
import { images } from '../../../../../constants'
import ".././User_Searchbar/userChatprofile.css"
import UserChatsearchBar from '../User_Searchbar'
import Chatroombackarrow from '../../../../../components/Chatroom-backarrow'
const UserChatroomprofile = () => {
    const navigate=useNavigate()
    return (
        //main div
        <div className='main div'>
       
            {/* side_div */}
            <div  className="side_div ms-lg-2 me-lg-2 ps-lg-5 pe-lg-5 pb-1  mb-3">
            <Chatroombackarrow url="/chat-room-modal" />
            
                <div className='flex-Section'>
                        <div>
                        <img src={images.ProfileImg} alt="" className="profilepic"></img>
                    </div>
                    <div>
                        <h3 className='pt-1  text-white'>Bonny Lass</h3>
                        <p className='para-4  text-white'>Female </p>
                    </div>
                    <div>
                        <p className='para-3 pt-1 pb-1 ps-2 pe-2 text-white'>Lorem ipsum dolor sit amet, consectetur adi tempor incidila bore et dolore magna </p>
                    </div>
                    {/* <div className="ps-5 pe-5 pb-2 pt-2  ">
                        <button className="Buttons pt-1 pb-1 ps-4 pe-4 text-white  " onClick={()=>navigate("/update-chatroom-profile")}>Edit Profile</button>
                        
                    </div> */}
                </div>
            </div>
        
        <UserChatsearchBar/>
        </div>
    )
}

export default UserChatroomprofile;