import Form from "react-bootstrap/Form";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import { PreviousEmployement } from "../../../../constants/jsonConstant";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useSelector } from "react-redux";
import url from "../../../../config/axios";
import { FailedNotify, SuccessNotify } from "../../../../constants/tostify";
const PreviuosEmployementHistory = () => {
  const { name } = useSelector((state) => state.user);
  const [addemployement, setAddEmployement] = useState(PreviousEmployement);
  const [isloading, setIsloading] = useState(false);
  
  const [cvdata, setCvdata] = useState({
    user: name._id,
    
    preemphistory: [
      {
        companyname: "",
        designation: "",
        industry: "",
        startdate: "",
        enddate: "",
      },
    ],
  });
  const AddmoreFields = () => {
    setAddEmployement([...addemployement, addemployement]);
  };
  const handleRemoveClick = (index) => {
    const list = [...addemployement];
    list.splice(index, 1);

    setAddEmployement(list);
  };
  const handelvalues = (e) => {
    setCvdata((prevState) => ({
      ...prevState,
      preemphistory: {
        ...prevState.preemphistory,
        [e.target.name]: e.target.value,
      },
    }));
  };
  const PostCvData = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("cvform/postNew", cvdata)
      .then(async (response) => {
        if (response?.data?.message.length > 0) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify(" Successfully Save CV Data");
          }, 1000);
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed to Save CV Data");
      });
  };

  return (
    <div className="px-2">
      {addemployement?.map((addhistory, index) => (
        <>
          <Row>
            <Col md={4} lg={4}>
              <Form.Label className="custm-lble ">
                {addhistory?.companyname}
              </Form.Label>
              <Form.Control
                placeholder="Company Name"
                type="text"
                name="companyname"
                className="labelText "
                onChange={(e) => handelvalues(e)}
              />
            </Col>
            <Col md={4} lg={4}>
              <Form.Label className="custm-lble ">
                {addhistory?.designation}
              </Form.Label>
              <Form.Control
                placeholder="Designation"
                type="text"
                name="designation"
                className="labelText "
                onChange={(e) => handelvalues(e)}
              />
            </Col>
            <Col md={4} lg={4}>
              <Form.Label className="custm-lble ">
                {addhistory?.industry}
              </Form.Label>
              <Form.Control
                placeholder="Industry"
                type="text"
                name="industry"
                className="labelText "
                onChange={(e) => handelvalues(e)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} lg={4}>
              <Form.Label className="custm-lble ">
                {addhistory?.startdate}
              </Form.Label>
              <Form.Control
                placeholder="Start Date"
                type="text"
                name="startdate"
                className="labelText "
                onChange={(e) => handelvalues(e)}
              />
            </Col>
            <Col md={4} lg={4}>
              <Form.Label className="custm-lble ">
                {addhistory?.enddate}
              </Form.Label>
              <Form.Control
                placeholder="Last Date"
                type="text"
                name="enddate"
                className="labelText "
                onChange={(e) => handelvalues(e)}
              />
            </Col>
          </Row>
          {index === 0 ? (
            " "
          ) : (
            <div className="d-flex justify-content-end cursor_P">
              <div>
                <DeleteIcon className="color-red" />
              </div>
              <div
                className="d-flex align-items-center"
                onClick={() => handleRemoveClick()}
              >
                <p className="color-remove-red mb-0">Remove</p>
              </div>
            </div>
          )}

          <div class="line-width">
            <hr className="mb-2"></hr>
          </div>
          <div onClick={() => AddmoreFields()}>
            <p className="color-text cursor_P">
              <AddCircleIcon style={{ fontSize: "large" }} /> Add More
            </p>
          </div>
        </>
      ))}
      {cvdata?.preemphistory?.enddate?.length > 0 ? (
        <div className="d-flex justify-content-end pt-3 pb-4 ">
          <div>
            <button className="m-0 btn-color" onClick={PostCvData}>
              Save
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default PreviuosEmployementHistory;
