import React from "react";
import BoxData from "../Box";
import CardNav from "../CardNav";
import { Col, Container, Row, Card } from "react-bootstrap";
import { images } from "../../../../constants";
import { useState } from "react";

const SellProperties = ({ title }) => {
  const [id, setIs] = useState(1);
  const [myinfo, setMyinfo] = useState("business info")
  return (
    <div>
      <Row>
        <Col lg="12  mt-sm-3 mt-md-0">
          <CardNav Header={title} myid={id} myinfo={myinfo} />
          <Card className="shadow-main mt-2">
            <BoxData
              Title="Sell Properties"
              Butonn="Buy Now"
              imgg={images.SellProperties}
              link="/find-property"
              buy="fifteen"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SellProperties;
