import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import DashboardButtons from "../../../../components/Dashboardbtn/dashboardButtons";
import BusinessModes from "../../Component/BussinessMode";
import "./style.css";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Tour from "reactour";
import Steps from "reactour";
import { SellingSteps } from "../../../../constants/jsonConstant";
import { Modal, Button } from "react-bootstrap";

const SellingSide = () => {

  return (
    <>
      <div className="specificpage" style={{ overflow: "hidden" }}>
        <Helmet>
          <body
            style={`background: linear-gradient(119.72deg, #15751F 5.7%, #0D3C82 93.64%);`}
          ></body>
        </Helmet>
        <>

        </>
        <Container fluid className="col-xl-10 col-lg-10 col-md-10 col-12 pt-3">
          <div className="screenHeight">
            <div>

              <Row>
                <div className="pb-4">
                  <DashboardButtons
                    textone=""
                    texttwo="Switch To Buying Side"
                    linkurl="/BuyingSide"
                  />
                </div>

                <div className="noscroll dashboard-scroll p-2">
                  <Col lg="12" sm="12" className="mt-sm-3 mt-lg-0 mt-md-0">
                    <BusinessModes
                      btntext="Create Now"
                      btntexttwo="Post"
                      btntextthree="Post Add"
                      btntextfour="Post Service"
                      chatroom="Chatroom"
                      helpline="Helpline"
                      email="Email"
                    />
                  </Col>
                </div>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </>

  );
};

export default SellingSide;
