import React from "react";
import ReportSection from "../../components/Food_Repot/ReportSection";
import { useState,useEffect } from "react";
import url from "../../config/axios";
import { useParams } from "react-router-dom";
const TransportsuggestionSection = () => {
  const [suggestdata, setSuggestdata] = useState();
  const {id} =useParams();
  const suggestdatta = () => {
    url
      .get(`suggestion/getShopSuggestion/vendorid?vendorid=${id}`)
      .then(async (res) => {

        setSuggestdata(res?.data?.data[0]?.suggestions);
      });
  };

  useEffect(() => {
    suggestdatta();
  }, []);
  return(<>
<ReportSection title="Suggestions" suggestdata={suggestdata} />
  </>) 
};

export default TransportsuggestionSection;
