import "./livechat.css";
import "../../../src/pages/User_Chatroom/Create_Profile/ownchatroom.css";
import { Liveinviteroomapi } from "../../constants/jsonConstant";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import Chatroombackarrow from "../Chatroom-backarrow";

const Livechatinvite = ({ setAddMembers }) => {
  const [livechat, Livechat] = useState(Liveinviteroomapi);

  return (
    <>
      <div className="row d-flex bg_purple">
        <div className="col-3" onClick={() => setAddMembers(1)}>
          <Chatroombackarrow />
        </div>
        <div className="col-6 d-flex justify-content-center ps-0">
          <div className="col-7 mt-3">
            <div class="input-group">
              <span class="input-group-addon bg-white border-0">
                <SearchIcon />
              </span>
              <input type="text" class="form-control" placeholder="Search" />
            </div>
          </div>
        </div>
      </div>
      <div className="row scroll-chatroom noscroll bg_purple">
        {livechat.map((e) => (
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 mt-4">
            <div
              class="card invite-cards"
         
            >
              <div className="img--invite pt-1">
                <img class="invitechatimg" src={e.image} alt="Card image cap" />
              </div>
              <div class="card-body p-0 ">
                <h6 class="card-title text-white text-center mt-2">{e.name}</h6>
                <div className="d-flex justify-content-center">
                  <button type="button" className=" text-dark own-btn mb-2">
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Livechatinvite;
