import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { images } from "../../constants/index";
import Rating from "@mui/material/Rating";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SyncIcon from "@mui/icons-material/Sync";
import { useNavigate } from "react-router-dom";
import url from "../../config/axios";
import { useSelector } from "react-redux";
import ReportIcon from "@mui/icons-material/Report";
import AvailabilityModel from "../servicesProfile/components/Avalibilitymodel";
import CandidatesBox from "../../components/candidatesBox";
import BackArrow from "../../components/backArrow";
import MessageIcon from "@mui/icons-material/Message";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SettingsIcon from "@mui/icons-material/Settings";
import Button from "react-bootstrap/Button";
import { Restaurent } from "../../constants/jsonConstant";
import "./style.css";
import Updatefoodform from "../Updatefoodform";

const Foodrestaurant = ({ id, setShow }) => {
  const { name } = useSelector((state) => state.user);
  const [getService, setGetService] = useState();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [value] = useState(3);
  const [value0] = useState(5);
  const [key, setKey] = useState("home");
  useEffect(() => {
    url.get(`service/id/?id=${id}`).then(async (response) => {
      setGetService(response?.data?.data?.Service);
    });
  }, []);
  const [showavaliable, setShowavaliable] = useState(false);
  const handleClose = () => setShowavaliable(false);
  const handleShow = () => setShowavaliable(true);
  const handleSelect = (k) => {
    if (k === "tab1") {
      handleShow();
      setKey("profile");
    } else {
    }
  };
  const [showoneModal, setShowoneModal] = useState(false);
  const handleTabSelect = () => {
    setShowoneModal(true);
  };
  const handleCloses = () => {
    setShowoneModal(false);
  };
  const [Open,Showopen] = useState(false);
  const openModal = () => {
    Showopen(true);
    
  }
  // const closeModal = () => {
  //   Showopen(false);
  // }
  return (
    <div>
      <Container>
        <div className="screenHeight">
          <AvailabilityModel
            handleClose={handleClose}
            showavaliable={showavaliable}
            setShowavaliable={setShowavaliable}
          />
            {Open && (
          <Updatefoodform
          Showopen={Showopen}
          Open={Open}
          />
          )}
          
            <div className="border b-radius">
              <div>
                <BackArrow back="/create-service" />
              </div>
              {/* <CandidatesBox
                showoneModal={showoneModal}
                handleClose={handleCloses}
              /> */}
              <Row className="ps-4 pe-4">
                <Col lg="2" xl="4">
                  <div className="img_fix mt-2 ms-5">
                    <img src={images.Cafe} alt="" className="Restaurant_img" />
                  </div>
                </Col>
                <Col lg="10 " xl="7">
                  <h2 className="font-bold mt-4">Cafe Des Amis</h2>
                  <p className="mb-1 font-sm-15 spaces">
                    {getService?.subcategory}
                  </p>
                  <p className="mb-1  spaces ">
                   <span className="font-lg ">Shop ID :</span>1234567899876321
                  </p>
                  <p className="mb-1 spaces">
                   Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                   Vestibulum pulvinar lorem in nunc mattis,id facilisis justo
                   lobortis.Cras quis arcu a ligula pretium lobortis sed non ipsum.
                  </p>
                  <p className="mb-1 font-sm-15 spaces">
                    {name?.country},{name?.state},{name?.city}
                  </p>
                  <div className="d-flex ">
                    <p className="mb-1 font_small"> 
                      <Typography
                        component="legend"
                        className="font_small mt-1">
                        <b>3.6</b>
                      </Typography>
                    </p>
                    <Rating name="read-only" value={value} readOnly />
                  </div>
                  <div className="d-flex">
                    <ReportIcon className="report-icn mt-1" />
                    <p className="report-icn m-0">Report</p>
                  </div>
                  <div className="d-flex">
                    <MessageIcon className="report-icn mt-1" />
                    <p className="report-icn m-0">Suggestion</p>
                  </div>
                  <Row className="mt-2">
                    <Col lg="2">
                      <div
                        className="d-flex  Button-4 "
                        onClick={() => navigate("/UpdateShopForm")}
                      >
                        <SyncIcon className=" Button-4 mt-2 ms-1" />
                        <button className="btn-1"> Update</button>
                      </div>
                    </Col>
                    <Col lg="3">
                      <button className="btn-application-1">
                        Give Admin Rights
                      </button>
                    </Col>
                    <Col lg="4">
                      <div>
                        <Col className="ms-1">
                          <button
                            className="btn-application-1"
                            onClick={() => navigate("/Promotionmerge")}
                          >
                            Promote your service
                          </button>
                        </Col>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="d-flex btn-2 ">
                        <AddCircleIcon className="btn-3 mt-1 ms-1" />
                        <button
                          className="btn-application-2 ms-2 mt-1"
                          onClick={() => navigate("/Restaurantform")}>
                             Add More 
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg="2">
                      <div
                        className="d-flex  "
                        onClick={() => navigate("/OrderHistory")}
                      >
                        <button className="btn-application-3">
                          Order History
                        </button>
                      </div>
                    </Col>
                    <Col lg="3">
                      <button 
                       onClick={() => navigate("/homemade-items-shops")}
                      className="btn-application-1 ">
                        Business Dashboard
                      </button>
                    </Col>
                    <Col lg="4">
                      <div className="Btn-Height ">
                        <LocalShippingIcon className="btn-3 ms-2" />
                        <button
                            onClick={() => navigate("/DeliveryForm")}
                          className="btn-application-4 "
                        >
                          Set Delivery option
                        </button>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="d-flex Btn-Height  me-3">
                        <SettingsIcon className="btn-3 mt-2 ms-2" />
                        <button
                          className="btn-application-5 "
                          onClick={() => navigate("/shop-setting")}
                        >
                          Settings
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg="12 mt-2">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => {
                      setKey(k);
                      handleSelect(k);
                    }}
                    className="mb-3 ms-1 display-setting font_small"
                  >
                    <Tab eventKey="home" title="Service">
                      <Row className="services-scroll noscroll">
                        {Restaurent.map((box) => (
                          <Row>
                            <Col className="mt-2" lg="3">
                              <img className="" src={box.IMage} alt="" />
                            </Col>
                            <Col className="" lg="9">
                              <div className="d-flex justify-content-between">
                                <h4 className="ms-5 mt-4">{box.name}</h4>
                                <h4 className="mt-4">{box.Time}</h4>
                                <Button
                                  className="mt-4 BUTTON"
                                  variant="outline-primary" 
                                  onClick={openModal}
                                  >
                                    View
                                </Button>{" "}
                              </div>
                              <h5 className="ms-5 mt-2">{box.Discrip}</h5>
                              <p className="ms-5 mt-2 phera-3">{box.Phara}</p>
                              <div className="d-flex justify-content-end">
                                <h6>Rating</h6>
                                <Rating name="read-only" value={value0} readOnly />
                              </div>
                            </Col>

                            <hr />
                          </Row>
                        ))}
                      </Row>
                    </Tab>
                    <Tab eventKey="profile" title="Connects">

                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </div>
         
        </div>
      </Container>
    </div>
  );
};

export default Foodrestaurant;
