import Form from "../../../../components/forms/index"
import { useEffect, useState } from "react"
import url from "../../../../config/axios"
import { SuccessNotify, FailedNotify } from "../../../../constants/tostify"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import Loader from "../../../../components/Loader"
import { publishImage } from "../../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";

const NewItemsUpdate = () => {
  const navigate = useNavigate();
  const { name } = useSelector((state) => state.user);
  const [displayimg, setDisplayimg] = useState();
  const [isloading, setIsloading] = useState(false);
  const [shopCategoryone, setShopCategoryone] = useState();

  const [selectshop, setSelectshop] = useState({
    shopCategory: ""
  });

  const Shopdatas = (e) => {
    setSelectshop((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split("."),
    }));
  };
  const categoryName = selectshop?.shopCategory[1];
  const categoryid = selectshop?.shopCategory[0];

  localStorage.setItem('mycategory', categoryName);

  const { id } = useParams();
  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setCreateShop((createShop) => ({
        ...createShop,
        image: abc
      }));

      setDisplayimg(abc);

    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });
  const [createShop, setCreateShop] = useState({
    userid: name?._id,
    shopname: "",
    shopaddress: "",
    shoplicense: "",
    contact: "",
    image: [""],
    shopCategory: categoryid,
    marketPlace: "",
    businessType: "retailerShop",
    description: "",
    status: true,
  });

  useEffect(() => {
    url
      .get("category/type/?type=retailerShop&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategoryone(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
      });
  }, []);

  useEffect(() => {
    setIsloading(true);
    url
      .get(`shop/id?id=${id}`)
      .then(async (response) => {
        if (response.data.success) {
          setIsloading(false);
          setCreateShop(response?.data?.data?.Shop);
        }

      })
      .catch((error) => {
        console.log("error", error);
        setIsloading(false);
      });
  }, []);

  const UpdateShop = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .put(`shop/id?id=${id}`, createShop)
      .then(async (response) => {
        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Form Update Successfully");
            navigate("/NewItem-bussines-Profile/" + id);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed to Update");
      });
  };

  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }
  return (
    <Form
      title="Fill to Update New Items Shop"
      butn="Update"
      navigateTo="/NewItem-bussines-Profile"
      back="/NewItem-bussines-Profile/"
      UpdateShop={UpdateShop}
      shopCategoryone={shopCategoryone}
      createShop={createShop}
      setCreateShop={setCreateShop}
      link="/new-items-update/"
      id={id}
      getInputProps={getInputProps}
      getRootProps={getRootProps}
      displayimg={displayimg}
      setDisplayimg={setDisplayimg}
      Shopdatas={Shopdatas}
    />
  );
};
export default NewItemsUpdate;
