import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import url from "../../../../config/axios";
import Loader from "../../../../components/Loader";
const Frequentlyquestions = () => {
  const navigate = useNavigate();
  const [sellerfaq, setSellerfaq] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    url
      .get(`faq/?faqType=sellerFaq`)
      .then(async (res) => {
        if (res.data.sucess) {
          setLoading(false);
          setSellerfaq(res.data.data.faq);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);
  return (
    <div>
      <Row className="ms-5">
        <p className="phera-faq ms-3 mt-4 mb-0">
          FAQ(Frequently asked questions)
        </p>
        <div className="ms-2">
        <div className="iput-search mt-2">
          <SearchIcon />
          <input
            placeholder="What do you need help with"
            className="input-ipt no-outline border-0 ps-4"
          />
        </div>
        </div>
       
        <Row className="pe-5">
          {
            loading===true ? (<Loader heightsetting="screenHeight" />) : (   sellerfaq?.map((item) => (
            <Col lg="6 pe-0">
              <Accordion defaultActiveKey="0">
                <Accordion.Item className=" mt-4" eventKey={item.eventKey}>
                  <Accordion.Header className="phera-faq">
                    {item?.topic}
                  </Accordion.Header>
                  <Accordion.Body>{item?.question}</Accordion.Body>
                  <Button
                    className="ms-3 mb-2"
                    variant="primary"
                    onClick={() => navigate("/Refund-Policy/"+item._id)}
                  >
                    Learn Now
                  </Button>
                </Accordion.Item>
              </Accordion>
            </Col>
          )))
            
            
         }
        </Row>
      </Row>
    </div>
  );
};

export default Frequentlyquestions;
