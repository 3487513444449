import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { images } from "../../constants";
import "./orderhistory.css";
import { OrderHistoryRecord } from "../../constants/jsonConstant";
import { PaginationControl } from "react-bootstrap-pagination-control";
import BackArrow from "../backArrow";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useLocation } from "react-router-dom";
// import Addtocartmodal from "../Addtocartmodal";
import ShoppingCart from "../ShoppingCart_Modal/ShoppingCart";

const OrderHistory = () => {
  const location = useLocation();
  const [page, setPage] = useState(1);
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    setShowModal(1);
  };

  const handleShowModaltwo = () => {
    setShowModal(2);
  };

  const handleShowModalthree = () => {
    setShowModal(3);
  };

  const [showModal, setShowModal] = useState(0);

  // const [showModal1, setShowModal1] = useState(false);
  // const [showModal2, setShowModal2] = useState(false);
  // const [isOpenone, SetisOpenone] = useState(false);
  const [buttondata, setButtondata] = useState(1);
  const handleSelect = (k) => {
    if (k === "a") {
      setButtondata(1);
    } else if (k === "b") {
      setButtondata(2);
    } else if (k === "c") {
      setButtondata(3);
    } else {
    }
  };
  return (
    <>
      <Row>
        {(buttondata === 1 && (
          <>
            <ShoppingCart
              handleCloseModal={handleCloseModal}
              handleShowModal={handleShowModal}
              showModal={showModal}
              delevered="Pending"
              pending="Pending"
              outgoing="Outgoing"
              color1="#003559"
              color2="#FAAF3A"
              color3="#003559"
            />
          </>
        )) ||
          (buttondata === 2 && (
            <>
              <ShoppingCart
                handleCloseModal={handleCloseModal}
                handleShowModaltwo={handleShowModaltwo}
                showModal={showModal}
                delevered="Delevered"
                pending="Pending"
                outgoing="Outgoing"
                color1="#40AE6C"
                color2="#FAAF3A"
                color3="#003559"
              />
            </>
          )) ||
          (buttondata === 3 && (
            <>
              <ShoppingCart
                handleCloseModal={handleCloseModal}
                handleShowModalthree={handleShowModalthree}
                showModal={showModal}
                delevered="Delevered"
                outgoing="Send"
                pending=""
                color1="#40AE6C"
                color2="#FAAF3A"
                color3=""
              />
            </>
          ))}
        <Col>
          <Container>
            {location.pathname === "/user-dashboard" ? (
              <div className="">
                <div className=" col-12  p-4 mx-auto ">
                  <div className="col-12">
                    <Tabs
                      onSelect={(k) => handleSelect(k)}
                      className="groundset"
                    >
                      <Tab
                        eventKey="a"
                        title="New Shopping Cart"
                        onSelect={handleSelect}
                      >
                        <Row className="col-12  pt-3 pb-1 ps-3 ">
                          <Col lg={3} className="p-0">
                            <p className="mb-0 fw-bold font-sm-15 ">
                              Tracking ID
                            </p>
                          </Col>
                          <Col lg={3} className="p-0">
                            <p className="mb-0 fw-bold font-sm-15 ">
                              Order Name
                            </p>
                          </Col>
                          <Col lg={3} className="p-0">
                            <p className="mb-0 ms-4 fw-bold font-sm-15 ">
                              Date
                            </p>
                          </Col>
                          <Col lg={2} className="p-0">
                            <p className="mb-0  fw-bold font-sm-15 ps-lg-3 ps-xl-4">Status </p>
                          </Col>
                          <Col lg={1} className="p-0">
                            <p className="mb-0 fw-bold font-sm-15 ">Details</p>
                          </Col>
                        </Row>

                        <div className="Order-Shadow noscroll">
                          {OrderHistoryRecord?.map((Items) => (
                            <div key={Items?.id}>
                              <Container>
                                <Row className=" border-top border-bottom pt-3 pb-3">
                                  <Col lg={3}>
                                    <p className="mb-0 font-sm-15 ">
                                      {Items?.trackingId}
                                    </p>
                                  </Col>
                                  <Col lg={3}>
                                    <p className="mb-0 font-sm-15 ">
                                      {Items?.Name}
                                    </p>
                                  </Col>
                                  <Col lg={3} className="p-0">
                                    <p className="mb-0 font-sm-15 ">
                                      {Items?.Date}
                                    </p>
                                  </Col>
                                  <Col lg={2} className="p-0">
                                    {/* {Items?.id === 4 || Items?.id === 5 ? (
                                    <p className="mb-0 font-sm-15  btn-statusone  ">
                                      delivered
                                    </p>
                                  ) : ( */}
                                    <p className="mb-0 font-sm-15 btn-status">
                                      pending   
                                    </p>
                                    {/* )} */}
                                  </Col>
                                  <Col lg={1} onClick={handleShowModal}>
                                    <img
                                      className="w-img pointer_img"
                                      src={Items?.Show}
                                    />
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          ))}
                        </div>

                        <div className="mt-2">
                          <PaginationControl
                            page={page}
                            between={4}
                            total={250}
                            limit={30}
                            changePage={(page) => {
                              setPage(page);
                            }}
                            ellipsis={1}
                          />
                        </div>
                      </Tab>
                      <Tab
                        eventKey="b"
                        title="Ongoing Orders"
                        onSelect={handleSelect}
                      >
                        <Row className="col-12  pt-3 pb-1 ps-3 ">
                          <Col lg={3} className="p-0">
                            <p className="mb-0 fw-bold font-sm-15 ">
                              Tracking ID
                            </p>
                          </Col>
                          <Col lg={3} className="p-0">
                            <p className="mb-0 fw-bold font-sm-15 ">
                              Order Name
                            </p>
                          </Col>
                          <Col lg={3} className="p-0">
                            <p className="mb-0 ms-4 fw-bold font-sm-15 ">
                              Date
                            </p>
                          </Col>
                          <Col lg={2} className="p-0">
                            <p className="mb-0  fw-bold font-sm-15 ps-lg-3 ps-xl-4">Status </p>
                          </Col>
                          <Col lg={1} className="p-0">
                            <p className="mb-0 fw-bold font-sm-15 ">Details</p>
                          </Col>
                        </Row>

                        <div className="Order-Shadow noscroll">
                          {OrderHistoryRecord?.map((Items) => (
                            <div key={Items?.id}>
                              <Container>
                                <Row className=" border-top border-bottom pt-3 pb-3">
                                  <Col lg={3}>
                                    <p className="mb-0 font-sm-15 ">
                                      {Items?.trackingId}
                                    </p>
                                  </Col>
                                  <Col lg={3}>
                                    <p className="mb-0 font-sm-15 ">
                                      {Items?.Name}
                                    </p>
                                  </Col>
                                  <Col lg={3} className="p-0">
                                    <p className="mb-0 font-sm-15 ">
                                      {Items?.Date}
                                    </p>
                                  </Col>
                                  <Col lg={2} className="p-0">
                                    {/* {Items?.id === 4 || Items?.id === 5 ? (
                                    <p className="mb-0 font-sm-15  btn-statusone  ">
                                      delivered
                                    </p>
                                  ) : ( */}
                                    <p className="mb-0 font-sm-15 btn-statustwo">
                                      Ongoing
                                    </p>
                                    {/* )} */}
                                  </Col>
                                  <Col lg={1} onClick={handleShowModal}>
                                    <img
                                      className="w-img pointer_img"
                                      src={Items?.Show}
                                    />
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          ))}
                        </div>

                        <div className="mt-2">
                          <PaginationControl
                            page={page}
                            between={4}
                            total={250}
                            limit={30}
                            changePage={(page) => {
                              setPage(page);
                            }}
                            ellipsis={1}
                          />
                        </div>

                      </Tab>
                      <Tab
                        eventKey="c"
                        title="Delivered Orders"
                        onSelect={handleSelect}
                      >
                        <Row className="col-12  pt-3 pb-1 ps-3 ">
                          <Col lg={3} className="p-0">
                            <p className="mb-0 fw-bold font-sm-15 ">
                              Tracking ID 
                            </p>
                          </Col>
                          <Col lg={3} className="p-0">
                            <p className="mb-0 fw-bold font-sm-15 ">
                              Order Name
                            </p>
                          </Col>
                          <Col lg={3} className="p-0">
                            <p className="mb-0 ms-4 fw-bold font-sm-15 ">
                              Date
                            </p>
                          </Col>
                          <Col lg={2} className="p-0">
                            <p className="mb-0  fw-bold font-sm-15 ps-lg-3 ps-xl-4">Status </p>
                          </Col>
                          <Col lg={1} className="p-0">
                            <p className="mb-0 fw-bold font-sm-15 ">Details</p>
                          </Col>
                        </Row>

                        <div className="Order-Shadow noscroll">
                          {OrderHistoryRecord?.map((Items) => (
                            <div key={Items?.id}>
                              <Container>
                                <Row className=" border-top border-bottom pt-3 pb-3">
                                  <Col lg={3}>
                                    <p className="mb-0 font-sm-15 ">
                                      {Items?.trackingId}
                                    </p>
                                  </Col>
                                  <Col lg={3}>
                                    <p className="mb-0 font-sm-15">
                                      {Items?.Name}
                                    </p>
                                  </Col>
                                  <Col lg={3} className="p-0">
                                    <p className="mb-0 font-sm-15 ">
                                      {Items?.Date}
                                    </p>
                                  </Col>
                                  <Col lg={2} className="p-0">
                                    {/* {Items?.id === 4 || Items?.id === 5 ? (
                                    <p className="mb-0 font-sm-15  btn-statusone  ">
                                      delivered
                                    </p>
                                  ) : ( */}
                                    <p className="mb-0 font-sm-15 btn-statusone ">
                                      delevired
                                    </p>
                                    {/* )} */}
                                  </Col>
                                  <Col lg={1} onClick={handleShowModal}>
                                    <img
                                      className="w-img pointer_img"
                                      src={Items?.Show}
                                    />
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          ))}
                        </div>

                        <div className="mt-2">
                          <PaginationControl
                            page={page}
                            between={4}
                            total={250}
                            limit={30}
                            changePage={(page) => {
                              setPage(page);
                            }}
                            ellipsis={1}
                          />
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            ) : (
              <div className="screenHeight ">
                <div className=" col-9 Form-Shadow p-4 mx-auto ">
                  <div className="col-12 d-flex">
                    <BackArrow
                      back="/RestaurantAlldata"
                      mystyle="m-lg-0 p-lg-2 mb-lg-4"
                    />
                  </div>
                  <div className="col-12">
                    <Tabs
                      onSelect={(k) => handleSelect(k)}
                      className="groundset"
                    >
                      <Tab
                        eventKey="a"
                        title="New Shopping Cart"
                        onSelect={handleSelect}
                      >
                        <Row className="col-12  pt-3 pb-1 ps-3 ">
                          <Col lg={3} className="p-0">
                            <p className="mb-0 fw-bold font-sm-15 ">
                              Tracking ID
                            </p>
                          </Col>
                          <Col lg={3} className="p-0">
                            <p className="mb-0 fw-bold font-sm-15 ">
                              Order Name
                            </p>
                          </Col>
                          <Col lg={3} className="p-0">
                            <p className="mb-0 ms-4 fw-bold font-sm-15 ">
                              Date
                            </p>
                          </Col>
                          <Col lg={2} className="p-0">
                            <p className="mb-0  fw-bold font-sm-15 ps-lg-3 ps-xl-4">Status </p>
                          </Col>
                          <Col lg={1} className="p-0">
                            <p className="mb-0 fw-bold font-sm-15 ">Details</p>
                          </Col>
                        </Row>

                        <div className="Order-Shadow noscroll">
                          {OrderHistoryRecord?.map((Items) => (
                            <div key={Items?.id}>
                              <Container>
                                <Row className=" border-top border-bottom pt-3 pb-3">
                                  <Col lg={3}>
                                    <p className="mb-0 font-sm-15 ">
                                      {Items?.trackingId}
                                    </p>
                                  </Col>
                                  <Col lg={3}>
                                    <p className="mb-0 font-sm-15 ">
                                      {Items?.Name}
                                    </p>
                                  </Col>
                                  <Col lg={3} className="p-0">
                                    <p className="mb-0 font-sm-15 ">
                                      {Items?.Date}
                                    </p>
                                  </Col>
                                  <Col lg={2} className="p-0">
                                    {/* {Items?.id === 4 || Items?.id === 5 ? (
                                    <p className="mb-0 font-sm-15  btn-statusone  ">
                                      delivered
                                    </p>
                                  ) : ( */}
                                    <p className="mb-0 font-sm-15 btn-status ">
                                      pending
                                    </p>
                                    {/* )} */}
                                  </Col>
                                  <Col lg={1} onClick={handleShowModal}>
                                    <img
                                      className="w-img pointer_img"
                                      src={Items?.Show}
                                    />
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          ))}
                        </div>

                        <div className="mt-2">
                          <PaginationControl
                            page={page}
                            between={4}
                            total={250}
                            limit={30}
                            changePage={(page) => {
                              setPage(page);
                            }}
                            ellipsis={1}
                          />
                        </div>
                      </Tab>
                      <Tab
                        eventKey="b"
                        title="Ongoing Orders"
                        onSelect={handleSelect}
                      >
                        <Row className="col-12  pt-3 pb-1 ps-3 ">
                          <Col lg={3} className="p-0">
                            <p className="mb-0 fw-bold font-sm-15 ">
                              Tracking ID
                            </p>
                          </Col>
                          <Col lg={3} className="p-0">
                            <p className="mb-0 fw-bold font-sm-15 ">
                              Order Name
                            </p>
                          </Col>
                          <Col lg={3} className="p-0">
                            <p className="mb-0 ms-4 fw-bold font-sm-15 ">
                              Date
                            </p>
                          </Col>
                          <Col lg={2} className="p-0">
                            <p className="mb-0  fw-bold font-sm-15 ps-lg-3 ps-xl-4">Status </p>
                          </Col>
                          <Col lg={1} className="p-0">
                            <p className="mb-0 fw-bold font-sm-15 ">Details</p>
                          </Col>
                        </Row>

                        <div className="Order-Shadow noscroll">
                          {OrderHistoryRecord?.map((Items) => (
                            <div key={Items?.id}>
                              <Container>
                                <Row className=" border-top border-bottom pt-3 pb-3">
                                  <Col lg={3}>
                                    <p className="mb-0 font-sm-15 ">
                                      {Items?.trackingId}
                                    </p>
                                  </Col>
                                  <Col lg={3}>
                                    <p className="mb-0 font-sm-15 ">
                                      {Items?.Name}
                                    </p>
                                  </Col>
                                  <Col lg={3} className="p-0">
                                    <p className="mb-0 font-sm-15 ">
                                      {Items?.Date}
                                    </p>
                                  </Col>
                                  <Col lg={2} className="p-0">
                                    {/* {Items?.id === 4 || Items?.id === 5 ? (
                                    <p className="mb-0 font-sm-15  btn-statusone  ">
                                      delivered
                                    </p>
                                  ) : ( */}
                                    <p className="mb-0 font-sm-15 btn-statustwo">
                                      Ongoing
                                    </p>
                                    {/* )} */}
                                  </Col>
                                  <Col lg={1} onClick={handleShowModal}>
                                    <img
                                      className="w-img pointer_img"
                                      src={Items?.Show}
                                    />
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          ))}
                        </div>

                        <div className="mt-2">
                          <PaginationControl
                            page={page}
                            between={4}
                            total={250}
                            limit={30}
                            changePage={(page) => {
                              setPage(page);
                            }}
                            ellipsis={1}
                          />
                        </div>
                      </Tab>
                      <Tab
                        eventKey="c"
                        title="Delivered Orders"
                        onSelect={handleSelect}
                      >
                        <Row className="col-12  pt-3 pb-1 ps-3 ">
                          <Col lg={3} className="p-0">
                            <p className="mb-0 fw-bold font-sm-15 ">
                              Tracking ID
                            </p>
                          </Col>
                          <Col lg={3} className="p-0">
                            <p className="mb-0 fw-bold font-sm-15 ">
                              Order Name
                            </p>
                          </Col>
                          <Col lg={3} className="p-0">
                            <p className="mb-0 ms-4 fw-bold font-sm-15 ">
                              Date
                            </p>
                          </Col>
                          <Col lg={2} className="p-0">
                            <p className="mb-0  fw-bold font-sm-15 ps-lg-3 ps-xl-4">Status </p>
                          </Col>
                          <Col lg={1} className="p-0">
                            <p className="mb-0 fw-bold font-sm-15 ">Details</p>
                          </Col>
                        </Row>

                        <div className="Order-Shadow noscroll">
                          {OrderHistoryRecord?.map((Items) => (
                            <div key={Items?.id}>
                              <Container>
                                <Row className=" border-top border-bottom pt-3 pb-3">
                                  <Col lg={3}>
                                    <p className="mb-0 font-sm-15 ">
                                      {Items?.trackingId}
                                    </p>
                                  </Col>
                                  <Col lg={3}>
                                    <p className="mb-0 font-sm-15 ">
                                      {Items?.Name}
                                    </p>
                                  </Col>
                                  <Col lg={3} className="p-0">
                                    <p className="mb-0 font-sm-15 ">
                                      {Items?.Date}
                                    </p>
                                  </Col>
                                  <Col lg={2} className="p-0">
                                    {/* {Items?.id === 4 || Items?.id === 5 ? (
                                    <p className="mb-0 font-sm-15  btn-statusone  ">
                                      delivered
                                    </p>
                                  ) : ( */}
                                    <p className="mb-0 font-sm-15 btn-statusone ">
                                      delevired
                                    </p>
                                    {/* )} */}
                                  </Col>
                                  <Col lg={1} onClick={handleShowModal}>
                                    <img
                                      className="w-img pointer_img"
                                      src={Items?.Show}
                                    />
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          ))}
                        </div>

                        <div className="mt-2">
                          <PaginationControl
                            page={page}
                            between={4}
                            total={250}
                            limit={30}
                            changePage={(page) => {
                              setPage(page);
                            }}
                            ellipsis={1}
                          />
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            )}
          </Container>
        </Col>
      </Row>
    </>
  );
};

export default OrderHistory;
