import url from "../config/axios"

export const publishImage = async (data) => {
    const formData = new FormData();
    formData.append('imagefront', data);
    data?.forEach((img) => {
        formData.append('images', img);
    });

    try {

        const res = await url.post('upload/multiple', formData);
        let objectNames = [];
        let resp = res.data.file;

        for (let i = 0; i < resp.length; i++) {
            objectNames.push('/images/' + resp[i].filename);
        }

        if (res.data.message === 'imagesSaved') {


            return objectNames;
        } else {
            alert('Error: images not saved');
            return [];
        }
    } catch (err) {
        console.log('Error:', err);
        alert('Catch error');
        return [];
    }
};