import { Post, Viewpost } from "../../../../pages/index";

const Timelineflow = ({ getTimelinePosts,id, rightSide,getmyTimeline, mysetting,timelineid, data,CreatePostSubmit,setCreatePost,createPost,show, setShow,setGetmyTimeline }) => {
 
  return (
    <>
      <div className="d-flex align-items-center flex-column">
        {rightSide === 11 ? (
          <Viewpost id={id} rightSide={rightSide} setGetmyTimeline={setGetmyTimeline}  mysetting={mysetting}  getTimelinePosts={getTimelinePosts} timelineid={timelineid} data={data} createPost={createPost} getmyTimeline={getmyTimeline}/>
        ) : (
          <>
            <Post id={id} mysetting={mysetting} data={data} CreatePostSubmit={CreatePostSubmit} setCreatePost={setCreatePost} createPost={createPost} show={show} setShow={setShow}  />
            <Viewpost id={id} mysetting={mysetting} setGetmyTimeline={setGetmyTimeline} getTimelinePosts={getTimelinePosts} timelineid={timelineid} data={data} createPost={createPost} getmyTimeline={getmyTimeline}/>
          </>
        )}
      </div>
    </>
  );
};

export default Timelineflow;
