import React from "react";
import ReportSection from "../../components/Food_Repot/ReportSection";
const ServiceReportSection = () => {
  return (
    <>
      <ReportSection title="Reports" />
    </>
  );
};

export default ServiceReportSection;
