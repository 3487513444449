import { Col, Row } from "react-bootstrap";
import { images } from "../../../../constants";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useLocation } from "react-router-dom";
import HistoryIcon from "@mui/icons-material/History";
import FoundationIcon from "@mui/icons-material/Foundation";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import {
  Businesstext,
  Menu,
  Category,
  BusinessCategory,
} from "../../../../constants/jsonConstant";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./leftmenu.css";
import { getAsset } from "../../../../helpers/helper";
import { useSelector } from "react-redux";


const Leftmenubar = ({ setUserdash, userimg }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { name } = useSelector((state) => state.user);
  return (
    <>
      <Col
        lg="12"
        md="12"
        className={
          location.pathname === "/user-dashboard"
            ? "bg-blue-lg screenHeight leftcomheighttt"
            : "bg-blue-lg screenHeight h-100"
        }
      >
        <div className="">
          <Col lg="10" className=" b-radius mx-auto pt-3 pb-3">
            <div className="profileImgbusiness d-flex justify-content-center">
              <img src={getAsset(userimg)} crossOrigin="anonymous" />
            </div>

            <div class="Name pt-4 pb-4 card-title text-center text-white">
              <h5 className="m-0">{name?.fullname}</h5>
            </div>
          </Col>
        </div>
        {location.pathname === "/user-dashboard" ? (
          <>
            <hr
              style={{
                color: "white",
                width: "2px solid white",
                opacity: "1.25",
                border: "1px solid",
              }}
            />
            <div className="ps-2">
              <Col
                md={11}
                lg={11}
                sm={11}
                xs={12}

                className="d-flex ps-0 justify-content-center  justify-content-sm-center justify-content-md-start cursor_P"
              >
                <div className="mt-2 mb-0 ">
                  <HistoryIcon className="hstryicons" style={{ color: "white" }} />
                </div>

                <ul style={{ listStyle: "none" }} className="ps-2 mb-2">
                  <li
                    className="text-white  setfonts font-lg mt-2 "
                    onClick={() => setUserdash(1)}
                  >
                    Activity History
                  </li>
                </ul>
              </Col>
              <Col
                md={11}
                lg={11}
                sm={11}
                xs={12}
                className="d-flex pe-0  justify-content-center  justify-content-sm-center justify-content-md-start cursor_P"
              >
                <div className="mt-2 mb-0">
                  {/* <FoundationIcon style={{ color: "white" }} /> */}
                  <img src={images?.Delivery} />
                </div>

                <ul style={{ listStyle: "none" }} className="ps-2 mb-2">
                  <li
                    className="text-white setfonts font-lg mt-2"
                    onClick={() => setUserdash(2)}
                  >
                    Order History
                  </li>
                </ul>
              </Col>
              <Col
                md={11}
                lg={12}
                sm={11}
                xs={12}
                xl={12}
                className="d-flex pe-0  justify-content-center  justify-content-sm-center justify-content-md-start  cursor_P"
              >
                <div className="mt-2 mb-0">
                  <SpeakerNotesIcon style={{ color: "white" }} />
                </div>

                <ul style={{ listStyle: "none" }} className="ps-2 mb-2">
                  <li
                    className="text-white setfonts font-lg mt-2"
                    onClick={() => setUserdash(3)}
                  >
                    Suggestions replies
                  </li>
                </ul>
              </Col>
            </div>
          </>
        ) : (
          <div className="mx-auto col-10">
            <Col lg="12" className="ps-0 pe-0  mb-3 businessItemsScroll">
              {Menu.map((e) => (
                <>
                  <div>
                    <div id="dropdown-basic" className="w-100 p-0">
                      <Row>
                        <Col
                          md="12"
                          lg="12"
                          sm="12"
                          xs="12"
                          className="d-flex pe-0"
                        >
                          <div className="mt-2 mb-0">
                            <img src={e.icon} className="menuimg-width" />
                          </div>

                          <ul
                            style={{ listStyle: "none" }}
                            className="ps-2 mb-2"
                          >
                            <li
                              className="leftmenu_sett text-white font-lg mt-2"
                              onClick={() => navigate(e.to)}
                            >
                              {e.text}
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </>
              ))}
            </Col>
          </div>
        )}
      </Col>
    </>
  );
};
export default Leftmenubar;
