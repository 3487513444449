import Singleaddshow from "../../components/Singleitem_show";
import { useEffect, useState } from "react";
import url from "../../config/axios";
import Loader from "../../components/Loader";
const Singleitemview = () => {
  const [singleitemadd, setSingleitemadd] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    url
      .get("shopitem/single?isSingleItem=true")
      .then(async (res) => {
        if (res.data.success) {
          setLoading(false);
          setSingleitemadd(res.data.data.ShopItems);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  console.log("______", singleitemadd)
  return (
    <>
      <Singleaddshow
        postlink="/post-single-ad"
        singleitemadd={singleitemadd}
        navurl="/singleitem-show/"
        loading={loading}
      />
    </>
  );
};

export default Singleitemview;
