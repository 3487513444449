import { Row, Col } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import BackArrow from "../../backArrow";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PersonalImage from "../../../helpers/fileUpload";
import { Category } from "../../../constants/jsonConstant";
import url from "../../../config/axios";
import { SuccessNotify, FailedNotify } from "../../../constants/tostify";
import { useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import { images } from "../../../constants";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Card from "react-bootstrap/Card";
import Loader from "../../Loader";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import { publishImage } from "../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";

const ServiceForm = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const { name } = useSelector((state) => state.user);

  const [shopCategory, setShopCategory] = useState();
  const [shopCategorytwo, setShopCategorytwo] = useState();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedstateone, setSelectedstateone] = useState("");
  const [searchTermone, setSearchTermone] = useState("");

  const [selectedcity, setSelectedcity] = useState("");
  const [searchTermtwo, setSearchTermtwo] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle the country selection
  const handleCountryChange = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
  };

  const handleSearchChangestate = (event) => {
    setSearchTermone(event.target.value);
  };

  const handleSearchChangecity = (event) => {
    setSearchTermtwo(event.target.value);
  };

  // Function to handle the state selection
  const handleCountryChangestate = (event) => {
    const { value } = event.target;
    setSelectedstateone(value);
  };

  // Function to handle the city selection
  const handleCountryChangecity = (event) => {
    const { value } = event.target;
    setSelectedcity(value);
  };
  const [hidefield, setHidefield] = useState(1);
  const [hidestate, setHidestate] = useState(1);
  const [hidecity, setHidecity] = useState(1);
  const [countrydata, SetCountrydata] = useState([]);
  const [getcountry] = useState();
  const [selectedState] = useState();
  const [getState, setState] = useState([]);
  const [getcity, setGetcity] = useState([]);
  const [statetwo, setStatetwo] = useState(getcountry);
  const [city, setCity] = useState(selectedState);
  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => SetCountrydata(res.data))

      .catch((error) => { });
  }, []);

  const country = [...new Set(countrydata.map((item) => item.country))];

  country.sort();
  const handleCountry = (e) => {
    let states = countrydata.filter(
      (state) => state.country === e.target.value
    );
    states = [...new Set(states.map((item) => item.subcountry))];
    states.sort();
    setState(states);
  };

  const handleState = (e) => {
    let cities = countrydata.filter(
      (city) => city.subcountry === e.target.value
    );
    setGetcity(cities);
  };

  const [data, setData] = useState({
    category: "",
    subCategory: "",
  });

  const Handledata = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split("."),
    }));
  };
  const categoryName = data?.category[1];
  const categoryid = data.category[0];
  const subcategoryName = data?.subCategory[1];
  const subcategoryid = data.subCategory[0];
  localStorage.setItem('mycategory', categoryName);

  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setCreateService((createService) => ({
        ...createService,
        images: abc,
      }));
    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  };

  const onDropport = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setCreateService((createService) => ({
        ...createService,
        portfolioImages: abc,
      }));
    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  };

  const onDropvideo = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setCreateService((createService) => ({
        ...createService,
        videos: abc,
      }));
    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  };

  const { getRootProps: getRootPropsImage, getInputProps: getInputPropsImage } =
    useDropzone({
      accept: "image/*",
      onDrop,
    });

  const { getRootProps: getRootPropsPort, getInputProps: getInputPropsPort } =
    useDropzone({
      accept: "image/*",
      onDrop: onDropport,
    });

  const { getRootProps: getRootPropsVideo, getInputProps: getInputPropsVideo } =
    useDropzone({
      accept: "video/*",
      onDrop: onDropvideo,
    });

  const [isloading, setIsloading] = useState(false);
  const [createService, setCreateService] = useState({
    userid: name?._id,
    profileName: "",
    category: "",
    categoryid: "",
    subCategory: "",
    subCategoryid: "",
    country: "",
    state: "",
    city: "",
    cnic: "",
    address: "",
    videoTitle: "",
    imageTitle: "",
    videoDescription: "",
    imageDescription: "",
    videos: [],
    images: [""],
    portfolioimages: [""],
    status: true,
  });

  useEffect(() => {
    setCreateService((createService) => ({
      ...createService,
      category: categoryName,
      categoryid: categoryid,
      subCategory: subcategoryName,
      subCategoryid: subcategoryid,
    }));
  }, [categoryid, categoryName, subcategoryName, subcategoryid]);

  useEffect(() => {
    url.get(`service/id/?id=${id}`).then(async (response) => {
      setCreateService(response?.data?.data?.Service);
    });
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    url
      .get("category/type/?type=services&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => { });
  }, []);

  const Mydata = () => {
    if (data?.category) {
      url
        .get(`/category/type/?type=services&parentCategory=${categoryName}`)
        .then(async (response) => {
          if (response?.data?.success) {
            setShopCategorytwo(response?.data?.data?.Category);
          }
        })
        .catch((error) => { });
    }
  };

  // const onChangePicture = (e) => {
  //   if (e.target.files[0]) {
  //     console.log("mtfile", e.target.files[0]);
  //     setSelectedImage(e.target.files[0]);
  //     // PersonalImage(e.target.files[0]);
  //     const reader = new FileReader();
  //     reader.readAsDataURL(e.target.files[0]);
  //   }
  // };

  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");

  // const removeImage = (index) => {
  //   const updatedImages = [...uploadedImages];
  //   updatedImages.splice(index, 1);
  //   setUploadedImages(updatedImages);
  // };

  const handleValue = (event) => {
    setCreateService((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    url.get(`service/id/?id=${id}`).then(async (response) => {
      setCreateService(response?.data?.data?.Service);
    });
  }, []);

  const formSericeSubmit = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("service/", createService)
      .then(async (response) => {
        if (response?.data?.success) {
          setIsloading(false);

          SuccessNotify("Service-Form Created SuccesFully...");
          navigate(`/service-business-profile/${response?.data?.data?._id}`);
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed to Create Form");
      });
  };

  const servcieFormupdate = (e) => {
    e.preventDefault();
    url
      .put(`service/id/?id=${id}`, createService)
      .then(async (response) => {
        if (response?.data?.success) {
          setTimeout(() => {
            SuccessNotify("Update Service SuccessFully");
            navigate(`/services-profile/${id}`);
          }, 3000);
        }
      })
      .catch((error) => {
        FailedNotify("Failed to Update Form");
      });
  };
  if (isloading) {
    return (
      <div>
        <Loader heightsetting="screenHeight" />
      </div>
    );
  }

  const validationSchema = Yup.object({
    category: Yup.string().required("category is required*"),
    subCategory: Yup.string().required("subcategory is required*"),
    profileName: Yup.string().required("Service name is required*"),
    country: Yup.string().required("country is required*"),
    state: Yup.string().required("state is required*"),
    city: Yup.string().required("city is required*"),
    cnic: Yup.string().required("cnic is required*"),
    address: Yup.string().required("address is required*"),
  });

  return (
    <>
      {selectedImage && <PersonalImage image={selectedImage} />}
      <Row>
        <div className="screenHeight d-flex justify-content-center  align-items-center">
          <Col xl={8} lg={8} md={10} sm={12} xs={12} className=" Form-Shadow ">
            <div className="arrowback ps-3">
              <BackArrow back="/SellingSide" black="black" mystyle="p-1 ps-3" />
            </div>
            <div className="">
              <Formik
                initialValues={{
                  profileName: "",
                  category: "",
                  subCategory: "",
                  country: "",
                  state: "",
                  city: "",
                  cnic: "",
                  address: "",
                }}
                // validateOnChange={false}
                validateOnBlur={false}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  // same shape as initial values
                }}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  errors,
                  touched,
                  isValid,
                  dirty,
                }) => (
                  <Form>
                    <div>
                      <h4 className="createHeading pt-3 ps-5">{props.title}</h4>
                    </div>
                    <Row className="ps-5 pe-5">
                      <Col md={4} lg={4} xl={4}>
                        <Form.Label className="custm-lble-2 pt-2">
                          Add Profile Name
                        </Form.Label>
                        <Form.Control
                          placeholder="Add Service Profile"
                          name="profileName"
                          defaultValue={createService?.profileName}
                          onChange={(e) => {
                            handleValue(e);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          className="form-control-seller"
                        />

                        {errors.profileName && touched.profileName ? (
                          <div>
                            <p className="error_text_color">
                              {errors.profileName}
                            </p>
                          </div>
                        ) : null}
                      </Col>

                      <Col md={4} lg={4} xl={4}>
                        <Form.Label className="custm-lble-2 pt-2">
                          Category
                        </Form.Label>
                        <Form.Select
                          className="form-control-seller "
                          placeholder="Sub-Category"
                          name="category"
                          onChange={(e) => {
                            handleValue(e);
                            handleChange(e);
                            Handledata(e);
                          }}
                          onBlur={handleBlur}
                        >
                          <option> select Category</option>
                          {shopCategory?.map((category) => (
                            <option
                              key={category._id}
                              value={`${category?._id}.${category?.name}`}
                            >
                              {category?.name}
                            </option>
                          ))}
                        </Form.Select>
                        {errors.category && touched.category ? (
                          <div>
                            <p className="error_text_color">
                              {errors.category}
                            </p>
                          </div>
                        ) : null}
                      </Col>

                      <Col lg="4">
                        <Form.Label className="custm-lble-2 pt-2 ">
                          Sub-Category
                        </Form.Label>
                        <Form.Select
                          className="  form-control-seller"
                          placeholder="Sub-Category"
                          name="subCategory"
                          onClick={Mydata}
                          onChange={(e) => {
                            handleValue(e);
                            handleChange(e);
                            Handledata(e);
                          }}
                          onBlur={handleBlur}
                        >
                          <option> select Sub-Category</option>
                          {shopCategorytwo?.map((category) => (
                            <option
                              key={category._id}
                              value={`${category?._id}.${category?.name}`}
                            >
                              {category?.name}
                            </option>
                          ))}
                        </Form.Select>
                        {errors.subCategory && touched.subCategory ? (
                          <div>
                            <p className="error_text_color">
                              {errors.subCategory}
                            </p>
                          </div>
                        ) : null}
                      </Col>
                    </Row>

                    <Row className="ps-5 pe-5 mt-2">
                      <Col md={4} lg={4} xl={4}>
                        <Form.Label className="custm-lble-2 pt-2">
                          Shop Address
                        </Form.Label>
                        <Form.Control
                          placeholder="Add Address with maps"
                          name="address"
                          defaultValue={createService?.address}
                          className="form-control-seller"
                          onChange={(e) => {
                            handleValue(e);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        />
                        <div className="position-relative">
                          <img className="iMg" src={images.FindClinic} />
                        </div>
                        {errors.address && touched.address ? (
                          <div>
                            <p className="error_text_color">{errors.address}</p>
                          </div>
                        ) : null}
                      </Col>

                      <Col md={4} lg={4} xl={4}>
                        <Form.Label className="custm-lble-2 pt-2">
                          CNIC or Licence Number
                        </Form.Label>
                        <Form.Control
                          placeholder="Licence Number/CNIC"
                          name="cnic"
                          defaultValue={createService?.cnic}
                          className="form-control-seller"
                          onChange={(e) => {
                            handleValue(e);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.cnic && touched.cnic ? (
                          <div>
                            <p className="error_text_color">{errors.cnic}</p>
                          </div>
                        ) : null}
                      </Col>

                      <div className="col-lg-4 col-md-4 col-12">
                        <label
                          htmlFor="inputPassword4"
                          placeholder="First name"
                          className="form-label labelSize"
                        >
                          Country*
                        </label>
                        <div className="blood-type">
                          <div className="d-flex">
                            <div className="w-100">
                              <input
                                type="search"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Search country here...."
                                className="w-100 search-input"
                                onClick={() => setHidefield(0)}
                              />
                            </div>
                            <div>
                              <span>
                                <SearchIcon className="search-icon-fs" />
                              </span>
                            </div>
                          </div>

                          <select
                            onClick={() => setHidefield(1)}
                            value={selectedCountry}
                            onChange={(e) => {
                              handleCountryChange(e);
                              handleCountry(e);
                              handleValue(e);
                              handleChange(e);
                            }}
                            className="b-radius selectSize w-100"
                            name="country"
                            onBlur={handleBlur}
                          >
                            {hidefield && <option>Select country</option>}

                            {country
                              .filter((country) =>
                                country
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                              )
                              .map((country, index) => (
                                <option key={country} value={getcountry}>
                                  {country}
                                </option>
                              ))}
                          </select>
                        </div>
                        {errors.country && touched.country ? (
                          <div>
                            <p className="error_text_color">{errors.country}</p>
                          </div>
                        ) : null}
                      </div>
                    </Row>

                    <Row className="ps-5 pe-5 mt-2">
                      <div className="col-md-4">
                        <label
                          htmlFor="inputPassword4"
                          placeholder="First name"
                          className="form-label labelSize"
                        >
                          State*
                        </label>
                        <div className="blood-type">
                          <div className="d-flex">
                            <div className="w-100">
                              <input
                                type="search"
                                value={searchTermone}
                                onChange={handleSearchChangestate}
                                placeholder="Search state here...."
                                className="w-100 search-input"
                                onClick={() => setHidestate(0)}
                              />
                            </div>
                            <div>
                              <span>
                                <SearchIcon className="search-icon-fs" />
                              </span>
                            </div>
                          </div>

                          <select
                            onClick={() => setHidestate(1)}
                            className="b-radius selectSize w-100 "
                            value={selectedstateone}
                            aria-label="Default select example "
                            name="state"
                            onChange={(e) => {
                              handleValue(e);
                              handleChange(e);
                              handleState(e);
                              handleCountryChangestate(e);
                            }}
                            onBlur={handleBlur}
                          >
                            {hidestate && <option>Select state</option>}
                            {/* <option>
                            {Open === undefined
                              ? "Select Country First"
                              : "select state"}
                          </option> */}

                            {getState
                              .filter((country) =>
                                country
                                  .toLowerCase()
                                  .includes(searchTermone.toLowerCase())
                              )
                              .map((country) => (
                                <option key={country} value={selectedState}>
                                  {country}
                                </option>
                              ))}
                          </select>
                        </div>
                        {errors.state && touched.state ? (
                          <div>
                            <p className="error_text_color">{errors.state}</p>
                          </div>
                        ) : null}
                      </div>

                      <div className="col-md-4">
                        <label
                          htmlFor="inputPassword4"
                          placeholder="First name"
                          className="form-label labelSize"
                        >
                          City
                        </label>
                        <div className="blood-type">
                          <div className="d-flex">
                            <div className="w-100">
                              <input
                                type="search"
                                value={searchTermtwo}
                                onChange={handleSearchChangecity}
                                placeholder="Search city here...."
                                className="w-100 search-input"
                                onClick={() => setHidecity(0)}
                              />
                            </div>
                            <div>
                              <span>
                                <SearchIcon className="search-icon-fs" />
                              </span>
                            </div>
                          </div>

                          <select
                            onClick={() => setHidecity(1)}
                            value={selectedcity}
                            className="b-radius selectSize w-100"
                            aria-label="Default select example"
                            name="city"
                            onChange={(e) => {
                              handleValue(e);
                              handleChange(e);
                              handleCountryChangecity(e);
                            }}
                            onBlur={handleBlur}
                          >
                            {/* <option>
                              {city === undefined
                                ? "Select State First"
                                : "Select City"}
                            </option> */}

                            {hidecity && <option>Select city</option>}

                            {getcity
                              .filter((country) =>
                                country.name
                                  .toLowerCase()
                                  .includes(searchTermtwo.toLowerCase())
                              )
                              .map((country) => (
                                <option key={country.name}>
                                  {country.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {errors.city && touched.city ? (
                          <div>
                            <p className="error_text_color">{errors.city}</p>
                          </div>
                        ) : null}
                      </div>

                      <Col md={4} lg={4} xl={4}>
                        <Form.Label className="custm-lble-2 pt-2">
                          Add Video Title
                        </Form.Label>
                        <Form.Control
                          placeholder="Add Video Title"
                          name="videoTitle"
                          defaultValue={createService.videoTitle}
                          className="form-control-seller"
                          onChange={(e) => handleValue(e)}
                          onBlur={handleBlur}
                        />
                        {errors.videos && touched.videos ? (
                          <div>
                            <p className="error_text_color">{errors.videos}</p>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="ps-5 pe-5 mt-2">
                      <Col md={4} lg={4} xl={4}>
                        <Form.Label className="custm-lble-2 pt-2">
                          Add Image Title
                        </Form.Label>
                        <Form.Control
                          className="form-control-seller"
                          placeholder="Add Image Title"
                          defaultValue={createService.imageTitle}
                          name="imageTitle"
                          onChange={(e) => handleValue(e)}
                          onBlur={handleBlur}
                        />
                        {errors.imagetitle && touched.imagetitle ? (
                          <div>
                            <p className="error_text_color">
                              {errors.imagetitle}
                            </p>
                          </div>
                        ) : null}
                      </Col>
                      <Col md={4} lg={4} xl={4}>
                        <Form.Label className="custm-lble-2 pt-2">
                          Add Video Description
                        </Form.Label>
                        <Form.Control
                          className="form-control-seller"
                          placeholder="Add Video Description"
                          defaultValue={createService.videoDescription}
                          name="videoDescription"
                          onChange={(e) => handleValue(e)}
                          onBlur={handleBlur}
                        />
                        {errors.videodescription && touched.videodescription ? (
                          <div>
                            <p className="error_text_color">
                              {errors.videodescription}
                            </p>
                          </div>
                        ) : null}
                      </Col>
                      <Col md={4} lg={4} xl={4}>
                        <Form.Label className="custm-lble-2 pt-2">
                          Add Image Description
                        </Form.Label>
                        <Form.Control
                          className="form-control-seller"
                          placeholder="Add Image Description"
                          defaultValue={createService.imageDescription}
                          name="imageDescription"
                          onChange={(e) => handleValue(e)}
                          onBlur={handleBlur}
                        />
                        {errors.imagedescription && touched.imagedescription ? (
                          <div>
                            <p className="error_text_color">
                              {errors.imagedescription}
                            </p>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="ps-5 pe-5 mt-5">
                      <Col md={4} lg={3} xl={2} xs={4}>
                        <Card
                          style={{ width: "6rem", height: "6rem" }}
                          {...getRootPropsPort()}
                          className="cursor_P"
                        >
                          <Card.Img
                            className="imet-fit"
                            src={images.AddImage2}
                          />
                          <Card.Body>
                            <input
                              type="file"
                              id="fileInputPort"
                              style={{ display: "none" }}
                              name="portfolioImages"
                              {...getInputPropsPort()}
                              multiple
                            />
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col md={4} lg={3} xl={2} xs={4}>
                        <Card
                          style={{ width: "6rem", height: "6rem" }}
                          {...getRootPropsVideo()}
                          className="cursor_P"
                        >
                          <Card.Img
                            className="imet-fit"
                            src={images.Addvideo}
                          />
                          <Card.Body>
                            <input
                              type="file"
                              id="fileInputVideo"
                              style={{ display: "none" }}
                              name="videos"
                              {...getInputPropsVideo()}
                            />
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col md={4} lg={3} xl={2} xs={4}>
                        <Card
                          style={{ width: "6rem", height: "6rem" }}
                          {...getRootPropsImage()}
                          className="cursor_P"
                        >
                          <Card.Img
                            className="imet-fit"
                            src={images.AddImage}
                          />
                          <Card.Body>
                            <input
                              type="file"
                              id="fileInputImage"
                              style={{ display: "none" }}
                              name="images"
                              {...getInputPropsImage()}
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>

                    {/* <Col
                        lg={4}
                        className=" mt-2 d-flex justify-content-start gap-2 "
                      >
                        {uploadedImages.map((image, index) => (
                          <Card
                            style={{
                              width: "6rem",
                              height: "6rem",
                              border: "2px solid",
                            }}
                            key={index}
                          >
                            <Card.Img
                              className="imet-fit upload-img"
                              src={image}
                              alt={`Uploaded ${index}`}
                            />
                            <div
                              className="close-btnone"
                              onClick={() => removeImage(index)}
                            >
                              <ClearIcon />
                            </div>
                          </Card>
                        ))}
                      </Col> */}

                    <Row className="pe-5">
                      <div className="btns pb-3">
                        <button
                          className="btn1"
                          onClick={() => navigate("/service-integrate")}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn2 disable-btn"
                          onClick={
                            props.btn === "Create"
                              ? (e) => formSericeSubmit(e)
                              : (e) => servcieFormupdate(e)
                          }
                          disabled={
                            location.pathname === "/create-service"
                              ? !(isValid && dirty)
                              : ""
                          }
                          data-title={
                            location.pathname === `${props.link}${id}`
                              ? null
                              : !(isValid && dirty)
                                ? "Please fill out all Fields to enable button!"
                                : null
                          }
                        >
                          {" "}
                          {props.btn}
                        </button>
                      </div>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </div>
      </Row>
    </>
  );
};
export default ServiceForm;
