import "./goLiveChatRoom.css"
import ChatRoomsuerView from "../../../components/User_viewchatroom";
import LiveChatRoomComments from "../../../components/User_Viewchatroom_comment"
import { Helmet } from "react-helmet";
const GoLive = () => {
    return (
        < >
  <Helmet>
      <body style="background:white"></body>
    </Helmet>
            <div className="container">
            <div className="screenHeight">
            <div className="row">
                    <div  className="col-xl-4 col-lg-4 col-md-5 col-sm-5 col-12 mt-3 pt-1">
                        <LiveChatRoomComments />
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-7 col-sm-7 col-12 ps-0 pe-0">
                        <ChatRoomsuerView />
                    </div>
                </div>
            </div>
                
            </div>
        </>

    )
}
export default GoLive;