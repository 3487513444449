import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Col, Row } from "react-bootstrap";
import ShopAdds from "../shopAds/ShopAdds";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SuccessNotify, FailedNotify } from "../../constants/tostify";
import Suggestion from "../../pages/suggestion";
import Suggestionmodal from "../../pages/suggestionmodal";
import Deletemodal from "../DeleteModal";
import ReportBox from "../Report/index";
import { images } from "../../constants";
import RedoIcon from "@mui/icons-material/Redo";
import url from "../../config/axios";
import ShareModel from "../Sharemodel";
import ShareOption from "../Shareoption";
import "./index.css";
import { getAsset } from "../../helpers/helper";

const UsedvehicleitemShop = ({
 
  shopid,
  urlPass,
  btnName,
  name,
  navigateto,
  updateState,
  setSuggest,
  suggest,
  Setreport,
  Report,
  post,
  suggestdata,
  shopData,

}) => {

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [showmodal, setShowmodal] = useState(false);
  const [showshare, setShowshare] = useState(false);
  const [itemid, setItemid] = useState();
  const [deleteshow, setDeleteshow] = useState(false);
  const handleClosetwo = () => setShowmodal(0);
  const [shows, setShows] = useState(false);
  const [itemshow, setItemshow] = useState();
  const [index, setIndex] = useState();

  useEffect(() => {
    url.get(`sellvehicle?shopid=${id}`).then(async (response) => {
      setItemshow(response?.data?.data?.SellVehicles);
    });
  }, []); 

  const handleSelect = (key) => {
    if (key === "pso") {
      navigate(post);
    }
  };

  const Deleteitem = () => {
    url
      .delete(`sellvehicle/?id=${itemid}`)
      .then(async (response) => {
        if (response.data.success) {
          setDeleteshow(false);
          SuccessNotify("Item Deleted Successfully");
          url.get(`sellvehicle?shopid=${id}`).then(async (response) => {
            setItemshow(response?.data?.data?.SellVehicles);
          });
        }
      })
      .catch((e) => {
        FailedNotify("Failed to Delete Try Again!");
        setDeleteshow(false);
      });
  };
  const handleClose = () => {
    setDeleteshow(false);
  };
  const handleCloseshare = () => {
    setShowshare(0);
    setShow(1);
  };
  const handleShowshare = () => {
    setShow(0);
    setShowshare(1);
  };
  const handleClosemodal = () => {
    setShowmodal(false);
    setShow(0);
  };
  const suggestid = localStorage.getItem("suggestid");
  return (
    <>
      <Col lg={12}>
        {deleteshow === true && (
          <Deletemodal
            shows={deleteshow}
            title="Profile"
            handleClose={handleClose}
            animation={true}
            handledelete={Deleteitem}
          />
        )}
        <ShopAdds back="/SellingSide" />
        <ShareOption
          showshare={showshare}
          handleCloseshare={handleCloseshare}
        />
        <ShareModel
          show={showmodal}
          handleClosemodal={handleClosemodal}
          handleShowshare={handleShowshare}
        />
        <Row>
          <Col lg={12}>
            {suggest === 1 ? (
              <Suggestionmodal
                setSuggest={setSuggest}
                suggest={suggest}
                suggestdata={suggestdata}
                shopid={shopid}
                shopData={shopData}
              />
            ) : suggest === 2 ? (
              <ReportBox setSuggest={setSuggest} suggest={suggest} />
            ) : suggest === 3 ? (
              <Suggestion
                suggestid={suggestid}
                suggestdata={suggestdata}
                shopData={shopData}
                setSuggest={setSuggest}
              />
            ) : (
              <Tabs
                onSelect={handleSelect}
                id="uncontrolled-tab-example"
                className="mb-3 ps-5"
              >
                <Tab
                  eventKey="jobs"
                  title="RegularItems"
                  className="jobviewhead"
                >
                  <div className="shop-scroll noscroll ">
                    <div className="items_scroll noscroll">
                      <div className=" row ps-5 pe-5 noscroll scroll-shops_2">
                        {itemshow?.map((Items) => (
                          <div className="col-lg-3 col-xl-3 col-md-6 p-2 ">
                            <div className="border_2 position-relative">
                              <button
                                className="text-white bg-blue share_font btn-position-absolute"
                                onClick={() => setShowmodal(true)}
                              >
                             
                                Share <RedoIcon className="share_icon_f" />
                              </button>
                              <div key={Items?.id}>
                                <div className="fix-img">
                                  <img  
                                    className="c_img"
                                    src={getAsset(Items?.images)}
                                    alt=""
                                    crossOrigin="anonymous"
                                  />
                                </div>
                                <div className="d-flex justify-content-between pt-1 pb-1 ps-2 pe-2 ">
                                  <div>
                                    <p className="mb-0 pt-1  items-card-font font-blue">
                                      {Items?.brandName}
                                    </p>
                                  </div>
                                  <div> {Items?.modelYear}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-1 pb-1 ps-2 pe-2 ">
                                  <div>
                                    <p className="mb-0 pt-1  items-card-font font-blue">
                                      PKR {Items?.price}
                                    </p>
                                  </div>
                                  <div>
                                                   
                                    <img src={images.StarImg} />
                                  </div>
                                </div>
                                <div className="d-flex justify-content-around mb-2 mt-1 ps-2 pe-2">
                                  <div>

                                    <p
                                      className="mb-0 text-white view-btn w-100 text-center bg-blue  me-1"
                                      onClick={() =>
                                        navigate(
                                          `/vehicle-add/${Items?._id}/${shopid}`
                                        )
                                      }
                                    >
                                      View
                                    </p>
                                  </div>
                                  <div>

                                    <p
                                      className="mb-0 delete-btn w-100 text-center text-white bg-red me-1"
                                      onClick={() => { setDeleteshow(true); setItemid(Items?._id); }}
                                    >
                                      Delete
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Tab>

                <Tab
                  id="uncontrolled-tab-example-tab-pso"
                  className="tab_postset"
                  eventKey="pso"
                  title="&#10133; Post Item"
                  onClick={() => navigate(`${post}/` + id)}
                ></Tab>
              </Tabs>
            )}
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default UsedvehicleitemShop;
