import { Container, Col, Row, Form } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import url from "../../../../config/axios";
import { SuccessNotify, FailedNotify } from "../../../../constants/tostify";
import { useSelector } from "react-redux";
const RequestDeleteshop = ({ shopid }) => {
  const location = useLocation();
  const { name } = useSelector((state) => state.user);
  const [deleteshop, setDeleteshop] = useState({
    reason: "",
    description: "",
    email: name.email,
  });

  const handleChange = (e) => {
    setDeleteshop((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    url
      .post(`shop/deleteShopRequest/shopid?shopid=${shopid}`, deleteshop)
      .then(async (res) => {
        if (res.data.success) {
          SuccessNotify(res.data.message);
        }
      })
      .catch((e) => {
        FailedNotify(e.response.data.message);
      });
  };

  return (
    <>
      <Container>
        {location.pathname === "/company-setting" ? (
          <p className="font_13">
            Please give us you feedback why you want to delete your Company?
          </p>
        ) : (
          <p className="font_13">
            Please give us you feedback why you want to delete your shop?
          </p>
        )}

        <Row>
          <Col lg="12">
            <Form>
              <Form.Check
                type="radio"
                label="Limited Features"
                value="Limited Features"
                name="reason"
                id="radioOption1"
                onChange={(e) => handleChange(e)}
              />

              <Form.Check
                type="radio"
                label="To many ads"
                value="To many ads"
                name="reason"
                id="radioOption2"
                onChange={(e) => handleChange(e)}
              />

              <Form.Check
                type="radio"
                label="Not functioning properly"
                value="Not functioning properly"
                name="reason"
                id="radioOption3"
                onChange={(e) => handleChange(e)}
              />

              <Form.Check
                type="radio"
                label="Other's ( please be specify)"
                value="Other's ( please be specify)"
                name="reason"
                id="radioOption4"
                onChange={(e) => handleChange(e)}
              />
            </Form>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FloatingLabel controlId="floatingTextarea2">
              <Form.Control
                as="textarea"
                placeholder="Describe"
                style={{ height: "100px" }}
                className="p-2"
                name="description"
                onChange={(e) => handleChange(e)}
              />
            </FloatingLabel>
            <div className="d-flex justify-content-end">
              <Col lg="2 mt-3 text-end">
                <button
                  className={`${
                    deleteshop.reason === "" ? "disable-btn" : ""
                  } btn-blue-sm ps-0 pe-0`}
                  onClick={(e) => handleSubmit(e)}
                >
                  Submit
                </button>
              </Col>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RequestDeleteshop;
