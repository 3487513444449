import { Container } from "react-bootstrap";
import { Col, Row, Button } from "react-bootstrap";
import BackArrow from "../../components/backArrow";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import Card from "react-bootstrap/Card";
import SearchIcon from "@mui/icons-material/Search";
import { images } from "../../constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Measurementunit, currencies } from "../../constants/jsonConstant";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import url from "../../config/axios";
import { SuccessNotify, FailedNotify } from "../../constants/tostify";
import { Padding } from "@mui/icons-material";
import { publishImage } from "../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
import { getAsset } from "../../helpers/helper";
import { Formik, Form } from "formik";
import * as Yup from "yup";
const PostSingleAdd = () => {
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const [shopCategory, setShopCategory] = useState();
  const [shopCategorytwo, setShopCategorytwo] = useState();

  const [displayimg, setDisplayimg] = useState();
  const { name } = useSelector((state) => state.user);
  const [selectedImage, setSelectedImage] = useState("");

  const [data, setData] = useState({
    categoryid: "",
    subCategoryid: ""
  });

  const Handledata = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split("."),
    }));
  };


  const categoryName = data?.categoryid[1];
  const categoryidone = data.categoryid[0];
  const subcategoryName = data?.subCategoryid[1];
  const subcategorytwo = data.subCategoryid[0];


  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setUserData((userData) => ({
        ...userData,
        images: abc,
      }));

      setDisplayimg(abc);
    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });
  const [userData, setUserData] = useState({
    userid: name._id,
    itemname: "",
    isSingleItem: true,
    isScrapeItem: false,
    category: "",
    categoryid: "",
    subCategory: "",
    subCategoryid: "",
    price: "",
    country: "",
    city: "",
    state: "",
    quantity: "",
    description: "",
    unitOfMeasurement: "",
    currency: "",
    images: [""],
    status: true,
  });

  useEffect(() => {
    setUserData((userData) => ({
      ...userData,
      category: categoryName,
      categoryid: categoryidone,
      subCategory: subcategoryName,
      subCategoryid: subcategorytwo
    }));
  }, [categoryidone, categoryName, subcategoryName, subcategorytwo]);

  const FirstCategory = (e) => {
    e.preventDefault();
    url
      .get("category/consumer/?type=singleItem&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });
  };

  const InputChange = (e) => {
    setUserData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const Mydata = (e) => {
    e.preventDefault();
    url
      .get(`/category/type/?type=singleItem&parentCategory=${categoryName}`)
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategorytwo(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
      });
  };

  const userDatahandler = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("/shopitem", userData)
      .then(async (response) => {
        console.log("newitemdata", response);

        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Add Post Successfully");
          }, 1000);
          navigate(`/singleadd-show`);
        }
      })
      .catch((e) => {
        setIsloading(false);
        FailedNotify("Failed To Post Try Again!");
      });
  };

  const removeImage = (index) => {
    const updatedImages = [...displayimg];
    updatedImages.splice(index, 1);
    setDisplayimg(updatedImages);
  };

  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedstateone, setSelectedstateone] = useState("");
  const [searchTermone, setSearchTermone] = useState("");

  const [selectedcity, setSelectedcity] = useState("");
  const [searchTermtwo, setSearchTermtwo] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle the country selection
  const handleCountryChange = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
  };

  const handleSearchChangestate = (event) => {
    setSearchTermone(event.target.value);
  };

  const handleSearchChangecity = (event) => {
    setSearchTermtwo(event.target.value);
  };

  // Function to handle the state selection
  const handleCountryChangestate = (event) => {
    const { value } = event.target;
    setSelectedstateone(value);
  };

  // Function to handle the city selection
  const handleCountryChangecity = (event) => {
    const { value } = event.target;
    setSelectedcity(value);
  };
  const [hidefield, setHidefield] = useState(1);
  const [hidestate, setHidestate] = useState(1);
  const [hidecity, setHidecity] = useState(1);

  const [countrydata, SetCountrydata] = useState([]);
  const [getcountry] = useState();
  const [selectedState] = useState();
  const [getState, setState] = useState([]);
  const [getcity, setGetcity] = useState([]);
  const [statetwo, setStatetwo] = useState(getcountry);
  const [city, setCity] = useState(selectedState);
  const [open, setOpen] = useState(getcountry);

  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => SetCountrydata(res.data))

      .catch((error) => { });
  }, []);

  const country = [...new Set(countrydata.map((item) => item.country))];

  country.sort();

  const handleCountry = (e) => {
    let states = countrydata.filter(
      (state) => state.country === e.target.value
    );
    states = [...new Set(states.map((item) => item.subcountry))];
    states.sort();
    setState(states);
  };

  const handleState = (e) => {
    let cities = countrydata.filter(
      (city) => city.subcountry === e.target.value
    );
    setGetcity(cities);
  };
  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }

  const validationSchema = Yup.object({
    description: Yup.string().required("description is required*"),
    state: Yup.string().required("description is required*"),
  });

  return (
    <>
      <Container>
        <Row>
          <div className="screenHeight d-flex justify-content-center align-items-center">
            <Col lg={10} md={12} sm={12} xs={12} className="Form-Shadow">
              <Row>
                <Col className="">
                  <div className="arrowback ps-3">
                    <BackArrow
                      back="/SellingSide"
                      black="black"
                      mystyle="ps-4 p-1"
                    />
                  </div>
                  {/* <div className="pe-3  pb-2"> */}
                  {/* </div> */}
                </Col>
              </Row>

              <Formik
                initialValues={{
                  description: "",
                  state: "",
                }}
                // validateOnChange={false}
                validateOnBlur={false}
                validationSchema={validationSchema}
                onSubmit={(values) => { }}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  errors,
                  touched,
                  isValid,
                  dirty,
                }) => (
                  <Form
                    onSubmit={(e) => {
                      userDatahandler(e);
                      handleSubmit(e);
                    }}
                  >
                    <div className="p-4">
                      <Row className="pt-2 pb-2 px-3">
                        <Col md={3} lg={3} xl={3}>
                          <label className="custm-lble">Item Name</label>
                          <input
                            placeholder="Item Name "
                            name="itemname"
                            className="input-style form-control-seller   place form-control"
                            onChange={(e) => InputChange(e)}
                          />
                        </Col>

                        <Col md={3} lg={3} xl={3}>
                          <label className="custm-lble">Brand Name</label>
                          <input
                            placeholder="Optional"
                            name="brandname"
                            className="input-style form-control-seller   place form-control"
                            onChange={(e) => InputChange(e)}
                          />
                        </Col>


                       

                        <Col md={3} lg={3} xl={3}>
                          <label className="custm-lble ">Select Category</label>
                          <div className="bluedrop">
                            <select
                              onChange={(e) => Handledata(e)}
                              onClick={(e) => FirstCategory(e)}
                              className="input-style form-control-seller form-select"
                              aria-label="Default select example"
                              name="categoryid"
                            >
                              <option className="">Select Category</option>
                              {shopCategory?.map((e) => (
                                <option
                                  key={e?._id}
                                  value={`${e?._id}.${e?.name}`}
                                >
                                  {e?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>

                        <Col md={3} lg={3} xl={3}>
                          <label className="custm-lble">
                            Select Sub-Category
                          </label>
                          <div className="bluedrop">
                            <select
                              className="input-style form-control-seller form-select"
                              aria-label="Default select example"
                              name="subCategoryid"
                              onChange={(e) => Handledata(e)}
                              onClick={(e) => Mydata(e)}
                            >
                              <option>Select Sub-Category</option>
                              {shopCategorytwo?.map((e) => (
                                <option key={e?._id} value={e?._id}>
                                  {e?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                      </Row>

                      <Row className="pt-2 pb-2  px-3">

                      <Col md={3} lg={3} xl={3}>
                          <label className="custm-lble">Price</label>
                          <input
                            onChange={(e) => InputChange(e)}
                            placeholder="Price"
                            name="price"
                            className="input-style form-control-seller  place form-control"
                            type="number"
                          />
                        </Col>

                        <Col md={3} lg={3} xl={3}>
                          <label className="custm-lble ">Total Quantity</label>
                          <input
                            onChange={(e) => InputChange(e)}
                            placeholder="Quantity"
                            name="quantity"
                            className="input-style form-control-seller place form-control"
                            type="number"
                          />
                        </Col>

                        <div className="col-md-3">
                          <label
                            htmlFor="inputPassword4"
                            placeholder="First name"
                            className="form-label labelSize mb-0"
                          >
                            Country*
                          </label>
                          <div className="blood-type input-style p-1">
                            <div className="d-flex">
                              <div className="w-100">
                                <input
                                  type="search"
                                  value={searchTerm}
                                  onChange={handleSearchChange}
                                  placeholder="Search country here...."
                                  className="w-100 search-input"
                                  onClick={() => setHidefield(0)}
                                />
                              </div>
                              <div>
                                <span>
                                  <SearchIcon className="search-icon-fs" />
                                </span>
                              </div>
                            </div>

                            <select
                              onClick={() => setHidefield(1)}
                              value={selectedCountry}
                              onChange={(e) => {
                                InputChange(e);
                                handleCountryChange(e);
                                handleCountry(e);
                              }}
                              className="b-radius selectSize w-100"
                              name="country"
                            >
                              {hidefield && <option>Select country</option>}

                              {country
                                .filter((country) =>
                                  country
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                )
                                .map((country, index) => (
                                  <option key={country} value={getcountry}>
                                    {country}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <label
                            htmlFor="inputPassword4"
                            placeholder="First name"
                            className="form-label labelSize mb-0"
                          >
                            State*
                          </label>
                          <div className="blood-type input-style p-1">
                            <div className="d-flex">
                              <div className="w-100">
                                <input
                                  type="search"
                                  value={searchTermone}
                                  onChange={handleSearchChangestate}
                                  placeholder="Search state here...."
                                  className="w-100 search-input"
                                  onClick={() => setHidestate(0)}
                                />
                              </div>
                              <div>
                                <span>
                                  <SearchIcon className="search-icon-fs" />
                                </span>
                              </div>
                            </div>

                            <select
                              onClick={() => setHidestate(1)}
                              className="b-radius selectSize w-100"
                              value={selectedstateone}
                              aria-label="Default select example "
                              name="state"
                              onChange={(e) => {
                                InputChange(e);
                                handleState(e);
                                handleCountryChangestate(e);
                                handleChange(e);
                              }}
                            >
                              {hidestate && <option>Select state</option>}
                              {/* <option>
                            {Open === undefined
                              ? "Select Country First"
                              : "select state"}
                          </option> */}

                              {getState
                                .filter((country) =>
                                  country
                                    .toLowerCase()
                                    .includes(searchTermone.toLowerCase())
                                )
                                .map((country) => (
                                  <option key={country} value={selectedState}>
                                    {country}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        
                      </Row>

                      <Row className="pt-2 pb-2  px-3">

                      <div className="col-md-3 ">
                          <label
                            htmlFor="inputPassword4"
                            placeholder="First name"
                            className="form-label labelSize mb-0"
                          >
                            City
                          </label>
                          <div className="blood-type input-style p-1">
                            <div className="d-flex">
                              <div className="w-100">
                                <input
                                  type="search"
                                  value={searchTermtwo}
                                  onChange={handleSearchChangecity}
                                  placeholder="Search city here...."
                                  className="w-100 search-input"
                                  onClick={() => setHidecity(0)}
                                />
                              </div>
                              <div>
                                <span>
                                  <SearchIcon className="search-icon-fs" />
                                </span>
                              </div>
                            </div>

                            <select
                              onClick={() => setHidecity(1)}
                              value={selectedcity}
                              className="b-radius selectSize w-100"
                              aria-label="Default select example"
                              name="city"
                              onChange={(e) => {
                                handleCountryChangecity(e);
                                InputChange(e);
                              }}
                            >
                              {/* <option>
                              {city === undefined
                                ? "Select State First"
                                : "Select City"}
                            </option> */}

                              {hidecity && <option>Select city</option>}

                              {getcity
                                .filter((country) =>
                                  country.name
                                    .toLowerCase()
                                    .includes(searchTermtwo.toLowerCase())
                                )
                                .map((country) => (
                                  <option key={country.name}>
                                    {country.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        
                        <Col className="mt-1" md={3} lg={3} xl={3}>
                          <label className="custm-lble">
                            Unit of Measurement
                          </label>
                          <div className="bluedrop">
                            <select
                              onChange={(e) => InputChange(e)}
                              aria-label="Default select example"
                              name="unitOfMeasurement"
                              className="input-style form-control-seller form-select"
                            // onChange={(e) => {
                            //   handleChange(e);
                            //   inputChange(e);
                            // }}
                            >
                              <option disabled selected>
                                {" "}
                                Select Unit{" "}
                              </option>
                              {Measurementunit.map((e) => (
                                <option>{e.name}</option>
                              ))}
                            </select>
                          </div>
                        </Col>

                        <Col md={3} lg={3} xl={3} className="mt-1">
                          <label className="custm-lble">Currency</label>
                          <div className="bluedrop">
                            <select
                              onChange={(e) => InputChange(e)}
                              aria-label="Default select example"
                              name="currency"
                              className="input-style form-control-seller form-select"
                            // onChange={(e) => {
                            //   handleChange(e);
                            //   inputChange(e);
                            // }}
                            >
                              <option disabled selected>
                                {" "}
                                Select Currency{" "}
                              </option>
                              {currencies.map((e) => (
                                <option>
                                  {e.abbreviation} {e.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                      </Row>

                      <div className="ps-3 pe-3 pt-4">
                        <label className="custm-lble">Description</label>
                        <textarea
                          onChange={(e) => {
                            InputChange(e);
                            handleChange(e);
                          }}
                          as="textarea"
                          rows={5}
                          name="description"
                          className="input-style form-control"
                        />
                      </div>

                      <div className="d-flex justify-content-between pt-2">
                        <Col lg="9" className="me-auto">
                          <Row>
                            <Col lg={2} className="mt-2 ms-3">
                              <Card
                                style={{ width: "6rem", height: "6rem" }}
                                {...getRootProps()}
                                className="dropzone"
                              >
                                <Card.Img
                                  className="imet-fit"
                                  src={images.AddImage}
                                />
                                <Card.Body>
                                  <input
                                    type="file"
                                    id="file"
                                    style={{ display: "none" }}
                                    {...getInputProps()}
                                    multiple
                                  />
                                </Card.Body>
                              </Card>
                            </Col>
                            {displayimg?.length > 0 && (
                              <Col lg={8} className=" mt-1 d-flex">
                                {displayimg.map((image, index) => (
                                  <Card
                                    className="me-2 mt-1"
                                    style={{
                                      width: "6rem",
                                      height: "6rem",
                                      border: "2px solid",
                                    }}
                                  >
                                    <Card.Img
                                      className="imet-fit upload-img mt-0"
                                      src={getAsset(image)}
                                      alt=""
                                      crossOrigin="anonymous"
                                    />
                                    <div
                                      className="close-btnone"
                                      onClick={() => removeImage(index)}
                                    >
                                      <ClearIcon />
                                    </div>
                                  </Card>
                                ))}
                              </Col>
                            )}
                          </Row>
                        </Col>

                        <div className="d-flex align-items-end pb-3 pe-3">
                          <div className="btns">
                            <button
                              className="btn1"
                              onClick={() => navigate("/SellingSide")}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn2 disable-btn "
                              onClick={(e) => userDatahandler(e)}
                              disabled={!(isValid && dirty)}
                              data-title={
                                !(isValid && dirty)
                                  ? "Please fill out all Fields to enable button!"
                                  : null
                              }
                            >
                              Post
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PostSingleAdd;
