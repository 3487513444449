
import React, { useState, useEffect } from "react";
import { Row, Container, Col } from "react-bootstrap";
import UserItemShop from "../../components/UserShopItem/UserItemShop";
import UserShopProfile from "../../components/UserShopProfile/UserShopProfile";
import BackArrow from "../../components/backArrow";
import { useParams } from "react-router-dom";
import url from "../../config/axios";
import { useSelector } from "react-redux";

const HomeMadeItemUserprofile = () => {
  const { shopid } = useParams();
  const [shopData, setShopData] = useState();
  const itemData = useSelector((state) => state.itemdata);

  const newItemdata = itemData?.itemdata;
  const regular = [];
  const specialoffer = [];
  const salespromotion = [];

  // Iterate through ShopItems and categorize them
  newItemdata?.forEach((item) => {
    if (
      (!item.specialoffer || item.specialoffer === "") &&
      (!item.salespromotion || item.salespromotion === "")
    ) {
      regular.push(item);
    } else if (!item.specialoffer || item.specialoffer === "") {
      salespromotion.push(item);
    } else if (!item.salespromotion || item.salespromotion === "") {
      specialoffer.push(item);
    } else {
      specialoffer.push(item);
      salespromotion.push(item);
    }
  });
  useEffect(() => {
    url.get(`shop/id?id=${shopid}`).then(async (res) => {
      setShopData(res?.data?.data?.Shop);
    });
  }, []);

  return (
    <Container>
      <Row>
        <Col lg="4" className="screenHeight">
          <div className="border b-radius height_100">
            <UserShopProfile shopData={shopData} shopid={shopid} />
          </div>
        </Col>

        <Col lg="8" className="screenHeight">
          <div className="border b-radius">
            <BackArrow back="/buy-homemade-products" />
            <UserItemShop
              shopid={shopid}
              itemData={itemData}
              regular={regular}
              specialoffer={specialoffer}
              salespromotion={salespromotion}
              newItemdata={newItemdata}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default HomeMadeItemUserprofile;
