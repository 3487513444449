import Nodata from "../components/Nodata";
import Modalcondition from "../pages/Auth/components/modaltermecondition";
import {
  Login,
  Dashboard,
  Newitemsupdate,
  Form,
  UsedItems,
  NewItemsForm,
  HomeMadeItemsForm,
  MineralAndMiningForm,
  BusinessPropertyForm,
  UpdatePropertyForm,
  PostAddForm,
  MineralMinnersPostAdd,
  UsedItemsPostAddForm,
  HomeMadePostAddForm,
  NewItemsPostAddForm,
  MineralsAndMiningUpdate,
  UserItemShop,
  HomeMadeItem,
  UserShopProfile,
  CreateCompanyForm,
  UpdateCompanyForm,
  CreateTransport,
  UpdateTransport,
  CreateJobApplication,
  UpdateJobApplication,
  CreateService,
  UpdateService,
  CreatePersonalProperty,
  AddPersonalProperty,
  UpdatePersonalProperty,
  PostBusinessAd,
  UpdateBusinessAd,
  HomeMadeProfile,
  FindJobFilter,
  AvailableCandidatesFilter,
  ServicesFilter,
  TransportFilter,
  PropertyFilter,
  HomemadeFilter,
  UsedProductsFilter,
  NewProductsFilter,
  MineralsMiningFilter,
  Viewfriends,
  Userprofile,
  MineralAndMiningProfile,
  NewItemProfile,
  UsedItemProfile,
  ShopViewModel,
  ShopItemsOwner,
  OwnerShopProfile,
  ViewApplication,
  HomeMadeOwnerProfile,
  CompanyProfile,
  Businessprofile,
  MultipleShops,
  UserShopViewModel,
  ViewJobDetails,
  CreateJobs,
  UpdateJobs,
  OrderHistory,
  SaleBussinesProfile,
  Transportprofile,
  Vehicaldetail,
  Singletransport,
  UserTransport,
  ServicesProfile,
  Avaliablecandidate,
  SalePerosonalProperty,
  MineralAndMining,
  NewItembussinesProfile,
  UsedItemsBussinesProfile,
  HomeMadeItemUserprofile,
  MinerlAndMiningUserProfile,
  NewItemsUserProfile,
  UsedItemsUserProfile,
  UserPeronalProperty,
  UserBussineProperty,
  BuyUsedProducts,
  BuyNewProducts,
  HomeMadeProducts,
  SelecteziHelpline,
  FindJobonlien,
  ViewJobOnlineDetails,
  UsedItemsCards,
  SIngleAddPostForm,
  UsedSingleAddPost,
  Useditemsupdate,
  HomemadeUpdate,
  Rawmaterialnewitem,
  Rawmaterialuseditem,
  HomemadeRawMaterialProfile,
  MineralsRawMaterialProfile,
  CVForm,
  ServicesOnlineProfile,
  BusinessDashboard,
  SelectCVTemplate,
  UpdateSingleNewItems,
  UpdateSingleUsedItems,
  UpdateSingleHomeMadeItems,
  UpdateSingleMineralsItems,
  ScrapOnline,
  BuyUsedCars,
  Businessprofilerecycle,
  RecycleOwnerProfile,
  RecycleUpdate,
  RecyclePostAdd,
  NewItemsOwnerView,
  UsedItemsOwnerView,
  HomeMadeItemsOwnerView,
  GemsandMiningItemsOwnerView,
  RecycleItemUpdate,
  RecycleItemsOwnerView,
  FarmingForm,
  FarmingOwnerProfile,
  RawmaterialFarming,
  FarmingUpdate,
  FarmingPostAddForm,
  FarmingOwnerItemView,
  FarmingItemsUpdate,
  BusinessProfileTransportation,
  BusinessPropertyItemsCard,
  UpdateVehical,
  PostVehical,
  Scrapematerial,
  Singleitems,
  PersonalAdd,
  RecycleShop,
  SellPropertyCards,
  FarmingOnline,
  RecycleOnline,
  SellVehicle,
  Sellsingle,
  Usedvehicleshop,
  Usedvehicleprofile,
  Usedvehiclebusinessprofile,
  Showadd,
  Usedvehicleviewadd,
  Updateusevehicle,
  Usedvehicleshopadd,
  Vehicleaddshow,
  Usedvehicleshopupdate,
  BuyingSide,
  SellingSide,
  PostSingleAdd,
  BuyRawMaterial,
  BuyManufacturer,
  BuyRetailer,
  BuyWholeSaller,
  RawItem,
  ManufacturerItem,
  WholesellerItem,
  RetailerItem,
  RawMaterialForm,
  ManufacturerForm,
  WholesellerForm,
  RetailerForm,
  Chatroomform,
  Ownchatroom,
  UpdateChatroomform,
  Mychart,
  Createchatroom,
  Livechatroom,
  JoinLobby,
  Chatroomstreamline,
  Joinownchatroom,
  Lobbychatroom,
  GoLive,
  Chatroommodal,
  UserVeiwChatRoom,
  InvitePeople,
  GoogleMeet,
  Profilesetting,
  Mainsetting,
  Mailsinput,
  WholesellerBusinessProfile,
  WholeSalerOwnerProfile,
  WholeSalerUpdate,
  WholesalerPostAddForm,
  WholeSalerOwnerView,
  ManufacturerBusinessProfile,
  ManufacturerUpdate,
  ManufacturerPostAddForm,
  ManufacturerOwnerProfile,
  ManufacturerOwnerView,
  RawMaterialBusinessProfile,
  RawMaterailOwnerProfile,
  RawMaterialUpdate,
  RawMaterialPostAddForm,
  RawMaterialOwnerView,
  EmailSpam,
  EmailDraft,
  HomePageTrash,
  AllEmail,
  QuotationSelling,
  TrashSelling,
  InvoiceSelling,
  QuotationBuying,
  AllInquiryBuying,
  AllPurchaseOrderBuying,
  Inbox,
  InvoiceBuying,
  AllInquirySelling,
  TrashBuying,
  ImportantEmails,
  AllPurchaseOrderSelling,
  Outbox,
  SentEmail,
  Hamburger,
  Singleitemmerge,
  NewItemsShops,
  HomeMadeShops,
  MineralAndMiningShops,
  UsedItemsShops,
  JobsCompanies,
  BusinessProperties,
  ServicesCategories,
  RecycleShops,
  FarmingShops,
  ShopManage,
  Propertybusiness,
  Emailmodal,
  Transportbusiness,
  Byvehicleuserprofile,
  Recycleuserprofile,
  Farminguserprofile,
  RetailerShop,
  WholesalerShop,
  ManufacturerShop,
  RawmaterialShop,
  ReportSelectezi,
  ManufacturerUpdatePostAddForm,
  RawMaterialUpdatePostAddForm,
  WholesellerUpdatePostAddForm,
  Searchicon,
  BuyerOne,
  SellerOne,
  Frequentlyquestions,
  Chatonline,
  Requestcall,
  Usedvehicleshops,
  ServicesShops,
  SingleItemBuy,
  Buyingtransportprofile,
  ModalEmailone,
  RemoveEmailone,
  RefundPolicy,
  OrderSumary,
  VerificationModal,
  Promotionmerge,
  CompanycreateShop,
  RetailerIntegrateform,
  Retailerintegrate,
  WholesellerIntegrateform,
  Wholesellerintegrate,
  ManufacturerIntegrateform,
  Manufacturerintegrate,
  RawIntegrateform,
  Rawintegrate,
  GemsIntegrateform,
  Gemsintegrate,
  UseditemIntegrateform,
  Useditemintegrate,
  HomemadeIntegrateform,
  Homemadeintegrate,
  PropertyIntegrateform,
  Propertyintegrate,
  UsedvehicleIntegrateform,
  Usedvehicleintegrate,
  TransportIntegrateform,
  Transportintegrate,
  RecycledIntegrateform,
  Recycledintegrate,
  FarmingIntegrateform,
  Farmingintegrate,
  CreateRestaurantProfile,
  RestaurantShopdata,
  RestaurantCard,
  RestaurantAlldata,
  FoodRestaurantComp,
  BuyRestaurantFood,
  Singleitemview,
  SingleItemOwnerView,
  UpdateSingleAdd,
  RestaurantshopForm,
  Restaurantintegrate,
  Restaurantintegrateform,
  Connectionrequest,
  Scrapitemview,
  UpdateScrapAdd,
  ScrapItemOwnerView,
  ResturentPostform,
  ResturentUpdateform,
  FoodReportSection,
  ServiceReportSection,
  TransportReportSection,
  CompanyBusinessProfile,
  FoodsuggestionSection,
  TransportsuggestionSection,
  ServicesuggestionSection,
  Servicereport,
  ServicePromotion,
  Foodandrestaurantbuy,
  FoodResturentBusiness,
  TransportProfileUpdate,
  TransportPromotion,
  FoodItemsView,
  RestaurantshopUpdateForm,
  Servicebusinessprofile,
  Serviceintegrate,
  ServiceIntegrateform,
  UpdateBussinessPro,
  UserDashboard,
  UserbuyShopViewModel,
  BuySingleProperty,
  Transportbusinessdashboard,
  TransportBussinesProfile,
  Otheruserprofile,
  SinglePropertyItemsCard,
  ViewbuyApplication,
  CVTemplatesbuyside,
  Viewbusinessfriends
 
} from "../pages/index";
import { Routes, Route } from "react-router-dom";
import NoMatch from "../pages/404Error/404Error";
import Customermerge from "../pages/CustomerSupport/Customer-merge";
import AccordionBuyerside from "../pages/CustomerSupport/AccordionBuyer";
import Suggestionmodal from "../pages/suggestionmodal";
import Suggestion from "../pages/suggestion";
import Report from "../components/Report";
import Reportmodal from "../components/Reportmodal";
import CandidatesBox from "../components/candidatesBox";
import Addmore from "../components/Addmore-form/Addmore";
// Testing-Routes
import Foodrestaurant from "../pages/Restaurant";
import DeliveryForm from "../pages/DeliveryForm";
import FoodReport from "../components/Food_Repot/ReportSection";
import Updatefoodform from "../pages/Updatefoodform";
import Connectmodal from "../components/Connect_modal";

function Routing() {
  return (
    <>
      <Routes>

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/Report-section" element={<FoodReport />} />
        <Route path="/Food-Report-section" element={<FoodReportSection />} />
        <Route path="/transport-addmore-form/:id" element={<Addmore />} />
        <Route
          path="/Service-Report-section"
          element={<ServiceReportSection />}
        />
        <Route
          path="/Transport-Report-section"
          element={<TransportReportSection />}
        />
        <Route path="/Modalcondition" element={<Modalcondition />} />
        <Route path="/form" element={<Form />} />
        <Route path="/used-items-form" element={<UsedItems />} />
        <Route path="/new-items-form" element={<NewItemsForm />} />
        <Route path="/farming-form" element={<FarmingForm />} />
        <Route path="/Home-Made-Form" element={<HomeMadeItemsForm />} />
        <Route path="/mineral-mining-form" element={<MineralAndMiningForm />} />
        <Route
          path="/business-property-form"
          element={<BusinessPropertyForm />}
        />
        <Route
          path="/update-property-form/:id"
          element={<UpdatePropertyForm />}
        />
        <Route
          path="/update-wholesaler-form/:id"
          element={<WholeSalerUpdate />}
        />
        <Route
          path="/update-rawmaterial-form/:id"
          element={<RawMaterialUpdate />}
        />
        <Route path="/postAddForm" element={<PostAddForm />} />
        <Route
          path="/manufacturer-postAdd/:id"
          element={<ManufacturerPostAddForm />}
        />
        <Route
          path="/rawmaterial-postAdd/:id"
          element={<RawMaterialPostAddForm />}
        />
        <Route path="/recycle-postadd/:id" element={<RecyclePostAdd />} />
        <Route path="/farming-postadd/:id" element={<FarmingPostAddForm />} />
        <Route
          path="/minerals-Minning-PostAdd/:id"
          element={<MineralMinnersPostAdd />}
        />
        <Route
          path="/useditems-postAdd/:id"
          element={<UsedItemsPostAddForm />}
        />
        <Route path="/homemade-postAdd/:id" element={<HomeMadePostAddForm />} />
        <Route path="/newitems-Postadd/:id" element={<NewItemsPostAddForm />} />
        <Route path="/homemade-update/:id" element={<HomemadeUpdate />} />
        <Route path="/new-items-update/:id" element={<Newitemsupdate />} />
        <Route path="/farming-update/:id" element={<FarmingUpdate />} />

        <Route
          path="/wholesaler-postadd/:id"
          element={<WholesalerPostAddForm />}
        />
        <Route path="/used-items-update/:id" element={<Useditemsupdate />} />
        <Route
          path="/manufacturer-update/:id"
          element={<ManufacturerUpdate />}
        />
        <Route
          path="/minerals-mining-update/:id"
          element={<MineralsAndMiningUpdate />}
        />
        <Route path="/recycle-update/:id" element={<RecycleUpdate />} />
        <Route
          path="/rawmaterial-ownerview/:shopid/:id"
          element={<RawMaterialOwnerView />}
        />
        <Route path="/userItemShop" element={<UserItemShop />} />
        <Route path="/homemadeitem" element={<HomeMadeItem />} />
        <Route path="/usershopprofile" element={<UserShopProfile />} />
        <Route path="/company-form" element={<CreateCompanyForm />} />
        <Route path="/update-company/:id" element={<UpdateCompanyForm />} />
        <Route path="/create-transport" element={<CreateTransport />} />
        <Route path="/update-transport/:id" element={<UpdateTransport />} />
        <Route path="/create-job-app" element={<CreateJobApplication />} />
        <Route path="/update-job-app/:id" element={<UpdateJobApplication />} />
        <Route path="/create-service" element={<CreateService />} />
        <Route path="/update-service/:id" element={<UpdateService />} />
        <Route
          path="/create-personal-property"
          element={<CreatePersonalProperty />}
        />
        <Route
          path="/add-personal-property"
          element={<AddPersonalProperty />}
        />
        <Route
          path="/update-personal-property"
          element={<UpdatePersonalProperty />}
        />
        <Route path="/create-business-Ad/:id" element={<PostBusinessAd />} />
        <Route
          path="/rawmaterial-business-profile/:id"
          element={<RawMaterialBusinessProfile />}
        />
        <Route
          path="/update-business-Ad/:index"
          element={<UpdateBusinessAd />}
        />
        <Route path="/home-made-profile" element={<HomeMadeProfile />} />
        <Route path="/find-job" element={<FindJobFilter />} />
        <Route
          path="/find-candidates"
          element={<AvailableCandidatesFilter />}
        />
        <Route path="/find-services" element={<ServicesFilter />} />
        <Route path="/find-property" element={<PropertyFilter />} />
        <Route path="/find-Transport" element={<TransportFilter />} />
        <Route path="/buy-Minerals" element={<MineralsMiningFilter />} />
        <Route path="/buy-new-products" element={<NewProductsFilter />} />
        <Route path="/buy-used-products" element={<UsedProductsFilter />} />
        <Route path="/buy-homemade-products" element={<HomemadeFilter />} />
        <Route path="/user-profile" element={<Userprofile />} />
        <Route path="/" element={<Login />} />
        <Route path="/view-friends" element={<Viewfriends />} />
        <Route
          path="/mineral-mining-profile"
          element={<MineralAndMiningProfile />}
        />
        <Route
          path="/manufacturer-owner-profile/:id"
          element={<ManufacturerOwnerProfile />}
        />
        <Route path="/new-item-profile" element={<NewItemProfile />} />
        <Route path="/used-item-profile" element={<UsedItemProfile />} />
        <Route path="/shop-view-model" element={<ShopViewModel />} />
        <Route path="/shopitemsowner" element={<ShopItemsOwner />} />
        <Route path="/owner-profile" element={<OwnerShopProfile />} />
        <Route path="/view-application/:id" element={<ViewApplication />} />
        <Route
          path="/homemadeownerprofile/:id"
          element={<HomeMadeOwnerProfile />}
        />
        <Route path="/company-profile/:id" element={<CompanyProfile />} />
        <Route path="/business-profile" element={<Businessprofile />} />
        <Route path="/multiple-shops-pofile" element={<MultipleShops />} />
        <Route path="/transport-profile/:id" element={<Transportprofile />} />
        <Route path="/vehicaldetail/:id/:shopid" element={<Vehicaldetail />} />
        <Route path="/businessprofile" element={<Businessprofile />} />
        <Route path="/create-job/:id" element={<CreateJobs />} />
        <Route path="/update-job/:id" element={<UpdateJobs />} />
        <Route path="/job-details/:id" element={<ViewJobDetails />} />
        <Route path="/UserShopViewModel" element={<UserShopViewModel />} />
        <Route
          path="/home-made-owner-profile/:id"
          element={<HomeMadeOwnerProfile />}
        />
        <Route path="/businessprofile" element={<Businessprofile />} />
        <Route path="/OrderHistory" element={<OrderHistory />} />
        <Route
          path="/Sale-Bussines-Property/:id"
          element={<SaleBussinesProfile />}
        />
        <Route path="/UserShopViewModel" element={<UserShopViewModel />} />
        <Route
          path="/userbuyshopviewmodel"
          element={<UserbuyShopViewModel />}
        />
        <Route
          path="/SalePerosonalProperty/:id"
          element={<SalePerosonalProperty />}
        />
        <Route
          path="/minerals-and-mining-profile/:id"
          element={<MineralAndMining />}
        />
        <Route
          path="/wholesaler-owner-profile/:id"
          element={<WholeSalerOwnerProfile />}
        />
        <Route path="/recycle-profile/:id" element={<RecycleOwnerProfile />} />
        <Route
          path="/NewItem-bussines-Profile/:id"
          element={<NewItembussinesProfile />}
        />
        <Route
          path="/manufacturer-bussines-Profile/:id"
          element={<ManufacturerBusinessProfile />}
        />
        <Route
          path="/wholsaler-bussines-Profile/:id"
          element={<WholesellerBusinessProfile />}
        />
        <Route
          path="/Used-Items-Bussines-Profile/:id"
          element={<UsedItemsBussinesProfile />}
        />
        <Route
          path="/Home-Made-Item-Userprofile/:shopid"
          element={<HomeMadeItemUserprofile />}
        />
        <Route
          path="/Minerl-And-Mining-UserProfile/:shopid"
          element={<MinerlAndMiningUserProfile />}
        />
        <Route path="/NewItems-UserProfile" element={<NewItemsUserProfile />} />
        <Route
          path="/user-peronal-property/:id"
          element={<UserPeronalProperty />}
        />
        <Route
          path="/user-bussines-property"
          element={<UserBussineProperty />}
        />
        <Route
          path="/usedItems-userprofile/:shopid"
          element={<UsedItemsUserProfile />}
        />
        <Route path="/services-profile/:id" element={<ServicesProfile />} />
        <Route path="/singletransport" element={<Singletransport />} />
        <Route path="/userTransport" element={<UserTransport />} />
        <Route path="/avaliablecandidate" element={<Avaliablecandidate />} />
        <Route path="/buy-used-product" element={<BuyUsedProducts />} />
        <Route path="/buy-new-product" element={<BuyNewProducts />} />
        <Route path="/buy-home-made" element={<HomeMadeProducts />} />
        <Route path="/buy-gems" element={<MineralAndMining />} />
        <Route path="/selectezi-helpline" element={<SelecteziHelpline />} />
        <Route path="/find-jobs-online/:id" element={<FindJobonlien />} />
        <Route
          path="/view-job-details/:id"
          element={<ViewJobOnlineDetails />}
        />
        <Route path="/post-items/:index" element={<SIngleAddPostForm />} />
        <Route path="/used-items-cards" element={<UsedItemsCards />} />
        <Route path="/used-single-addpost" element={<UsedSingleAddPost />} />
        <Route
          path="/rawmaterial-newitem/:id"
          element={<Rawmaterialnewitem />}
        />
        <Route
          path="/business-transport/:id"
          element={<BusinessProfileTransportation />}
        />
        <Route
          path="/rawmaterial-farming/:id"
          element={<RawmaterialFarming />}
        />
        <Route
          path="/rawmaterial-recycle/:id"
          element={<Businessprofilerecycle />}
        />
        <Route
          path="/rawmaterial-useditem/:id"
          element={<Rawmaterialuseditem />}
        />
        <Route
          path="/rawmaterial-mineralsandmining/:id"
          element={<MineralsRawMaterialProfile />}
        />
        <Route
          path="/rawmaterial-homemade/:id"
          element={<HomemadeRawMaterialProfile />}
        />
        <Route path="/CV-Form" element={<CVForm />} />
        <Route
          path="/services-online/:id"
          element={<ServicesOnlineProfile />}
        />
        <Route path="/business-dashboard" element={<BusinessDashboard />} />
        <Route path="/select-template" element={<SelectCVTemplate />} />
        <Route
          path="/new-item-update/:index"
          element={<UpdateSingleNewItems />}
        />
        <Route
          path="/used-item-update/:index"
          element={<UpdateSingleUsedItems />}
        />
        <Route
          path="/homemade-item-update/:index"
          element={<UpdateSingleHomeMadeItems />}
        />
        <Route
          path="/minerals-mining-item-update/:index"
          element={<UpdateSingleMineralsItems />}
        />
        <Route
          path="/recycle-item-update/:index"
          element={<RecycleItemUpdate />}
        />
        <Route
          path="/farming-item-update/:index"
          element={<FarmingItemsUpdate />}
        />
        <Route path="/sell-property-cards" element={<SellPropertyCards />} />
        <Route path="/Farming-online" element={<FarmingOnline />} />
        <Route path="/scrap-online" element={<ScrapOnline />} />
        <Route path="/recycle-online" element={<RecycleOnline />} />
        <Route path="/buy-used-cars" element={<BuyUsedCars />} />
        <Route path="/farming-profile/:id" element={<FarmingOwnerProfile />} />
        <Route
          path="/new-item-owner-view/:shopid/:id"
          element={<NewItemsOwnerView />}
        />
        <Route
          path="/farming-item-owner-view/:shopid/:id"
          element={<FarmingOwnerItemView />}
        />
        <Route
          path="/used-item-owner-view/:shopid/:id"
          element={<UsedItemsOwnerView />}
        />
        <Route
          path="/wholesaler-owner-view/:shopid/:id"
          element={<WholeSalerOwnerView />}
        />
        <Route
          path="/manufacturer-owner-view/:shopid/:id"
          element={<ManufacturerOwnerView />}
        />
        <Route
          path="/homeMade-item-owner-view/:shopid/:id"
          element={<HomeMadeItemsOwnerView />}
        />
        <Route
          path="/gems-minerals-item-owner-view/:shopid/:id"
          element={<GemsandMiningItemsOwnerView />}
        />
        <Route
          path="/recycle-item-owner-view/:shopid/:id"
          element={<RecycleItemsOwnerView />}
        />
        <Route
          path="/business-property-owner-view/:id/:id"
          element={<BusinessPropertyItemsCard />}
        />
        <Route
          path="/rawmaterial-owner-profile/:id"
          element={<RawMaterailOwnerProfile />}
        />
        <Route path="/post-vehicle/:id" element={<PostVehical />} />
        <Route path="/update-vehicle/:id" element={<UpdateVehical />} />
        <Route path="/scrape-material" element={<Scrapematerial />} />
        <Route path="/single-items" element={<Singleitems />} />
        <Route path="/personal-add" element={<PersonalAdd />} />
        <Route path="/recycle-shop" element={<RecycleShop />} />
        <Route path="/sell-vehicle" element={<SellVehicle />} />
        <Route path="/sell-single" element={<Sellsingle />} />
        <Route path="/used-vehicle" element={<Usedvehicleshop />} />
        <Route
          path="/used-vehicle-profile/:id"
          element={<Usedvehicleprofile />}
        />
        <Route
          path="/used-vehicle-businessprofile/:id"
          element={<Usedvehiclebusinessprofile />}
        />
        <Route path="/show-single-add" element={<Showadd />} />
        <Route
          path="/used-vehicle-addview/:id"
          element={<Usedvehicleviewadd />}
        />
        <Route path="/update-use-vehicle/:id" element={<Updateusevehicle />} />
        <Route path="/usedvehicle_add/:id" element={<Usedvehicleshopadd />} />
        <Route path="/vehicle-add/:id/:shopid" element={<Vehicleaddshow />} />
        <Route
          path="/usedvehicle-update/:id/"
          element={<Usedvehicleshopupdate />}
        />
        <Route path="/BuyingSide" element={<BuyingSide />} />
        <Route path="/SellingSide" element={<SellingSide />} />
        <Route path="/post-single-ad" element={<PostSingleAdd />} />
        <Route path="/buy-raw-material" element={<BuyRawMaterial />} />
        <Route path="/buy-manufacturer" element={<BuyManufacturer />} />
        <Route path="/buy-wholeseller" element={<BuyWholeSaller />} />
        <Route path="/buy-retailer" element={<BuyRetailer />} />
        <Route path="/rawmaterial-userprofile/:shopid" element={<RawItem />} />
        <Route
          path="/manufacturer-userprofile/:shopid"
          element={<ManufacturerItem />}
        />
        <Route
          path="/wholeseller-userprofile/:shopid"
          element={<WholesellerItem />}
        />
        <Route
          path="/retailer-userprofile/:shopid"
          element={<RetailerItem />}
        />
        <Route path="/raw-material-form" element={<RawMaterialForm />} />
        <Route path="/manufacturer-form" element={<ManufacturerForm />} />
        <Route path="/wholeseller-form" element={<WholesellerForm />} />
        <Route path="/retailer-form" element={<RetailerForm />} />
        {/* Emails */}
        <Route path="/home-email" element={<AllEmail />} />
        <Route path="/sent-mail" element={<SentEmail />} />
        <Route path="/important-mail" element={<ImportantEmails />} />
        <Route path="/inbox" element={<Inbox />} />
        <Route path="/email-outbox" element={<Outbox />} />
        <Route path="/home-page-trash" element={<HomePageTrash />} />
        <Route path="/email-draft" element={<EmailDraft />} />
        <Route path="/email-spam" element={<EmailSpam />} />
        {/* ////// */}
        {/* B2B Buying Side*/}
        <Route path="/all-inquiry-buying" element={<AllInquiryBuying />} />
        <Route path="/quotation-buying" element={<QuotationBuying />} />
        <Route
          path="/purchase-order-buying"
          element={<AllPurchaseOrderBuying />}
        />
        <Route path="/invoice-buying" element={<InvoiceBuying />} />
        <Route path="/trash-buying" element={<TrashBuying />} />
        {/* ///// */}
        {/* B2B Selling side */}
        <Route path="/inquiry-selling" element={<AllInquirySelling />} />
        <Route path="/quotation-selling" element={<QuotationSelling />} />
        <Route
          path="/purchase-order-selling"
          element={<AllPurchaseOrderSelling />}
        />
        <Route path="/invoice-selling" element={<InvoiceSelling />} />
        <Route path="/trash-selling" element={<TrashSelling />} />
        {/* ///// */}
        <Route path="/chatroom-profile" element={<Chatroomform />} />
        <Route
          path="/update-chatroom-profile"
          element={<UpdateChatroomform />}
        />
        <Route path="/my-chart" element={<Mychart />} />
        <Route path="/create-chart-room" element={<Createchatroom />} />
        <Route path="/live-chart-room" element={<Livechatroom />} />
        <Route path="/join-lobby" element={<JoinLobby />} />
        <Route path="/chatroom-streamline" element={<Chatroomstreamline />} />
        <Route path="/joinown-chatroom" element={<Joinownchatroom />} />
        <Route path="/lobby-chatroom" element={<Lobbychatroom />} />
        <Route path="/go-live" element={<GoLive />} />
        <Route path="/chat-room-modal" element={<Chatroommodal />} />
        <Route path="/user-live-chatroom" element={<UserVeiwChatRoom />} />
        <Route path="/invite-people" element={<InvitePeople />} />
        <Route path="/own-chatroom" element={<Ownchatroom />} />
        <Route path="/Hamburger" element={<Hamburger />} />
        <Route path="/GoogleMeet" element={<GoogleMeet />} />
        <Route path="/profile-setting" element={<Profilesetting />} />
        <Route path="/shop-setting/:shopid" element={<Mainsetting />} />
        <Route path="/compose-mail" element={<Mailsinput />} />
        <Route path="/Singleitemmerge" element={<Singleitemmerge />} />
        <Route path="/promote-shop/:shopid" element={<Promotionmerge />} />
        <Route path="/homemade-items-shops" element={<HomeMadeShops />} />
        <Route
          path="/minerals-mining-shops"
          element={<MineralAndMiningShops />}
        />
        <Route path="/job-companies" element={<JobsCompanies />} />
        <Route path="/Emailmodal" element={<Emailmodal />} />
        <Route path="/Nodata" element={<Nodata />} />
        <Route path="/Propertybusiness/:id" element={<Propertybusiness />} />
        <Route path="Transportbusiness" element={<Transportbusiness />} />
        <Route path="Byvehicleuserprofile" element={<Byvehicleuserprofile />} />
        <Route path="Recycleuserprofile" element={<Recycleuserprofile />} />
        <Route path="Farminguserprofile" element={<Farminguserprofile />} />
        <Route path="/new-items-shops" element={<NewItemsShops />} />
        <Route path="/used-items-shops" element={<UsedItemsShops />} />
        <Route path="/business-properties" element={<BusinessProperties />} />
        <Route path="/recycle-shops" element={<RecycleShops />} />
        <Route path="/farming-shops" element={<FarmingShops />} />
        <Route path="/ShopManage" element={<ShopManage />} />
        <Route path="/RetailerShop" element={<RetailerShop />} />
        <Route path="/WholesalerShop" element={<WholesalerShop />} />
        <Route path="/ManufacturerShop" element={<ManufacturerShop />} />
        <Route path="/RawmaterialShop" element={<RawmaterialShop />} />
        <Route path="/no-match" element={<NoMatch />} />
        <Route path="/ReportSelectezi" element={<ReportSelectezi />} />
        <Route
          path="/manufacturer-item-update/:index"
          element={<ManufacturerUpdatePostAddForm />}
        />
        <Route
          path="/wholeseller-item-update/:index"
          element={<WholesellerUpdatePostAddForm />}
        />
        <Route path="/no-match" element={<NoMatch />} />
        <Route
          path="/raw-item-update/:index"
          element={<RawMaterialUpdatePostAddForm />}
        />

        <Route path="/Searchicon" element={<Searchicon />} />
        <Route path="/BuyerOne" element={<BuyerOne />} />
        <Route path="/SellerOne" element={<SellerOne />} />
        <Route path="/Frequentlyquestions" element={<Frequentlyquestions />} />
        <Route path="/Chatonline" element={<Chatonline />} />
        <Route path="/Requestcall" element={<Requestcall />} />
        <Route path="/Customermerge" element={<Customermerge />} />
        <Route path="/AccordionBuyerside" element={<AccordionBuyerside />} />
        <Route
          path="/buying-transport/:shopid"
          element={<Buyingtransportprofile />}
        />
        <Route path="/ModalEmailone" element={<ModalEmailone />} />
        <Route path="/RemoveEmailone" element={<RemoveEmailone />} />
        <Route path="/OrderSumary" element={<OrderSumary />} />
        <Route path="/VerificationModal" element={<VerificationModal />} />
        <Route path="/Searchicon" element={<Searchicon />} />
        <Route path="/BuyerOne" element={<BuyerOne />} />
        <Route path="/SellerOne" element={<SellerOne />} />
        <Route path="/Frequentlyquestions" element={<Frequentlyquestions />} />
        <Route path="/Chatonline" element={<Chatonline />} />
        <Route path="/Requestcall" element={<Requestcall />} />
        <Route path="/customer-support" element={<Customermerge />} />
        <Route path="/AccordionBuyerside" element={<AccordionBuyerside />} />
        <Route path="/usedvehicle-shops" element={<Usedvehicleshops />} />
        <Route path="/services-shops" element={<ServicesShops />} />

        <Route path="/single-item-buy" element={<SingleItemBuy />} />
        <Route path="/ModalEmailone" element={<ModalEmailone />} />
        <Route path="/RemoveEmailone" element={<RemoveEmailone />} />
        <Route path="/Suggestionmodal" element={<Suggestionmodal />} />
        <Route path="/Suggestion" element={<Suggestion />} />
        <Route path="/Report" element={<Report />} />
        <Route path="/Reportmodal" element={<Reportmodal />} />

        <Route path="/CandidatesBox" element={<CandidatesBox />} />
        <Route
          path="/service-business-profile/:id"
          element={<Servicebusinessprofile />}
        />
        <Route path="/Foodrestaurant" element={<Foodrestaurant />} />
        <Route path="/DeliveryForm" element={<DeliveryForm />} />

        <Route path="/Updatefoodform" element={<Updatefoodform />} />
        <Route path="/CompanycreateShop/:id" element={<CompanycreateShop />} />
        <Route
          path="/retailer-integrate-form"
          element={<RetailerIntegrateform />}
        />
        <Route path="/CandidatesBox" element={<CandidatesBox />} />
        <Route
          path="/Byvehicleuserprofile/:shopid"
          element={<Byvehicleuserprofile />}
        />
        <Route
          path="/Recycleuserprofile/:shopid"
          element={<Recycleuserprofile />}
        />
        <Route
          path="/Farminguserprofile/:shopid"
          element={<Farminguserprofile />}
        />
        <Route path="/Foodrestaurant" element={<Foodrestaurant />} />
        <Route path="/DeliveryForm" element={<DeliveryForm />} />
        <Route path="/Updatefoodform" element={<Updatefoodform />} />
        <Route path="/CompanycreateShop/:id" element={<CompanycreateShop />} />
        <Route
          path="/retailer-integrate-form"
          element={<RetailerIntegrateform />}
        />
        <Route path="/retailer-integrate" element={<Retailerintegrate />} />
        <Route
          path="/wholeseller-integrate-form"
          element={<WholesellerIntegrateform />}
        />
        <Route
          path="/wholeseller-integrate"
          element={<Wholesellerintegrate />}
        />
        <Route
          path="/manufacturer-integrate"
          element={<Manufacturerintegrate />}
        />
        <Route
          path="/manufacturer-integrate-form"
          element={<ManufacturerIntegrateform />}
        />

        <Route path="/raw-integrate-form" element={<RawIntegrateform />} />
        <Route path="/raw-integrate" element={<Rawintegrate />} />
        <Route path="/gems-integrate" element={<Gemsintegrate />} />
        <Route path="/gems-integrate-form" element={<GemsIntegrateform />} />

        <Route
          path="/useditems-integrate-form"
          element={<UseditemIntegrateform />}
        />
        <Route path="/useditems-integrate" element={<Useditemintegrate />} />

        <Route
          path="/homemade-integrate-form"
          element={<HomemadeIntegrateform />}
        />
        <Route path="/homemade-integrate" element={<Homemadeintegrate />} />
        <Route path="/property-integrate" element={<Propertyintegrate />} />
        <Route
          path="/property-integrate-form"
          element={<PropertyIntegrateform />}
        />
        <Route
          path="/usedvehicle-integrate-form"
          element={<UsedvehicleIntegrateform />}
        />
        <Route
          path="/usedvehicle-integrate"
          element={<Usedvehicleintegrate />}
        />
        <Route path="/transport-integrate" element={<Transportintegrate />} />
        <Route
          path="/transport-integrate-form"
          element={<TransportIntegrateform />}
        />
        <Route
          path="/recycled-integrate-form"
          element={<RecycledIntegrateform />}
        />
        <Route path="/recycled-integrate" element={<Recycledintegrate />} />
        <Route
          path="/CreateRestaurantProfile/:id"
          element={<CreateRestaurantProfile />}
        />
        <Route
          path="/RestaurantShopdata/:id"
          element={<RestaurantShopdata />}
        />
        <Route path="/RestaurantCard" element={<RestaurantCard />} />
        <Route path="/RestaurantAlldata/:id" element={<RestaurantAlldata />} />
        <Route
          path="/farming-integrate-form"
          element={<FarmingIntegrateform />}
        />
        <Route path="/farming-integrate" element={<Farmingintegrate />} />
        <Route path="/FoodRestaurantComp" element={<FoodRestaurantComp />} />
        <Route path="/BuyRestaurantFood" element={<BuyRestaurantFood />} />
        <Route path="/singleadd-show" element={<Singleitemview />} />
        <Route path="/singleitem-show/:id" element={<SingleItemOwnerView />} />
        <Route path="/single-item-update/:id" element={<UpdateSingleAdd />} />
        <Route path="/connect-modal" element={<Connectmodal />} />
        <Route path="/connection-request/:id" element={<Connectionrequest />} />
        <Route path="/restaurant-shop-form" element={<RestaurantshopForm />} />
        <Route path="/connection-request" element={<Connectionrequest />} />
        <Route path="/restaurant-integrate" element={<Restaurantintegrate />} />
        <Route
          path="/restaurant-integrate-form"
          element={<Restaurantintegrateform />}
        />
        <Route path="/scrap-item-show" element={<Scrapitemview />} />
        <Route path="/update-scrap-item/:id" element={<UpdateScrapAdd />} />
        <Route path="/scrap-single-item/:id" element={<ScrapItemOwnerView />} />

        <Route
          path="/resturent-post-form/:id"
          element={<ResturentPostform />}
        />
        <Route
          path="/company-business-Profile/:id"
          element={<CompanyBusinessProfile />}
        />
        <Route
          path="/resturent-update-form/:id"
          element={<ResturentUpdateform />}
        />
        <Route path="/food-suggestion" element={<FoodsuggestionSection />} />

        <Route
          path="/transport-suggestion/:id"
          element={<TransportsuggestionSection />}
        />

        <Route
          path="/service-suggestion/:id"
          element={<ServicesuggestionSection />}
        />

        <Route path="/service-report" element={<Servicereport />} />
        <Route path="/promote-service" element={<ServicePromotion />} />
        <Route path="/promote-transport" element={<TransportPromotion />} />
        <Route
          path="/food-buying-profile/:shopid"
          element={<Foodandrestaurantbuy />}
        />
        <Route
          path="/food-business-dashboard"
          element={<FoodResturentBusiness />}
        />
        <Route path="/food-item-view/:shopid/:id" element={<FoodItemsView />} />
        <Route
          path="/restaurant-update/:id"
          element={<RestaurantshopUpdateForm />}
        />
        <Route path="/usedvehicle-setting/:shopid" element={<Mainsetting />} />
        <Route path="/company-setting/:shopid" element={<Mainsetting />} />
        <Route path="/resturant-setting/:shopid" element={<Mainsetting />} />
        <Route path="/service-integrate" element={<Serviceintegrate />} />
        <Route
          path="/service-integrate-form"
          element={<ServiceIntegrateform />}
        />
        <Route
          path="/update-bussiness-profile/:index"
          element={<UpdateBussinessPro />}
        />
        <Route path="/user-dashboard" element={<UserDashboard />} />
        <Route path="/buy-single-property" element={<BuySingleProperty />} />
        <Route
          path="/transport-business-dashboard"
          element={<Transportbusinessdashboard />}
        />

        <Route
          path="/transport-bussiness-profile/:id"
          element={<TransportBussinesProfile />}
        />

        <Route path="/other-profile" element={<Otheruserprofile />} />
        <Route path="/view-business-friend/:id" element={<Viewbusinessfriends />} />

        <Route
          path="/single-property-addview/:id"
          element={<SinglePropertyItemsCard />}
        />
        <Route
          path="/view-buy-application/:id"
          element={<ViewbuyApplication />}
        />
        <Route path="/view-candidate-cv/:id" element={<CVTemplatesbuyside />} />

      </Routes>
    </>
  );
}
export default Routing;
