import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { images } from "../../../constants";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import { salepromotion, saleoffer } from "../../../constants/jsonConstant";
import "./style.css";
const BuyingFilter = ({
  createshopcategory,
  shopCategory,
  Handledata,
  shopCategorytwo,
  Mydata,
  Handleadditioanalshop,
  FilterHandler,
  Mydataone,
  Handlescrapdata,
  scrapcategory,
  scrapcategorytwo,
  createscrapcategory,
  FilterHandlerscrap,
  FilterHandleritem,
}) => {
  const location = useLocation();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedstateone, setSelectedstateone] = useState("");
  const [searchTermone, setSearchTermone] = useState("");
  const [selectedcity, setSelectedcity] = useState("");
  const [searchTermtwo, setSearchTermtwo] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  //Function to handle the country selection
  const handleCountryChange = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
  };
  //
  const handleSearchChangestate = (event) => {
    setSearchTermone(event.target.value);
  };

  const handleSearchChangecity = (event) => {
    setSearchTermtwo(event.target.value);
  };

  // Function to handle the state selection
  const handleCountryChangestate = (event) => {
    const { value } = event.target;
    setSelectedstateone(value);
  };

  // Function to handle the city selection
  const handleCountryChangecity = (event) => {
    const { value } = event.target;
    setSelectedcity(value);
  };
  const [hidefield, setHidefield] = useState(1);
  const [hidestate, setHidestate] = useState(1);
  const [hidecity, setHidecity] = useState(1);
  const [countrydata, SetCountrydata] = useState([]);
  const [getcountry] = useState();
  const [selectedState] = useState();
  const [getState, setState] = useState([]);
  const [getcity, setGetcity] = useState([]);
  const [statetwo, setStatetwo] = useState(getcountry);
  const [city, setCity] = useState(selectedState);
  const [open, setOpen] = useState(getcountry);
  const [selectedSegment, setSelectedSegment] = useState("custom");
  const handleSegmentChange = (value) => {
    setSelectedSegment(value);
  };
  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => SetCountrydata(res.data))

      .catch((error) => {});
  }, []);
  const country = [...new Set(countrydata.map((item) => item.country))];
  country.sort();
  const handleCountry = (e) => {
    let states = countrydata.filter(
      (state) => state.country === e.target.value
    );
    states = [...new Set(states.map((item) => item.subcountry))];
    states.sort();
    setState(states);
  };
  const handleState = (e) => {
    let cities = countrydata.filter(
      (city) => city.subcountry === e.target.value
    );
    setGetcity(cities);
  };
  return (
    <div className=" height_100">
      <div className="d-flex bg-primary-one pt-1 pb-1 ps-3 ">
        <div>
          <img className="w-imgone" src={images.WorldSearchtwo} alt="web" />
        </div>
        <div>
          <h6 className="titleText pt-1 ps-2 mb-0">
            Search Category, Area & Desired Location
          </h6>
        </div>
      </div>
      {location.pathname === "/single-item-buy" ||
      location.pathname === "/scrap-online" ? (
        ""
      ) : (
        <div className=" ps-xl-3 pe-xl-3 ps-lg-1 pe-lg-1 ps-md-2 pe-md-2 ps-sm-2 pe-sm-2">
          <div className="segment-container mt-2">
            <button
              className={`segment-button pt-1 pb-1 ${
                selectedSegment === "custom" ? "active" : ""
              }`}
              onClick={() => handleSegmentChange("custom")}
            >
              Search by item details
            </button>
            <button
              className={`segment-button ${
                selectedSegment === "segment" ? "active" : ""
              }`}
              onClick={() => handleSegmentChange("segment")}
            >
              Search by shop details
            </button>
          </div>
        </div>
      )}
      {selectedSegment === "custom" ? (
        <div className=" ps-3 pe-3 pt-1 pb-1">
          {location.pathname === "/buy-used-cars" ? (
            <>
              <Form.Label className="custm-lble">Brand Name</Form.Label>
              <Form.Control
                className="custom-fix-one"
                placeholder="Brand Name"
                name="brandName"
                onChange={(e) => {
                  Handleadditioanalshop(e);
                }}
              />
              <Form.Label className="custm-lble">Model Year</Form.Label>
              <Form.Control
                className="custom-fix-one"
                placeholder="Year"
                name="modelYear"
                onChange={(e) => {
                  Handleadditioanalshop(e);
                }}
              />
            </>
          ) : (
            <>
              <div>
                <Form.Label className="custm-lble">
                  {location.pathname === "/BuyRestaurantFood"
                    ? "Search by Dish Name"
                    : "Search by Item Name"}
                </Form.Label>
                <Form.Control
                  className="custom-fix-one"
                  placeholder="Item Name"
                  name={
                    location.pathname === "/BuyRestaurantFood"
                      ? "dishname"
                      : "itemname"
                  }
                  onChange={(e) => {
                    Handleadditioanalshop(e);
                  }}
                />
              </div>

              {location.pathname === "/buy-raw-material" ||
              location.pathname === "/recycle-online" ||
              location.pathname === "/buy-used-cars" || location.pathname === "/BuyRestaurantFood" ? (
                ""
              ) : (
                <>
                  <Form.Label className="custm-lble">
                    Search By Brand Name
                  </Form.Label>
                  <Form.Control
                    className="custom-fix-one"
                    placeholder="Brand Name"
                    name="brandName"
                    onChange={(e) => {
                      Handleadditioanalshop(e);
                    }}
                  />{" "}
                </>
              )}
            </>
          )}
          {location.pathname === "/buy-used-cars" ||
          location.pathname === "/scrap-online" ? (
            ""
          ) : (
            <>
              <div>
                <Form.Label className="custm-lble">
                  Search by Category
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="categoryid"
                  className="custom-fix-one form-control"
                  onChange={(e) => {
                    Handledata(e);
                    Handleadditioanalshop(e);
                  }}
                >
                  <option>Category</option>
                  {shopCategory?.map((category) => (
                    <>
                      <option
                        key={category._id}
                        value={`${category?._id}.${category?.name}`}
                      >
                        {category?.name}
                      </option>
                    </>
                  ))}
                </Form.Select>
              </div>
              <div>
                <Form.Label className="custm-lble">
                  Search by Sub-Category
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="subCategoryid"
                  className="custom-fix-one form-control"
                  onChange={(e) => {
                    Handledata(e);
                    Handleadditioanalshop(e);
                  }}
                  onClick={Mydata}
                >
                  <option>Select Sub Category</option>

                  {shopCategorytwo?.map((subcategory) => (
                    <>
                      <option key={subcategory._id} value={subcategory?._id}>
                        {subcategory?.name}
                      </option>
                    </>
                  ))}
                </Form.Select>
              </div>

              {
              location.pathname === "/buy-retailer" ||
              location.pathname === "/buy-Minerals" ||
              location.pathname === "/buy-used-products" ||
              location.pathname === "/Farming-online" ||
              location.pathname === "/BuyRestaurantFood" ||
              location.pathname === "/buy-homemade-products" ? (
                <>
                  <Form.Label className="custm-lble">Item With Sale</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="sale"
                    className="custom-fix-one form-control"
                    onChange={(e) => {
                      Handleadditioanalshop(e);
                    }}
                  >
                    <option disabled selected>
                      Sale
                    </option>

                    {salepromotion.map((e) => (
                      <option>{e.label}</option>
                    ))}
                  </Form.Select>

                  <Form.Label className="custm-lble">
                    Item With Special Offers
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="specialOffer"
                    className="custom-fix-one form-control"
                    onChange={(e) => {
                      Handledata(e);
                      Handleadditioanalshop(e);
                    }}
                  >
                    <option disabled selected>
                      Special Offer
                    </option>
                    {saleoffer.map((e) => (
                      <option>{e.label}</option>
                    ))}
                  </Form.Select>
                </>
              ) : (
                ""
              )}
            </>
          )}

          <div className="col-md-12">
            <label
              htmlFor="inputPassword4"
              placeholder="First name"
              className="form-label labelSize"
            >
              Country*
            </label>
            <div className="blood-type custom-fix-one">
              <div className="d-flex">
                <div className="w-100">
                  <input
                    type="search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search country here...."
                    className="w-100 search-input"
                    onClick={() => setHidefield(0)}
                  />
                </div>
                <div>
                  <span>
                    <SearchIcon className="search-icon-fs" />
                  </span>
                </div>
              </div>

              <select
                onClick={() => setHidefield(1)}
                value={selectedCountry}
                name="country"
                onChange={(e) => {
                  handleCountryChange(e);
                  handleCountry(e);
                  Handleadditioanalshop(e);
                }}
                className="b-radius selectSize w-100"
              >
                {hidefield && <option>Select country</option>}

                {country
                  .filter((country) =>
                    country.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .map((country, index) => (
                    <option key={country} value={getcountry}>
                      {country}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <label
              htmlFor="inputPassword4"
              placeholder="First name"
              className="form-label labelSize"
            >
              State*
            </label>
            <div className="blood-type custom-fix-one">
              <div className="d-flex">
                <div className="w-100">
                  <input
                    type="search"
                    value={searchTermone}
                    onChange={handleSearchChangestate}
                    placeholder="Search state here...."
                    className="w-100 search-input"
                    onClick={() => setHidestate(0)}
                  />
                </div>
                <div>
                  <span>
                    <SearchIcon className="search-icon-fs" />
                  </span>
                </div>
              </div>

              <select
                onClick={() => setHidestate(1)}
                className="b-radius selectSize w-100"
                value={selectedstateone}
                aria-label="Default select example "
                name="state"
                onChange={(e) => {
                  handleState(e);
                  handleCountryChangestate(e);
                  Handleadditioanalshop(e);
                }}
              >
                {hidestate && <option>Select state</option>}

                {getState
                  .filter((country) =>
                    country.toLowerCase().includes(searchTermone.toLowerCase())
                  )
                  .map((country) => (
                    <option key={country} value={selectedState}>
                      {country}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <label
              htmlFor="inputPassword4"
              placeholder="First name"
              className="form-label labelSize"
            >
              City
            </label>
            <div className="blood-type custom-fix-one">
              <div className="d-flex">
                <div className="w-100">
                  <input
                    type="search"
                    value={searchTermtwo}
                    onChange={handleSearchChangecity}
                    placeholder="Search city here...."
                    className="w-100 search-input"
                    onClick={() => setHidecity(0)}
                  />
                </div>
                <div>
                  <span>
                    <SearchIcon className="search-icon-fs" />
                  </span>
                </div>
              </div>
              <select
                onClick={() => setHidecity(1)}
                value={selectedcity}
                className="b-radius selectSize w-100"
                aria-label="Default select example"
                name="city"
                onChange={(e) => {
                  handleCountryChangecity(e);
                  Handleadditioanalshop(e);
                }}
              >
                {hidecity && <option>Select city</option>}

                {getcity
                  .filter((country) =>
                    country.name
                      .toLowerCase()
                      .includes(searchTermtwo.toLowerCase())
                  )
                  .map((country) => (
                    <option key={country.name}>{country.name}</option>
                  ))}
              </select>
            </div>
          </div>

          <div className="">
            <Form.Label className="custm-lble pt-2 mb-0">
              Price Filter
            </Form.Label>
            <Row className="d-flex">
              <Col xl={3} lg={4} md={2} sm={2} xs={3} className="d-flex">
                <Form.Label className="custm-lble pt-1 pe-1">Min</Form.Label>
                <div className="inputSetting">
                  <input
                    placeholder=""
                    type="number"
                    name="min"
                    onChange={(e) => Handleadditioanalshop(e)}
                  />
                </div>
              </Col>
              <Col xl={3} lg={4} md={2} sm={2} xs={3} className="d-flex">
                <Form.Label className="custm-lble pt-1 pe-1">Max</Form.Label>
                <div className="inputSetting">
                  <input
                    placeholder=""
                    type="number"
                    name="max"
                    onChange={(e) => Handleadditioanalshop(e)}
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div className=" pt-1 d-flex justify-content-center ">
            <button
              className="btn-lg bg-blue text-white"
              onClick={FilterHandleritem}
            >
              Search
            </button>
          </div>
          <div></div>
        </div>
      ) : (
        <div className=" ps-3 pe-3 pt-1 pb-1">
          <div>
            {location.pathname === "/single-item-buy" ||
            location.pathname === "/scrap-online" ? (
              ""
            ) : (
              <>
                <div>
                  <Form.Label className="custm-lble">
                    Search by Shop Name
                  </Form.Label>
                  <Form.Control
                    className="custom-fix-one"
                    placeholder="Shop Name"
                    name="shopname"
                    onChange={(e) => Handleadditioanalshop(e)}
                  />
                </div>
              </>
            )}
          </div>

          <div>
            <Form.Label className="custm-lble">Search by Shop ID</Form.Label>
            <Form.Control
              className="custom-fix-one"
              placeholder="Search by Shop ID"
              name="shopid"
              onChange={(e) => Handleadditioanalshop(e)}
            />
          </div>

          {location.pathname === "/BuyRestaurantFood" ? (
            ""
          ) : (
            <div>
              <Form.Label className="custm-lble">
                Search by Shop Category
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="custom-fix-one"
                name="shopCategory"
                onChange={(e) => {
                  Handleadditioanalshop(e);
                }}
              >
                <option>Shop Category</option>
                {createshopcategory?.map((category) => (
                  <>
                    <option key={category._id} value={category?._id}>
                      {category?.name}
                    </option>
                  </>
                ))}
              </Form.Select>
            </div>
          )}
          {location.pathname === "/buy-retailer" ||
          location.pathname === "/buy-Minerals" ||
          location.pathname === "/buy-homemade-products" ||
          location.pathname === "/buy-used-products" ||
          location.pathname === "/recycle-online" ||
          location.pathname === "/Farming-online" ||
          location.pathname === "/BuyRestaurantFood" ? (
            <>
              <Form.Label className="custm-lble">Shops With Sale</Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="sale"
                className="custom-fix-one form-control"
                onChange={(e) => {
                  Handleadditioanalshop(e);
                }}
              >
                <option disabled selected>
                  Sale
                </option>

                {salepromotion.map((e) => (
                  <option>{e.label}</option>
                ))}
              </Form.Select>

              <Form.Label className="custm-lble">
                Shops With Special Offers
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="specialOffer"
                className="custom-fix-one form-control"
                onChange={(e) => {
                  Handledata(e);
                  Handleadditioanalshop(e);
                }}
              >
                <option disabled selected>
                  Special Offer
                </option>
                {saleoffer.map((e) => (
                  <option>{e.label}</option>
                ))}
              </Form.Select>
            </>
          ) : (
            ""
          )}

          {location.pathname === "/scrap-online" ||
          location.pathname === "/single-item-buy" ? (
            ""
          ) : (
            <div>
              <Form.Label className="custm-lble">Market Place</Form.Label>
              <Form.Control
                className="custom-fix-one"
                placeholder="Market Place"
                name="marketPlace"
                onChange={(e) => {
                  Handleadditioanalshop(e);
                }}
              />
            </div>
          )}

          <div className="pt-3 d-flex justify-content-center">
            <button
              className="btn-lg bg-blue text-white"
              onClick={FilterHandler}
            >
              Search
            </button>
          </div>
          <div></div>
        </div>
      )}
    </div>
  );
};
export default BuyingFilter;
