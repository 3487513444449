import React from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { Restaurent } from "../../../../constants/jsonConstant";
import Rating from "@mui/material/Rating";
import { useState, useEffect } from "react";
import "./style.css";
import AvailabilityModel from "../../../servicesProfile/components/Avalibilitymodel";
import Updatefoodform from "../../../Updatefoodform";
import { useNavigate } from "react-router-dom";
import url from "../../../../config/axios";
import Suggestionmodal from "../../../suggestionmodal";
import Suggestion from "../../../suggestion";
import ReportBox from "../../../../components/Reportbox";
import { getAsset } from "../../../../helpers/helper";
const RestaurantCard = ({
  id,
  suggest,
  suggestdata,
  setSuggest,
  shopData,
  shopid,
}) => {
  const [key, setKey] = useState("home");
  const [itemdata, setItemdata] = useState();
  const [saledata,setSaledata]=useState();
  const [specialdata,setSpecialdata]=useState();
  const handleShow = () => setShowavaliable(true);
  const [showavaliable, setShowavaliable] = useState(false);
  const [suggestId, setSuggestId] = useState();
  const navigate = useNavigate();
  localStorage.setItem("suggestid", suggestId);


  const handleSelect = (k) => {
    if (k === "tab1") {
      handleShow();
      setKey("Connents");
    } else if (k === "AddMore") {
      navigate(`/resturent-post-form/${id}`);
    } else {
    }
  };
  const [Open, Showopen] = useState(false);
  const handleClose = () => setShowavaliable(false);
  const openModal = () => {
    Showopen(true);
  };

  useEffect(() => {
    url.get(`/fooditem/?shopid=${id}`).then(async (res) => {
      console.log("adasdasmy--dataa",res)
      setItemdata(res?.data?.regular);
      setSpecialdata(res?.data?.specialOffers);
      setSaledata(res?.data?.salespromotion);    
    });
  }, []);
  const suggestsingleid = localStorage.getItem("suggestid");
  return (
    <>
      <AvailabilityModel
        handleClose={handleClose}
        showavaliable={showavaliable}
        setShowavaliable={setShowavaliable}
      />

      {Open && <Updatefoodform Showopen={Showopen} Open={Open} />}

      <Col lg="12 mt-4">
        {suggest === 1 ? (
          <Suggestionmodal
            setSuggestId={setSuggestId}
            setSuggest={setSuggest}
            suggest={suggest}
            suggestdata={suggestdata}
            shopid={shopid}
            shopData={shopData}
          />
        ) : suggest === 2 ? (
          <ReportBox setSuggest={setSuggest} suggest={suggest} />
        ) : suggest === 3 ? (
          <Suggestion
            suggestsingleid={suggestsingleid}
            suggestdata={suggestdata}
            shopData={shopData}
            setSuggest={setSuggest}
          />
        ) : (
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => {
              setKey(k);
              handleSelect(k);
            }}
            className="mb-3 ms-1 adddmoree groundset"
          >
            <Tab
              eventKey="home"
              title="Regular Items"
              className="resturent-height noscroll"
            >
              {itemdata?.map((box) => (
                <Row className="mt-1 border_3 p-0 m-0">
                  <Col className="ps-0" lg="4" xl="4">
                    <div className="imagesetttttt">
                      <img className="food-img" src={getAsset(box.images)} alt="" crossOrigin="anonymous" />
                    </div>
                  </Col>
                  <Col className="" lg="8" xl="8">
                    <div className="row">
                      <div className="col-3 p-0">
                        <h6 className="ms-3 mt-4">{box.dishname}</h6>
                      </div>
                      <div className="col-6 p-0">
                        <h6 className="mt-4">
                          {" "}
                          Prepration Time: {box.preparationTime} Min
                        </h6>
                      </div>

                      <div className="col-3 p-0 text-center">
                        <button
                          class="mt-4 connect-btn mt-1 w-50"
                          onClick={() =>
                            navigate(`/food-item-view/${id}/${box._id}`)
                          }
                        >
                          View
                        </button>
                      </div>
                    </div>
                    <h6 className="ms-1 mt-2">Description</h6>
                    <p className="ms-1 mt-2 font_14">{box.description}</p>

                    <div className="d-flex justify-content-end">
                      <h6>Rating</h6>
                      <Rating name="read-only" readOnly />
                    </div>
                  </Col>
                </Row>
              ))}
            </Tab>

            <Tab
              eventKey="saleitems"
              title="Sales Promotion"
              className="resturent-height noscroll"
            >
              {saledata?.map((box) => (
                <Row className="mt-1 border_3 p-0 m-0">
                  <Col className="ps-0" lg="4" xl="4">
                    <div className="position-relative">
                      <div className="salestwo ps-2 pe-2">{box?.salesPromotion}</div>
                      <img className="food-img" src={getAsset(box.images)} alt="" crossOrigin="anonymous" />
                    </div>
                  </Col>
                  <Col className="" lg="8" xl="8">
                    <div className="row">
                      <div className="col-3 p-0">
                      <h6 className="ms-3 mt-4">{box.dishname}</h6>
                      </div>
                      <div className="col-6 p-0">
                      Prepration Time: {box.preparationTime} Min
                      </div>

                      <div className="col-3 p-0 text-center">
                        <button
                          class="mt-4 connect-btn mt-1 w-50"
                          onClick={() =>
                            navigate(`/food-item-view/${id}/${box._id}`)
                          }
                        >
                          View
                        </button>
                      </div>
                    </div>
                    <h6 className="ms-1 mt-2">Description</h6>
                    <p className="ms-1 mt-2 font_14">{box.description}</p>
                    <div className="d-flex justify-content-end">
                      <h6>Rating</h6>
                      <Rating name="read-only" readOnly />
                    </div>
                  </Col>
                </Row>
              ))}
            </Tab>

            <Tab
              eventKey="specialitems"
              title="Special Offers"
              className="resturent-height noscroll"
            >
              {specialdata?.map((box) => (
                <Row className="mt-1 border_3 p-0 m-0">
                  <Col className="ps-0" lg="4" xl="4">
                    <div className="position-relative">
                      <div className="sales ps-2 pe-2">{box?.specialOffers}</div>
                      <img className="food-img" src={getAsset(box.images)} alt="" crossOrigin="anonymous" />
                    </div>
                  </Col>
                  <Col className="" lg="8" xl="8">
                    <div className="row">
                      <div className="col-3 p-0">
                      <h6 className="ms-3 mt-4">{box.dishname}</h6>
                      </div>
                      <div className="col-6 p-0">
                      Prepration Time: {box.preparationTime} Min
                      </div>

                      <div className="col-3 p-0 text-center">
                        <button
                          class="mt-4 connect-btn mt-1 w-50"
                          onClick={() =>
                            navigate(`/food-item-view/${id}/${box._id}`)
                          }
                        >
                          View
                        </button>
                      </div>
                    </div>
                    <h6 className="ms-1 mt-2">Description</h6>
                    <p className="ms-1 mt-2 font_14">{box.description}</p>
                    <div className="d-flex justify-content-end">
                      <h6>Rating</h6>
                      <Rating name="read-only" readOnly />
                    </div>
                  </Col>
                </Row>
              ))}
            </Tab>
            <Tab
              eventKey="AddMore"
              title="&#10133; Add More"
              className="resturent-height noscroll "
              onSelect={(k) => handleSelect(k)}
            ></Tab>
          </Tabs>
        )}
      </Col>
    </>
  );
};

export default RestaurantCard;
