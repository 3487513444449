import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './style.css'

const PurchaseTable = () => {
    return (
        <div>

            <Container>

                <div>

                <Row>

                    <Col className='pe-5' lg="7" md="6" sm="6">


                        <div className="mt-1 fw-bold Description ">

                            <p className=' purchase-terms ps-2 pt-2 pb-2 mb-0 '>Terms And Condition</p>
                            <textarea
                                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis nisi leo, eu aliquet nisl ornare eget. Praesent in urna quis massa gravida cursus nec vel libero Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis nisi leo, eu aliquet nisl ornare eget. Praesent in urna quis massa gravida cursus nec vel libero "
                                className="inputarea pt-0 pb-0"

                            />
                        </div>

                    </Col>



                    <Col className='ps-3' lg="5" md="6" sm="6" >


                        <p className='pay text-center'> Amount Due </p>

                        <table class="  table table-bordered">
                            <tbody className='tablebody'>

                                <tr>
                                    <td className='ps-3 pt-0 pb-1 hgt'>Subtotal</td>
                                    <td className='ps-3 pt-0 pb-1 hgt'>500</td>

                                </tr>

                                <tr>
                                    <td className='ps-3 pt-0 pb-1 hgt'>Tax 10%</td>
                                    <td className='ps-3 pt-0 pb-1 hgt'>100</td>
                                </tr>
                                <tr>
                                    <td className='ps-3 pt-0 pb-1 hgt'>Delivery</td>
                                    <td className='ps-3 pt-0 pb-1 hgt'>600</td>

                                </tr>
                                <tr>
                                    <td className='ps-3 pt-0 pb-1 hgt'>Others</td>
                                    <td className='ps-3 pt-0 pb-1 hgt'>600</td>

                                </tr>
                                <tr>
                                    <td className='ps-3 pt-0 pb-1 hgt'>Total</td>
                                    <td className='ps-3 pt-0 pb-1 hgt'>600</td>

                                </tr>
                            </tbody>
                        </table>

                    </Col>

                    <div>

                    </div>

                    <Container>
                        <div className=" ps-2 pt-1 pe-1 pb-1 mb-2 TermsConditions  ">
                            <p className='mb-0 terms-and-conditions-class'>Description</p>
                            <textarea
                                placeholder="Type here"
                                className="inputareabottom fw-bold"

                            />
                        </div>
                    </Container>
                </Row>
                </div>
            </Container>

        </div>
    )
}

export default PurchaseTable
