import React from "react";
import BoxData from "../Box";
import CardNav from "../CardNav";
import { Col, Container, Row, Card } from "react-bootstrap";
import { images } from "../../../../constants";
import { useState } from "react";
const SellSingleitem = ({ btn, title }) => {
  const [id, setIs] = useState(1);
  const [myinfo, setMyinfo] = useState("single info")
  return (
    <div>
      <Row>
        <Col lg="12">
          <CardNav Header={title} myid={id} myinfo={myinfo} />
          <Card className="shadow-main mt-lg-2 mt-md-3 mt-sm-2">
            <BoxData
              Title="Single Item"
              Butonn={btn}
              imgg={images.SingleAdds}
              url="/post-single-ad"
              link="/single-item-buy"
              ms="fourteen-element"
              buy="fourteen"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SellSingleitem;
