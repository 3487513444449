import { Col } from "react-bootstrap";
import ReactPlayer from 'react-player';
import { getAsset } from '../../../../helpers/helper';
import { useEffect } from "react";
const ServicesOnlineVideo = ({getService}) => {
    const videoUrl = getAsset(getService?.videos);

    useEffect(() => {
        console.log('Video URL:', videoUrl);
    }, [videoUrl]);

    if (!videoUrl) {
        return <div>No video available</div>;
    }
    return (
        <div>
        <ReactPlayer 
            url={videoUrl} 
            controls 
            width="100%" 
            height="330px" 
            config={{ file: { attributes: { crossOrigin: 'anonymous' } } }}
        />
        <h6>{getService?.videoTitle}</h6>
        <p className='font_13'>{getService?.videoDescription}</p>
    </div>
    )
}
export default ServicesOnlineVideo;