import { Container, Button, Modal,Form} from "react-bootstrap";
import VideocamIcon from "@mui/icons-material/Videocam";
import { useState } from "react";
const Livemodal =({show,handleClose})=>{
   
return(<>
<Container>
   

        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton className="font-sm">
            <Modal.Title>
              <h6 className="m-0">Go Live</h6>
            </Modal.Title>
          </Modal.Header>
          <Form.Select
            aria-label="Default select example"
            className="w-25 ms-3 mt-3"
          >
            <option>Privacy</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
          <Modal.Body>
           <div className="d-flex justify-content-center mb-4 mt-2"><Button className="text-white font_snall bg-btn-primary"><VideocamIcon /> <b>Go Live</b></Button></div> 
        
          </Modal.Body>
         
        </Modal>
      </Container>

</>)


}
export default Livemodal