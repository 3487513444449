import { Container, Col, Row } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GroupIcon from "@mui/icons-material/Group";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Title, Timeline } from "../../../../../pages/index";
import { FriendsProfiles } from "../../../../../constants/jsonConstant";
import BackArrow from "../../../../../components/backArrow";
import { useNavigate } from "react-router-dom";
import url from "../../../../../config/axios";
import { SuccessNotify, FailedNotify } from "../../../../../constants/tostify";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAsset } from "../../../../../helpers/helper";

const Viewbusinessfriends = ({ profileurl, id, setProfileshowhide }) => {
  const [leftSide, setLeftSidewidth] = useState(7);
  const [rightSide, setRightSide] = useState(5);
  const { name } = useSelector((state) => state.user);
  const [tabsData, setTabsData] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [infooo, setInfooo] = useState();
  const [info, setInfo] = useState();
  const location = useLocation();

  const [connectedData, setConnectedData] = useState();

  const [loading, setLoading] = useState(true);

  const [key, setKey] = useState("home");
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the connected data when the component is mounted
    const fetchConnectedData = async () => {
      try {
        const response = await url.get(
          `conection/getAllConnected/?userid=${name._id}&shopid=${id}`
        );

        setConnectedData(response?.data?.connectedBT[0]);
      } catch (error) {
        console.error("Error fetching connected data:", error);
      } finally {
        setLoading(false); // Set loading to false after the API call
      }
    };

    fetchConnectedData();
  }, [name._id, id]);

  console.log("______________________________________________+", tabsData);

  return (
    <>
      <div className="border ps-0 pe-0 height_100">
        <div onClick={() => setProfileshowhide(false)}>
          <BackArrow mystyle="p-1 ps-2" />
        </div>

        <Row>
          <Col lg="12">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3 display-setting font_sm d-flex justify-content-center"
            >
              <Tab eventKey="home" title="My Friend's">
                <Title name="General Friend's" title="105 General friends" />
                <div className="noscroll request_height">
                  <Row className="ps-4 pe-4 pt-0 pb-0">
                    {connectedData?.items?.map((item) => (
                      <Col lg="2" md="2" sm="12" className="border mt-3 p-2">
                        <div className="d-flex align-items-center flex-column">
                          <img
                            src={getAsset(item?.recievershopimage[0])}
                            alt={item?.recievershopname}
                            crossOrigin="anonymous"
                            className="profile_img"
                          />
                          <h6 className="font-sm">{item?.sendershopname}</h6>
                          <div
                            className="font_11  mob_f font-grey "
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(`${profileurl}/${id}`)}
                          >
                            <VisibilityIcon style={{ fontSize: "14px" }} />
                            View Profile
                          </div>
                          <button type="button" className=" folow-btn mt-1">
                            {" "}
                            Remove{" "}
                          </button>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="profile" title="Random Poeple's">
                <Title name="Random Friend's" title="110 Random friends" />
                <div className="noscroll request_height">
                  <Row className="ps-4 pe-4 pt-0 pb-0">
                    {FriendsProfiles.map((e) => (
                      <Col lg="2" md="2" sm="12" className="border mt-3 p-2">
                        <div className="d-flex align-items-center flex-column">
                          <img src={e.img} alt="" className="profile_img" />
                          <h6 className="font-sm">{e.name}</h6>
                          <div
                            className="font_11  mob_f font-grey "
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(`${profileurl}/${id}`)}
                          >
                            <VisibilityIcon style={{ fontSize: "14px" }} />
                            View Profile
                          </div>
                          <button type="button" className=" folow-btn mt-1">
                            {" "}
                            Connect{" "}
                          </button>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Viewbusinessfriends;
