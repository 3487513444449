import UserShopProfile from "../../components/UserShopProfile/UserShopProfile"
import { Row, Container } from "react-bootstrap"
import UserItemShop from "../../components/UserShopItem/UserItemShop"

const UsedItemProfile = () => {
    return (
        <Container>
            <div className='screenHeight'>
                <Row className="d-flex justify-content-center">
                    <UserShopProfile />
                    <UserItemShop />
                </Row>
            </div>
        </Container>

    )
}

export default UsedItemProfile