import { Col, Row } from "react-bootstrap";
import "./jobList.css";
import Tab from "react-bootstrap/Tab";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tabs from "react-bootstrap/Tabs";
import {Form, FormControl,Dropdown} from "react-bootstrap";
import { JobsList } from "../../../../constants/jsonConstant";
// import JobsFollowers from "../../../createJobsProfile/components/jobsFollowers";
// import JobsFollowing from "../../../createJobsProfile/components/jobsFollowing";
import url from "../../../../config/axios";
import Adds from "../../../../components/shopAds/ShopAdds";
import { useNavigate } from "react-router-dom";
import CandidatesBox from "../../../../components/candidatesBox";
import { useState } from "react";
const JobList = ({ itemData }) => {
  const [showoneModal, setShowoneModal] = useState(false);

  const handleTabSelect = (eventKey) => {
    if (eventKey === "tab1") {
      setShowoneModal(true);
    }
  };

  const handleCloses = () => {
    setShowoneModal(false);
  };
  const navigate = useNavigate();
const [companysearchdata,setCompanysearchdata]=useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchItems] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
  ]);

  const handleFocus = () => {
    setShowDropdown(true);
  };

  const filterData = (e) => {
url.get(`company/searchbyname/?title=${e}&businessType=jobsCompany`).then((res)=>{ 
  console.log("res+++_+_",res)
  setCompanysearchdata(res?.data?.data?.Companies);

 })

  }
  const handleBlur = () => {
    // Delay the dropdown hide to allow clicks to register on dropdown items
    setTimeout(() => setShowDropdown(false), 100);
  };

  return (
    <Col>
      {/* <CandidatesBox showoneModal={showoneModal} handleClose={handleCloses} /> */}
      <div>
        <Adds back="/find-job" />
      </div>
      <div className="position-relative">
        <Tabs
          defaultActiveKey="jobs"
          id="uncontrolled-tab-example"
          className="mb-3"
          onSelect={(k) => handleTabSelect(k)}
        >
          <Tab eventKey="jobs" title="Jobs">
            <div className="scroll-company-jobs noscroll">
              {
              companysearchdata && companysearchdata.length>0 ? 
              companysearchdata?.map((jobs) => (
                <div>
                  <Row className="ps-2 ">
                    <Col md={4} xl={4}>
                      <div>
                        <p className="mb-1 jobPara">Designation</p>
                        <h6 className="jobsDesc">{jobs?.title}</h6>
                      </div>
                    </Col>
                    <Col
                      md={2}
                      xl={2}
                      className="d-flex justify-content-center ps-0 "
                    >

                      <div>
                        <p className="mb-1 jobPara">Posted date</p>
                        <h6 className="jobsDesc">{jobs?.createdAt.slice(0, 10)}</h6>
                      </div>
                    </Col>
                    <Col
                      md={2}
                      xl={2}
                      className="d-flex justify-content-center p-0 "
                    >
                      <div>
                        <p className="mb-1 jobPara">Last date of apply</p>
                        <h6 className="jobsDesc">{jobs?.duration}</h6>
                      </div>
                    </Col>
                    <Col
                      md={2}
                      xl={2}
                      className="d-flex justify-content-center ps-0 "
                    >
                      <div>
                        <p className="mb-1 jobPara">View details</p>
                        <div className="cursor d-flex justify-content-center">
                          <VisibilityIcon
                            className="viewBlue"
                            onClick={() => navigate(`/view-job-details/${jobs?._id}`)}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col
                      md={2}
                      xl={2}
                      className="d-flex justify-content-center ps-0 "
                    >
                      <div>
                        <p className="mb-1 jobPara">Share</p>
                        <div className="cursor d-flex justify-content-center">
                          <ShortcutIcon className="viewBlue" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr className="m-0" />
                </div>
              ))
              
              :
              itemData?.itemdata?.map((jobs) => (
                <div>
                  <Row className="ps-2 ">
                    <Col md={4} xl={4}>
                      <div>
                        <p className="mb-1 jobPara">Designation</p>
                        <h6 className="jobsDesc">{jobs?.title}</h6>
                      </div>
                    </Col>
                    <Col
                      md={2}
                      xl={2}
                      className="d-flex justify-content-center ps-0 "
                    >

                      <div>
                        <p className="mb-1 jobPara">Posted date</p>
                        <h6 className="jobsDesc">{jobs?.createdAt.slice(0, 10)}</h6>
                      </div>
                    </Col>
                    <Col
                      md={2}
                      xl={2}
                      className="d-flex justify-content-center p-0 "
                    >
                      <div>
                        <p className="mb-1 jobPara">Last date of apply</p>
                        <h6 className="jobsDesc">{jobs?.duration}</h6>
                      </div>
                    </Col>
                    <Col
                      md={2}
                      xl={2}
                      className="d-flex justify-content-center ps-0 "
                    >
                      <div>
                        <p className="mb-1 jobPara">View details</p>
                        <div className="cursor d-flex justify-content-center">
                          <VisibilityIcon
                            className="viewBlue"
                            onClick={() => navigate(`/view-job-details/${jobs?._id}`)}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col
                      md={2}
                      xl={2}
                      className="d-flex justify-content-center ps-0 "
                    >
                      <div>
                        <p className="mb-1 jobPara">Share</p>
                        <div className="cursor d-flex justify-content-center">
                          <ShortcutIcon className="viewBlue" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr className="m-0" />
                </div>
              ))
              
              
              
              }
            </div>
          </Tab>
          {/* <Tab eventKey="tab1" title="Connects" variant="primary"></Tab> */}
        </Tabs>

        <div className="position-absolute search-absolute">
              <Form
                className="position-relative"
                onFocus={handleFocus}
                onBlur={handleBlur}
              >
                <FormControl
                  type="text"
                  placeholder="Search"
                  className="mr-sm-2 search-buing-area"
                  name="title"
                  onChange={(e) => filterData(e.target.value)}
                />
           
              </Form>
            </div>
      </div>

    
    </Col>
  );
};
export default JobList;
