import { Container } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import UserShopProfile from "../../components/UserShopProfile/UserShopProfile";
import CompanyJobList from "./components/jobList";
import BackArrow from "../../components/backArrow";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { FailedNotify, SuccessNotify } from "../../constants/tostify";
import url from "../../config/axios";
import { useState, useEffect } from "react";
const FindjobOnline = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const imageUrl = useSelector((state) => state.imgurl);
  const [loading, setloading] = useState(false);
  const { name } = useSelector((state) => state.user);
  const itemData = useSelector((state) => state.itemdata);
  const [lgShowreport, setLgShowreport] = useState(false);
  const [shopData, setShopData] = useState();
  const [jobData, setJobData] = useState();
  const [createShop, setCreateShop] = useState({
    userid: name._id,
    problem: "",
    desciription: "",
    image: [""],
  });
  console.log("jobitemdata...................", itemData)
  useEffect(() => {
    url.get(`company/id/?id=${id}`).then(async (res) => {
      setShopData(res?.data?.data?.Company);
    });
  }, []);
  // useEffect(() => {
  //   url.get(`job/company/?companyId=${id}`).then(async (res) => {
  //     setJobData(res?.data?.data?.Jobs);

  //   });
  // }, []);

  useEffect(() => {
    setCreateShop((createShop) => ({
      ...createShop,
      image: imageUrl?.imgurl || "",
    }));
  }, [imageUrl]);

  const PostShopDatas = (e) => {
    setloading(true);
    e.preventDefault();
    url
      .post("help/?", createShop)
      .then(async (response) => {
        if (response?.data?.success) {
          setloading(false);
          setLgShowreport(false);
          setTimeout(() => {
            SuccessNotify("Form Retailer Shop Created Successfully");
            navigate(`/retailer-userprofile`);
          }, 1000);
        }
      })
      .catch((error) => {
        setloading(false);
        FailedNotify("Failed To Create Shop");
      });
  };

  return (
    <Container>
      <Row>
        <Col md={4} lg={4} className="screenHeight">
          <div className="border b-radius height_100">
            <UserShopProfile
            
              PostShopDatas={PostShopDatas}
              setCreateShop={setCreateShop}
              lgShowreport={lgShowreport}
              setLgShowreport={setLgShowreport}
              shopData={shopData}
              id={id}
            />
          </div>
        </Col>
        <Col md={8} lg={8} className="screenHeight ">
          <BackArrow back="/find-job" />
          <div className="border b-radius px-3 ">
            <CompanyJobList itemData={itemData} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default FindjobOnline;