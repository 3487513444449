import { Categories } from "../../constants/jsonConstant";
import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Row, Col, Button } from "react-bootstrap";
import ShopAdds from "../shopAds/ShopAdds";
import { images } from "../../constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RedoIcon from "@mui/icons-material/Redo";
import "./shopItems.css";
import SalesPromotion from "../../components/SalesPromotion/SalesPromotion";
import SpecialOffer from "../../components/specialoffer/SpecialOffer";
import url from "../../config/axios";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import ReportBox from "../Report/index";
import ShopCategory from "../Category";
import Suggestionmodal from "../../pages/suggestionmodal";
import Deletemodal from "../DeleteModal";
import { SuccessNotify, FailedNotify } from "../../constants/tostify";
import { InsertEmoticonRounded } from "@mui/icons-material";
import Suggestion from "../../pages/suggestion";
import ShareOption from "../Shareoption";
import ShareModel from "../Sharemodel";
import { getAsset } from "../../helpers/helper";
import Loader from "../Loader";

const OwnerItemShop = ({
  shopid,
  urlPass,
  btnName,
  name,
  navigateto,
  updateState,
  setSuggest,
  suggest,
  Setreport,
  Report,
  post,
  suggestdata,
  shopData,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [itemid, setItemid] = useState();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [showmodal, setShowmodal] = useState(0);
  const handleClose = () => setShows(0);
  const handleClosetwo = () => setShowmodal(0);
  const handleShow = () => setShowmodal(1);
  const [itemshow, setItemshow] = useState();
  const [specialoffer, setSpecialoffer] = useState();
  const [salepromotion, setSalepromotion] = useState();
  const [loading, setLoading] = useState(false);
  const [showshare, setShowshare] = useState(false);
  const [showcategory, setShowcategory] = useState(0);
  const [leftmargin, setLeftmargin] = useState("Post Item");
  const [suggestId, setSuggestId] = useState();
  const [shows, setShows] = useState(false);

  localStorage.setItem("suggestid", suggestId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (
          location.pathname.includes(`/rawmaterial-owner-profile/${shopid}`) ||
          location.pathname.includes(`/manufacturer-owner-profile/${shopid}`) ||
          location.pathname.includes(`/wholesaler-owner-profile/${shopid}`) ||
          location.pathname.includes(`/recycle-profile/${shopid}`)
        ) {
          document.getElementById(
            "uncontrolled-tab-example-tab-sales"
          ).style.visibility = "hidden";
          document.getElementById(
            "uncontrolled-tab-example-tab-special"
          ).style.visibility = "hidden";
        }

        const response = await url.get(`shopitem/shop/?shopid=${shopid}`);

        if (response?.data?.success) {
          setItemshow(response?.data?.regular);
          setSpecialoffer(response?.data?.specialoffer);
          setSalepromotion(response?.data?.salespromotion);
        } else {
          setItemshow([]);
        }

      } catch (error) {
        console.error("Failed to fetch data:", error);

      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [location.pathname, shopid, url]);

  const handleSelect = (key) => {
    if (key === "tab1") {
      navigate(navigateto);
    } else if (key === "post") {
      navigate(post);
    }
  };
  const handledelete = () => {
    url
      .delete(`/shopitem/?id=${itemid}`)
      .then((res) => {
        if (res.data.success) {
          setShows(false);
          SuccessNotify("Item deleted successfully");
          url.get(`shopitem/shop/?shopid=${shopid}`).then(async (response) => {
            setItemshow(response?.data?.regular);
            setSpecialoffer(response?.data?.specialoffer);
            setSalepromotion(response?.data?.salespromotion);
          });
        }
      })
      .catch((err) => {
        FailedNotify("Failed to delete item");
      });
  };
  const handleCloseshare = () => {
    setShowshare(0);
    setShow(1);
  };
  const handleShowshare = () => {
    setShow(0);
    setShowshare(1);
  };
  const closemodal = () => setShowmodal(false);
  const suggestsingleid = localStorage.getItem("suggestid");
  return (
    <>
      <ShareOption showshare={showshare} handleCloseshare={handleCloseshare} />
      <ShareModel
        show={showmodal}
        handleClosemodal={closemodal}
        handleShowshare={handleShowshare}
      />
      <Deletemodal
        shows={shows}
        handleClose={handleClose}
        handledelete={handledelete}
      />
      <Col lg={12}>
        <ShopAdds back="/SellingSide" updateState={updateState} />
        <div onBlur={() => setShowcategory(0)}>
          <Row>
            <Col lg={12}>
              {suggest === 1 ? (
                <Suggestionmodal
                  setSuggestId={setSuggestId}
                  setSuggest={setSuggest}
                  suggest={suggest}
                  suggestdata={suggestdata}
                  shopid={shopid}
                  shopData={shopData}
                />
              ) : suggest === 2 ? (
                <ReportBox setSuggest={setSuggest} suggest={suggest} />
              ) : suggest === 3 ? (
                <Suggestion
                  suggestsingleid={suggestsingleid}
                  suggestdata={suggestdata}
                  shopData={shopData}
                  setSuggest={setSuggest}
                />
              ) : (
                <Tabs
                  id="uncontrolled-tab-example"
                  className="mb-3 ps-lg-5 tabsajdust"
                  onSelect={handleSelect}
                >
                  <Tab
                    eventKey="jobs"
                    title="Regular items"
                    className="jobviewhead "
                  >
                    {showcategory === 1 && <ShopCategory />}
                    <div className="shop-scroll noscroll ">
                      <div className="items_scroll noscroll">
                        <div className=" row ps-2 pe-2">
                          {loading ? (
                            <Loader heightsetting={"screenHeight"} />
                          ) : itemshow && itemshow?.length > 0 ? (
                            itemshow?.map((Items) => (
                              <div className="col-lg-4 col-xl-3 col-md-6 col-6 p-2">
                                <div className="border_2 ">
                                  <div key={Items?.id}>
                                    <div className="fix-img">
                                      <img
                                        className="c_img"
                                        src={getAsset(Items?.images[0])}
                                        alt=""
                                        crossOrigin="anonymous"
                                      />
                                    </div>
                                    <Row className="align-items-center">
                                      <Col
                                        lg={6}
                                        md={5}
                                        xs="6"
                                        sm="6"
                                        className="pe-0"
                                      >
                                        <div className="pt-1 ms-2">
                                          <p className="mb-0 ps-2 pe-2 items-card-font fw-bold">
                                            {Items?.itemname.slice(0, 7)}
                                          </p>
                                        </div>
                                      </Col>
                                      <Col
                                        lg={6}
                                        md={7}
                                        xs="6"
                                        sm="6"
                                        className="ps-0"
                                      >
                                        <div>
                                          <p className="mb-0 ps-2  labelText fw-bold pt-1 ms-2 ms-lg-1">
                                            Qty ({Items?.quantity})
                                          </p>
                                        </div>
                                      </Col>
                                    </Row>
                                    <div className="d-flex justify-content-between align-items-center  ps-2 pe-2 ">
                                      <p className="mb-0 pt-1  items-card-font font-blue ms-2">
                                        <span style={{ color: "black" }}>
                                          Price:
                                        </span>
                                        &nbsp;
                                        {Items?.price}
                                      </p>
                                      <img src={images.img_five} />
                                    </div>

                                    <div className="row align-items-center">
                                      <Col
                                        lg="6"
                                        xs="6"
                                        md="6"
                                        sm="6"
                                        className="pe-0"
                                      >
                                        <h6 className="items-card-font ms-3 mb-0">
                                          Orders (10)
                                        </h6>
                                      </Col>
                                      <Col
                                        lg="6"
                                        xs="6"
                                        md="6"
                                        sm="6"
                                        className="text-center "
                                      >
                                        <button
                                          className="text-white bg-blue share_font item-share-btn"
                                          onClick={() => {
                                            setShowmodal(true);
                                          }}
                                        >
                                          Share
                                          <RedoIcon className="share_icon_f" />
                                        </button>
                                      </Col>
                                    </div>
                                    <div className="d-flex justify-content-around mb-2 mt-1">
                                      <p
                                        className="mb-0 view-btn  btn-primary  text-center ms-1 me-1 mt-1"
                                        onClick={() =>
                                          // setShowmodal(1) + setIndex(Items._id)
                                          navigate(
                                            `${urlPass}/${shopid}/${Items._id}`
                                          )
                                        }
                                      >
                                        View
                                      </p>
                                      <Button
                                        className="mb-0 view-btnone text-white  text-center ms-1 me-1 mt-1"
                                        variant="danger"
                                        onClick={() => {
                                          setShows(true);
                                          setItemid(Items._id);
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <h4 className="text-center">No items found</h4>
                          )}
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="sales" title="Sales Promotion">
                    <SalesPromotion
                      salepromotion={salepromotion}
                      setShowmodal={setShowmodal}
                      urlPass={urlPass}
                      setShows={setShows}
                      setItemid={setItemid}
                      shopid={shopid}
                    />
                  </Tab>
                  <Tab eventKey="special" title="Special Offers">
                    <SpecialOffer
                      specialoffer={specialoffer}
                      setShowmodal={setShowmodal}
                      urlPass={urlPass}
                      setShows={setShows}
                      setItemid={setItemid}
                      shopid={shopid}
                    />
                  </Tab>
                  <Tab
                    eventKey="post"
                    title="&#10133; Post Item"
                    onClick={() => navigate(`${post}/` + id)}
                  ></Tab>
                  &nbsp;&nbsp;
                </Tabs>
              )}
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
};
export default OwnerItemShop;
