import React from "react"
import UserTransport from "../UsersideTransport/Userside"
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { FailedNotify, SuccessNotify } from "../../constants/tostify";
import url from "../../config/axios";
import { useState, useEffect } from "react";
const Buyingtransportprofile = () => {
  const { shopid } = useParams();
  const navigate = useNavigate();
  const imageUrl = useSelector((state) => state.imgurl);
  const [loading, setloading] = useState(false);
  const { name } = useSelector((state) => state.user);
  const itemData = useSelector((state) => state.itemdata);

  const [shopData, setShopData] = useState();
  const [itemshow, setItemshow] = useState();
  const [createShop, setCreateShop] = useState({
    userid: name._id,
    problem: "",
    desciription: "",
    image: [""],
  });
  useEffect(() => {
    setCreateShop((createShop) => ({
      ...createShop,
      image: imageUrl?.imgurl || "",
    }));
  }, [imageUrl]);

  useEffect(() => {
    url.get(`shop/id?id=${shopid}`).then(async (res) => {
      setShopData(res?.data?.data?.Shop);

    });
  }, []);
  useEffect(() => {
    url.get(`/tranportvehicle/?shopid=${shopid}`).then(async (response) => {
      console.log("shoppppitemkroooooo", response)
      setItemshow(response?.data?.data?.TransportVehicles);
    });
  }, []);

  const PostShopDatas = (e) => {
    setloading(true);
    e.preventDefault();
    url
      .post("help/?", createShop)
      .then(async (response) => {
        if (response?.data?.success) {
          setloading(false);
          setTimeout(() => {
            SuccessNotify("Form Retailer Shop Created Successfully");
            navigate(`/retailer-userprofile`);
          }, 1000);
        }
      })
      .catch((error) => {
        setloading(false);
        FailedNotify("Failed To Create Shop");
      });
  };

  return (<>
    <div className="container">
      <div className="screenHeight ">
        <UserTransport
          PostShopDatas={PostShopDatas}
          setCreateShop={setCreateShop}
          shopData={shopData}
          itemshow={itemshow}
          itemData={itemData}
          shopid={shopid}
        />
      </div>
    </div>

  </>)
}

export default Buyingtransportprofile