import { Row, Col } from "react-bootstrap";
import "./CVimage.css";
import { images } from "../../../../../constants/index";
import ProfileInformationCV from "../profileInformation";
import CVProfileBio from "../CVProfileBio";
const CVImage = ({ cvdata }) => {
  return (
  
      <Row>
        <Col md={4} lg={4} xl={3} className="ps-0 pe-0">
          <div className="leftsideback d-flex flex-column align-items-center ">
            <div className="pt-2">
              <div className="img-Background ">
                <img
                  src={cvdata?.profileInformation?.photo}
                  alt=""
                  className="img-Background "
                />
              </div>
              <div className="profile-1-scroll noscroll ps-md-2 ps-lg-2 ps-xl-0">
                <ProfileInformationCV cvdata={cvdata} />
              </div>
            </div>
          </div>
        </Col>
        <Col md={8} lg={8} xl={9} className="pe-0">
          <CVProfileBio cvdata={cvdata} />
        </Col>
      </Row>
   
  );
};
export default CVImage;
