import React from "react";
import { useNavigate } from "react-router-dom";
import "../chatroomview/chatrooom.css";
import { UserChatroomviewImages } from "../../constants/jsonConstant";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

const UserChatRoompeoples = ({ setAddMembers,openAddMembers }) => {
  const navigate = useNavigate();
  return (
    // main_div_people_view
    <div className="main_div_people_view">
      {/* Inner_people_view */}
      <div className="Inner_people_view  ms-2 me-2 mt-2   ps-0 pe-0  ">
        <div className="Flex-View_people">
          <div className="heading-flex">
            <h4>Room Members</h4>
          </div>
          <div className="Btn_flex_section pt-2 pb-2 ps-3 pe-3">
            <div className="col-5 ps-0 pe-0">
              <div class="input-group">
              <span class="input-group-addon bg-white border-0">
                 
                 <SearchIcon />
               </span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search"
                />
              
              </div>
            </div>
            <div className="col-7 d-flex justify-content-end pe-0">
              <button
                type="submit"
                className="btn-33-AddMembers p-1"
                onClick={() => setAddMembers(2)}
              >
  
            <AddIcon /> Add Member
              </button>
            </div>
          </div>
        </div>
        {/* people_section_start-1 */}
        <div className="  pt-2 People_Main_Scrool noscroll ">
          {UserChatroomviewImages?.map((Elem) => {
            const { id, img, name, paragraph, text, image, message } = Elem;
            return (
              <div key={id}>
                <div>
                  <div className="People_flex_section_Main">
                    <div className="">
                      <img src={img} alt="boyimg1" className="img_boy_1" />
                    </div>
                    <div className="Padding_srction_people">
                      <div className="prfile-text-flex">
                        <div>
                          <p className="Admin_name_Section mb-0">{name}</p>
                        </div>
                        <div className="Admin-online">
                          <div>
                            <p className="ps-2 pe-2">
                              {paragraph}{" "}
                              <span
                                onClick={() => navigate("/single-chatroom")}
                              >
                                {message}
                              </span>
                            </p>
                          </div>

                          <div className="img_position_People">
                            <img src={image} />
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <p className="Chats-show-profile pb-1 mb-0">{text}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default UserChatRoompeoples;
