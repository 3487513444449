import React from 'react'
import { Row, Container } from "react-bootstrap"
import UserItemShop from '../../components/UserShopItem/UserItemShop'
import UserShopProfile from '../../components/UserShopProfile/UserShopProfile'

const UserBussineProperty = () => {


    return (
        <Container>
            <div className='screenHeight'>
                <Row className="d-flex justify-content-center">
                    <UserShopProfile />
                    <UserItemShop />
                </Row>
            </div>
        </Container>
    )
}

export default UserBussineProperty;