import React from "react";
import BoxData from "../Box";
import CardNav from "../CardNav";
import { Col, Container, Row, Card } from "react-bootstrap";
import { images } from "../../../../constants";
import { useState } from "react";

const BuyVehicles = ({ title }) => {
  const [id,setIs]=useState(1);
  const [myinfo,setMyinfo]=useState("vehicle info")
  return (
    <div>
      <Row>
        <Col lg="12">
        <CardNav Header={title} myid={id} myinfo={myinfo} />
          <Card className="shadow-main mt-2">
            <BoxData
              Title="Buy Vehicles"
              Butonn="Buy Now"
              imgg={images.BuyVehicles}
              link="/buy-used-cars"
              buy="sixteen"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default BuyVehicles;
