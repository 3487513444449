import Button from "react-bootstrap/Button";
import { useEffect } from "react";
import { images } from "../../../constants";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { useRef, useState } from "react";
import BackArrow from "../../backArrow";

import { getAsset } from "../../../helpers/helper";
import ClearIcon from "@mui/icons-material/Clear";

// import "./form.css";
const Form = ({
  CreateHandler,
  butn,
  setCreateShop,
  back,
  title,
  UpdateShop,
  createShop,
  id,
  shopCategory,
  shopCategoryone,

  getInputProps,
  getRootProps,
  displayimg,
  setDisplayimg
}) => {
  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState("");
  const ShopHandler = (event) => {
    setCreateShop((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const removeImage = (index) => {
    const updatedImages = [...displayimg];
    updatedImages.splice(index, 1);
    setDisplayimg(updatedImages);
  };

  return (
    <>

      <Row>
        <form>
          <div className="screenHeight d-flex justify-content-center align-items-center">
            <div className="Form-Shadow  col-lg-6 col-md-7 col-sm-9 col-xs-12   pb-3">
              <div className="arrowback ps-3">
                <BackArrow back={back} black="black" mystyle="ps-4" />
              </div>
              <div className="ps-5 pe-5 pt-1 ms-3 me-3">
                <div className="pt-4 ">
                  <h4 className="createHeading">{title}</h4>
                </div>

                <div className="d-flex justify-content-between pt-1">
                  <div className="d-flex flex-column pt-3 col-6 pe-2">
                    <label className="custm-lble-2 pb-2">Shop Name</label>
                    <input
                      defaultValue={createShop?.shopname}
                      name="shopname"
                      onChange={(e) => ShopHandler(e)}
                      className="input-style"
                      placeholder="Name"
                      disabled
                    />
                  </div>

                  <div className="d-flex flex-column pt-3 col-6 ps-2">
                    <label className="custm-lble-2 pb-2">
                      Select Shop Category
                    </label>

                    <div class="selectdiv">
                      <label>
                        <select
                          name="shopCategory"
                          placeholder="Select Catagory"
                          style={{ color: "black" }}
                          className=""
                          onChange={(e) => {
                            ShopHandler(e);
                          }}
                        >
                          <option selected disabled hidden>
                            Select Category
                          </option>
                          {shopCategoryone?.map((category) => (
                            <option key={category._id} value={category?._id}>
                              {category?.name}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-between pt-1">
                  <div className="d-flex flex-column pt-3 col-6 pe-2">
                    <label className="custm-lble-2 pb-2">Market Place</label>
                    <input
                      name="marketPlace"
                      className="input-style"
                      placeholder="Market Place"
                      onChange={(e) => ShopHandler(e)}
                      defaultValue={createShop?.marketPlace}
                    />
                  </div>

                  <div className="d-flex flex-column pt-3 col-6  ps-2">
                    <label className="custm-lble-2 pb-2">
                      CNIC or Licence Number
                    </label>
                    <input
                      defaultValue={createShop?.shoplicense}
                      name="shoplicense"
                      onChange={(e) => ShopHandler(e)}
                      className="input-style"
                      placeholder="Licence Number/CNIC"
                      disabled
                    />
                  </div>
                </div>

                <div>
                  <div className="d-flex flex-column pt-2">
                    <label className="custm-lble-2 pb-2">Shop Address</label>
                    <input
                      defaultValue={createShop?.shopaddress}
                      name="shopaddress"
                      className="input-style"
                      placeholder="Add Address with maps"
                    />
                    <div className="position-relative">
                      <img className="iMg" src={images.FindClinic} />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="d-flex flex-column pt-2">
                    <label className="custm-lble-2 pb-2">Description</label>
                    <textarea
                      defaultValue={createShop?.description}
                      name="description"
                      onChange={(e) => ShopHandler(e)}
                      placeholder="Description"
                      className="input-style pt-1 ps-2 pb-5 pe-2 "
                    />
                  </div>
                </div>

                {/* UploadImg */}
                <Col lg="9" className="me-auto">
                  <Row>
                    <Col lg={3} className="mt-2 ms-1">
                      <Card
                        style={{ width: "6rem", height: "6rem" }}
                        {...getRootProps()}
                        className="dropzone"
                      >
                        <Card.Img
                          className="imet-fit"
                          src={images.AddImage}
                        />
                        <Card.Body>
                          <input
                            type="file"
                            id="file"
                            style={{ display: "none" }}
                            {...getInputProps()}
                            multiple
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                    {
                      displayimg?.length > 0 && (
                        <Col lg={7} className=" mt-1 d-flex">


                          {displayimg.map((image, index) => (
                            <Card
                              className="me-2 mt-1"
                              style={{
                                width: "6rem",
                                height: "6rem",
                                border: "2px solid",
                              }}

                            >
                              <Card.Img
                                className="imet-fit upload-img mt-0"
                                src={getAsset(image)}
                                alt=""
                                crossOrigin="anonymous"
                              />
                              <div
                                className="close-btnone"
                                onClick={() => removeImage(index)}
                              >
                                <ClearIcon />
                              </div>
                            </Card>
                          ))}
                        </Col>
                      )

                    }

                  </Row>
                </Col>

                <div className="btns">
                  <button
                    className="btn1"
                    onClick={() => navigate(`${back}${id}`)}
                  >
                    Cancel
                  </button>

                  <button
                    className="btn2"
                    variant="primary"
                    onClick={butn === "Create" ? CreateHandler : UpdateShop}
                  >
                    {butn}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Row>
    </>
  );
};
export default Form;
