import { Container, Col } from "react-bootstrap";
import "./chatroommodal.css";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Chatroombackarrow from "../../../components/Chatroom-backarrow";
const Chatroommodal = () => {
  const navigate = useNavigate();
  
  return (
    <>
      <Container>
        <div className="screenHeight">
      
       
        <div className="d-flex justify-content-center align-items-center modal_height">
        <Col lg="6" md="6" sm="12" xs="12" className=" text-center bg-black-2">
          <Chatroombackarrow url="/SellingSide" />
            <div className="col_custm">
              <h5 className="text-white">Continue to Enter Chat Room</h5>
              <button
                className="mt-5 btn-continue ps-3 pe-3 pt-1 pb-1"
                onClick={() => navigate("/own-chatroom")}
              >
                Continue
              </button>
            </div>
          </Col>
        </div>
          
        </div>
      </Container>
    </>
  );
};

export default Chatroommodal;
