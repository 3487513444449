import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";
import storage from 'redux-persist/lib/storage';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        name: ""
    },
    reducers: {
        updateUserName: (state, action) => {
            state.name = action.payload
        },
        extraReducers: (builder) => {
            builder.addCase(PURGE, (state) => {
                storage.remove("root");
            });
          }
}

});

// this is for dispatch
export const { updateUserName} = userSlice.actions;

// this is for configureStore
export default userSlice.reducer;