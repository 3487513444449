import React from "react";
import Card from "react-bootstrap/Card";

import { BoxData } from "../../pages";
import { CardNav } from "../../pages";
import { BuyingBusinessData } from "../../constants/jsonConstant";
import { Col, Row } from "react-bootstrap";

import { images } from "../../constants";
import "../../pages/NewDashboard/Component/BussinessMode/style.css";

const BuyingBusinessModes = ({ btntext, title, btntexttwo }) => {
  return (
    <>
      <div>
        <Row className="set-one set-onetwo">
          {/* <Col lg='12'> */}

          {/* </Col> */}
          {BuyingBusinessData.map((data) => (
            <Col lg="3" md="3" sm="6" xs="6">
              <CardNav
                Header={data.title}
                para={data.para}
                myinfo={data.info}
                myid={data.id}
              />

              <Card className="shadow-main mt-2">
                {(data.id === 1 && (
                  <BoxData
                    Butonn={btntext}
                    imgg={images.Retailer}
                    url="/retailer-integrate"
                    link="/buy-retailer"
                    ms="eight-element"
                    buy="eight"
                    Title={data.titleone}
                  />
                )) ||
                  (data.id === 2 && (
                    <BoxData
                      Butonn={btntext}
                      imgg={images.WholeSaler}
                      url="/wholeseller-integrate"
                      link="/buy-wholeseller"
                      ms="six-element"
                      buy="six"
                      Title={data.titleone}
                    />
                  )) ||
                  (data.id === 3 && (
                    <BoxData
                      Butonn={btntext}
                      imgg={images.Factory}
                      url="/manufacturer-integrate"
                      link="/buy-manufacturer"
                      className="mt-2"
                      ms="seven-element"
                      buy="seven"
                      Title={data.titleone}
                    />
                  )) ||
                  (data.id === 4 && (
                    <BoxData
                      Butonn={btntext}
                      imgg={images.IronLogo}
                      url="/raw-integrate"
                      link="/buy-raw-material"
                      ms="eight-element"
                      buy="eight"
                      Title={data.titleone}
                    />
                  ))}
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default BuyingBusinessModes;
