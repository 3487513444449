import React from 'react'
import { Row, Col } from 'react-bootstrap';
import HeaderOne from './Components/HeaderOne';
import { Container } from "react-bootstrap";
import ImageOne from './Components/ImageOne';
import LeaveSchedule from './Components/LeaveSchedule'
import './style.css'

import Chatleave from './Components/ChatLeave';
const GoogleMeet = () => {
  return (
    <div>
      <Container className=''>
        <div className='screenHeight d-flex align-items-center'>
        <Row className='pe-0 end-owv'>
        <Col className='pe-0' lg='8'>
       <HeaderOne/> 
        <Row>
        <Col lg='12' className='pe-0 pt-2'>
        <ImageOne/>
        </Col>
        </Row>
        <Col lg='12' className='pe-0 pb-3'>
          <LeaveSchedule/>
        </Col>
        
        </Col>
        <Col lg='4'>
        <Chatleave/>
        </Col>
        
      </Row>
        </div>
      </Container>
   
    </div>
  )
}

export default GoogleMeet;