import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Container } from 'react-bootstrap';
import {images} from '../../../../constants';
import "./style.css"

const Emailmodal = () => {

    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const handleDrop = (e) => {
    e.preventDefault();
    const imageFile = e.dataTransfer.files[0];
    setFile(imageFile);
    setPreviewUrl(URL.createObjectURL(imageFile));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

 
  

  return (
    <div>
      <Container>
        <div className='screenHeight '>
   
        <Button variant="primary" onClick={handleShow}>
        CLICK ME
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Insert Photo</Modal.Title>
        </Modal.Header>
        <img  
        className='MODLPIC pt-5'
        src={images.Emailpic} 
        alt='Emailpic'/>
        <h6 className=' MODLTXT text-center'  onClick={() => document.getElementById("imageInput").click()}>Drop your image here, or  <span className='span-color'>browse</span> </h6>
        <p className='text-center' >Select  Images</p>
        <div className='MODl'>

        
        {!previewUrl && (
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          style={{
            border: "dashed 2px gray",
            padding: "1rem",
            textAlign: "center",
          }}
        >
          <p>Drag and drop your image here</p>
        </div>
        
      )}
      {previewUrl && (
        <div>
          <img src={previewUrl} alt="preview" style={{ maxWidth: "100%" }} />
        
        </div>
      )}
       <div>
       </div>
<input
  id="imageInput"
  type="file"
  accept="image/*"
  style={{ display: "none" }}
  onChange={handleImageSelect}
/>

{selectedImage && (
  <div>
    <img src={URL.createObjectURL(selectedImage)} alt="Selected"  width='100%' height='100%'/>
  </div>
)}
</div>
      </Modal>
     
<div>
     
    </div>

        </div>
      </Container>
    </div>
  )
}

export default Emailmodal
