import Sidebar from "../../Auth/components/sidebar/sidebar";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import * as Yup from "yup";
import { useState } from "react";
import url from "../../../config/axios";
import { SuccessNotify } from "../../../constants/tostify";
import { FailedNotify } from "../../../constants/tostify";
import Loader from "../../../components/Loader";
import "../../Auth/Auth.css";
import { useDispatch } from "react-redux";
import { updateUserName } from "../../../store/modules/userData.js";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MarqueePeople from "../../../components/marqueeslider/Marqueeslider.js";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isloading, setIsloading] = useState(false);
  const [showpassword, setShowPassword] = useState('password');
  const [changeicon, setChangeicon] = useState(1);
  const [logindata, setLogindata] = useState({
    emailorphone: "",
    password: "",
  });
  const userLoginadata = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("/user/login", logindata)
      .then(async (response) => {
        if (response.data.success) {
          dispatch(updateUserName(response?.data?.data?.User));
          setIsloading(false);
          SuccessNotify("Login Successfully");
          navigate("/BuyingSide");
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Email or Password is Incorrect");
      });
  };
  const inputChange = (event) => {
    setLogindata((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const validationSchema = Yup.object().shape({
    emailorphone: Yup.string()
      .email("*Invalid email address")
      .required("*Email or Phone number is Required"),
    password: Yup.string().required("Password is Required*"),
  });
  const Selectezi = "Selectezi"
  return (
    <>
      <Container fluid>
        <Row className="sponsersettingrelative">
          <Col lg="2" md="2" sm="12" className="p-lg-5">
            {/* <Sidebar /> */}
            <div>
              <h4 className="font-weight-bold selectezilogo">{Selectezi.toUpperCase()}</h4>
            </div>
          </Col>
          <Col lg="8" md="7" sm="12" className=" p-3 loginsetheight">
            <Col lg="8" className="mx-auto height-login">
              <h3 className="mb-2  font-bold mt-lg-5">Login</h3>
              <p className="mb-3 login_font">
                Login and Establish your business Shop to join the business
                network.where you can engage your business with thousands of new
                clients along your area.
              </p>
              {isloading === true ? (
                <Loader heightsetting="screenHeight" />
              ) : (
                <Formik
                  initialValues={{
                    emailorphone: "",
                    password: "",
                  }}
                  // validateOnChange={false}
                  validateOnBlur={false}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    // same shape as initial values
                  }}
                >
                  {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    errors,
                    touched,
                    isValid,
                    dirty,
                  }) => (
                    <Form
                      onSubmit={(e) => {
                        userLoginadata(e);
                        handleSubmit(e);
                      }}
                    >
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label labelSize"
                      >
                        Email address or Phone number*
                      </label>
                      <Field
                        type="text"
                        className="form-control box-height inputSizing"
                        id="emailorphone"
                        name="emailorphone"
                        placeholder="Email address or Phone number"
                        aria-describedby="emailHelp"
                        onChange={(e) => {
                          inputChange(e);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        isInvalid={errors.emailorphone && touched.emailorphone}
                      />
                      {errors.emailorphone && touched.emailorphone ? (
                        <div>
                          <p className="error_text_color">
                            {errors.emailorphone}
                          </p>
                        </div>
                      ) : null}

                      <div className=" mt-2">
                        <label
                          htmlFor="exampleInputPassword1"
                          className="form-label labelSize"
                        >
                          Password*
                        </label>
                        <InputGroup className=" eyeset form-control box-height inputSizing">
                          <Field
                            type={showpassword}
                            className="border-0 password-width"
                            placeholder="Enter password"
                            id="password"
                            name="password"
                            onChange={(e) => {
                              inputChange(e);
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            isInvalid={errors.password && touched.password}
                          />
                          {
                            changeicon === 1 && (<VisibilityIcon className="cursor_P" onClick={() => { setShowPassword('text'); setChangeicon(2) }} />)
                          }
                          {
                            changeicon === 2 && (<VisibilityOffIcon className="cursor_P" onClick={() => { setShowPassword('password'); setChangeicon(1) }} />)
                          }
                        </InputGroup>
                      </div>
                      {errors.password && touched.password ? (
                        <div>
                          <p className="error_text_color">{errors.password}</p>
                        </div>
                      ) : null}

                      <Button
                        className="login-button disable-btn  text-white  w-100 mt-3 p-3 font-sm "
                        type="submit"
                        disabled={!(isValid && dirty)}
                        data-title={
                          !(isValid && dirty)
                            ? "Please fill out all Fields to enable button!"
                            : null
                        }
                      >
                        Login
                      </Button>
                      <div
                        id="emailHelp"
                        className=" font-blue text-center  mt-lg-3 mt-xl-3 "
                      >
                        <NavLink to="/signup">
                          Do You Want To Join Us ?
                          <span style={{ color: "black", fontWeight: "500" }}>
                            {" "}
                            SignUp
                          </span>
                        </NavLink>
                      </div>
                      <div className="text-center font-blue  mt-lg-1 mt-xl-1 ">
                        <NavLink to="/forgot">
                          Forgot your{" "}
                          <span style={{ color: "black", fontWeight: "500" }}>
                            Password
                          </span>
                        </NavLink>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </Col>
          </Col>
        </Row>


        {/* <Row className="mt-1">
          <Col lg={3} md={3} sm={12} className="sponsored_by p-1 ">
            <h3 className="text-sm-center text-xs-center">Sponsored By</h3>
          </Col>
          <Col lg={7} md={7} sm={12} className=" marqueecol p-0 mt-sm-4"><MarqueePeople /></Col>
          <Col lg={2} md={2} sm={""} className="sponsored_by d-sm-none d-md-block"></Col>
        </Row> */}

      </Container >
    </>
  );
};
export default Login;
