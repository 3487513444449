import React, { useEffect, useState } from "react";
import { Container, Col } from "react-bootstrap";
import RestaurantCard from "./Component/Restaurantcards";
import RestaurantShopdata from "./Component/Restaurant_Shopdata";
import "./style.css";
import { useParams } from "react-router-dom";
import url from "../../config/axios";
import { useSelector } from "react-redux";

const RestaurantAlldata = () => {
  const { id } = useParams();
  const [shopData, setShopData] = useState();
  const [suggestdata, setSuggestdata] = useState();
  const [suggest, setSuggest] = useState(0);
  const { name } = useSelector((state) => state.user);
  
  useEffect(() => {

    url
      .get(`shop/user/?userid=${name._id}&businessType=foodShop`)
      .then(async (res) => {
        console.log("res11111", res);
        if (res.data.success) {
          localStorage.setItem("mycategory", (res?.data?.data?.Shops[0]?.shopCategory?.name));
        }
      });
  }, []);

  useEffect(() => {
    url.get(`/shop/id?id=${id}`).then(async (res) => {
      setShopData(res.data.data.Shop);
    });
  }, []);

  // call this  api on click on suggest.....................................................
  const suggestdatta = () => {
    url
      .get(`suggestion/getShopSuggestion/vendorid?vendorid=${shopData?.shopid}`)
      .then(async (res) => {
        setSuggestdata(res?.data?.data[0]?.suggestions);
      });
  };

  return (
    <>
      <Container>
        <div className="screenHeight">
          <Col xl="9" lg="12" md="9" xs="12" sm="12" className="mx-auto">
            <div>
              <RestaurantShopdata
                shopid={id}
                className="images-shopdata"
                shopData={shopData}
                id={id}
                suggestdatta={suggestdatta}
                setSuggest={setSuggest}
              />
            </div>

            <div>
              <RestaurantCard
                id={id}
                suggestdata={suggestdata}
                suggest={suggest}
                setSuggest={setSuggest}
                shopData={shopData}
                shopid={id}
              />
            </div>
          </Col>
        </div>
      </Container>
    </>
  );
};

export default RestaurantAlldata;
