import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Leftbar from '../../Components/EmailLeftbar'
import MainChats from '../../Components/mainChats'
import HeaderNav from '../../Components/HeaderNav'
import HeaderNav2 from '../../Components/HeaderNav2'
import ChatingSide from '../../Components/ChatingSide'
import { useState } from "react";
import Creatmeetinging from "../../Components/Creatmeeting";
import Joinmeetinging from "../../Components/Joinmeetingmodal";
import ModalSchedulehedule from "../../Components/ModalSchedule";
import './style.css';

const SentEmail = () => {
    const [mettingm, setMettingm] = useState(0);

  const handleClose = () => setMettingm(0);
  const handleShow = () => setMettingm(1);

  const [mettingn, setMeetingn] = useState(0);
  const handleClosen = () => setMeetingn(0);
  const handleShowm = () => setMeetingn(1);

  const [mettingb, setMettingb] = useState(0);
  const handleCloseb = () => setMettingb(0);
  const handleShowc = () => setMettingb(1);
    return (
        <div>

            <Container>
            {mettingm === 1 && (
            <Creatmeetinging mettingm={mettingm} handleClose={handleClose} />
          )}
          {mettingn === 1 && (
            <Joinmeetinging mettingn={mettingn} handleClose={handleClosen} />
          )}
          {mettingb === 1 && (
            <ModalSchedulehedule mettingb={mettingb} handleClose={handleCloseb} />
          )}

                <div>

                    <Row className='screenHeight d-flex align-items-center border_3' >
                        <Col lg='2 ps-0 pe-0'>

                            <Leftbar handleShow={handleShow}
              handleShowm={handleShowm}
              handleShowc={handleShowc} />

                        </Col>



                        <Col lg='3' md='4' className=' ps-0 pe-0 '>

                            <Row>
                                <HeaderNav Mails='Send' />
                            </Row>

                            <MainChats />

                        </Col>

                        <Col lg='7 ps-0' md='8'>

                            <Row>
                                <HeaderNav2 />
                            </Row>
                            <Row>
                                <ChatingSide/>
                            </Row>
                        </Col>
                    </Row>

                </div>

            </Container>

        </div>
    )
}

export default SentEmail;
