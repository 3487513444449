
import Form from "../../../components/forms/index";
import { SuccessNotify, FailedNotify } from "../../../constants/tostify";
import url from "../../../config/axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import { publishImage } from "../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
const RecycleShop = () => {
  const navigate = useNavigate();
  const { name } = useSelector((state) => state.user);
  const [displayimg, setDisplayimg] = useState();
  const [shopCategoryone, setShopCategoryone] = useState();
  const [isloading, setIsloading] = useState(false);

  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setCreateShop((createShop) => ({
        ...createShop,
        image: abc
      }));

      setDisplayimg(abc);

    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });



  const [selectshop, setSelectshop] = useState({
    shopCategory: ""
  });
  const Shopdatas = (e) => {
    setSelectshop((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split("."),
    }));
  };

  const categoryName = selectshop?.shopCategory[1];
  const categoryid = selectshop?.shopCategory[0];

  localStorage.setItem('mycategory', categoryName);

  const [createShop, setCreateShop] = useState({
    userid: name._id,
    shopname: "",
    shopaddress: "",
    shoplicense: "",
    contact: "",
    category: "",
    image: [""],
    isFoodShop: false,
    shopCategory: "",
    marketPlace: "",
    businessType: "recycledShop",
    description: "",
    status: true,
  });


  useEffect(() => {
    setCreateShop((createShop) => ({
      ...createShop,
      shopCategory: categoryid
    }
    ))
  }, [categoryid]);


  useEffect(() => {
    url
      .get("category/type/?type=recycledShop&parentCategory=")
      .then(async (response) => {
        if (response?.data?.success) {
          setShopCategoryone(response?.data?.data?.Category)
        }
      })
      .catch((error) => { });
  }, [])
  const PostShopData = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("shop/", createShop)
      .then(async (response) => {

        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Form Recycle Created Successfully");
            navigate(`/rawmaterial-recycle/${response?.data?.data?._id}`);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed To Create Shop");
      });
  };
  if (isloading) {
    return (
      <div>
        <Loader heightsetting="screenHeight" />
      </div>
    );
  }
  return (
    <>
      <Form
        title="Fill to Create Recycle Material Shop"
        butn="Create"
        back="/recycled-integrate"
        CreateHandler={PostShopData}
        setCreateShop={setCreateShop}
        createShop={createShop}
        shopCategoryone={shopCategoryone}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        displayimg={displayimg}
        setDisplayimg={setDisplayimg}
        Shopdatas={Shopdatas}
      />
    </>
  );
};
export default RecycleShop;
