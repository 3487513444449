import React from "react";
import { images } from "../../constants/index";
import UserChatRoompeoples from "../Userchatroompeople";
import { useLocation } from "react-router-dom";
import "./chatrooom.css";
import Chatroombackarrow from "../Chatroom-backarrow";
const UserChatroomview = ({ setAddMembers, title,openAddMembers }) => {
    const location=useLocation();
  return (
    <>
      {/* main_div */}
      <div className="main_div">
        {/* View_section */}

        <div className="View_section  ms-2 me-2 mt-2 mb-2 pb-4 ps-2 pe-2 ">
        <Chatroombackarrow url="/own-chatroom" />
          <div className="View_box">
            <div className="img_view_section">
              <img src={images.JoyStick}></img>
            </div>
            {/* paragraph-view */}
            <div className="paragraph-view">
              <h4 className="pt-2 pb-1 ">{title}</h4>
              <p className="pt-1 pb-2 text-center mb-0 ">
                Lorem ipsum dolor sit amet, consectetur adi tempor incidila bore
                et dolore mat.
              </p>
              {
                location.pathname==="/joinown-chatroom" || location.pathname==="/lobby-chatroom"?   <button className="bg-dark text-white ps-4 pe-4">Delete</button> : ""
              }
            
            </div>
          </div>
        </div>
      </div>

      {/* new_components */}

      <UserChatRoompeoples setAddMembers={setAddMembers} openAddMembers={openAddMembers}/>
    </>
  );
};

export default UserChatroomview;
