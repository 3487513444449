import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "./style.css"

const Reciveinvoice = () => {
  return (
    <div>
      <Container>
        <div>
          <Row>

            <Col lg='7' md='8'>

              <Row>
                <Col lg='6' md='6'>

                  <p className='back-area-sent mb-1'>Sent by:</p>
                  <p className='back-area-sent pt-0 mb-1'>Date Sent:</p>
                  <p className='back-area-sent pt-0'>Compiled by:</p>

                </Col>

                <Col lg='6' md='6'>

                  <p className='back-area-sent mb-1'>Refrience no:</p>
                  <p className='back-area-sent pt-0 mb-1'>Carrier:</p>
                  <p className='back-area-sent pt-0'>Authorised by:</p>

                </Col>

              </Row>

              <Row className='trms-cndition m-auto'>

                  <p className=' mb-0 pe-0'>Terms and Conditions</p>
                  <textarea
                    placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis nisi leo, eu aliquet nisl ornare eget.... READ MORE "
                    className=" clss border-0  fw-bold"

                  />

              </Row>

            </Col>

            <Col className='ps-3' lg="5" md="4" sm="6" >


              <p className='pay text-center'> Amount Due </p>

              <table class="  table table-bordered">
                <tbody className='tablebody'>

                  <tr>
                    <td className='ps-3 pt-0 pb-1 hgt'>Subtotal</td>
                    <td className='ps-3 pt-0 pb-1 hgt'>500</td>

                  </tr>

                  <tr>
                    <td className='ps-3 pt-0 pb-1 hgt'>Tax 10%</td>
                    <td className='ps-3 pt-0 pb-1 hgt'>100</td>
                  </tr>
                  <tr>
                    <td className='ps-3 pt-0 pb-1 hgt'>Delivery</td>
                    <td className='ps-3 pt-0 pb-1 hgt'>600</td>

                  </tr>
                  <tr>
                    <td className='ps-3 pt-0 pb-1 hgt'>Others</td>
                    <td className='ps-3 pt-0 pb-1 hgt'>600</td>

                  </tr>
                  <tr>
                    <td className='ps-3 pt-0 pb-1 hgt'>Total</td>
                    <td className='ps-3 pt-0 pb-1 hgt'>600</td>

                  </tr>
                </tbody>
              </table>

            </Col>

          </Row>
        </div>

      </Container>
    </div>
  )
}

export default Reciveinvoice
