import { images } from "../../constants";
import "./style.css";
import { useState } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { Navbarapi } from "../../constants/jsonConstant";
import HomeIcon from "@mui/icons-material/Home";
import Logoutmodal from "../Logout_modal/Logoutmodal";
import Modals from "./Modals";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MessageIcon from '@mui/icons-material/Message';
import { BuyingRoute } from "../../constants/jsonConstant";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import MassengerModals from "./MassengerModal";
import MassengerModalMobile from "./MassengerModal/MassengerModalMobile";

  const Navbarr = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(0);
  const handleClose = () => setShow(0);
  const [path] = useState(Navbarapi);
  const location = useLocation();
  const textToShow = path.find((el) => el.route === location.pathname)?.name;
  const [lgShow, setLgShow] = useState(false);
  const [lgMsgShow, setLgMsgShow] = useState(false);
  const [givepath, setGivepath] = useState(BuyingRoute);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: -7,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  }));

  return (
  
  <>
      {show === 1 && (
        <Logoutmodal show={show} handleClose={handleClose} animation={true} />
      )}

      <Modals setLgShow={setLgShow} lgShow={lgShow} />
      <div className="massengerShowComputer">
      <MassengerModals setLgMsgShow={setLgMsgShow} lgMsgShow={lgMsgShow} />
      </div>
      <div className="massengerShowMobile">
      <MassengerModalMobile setLgMsgShow={setLgMsgShow} lgMsgShow={lgMsgShow} />
      </div>


      <Container fluid className="nav-absolute container-border-b">
        <Navbar
          collapseOnSelect
          className=" bg-white w-100 pb-0 mb-0"
          style={{ zIndex: "1" }}
        >
          
          <Container fluid className="ms-0 me-0">
            <Navbar.Brand onClick={() => navigate("/SellingSide")}>
              {/* <img src={images.Selecteziimg} /> */}
              <p className="logo m-0 p-0 fs-7">SELECTEZI</p>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav defaultActiveKey="#home" className="me-auto ms-auto">
                <ul className="navbar-nav me-auto ms-auto mb-2 mb-lg-0 ">
                  <li className="nav-item menu_active">
                    <NavLink
                      activeclassname="active nav-link"
                      aria-current="page"
                      to={"/BuyingSide"}
                      className="navbar_f smallFont pe-2 pe-md-0 pe-lg-0 pe-xl-0"
                      style={{ color: "#7b7b93" }}
                    >
                      <HomeIcon className="mb-1 smallFonthome" />
                      Home
                    </NavLink>
                  </li>

                  <li className="nav-item menu_active ms-lg-3 ms-sm-3 ms-xs-4 setlinks_onsm">
                    <NavLink
                      activeclassname="active nav-link"
                      aria-current="page"
                      className="navbar_f smallFont "
                      style={{
                        color: "#3d3bee",
                        borderBottom: "3px solid #3d3bee",
                        paddingBottom: "10px",
                      }}
                    >
                      {textToShow} taiba
                    </NavLink>
                  </li>
                </ul>
              </Nav>
              <Nav>

              <Nav.Link
                  href=""
                  onClick={() => setLgMsgShow(true)}
                  className="ps-1"
                >
               
               <MessageIcon className="font_black icon_setting" />


               
                </Nav.Link>
                &nbsp;

                <Nav.Link
                  href=""
                  onClick={() => setLgShow(true)}
                  className="ps-1"
                >
                  <NotificationsIcon className="font_black icon_setting" />

                  <StyledBadge badgeContent={3} color="primary"></StyledBadge>
                </Nav.Link>
                &nbsp;
                <Nav.Link eventKey={2} href="" onClick={() => setShow(1)}>
                  <img className="img-width" src={images.LogOut} /> 
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Container>
    </>
  );
};

export default Navbarr;
