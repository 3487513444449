import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import SendIcon from "@mui/icons-material/Send";
import ReplyIcon from "@mui/icons-material/Reply";
import { images } from "../../constants";
import "./Style.css";
import { getAsset } from "../../helpers/helper";
import url from "../../config/axios";
import { useSelector } from "react-redux";
const Commentmodal = ({
  handleclose,
  handleSubmit,
  handleCommentChange,
  comments,
  id,
}) => {
  const { name } = useSelector((state) => state.user);
  const [visibleCommentsCount, setVisibleCommentsCount] = useState(3);
  const [replyInputVisible, setReplyInputVisible] = useState(null);
  const [replyMessage, setReplyMessage] = useState("");
  const [suggestid, setSuggestId] = useState();

  const handleViewMore = () => {
    setVisibleCommentsCount((prevCount) => prevCount + 3);
  };

  const handleReplyIconClick = (index, suggestionId) => {
    setReplyInputVisible(replyInputVisible === index ? null : index);
    setSuggestId(suggestionId);
  };

  const handleReplyMessageChange = (e) => {
    setReplyMessage(e.target.value);
  };

  const handleReplySubmit = () => {
    url
      .put(`/timeline/reply/suggestionId?suggestionId=${suggestid}`, {
        reply: [
          {
            userid: name._id,
            shopid: id,
            role: "",
            imageUrl: "",
            message: replyMessage,
          },
        ],
      })
      .then((response) => {
        // Handle the response here if needed
        setReplyMessage("");
        setReplyInputVisible(null);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <div>
      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal.Dialog className="">
          <div className="noscroll Modal-head">
            <div className="d-flex justify-content-end">
              <ClearSharpIcon onClick={handleclose} alt="close" />
            </div>

            <Row className="pt-1">
              <Col
                lg={2}
                md={2}
                sm={2}
                xs={2}
                className="d-flex justify-content-center p-0"
              >
                <img src={images.Commentspic} alt="" className="commentImg" />
              </Col>
              <Col
                className="inputComment ps-0"
                lg={10}
                md={10}
                sm={10}
                xs={10}
              >
                <div className="custom-input">
                  <div>
                    <input
                      placeholder="Write comment"
                      className=""
                      onChange={handleCommentChange}
                    />
                    <SendIcon onClick={handleSubmit} />
                  </div>
                </div>
              </Col>
            </Row>
                 
            {comments?.data?.suggestions
              ?.slice(0, visibleCommentsCount)
              .map((suggestion, index) => (
                <Row className="pt-1" key={index}>
                  <Col
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    className="d-flex justify-content-center p-0"
                  >
                    <img
                      key={index}
                      src={getAsset(suggestion.imageUrl)}
                      crossOrigin="anonymous"
                      alt=""
                      className="commentImg"
                    />
                  </Col>
                  <Col
                    className="inputComment ps-0"
                    lg={10}
                    md={10}
                    sm={10}
                    xs={10}
                  >
                    <div className="pera-one">
                      <p className=" ps-2 py-3 d-flex justify-content-between align-items-center">
                        {suggestion?.message}
                        <ReplyIcon
                          className="reply-icon ms-2"
                          onClick={() =>
                            handleReplyIconClick(index, suggestion?._id)
                          }
                        />
                      </p>
                    </div>

                    {/* Displaying the replies */}
                    {comments?.data?.replies
                      // ?.filter(reply => reply.suggestionId === suggestion._id) // Filtering replies specific to this comment
                      ?.map((reply, replyIndex) => (
                        <p className=" ps-4 py-1" key={replyIndex}>
                          {reply?.message}
                        </p>
                      ))}

                    {replyInputVisible === index && (
                      <div className=" mt-2">
                        <div className="custom-input">
                          <div>
                            <input
                              placeholder="Write a reply"
                              value={replyMessage}
                              onChange={handleReplyMessageChange}
                            />
                            <SendIcon onClick={handleReplySubmit} />
                          </div>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              ))}

            {visibleCommentsCount < comments?.data?.suggestions?.length && (
              <div
                className="d-flex justify-content-center"
                onClick={handleViewMore}
              >
                <VisibilityIcon className="ph-icon" />
                <p className="ph-view ps-1">View More</p>
              </div>
            )}
          </div>
        </Modal.Dialog>
      </div>
    </div>
  );
};

export default Commentmodal;
