import React from "react";
import BoxData from "../Box";
import CardNav from "../CardNav";
import { Col, Row, Card } from "react-bootstrap";
import { images } from "../../../../constants";
import { useState } from "react";

const FoodRestaurantComp = ({ btn, titleone, titletwo, img_h }) => {
  const [id,setIs]=useState(1);
  const [myinfo,setMyinfo]=useState("single info")
  return (
    <div>
      <Row className="set-one">
        <Col lg="12">
        <CardNav Header={titleone} myid={id} myinfo={myinfo} />

          <Card className="shadow-main mt-2">
            <BoxData
              Title={titletwo}
              Butonn={btn}
              imgg={images.Foodbar}
              url="/BuyRestaurantFood"
              link="/BuyRestaurantFood"
              ms="twentysix-element"
              buy="twenty-four"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default FoodRestaurantComp;
