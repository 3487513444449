import { Container, Col, Row, Button } from "react-bootstrap";
import BackArrow from "../../components/backArrow";
import Rating from "@mui/material/Rating";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Updatefoodform from "../Updatefoodform";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Reportbox from "../../components/Reportbox";
import Reportselectezibox from "../../components/Report to selectezi";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import RatingModel from "../../components/ProductsRating";
import { useNavigate } from "react-router-dom";
import PlaceIcon from "@mui/icons-material/Place";
import ReportIcon from "@mui/icons-material/Report";
import StarIcon from "@mui/icons-material/Star";
import ShareIcon from "@mui/icons-material/Share";
import ChatIcon from "@mui/icons-material/Chat";
import { Form, FormControl } from "react-bootstrap";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import Connectmodal from "../../components/Connect_modal";
import Suggestionbox from "../../components/Suggestionbox";
import ReplyIcon from "@mui/icons-material/Reply";
import url from "../../config/axios"
import { FailedNotify, SuccessNotify } from "../../constants/tostify";
import { useSelector } from "react-redux";

const Foodandrestaurantbuy = () => {
  const { shopid } = useParams();
  const [value] = useState(3);
  const [shopData, setShopData] = useState();
  const { name } = useSelector((state) => state.user);
  const itemData = useSelector((state) => state.itemdata);
  const newItemdata = itemData?.itemdata;
  const regular = [];
  const specialOffers = [];
  const salesPromotion = [];
  console.log("newitemdatttttta", newItemdata)
  // Iterate through ShopItems and categorize them
  newItemdata?.forEach((item) => {
    if (
      (!item.specialOffers || item.specialOffers === "") &&
      (!item.salesPromotion || item.salesPromotion === "")
    ) {
      regular.push(item);
    } else if (!item.specialOffers || item.specialOffers === "") {
      salesPromotion.push(item);
    } else if (!item.salesPromotion || item.salesPromotion === "") {
      specialOffers.push(item);
    } else {
      specialOffers.push(item);
      salesPromotion.push(item);
    }

  });


  console.log("regular", regular);
  console.log("special", specialOffers);
  console.log("sale", salesPromotion);


  const [isloading, setIsloading] = useState(false);
  const [lgShowreport, setLgShowreport] = useState(false);
  const [selectezireport, setSelectezireport] = useState(false);
  const [smShow, setSmShow] = useState(0);
  const [showoneModal, setShowoneModal] = useState(false);
  const [showconnect, setShowconnect] = useState(false);
  const [itemdata, setItemdata] = useState();
  const [lgShow, setLgShow] = useState(0);
  const [key, setKey] = useState("home");
  const handleShow = () => setShowavaliable(true);
  const [showavaliable, setShowavaliable] = useState(false);
  const [Open, setOpen] = useState(0);
  const [foodsearchregular, setFoodsearchregular] = useState();
  const [foodsearchspecial, setFoodsearchspecial] = useState();
  const [foodsearchsale, setFoodsearchsale] = useState();

  const handleClose = () => setShowavaliable(false);

  const navigate = useNavigate();

  const handleTabSelect = () => {
    setShowoneModal(true);
  };

  const handleCloses = () => {
    setShowoneModal(false);
  };
  const handleCloseconnect = () => {
    setShowconnect(false);
  };


  const handleSelect = (k) => {
    if (k === "tab1") {
      handleShow();
      setKey("Connents");
    } else if (k === "Postitems") {
      navigate("/resturent-post-form");
    } else {
    }
  };
  useEffect(() => {
    url.get(`shop/id?id=${shopid}`).then(async (res) => {
      setShopData(res?.data?.data?.Shop);
    });
  }, []);

  useEffect(() => {
    url.get(`/fooditem/?shopid=${shopid}`).then(async (res) => {
      console.log("item rrrrr ", res)
      setItemdata(res.data);

    });
  }, []);



  useEffect(() => {
    url.get(`user/id?id=${name?._id}`).then((res) => {
      setSuggestion((prevState) => ({
        ...prevState,
        imageUrl: res?.data?.data?.User?.imageUrl,
        vendorid: shopData?.shopid,
      }))
    })
  }, [shopData]);

  const [suggestion, setSuggestion] = useState({
    vendorid: "",
    userid: name?._id,
    username: name?.fullname,
    useremail: name?.email,
    role: "",
    imageUrl: "",
  });


  const suggestHandel = (e) => {
    setSuggestion((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [searchItems] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
  ]);

  const handleFocus = () => {
    setShowDropdown(true);
  };


  const handleBlur = () => {
    // Delay the dropdown hide to allow clicks to register on dropdown items
    setTimeout(() => setShowDropdown(false), 100);
  };

  const filterFood = (e) => {
    url.get(`fooditem/searchbydishname/?dishname=${e}&businessType=foodShopItem`).then((res) => {
      setFoodsearchregular(res?.data?.regular);
      setFoodsearchspecial(res?.data?.specialoffer);
      setFoodsearchsale(res?.data?.salespromotion);
    })

  }
  const suggestPost = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post(`/suggestion/`, suggestion)
      .then((res) => {
        SuccessNotify("Suggestion Data is posted successfully");
        setIsloading(false);
      })
      .catch((err) => {
        FailedNotify("Failed to  post suggestion Data");
      });
  };

  return (
    <>
      <Suggestionbox
        lgShow={lgShow}
        setLgShow={setLgShow}
        suggestion={suggestion}
        suggestHandel={suggestHandel}
        suggestPost={suggestPost}
      />

      <Connectmodal
        showconnect={showconnect}
        handleCloseconnect={handleCloseconnect}
      />

      <RatingModel setSmShow={setSmShow} smShow={smShow} />
      <Reportbox
        lgShowreport={lgShowreport}
        setLgShowreport={setLgShowreport}
      />
      <Reportselectezibox
        selectezireport={selectezireport}
        setSelectezireport={setSelectezireport}
      />

      {Open && <Updatefoodform setOpen={setOpen} Open={Open} />}
      <Container>
        <div className="screenHeight">
          <div className="border b-radius mx-auto servicesHeight col-lg-8 mx-auto">
            <Row className="mx-auto col-10">
              <Col lg="6" className="ps-0">
                <BackArrow back="/create-service" />
              </Col>
              <Col lg="6" className="text-end pe-0">
                <button className="share_btton  mt-2">
                  share
                  <ReplyIcon
                    style={{
                      transform: "rotateY(180deg)",
                      color: "#0353A4",
                      fontSize: "medium",
                    }}
                  />

                </button>
              </Col>
            </Row>
            <Row className="p-0 m-0 pt-2 pb-2">
              <Col lg="10" className="mx-auto">
                <Row className="border_3">
                  <Col lg="5" xl="5" className="">
                    <div className="p-3 pt-1 pb-1">
                      <img
                        src={shopData?.image}
                        alt=""
                        className="Restaurant-img"
                      />
                    </div>
                  </Col>
                  <Col lg="7" xl="7" className="mt-2">
                    <Row>
                      <Col lg="6" xl="8" className="pe-0">
                        <h2 className="font-bold">{shopData?.shopname}</h2>
                        <span className="font-bold font_12">Profile ID :</span>
                        <span className="font_12" style={{ color: "grey" }}>
                          {shopData?.shopid}
                        </span>
                        <p className="mb-0 font_12">
                          <PlaceIcon style={{ fontSize: "16px" }} /> {shopData?.shopaddress}
                        </p>
                        <div className="">
                          <p className="mb-0 font_13">
                            {shopData?.description}
                          </p>
                        </div>
                        <div>
                          <p className="mb-1 font_small w-25 d-flex">
                            <Typography
                              component="legend"
                              className="font_small mt-1"
                            >
                              <b>3.6</b>
                            </Typography>
                            <Rating name="read-only" readOnly />
                          </p>
                        </div>
                        <div className="">
                          <div className="d-flex">
                            <div
                              className="d-flex"
                              style={{ textDecoration: "none" }}
                              onClick={() => setSmShow(true)}
                            >

                              <StarIcon className="report-icn mt-1 cursor_P" />
                              <p className="font_13 m-0 cursor_P">
                                &nbsp;Rate This Services
                              </p>
                            </div>
                          </div>
                          <div
                            className="d-flex mt-1"
                            onClick={() => setShowconnect(true)}
                          >
                            <ShareIcon className="report-icn " />
                            <p className="font_13 m-0 cursor_P">
                              &nbsp;Send Connection Request
                            </p>
                          </div>

                          <div
                            className="d-flex mt-1"
                            onClick={() => setLgShow(1)}
                          >
                            <ChatIcon className="report-icn " />
                            <p className="font_13 m-0 cursor_P">
                              &nbsp;Give Suggestion To Service Owner
                            </p>
                          </div>

                          <div
                            className="d-flex mt-1"
                            onClick={() => setLgShowreport(true)}
                          >
                            <ReportIcon className="report-icn" />
                            <p className="font_13 m-0 cursor_P">
                              &nbsp;Report To Service Owner
                            </p>
                          </div>

                          <div
                            className="d-flex mt-1"
                            onClick={() => setSelectezireport(true)}
                          >
                            <ReportProblemIcon
                              className="report-icn"
                              style={{ color: "red" }}
                            />
                            <p className="font_13 m-0 cursor_P">
                              &nbsp;Report To Selectezi
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col
                        lg="6"
                        xl="4"
                        className="d-flex flex-column text-end"
                      >
                        <div className="mb-5 pb-3 text-end">
                          <QrCode2Icon style={{ fontSize: "35px" }} />
                        </div>

                        <div>
                          <Button className=" custom-available-button ps-3 pe-3  pt-2 pb-2 mt-3">
                            <FiberManualRecordIcon
                              fontSize="small"
                              style={{ color: "green" }}
                            />
                            Available
                          </Button>
                        </div>

                        <div>
                          <Button className="mt-3 custom-button-one ps-4 pe-4 pt-2 pb-2 mt-3 btn btn-primary">
                            <CallIcon fontSize="small" />
                            &nbsp; Call &nbsp;&nbsp;
                          </Button>
                        </div>

                        <div>
                          <Button className="custom-button-one ps-3 pe-3  pt-2 pb-2 mt-3 btn btn-primary mt-3">
                            <EmailIcon fontSize="small" />
                            &nbsp; Message
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Col lg="12" className="mt-4 position-relative">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => {
                      setKey(k);
                      handleSelect(k);
                    }}
                    className="mb-3 ms-1"
                  >
                    <Tab
                      eventKey="home"
                      title="Regular Items"
                      className="resturent-height noscroll"
                    >
                      {

                        foodsearchregular && foodsearchregular?.length > 0 ?
                          foodsearchregular?.map((box) => (
                            <Row className="mt-1 border_3 p-0 m-0">
                              <Col className="ps-0" lg="4" xl="4">
                                <img className="w-100" src={box?.images} alt="" />
                              </Col>
                              <Col className="" lg="8" xl="8">
                                <div className="row">
                                  <div className="col-3 p-0">
                                    <h6 className="ms-3 mt-4">{box?.dishname}</h6>
                                  </div>
                                  <div className="col-6 p-0">
                                    <h6 className="mt-4">
                                      Prepration Time:{box?.preparationTime}</h6>
                                  </div>

                                  <div className="col-3 p-0 text-center">
                                    <button
                                      class="mt-4 connect-btn mt-1"
                                      onClick={() => setOpen(box?._id)}
                                    >
                                      View
                                    </button>
                                  </div>
                                </div>

                                <h6 className="ms-1 mt-2">Description</h6>
                                <p className="ms-1 mt-2 font_14">{box?.description}</p>

                                <div className="d-flex justify-content-end">
                                  <h6>Rating</h6>
                                  <Rating name="read-only" readOnly />
                                </div>
                              </Col>
                            </Row>
                          ))

                          : regular?.map((box) => (
                            <Row className="mt-1 border_3 p-0 m-0">
                              <Col className="ps-0" lg="4" xl="4">
                                <img className="w-100" src={box?.images} alt="" />
                              </Col>
                              <Col className="" lg="8" xl="8">
                                <div className="row">
                                  <div className="col-3 p-0">
                                    <h6 className="ms-3 mt-4">{box?.dishname}</h6>
                                  </div>
                                  <div className="col-6 p-0">
                                    <h6 className="mt-4">
                                      Prepration Time:{box?.preparationTime}</h6>
                                  </div>

                                  <div className="col-3 p-0 text-center">
                                    <button
                                      class="mt-4 connect-btn mt-1"
                                      onClick={() => setOpen(box?._id)}
                                    >
                                      View
                                    </button>
                                  </div>
                                </div>
                                <h6 className="ms-1 mt-2">Description</h6>
                                <p className="ms-1 mt-2 font_14">{box?.description}</p>

                                <div className="d-flex justify-content-end">
                                  <h6>Rating</h6>
                                  <Rating name="read-only" readOnly />
                                </div>
                              </Col>
                            </Row>
                          ))

                      }

                    </Tab>
                    <Tab
                      eventKey="saleitems"
                      title="Sales Promotion"
                      className="resturent-height noscroll"
                    >

                      {

                        foodsearchsale && foodsearchsale?.length > 0 ?
                          foodsearchsale?.map((box) => (
                            <Row className="mt-1 border_3 p-0 m-0">
                              <Col className="ps-0" lg="4" xl="4">
                                <div className="position-relative">
                                  <div className="salestwo ps-2 pe-2">
                                    Sales {box?.salesPromotion}
                                  </div>
                                  <img className="w-100" src={box.IMage} alt="" />
                                </div>

                              </Col>
                              <Col className="" lg="8" xl="8">
                                <div className="row">
                                  <div className="col-3 p-0">
                                    <h6 className="ms-3 mt-4">{box?.dishname}</h6>
                                  </div>
                                  <div className="col-6 p-0">
                                    <h6 className="mt-4">{box?.preparationTime}</h6>
                                  </div>

                                  <div className="col-3 p-0 text-center">
                                    <button
                                      class="mt-4 connect-btn mt-1"
                                      onClick={() => setOpen(box?._id)}
                                    >
                                      View
                                    </button>
                                  </div>
                                </div>
                                <h6 className="ms-1 mt-2">{box?.description}</h6>
                                {/* <p className="ms-1 mt-2 font_14">{box.Phara}</p> */}
                                <div className="d-flex justify-content-end">
                                  <h6>Rating</h6>
                                  <Rating name="read-only" readOnly />
                                </div>
                              </Col>
                            </Row>
                          ))

                          :
                          salesPromotion?.map((box) => (
                            <Row className="mt-1 border_3 p-0 m-0">
                              <Col className="ps-0" lg="4" xl="4">
                                <div className="position-relative">
                                  <div className="salestwo ps-2 pe-2">
                                    Sales {box?.salesPromotion}
                                  </div>
                                  <img className="w-100" src={box.IMage} alt="" />
                                </div>
                              </Col>
                              <Col className="" lg="8" xl="8">
                                <div className="row">
                                  <div className="col-3 p-0">
                                    <h6 className="ms-3 mt-4">{box?.dishname}</h6>
                                  </div>
                                  <div className="col-6 p-0">
                                    <h6 className="mt-4">{box?.preparationTime}</h6>
                                  </div>

                                  <div className="col-3 p-0 text-center">
                                    <button
                                      class="mt-4 connect-btn mt-1"
                                      onClick={() => setOpen(box?._id)}
                                    >
                                      View
                                    </button>
                                  </div>
                                </div>
                                <h6 className="ms-1 mt-2">{box?.description}</h6>
                                {/* <p className="ms-1 mt-2 font_14">{box.Phara}</p> */}
                                <div className="d-flex justify-content-end">
                                  <h6>Rating</h6>
                                  <Rating name="read-only" readOnly />
                                </div>
                              </Col>
                            </Row>
                          ))


                      }
                    </Tab>

                    <Tab
                      eventKey="specialitems"
                      title="Special Offers"
                      className="resturent-height noscroll"
                    >
                      {

                        foodsearchspecial && foodsearchspecial.length > 0 ?

                          foodsearchspecial?.map((box) => (
                            <Row className="mt-1 border_3 p-0 m-0">
                              <Col className="ps-0" lg="4" xl="4">
                                <div className="position-relative">
                                  <div className="sales w-50 ps-2 pe-2">
                                    {box?.specialOffers}
                                  </div>
                                  <img className="w-100" src={box.IMage} alt="" />
                                </div>
                              </Col>

                              <Col className="" lg="8" xl="8">
                                <div className="row">
                                  <div className="col-3 p-0">
                                    <h6 className="ms-3 mt-4">{box?.dishname}</h6>
                                  </div>
                                  <div className="col-6 p-0">
                                    <h6 className="mt-4">{box?.preparationTime}</h6>
                                  </div>

                                  <div className="col-3 p-0 text-center">
                                    <button
                                      class="mt-4 connect-btn mt-1"
                                      onClick={() => setOpen(box?._id)}
                                    >
                                      View
                                    </button>
                                  </div>
                                </div>
                                <h6 className="ms-1 mt-2">{box?.description}</h6>
                                {/* <p className="ms-1 mt-2 font_14">{box.Phara}</p> */}
                                <div className="d-flex justify-content-end">
                                  <h6>Rating</h6>
                                  <Rating name="read-only" readOnly />
                                </div>
                              </Col>
                            </Row>
                          ))

                          : specialOffers?.map((box) => (
                            <Row className="mt-1 border_3 p-0 m-0">
                              <Col className="ps-0" lg="4" xl="4">
                                <div className="position-relative">
                                  <div className="sales w-50 ps-2 pe-2">
                                    {box?.specialOffers}
                                  </div>
                                  <img className="w-100" src={box.IMage} alt="" />
                                </div>
                              </Col>

                              <Col className="" lg="8" xl="8">
                                <div className="row">
                                  <div className="col-3 p-0">
                                    <h6 className="ms-3 mt-4">{box?.dishname}</h6>
                                  </div>
                                  <div className="col-6 p-0">
                                    <h6 className="mt-4">{box?.preparationTime}</h6>
                                  </div>

                                  <div className="col-3 p-0 text-center">
                                    <button
                                      class="mt-4 connect-btn mt-1"
                                      onClick={() => setOpen(box?._id)}
                                    >
                                      View
                                    </button>
                                  </div>
                                </div>
                                <h6 className="ms-1 mt-2">{box?.description}</h6>
                                {/* <p className="ms-1 mt-2 font_14">{box.Phara}</p> */}
                                <div className="d-flex justify-content-end">
                                  <h6>Rating</h6>
                                  <Rating name="read-only" readOnly />
                                </div>
                              </Col>
                            </Row>
                          ))


                      }
                    </Tab>

                  </Tabs>
                  <div className="position-absolute search-absolute">
                    <Form
                      className="position-relative"
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    >
                      <FormControl
                        type="text"
                        placeholder="Search"
                        className="mr-sm-2 search-buing-area"
                        onChange={(e) => filterFood(e.target.value)}
                      />

                    </Form>
                  </div>
                </Col>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>

  );
};

export default Foodandrestaurantbuy;
