import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Images from "../../constants/images";
import { useEffect, useState } from "react";
import url from "../../config/axios";
import "./style.css";
const Reportmodal = ({ setOpenBox, reportM }) => {
  const [report, setReport] = useState();
  useEffect(() => {
    url.get(`/help/id?id=${reportM}`).then((res) => {
     
      setReport(res?.data?.data?.help);
    });
  }, []);

  return (
    <div>
      <Container className="">
        <div>
          <div>
            <Modal.Header
              className="report-bttn"
              closeButton
              onClick={() => {
                setOpenBox(1);
              }}
            >
              <Modal.Title className="ms-3">Report</Modal.Title>
            </Modal.Header>
            <hr />
            <div className="report-hgt noscroll">
              <div>
                <h6 className="report-bug ms-3 p-2">{report?.problem}</h6>
              </div>
              <h6 className="mt-4 ms-3">Report Description*</h6>
              <div>
                <p className="text-0 ms-3 font_sm_14 p-2">
                  {report?.desciription}
                </p>
              </div>
              <h6 className="mt-4 mb-3 ms-3">Screenshots*</h6>
              <Row className="m-0">
                <Col className="col-4">
                  <img
                    className="report-image1 ms-1"
                    src={report?.image}
                    alt=""
                  />
                </Col>
                <Col className="col-4">
                  <img
                    className="report-image1 ms-1"
                    src={report?.image}
                    alt=""
                  />
                </Col>
                <Col className="col-4">
                  <img
                    className="report-image1 ms-1"
                    src={report?.image}
                    alt=""
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-end mt-3 me-2">
                <button className="text-white bg-primary btn pt-0 pb-0">
                  Reply
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default Reportmodal;
