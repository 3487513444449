import { ShopModelCarousal } from "../../constants/jsonConstant";
import { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import Modal from "react-bootstrap/Modal";
import { images } from "../../constants";
import url from "../../config/axios"
// import "./fooddataModel.css";
import { Row, Col, Tabs, Tab, Card, Container, Button } from "react-bootstrap";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Rating from "@mui/material/Rating";
import { RatingStar } from "../../constants/jsonConstant";
import BackArrow from "../../components/backArrow";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./style.css";
import { getAsset } from "../../helpers/helper";
import moment from "moment";

const Updatefoodform = ({ Open, setOpen }) => {
  const [increase, setIncrease] = useState(1);
  const { name } = useSelector((state) => state.user);
  const [fooddata, setFooddata] = useState();
  useEffect(() => {
    url.get(`fooditem/id?id=${Open}`).then((response) => {
      console.log("abc", response)
      setFooddata(response?.data?.data)
    });
  }, []);
  console.log("foooodyyy", fooddata)
  const [value] = useState(5);
  const [valueone] = useState(4);
  const [valuetwo] = useState(3);
  const [valuethree] = useState(2);
  const [valuefour] = useState(1);

  const location = useLocation();

  useEffect(() => {
    if (Open) {
      const imgs = document.querySelectorAll('.image-gallery-image');
      imgs.forEach((element) => {
        element.setAttribute('crossorigin', 'anonymous');
      });
    }
  }, [Open]);

  useEffect(() => {

    if (Open) {
      const thumimgs = document.querySelectorAll('.image-gallery-thumbnail-image');

      thumimgs.forEach(element => {
        element?.setAttribute('crossorigin', 'anonymous');
      });
    }

  }, [Open]);

  const ShopModelCarousal = [
    {
      id: 1,
      original: getAsset(fooddata?.images ? fooddata?.images[0] : fooddata?.image[0]),
      thumbnail: getAsset(fooddata?.images ? fooddata?.images[0] : fooddata?.image[0]),



    },
    {
      id: 2,
      original: getAsset(fooddata?.images ? fooddata?.images[1] : fooddata?.image[1]),
      thumbnail: getAsset(fooddata?.images ? fooddata?.images[1] : fooddata?.image[1]),

    },
    {
      id: 3,
      original: getAsset(fooddata?.images ? fooddata?.images[2] : fooddata?.image[2]),
      thumbnail: getAsset(fooddata?.images ? fooddata?.images[2] : fooddata?.image[2]),

    },
    {
      id: 4,
      original: getAsset(fooddata?.images ? fooddata?.images[3] : fooddata?.image[3]),
      thumbnail: getAsset(fooddata?.images ? fooddata?.images[3] : fooddata?.image[3]),

    },
    {
      id: 5,
      original: getAsset(fooddata?.images ? fooddata?.images[4] : fooddata?.image[4]),
      thumbnail: getAsset(fooddata?.images ? fooddata?.images[4] : fooddata?.image[4]),

    },
  ];

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={Open}
        onHide={() => setOpen(false)}
      >
        <Modal.Body className="">
          <div>
            <BackArrow />
          </div>
          <div className="ps-4">
            <p>Posted At : {moment(fooddata?.createdAt).format("DD-MM-YYYY")}</p>
          </div>
          <Row>
            <Col lg="6">
              <div className="row ">
                <ImageGallery
                  showPlayButton={false}
                  showFullscreenButton={false}
                  className="mb-0"
                  items={ShopModelCarousal}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className=" ps-4 pe-4 pt-4 pb-1 bg-vilot View-Model-Shadow ">
                <div className="d-flex justify-content-end">
                  {/* <button className="text-white bg-blue share_font">share</button> */}
                  <Button variant="primary">Share</Button>
                </div>

                <h4 className="fw-bold">{fooddata?.dishname}</h4>
                <p className="col-10 fw-lighter">
                  {fooddata?.description}
                </p>
                <div>
                  <h4>price</h4>
                  <h3 className="font-blue">{fooddata?.currency.slice(0, 3)}&nbsp;{fooddata?.price}</h3>
                </div>
                <div>
                  <p className="mb-0">Contact us on</p>
                  <p className="mb-0 pt-2 pb-2">{name?.email}</p>
                  <p className="mb-0">{name?.phonenumber}</p>
                </div>

                {/* <div className="bg-white quantityBox mt-2 mb-2  pe-2 ps-2 w-25 d-flex align-items-center ">
                  <div>
                    <p className="mb-0 pe-1">QTY:</p>
                  </div>
                  <div>
                    <p className="mb-0 pe-1">{increase}</p>
                  </div>

                  <div>
                    <div className="d-flex flex-column align-items-center ">
                      <ExpandLessIcon
                        fontSize="small"
                        onClick={() => setIncrease(increase + 1)}
                      />
                      <ExpandMoreIcon
                        fontSize="small"
                        onClick={() => setIncrease(increase - 1)}
                      />
                    </div>
                  </div>
                </div> */}

                <div className="pt-3 cursor">


                  <button
                    className=" d-flex align-items-center justify-content-center mt-5 mb-0 border rounded ps-4 pe-4 pt-1 pb-1 bg-blue ms-3 me-3 w-75 text-white"
                    onClick={""}
                  >
                    <img src={images.Cart} alt="" />
                    <span className="ps-2">Add to cart</span>
                  </button>

                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Updatefoodform;
