import { Container, Row, Col } from "react-bootstrap";
import Usedvehicleform from "../../../components/Used_Vehicle_shop";
import url from "../../../config/axios";
import { SuccessNotify, FailedNotify } from "../../../constants/tostify";
import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Loader from "../../../components/Loader";
import { publishImage } from "../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
const Updateusevehicle = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [displayimg,setDisplayimg]=useState();
  const { name } = useSelector((state) => state.user);
  const [isloading, setIsloading] = useState(false);
  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setUsedvehicle((usedvehicle) => ({
        ...usedvehicle,
        images: abc
      }));

      setDisplayimg(abc);
    
    }catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const [usedvehicle, setUsedvehicle] = useState({
    userid: name._id,
    shopid: "",
    modelYear: "",
    model: "",
    make: "",
    version: "",
    registeredIn: "",
    color: "",
    assembly: "",
    city: "",
    cityArea: "",
    engineType: "",
    engineCapactiy: "",
    mileage: "",
    transmission: "",
    price: "",
    contact: "",
    description: "",
    images: [""],
    status: false,
  });

  const UpdateVehicleData = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .put(`sellvehicle/id/?id=${id}`, usedvehicle)
      .then(async (response) => {
        console.log("response", response);
        if (response?.data?.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Add Updated Successfully");
            navigate(`/used-vehicle-profile/${usedvehicle.shopid}`);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed To Update Add");
      });
  };

  useEffect(() => {
    url.get(`sellvehicle/id/?id=${id}`).then(async (res) => {
      setUsedvehicle(res.data.data.SellVehicle);
    });
  }, []);

  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }

  return (
    <>
      <Container>
        <Row className="screenHeight">
          <Usedvehicleform
            setUsedvehicle={setUsedvehicle}
            UpdateVehicleData={UpdateVehicleData}
            usedvehicle={usedvehicle}
            butn="Update"
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            displayimg={displayimg}
            setDisplayimg={setDisplayimg}
          />
        </Row>
      </Container>
    </>
  );
};

export default Updateusevehicle;
