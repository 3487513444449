import "./userChatprofile.css";
import { images } from "../../../../../constants";
const UserChatsearchBar = () => {
  return (
    <>
      {/* main_div */}
      <div className="main_div">
        {/* search_bar */}
        <div
          style={{ background: "var(--sky-color)" }}
          className="search_bar  ms-lg-2 me-lg-2 "
        >
          {/* img */}
          <div className="img-para-flex pt-2 pb-3 ps-2 pe-2 heightmatch">
            <div>
              <img
                src={images.ProfileImg}
                className="img-width_chat"
                alt="Profileimg"
              ></img>
            </div>
            <div>
              <h4 className="text-white ps-3 pe-2 pt-1 searching">
                Search by Country, State, City
              </h4>
            </div>
          </div>
          {/* select_box_section */}
          <span className="ps-3 pe-3 text-white  pb-1 lablee">Category</span>
          <div className="ps-3 pe-3">
            <select
              style={{ background: "var(--sky-color)" }}
              name="Search"
              className="w-100 select-box pt-1 pb-1 ps-3 pe-3"
            >
              <option value="">Search</option>
            </select>
          </div>

          {/* select-box-2 */}
          <span className="ps-3 pe-3 text-white pt-1 pb-1 lablee">Country</span>
          <div className="ps-3 pe-3">
            <select
              style={{ background: "var(--sky-color)" }}
              name="Search"
              className="w-100 select-box pt-1 pb-1 ps-3 pe-3"
            >
              <option value="">Pakistan</option>
            </select>
          </div>

          {/* select-box-3 */}
          <span className="ps-3 pe-3 text-white pt-1 pb-1 lablee">State</span>
          <div className="ps-3 pe-3">
            <select
              style={{ background: "var(--sky-color)" }}
              name="Search"
              className="w-100 select-box pt-1 pb-1 ps-3 pe-3"
            >
              <option value="">khyber pakhtunkhwa</option>
            </select>
          </div>

          {/* select-box-4 */}
          <span className="ps-3 pe-3 text-white pt-1 pb-1 lablee">City</span>
          <div className="ps-3 pe-3">
            <select
              style={{ background: "var(--sky-color)" }}
              name="Search"
              className="w-100 select-box pt-1 pb-1 ps-3 pe-3"
            >
              <option value=""> Abbottabad</option>
            </select>
          </div>

          {/* Btns_Section_Starts */}
          <div className="tns_Section_Starts pt-3">
            <p className="ps-3 pe-3 text-white pt-1 pb-1 mb-0">Nearby Me</p>
          </div>

          {/* new_buttons */}
          <div className="new_buttons ps-3 pe-3 pt-2">
            <p className=" text-white">0 Km</p>
            <button className="btn-minis">-</button>
            <button className="btn-plus">+</button>
          </div>

          {/* search-btn */}
          <div className="ps-3 pe-3">
            <button className=" Btn_radius create-btn text-white mt-3 mb-3 pt-1 pb-1 ">
              Search
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserChatsearchBar;
