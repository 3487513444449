
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "../../config/axios";
import ShopAdds from "../../components/shopAds/ShopAdds";
import BackArrow from "../../components/backArrow";
import Businessprofiletitle from "../../components/BusinessProfile/Component/Title/businessprofiletitle";
import Rawmaterialcard from "../../components/BusinessProfile/Component/Rawmaterialcard/Rawmaterialcard";
import Timeline from "../../components/Timeline/timeline";
// import {
//     Businessprofiletitle,
//     Rawmaterialcard,
//     Timeline,
// } from "../../../pages/index";
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux'
const TransportBussinesProfile = () => {
    const { id } = useParams();
    const { name } = useSelector((state) => state.user)
    const [tabsData, setTabsData] = useState([]);
    const [activeTab, setActiveTab] = useState('');
    const [loading, setLoading] = useState(true);
    const [createPost, setCreatePost] = useState(
        {
            shopid: id,
            userid: name?._id,
            businessType: "transportShop",
            my: [{
                heading: "",
                content: "",
                img: [""],
                video: [""],

            }]

        }
    )
    useEffect(() => {
        axios.get(`conection/publicOrShopAgainstPublic/?businessType=transportShop&shopid=${id}&userid=${name?._id}`)
            .then(async (res) => {
                const responseData = res.data.data;
                const dynamicTabs = Object.keys(responseData).map((key) => ({
                    eventKey: key,
                    title: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'),
                    data: responseData[key],
                }));
                setTabsData(dynamicTabs);
                if (dynamicTabs.length > 0) {
                    setActiveTab(dynamicTabs[0].eventKey);
                }
                setLoading(false);
            })
            .catch((err) => {
                console.error("API call error:", err);
                setLoading(false);
            });
    }, [id]);

    const handleTabSelect = (eventKey) => {
        setActiveTab(eventKey);
    };

    const getActiveTabData = () => {
        const activeTabData = tabsData.find(tab => tab.eventKey === activeTab);
        return activeTabData ? activeTabData.data : [];
    };

    return (
        <>
            <Container>
                <Row>
                    <Col lg="7" className="screenHeight p-0">
                        <div className="border b-radius">
                            <div className="ms-2"><BackArrow /></div>
                            <ShopAdds />
                            <Businessprofiletitle linkurl="/transport-profile" id={id} />
                            <Rawmaterialcard />
                        </div>
                    </Col>

                    <Col
                        lg="5"
                        sm="12"
                        className="ps-4 screenHeight"
                        style={{ position: "relative" }}
                    >
                        <div className="border b-radius">
                            <Timeline
                                tabsData={tabsData}
                                activeTab={activeTab}
                                onTabSelect={handleTabSelect}
                                tabData={getActiveTabData()}
                                loading={loading}
                                createPost={createPost}
                                setCreatePost={setCreatePost}
                                setLoading={setLoading}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default TransportBussinesProfile;
