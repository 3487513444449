import React from "react";
import { Button, Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { images } from "../../constants";
import Card from "react-bootstrap/Card";
import ClearIcon from "@mui/icons-material/Clear";
import { useRef, useState } from "react";
import { publishImage } from "../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
import { getAsset } from "../../helpers/helper";
import { FailedNotify } from "../../constants/tostify";
import BackArrow from "../../components/backArrow";

const ReportSelectezi = () => {
  const [displayimg, setDisplayimg] = useState();

  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      //  setUserData((userData) => ({
      //    ...userData,
      //    images: abc
      //  }));

      setDisplayimg(abc);
    } catch (error) {
      FailedNotify("Failed! Try Again");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const removeImage = (index) => {
    const updatedImages = [...displayimg];
    updatedImages.splice(index, 1);
    setDisplayimg(updatedImages);
  };
  return (
    <div className=" d-flex justify-content-center align-items-center">
      <div className="screenHeight Form-Shadow  col-lg-6 col-md-7 col-sm-9 col-xs-12   pb-3">
        <div className="arrowback ps-2">
          <BackArrow black="black" />
        </div>
        <div className="ps-5 pe-5 pt-1 ms-3 me-3">
          <div className="pt-4 ">
            <h4 className="createHeading">
              Please Select a Problem To Continue
            </h4>
          </div>

          <div className="d-flex justify-content-between pt-1">
            <div className="d-flex flex-column pt-3 col-6 ps-2">
              <label className="custm-lble-2 pb-2">Select to continue</label>
              <select
                name="category"
                placeholder="Select Catagory"
                className="select-option input-style pt-1 pb-1 ps-2 pe-2 "
              >
                <option>Select one option</option>
              </select>
            </div>

            <div className="d-flex flex-column pt-3 col-6 ps-2">
              <label className="custm-lble-2 pb-2">
                You can report after selecting a problem.
              </label>
              <select
                name="category"
                placeholder="Select Catagory"
                className="select-option input-style pt-1 pb-1 ps-2 pe-2 "
              >
                <option>Select one option</option>
              </select>
            </div>
          </div>

          <div>
            <div className="d-flex flex-column pt-2 ms-2">
              <label className="custm-lble-2 pb-2">Report Description*</label>
              <textarea
                name="description"
                placeholder="Description"
                className="input-style pt-1 ps-2 pb-5 pe-2 "
              />
            </div>
          </div>

          <Col className="mt-4 ">
            <div className="custm-lble-2 ps-3">Add ScreenShots*</div>
            <Col lg="9" className="me-auto">
              <Row>
                <Col lg={3} className="mt-2 ms-1">
                  <Card
                    style={{ width: "6rem", height: "6rem" }}
                    {...getRootProps()}
                    className="dropzone"
                  >
                    <Card.Img className="imet-fit" src={images.AddImage} />
                    <Card.Body>
                      <input
                        type="file"
                        id="file"
                        style={{ display: "none" }}
                        {...getInputProps()}
                        multiple
                      />
                    </Card.Body>
                  </Card>
                </Col>
                {displayimg?.length > 0 && (
                  <Col lg={7} className=" mt-1 d-flex">
                    {displayimg.map((image, index) => (
                      <Card
                        className="me-2 mt-1"
                        style={{
                          width: "6rem",
                          height: "6rem",
                          border: "2px solid",
                        }}
                      >
                        <Card.Img
                          className="imet-fit upload-img mt-0"
                          src={getAsset(image)}
                          alt=""
                          crossOrigin="anonymous"
                        />
                        <div
                          className="close-btnone"
                          onClick={() => removeImage(index)}
                        >
                          <ClearIcon />
                        </div>
                      </Card>
                    ))}
                  </Col>
                )}
              </Row>
            </Col>
          </Col>

          <div className="d-flex justify-content-end">
            <button className="btn2" >
              Send Report
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportSelectezi;
