import React from "react";
import Card from "react-bootstrap/Card";
import BoxData from "../Box";
import CardNav from "../CardNav";
import {BusinessDatatwo } from "../../../../constants/jsonConstant";
import { Col, Row } from "react-bootstrap";
import { images } from "../../../../constants";
import "./style.css";

const JobOppurtunity = ({ btn, titleone, titletwo }) => {
  return (
    <div>
      <Row>
        {BusinessDatatwo.map((data) => (
          <Col lg="6" md="6" sm="6" xs="6">
            <CardNav Header={data.title} para={data.para} myinfo={data.info} myid={data.id} />

            <Card className="shadow-joboprt mt-2">
              {(data.id === 1 && (
                <BoxData
                  Title={titleone}
                  Butonn={btn}
                  imgg={images.Jobs}
                  ms="nine-element"
                  buy="nine"
                  url="/company-form"
                  link="/find-job"
                />
              )) ||
                (data.id === 2 && (
                  <BoxData
                     Title={titletwo}
                    Butonn={btn}
                    imgg={images.Candidates}
                    link="/find-candidates"
                    ms="ten-element"
                    buy="ten"
                  />
                ))}
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default JobOppurtunity;
