import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { useLocation } from "react-router-dom";
import DashboardinfoModal from "../../../../components/Dashboardinfo_modal";
import { Modaldataid } from "../../../../constants/jsonConstant";
import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./style.css";

export default function CardNav({ Header, para, myid, myinfo }) {
  const location = useLocation();
  const [lgShow, setLgShow] = useState(0);

  const handleModal = (e) => {
    setLgShow(e);
  };
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Click on Button to See Details
    </Tooltip>
  );
  return (
    <div>
      {Modaldataid.map(
        (e) =>
          lgShow === e.id && (
            <DashboardinfoModal
              lgShow={lgShow}
              setLgShow={setLgShow}
              heading={Header}
              info={myinfo}
            />
          )
      )}

      {location.pathname === "/BuyingSide" ? (
        <div className="cursor_P mt-2">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <p className="mb-0">
              <Navbar className="Main-nav-css">
                <div className="d-flex justify-content-center flex-column w-100 ps-lg-2 pe-lg-2 ps-md-2 pe-md-2 pt-md-2 pt-lg-2 pt-sm-0">
                  <div onClick={() => handleModal(myid)}>
                    <p className="Alldashboardfont bg-blue-lg text-white mb-md-2  mb-lg-2 text-center pt-1 pb-1 m-sm-0 ">
                      {Header}
                    </p>
                  </div>
                  {location.pathname === "/BuyingSide" ? (
                    ""
                  ) : (
                    <div>
                      <p className="font_11 text-center mb-1">
                        {para}

                        <span
                          style={{
                            color: "#0353A4",
                            fontWeight: "800",
                            fontSize: "9px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleModal(myid)}
                        >
                          Read More
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </Navbar>
            </p>
          </OverlayTrigger>
        </div>
      ) : (
        <Navbar className="Main-nav-css">
          <div className="d-flex justify-content-center flex-column w-100 ps-3 pe-3 pt-2">
            <div onClick={() => handleModal(myid)}>
              <p className="Alldashboardfont bg-blue-lg text-white mb-2 text-center pt-1 pb-1">
                {Header}
              </p>
            </div>
            {location.pathname === "/BuyingSide" ? (
              ""
            ) : (
              <div>
                <p className="font_11 sellingpara text-center mb-1 d-none d-lg-block">
                  {para}

                  <span
                    style={{
                      color: "#0353A4",
                      fontWeight: "800",
                      fontSize: "9px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleModal(myid)}
                  >
                    Read More
                  </span>
                </p>
              </div>
            )}
          </div>
        </Navbar>
      )}
    </div>
  );
}
