import React from "react";
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { images } from "../../constants";
import BackArrow from "../backArrow";
import OwnerPropertyViewModel from "../ShopViewModel/OwnerPropertyViewModel";
import { Deletemodal } from "../../pages/index";
import ShareModel from "../Sharemodel";
import ShareOption from "../Shareoption";
import RedoIcon from "@mui/icons-material/Redo";
import { getAsset } from "../../helpers/helper";

const SalePerosonalProperty = ({ values, setValues }) => {
  const [showmodal, setShowmodal] = useState(0);
  const [show, setShow] = useState(0);
  const [showshare, setShowshare] = useState(0);
  const [shows, setShows] = useState(0);
  const handleShow = () => {
    setShowmodal(1);
    setItemshow(0);
  };
  const handleClosetwo = () => setShowmodal(0);
  const [itemshow, setItemshow] = useState(1);
  const handleClose = () => setShow(0);
  const navigate = useNavigate();
  const handleCloseshare = () => {
    setShowshare(0);
    setShows(1);
  };

  const handleClosemodal = () => {
    setShows(0);
  };

  const handleShowshare = () => {
    setShows(0);
    setShowshare(1);
  };
  const [index, setIndex] = useState();
  return (
    <>
      <ShareOption showshare={showshare} handleCloseshare={handleCloseshare} />
      <ShareModel
        handleClosemodal={handleClosemodal}
        show={shows}
        handleShowshare={handleShowshare}
      />
      {show === 1 && (
        <Deletemodal show={show} title="Profile" handleClose={handleClose} />
      )}

      <Container>


        {itemshow && (
          <Row>
            <Col lg={11} className="mx-auto">
              <div className=" screenHeight ">
                <div className="Form-Shadow border  ms-3 ps-0 pe-0 ms-0 me-0 pt-2 pb-2 ">
                  <Row className="ms-3 me-3  align-Items-center pt-2 pb-2">
                    <Col lg={6}>
                      <div>
                        <BackArrow back="/add-personal-property" />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="d-flex justify-content-end ">
                        <button
                          className="border ps-3 pe-3 pt-1 pb-1  bg-blue text-white"
                          onClick={() => navigate("/add-personal-property")}
                        >
                          Post add
                        </button>
                      </div>
                    </Col>
                  </Row>
                  {/* <UserShopViewModel /> */}

                  <div className=" row ps-5 pe-5 noscroll scroll-shops_2">
                    {values?.map((Items) => (
                      <div className="col-lg-3 col-xl-2 col-md-6 p-2">
                        <div className="border_2">
                          <div key={Items?.id}>
                            <div className="fix-img">
                              <img
                                className="c_img"
                                src={getAsset(Items?.image[0])}
                                alt=""
                                crossOrigin="anonymous"
                              />
                            </div>

                            <Row className="d-flex align-items-center">
                              <Col lg={12}>
                                <div className="pt-1  ">
                                  <p className="mb-0 ps-2 pe-2 items-card-font fw-bold">
                                    {Items?.subCategory}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                            <div className="d-flex justify-content-between pt-1 pb-1 ps-2 pe-2 ">
                              <div>
                                <p className="mb-0 pt-1  items-card-font font-blue">
                                  {Items?.currency.slice(0, 3)} {Items?.price}
                                </p>
                              </div>

                              <div>
                                <button
                                  className="text-white bg-blue share_font"
                                  onClick={() => setShows(1)}
                                >
                                  Share <RedoIcon className="share_icon_f" />
                                </button>
                              </div>
                            </div>

                            <div className="d-flex justify-content-around mb-2 mt-1 ps-2 pe-2">
                              <p
                                className="mb-0 view-btn w-100 text-center  me-1"
                                onClick={() =>
                                  navigate(`/single-property-addview/${Items?._id}`)
                                }
                              >
                                View
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* <div className="ms-3 me-3  noscroll Property-scroll  ">
                  <div className="row ps-2 pe-2">
                    {values?.map((Items) => (
                      <div className="col-lg-3 p-2 ">
                        <div key={Items?._id} className="ShopsCards  mt-3 ">
                          <img
                            className="card-img"
                            src={Items?.image}
                            alt=""
                          />
                          <div className="border">
                            <div className="pt-1  ">
                              <p className="mb-0 ps-2 pe-2 items-card-font fw-bold">
                                {Items?.Name}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center pt-1 pb-1 ps-2 pe-2 ">
                              <p className="mb-0 pt-1  items-card-font font-blue">
                                Pkr {Items?.price}
                              </p>
                              <img src={images.StarImg} />
                            </div>
                            <div className="d-flex justify-content-around mb-2">
                              <p
                                className="mb-0  view-btn"
                                onClick={() => setShowmodal(1)}
                              >
                                View
                              </p>
                              <p
                                className="mb-0  delete-btn "
                                onClick={() => setShow(1)}
                              >
                                Delete
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div> */}
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default SalePerosonalProperty;
