import CreateCompany from "../../../../components/forms/propertyForm/index";
import url from "../../../../config/axios";
import { SuccessNotify, FailedNotify } from "../../../../constants/tostify";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
import { publishImage } from "../../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
const UpdateCompanyForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const { name } = useSelector((state) => state.user);
  const [displayimg,setDisplayimg]=useState();
  const userId = name._id;
  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setValues((values) => ({
        ...values,
        image: abc
      }));

      setDisplayimg(abc);
    
    }catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const [values, setValues] = useState({
    userid: userId,
    companyname: "",
    companyaddress: "",
    companylicense: "",
    contactnumber: "",
    description: "",
    image: [""],
    businessType: "company",
  });
  console.log("../", values);
  const UpdateForm = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .put(`/company/id/?id=${id}`, values)
      .then(async (response) => {
        if (response.data.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Update Company SuccessFully");
            navigate("/company-profile/" + id);
          }, 3000);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsloading(false);
        FailedNotify("Failed to Update Form");
      });
  };

  useEffect(() => {
    setIsloading(true);
    url.get(`/company/id/?id=${id}`).then(async (response) => {
      if (response.data.success) {
        setIsloading(false);
        setValues(response?.data?.data?.Company);
      }
    });
  }, []);

  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }
  return (
    <div>
      <CreateCompany
        heading="Fill to Update Company Profile"
        Buttn="Update"
        name="Company Name"
        address="Company Address"
        url="/company-profile"
        back="/company-profile/"
        setValues={setValues}
        values={values}
        Updatedata={UpdateForm}
        id={id}
        link="/update-company/"
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        displayimg={displayimg}
        setDisplayimg={setDisplayimg}
      />
    </div>
  );
};
export default UpdateCompanyForm;
