import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../components/salespromotion_modal/style.css";
import { salepromotion } from "../../constants/jsonConstant";
import { saleoffer } from "../../constants/jsonConstant";
import url from "../../config/axios";
import { SuccessNotify, FailedNotify } from "../../constants/tostify";
import { useLocation } from "react-router-dom";
const Salespromotion = ({ show, setShow, shopid }) => {
  const location = useLocation();
  const [offer, setOffer] = useState({
    sale: "",
    specialOffer: ""
  });

  const handelvalues = (e) => {
    setOffer((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFun = (e) => {

    e.preventDefault();

    url.patch(`shop/addsalespecialoffer/?id=${shopid}`, offer).then((res) => {
      if (res.data.success) {
        SuccessNotify("Promotion Added Successfully");
        setShow(false);
      }
    }).catch((e) => {
      FailedNotify(e.response.data.message);
      setShow(false);
    })


  }



  return (
    <>
      <Modal className="container" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 class="text-center">
              Add a sale promotion or special offer to shop
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={6} className="bluedrop4">
              {/* <input
                type="checkbox"
                class="form-check-input  "
                id="exampleCheck1"
              /> */}
              <label class=" form-check-label saleslabel" for="exampleCheck1">
                <b>Sales promotion</b>
              </label>
              <select
                class="form-select mt-3 selectstyle "
                aria-label="Default select example"
                name="sale"
                onChange={(e) => handelvalues(e)}
              >
                <option selected disabled>select sales</option>
                {salepromotion.map((item) => (
                  <option key={item.id} >
                    {item.label}
                  </option>
                ))}
              </select>
            </Col>
            <Col lg={6} className="bluedrop4">
              <div class="form-check ">
                {/* <input
                  type="checkbox"
                  class="form-check-input "
                  id="exampleCheck1"
                /> */}
                <label class="form-check-label ml-3" for="exampleCheck1">
                  <b>Special offers</b>
                </label>
              </div>
              <div >
                <select
                  class="form-select mt-3 "
                  aria-label="Default select example"
                  name="specialOffer"
                  onChange={(e) => handelvalues(e)}
                >
                  <option selected disabled>select offer</option>
                  {saleoffer.map((item) => (
                    <option key={item.id} >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-outline-danger"
            onClick={() => setShow(false)}
          >
            Cancel
          </button>

          <button type="button" class="btn btn-primary apply_btn p-2 " onClick={handleFun}>
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Salespromotion;
