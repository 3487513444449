import { Container, Row, Col } from "react-bootstrap";
import JobCandidateFilter from "../../../../components/filteration/JobCandiditeFilter";
import { Avaliablecandidate } from "../../../../pages/index";
import url from "../../../../config/axios";
import { useEffect, useState } from "react";

const AvailableCandidatesFilter = () => {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState();
  const [jobdataone, SetJobdataone] = useState({
    categoryid: "",
    title: "",
  });
  const Handledata = (e) => {
    SetJobdataone((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split("."),
    }));
  };

  const categoryName = jobdataone?.categoryid[1];
  const categoryidone = jobdataone?.categoryid[0];
  const titleName = jobdataone?.title[1];
  const titleid = jobdataone?.title[0];
  const [filterCandidatesData, setFilterCandidatesData] = useState({
    jobtitle: "",
  });

  const [jobCandidatesDataShow, setJobcandidatesDatashow] = useState([]);
  const [employeedata, setEmployeedata] = useState([]);

  const SubmitFilterData = () => {
    url
      .get(
        `employee/filter/?category=6392e00cbc259312c75a8f61${filterCandidatesData?.jobtitle}`
      )
      .then((res) => {
        setJobcandidatesDatashow(res?.data?.data?.Employees);
      });
  };

  useEffect(() => {
    url.get(`employee/?jobtitle=true`).then(async (res) => {
      setJobcandidatesDatashow(res?.data?.data?.Employees);
    });
  }, []);

  const InputHandler = (e) => {
    setFilterCandidatesData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const [candidatedata, setCandidatedata] = useState({
    title: "",
    categoryid: "",
    subCategoryid: "",
    siteremote: "",
    jobtype: "",
    country: "",
    state: "",
    city: "",
  });
  console.log("candidatestateeeeeeeeeeee", candidatedata)

  useEffect(() => {
    setCandidatedata(() => ({
      ...candidatedata,
      categoryid: categoryidone && categoryidone ? categoryidone : "",
      title: titleid,
    }))

  }, [categoryidone, titleid])

  const Handlecandidate = (e) => {
    setCandidatedata((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };
  const FilterCandidate = () => {
    setIsloading(true);
    url
      .get(`/employee/filter/?categoryid=${candidatedata?.categoryid}&subCategoryid=${candidatedata?.subCategoryid}&siteremote=${candidatedata?.siteremote}&jobtype=${candidatedata?.jobtype}&country=${candidatedata?.country}&state=${candidatedata?.state}&city=${candidatedata?.city}`)
      .then(async (response) => {
        if (response?.data?.success) {
          setEmployeedata(response?.data?.employees)
          setIsloading(false);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error);
        setIsloading(false);
      });
  };
  return (
    <>
      <Container>
        <Row>
          <Col lg="4" className="screenHeight">
            <div className="border b-radius height_100">

              <JobCandidateFilter
                InputHandler={InputHandler}
                SubmitFilterData={SubmitFilterData}
                title="Search Candidate by Category,Area,Job type"
                category="Job Title"
                search="Search by Job Type"
                Handlecandidate={Handlecandidate}
                FilterCandidate={FilterCandidate}
                Handledata={Handledata}
                categoryName={categoryName}
                titleName={titleName}
              />
            </div>
          </Col>

          <Col lg="8" className="screenHeight">
            <div className="border b-radius ">
              <Avaliablecandidate
                employeedata={employeedata}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default AvailableCandidatesFilter;
