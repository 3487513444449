import { ShopModelCarousal } from "../../constants/jsonConstant";
import { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import "./shopitemview.css";
import { Row, Col, Container } from "react-bootstrap";
import BackArrow from "../backArrow";
import RedoIcon from "@mui/icons-material/Redo";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { SuccessNotify } from "../../constants/tostify";
import { FailedNotify } from "../../constants/tostify";
import Deletemodal from "../DeleteModal";
import url from "../../config/axios";
import ShareModel from "../Sharemodel";
import ShareOption from "../Shareoption";
import Loader from "../Loader";
import { useSelector } from "react-redux";
import itemdata from "../../store/modules/itemdata";
import { getAsset } from "../../helpers/helper";

const ShopItemsView = ({ index, update, deleteurl, urlone }) => {
  const { name } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const { id, shopid } = useParams();
  const handleClose = () => setShows(0);
  const [increase, setIncrease] = useState(1);
  const [Itemdata, setItemsData] = useState();
  const [show, setShow] = useState(0);
  const [shows, setShows] = useState(false);
  useEffect(() => {
    url.get(`shopitem/id/?id=${id}`).then(async (response) => {
      setItemsData(response?.data?.data?.ShopItem);
    });
  }, []);

  useEffect(() => {
    const img = document.querySelectorAll('.image-gallery-image');
    img.forEach(element => {
      element?.setAttribute('crossorigin', 'anonymous');
    });

  }, []);

  useEffect(() => {
    const thumimgs = document.querySelectorAll('.image-gallery-thumbnail-image');
    thumimgs.forEach(element => {
      element?.setAttribute('crossorigin', 'anonymous');
    });
  }, []);

  const Deleteitem = () => {
    setIsloading(true);
    url
      .delete(`shopitem/?id=${id}`)
      .then(async (response) => {
        if (response.data.success) {
          setIsloading(false);
          SuccessNotify("Item Deleted Successfully");
          setShows(0);
          if (
            location.pathname === `/scrap-single-item/${id}` ||
            location.pathname === `/singleitem-show/${id}`
          ) {
            navigate(`${urlone}`);
          } else {
            setShows(0);
            navigate(`${deleteurl}/${shopid}`);
          }
        }
      })
      .catch((e) => {
        setIsloading(false);
        FailedNotify("Failed to Delete Try Again!");
      });
  };

  const [showmodal, setShowmodal] = useState(false);
  const handleClosemodal = () => setShowmodal(false);
  const handleShow = () => setShowmodal(true);

  const [showshare, setShowshare] = useState(false);
  const handleCloseshare = () => {
    setShowshare(false);
    setShowmodal(true);
  };
  const handleShowshare = () => setShowshare(true);

  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }


  const ShopModelCarousal = [
    {
      id: 1,
      original: getAsset(Itemdata?.images[0]),
      thumbnail: getAsset(Itemdata?.images[0]),

    },
    {
      id: 2,
      original: getAsset(Itemdata?.images[1]),
      thumbnail: getAsset(Itemdata?.images[1]),
    },
    {
      id: 3,
      original: getAsset(Itemdata?.images[2]),
      thumbnail: getAsset(Itemdata?.images[2]),
    },
    {
      id: 4,
      original: getAsset(Itemdata?.images[3]),
      thumbnail: getAsset(Itemdata?.images[3]),

    },
    {
      id: 5,
      original: getAsset(Itemdata?.images[4]),
      thumbnail: getAsset(Itemdata?.images[4]),

    },
  ];
  return (
    <>
      <ShareOption showshare={showshare} handleCloseshare={handleCloseshare} />
      <ShareModel
        show={showmodal}
        handleClosemodal={handleClosemodal}
        handleShowshare={handleShowshare}
      />
      {shows === 1 && (
        <Deletemodal
          shows={shows}
          title="Profile"
          handleClose={handleClose}
          animation={true}
          handledelete={Deleteitem}
        />
      )}
      <Container>
        <div className="screenHeight">
          <div className="ps-5">
            <h5>{Itemdata?.createdAt}</h5>
          </div>
          <Row>
            <Col lg="6">
              <div className="row ">
                <ImageGallery
                  showPlayButton={false}
                  showFullscreenButton={false}
                  items={ShopModelCarousal}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className=" ps-4 pe-4 pt-4 pb-1 bg-vilot View-Model-Shadow">
                {location.pathname === `/singleitem-show/${id}` ? (
                  <BackArrow back={`${urlone}`} />
                ) : (
                  <BackArrow back={`${urlone}/${shopid}`} />
                )}
                <Row className="ps-5 pe-5">
                  <Col lg="6" md="6" sm="6" xs="6">
                    <h4 className="fw-bold">{Itemdata?.itemname}</h4>
                  </Col>
                  <Col lg="6" md="6" sm="6" xs="6" className="text-center">
                    <button className="shareItembtn" onClick={handleShow}>
                      Share <RedoIcon className="share_icon_f" />
                    </button>
                  </Col>
                  <p className="col-11 fw-lighter">{Itemdata?.description}</p>
                  <div>
                    <h3 className="font-blue">
                      {Itemdata?.currency?.slice(0, 3)}
                      &nbsp;
                      {Itemdata?.price}
                    </h3>
                  </div>
                  <div>
                    <p className="mb-0">Contact us on</p>
                    <p className="mb-0 pt-2 pb-2">{name.email}</p>
                    <p className="mb-0">{name.phonenumber}</p>
                  </div>
                  <div className="bg-white quantityBox mt-2 mb-2  pe-2 ps-2 w-25 d-flex align-items-center ">
                    <div>
                      <p className="mb-0 pe-1">
                        Quantity: {Itemdata?.quantity}
                      </p>
                    </div>
                    <div></div>
                    {/* <div>
                      <div className="d-flex flex-column align-items-center ">
                        <ExpandLessIcon
                          fontSize="small"
                          onClick={() => setIncrease(increase + 1)}
                        />
                        <ExpandMoreIcon
                          fontSize="small"
                          onClick={() => setIncrease(increase - 1)}
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className="pt-4 pb-4 d-flex">
                    <button
                      className="d-flex align-items-center border rounded ps-3 pe-3 pt-1 pb-1 bg-red text-white"
                      onClick={() => setShows(1)}
                    >
                      <DeleteOutlineOutlinedIcon />
                      <span className="ps-1">Delete</span>
                    </button>
                    <button
                      className="d-flex align-items-center border rounded ps-4 pe-4 pt-1 pb-1 bg-blue ms-3 me-3 text-white"
                      onClick={() => navigate(`${update}/${id}`)}
                    >
                      <CreateOutlinedIcon />
                      <span className="ps-1">Edit</span>
                    </button>
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default ShopItemsView;
