import { Col, Form, Row } from "react-bootstrap";
import "./editbox.css";
const Editdilogbox = ({
  setEditbox,
  editbox,
  inputChange,
  userDatahandler,
  userDatahandler1,
  userDatahandler2,
  userDatahandler3,
  userDatahandler4,
}) => {
 
  return (
    <>
      <Row>
        <Col xl="2" lg="3 pe-3" sm="12 px-0" md="9" className="smallDialogBox">
          <div className="form-row  Backgroundbox">
            <div className="col-md-12 ps-2">
              <label for="validationTooltip03"></label>
              <input
                type="text"
                classname="form-control writeinput"
                id="validationTooltip03"
                placeholder="Write"
                name={
                  editbox === 1
                    ? "atext"
                    : editbox === 2
                    ? "btext"
                    : editbox === 3
                    ? "ctext"
                    : editbox === 4
                    ? "email"
                    : editbox === 5
                    ? "createdAt"
                    : ""
                }
                onChange={(e) => {
                  inputChange(e);
                }}
              />
            </div>
            <div className="col-12">
              <div className="modal-body boxes ">
                <Form.Select
                  aria-label="Default select example"
                  className="select_box_W select_box"
                  name={
                    editbox === 1
                      ? "aprivacy"
                      : editbox === 2
                      ? "bprivacy"
                      : editbox === 3
                      ? "cprivacy"
                      : editbox === 4
                      ? "dprivacy"
                      : editbox === 5
                      ? "eprivacy"
                      : ""
                  }
                  onChange={(e) => {
                    inputChange(e);
                  }}
                >
                  <option>Public</option>
                  <option value="1">Friends</option>
                  <option value="2">Only me</option>
                </Form.Select>

                <div className="pt-3">
                  <button
                    type="button"
                    class="btn  text-white font_small bg-dark pt-0 pb-0"
                    onClick={() => setEditbox(false)}
                  >
                    Cancel
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-primary text-white font_small bg-blue  pt-0 pb-0 "
                    onClick={
                      editbox === 1
                        ? userDatahandler
                        : editbox === 2
                        ? userDatahandler1
                        : editbox === 3
                        ? userDatahandler2
                        : editbox === 4
                        ? userDatahandler3
                        : editbox === 5
                        ? userDatahandler4
                        : ""
                    }
                  >
                    Save
                  </button>
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default Editdilogbox;
