import { Rating } from "@mui/material";
import React, { useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import BackArrow from "../../../../components/backArrow";
import { Link } from "react-router-dom";
import { images } from "../../../../constants/index";
import MessageIcon from "@mui/icons-material/Message";
import Button from "react-bootstrap/Button";
import Typography from "@mui/material/Typography";
import ReportIcon from "@mui/icons-material/Report";
import SyncIcon from "@mui/icons-material/Sync";
import { useNavigate } from "react-router-dom";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import SettingsIcon from "@mui/icons-material/Settings";
import BatchPredictionIcon from "@mui/icons-material/BatchPrediction";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CampaignIcon from "@mui/icons-material/Campaign";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import Salespromotion from "../../../../components/salespromotion_modal";
import Qrmodal from "../../../../components/QRmodal/Qrmodal";
import "./style.css";
import { getAsset } from "../../../../helpers/helper";
import Desmodal from "../../../../components/Des_Modal";
const RestaurantShopdata = ({
  shopData,
  id,
  suggestdatta,
  setSuggest,
  shopid,
}) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showmodal, setShowmodal] = useState(false);
  const [desshow, setDesshow] = useState(false);
  const closemodal = () => setShowmodal(false);
  const handleClosedes = () => setDesshow(false);
  const handleShowdes = () => setDesshow(true);
  return (
    <>
      <Desmodal
        desshow={desshow}
        handleClosedes={handleClosedes}
        des={shopData?.description}
      />
      <Qrmodal show={showmodal} handleClosemodal={closemodal} />
      <Salespromotion show={show} setShow={setShow} shopid={shopid} />

      <BackArrow />

      <Row className="border_3 p-0 m-0 pt-2 pb-2">
        <Col lg="4" xl="4">
          <div className="mt-2 picset">
            <img
              src={getAsset(shopData?.image)}
              alt=""
              className="Restaurant-img"
              crossOrigin="anonymous"
            />
          </div>
        </Col>
        <Col lg="8" xl="8" className="">
          <Row>
            <Col lg="8" xl="8">
              <h2 className="font-bold">
                {shopData?.shopname && shopData?.shopname.length >= 15
                  ? shopData?.shopname.slice(0, 15) + "..."
                  : shopData?.shopname}
              </h2>
              <span className="font-bold">Shop ID : </span>
              {shopData?.shopid} <br></br>
              <span className="font-bold">Address : </span>
              {shopData?.shopaddress}
              <div className="d-flex">
                <p className="mb-2 font_14">
                  {shopData?.description.slice(0, 40)}
                </p>
                <p className="shop-profile-para mb-0 ">
                  {shopData?.description?.length > 40 && (
                    <span
                      onClick={handleShowdes}
                      className="mb-0 cursor_P font_11 text-dark"
                      style={{ marginTop: "3px" }}
                    >
                      &nbsp;<span style={{ fontWeight: "700" }}>ReadMore...</span>
                    </span>
                  )}
                </p>
              </div>
              <div>
                <p className="mb-1 font_small w-25 d-flex">
                  <Typography component="legend" className="font_small mt-1">
                    <b>3.6</b>
                  </Typography>
                  <Rating name="read-only" readOnly />
                </p>
              </div>
              <div className="">
                <div className="d-flex">
                  <div
                    className="d-flex cursor_P"
                    style={{ textDecoration: "none" }}
                    onClick={() => navigate("/resturant-setting")}
                  >
                    <SettingsIcon className="report-icn-food mt-1" />
                    <p className="font-sm-15 m-0">&nbsp;Setting</p>
                  </div>
                </div>
                <div
                  className="d-flex cursor_P"
                  onClick={() => navigate("/Food-Report-section")}
                >
                  <ReportIcon className="report-icn-food mt-1" />
                  <p className="font-sm-15 m-0">&nbsp;Reports</p>
                </div>

                <div
                  className="d-flex cursor_P"
                  onClick={() => {
                    setSuggest(1);
                    suggestdatta();
                  }}
                >
                  <BatchPredictionIcon className="report-icn-food mt-1" />
                  <p className="font-sm-15 m-0">&nbsp;Suggestion</p>
                </div>

                <div
                  className="d-flex cursor_P"
                  onClick={() => navigate("/DeliveryForm")}
                >
                  <LocalShippingIcon className="report-icn-food mt-1" />
                  <p className="font-sm-15 m-0">&nbsp;Set delivery options</p>
                </div>

                <div
                  className="d-flex cursor_P"
                  onClick={() => navigate(`/promote-shop/${id}`)}
                >
                  <CampaignIcon className="report-icn-food mt-1" />

                  <p className="font-sm-15 m-0">&nbsp;Promote Your Services</p>
                </div>
                <div className="d-flex cursor_P" onClick={() => setShow(true)}>
                  <LocalOfferIcon className="report-icn-food mt-1" />
                  &nbsp;
                  <p className="font-sm-15 m-0">
                    Add a sale/special offer to shop
                  </p>
                </div>
              </div>
            </Col>

            <Col lg="4" xl="4" className="d-flex flex-column text-end">
              <div className="mb-4 text-end">
                <QrCode2Icon
                  style={{ fontSize: "50px" }}
                  onClick={() => setShowmodal(true)}
                />
              </div>

              <div onClick={() => navigate(`/restaurant-update/${id}`)}>
                <Button className="custom-button pt-1 pb-1 ps-4 pe-3">
                  <SyncIcon />
                  &nbsp;Update&nbsp;&nbsp;
                </Button>
              </div>

              <div>
                <Button
                  className="custom-button-one ps-3 pe-3  pt-2 pb-2 mt-3"
                  onClick={() => navigate("/OrderHistory")}
                >
                  &nbsp;&nbsp;&nbsp;Order History
                </Button>
              </div>

              <div>
                <Button
                  className="mt-3 custom-button-one ps-2 pe-2 pt-2 pb-2 mt-3 btn btn-primary"
                  onClick={() => navigate("/ShopManage")}
                >
                  Give Admin Rights
                </Button>
              </div>

              <div>
                <Button
                  onClick={() => navigate("/food-business-dashboard")}
                  className="custom-button-one ps-1 pe-1  pt-2 pb-2 mt-3 btn btn-primary mt-3"
                >
                  Business Dashboard
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default RestaurantShopdata;
