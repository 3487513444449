import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { images } from "../../../../constants";
import Form from "react-bootstrap/Form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RedoIcon from "@mui/icons-material/Redo";
import { useNavigate, useParams } from "react-router-dom";
import url from "../../../../config/axios";
import moment from "moment";
import BAckArrow from "../../../../components/backArrow";
import "../viewApplication/viewapplication.css";
import { FailedNotify, SuccessNotify } from "../../../../constants/tostify";
import { useSelector } from "react-redux";
import ShareModel from "../../../../components/Sharemodel";
import ShareOption from "../../../../components/Shareoption";  
const ViewbuyApplication = () => {  
  const navigate = useNavigate();
  const { name } = useSelector((state) => state.user);
  
const [showshare, setShowshare] = useState(0);
const [show, setShow] = useState(0);
  const { id } = useParams();
  const [employeedata, setEmployeedata] = useState();


  useEffect(() => {
    url.get(`/employee/id/?id=${id}`).then(async (response) => {
      setEmployeedata(response?.data?.data?.Employee);
    });
  }, []);


  const handleCloseshare = () => {
    setShowshare(0);
    setShow(1);
  };
  const handleClosemodal = () => {
    setShow(0);
  };

  const handleShowshare = () => {
    setShow(0);
    setShowshare(1);
  };



  return (
    <Row>
      <ShareOption
          showshare={showshare}
          handleCloseshare={handleCloseshare}
        />
        <ShareModel
          handleClosemodal={handleClosemodal}
          show={show}
          handleShowshare={handleShowshare}
        />

      <div className="screenHeight d-flex justify-content-center align-items-center">
        <Col lg={11} md={11} className="p-0 ">
          <Col xl={5} lg={7} md={9} className="Form-Shadow  mx-auto ">
            <div className="arrowback   pe-3 pt-2 pb-1">
              <BAckArrow
                back="/create-job-app"
                black="black"
                mystyle="ps-3 p-1"
              />
            </div>
            <div className="ps-4 pe-2">
               <div className="ps-3">
                <h4 className="fs-4 mb-1">Application</h4>
              </div>
              <div className="ps-3">
              <h6>Job Type</h6>
              <p className="font_13">{employeedata?.jobtype}</p>
              </div>
         
              <div className="ps-3">
                <h6 className="mb-1 jobviewhead">Salary</h6>

                <p className="ViewlabelText mb-1 ">
                  {employeedata?.salarywant}
                </p>
              </div>
              <div className="ps-3">
                <h6 className="mb-1 pt-1 jobviewhead">Job</h6>
                <p className="ViewlabelText mb-1">{employeedata?.jobtitle}</p>
              </div>
              <div className="ps-3">
                <h6 className="mb-1 pt-1 jobviewhead">Country,State,City</h6>
                <p className="ViewlabelText mb-1">{`${employeedata?.country}, ${employeedata?.state}, ${employeedata?.city}`}</p>
              </div>
              <div className="ps-3">
                <h6 className="mb-1 pt-1 jobviewhead">Job Experience</h6>
                <p className="ViewlabelText mb-1">
                  {employeedata?.jobexperience}
                </p>
              </div>
              <div className="ps-3">
                <h6 className="mb-1 pt-1 jobviewhead">Contact Number</h6>
                <p className="ViewlabelText mb-1">{name.phonenumber}</p>
              </div>
              <div className="ps-3">
                <h6 className="mb-1 pt-1 jobviewhead">Email</h6>
                <p className="ViewlabelText mb-1">{name.email}</p>
              </div>
              <div className="ps-3">
                <h6 className="mb-1 pt-1 jobviewhead">Remaining Time</h6>
                <p className=" ViewlabelText mb-1">
                  {moment(employeedata?.duration).format("DD-MMMM-YYYY")}
                </p>
              </div>
              <div className="ps-3">
                <h6 className="mb-3 pt-1 jobviewhead">Description</h6>
                <p className="ViewlabelText">{employeedata?.jobdescription}</p>
              </div>
              <div>
                <Row className="d-flex  align-items-end pt-3 pb-3">
                
                  <Col
                    md={6}
                    lg={12}
                    
                  >
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="pe-2 ">
                        <button className="m-0 btn btn-primary pt-1 pb-1" onClick={()=>navigate(`/view-candidate-cv/${employeedata?.userid}`)}>
                          <VisibilityIcon className="iconWhite me-1" fontSize="small" />
                          View CV
                        </button>
                      </div>

                      <button
                        className=" m-0 btn btn-primary pt-1 pb-1"
                        onClick={() =>
                          setShow(1)
                        }
                      >
                       
                        Share
                        &nbsp;<RedoIcon fontSize="small" />
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Col>
      </div>
    </Row>
  );
};
export default ViewbuyApplication;
