import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import url from "../../config/axios";

import SalePerosonalProperty from "../../components/PersonalProperty_ADD/SalePerosonalProperty";
const PersonalAdd = () => {
  const [values, setValues] = useState();
  const navigate = useNavigate();
  const { name } = useSelector((state) => state.user);
  useEffect(() => {
    url.get(`property/user/?userid=${name?._id}`).then(async (response) => {
      setValues(response?.data?.data?.Companies);
     
    });
  }, []);
  
 
  return (
    <>
      <SalePerosonalProperty values={values} setValues={setValues} />
    </>
  );
};
export default PersonalAdd;
