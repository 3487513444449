import { Row, Col } from "react-bootstrap";
import "./CVimage.css";
import { images } from "../../../../../constants/index";
import ProfileInformationCV from "../profileInformation2";
import CVProfileBio from "../CVProfileBio2";
const CVImage = ({ cvdata }) => {
  return (
  
          <Row>
            <Col md={4} lg={4} xl={3} className="ps-0 pe-0">
              <div className="template-2-back d-flex flex-column align-items-center ">
                <div className="pt-2 d-flex flex-column align-items-center">
                  <div className="img-2-Background">
                    <img
                      src={cvdata?.profileInformation?.photo}
                      alt=""
                      className="img-2-Background"
                    />
                  </div>
                  <div>
                    <h5 className="text-white text-center fs-2 fw-bold mb-0">
                      {cvdata?.profileInformation?.firstName}
                    </h5>
                    <h6 className="text-white text-center fs-5">
                      {cvdata?.profileInformation?.jobDesignation}
                    </h6>
                  </div>
                  <div className="profile-2-scroll noscroll ps-md-2 ps-lg-2 ps-xl-0">
                    <ProfileInformationCV cvdata={cvdata} />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={8} lg={8} xl={9} className="pe-0">
              <CVProfileBio cvdata={cvdata} />
            </Col>
          </Row>
      
  );
};
export default CVImage;
