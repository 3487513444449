import "./helplineForm.css"
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import {Col,Card} from "react-bootstrap"
import { ClearIcon } from "@mui/x-date-pickers";
import { getAsset } from "../../../../helpers/helper";
const HelpLineForm = ({
  CreateHandler,
  setHelpline,
  helpline,
  PostHelplineData,
  helpreport,
  getInputProps,
   getRootProps,
    displayimg,
    setDisplayimg,
  
}) => {



  const navigate = useNavigate();

  const HelpHandler = (event) => {
    setHelpline((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const removeImage = (index) => {
    const updatedImages = [...displayimg];
    updatedImages.splice(index, 1);
    setDisplayimg(updatedImages);
  };
  return (
    <Row onSubmit={(e) => {
      CreateHandler(e);
      
    }}>
      <div className=" d-flex justify-content-center">
        <div className="   ps-3 pe-3  pb-3">
          <div className="ps-3 pe-3 pt-2">
            <div>
              <h4 className="fs-5 form-font fw-bold">Report Problem</h4>
            </div>
            {/* Inputs  */}
            <div className="d-flex flex-column pt-2">
              <label className="form-font pb-2 fw-bold">Problem</label>
              {/* {
                helpreport?.map((help) => (
                  <option key={help._id} value={help?.problem} >{help?.desciription}</option>
                ))
              }
              {helpreport?.map((help) => (
                <option key={help._id} value={help?._id}>
                  {help?.name}
                </option>
              ))} */}
              <input
               className="input-style" placeholder="Write Problem"
               name="problem"
               defaultValue={helpline?.problem}
               onChange={(e) => {
                HelpHandler(e);
                
              }}
              />
            </div>
            {/* Description */}
            <div>
              <div className="d-flex flex-column pt-2">
                <label className="form-font pb-2 fw-bold">Description</label>
                <textarea
                  placeholder="Description"
                  className="textarea /selectezi-helpline pt-1 ps-2 pb-5 pe-2 "
                  name="desciription"
                  defaultValue={helpline?.desciription}
                />
              </div>
            </div>

            {/* UploadImg */}
            <div className="d-flex  pe-3 pt-2 pb-2 ">
              <div
                className=" me-3 pt-1 pb-1 ps-3 pe-3 border border-2 d-flex flex-column 
            align-items-center cursor background-white" 
            
            
              >
                <span role="button">
                  <div
                    
                    
                    {...getRootProps()}
                    className="dropzone text-success"
                  >
                    <ControlPointOutlinedIcon className="Icon-font text-dark" />
                    <input
                      type="file"
                      id="file"
                      {...getInputProps()}
                      multiple
                      style={{ display: "none" }}
                      name="image"
                    />
                  </div>
                </span>

                <p className="pt-2 mb-0 font-grey">Add Pictures</p>
              </div>
              {/* <div className='pt-1 pb-1 ps-3 pe-3 border border-2 d-flex flex-column 
            align-items-center cursor background-white'>
                                <AddPhotoAlternateOutlinedIcon className="Icon-font"/>
                               
                                <p className=" pt-2 mb-0 font-grey">Add more Pictures</p>
                            </div> */}

{displayimg?.length > 0 && (
<Col lg={7} className=" mt-1 d-flex">
                      

                      {displayimg.map((image,index) => (
                        <Card
                          className="me-2 mt-1"
                          style={{
                            width: "6rem",
                            height: "6rem",
                            border: "2px solid",
                          }}
                          
                        >
                          <Card.Img
                            className="imet-fit upload-img mt-0"
                            src={getAsset(image)}
                            alt=""
                            crossOrigin="anonymous"
                          />
                          <div
                            className="close-btnone"
                            onClick={() => removeImage(index)}
                          >
                            <ClearIcon />
                          </div>
                        </Card>
                      ))}
                    </Col>	
)

                      }
            </div>
            <div>
              <h6 className="py-1">
                Describe your problem here or contact us on our helpline number
              </h6>
              <div className="d-flex">
                <p className="me-2 text-danger fw-bold">098765655</p>
                <p className="text-danger fw-bold">098765655</p>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-1 mb-2 align-items-center ">
              <div>
                <button
                  className="border border-0 ps-4 pe-4  bg-red text-white border-radius-btns"
                  onClick={() => navigate("/SellingSide")}
                >
                  Cancel
                </button>
              </div>
              <div className="ms-4 me-4">
                <button  className="border border-0 ps-4 pe-4  bg-btn-primary text-white border-radius-btns">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Row>
  );
};
export default HelpLineForm;
