import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";
import { useState } from "react";
import BoxData from "../Box";
import CardNav from "../CardNav";
import { BusinessData } from "../../../../constants/jsonConstant";
import { Col, Container, Row } from "react-bootstrap";
import Cards from "../../Component/Card";
import { images } from "../../../../constants";
import PostCvModal from "../PostCvModal";
import url from "../../../../config/axios"
import { useSelector } from "react-redux";
import "./style.css";

const BusinessModes = ({
  btntext,
  title,
  btntexttwo,
  btntextthree,
  btntextfour,
  chatroom,
  helpline,
  email,
}) => {
  const [show, setShow] = useState(false);
  const { name } = useSelector((state) => state.user);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [scrapform, setScrapform] = useState();
  const [singleform, setSingleform] = useState();
  const [personalform, setPersonalform] = useState();
  const [cvform, setCvform] = useState();


  useEffect(() => {
    url.get(`shopitem/getscrapItemByUser/${name?._id}`).then(async (response) => {
      if (response.data.success) {
        setScrapform(response?.data?.data?.ShopItems);
      }
    }
    )

    url.get(`shopitem/getSingleitembyuser/${name?._id}`).then(async (response) => {
      if (response.data.success) {
        setSingleform(response?.data?.data?.ShopItems);
      }
    })

    url.get(`property/user/?userid=${name?._id}`).then(async (response) => {
      if (response.data.success) {
        setPersonalform(response?.data?.data?.Companies);
      }
    })

    url.get(`cvform/checkcvexist?user=${name?._id}`).then(async (response) => {
      
      if (response.data.success) {
        setCvform(response?.data?.cvform?.profileInformation?.firstName);
      }
    })

  }, []);

  return (
    <>
      <PostCvModal handleClose={handleClose} show={show} />
      <div>
        <Row className="set-one set-onetwo mx-2 mx-sm-0">
          {BusinessData?.map((data) => (
            <Col key={data.id} lg="3" md="3" sm="6" xs="12" className="mt-3">
              <CardNav
                Header={data.title}
                para={data.para}
                myid={data.id}
                myinfo={data.info}
              ></CardNav>
              <Card className="shadow-main mt-2 ">
                {(data.id === 1 && (
                  <Cards
                    ms="four-element"
                    btn="Business Dashboard"
                    text=""
                    profileurl="/homemade-items-shops"
                    myclass="business_avataar"
                    imgsrc={images.Businessdashboard}
                  />
                )) ||
                  (data.id === 2 && (
                    <BoxData
                      Butonn={btntext}
                      Title={data.titleone}
                      imgg={images.WholeSaler}
                      url="/wholeseller-integrate"
                      link="/buy-wholeseller"
                      ms="six-element"
                      buy="six"
                    />
                  )) ||
                  (data.id === 3 && (
                    <BoxData
                      Butonn={btntext}
                      Title={data.titleone}
                      imgg={images.Factory}
                      url="/manufacturer-integrate"
                      link="/buy-manufacturer"
                      className="mt-2"
                      ms="seven-element"
                      buy="seven"
                    />
                  )) ||
                  (data.id === 4 && (
                    <BoxData
                      Butonn={btntext}
                      Title={data.titleone}
                      imgg={images.IronLogo}
                      url="/raw-integrate"
                      link="/buy-raw-material"
                      ms="eight-element"
                      buy="eight"
                    />
                  )) ||
                  (data.id === 5 && (
                    <BoxData
                      Butonn={btntext}
                      Title={data.titleone}
                      imgg={images.Retailer}
                      url="/retailer-integrate"
                      link="/buy-retailer"
                      ms="eight-element"
                      buy="eight"
                    />
                  )) ||
                  (data.id === 6 && (
                    <BoxData
                      Butonn={btntext}
                      Title={data.titleone}
                      imgg={images.Homemadetwo}
                      url="/Homemade-integrate"
                      link="/buy-homemade"
                      ms="six-element"
                      buy="six"
                    />
                  )) ||
                  (data.id === 7 && (
                    <BoxData
                      Butonn={btntext}
                      Title={data.titleone}
                      imgg={images.GemStone}
                      url="/gems-integrate"
                      link="/buy-minerals"
                      className="mt-2"
                      ms="seven-element"
                      buy="seven"
                    />
                  )) ||
                  (data.id === 8 && (
                    <BoxData
                      Butonn={btntext}
                      Title={data.titleone}
                      imgg={images.UsedItems}
                      url="/useditems-integrate"
                      link="/buy-usedItems"
                      ms="eight-element"
                      buy="eight"
                    />
                  )) ||
                  (data.id === 9 && (
                    <BoxData
                      Butonn={btntext}
                      Title={data.titleone}
                      imgg={images.Candidates}
                      ms="nine-element"
                      buy="nine"
                      url="/company-form"
                      link="/find-job"
                    />
                  )) ||
                  (data.id === 10 && (
                    <BoxData
                      Butonn={btntexttwo}
                      Title={data.titleone}
                      imgg={images.Jobs}
                      cvform={cvform}
                      handleShow={handleShow}
                      link="/find-candidates"
                      ms="ten-element"
                      buy="ten"
                    />
                  )) ||
                  (data.id === 11 && (
                    <BoxData
                      Butonn={btntextthree}
                      Title={data.titleone}
                      imgg={images.SingleAdds}
                      url={singleform?.length > 0 ? "/singleadd-show" : "/post-single-ad"}
                      link="/single-item-buy"
                      ms="fourteen-element"
                      buy="fourteen"
                    />
                  )) ||
                  (data.id === 12 && (
                    <BoxData
                      Butonn={btntext}
                      Title={data.titleone}
                      imgg={images.Foodbar}
                      url="/restaurant-integrate"
                    />
                  )) ||
                  (data.id === 13 && (
                    <BoxData
                      Butonn={btntextfour}
                      Title={data.titleone}
                      imgg={images.Services}
                      url="/service-integrate"
                    />
                  )) ||
                  (data.id === 14 && (
                    <BoxData
                      Butonn={btntextfour}
                      Title={data.titleone}
                      imgg={images.TransportServices}
                      url="/transport-integrate"
                    />
                  )) ||
                  (data.id === 15 && (
                    <BoxData
                      Butonn={btntext}
                      Title={data.titleone}
                      imgg={images.SellProperties}
                      url="/property-integrate"
                    />
                  )) ||
                  (data.id === 16 && (
                    <BoxData
                      Butonn={btntextthree}
                      Title={data.titleone}
                      imgg={images.SingleVehicle}
                      url={personalform?.length > 0 ? "/personal-add" : "/add-personal-property"}
                    />
                  )) ||
                  (data.id === 17 && (
                    <BoxData
                      Butonn={btntext}
                      Title={data.titleone}
                      imgg={images.Farming}
                      url="/farming-integrate"
                    />
                  )) ||
                  (data.id === 18 && (
                    <BoxData
                      Butonn={btntext}
                      Title={data.titleone}
                      imgg={images.Recycled}
                      url="/recycled-integrate"
                    />
                  ))

                  // ||
                  // (data.id === 19 && (
                  //   <BoxData
                  //     Butonn={btntextthree}
                  //     Title={data.titleone}
                  //     imgg={images.ScrapMaterial}
                  //     url={scrapform?.length > 0 ? "/scrap-item-show" : "/scrape-material"}
                  //   />
                  // )) 

                  ||
                  (data.id === 20 && (
                    <BoxData
                      Butonn={btntext}
                      Title={data.titleone}
                      imgg={images.BuyVehicles}
                      url="/usedvehicle-integrate"
                    />
                  )) ||
                  (data.id === 21 && (
                    <BoxData
                      Butonn={chatroom}
                      Title={data.titleone}
                      imgg={images.ChatRoom}
                      url="/chat-room-modal"
                    />
                  )) ||
                  (data.id === 22 && (
                    <BoxData
                      Butonn={helpline}
                      Title={data.titleone}
                      imgg={images.HelpLine}
                      url="/customer-support"
                    />
                  )) ||
                  (data.id === 23 && (
                    <BoxData
                      Title={data.titleone}
                      Butonn={email}
                      url="/inbox"
                      imgg={images.Email}
                    />
                  ))}
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default BusinessModes;
