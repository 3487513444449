import { Row, Col } from "react-bootstrap";
import { candidatesapi } from "../../constants/jsonConstant";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BackArrow from "../backArrow";
import "./availibleCandidates.css";
import { images } from "../../constants/index";
import { getAsset } from "../../helpers/helper";
import { useNavigate } from "react-router-dom";
const Avaliablecandidate = ({ employeedata }) => {

  console.log("dadasds", employeedata)
  const navigate =useNavigate();
  return (
    <>
      <div className="col-10 mx-auto mt-2">
        <div className="d-flex flex-column">
          <div>
            <BackArrow back="/BuyingSide" />
          </div>
          <h6 className="pt-1 mb-1" style={{ color: "blue" }}>
            Available Candidates
          </h6>
          <p className="ms-3">250</p>
        </div>
        <div className="scroll_C_row noscroll ">
        <div className="row mt-3">
       
          {employeedata?.map((e) => (
            <Col lg="3" className=" pl-0 pr-0 mt-3">
              <div class="card text-center cardsettingss" style={{ width: "10rem" ,}}>
                <img
                  class="card-img-top mt-2 "
                  crossOrigin="anonymous"

                  src={e?.image[0]?.length > 0 ? getAsset(e?.image) : images?.noprofile}
                  alt="Card cap"
                  style={{ width: "65px", margin: "auto" }}
                />
                <div class="card-body p-1">
                  <h5 class="card-title mb-1" style={{ fontSize: "14px" }}>
                    {e.name}
                  </h5>
                  {/* <p
                    class="card-text mb-0"
                    style={{ fontSize: "11px", color: "grey" }}
                  >
                    <VisibilityIcon className="icon_f font-grey" />
                    View CV
                  </p> */}
                  <button className="follow-connt mb-1" onClick={() => navigate(`/view-buy-application/${e?._id}`)}>View</button>
                </div>
                <div className="margin-Boxes pt-2 ">
                  <Row className="row bg-blue ">
                    <Col lg={6} className="p-0">
                      <div className="border-end cursor">
                        <p className="mb-0 ps-1 d-flex p-1">
                          <img className="Model_btn " src={images.BlueMail} />
                          <span className="btns text-white ps-1">Message</span>
                        </p>
                      </div>
                    </Col>
                    <Col lg={6} className="p-0">
                      <div className="cursor">
                        <p className="mb-0 ps-1 d-flex p-1 ">
                          <img className="Model_btn" src={images.BluePhone} />
                          <span className="btns text-white ps-1">Call</span>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          ))}

      
          
        </div>

        </div>

      </div>
    </>
  );
};

export default Avaliablecandidate;
