import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { paymentoption } from "../../../../constants/jsonConstant";
import { images } from "../../../../constants";
import "./style.css";

function OrderSumary({ isOpenone, SetisOpenone }) {
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState(`1`);

  const handlePaymentOptionClick = (event) => {
    setSelectedOption(event.target.value);
  };

  const [isCodeVisible, setIsCodeVisible] = useState(false);

  const handleShowCode = () => {
    setIsCodeVisible(true);
    setSelectedOption("0")
  };

  return (
    <Container>
      <div>
        <div className="screenHeight">
          {/* <Button variant="primary" onClick={() => setShow(true)}>
            Custom Width Modal
          </Button> */}

          <Modal
            show={isOpenone}
            onHide={() => setShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className=" modal-xl"
            style={{ display: 'block', position: 'center' }}

          >
            <Modal.Header closeButton onClick={() => SetisOpenone(false)}></Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} md={8} lg={6}>
                  <div>

                    <Modal.Title id="contained-modal-title-vcenter">
                      Payment
                    </Modal.Title>
                    <div>

                      <hr />
                      <div className="ps-4">
                        <p className="paywith-div">Paywith:</p>
                      </div>
                      <div className="d-flex justify-content-evenly ratiobtn-div">
                        {paymentoption.map((option) => (
                          <div
                            className="d-flex justify-content-between gap-1"
                            key={option.id}
                          >
                            <input
                              type="radio"
                              name="payment"
                              value={`${option.id}`}
                              checked={selectedOption === option.id}
                              className="form-check-input"
                              onChange={handlePaymentOptionClick}
                            />
                            {option.label}
                            <br />
                          </div>
                        ))}
                      </div>
                      {selectedOption === `1` && (
                        <>
                          <div className="mt-4 ps-3">
                            <h5 className="heading-">Card Number</h5>
                            <input
                              type="text"
                              placeholder="Enter your information"
                              className="form-control mt-4"
                            />
                          </div>

                          <div className="d-flex justify-content-between mt-3 ps-3">
                            <div>
                              <h6 className="">Expiration Date</h6>
                              <input
                                type="text"
                                placeholder="Field 1"
                                className="form-control"
                                style={{ width: "94%" }}
                              />
                            </div>
                            <div>
                              <h6>CVV</h6>
                              <input
                                type="text"
                                placeholder="Field 2"
                                className="form-control"
                                style={{ width: "94%" }}
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {selectedOption === `2` && (
                        <div>
                          <div className="d-flex justify-content-center mt-3 ps-3">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                            >
                              <option selected>Open this select menu</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <div className="d-flex justify-content-center">
                      <p>Enter 4 digits card pin to conform this payment</p>
                    </div>
                    <div class="otp-container d-flex justify-content-center">
                      <input type="text" class="otp-input" maxlength="1" />
                      <input type="text" class="otp-input" maxlength="1" />
                      <input type="text" class="otp-input" maxlength="1" />
                      <input type="text" class="otp-input" maxlength="1" />
                    </div> */}
                  </div>

                  <div className="d-flex justify-content-center mt-4 pe-4 ps-4">
                    <div>
                      {selectedOption === `1` || selectedOption === `2` || isCodeVisible === false ? (<></>) : (
                        <div>
                          <div className="d-flex justify-content-center">
                            <p>
                              Enter 4 digits card pin to confirm this payment
                            </p>
                          </div>
                          <div className="otp-container d-flex justify-content-center">
                            <input
                              type="text"
                              className="otp-input"
                              maxLength="1"
                            />
                            <input
                              type="text"
                              className="otp-input"
                              maxLength="1"
                            />
                            <input
                              type="text"
                              className="otp-input"
                              maxLength="1"
                            />
                            <input
                              type="text"
                              className="otp-input"
                              maxLength="1"
                            />
                          </div>
                        </div>

                      )}

                      <button
                        onClick={() => handleShowCode()}
                        variant="primary"
                        size="lg"
                        className="mt-4 button-settingdone text-light "
                      >
                        Pay USD58.8!

                      </button>
                    </div>
                  </div>


                </Col>

                <Col xs={6} md={4} lg={6}>
                  <div>
                    <h2>Order History</h2>
                    <hr />

                    <div className="d-flex align-items-start">
                      <img
                        src={images.Leatherbag}
                        alt="Taxmax leather bag"
                        className="img-fluid ps-3"
                      />

                      <div className="d-flex flex-column mt-2">
                        <h6 className="ps-3 mb-0">Taxmax leather bag</h6>
                        <h6 className="ps-3 mb-0 textcolor-set">Taxmax leather bag</h6>
                      </div>
                      <div className="ms-auto d-flex flex-column p-0">
                        <h6 className="mb-0">$49.80</h6>
                        <p className="mb-0 textcolor-set p-2">Qty:2</p>
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between p-1">
                      <h6>sub total</h6>
                      <h6>49.5$</h6>
                    </div>
                    <div className="d-flex justify-content-between p-1">
                      <h6>sub total</h6>
                      <h6>49.5$</h6>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column">
                        <h6>Total</h6>
                        <p className="includecolor-set">including $2.24 in taxes</p>
                      </div>
                      <h3>58.5$</h3>
                    </div>
                  </div>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </Container>
  );
}

export default OrderSumary;
