import { ShopModelCarousal } from "../../constants/jsonConstant";
import { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { images } from "../../constants";
import "./shopViewModel.css";
import { Row, Col } from "react-bootstrap";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { useNavigate } from "react-router-dom";
import Deletemodal from "../DeleteModal";
import url from "../../config/axios";
import moment from "moment";
import { SuccessNotify } from "../../constants/tostify";
import { FailedNotify } from "../../constants/tostify";
const OwnerShopViewModel = ({ handleClosetwo, handleShow, index, butn }) => {
  const [show, setShow] = useState(0);
  const [singleitem, setSingleitem] = useState();
  const [shows, setShows] = useState(false);
  const handleClose = () => setShows(0);
  const navigate = useNavigate();
  
  useEffect(() => {
    url.get(`shopitem/id/?id=${index}`).then(async (response) => {
      setSingleitem(response?.data?.data?.ShopItem);
    });
  }, []);

  const Deleteitem = () => {
    url
      .delete(`shopitem/?id=${index}`)
      .then(async (response) => {
        if (response.data.success) {
          SuccessNotify("Item Deleted Successfully");
          setShows(0);
          window.location.reload(false);
        }
      })
      .catch((e) => {
        FailedNotify("Failed to Delete Try Again!");
      });
  };

  return (
    <>
      {shows === 1 && (
        <Deletemodal
          shows={shows}
          title="Profile"
          handleClose={handleClose}
          animation={true}
          DeleteHnadler={Deleteitem}
        />
      )}

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClosetwo}
        show={handleShow}
      >
     
        <Modal.Body className=" pt-3  pb-3">
          <div>
            <p className="fw-bold">
              Posted on {moment(singleitem?.duration).format("DD-MMMM-YYYY")}
            </p>
          </div>
          <Row>
            <Col lg="6">
              <div className="row">
                <ImageGallery
                  showPlayButton={false}
                  showFullscreenButton={false}
                  items={ShopModelCarousal}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="ps-4 pe-4 pt-5 pb-4 bg-vilot View-Model-Shadow">
                <h4 className="fw-bold">{singleitem?.itemname}</h4>
                <p className="col-10 fw-lighter">{singleitem?.description}</p>

                <div className="pt-2 pb-2">
                  <div>
                    <h3 className="font-blue">PKR {singleitem?.price}</h3>
                  </div>
                </div>
                <div className="pt-3 cursor">
                  <p className=" d-flex align-items-center justify-content-center mb-0 border rounded ps-4 pe-4 pt-1 pb-1 bg-blue ms-3 me-3 w-75 text-white">
                    <img src={images.Cart} />
                    <span className="ps-2"> Add to cart</span>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OwnerShopViewModel;
