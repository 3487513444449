import { Modal } from "react-bootstrap";
import "./deletemodal.css";
const Deletemodal = ({ shows, handleClose, title, handledelete,postId }) => {
  return (
    <>
      <Modal
        show={shows}
        animation={true}
        onHide={handleClose}
        className="mt-5 pt-5"
      >
        <Modal.Body className="col-8 mx-auto text-center p-t-3 pb-3">
          <h5 className="mt-4">Are You Sure You Want to Delete Your {title}</h5>
        </Modal.Body>
        <div className="d-flex justify-content-center pb-4">
          <button className="post-btn" onClick={handleClose}>
            Cancel
          </button>
          <button className="ms-3 cancle-btn cursor" onClick={()=>handledelete(postId)} >
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Deletemodal;
