import Promotionmerge from "../../addpromotion";
const ServicePromotion = () => {
  return (
    <>
      <Promotionmerge />
    </>
  );
};

export default ServicePromotion;
