import "./chatroomview.css"
import { images } from "../../constants"
import {useNavigate} from "react-router-dom"
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
const ChatRoomsuerView = () => {
    const navigate=useNavigate()
    return (
        <div>
            <div className="liveChatRoomBackground ">
            
                <div className="d-flex justify-content-center align-items-center pt-5 mt-2">
                    <img src={images.LiveChatroom} alt="" className="chatroomImg" />
                </div>
                <div className="row pt-3 pb-3">
                    <div className="col-7 ps-0">
                        <div className="d-flex justify-content-end pe-4 icon_con">
                           <div className="microphoneBackground"> <KeyboardVoiceIcon className="microphone" /></div>
                           
                        </div>
                    </div>
                    <div className="col-4 ps-0 ms-4 pt-2">
                        <div className="d-flex justify-content-end ">
                            <buton className="liveClose cursor_P" onClick={()=>navigate("/live-chart-room")}>Close</buton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ChatRoomsuerView;