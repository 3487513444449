import { Col, Row, Container } from "react-bootstrap";
import BusinessPropertySideBar from "../createJobsProfile/components/companySidebar/businesspropertySidebar";
import PropertyItemsCard from "../../components/PropertyItems/PropertyItems";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import url from "../../config/axios";
import Managebuttons from "../../components/Manage_Buttons/index.js";
import BackArrow from "../../components/backArrow";

const SaleBussinesProfile = () => {
  const [shopData, setShopData] = useState();
  const [suggest, setSuggest] = useState(0);
  const [suggestdetail, setSuggestdetail] = useState(0);
  const [suggestid, setSuggestid] = useState("");
  const [suggestdata, setSuggestdata] = useState();
  const [companyData, setCompanyData] = useState();
  const { id } = useParams();

  useEffect(() => {
    url
      .get(`company/id/?id=${id}`)
      .then(async (res) => {

        setShopData(res?.data?.data?.Company);
        setSuggestid(res?.data?.data?.Company?._id);
      })
      .catch((err) => {
        console.log("error part ");
      });
  }, []);
  // useEffect(() => {
  //   url.get(`/company/id/?id=${id}`).then(async (res) => {
  //     setCompanyData(res?.data?.data?.Company);
  //   });
  // }, []);
  // console.log("shopppppppppppppppppdataaaaaaa", shopData);

  const suggestdatta = () => {
    url
      .get(`suggestion/getShopSuggestion/vendorid?vendorid=${suggestid}`)
      .then(async (res) => {

        setSuggestdata(res?.data?.data[0]?.suggestions);
      });
  };
  console.log("sugggestiddddd", suggestid);
  return (
    <Container>
      <Col md={12} lg={11} className="mx-auto">
        <Row>
          <Col md={4} lg={3} className="p-0">
            <BusinessPropertySideBar

              Add="Post Add"
              Name="Update Bussines Property"
              update="/update-property-form"
              post="/create-business-Ad"
              navigateTo="/update-property-form"
              shopData={shopData}
              setSuggest={setSuggest}
              suggestdatta={suggestdatta}
              companyData={companyData}
            />
          </Col>
          <Col md={8} lg={9} className=" screenHeight custom_ps-4">
            <div className="Form-Shadow h-100">
              <Row>
                <Col lg="6">
                  <BackArrow
                    back={`/rawmaterial-business-property/${id}`}
                    mystyle="ps-4"
                  />
                </Col>
                <Col lg="6" className="mt-2 pe-4">
                  <Managebuttons businessurl="/business-properties" id={id} />
                </Col>
              </Row>
              <PropertyItemsCard
                urlPass="/business-property-owner-view"
                shopid={id}
                suggest={suggest}
                setSuggest={setSuggest}
                suggestdetail={suggestdetail}
                setSuggestdetail={setSuggestdetail}
                post={`/create-business-Ad/${id}`}
                suggestdata={suggestdata}
                shopData={shopData}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};
export default SaleBussinesProfile;
