import React from "react";
import PostAddForm from "../../../../components/forms/postItemsForm/index";
import { useState, useEffect } from "react";
import url from "../../../../config/axios";
import { useNavigate, useParams } from "react-router-dom";
import { SuccessNotify } from "../../../../constants/tostify";
import { FailedNotify } from "../../../../constants/tostify";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
import { publishImage } from "../../../../helpers/imageupload";
import { useDropzone } from "react-dropzone";
const  WholesellerPostAddForm = () => {
  const { name } = useSelector((state) => state.user);
  const [displayimg,setDisplayimg]=useState();
  const [shopCategory, setShopCategory] = useState()
  const[mygetitem,setMygetitem]=useState();
   const [shopCategorytwo, setShopCategorytwo] = useState()
  const { index } = useParams();
  const [isloading, setIsloading] = useState(false);
  const navigate = useNavigate();

  const [data, setData] = useState({
    category:"",
    subCategory:""
  })

  const Handledata = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.split(".")

    }))
  }

  const categoryName = data?.category[1];
  const categoryid = data.category[0];
  const subcategoryName = data?.subCategory[1];
  const subcategoryid = data.subCategory[0];

  const onDrop = async (acceptedFiles) => {
    try {
      const abc = await publishImage(acceptedFiles);
      setUserData((userData) => ({
        ...userData,
        images: abc
      }));

      setDisplayimg(abc);
    
    }catch (error) {
      FailedNotify("Failed! Try Again");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });
  const [userData, setUserData] = useState({
    userid: name?._id,
    shopid: index,
    itemname: "",
    price: "",
    category: "",
    categoryid:"",
    subCategory: "",
    subCategoryid:"",
    totalquantity: "",
    country: "",
    city: "",
    state: "",
    images: [""],
    description: "",
    status: true,
  });



console.log("______________+++++++++++++++",mygetitem)



  useEffect(() => {
    setUserData((userData) => ({
      ...userData,
    category:categoryName,
    categoryid:categoryid,
    subCategory:subcategoryName,
    subCategoryid:subcategoryid
     
    }));
  
  }, [categoryid,categoryName,subcategoryName,subcategoryid]);


 





  const FirstCategory = () => {
    url.get(`/category/type/?type=wholesellerShop&parentCategory=${mygetitem}`)
      .then(async (response) => {
        console.log("response1230---", response)
        if (response?.data?.success) {
          setShopCategory(response?.data?.data?.Category);
        }
      })
      .catch((error) => {
        console.log("servicesCategories", error)
      });
  }

  useEffect(() => {
    setMygetitem(localStorage.getItem('mycategory'))
  }, [])

  const Mydata = () => {

      url
        .get(`/category/type/?type=wholesellerShopItem&parentCategory=${categoryName}`)
        .then(async (response) => {
          if (response?.data?.success) {
            setShopCategorytwo(response?.data?.data?.Category);
          }
        })
        .catch((error) => {
          console.log("servicesCategories", error)
        });
  }
  const Updatefunction = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .put(`shopitem/id/?id=${index}`, userData)
      .then(async (response) => {
        if (response.data.success) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify("Add Update Successfully");
          }, 1000);
          navigate(
            `/wholesaler-owner-profile/${response?.data?.data?.ShopItem?.shopid}`
          );
        }
      })
      .catch((e) => {
        setIsloading(false);
        FailedNotify("Failed To Update Try Again!");
      });
  };

  useEffect(() => {
    url.get(`shopitem/id/?id=${index}`).then(async (response) => {
      setUserData(response?.data?.data?.ShopItem);
    });
  }, []);

  if (isloading) {
    return <Loader heightsetting="screenHeight" />;
  }

  return (
    <PostAddForm
      butn="Update"
      Updatehandler={Updatefunction}
      setUserData={setUserData}
      userData={userData}
      updateurl="/wholeseller-item-update/"
      backurl="/wholesaler-owner-view"
      index={index}
      Mydata={Mydata}
      Handledata={Handledata}
shopCategory={shopCategory}
      shopCategorytwo={shopCategorytwo} 
      getInputProps={getInputProps}
      getRootProps={getRootProps}
      displayimg={displayimg}
      setDisplayimg={setDisplayimg}
      FirstCategory={FirstCategory}

    />
  );
};

export default WholesellerPostAddForm;
