import "./mychart.css";
import { Ownchatroom, Mychatapi } from "../../../constants/jsonConstant";
import SearchIcon from "@mui/icons-material/Search";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Chatroombackarrow from "../../../components/Chatroom-backarrow";

const Mychart = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(Ownchatroom);
  const [mychat, Mychat] = useState(Mychatapi);

  return (
    <>
      <Helmet>
        <body style="background: white"></body>
      </Helmet>

      <div className="container">
        <div className="screenHeight">
          <div className="row d-flex justify-content-evenly">
            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 custom-bg mt-4 mobile_colmn">
              <div className="row mt-1 bordr-bottm">
                <div className="col-4"><Chatroombackarrow url="/own-chatroom" /></div>
                <div className="col-8 ">
                  <h6 className="pt-1 pb-1">My Chat Rooms</h6>
                </div>
              </div>

              <div class="mt-4 text-center text-white">
                <p style={{ fontSize: "13px" }}>Chat Rooms</p>
              </div>
              <div className="row p-2">
                {mychat.map((e) => (
                  <div className="col-lg-4  col-sm-6 col-md-6 col-12 mt-3">
                    <div class="card" style={{ border: "none" }}>
                      <img
                        class="card-img-top"
                        src={e.image}
                        alt="Card image cap"
                      />
                      <div class="card-body p-0 ownchatroombody">
                        <h6 class="card-title text-white text-center mt-2">
                          {e.groupname}
                        </h6>
                        <div className="d-flex justify-content-center">
                          <button
                            type="button"
                            className=" own-btn mb-2"
                            onClick={() => navigate("/joinown-chatroom")}
                          >
                            Open
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 custom-bg-2 mt-4  mobile_colmn ">
              <div className="row bordr-bottm mt-1">
                <div className="col-12 ">
                  <h6 className="text-center pt-1 pb-1">Join Chat Rooms</h6>
                </div>
              </div>
              <div class="col-12 d-flex justify-content-center ps-0 mt-2">
                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-10 col-11 ps-0 pe-0">
                  {" "}
                  <div class="input-group">
                    {" "}
                    <span class="input-group-addon bg-white border-0">
                      {" "}
                      <SearchIcon />{" "}
                    </span>{" "}
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search"
                    />{" "}
                  </div>{" "}
                </div>
              </div>

              <div className="row scroll__row noscroll p-2">
                {data.map((e) => (
                  <div className="col-lg-4 col-sm-6 col-md-6 col-12 mt-4">
                    <div class="card" style={{ border: "none" }}>
                      <div className="activechatroom pr-1">
                        <span className="dotttt">.</span>
                      </div>

                      <img
                        class="card-img-top"
                        src={e.image}
                        alt="Card image cap"
                      />
                      <div class="card-body p-0 ownchatroombody">
                        <h6 class="card-title text-white text-center mt-2">
                          {e.groupname}
                        </h6>
                        <div className="d-flex justify-content-center">
                          <button
                            type="button"
                            className=" own-btn mb-2"
                            onClick={() => navigate("/chatroom-streamline")}
                          >
                            Join
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mychart;
