import { Container, Row, Col, Form } from "react-bootstrap";
import { courior } from "../../../../constants/jsonConstant";
import { useState } from "react";
import url from "../../../../config/axios";
import { SuccessNotify,FailedNotify } from "../../../../constants/tostify";

const Shippinganddelivering = ({shopid}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [delivery, setDelivery] = useState({
    shopsetting: { 
      shippingAndDelivery: {
        deliveryArea: "",
        pickupLocation: "",
        sameDayDelivery: false, 
        estimatedTimeForDelivery: "",
        shippingServiceProvider: ""
      }
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    url.put( `shop/shipingDeivery/shopid?shopid=${shopid}`, delivery
  ).then((res) => {
      if (res.data.success) {
        SuccessNotify(res.data.message);
      }
    }).catch((e)=>{
      FailedNotify(e.response.data.message)
    })
  };

  const handleInput = (e) => {
    setDelivery((prevState) => ({
      ...prevState,
      shopsetting: {
        ...prevState.shopsetting,
        shippingAndDelivery: {
          ...prevState.shopsetting.shippingAndDelivery,
          [e.target.name]: e.target.name === 'sameDayDelivery' ? e.target.checked : e.target.value
        }
      }
    }));
  };

  return (
    <>
      <Container>
        <Form>
          <Row>
            <Col lg="3">
              <Form.Check
                type="checkbox"
                label="Deliver only in Pakistan"
                name="deliveryArea"
                value="Deliver only in Pakistan"
                id="radioOption1"
                onChange={(e) => handleInput(e)}
              />
            </Col>

            <Col lg="3">
              <Form.Check
                type="checkbox"
                label="International Delivery"
                name="pickupLocation"
                value="International Delivery"
                id="radioOption2"
                onChange={(e) => handleInput(e)}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <Form.Check
                type="checkbox"
                label="Same Day delivery"
                name="sameDayDelivery"
                checked={isChecked}
                id="radioOption3"
                onChange={(e) => {handleInput(e); setIsChecked(e.target.checked);}}
              />
            </Col>
          </Row>

          <Row>
            <Col lg="3">
              <p className="form-check-label mb-0">
                Estimated days for delivery
              </p>

              <Form.Control
                placeholder="Days"
                type="number"
                name="estimatedTimeForDelivery"
                className="labelText custom-fix-one w-75"
                onChange={(e) => handleInput(e)}
              />
            </Col>

            <Col lg="3">
              <p className="form-check-label mb-0">Select Courier Company</p>
              <Form.Select
                className="custom-fix-one w-75"
                aria-label="Default select example"
                name="shippingServiceProvider"
                onChange={(e) => handleInput(e)}
              >
                <option disabled selected>
                  Select
                </option>
                {courior.map((item, index) => (
                  <option key={index}>{item.service}</option>
                ))}
              </Form.Select>
            </Col>
            <div className="d-flex justify-content-end pt-3 pb-4">
              <div>
                <button className="m-0 btn-color" onClick={(e) => handleSubmit(e)}>Submit</button>
              </div>
            </div>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default Shippinganddelivering;
