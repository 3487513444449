import "./dashboard.css";
import { images } from "../../constants/index";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Helmet } from "react-helmet";
import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import VerifiedIcon from "@mui/icons-material/Verified";
import BusinessCards from "./components/businessmen-platform/businessmen-platform";
import BuyingCards from "./components/buying-cards/buying-cards";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const Dashboard = () => {
  const navigate = useNavigate();
  const { name } = useSelector((state) => state.user);

  return (
    <>
      <Row>
        <div className="background-dashboard-color">
          <Container fluid className="px-0 ">
            <Row>
              <Col lg={3} md={3} sm={12} xs={12} className="ps-4  pe-0">
                <div className="CardSize">
                  <Card className="Cardadjust pt-lg-4 pt-3 pt-xl-3">
                    <Card.Body className=" marginsettings">
                      <Col>
                        <div className="profileImg">
                          <img src={images.ProfileImg} />
                        </div>
                      </Col>
                      <Card.Title className="Name pt-3 pb-2">
                        {name.fullname}

                        {/* {userName?.fullname} */}
                      </Card.Title>
                      <Row className="mt-3 mt-xl-0 mt-md-0">
                        <Col md={8} lg={8} sm={3} xs={4} className=" pe-lg-0">
                          <div className="d-flex">
                            <div>
                              <LocationCityIcon style={{ color: "#D62828" }} />
                            </div>
                            <div className="ps-1 ps-xl-1 ps-lg-0 pt-1">
                              <p className="dash-location">
                                Islamabad,Pakistan
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col
                          md={4}
                          lg={4}
                          sm={9}
                          xs={8}
                          className="ps-0 pe-lg-0"
                        >
                          <div className="d-flex">
                            <div>
                              <VerifiedIcon style={{ color: "#006DAA" }} />
                            </div>
                            <div className="ps-1 ps-lg-0 pt-1">
                              <p className="dash-location">Verified</p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg="7 ps-0 pe-0"
                          md="7 ps-0 pe-0"
                          sm="3 ps-3"
                          xs="4 ps-3"
                        >
                          <button
                            className="view-button view-button-resp "
                            onClick={() => navigate("/business-dashboard")}
                          >
                            <WorkIcon className="icon_f text-white ms-0" />
                            Business Dashboard
                          </button>
                        </Col>
                        <Col lg="5  pe-0" md="5  pe-0" sm="9 ps-0" xs="8">
                          <button
                            className="view-button view-button-resp"
                            onClick={() => navigate("/user-profile")}
                          >
                            <PersonIcon className="icon_f text-white" />
                            View Profile
                          </button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              </Col>

              <Col lg={9} md={9} sm={12} xs={12} className="ps-0 pt-">
                <BusinessCards />
              </Col>
            </Row>
            <div className="welcomeSpacing">
              <Col lg={12} xl={12}>
                <div className=" text-center ">
                  <h4 className="welcome-text">Welcome On The Board </h4>
                </div>
              </Col>
            </div>

            <Col lg={12} xl={12} className="buyingCardsSpace ">
              <BuyingCards />
            </Col>
          </Container>
        </div>
      </Row>
    </>
  );
};
export default Dashboard;
