import "./profileconnectivity.css";
import Row from "react-bootstrap/esm/Row";
import { useNavigate } from "react-router-dom";
import {
  generalFriendsList,
  ConnectivityCards,
} from "../../../../constants/jsonConstant";
import Card from "react-bootstrap/Card";
const Profileconnectivity = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="scrollable noscroll pb-4">
        <Row className="justify-content-center">
          {ConnectivityCards?.map((data) => (
            <Card className="mt-2 ms-2 card_W">
              <Card.Body className="p-1">
                <div className="d-flex justify-content-between">
                  <div className="font_small">
                    <h6 className="mb-0 h6_respons">
                      {data.title}
                    </h6>
                    <Card.Text>{data.subtitle}</Card.Text>
                  </div>

                  <div
                    className="font_small font-navy"
                    style={{ cursor: "pointer" }}
                  >
                    <p onClick={() => navigate('/view-friends')}>
                      <b>View all</b>
                    </p>
                  </div>
                </div>
                <div className="row pt-3 ">
                  {generalFriendsList?.map((profile) => (
                    <div className="col-4 ps-2 pe-2" key={profile.id}>
                      <div className="personImgSize">
                        <img src={profile.generalImg} alt="" />
                      </div>
                      <div className="font_exsmall mt-2">
                        <p>{profile.name}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Card.Body>
            </Card>
          ))}
        </Row>
      </div>
    </>
  );
};

export default Profileconnectivity;

