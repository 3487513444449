import { Row, Col, InputGroup, Form } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
const Title = (props) => {
  return (
    <>
      <Row>
        <div className="d-flex justify-content-between">
          <Col lg="5 ms-3">
            <h6 className="mb-0 font-bold font-navy">{props.name}</h6>
            <p className="font-sm">{props.title}</p>
          </Col>

          <Col lg="5  me-3">
            <InputGroup className="w-100 mt-2">
              <InputGroup.Text id="basic-addon1">
                <SearchIcon />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
          </Col>
        </div>
      </Row>
    </>
  );
};

export default Title;
