import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import backArrow from "../../../../components/backArrow";
import { useNavigate } from "react-router-dom";
import { images } from "../../../../constants";
import url from "../../../../config/axios";
import Loader from "../../../../components/Loader";
const Showadd = () => {
  const [values, setValues] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsloading(true);
    url.get("/sellvehicle").then(async (res) => {
      if (res.data.success) {
        setIsloading(false);
      }
      setValues(res.data.data.SellVehicles);
    });
  }, []);
  return (
    <>
      <Container>
        <Row>
          <Col lg={11} className="mx-auto">
            <div className=" screenHeight ">
              <div className="Form-Shadow border  ms-3 ps-0 pe-0 ms-0 me-0 pt-2 pb-2 ">
                <Row className="ms-3 me-3  align-Items-center pt-2 pb-2">
                  <Col lg={6}>
                    <div>
                      <backArrow back="/SellingSide" />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="d-flex justify-content-end ">
                      <button
                        className="border ps-3 pe-3 pt-1 pb-1  bg-blue text-white"
                        onClick={() => navigate("/sell-single")}
                      >
                        Post add
                      </button>
                    </div>
                  </Col>
                </Row>

                {isloading ? (
                  <Loader heightsetting="loaderHeight" />
                ) : (
                  <div className=" row ps-5 pe-5 noscroll scroll-shops_2">
                    {values?.map((Items) => (
                      <div className="col-lg-3 col-xl-2 col-md-6 p-2">
                        <div className="border_2">
                          <div key={Items?.id}>
                            <div className="fix-img">
                              <img
                                className="c_img"
                                src={Items?.images[0]}
                                alt=""
                              />
                            </div>

                            <div className="d-flex justify-content-between pt-1 pb-1 ps-2 pe-2 ">
                              <div>
                                <p className="mb-0 pt-1  items-card-font font-blue">
                                  {Items?.model}
                                </p>
                              </div>

                              <div> {Items?.modelYear}</div>
                            </div>
                            <div className="d-flex justify-content-between pt-1 pb-1 ps-2 pe-2 ">
                              <div>
                                <p className="mb-0 pt-1  items-card-font font-blue">
                                  PKR {Items?.price}
                                </p>
                              </div>

                              <div>
                                {" "}
                                <img src={images.StarImg} />
                              </div>
                            </div>

                            <div className="d-flex justify-content-around mb-2 mt-1 ps-2 pe-2">
                              <p
                                className="mb-0 view-btn w-100 text-center  me-1"
                                onClick={() =>
                                  navigate(
                                    `/used-vehicle-addview/${Items?._id}`
                                  )
                                }
                              >
                                View
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Showadd;
