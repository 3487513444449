import React, { useState } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Col,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { images } from "../../../../constants";
import { MenuLeftbar } from "../../../../constants/jsonConstant";
import { MenuLeftbar2 } from "../../../../constants/jsonConstant";
import { MenuLeftbar3 } from "../../../../constants/jsonConstant";
import { MenuLeftbar4 } from "../../../../constants/jsonConstant";
import { MenuLeftbar5 } from "../../../../constants/jsonConstant";
import { useNavigate } from "react-router-dom"
import CloseIcon from '@mui/icons-material/Close';
import "./index.css"

function Hamburger() {
  const [openDropdownId, setOpenDropdownId] = useState(null);

  const handleDropdownToggle = (itemId) => {
    setOpenDropdownId(openDropdownId === itemId ? null : itemId);
  };


  const handleOptionClick = (option) => {
  };

  const [isOpen, setIsOpen] = useState(false);
  const [hamburgeropen, setHamburgeropen] = useState(false);
  function handleClick() {
    setIsOpen(true);
  }

  const navigate = useNavigate();

  return (
    <>
      <div>
dsffffffffffffffffffffffffff


      
      
      </div>

    </>
  );
}

export default Hamburger;


