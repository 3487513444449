import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import { certifications } from "../../../../constants/jsonConstant";
import { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
const Certifications = ({ handelvalues }) => {
  const [addcertifications, setAddCertification] = useState(certifications);
  const AddmoreFields = () => {
    setAddCertification([...addcertifications, addcertifications]);
  };
  const handleRemoveClick = (index) => {
    const list = [...addcertifications];
    list.splice(index, 1);
    setAddCertification(list);
  };
  return (
    <div className="px-2">
      {addcertifications?.map((certificationsinput, index) => (
        <>
          <Row>
            <Col md={4} lg={4}>
              <Form.Label className="custm-lble ">
                {certificationsinput?.nameofcertifications}
              </Form.Label>
              <Form.Control
                placeholder="Name of Certification"
                type="text"
                name="nameofcertificate"
                className="labelText "
                onChange={(e) => handelvalues(e)}
              />
            </Col>
            <Col md={4} lg={4}>
              <Form.Label className="custm-lble ">
                {certificationsinput?.institution}
              </Form.Label>
              <Form.Control
                placeholder="Institution"
                type="text"
                name="institution"
                className="labelText "
                onChange={(e) => handelvalues(e)}
              />
            </Col>
            <Col md={4} lg={4}>
              <Form.Label className="custm-lble ">
                {certificationsinput?.Issuingauthority}
              </Form.Label>
              <Form.Control
                placeholder="Issuing Authority"
                type="text"
                name="issuingauthority"
                className="labelText "
                onChange={(e) => handelvalues(e)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} lg={4}>
              <Form.Label className="custm-lble ">
                {certificationsinput?.effectivedate}
              </Form.Label>
              <Form.Control
                placeholder="Effective Date"
                type="text"
                name="effectivedate"
                className="labelText"
                onChange={(e) => handelvalues(e)}
              />
            </Col>
            <Col md={4} lg={4}>
              <Form.Label className="custm-lble ">
                Expirationdate
              </Form.Label>
              <Form.Control
                placeholder="Expiration Date"
                type="text"
                name="expirationdate"
                className="labelText"
                onChange={(e) => handelvalues(e)}
              />
            </Col>
          </Row>

          {index === 0 ? (
            " "
          ) : (
            <div className="d-flex justify-content-end ">
              <div>
                <DeleteIcon className="color-red" />
              </div>
              <div
                className="d-flex align-items-center cursor_P"
                onClick={() => handleRemoveClick()}
              >
                <p className="color-remove-red mb-0">Remove</p>
              </div>
            </div>
          )}

          <div class="line-width">
            <hr className="mb-2"></hr>
          </div>
          <div>
            <p className="color-text cursor_P" onClick={() => AddmoreFields()}>
              <AddCircleIcon style={{ fontSize: "large" }} /> Add More
            </p>
          </div>
        </>
      ))}

      {/* <div className="d-flex justify-content-end pt-3 pb-4 ">
        <div>
          <button className="m-0 btn-color ">Save</button>
        </div>
      </div> */}
    </div>
  );
};
export default Certifications;
