import { Row, Col } from "react-bootstrap";
import React, { useState } from "react";
import CVAccordions from "./components/CVAccordions";
import AddIcon from "@mui/icons-material/Add";
import "./CVform.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackArrow from "../../components/backArrow";
import url from "../../config/axios";
import { FailedNotify, SuccessNotify } from "../../constants/tostify";

const CVForm = () => {
  const { name } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);

  const [cvdata, setCvdata] = useState({
    user: name._id,

    certification: [
      {
        nameofcertificate: "",
        institution: "",
        issuingauthority: "",
        effectivedate: "",
        expirationdate: "",
      },
    ],
  });
  const handelvalues = (e) => {
    setCvdata((prevState) => ({
      ...prevState,
      certification: {
        ...prevState.certification,
        [e.target.name]: e.target.value,
      },
    }));
  };
  const PostCvData = (e) => {
    setIsloading(true);
    e.preventDefault();
    url
      .post("cvform/postNew", cvdata)
      .then(async (response) => {
        if (response?.data?.message.length > 0) {
          setIsloading(false);
          setTimeout(() => {
            SuccessNotify(" Successfully Post CV Data");
          }, 1000);
          navigate("/select-template");
        }
      })
      .catch((error) => {
        setIsloading(false);
        FailedNotify("Failed to Post CV Data");
      });
  };
  return (
    <Row>
      <form>
        <div className="screenHeight d-flex justify-content-center align-items-center">
          <Col lg={8} md={9} className="Form-Shadow">
            <div class="arrowback">
              <BackArrow back="/SellingSide" black="black" mystyle="p-1 ps-3" />
            </div>
            <Col md={11} lg={11} className="mx-auto px-3">
              <div className="d-flex justify-content-center pt-3 pb-5">
                <div className="d-flex align-content-center gap-3">
                  <AddIcon className="iconWhite plus-icon mt-2" />
                  <p className="color-one mt-1">Add Information</p>
                </div>

                <div className="d-flex align-items-center pt-1 ps-2">
                  <h6 className="titleText p-0">Add Information</h6>
                </div>
              </div>
              <div>
                <CVAccordions
                  one="Profile Information"
                  two="Previous Employement History"
                  three="Formal Education"
                  four="Professional Skills"
                  five="Language Skills"
                  six="Certifications/Licences"
                  handelvalues={handelvalues}
                />
              </div>
              <div className="d-flex justify-content-end pt-3 pb-4 ">
                {cvdata?.certification?.expirationdate?.length > 0 ? (
                  <div>
                    <button className="m-0 btn-color " onClick={PostCvData}>
                      Post CV
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Col>
        </div>
      </form>
    </Row>
  );
};
export default CVForm;
