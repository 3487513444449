import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useState } from "react";
import Pagination from "react-bootstrap/Pagination";
import "../candidatesBox/candidatesBox.css";
import { useLocation } from "react-router-dom";
import { getAsset } from "../../helpers/helper";
import Candidateshowmodal from "../Show_candidatemodal";
import Loader from "../../components/Loader";
import { ImagesearchRoller } from "@mui/icons-material";
  const JobapplyCandidates = ({ appliedJobdata, isloading }) => {
  const [lgShow, setLgShow] = useState(false);
  const [coverletter, setCoverletter] = useState("");
  const [cvdata, setCvdata] = useState("");
  const location = useLocation(); 
  return (

    <Row className="px-3">
      <Candidateshowmodal
        lgShow={lgShow}
        setLgShow={setLgShow}
        coverletter={coverletter}
        cvdata={cvdata}
      />

      <div
        className={
          location.pathname === "/RestaurantAlldata"
            ? ""
            : `noscroll manage-height`
        }
      >
        {isloading ? (
          <Loader heightsetting="scroll-company" />
        ) : (
          <Row>
          
            {appliedJobdata?.map((box) => (
              <Col md={3} lg={3} className="px-md-1 pb-1 mt-2">
                <Card className="border candidate-card-p p-3">
                  <div className="py-1 d-flex flex-column align-items-center">
                    <div className="candidateImg">
                      <img
                        src={getAsset(box?.image[0])}
                        alt=""
                        className="h-100 w-100"
                        crossOrigin="anonymous"
                      />
                    </div>

                    <img src={ImagesearchRoller} alt="" />

                    <div>
                      <h6 className="candidateName mb-0">{box?.name}</h6>
                    </div>
                    <div>
                      <p className="candidateOccupation mb-0 font_13">
                        {box?.designation}
                      </p>
                    </div>
                    <div className="d-flex justify-content-around w-100 mt-1">
                      <button
                        className="connect-btn mt-1 w-50"
                        onClick={() => {
                          setLgShow(true);
                          setCoverletter(box?.coverletter);
                          setCvdata(box?.cv[0]);
                        }}
                      >
                        View
                      </button>
                    </div>
                    {/* <div>
                      <Button variant="primary">Connect</Button>
                      <Button variant="danger">Ignore</Button>
                    </div> */}
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </div>

      <Col className="pt-2 mx-auto d-flex justify-content-center mt-5">
        <Pagination>
          <Pagination.First />
          <Pagination.Prev />
          <Pagination.Item>{1}</Pagination.Item>
          <Pagination.Ellipsis />

          <Pagination.Item>{10}</Pagination.Item>
          <Pagination.Item>{11}</Pagination.Item>
          <Pagination.Item active>{12}</Pagination.Item>
          <Pagination.Item>{13}</Pagination.Item>
          <Pagination.Item disabled>{14}</Pagination.Item>
           
          <Pagination.Ellipsis />
          <Pagination.Item>{20}</Pagination.Item>
          <Pagination.Next />
          <Pagination.Last />
        </Pagination>
      </Col>
    </Row>
  );
};
export default JobapplyCandidates;
