import "../Forgot password/Forgotpassword.css";

import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { images } from "../../../../constants";

const EmailVerify = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container fluid className="f-bg-color">
        <Row>
          <Col
            lg="4"
            className="mx-auto  background-white f-modal-style rounded cursor_P"
            onClick={() => navigate("/")}
          >
            <div className="ps-5 pe-5">
              <div className=" d-flex justify-content-center">
                <img src={images.Frame} alt="" />
              </div>

              <h5 className="text-center mt-2">Verification</h5>
              <p className="text-center mt-2 font-ss">
                We have send you verification code on your given email enter the
                code to verify
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EmailVerify;
