import React from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BackArrow from "../../../components/backArrow";
import Searchicon from "../Components/Searchicon";
import BuyerOne from "../Components/BuyerOne";
import SellerOne from "../Components/SellerOne";
import Frequentlyquestions from "../Components/Frequentlyquestions";
import AccordionBuyerside from "../AccordionBuyer";
import Chatonline from "../Components/Chatonline";
import Requestcall from "../Components/Requestcall";
const Customermerge = () => {
  const [Showopen, setShowopen] = useState(1);
  return (
    <Container>
      <div className="d-flex justify-content-center  align-items-center mt-5 pt-3">
        <Col lg={10} md={10} sm={12} xs={12} className=" Form-Shadow ">
          <div className="arrowback ps-3">
            <BackArrow back="/SellingSide" black="black" mystyle="p-1 ps-3"/>
          </div>
          <Searchicon />
          <Row className="mt-3 ms-5 ps-3">
            <Col lg={6} className="ps-0 cursor_P">
              <BuyerOne setShowopen={setShowopen} />
            </Col>
            <Col lg={6} className="cursor_P">
              <SellerOne setShowopen={setShowopen} />
            </Col>
          </Row>
          {Showopen === 1 && <AccordionBuyerside />}
          {Showopen === 2 && <Frequentlyquestions />}

          <Row className="mt-3 ms-5">
            <Col lg={6}>
              <Chatonline />
            </Col>
            <Col lg={6}>
              <Requestcall />
            </Col>
          </Row>
        </Col>
      </div>
    </Container>
  );
};
export default Customermerge;
