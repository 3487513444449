import { useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { images } from "../../constants";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
const Purchasemodal = ({ purchase, setPurchase }) => {
    const ref = useRef();
    const [mdldata, setMdldata] = useState(1);
    const [otp, setOtp] = useState({
        one1: "",
        one2: "",
        one3: "",
        one4: "",
    }
    );
    const handleValue = (e) => {
        setOtp((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));

        if (e.target.name === "one1" && e.target.value.length === 1) {
            one2.current.focus();
        }

        else if (e.target.name === "one2" && e.target.value.length === 1) {
            one3.current.focus();
        }

        else if (e.target.name === "one3" && e.target.value.length === 1) {
            one4.current.focus();
        }
    };
    const one2 = useRef();
    const one3 = useRef();
    const one4 = useRef();
    return (
        <>
            <Modal
                size="xl"
                show={purchase}
                onHide={() => setPurchase(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                className=''
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="p-sm-5">
                    <Row className="justify-content-center">
                        <Col xl={8} md={12} lg={12} sm={12}>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Payment
                            </Modal.Title>
                            <div>
                                <hr />
                                <div className="">
                                    <p className="paywith-div">Paywith:</p>
                                </div>
                                <div className="d-flex">
                                    <div
                                        className="d-flex justify-content-between gap-2"
                                    >
                                        <input
                                            type="radio"
                                            name="payment"
                                            value=""
                                            onClick={() => setMdldata(1)}
                                            className=""
                                            style={{ accentColor: "green" }}
                                        />
                                        <label>Card</label>
                                        <br />
                                    </div>
                                    <div
                                        className="d-flex justify-content-between gap-2"
                                    >
                                        <input
                                            type="radio"
                                            name="payment"
                                            value=""
                                            className=""
                                            style={{ accentColor: "green" }}
                                            onClick={() => setMdldata(2)}
                                        />
                                        <label>Bank</label>
                                        <br />
                                    </div>
                                </div>
                            </div>
                            {mdldata === 1 && (
                                <div className='col-12 mt-4'>
                                    <h6 className="">Card Number</h6>
                                    <div className="mt-2">
                                        <input className='w-100 paymentmdl' />
                                    </div>
                                    <div className='d-flex flex-column flex-sm-row justify-content-between mt-3'>
                                        <div>
                                            <h6 className="">Expiration Date</h6>
                                            <input className='paymentmdl w-100' />
                                        </div>
                                        <div>
                                            <h6 className="">CVV</h6>
                                            <input className='paymentmdl w-100' />
                                        </div>
                                    </div>
                                    <div className='mt-5'>
                                        <button
                                            variant="primary"
                                            size="lg"
                                            className=" button-settingdone text-light  w-100"
                                            onClick={() => setMdldata(3)}
                                        >
                                            Pay USD58.8!
                                        </button>
                                    </div>
                                </div>
                            )}
                            {mdldata === 2 && (
                                <div className=' mt-4' >
                                    <div class="form-group">
                                        <select class="form-control p-2" id="sel1">
                                            <option selected disabled>Choose your bank</option>
                                            <option>Meezan Bank</option>
                                            <option>Allied Bank</option>
                                            <option>Habib Bank</option>
                                        </select>
                                    </div>
                                    <div className='mt-3'>
                                        <h6> Enter your Bank Account Number</h6>
                                        <div className="mt-2">
                                            <input className='w-100 p-1 paymentmdl mt-1' />
                                        </div>
                                    </div>
                                    <div className='mt-5'>
                                        <button
                                            variant="primary"
                                            size="lg"
                                            className=" button-settingdone text-light w-100"
                                        >
                                            Proceed
                                        </button>
                                    </div>
                                </div>
                            )}
                            {mdldata === 3 && (
                                <div className='mt-4'>
                                    <div className="d-flex justify-content-center">
                                        <p className="fourdigits">
                                            Enter 4 digits card pin to confirm this payment
                                        </p>
                                    </div>
                                    <div className="otp-container d-flex justify-content-center">
                                        <input
                                            type="text"
                                            className="otp-input"
                                            name="one1"
                                            maxLength="1"
                                            onChange={(e) => handleValue(e)}
                                        />
                                        <input
                                            type="text"
                                            className="otp-input"
                                            maxLength="1"
                                            name="one2"
                                            onChange={(e) => handleValue(e)}
                                            ref={one2}
                                        />
                                        <input
                                            type="text"
                                            className="otp-input"
                                            maxLength="1"
                                            name="one3"
                                            onChange={(e) => handleValue(e)}
                                            ref={one3}
                                        />
                                        <input
                                            type="text"
                                            className="otp-input"
                                            maxLength="1"
                                            name="one4"
                                            onChange={(e) => handleValue(e)}
                                            ref={one4}
                                        />
                                    </div>
                                    <div className="mt-5 d-flex justify-content-center">
                                        <button
                                            variant="primary"
                                            size="lg"
                                            className="confirmpaymentsett button-settingdone text-light  w-100"
                                        >
                                            Confirm Payment
                                        </button>
                                    </div>

                                </div>
                            )}

                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Purchasemodal;