import React from 'react'
import { Container } from 'react-bootstrap'
import './style.css'
import { images } from '../../constants'
const TableEmail2 = () => {
  return (
    <div>
      <Container>


        <div>
          <div class="table-responsive ">
            <table class="table table-bordered border_3">
              <thead>
                <tr className='TABLE'>

                  <th className='headtxt1  text-center '>Items</th>
                  <th className='headtxt2 tablerow text-left '>Descripition</th>
                  <th className='headtxt3 text-center '>Quantity</th>

                </tr>
              </thead>
              <tbody className='bg-clr-class'>
                <tr>
                  <td className='Table-font text-center pt-1 pb-0 fw-bold'>Bolts</td>
                  <td className='Table-font text-left pt-1 pb-0 fw-bold'>Steal M10 Bolts </td>
                  <td className='Table-font text-center pt-1 pb-0 fw-bold'>10</td>

                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>

                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>

                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>

                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>

                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>

                </tr>
                <tr>
                
                  <td className='bbb pb-0 pt-1 ps-1 pe-1'><img className='me-1' src={images.InsertRow} width='15' height='15' />Add Row</td>
                  <td></td>
                  <td></td>

                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default TableEmail2
