
import ChatRoomInvitePeople from "./components/InviteGoLiveChat";
import ChatRoomOwnView from "./components/OwnLiveChatView";
import { Helmet } from "react-helmet";
const UserVeiwChatRoom = () => {
  return (
    <div>
      <Helmet>
        <body style="background:#0FC2C0"></body>
      </Helmet>
      <div className="container">
        <div className="screenHeight">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-5 col-sm-5 col-12  mt-4">
              <ChatRoomInvitePeople />
            </div>
            <div className="col-xl-8 col-lg-8 col-md-7 col-sm-7 col-12 ps-0 pe-0 mt-4">
              <ChatRoomOwnView />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserVeiwChatRoom;
