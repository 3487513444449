import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Container} from 'react-bootstrap'
import PostAddIcon from '@mui/icons-material/PostAdd';
import "./style.css"

const Creatmeeting = ({mettingm,handleClose}) => {

    const navigate = useNavigate();

  return (
    <div>
    
   
   
   {/* <Button variant="primary" onClick={handleShow}>
       Launch demo modal
     </Button> */}


     <Modal show={mettingm} onHide={handleClose}>
       <Modal.Header >
   <KeyboardBackspaceIcon onClick={handleClose} className='Arrow'/>
      
       </Modal.Header>

       <div className='MODLBTTON1 mt-3 mb-3  d-flex justify-content-center'>
        
       
       
       <Button onClick={() => navigate("/GoogleMeet")} className='pt-2 pb-2 ps-4 pe-4 fs-4 ' variant="primary" > <PostAddIcon  className='addicon me-2 mb-1'/>Create Meeting Room </Button>
      
       </div>
    
     </Modal>

   
   
    </div>
  )
}

export default Creatmeeting
