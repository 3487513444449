import { Col, Form, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import BackArrow from "../backArrow";
import { useRef } from "react";
import { images } from "../../constants";
import PersonalImage from "../../helpers/fileUpload";
import { useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
const VehicleAdd = ({
  back,
  butn,
  postbtn,
  cancel,
  setUserData,
  handlerFunction,
  userData,
  id,
  updatefunction,
  inputChange,
}) => {
  const location = useLocation();

  const [selectedImage, setSelectedImage] = useState("");

  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedstateone, setSelectedstateone] = useState("");
  const [searchTermone, setSearchTermone] = useState("");

  const [selectedcity, setSelectedcity] = useState("");
  const [searchTermtwo, setSearchTermtwo] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle the country selection
  const handleCountryChange = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
  };

  const handleSearchChangestate = (event) => {
    setSearchTermone(event.target.value);
  };

  const handleSearchChangecity = (event) => {
    setSearchTermtwo(event.target.value);
  };

  // Function to handle the state selection
  const handleCountryChangestate = (event) => {
    const { value } = event.target;
    setSelectedstateone(value);
  };

  // Function to handle the city selection
  const handleCountryChangecity = (event) => {
    const { value } = event.target;
    setSelectedcity(value);
  };
  const [hidefield, setHidefield] = useState(1);
  const [hidestate, setHidestate] = useState(1);
  const [hidecity, setHidecity] = useState(1);

  const [countrydata, SetCountrydata] = useState([]);
  const [getcountry] = useState();
  const [selectedState] = useState();
  const [getState, setState] = useState([]);
  const [getcity, setGetcity] = useState([]);
  const [statetwo, setStatetwo] = useState(getcountry);
  const [city, setCity] = useState(selectedState);
  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => SetCountrydata(res.data))

      .catch((error) => {});
  }, []);

  const country = [...new Set(countrydata.map((item) => item.country))];

  country.sort();

  const handleCountry = (e) => {
    let states = countrydata.filter(
      (state) => state.country === e.target.value
    );
    states = [...new Set(states.map((item) => item.subcountry))];
    states.sort();
    setState(states);
  };

  const handleState = (e) => {
    let cities = countrydata.filter(
      (city) => city.subcountry === e.target.value
    );
    setGetcity(cities);
  };
  const inputfile = useRef(null);
  const upload_img = () => {
    inputfile.current.click();
  };

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      console.log("mtfile", e.target.files[0]);
      setSelectedImage(e.target.files[0]);
      // PersonalImage(e.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <>
      {selectedImage && <PersonalImage image={selectedImage} />}
      <Row>
        <form>
          <div className="screenHeight d-flex justify-content-center align-items-center ">
            <Col lg={9} md={11} className="Form-Shadow">
              {location.pathname === `/update-vehicle/${userData?._id}` ? (
                <div class="arrowback">
                  <BackArrow back={`/vehicaldetail/${id}`} black="black" />
                </div>
              ) : (
                <div class="arrowback">
                  <BackArrow back={back + id} black="black" />
                </div>
              )}

              <div className="px-3">
                <Row className="mt-4">
                  <Col md={3} lg={3} xl={3}>
                    <Form.Label className="custm-lble pb-1">
                      Vehicle Name
                    </Form.Label>
                    <Form.Control
                      placeholder="Name"
                      name="brandName"
                      className="labelText form-control-seller"
                      onChange={(e) => inputChange(e)}
                      defaultValue={userData?.brandName}
                    />
                  </Col>
                  <Col md={3} lg={3} xl={3}>
                    <Form.Label className="custm-lble pb-1">
                      Vehicle Model
                    </Form.Label>
                    <Form.Control
                      placeholder="Model"
                      name="model"
                      className="labelText form-control-seller"
                      onChange={(e) => inputChange(e)}
                      defaultValue={userData?.model}
                    />
                  </Col>
                  <Col md={3} lg={3} xl={3}>
                    <Form.Label className="custm-lble pb-1">
                      Number Plate
                    </Form.Label>
                    <Form.Control
                      placeholder="Job Experience"
                      name="noPlate"
                      className="labelText form-control-seller"
                      onChange={(e) => inputChange(e)}
                      defaultValue={userData?.noPlate}
                    />
                  </Col>
                  <Col md={3} lg={3} xl={3}>
                    <Form.Label className="custm-lble pb-1">
                      Vehicle Color
                    </Form.Label>
                    <Form.Control
                      placeholder="Color"
                      name="color"
                      className="labelText form-control-seller"
                      onChange={(e) => inputChange(e)}
                      defaultValue={userData?.color}
                    />
                  </Col>
                  <div className="col-md-3 mt-3">
                    <label
                      htmlFor="inputPassword4"
                      placeholder="First name"
                      className="form-label labelSize"
                    >
                      Country*
                    </label>
                    <div className="blood-type input-style">
                      <div className="d-flex">
                        <div className="w-100">
                          <input
                            type="search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder="Search country here...."
                            className="w-100 search-input"
                            onClick={() => setHidefield(0)}
                          />
                        </div>
                        <div>
                          <span>
                            <SearchIcon className="search-icon-fs" />
                          </span>
                        </div>
                      </div>

                      <select
                        onClick={() => setHidefield(1)}
                        value={selectedCountry}
                        onChange={(e) => {
                          handleCountryChange(e);
                          handleCountry(e);
                        }}
                        className="b-radius selectSize w-100"
                        name="country"
                      >
                        {location.pathname === `/update-vehicle/${id}` ? (
                          <option>{userData?.country}</option>
                        ) : (
                          hidefield && <option>Select country</option>
                        )}
                        {country
                          .filter((country) =>
                            country
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          .map((country, index) => (
                            <option key={country} value={getcountry}>
                              {country}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3 mt-3">
                    <label
                      htmlFor="inputPassword4"
                      placeholder="First name"
                      className="form-label labelSize"
                    >
                      State*
                    </label>
                    <div className="blood-type input-style">
                      <div className="d-flex">
                        <div className="w-100">
                          <input
                            type="search"
                            value={searchTermone}
                            onChange={handleSearchChangestate}
                            placeholder="Search state here...."
                            className="w-100 search-input"
                            onClick={() => setHidestate(0)}
                          />
                        </div>
                        <div>
                          <span>
                            <SearchIcon className="search-icon-fs" />
                          </span>
                        </div>
                      </div>

                      <select
                        onClick={() => setHidestate(1)}
                        className="b-radius selectSize w-100"
                        value={selectedstateone}
                        aria-label="Default select example "
                        name="state"
                        onChange={(e) => {
                          handleState(e);
                          handleCountryChangestate(e);
                        }}
                      >
                        {location.pathname === `/update-vehicle/${id}` ? (
                          <option>{userData?.state}</option>
                        ) : (
                          hidestate && <option>Select state</option>
                        )}

                        {/* <option>
                            {Open === undefined
                              ? "Select Country First"
                              : "select state"}
                          </option> */}

                        {getState
                          .filter((country) =>
                            country
                              .toLowerCase()
                              .includes(searchTermone.toLowerCase())
                          )
                          .map((country) => (
                            <option key={country} value={selectedState}>
                              {country}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3 mt-3">
                    <label
                      htmlFor="inputPassword4"
                      placeholder="First name"
                      className="form-label labelSize"
                    >
                      City
                    </label>
                    <div className="blood-type input-style">
                      <div className="d-flex">
                        <div className="w-100">
                          <input
                            type="search"
                            value={searchTermtwo}
                            onChange={handleSearchChangecity}
                            placeholder="Search city here...."
                            className="w-100 search-input"
                            onClick={() => setHidecity(0)}
                          />
                        </div>
                        <div>
                          <span>
                            <SearchIcon className="search-icon-fs" />
                          </span>
                        </div>
                      </div>

                      <select
                        onClick={() => setHidecity(1)}
                        value={selectedcity}
                        className="b-radius selectSize w-100"
                        aria-label="Default select example"
                        name="city"
                        onChange={(e) => {
                          handleCountryChangecity(e);
                        }}
                      >
                        {/* <option>
                              {city === undefined
                                ? "Select State First"
                                : "Select City"}
                            </option> */}
                        {location.pathname === `/update-vehicle/${id}` ? (
                          <option>{userData?.city}</option>
                        ) : (
                          hidecity && <option>Select city</option>
                        )}

                        {getcity
                          .filter((country) =>
                            country.name
                              .toLowerCase()
                              .includes(searchTermtwo.toLowerCase())
                          )
                          .map((country) => (
                            <option key={country.name}>{country.name}</option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <Col md={3} lg={3} xl={3} className="mt-3">
                    <Form.Label className="custm-lble">Contact</Form.Label>
                    <Form.Control
                      placeholder="Contact"
                      name="contact"
                      className="labelText form-control-seller"
                      onChange={(e) => inputChange(e)}
                      defaultValue={userData?.contact}
                    />
                  </Col>
                </Row>

                <div>
                  <Form.Label className="custm-lble pb-1 mt-4">
                    Add Description
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={6}
                    name="description"
                    className="labelText"
                    onChange={(e) => inputChange(e)}
                    defaultValue={userData?.description}
                  />
                </div>
                <div className="pt-3 pb-2">
                  <h6 className="custm-lble-2">Upload Image</h6>
                </div>
                <span role="button">
                  {" "}
                  <div
                    onClick={upload_img}
                    className="text-success"
                    onChange={onChangePicture}
                  >
                    {" "}
                    <img className="w-img-upload" src={images.Upload} />{" "}
                    <input
                      type="file"
                      id="file"
                      ref={inputfile}
                      style={{ display: "none" }}
                      name="images"
                      onChange={(e) => onChangePicture(e)}
                    />{" "}
                  </div>
                </span>

                <div className="d-flex justify-content-end pt-2 mt-1 pb-3 mb-2">
                  <div>
                    <button
                      className="btn-blue-sm"
                      onClick={
                        butn === "Post" ? handlerFunction : updatefunction
                      }
                    >
                      {butn}
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </form>
      </Row>
    </>
  );
};

export default VehicleAdd;
