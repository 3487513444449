import { Row, Col, Container } from "react-bootstrap";
import OwnerShopProfile from "../../components/OwnerShopProfile/OwnerShopProfile";
import UsedvehicleitemShop from "../../components/Used_vehicle_shopitems";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import BackArrow from "../../components/backArrow";
import Managebuttons from "../../components/Manage_Buttons";
import url from "../../config/axios";
const Usedvehicleprofile = () => {
  const { id } = useParams();
  const [shopData, setShopData] = useState();
  const [suggest, setSuggest] = useState(0);
  const [suggestdata, setSuggestdata] = useState();
  const [suggestdetail, setSuggestdetail] = useState(0);
  useEffect(() => {
    url.get(`shop/id?id=${id}`).then(async (res) => {
      setShopData(res?.data?.data?.Shop);
    });
  }, []);
  const suggestdatta = () => {
    url
      .get(`suggestion/getShopSuggestion/vendorid?vendorid=${shopData?.shopid}`)
      .then(async (res) => {
     
        setSuggestdata(res?.data?.data[0]?.suggestions);
      });
  };
  return (
    <>
      <Container>
        <Col md={12} lg={11} className="mx-auto">
          <Row>
            <Col md={4} lg={3} className="p-0">
              {" "}
              <OwnerShopProfile
                Add="Create Items"
                Name="Update UsedVehicle Profile"
                navigateTo="/usedvehicle-update"
                post="/usedvehicle_add"
                shopData={shopData}
                setSuggest={setSuggest}
                suggestdatta={suggestdatta}
              />
            </Col>
            <Col md={8} lg={9} className=" screenHeight custom_ps-4">
              <div className="Form-Shadow h-100">
                <Row>
                  <Col sm={3} md={4} lg={5}>
                    <BackArrow
                      mystyle="ms-0 ps-2 pt-1"
                      back={`/used-vehicle-businessprofile/${id}`}
                    />
                  </Col>
                  <Col sm={9} md={8} lg={7} className="mt-2 pe-4">
                    <Managebuttons id={id} businessurl="/usedvehicle-shops" />
                  </Col>
                </Row>
                <UsedvehicleitemShop
                  urlPass="/farming-item-owner-view"
                  shopid={id}
                  shopData={shopData}
                  suggest={suggest}
                  setSuggest={setSuggest}
                  suggestdata={suggestdata}
                  suggestdetail={suggestdetail}
                  setSuggestdetail={setSuggestdetail}
                  post={`/usedvehicle_add/${id}`}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Container>
    </>
  );
};
export default Usedvehicleprofile;
