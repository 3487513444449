import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { images } from "../../../../constants";
import axios from "axios";
import "./Style.css";
import SearchIcon from "@mui/icons-material/Search";

const Singleitem = () => {
  const location = useLocation();
  const [countrydata, SetCountrydata] = useState([]);
  const [getcountry] = useState();
  const [selectedState] = useState();
  const [getState, setState] = useState([]);
  const [getcity, setGetcity] = useState([]);
  const [statetwo, setStatetwo] = useState(getcountry);
  const [city, setCity] = useState(selectedState);
  const [open, setOpen] = useState(getcountry);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedstateone, setSelectedstateone] = useState("");
  const [searchTermone, setSearchTermone] = useState("");

  const [selectedcity, setSelectedcity] = useState("");
  const [searchTermtwo, setSearchTermtwo] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle the country selection
  const handleCountryChange = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
  };

  const handleSearchChangestate = (event) => {
    setSearchTermone(event.target.value);
  };

  const handleSearchChangecity = (event) => {
    setSearchTermtwo(event.target.value);
  };

  // Function to handle the state selection
  const handleCountryChangestate = (event) => {
    const { value } = event.target;
    setSelectedstateone(value);
  };

  // Function to handle the city selection
  const handleCountryChangecity = (event) => {
    const { value } = event.target;
    setSelectedcity(value);
  };
  const [hidefield, setHidefield] = useState(1);
  const [hidestate, setHidestate] = useState(1);
  const [hidecity, setHidecity] = useState(1);

  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => SetCountrydata(res.data));
  }, []);

  const country = [...new Set(countrydata.map((item) => item.country))];

  country.sort();

  const handleCountry = (e) => {
    let states = countrydata.filter(
      (state) => state.country === e.target.value
    );
    states = [...new Set(states.map((item) => item.subcountry))];
    states.sort();
    setState(states);
  };

  const handleState = (e) => {
    let cities = countrydata.filter(
      (city) => city.subcountry === e.target.value
    );
    setGetcity(cities);
  };

  return (
    <div>
      <div className="d-flex bg-primary-one pt-2 pb-2 mb-2 ps-3 ">
        <div>
          <img className="w-imgone" src={images.WorldSearchtwo} alt="web" />
        </div>
        <div>
          <h6 className="titleText pt-1 ps-2 mb-0">
            Search Category, Area & Desired Location
          </h6>
        </div>
      </div>
      <div className=" ps-3 pe-3 pt-3 pb-1">
        <div>
          <Form.Label className="custm-lble">Search by Item Name</Form.Label>
          <Form.Control className="custom-fix-one" placeholder="Item Name" />
        </div>
        <div>
          <Form.Label className="custm-lble">Search by Category</Form.Label>
          <Form.Select className="custom-fix-one" placeholder="Sub-Category">
            <option> select Category</option>
          </Form.Select>
        </div>
        
        {/* <div>
        <Form.Label className="custm-lble">Search by sub Category</Form.Label>
        <Form.Control placeholder="Category" />
      </div> */}

        <div>
          <Form.Label className="custm-lble">Search by Sub-Category</Form.Label>
          <Form.Select className="custom-fix-one" placeholder="Sub-Category">
            <option> select Category</option>
          </Form.Select>
        </div>
        <div>
          <Form.Label className="custm-lbes pt-2">
            Search by Location
          </Form.Label>
        </div>

        <div className="col-md-12">
          <label
            htmlFor="inputPassword4"
            placeholder="First name"
            className="form-label labelSize"
          >
            Country*
          </label>
          <div className="blood-type custom-fix-one">
            <div className="d-flex">
              <div className="w-100">
                <input
                  type="search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search country here...."
                  className="w-100 search-input"
                  onClick={() => setHidefield(0)}
                />
              </div>
              <div>
                <span>
                  <SearchIcon className="search-icon-fs" />
                </span>
              </div>
            </div>

            <select
              onClick={() => setHidefield(1)}
              value={selectedCountry}
              onChange={(e) => {
                handleCountryChange(e);
                handleCountry(e);
              }}
              className="b-radius selectSize w-100"
              name="country"
            >
              {hidefield && <option>Select country</option>}

              {country
                .filter((country) =>
                  country.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map((country, index) => (
                  <option key={country} value={getcountry}>
                    {country}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="col-md-12">
          <label
            htmlFor="inputPassword4"
            placeholder="First name"
            className="form-label labelSize"
          >
            State*
          </label>
          <div className="blood-type custom-fix-one">
            <div className="d-flex">
              <div className="w-100">
                <input
                  type="search"
                  value={searchTermone}
                  onChange={handleSearchChangestate}
                  placeholder="Search state here...."
                  className="w-100 search-input"
                  onClick={() => setHidestate(0)}
                />
              </div>
              <div>
                <span>
                  <SearchIcon className="search-icon-fs" />
                </span>
              </div>
            </div>

            <select
              onClick={() => setHidestate(1)}
              className="b-radius selectSize w-100"
              value={selectedstateone}
              aria-label="Default select example "
              name="state"
              onChange={(e) => {
                handleState(e);
                handleCountryChangestate(e);
              }}
            >
              {hidestate && <option>Select state</option>}
              {/* <option>
                            {Open === undefined
                              ? "Select Country First"
                              : "select state"}
                          </option> */}

              {getState
                .filter((country) =>
                  country.toLowerCase().includes(searchTermone.toLowerCase())
                )
                .map((country) => (
                  <option key={country} value={selectedState}>
                    {country}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="col-md-12">
          <label
            htmlFor="inputPassword4"
            placeholder="First name"
            className="form-label labelSize"
          >
            City
          </label>
          <div className="blood-type custom-fix-one">
            <div className="d-flex">
              <div className="w-100">
                <input
                  type="search"
                  value={searchTermtwo}
                  onChange={handleSearchChangecity}
                  placeholder="Search city here...."
                  className="w-100 search-input"
                  onClick={() => setHidecity(0)}
                />
              </div>
              <div>
                <span>
                  <SearchIcon className="search-icon-fs" />
                </span>
              </div>
            </div>

            <select
              onClick={() => setHidecity(1)}
              value={selectedcity}
              className="b-radius selectSize w-100"
              aria-label="Default select example"
              name="city"
              onChange={(e) => {
                handleCountryChangecity(e);
              }}
            >
              {/* <option>
                              {city === undefined
                                ? "Select State First"
                                : "Select City"}
                            </option> */}

              {hidecity && <option>Select city</option>}

              {getcity
                .filter((country) =>
                  country.name
                    .toLowerCase()
                    .includes(searchTermtwo.toLowerCase())
                )
                .map((country) => (
                  <option key={country.name}>{country.name}</option>
                ))}
            </select>
          </div>
        </div>

        <div>
          <Form.Label className="custm-lbes pt-2 mb-0">Price Filter</Form.Label>
          <Row className="d-flex">
            <Col xl={3} className="d-flex">
              <Form.Label className="custm-lble pt-1 pe-1">Min</Form.Label>
              <div className="inputSetting">
                <input placeholder="" type="number" />
              </div>
            </Col>
            <Col xl={3} className="d-flex">
              <Form.Label className="custm-lble pt-1 pe-1">Max</Form.Label>
              <div className="inputSetting">
                <input placeholder="" type="number" />
              </div>
            </Col>
          </Row>
        </div>
        <div className=" pt-1 d-flex justify-content-center ">
          <button className="btn-lg bg-blue text-white">Search</button>
        </div>
      </div>
    </div>
  );
};

export default Singleitem;
