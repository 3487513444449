import { Col, Row } from "react-bootstrap";
import "./category.css";
import { subcategor } from "../../constants/jsonConstant";

const ShopCategory = () => {
  return (
    <>
      <div className="position_r">
      <div className="d-flex justify-content-end">
      <div className="sub_category col-9 mx-auto ">
          <Row>
            {subcategor.map((e) => (
              <Col lg="4">
                <ul className="font_small">
                  <li className="mt-3">{e.item}</li>
                  <li className="mt-3">{e.item1}</li>
                  <li className="mt-3">{e.item2}</li>
                  <li className="mt-3">{e.item3}</li>
                  <li className="mt-3">{e.item4}</li>
                  <li className="mt-3">{e.item5}</li>
                  <li className="mt-3">{e.item6}</li>
                 
                </ul>
              </Col>
            ))}
          </Row>
        </div>
      </div>
        
      </div>
    </>
  );
};

export default ShopCategory;
