import { images } from "../constants/index";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CallIcon from "@mui/icons-material/Call";
import StarIcon from "@mui/icons-material/Star";

// businessmen-cards

export const businessCards = [
  {
    id: 1,
    img: images.dashboard_1,
    title: "Job Wanted",
    link: "/create-job-app",
  },
  {
    id: 2,
    img: images.dashboard_2,
    title: "Create Jobs Offer",

    url: "/company-profile",
  },
  {
    id: 3,
    img: images.dashboard_3,
    title: "Sell Property",
    link: "/sell-property-cards",
  },
  {
    id: 4,
    img: images.Farming,
    title: "Create Farming",
    link: "/farming-form",
  },
  {
    id: 5,
    img: images.dashboard_5,
    title: "Sell New Item",
    link: "/new-items-form",
  },
  {
    id: 6,
    img: images.dashboard_6,
    title: "Gems and Minerals",
    link: "/mineral-mining-form",
  },
  {
    id: 7,
    img: images.dashboard_7,
    title: "Create Services",
    link: "/create-service",
    url: "/services-profile",
  },

  {
    id: 8,
    img: images.dashboard_8,
    title: "Sale Home Made Items",
    link: "/Home-Made-Form",
  },

  {
    id: 9,
    img: images.dashboard_9,
    title: "Second Hand Items",
    link: "/used-items-form",
  },
  {
    id: 10,
    img: images.dashboard_10,
    title: "Sell Recycle Material",
    link: "/recycle-shop",
  },
  {
    id: 11,
    img: images.dashboard_11,
    title: "Sell Scrap Materials",
    link: "/scrape-material",
  },
  {
    id: 12,
    img: images.dashboard_12,
    title: "Sell Used Vehicles",
    link: "/sell-vehicle",
  },
  {
    id: 13,
    img: images.dashboard_14,
    title: "Sell Single Items",
    link: "/create-transport",
  },
  {
    id: 14,
    img: images.dashboard_14,
    title: "Transportation Profile",
    link: "/create-transport",
  },
];

// buying-cards
export const BuyingCard = [
  {
    id: 1,
    img: images.dashboard_d_1,
    title: " Find Jobs Online",
    path: "/find-job",
  },
  {
    id: 2,
    img: images.dashboard_d_2,
    title: "Candidates Online",
    path: "/find-candidates",
  },
  {
    id: 3,
    img: images.dashboard_d_3,
    title: "Property Online",
    path: "/find-property",
  },
  {
    id: 4,
    img: images.dashboard_d_4,
    title: "Buy New Products",
    path: "/buy-new-products",
  },
  {
    id: 5,
    img: images.dashboard_d_5,
    title: "Gems And Minerals",
    path: "/buy-Minerals",
  },
  {
    id: 6,
    img: images.dashboard_d_6,
    title: "Farming Online",
    path: "/Farming-online",
  },
  {
    id: 7,
    img: images.dashboard_d_7,
    title: "Chat Room",
  },
  {
    id: 8,
    img: images.dashboard_d_8,
    title: "Services Online",
    path: "/find-services",
  },
  {
    id: 9,
    img: images.dashboard_d_9,
    title: "Select Ezi Helpline",
    path: "/selectezi-helpline",
  },
  {
    id: 10,
    img: images.dashboard_d_10,
    title: "Home Made Online",
    path: "/buy-homemade-products",
  },
  {
    id: 11,
    img: images.dashboard_d_11,
    title: "Buy Used Products",
    path: "/buy-used-products",
  },
  {
    id: 12,
    img: images.dashboard_d_12,
    title: "Scrap Materials",
    path: "/scrap-online",
  },
  {
    id: 13,
    img: images.dashboard_d_13,
    title: "Recycle Material",
    path: "/recycle-online",
  },
  {
    id: 14,
    img: images.dashboard_d_14,
    title: "Used Vehicle",
    path: "/buy-used-cars",
  },
  {
    id: 15,
    img: images.dashboard_d_16,
    title: "Single Items",
    // path: "/buy-used-products",
  },
  {
    id: 16,
    img: images.dashboard_d_16,
    title: "Transportation",
    path: "/find-Transport",
  },
];

export const ShopItemsData = [
  {
    id: 1,
    img: images.Items,
    Cookies: "Cookies",
    quantity: "Quantity (30)",
    Price: "PKR, 200",
    img1: images.StarImg,
    orders: "Orders (10)",
    View: "View",
    btn2: "Delete",
    path: "/shop-view-model",
  },
  {
    id: 2,
    img: images.Items,
    Cookies: "Cookies",
    quantity: "Quantity (30)",
    Price: "PKR, 200",
    img1: images.StarImg,
    orders: "Orders (10)",
    View: "View",
    btn2: "Delete",
    path: "/shop-view-model",
  },
  {
    id: 3,
    img: images.Items,
    Cookies: "Cookies",
    quantity: "Quantity (30)",
    Price: "PKR, 200",
    img1: images.StarImg,
    orders: "Orders (10)",
    View: "View",
    btn2: "Delete",
    path: "/shop-view-model",
  },
  {
    id: 4,
    img: images.Items,
    Cookies: "Cookies",
    quantity: "Quantity (30)",
    Price: "PKR, 200",
    img1: images.StarImg,
    orders: "Orders (10)",
    View: "View",
    btn2: "Delete",
    path: "/shop-view-model",
  },
  {
    id: 5,
    img: images.Items,
    Cookies: "Cookies",
    quantity: "Quantity (30)",
    Price: "PKR, 200",
    img1: images.StarImg,
    orders: "Orders (10)",
    View: "View",
    btn2: "Delete",
    path: "/shop-view-model",
  },
  {
    id: 6,
    img: images.Items,
    Cookies: "Cookies",
    quantity: "Quantity (30)",
    Price: "PKR, 200",
    img1: images.StarImg,
    orders: "Orders (10)",
    View: "View",
    btn2: "Delete",
    path: "/shop-view-model",
  },
  {
    id: 7,
    img: images.Items,
    Cookies: "Cookies",
    quantity: "Quantity (30)",
    Price: "PKR, 200",
    img1: images.StarImg,
    orders: "Orders (10)",
    View: "View",
    btn2: "Delete",
    path: "/shop-view-model",
  },
  {
    id: 8,
    img: images.Items,
    Cookies: "Cookies",
    quantity: "Quantity (30)",
    Price: "PKR, 200",
    img1: images.StarImg,
    orders: "Orders (10)",
    View: "View",
    btn2: "Delete",
    path: "/shop-view-model",
  },
  {
    id: 9,
    img: images.Items,
    Cookies: "Cookies",
    quantity: "Quantity (30)",
    Price: "PKR, 200",
    img1: images.StarImg,
    orders: "Orders (10)",
    View: "View",
    btn2: "Delete",
    path: "/shop-view-model",
  },
  {
    id: 10,
    img: images.Items,
    Cookies: "Cookies",
    quantity: "Quantity (30)",
    Price: "PKR, 200",
    img1: images.StarImg,
    orders: "Orders (10)",
    View: "View",
    btn2: "Delete",
    path: "/shop-view-model",
  },
];

export const ShopModelCarousal = [
  {
    id: 1,
    original: images.Image3,
    thumbnail: images.Image3,
  },
  {
    id: 2,
    original: images.Image1,
    thumbnail: images.Image1,
  },
  {
    id: 3,
    original: images.Image2,
    thumbnail: images.Image2,
  },
  {
    id: 4,
    original: images.Image1,
    thumbnail: images.Image1,
  },
  {
    id: 5,
    original: images.Image2,
    thumbnail: images.Image2,
  },
];

export const userShopITems = [
  {
    id: 1,
    img: images.ShopItem,
    title: "Cookies",
    Price: "PKR, 200",
    img1: images.Phone,
    img3: images.Mail,
    Viewbtn: "View",
    Message: "Message",
    Call: "Call",
    img4: images.StarsShop,
    stock: "InStock",
  },
  {
    id: 2,
    img: images.ShopItem,
    title: "Cookies",
    Price: "PKR, 200",
    img1: images.Phone,
    img3: images.Mail,
    Viewbtn: "View",
    Message: "Message",
    Call: "Call",
    img4: images.StarsShop,
    // outstock: "outofStock",
    stock: "InStock",
  },
  {
    id: 3,
    img: images.ShopItem,
    title: "Cookies",
    Price: "PKR, 200",
    img1: images.Phone,
    img3: images.Mail,
    Viewbtn: "View",
    Message: "Message",
    Call: "Call",
    img4: images.StarsShop,
    stock: "InStock",
  },
  {
    id: 4,
    img: images.ShopItem,
    title: "Cookies",
    Price: "PKR, 200",
    img1: images.Phone,
    img3: images.Mail,
    Viewbtn: "View",
    Message: "Message",
    Call: "Call",
    img4: images.StarsShop,
    // outstock: "outofStock",
    stock: "InStock",
  },
  {
    id: 5,
    img: images.ShopItem,
    title: "Cookies",
    Price: "PKR, 200",
    img1: images.Phone,
    img3: images.Mail,
    Viewbtn: "View",
    Message: "Message",
    Call: "Call",
    img4: images.StarsShop,
    stock: "Stock",
  },
  {
    id: 6,
    img: images.ShopItem,
    title: "Cookies",
    Price: "PKR, 200",
    img1: images.Phone,
    img3: images.Mail,
    Viewbtn: "View",
    Message: "Message",
    Call: "Call",
    img4: images.StarsShop,
    // outstock: "outofStock",
    stock: "InStock",
  },
  {
    id: 7,
    img: images.ShopItem,
    title: "Cookies",
    Price: "PKR, 200",
    img1: images.Phone,
    img3: images.Mail,
    Viewbtn: "View",
    Message: "Message",
    Call: "Call",
    img4: images.StarsShop,
    stock: "InStock",
  },
  {
    id: 8,
    img: images.ShopItem,
    title: "Cookies",
    Price: "PKR, 200",
    img1: images.Phone,
    img3: images.Mail,
    Viewbtn: "View",
    Message: "Message",
    Call: "Call",
    img4: images.StarsShop,
    // outstock: "outofStock",
    stock: "InStock",
  },
  {
    id: 9,
    img: images.ShopItem,
    title: "Cookies",
    Price: "PKR, 200",
    img1: images.Phone,
    img3: images.Mail,
    Viewbtn: "View",
    Message: "Message",
    Call: "Call",
    img4: images.StarsShop,
    stock: "InStock",
  },
  {
    id: 10,
    img: images.ShopItem,
    title: "Cookies",
    Price: "PKR, 200",
    img1: images.Phone,
    img3: images.Mail,
    Viewbtn: "View",
    Message: "Message",
    Call: "Call",
    img4: images.StarsShop,
    // outstock: "outofStock",
    stock: "InStock",
  },
];

// ConnectivityCards

export const ConnectivityCards = [
  {
    id: 1,
    title: "General Friends",
    subtitle: "0 General Friend",
  },
  {
    id: 2,
    title: "Connected Services",
    subtitle: "50 Connective Service",
  },
  {
    id: 3,
    title: "Favourite Recruiters",
  },
  {
    id: 4,
    title: "Interested Properties",
  },
  {
    id: 5,
    title: "Second Hand Items",
  },
  {
    id: 6,
    title: "Home Made Items",
  },

  {
    id: 7,
    title: "Home Made Items",
  },

  {
    id: 7,
    title: "Home Made Items",
  },
];

//businessconnectivity

export const rawmaterialcards = [
  {
    id: 1,
    title: "Manufacturers",
    subtitle: "105 Manufacturers",
  },
  {
    id: 2,
    title: "Service Profile",
    subtitle: "50 Service",
  },
  {
    id: 3,
    title: "Transportation",
    subtitle: "50 Transportation",
  },
  {
    id: 4,
    title: "Jop Wanted",
    subtitle: "50 Transportation",
  },
  {
    id: 5,
    title: "Properties Online",
    subtitle: "50 Properties",
  },

  {
    id: 6,
    title: "General Friends",
    subtitle: "50 Friends",
  },
];
export const manufacturecards = [
  {
    id: 1,
    title: "Raw material suppliers",
    subtitle: "105 Raw Material",
  },
  {
    id: 2,
    title: "Service Profile",
    subtitle: "50 Service",
  },
  {
    id: 3,
    title: "Wholeseller Profile ",
    subtitle: "50 Wholeseller ",
  },
  {
    id: 4,
    title: "Properties Online",
    subtitle: "50 Property",
  },
  {
    id: 5,
    title: "Jobs Wanted",
    subtitle: "50 Job ",
  },

  {
    id: 6,
    title: "Retailers Profile ",
    subtitle: "50 Retailers",
  },

  {
    id: 7,
    title: "General Friends",
    subtitle: "50 Friends ",
  },

  {
    id: 8,
    title: "Transport Profile",
    subtitle: "50 Transport ",
  },
];

export const wholesalercards = [
  {
    id: 1,
    title: "Finished Raw material",
    subtitle: "105 Finished Raw Material",
  },
  {
    id: 2,
    title: "Service Profile",
    subtitle: "50 Service",
  },
  {
    id: 3,
    title: "Transport Profile ",
    subtitle: "50 Transport ",
  },
  {
    id: 4,
    title: "Properties Online",
    subtitle: "50 Property",
  },
  {
    id: 5,
    title: "Jobs Wanted",
    subtitle: "50 Job ",
  },

  {
    id: 6,
    title: "Retailers Profile ",
    subtitle: "50 Retailers",
  },

  {
    id: 7,
    title: "General Friends",
    subtitle: "50 Friends ",
  },

  {
    id: 8,
    title: "Manufacturers",
    subtitle: "50 Manufactures ",
  },
];

export const retailercard = [
  {
    id: 1,
    title: "Whole Seller",
    subtitle: "105 Whole Seller",
  },
  {
    id: 2,
    title: "Service Profile",
    subtitle: "50 Service",
  },
  {
    id: 3,
    title: "Transport Profile ",
    subtitle: "50 Transport ",
  },
  {
    id: 4,
    title: "Properties Online",
    subtitle: "50 Property",
  },
  {
    id: 5,
    title: "Jobs Wanted",
    subtitle: "50 Job ",
  },

  {
    id: 6,
    title: "General Friends",
    subtitle: "50 Friends ",
  },

  {
    id: 7,
    title: "Manufacturers",
    subtitle: "50 Manufactures ",
  },
];

export const generalFriendsList = [
  {
    id: 1,
    generalImg: images.Profileimg,
    name: "Alex john",
  },
  {
    id: 2,
    generalImg: images.Profileimg,
    name: "Alex john",
  },
  {
    id: 3,
    generalImg: images.Profileimg,
    name: "Alex john",
  },
  {
    id: 4,
    generalImg: images.Profileimg,
    name: "Alex john",
  },
  {
    id: 5,
    generalImg: images.Profileimg,
    name: "Alex john",
  },
  {
    id: 6,
    generalImg: images.Profileimg,
    name: "Alex john",
  },
];

export const timelinemodal = [
  {
    id: 1,
    icon: <AddCircleOutlinedIcon />,
    text: "Add Status",
    color: "#59d28a",
    link: "",
  },

  {
    id: 2,
    icon: <VideoCallIcon />,
    text: "Live Video",
    color: "#3d3bee",
    link: "",
  },

  {
    id: 3,
    icon: <AddPhotoAlternateIcon />,
    text: "PhotoVideo",
    color: "#ffc01e",
    link: "",
  },

  {
    id: 4,
    icon: <FileUploadIcon />,
    text: "Upload Post",
    color: "#ef3900",
    link: "",
  },
];

////////main user profile
export const FriendsChat = [
  {
    id: 1,
    friendImg: images.Profileimg,
    friendName: "Alex John",
    friendDescription: "Lorem ipsum dolor sit amet,ta.....",
  },
  {
    id: 2,
    friendImg: images.Profileimg,
    friendName: "Alex John",
    friendDescription: "Lorem ipsum dolor sit amet,ta.....",
  },
  {
    id: 3,
    friendImg: images.Profileimg,
    friendName: "Alex John",
    friendDescription: "Lorem ipsum dolor sit amet,ta.....",
  },
  {
    id: 4,
    friendImg: images.Profileimg,
    friendName: "Alex John",
    friendDescription: "Lorem ipsum dolor sit amet,ta.....",
  },
  {
    id: 5,
    friendImg: images.Profileimg,
    friendName: "Alex John",
    friendDescription: "Lorem ipsum dolor sit amet,ta.....",
  },
  {
    id: 6,
    friendImg: images.Profileimg,
    friendName: "Alex John",
    friendDescription: "Lorem ipsum dolor sit amet,ta.....",
  },
  {
    id: 7,
    friendImg: images.Profileimg,
    friendName: "Alex John",
    friendDescription: "Lorem ipsum dolor sit amet,ta.....",
  },
  {
    id: 8,
    friendImg: images.Profileimg,
    friendName: "Alex John",
    friendDescription: "Lorem ipsum dolor sit amet,ta.....",
  },
  {
    id: 9,
    friendImg: images.Profileimg,
    friendName: "Alex John",
    friendDescription: "Lorem ipsum dolor sit amet,ta.....",
  },
  {
    id: 10,
    friendImg: images.Profileimg,
    friendName: "Alex John",
    friendDescription: "Lorem ipsum dolor sit amet,ta.....",
  },
];

//timeline submodal text
export const Submodal = [
  {
    id: 1,
    time: "16 mins ago",
    desc: "Lorem ipsum dolor sit amet,tagipsum dolor sit amet,ta.",
  },

  {
    id: 2,
    time: "16 mins ago",
    desc: "Lorem ipsum dolor sit amet,tagipsum dolor sit amet,ta.",
  },

  {
    id: 3,
    time: "16 mins ago",
    desc: "Lorem ipsum dolor sit amet,tagipsum dolor sit amet,ta.",
  },

  {
    id: 4,
    time: "16 mins ago",
    desc: "Lorem ipsum dolor sit amet,tagipsum dolor sit amet,ta.",
  },
];

// timelinepost
export const timelinePosts = [
  {
    id: 1,
    img: images.ProfileImg,
    name: "Kap Clothing",
    category: "Garment Retailer",
    title: "How to Manage Time for traveling",
    desc: " Lorem ipsum dolor sit amet, consectetur adi tempor...",
    postimg: images.p10,
  },

  {
    id: 2,
    img: images.ProfileImg,
    name: "Kap Clothing",
    category: "Garment Retailer",
    title: "How to Manage Time for traveling",
    desc: " Lorem ipsum dolor sit amet, consectetur adi tempor...",
    postimg: images.p12,
  },
];

//////connectedservices
export const FriendsProfiles = [
  {
    id: 1,
    img: images.ProfileImg,
    name: "Alex John",
  },
  {
    id: 2,
    img: images.ProfileImg,
    name: "Ammanda  ",
  },
  {
    id: 3,
    img: images.ProfileImg,
    name: "Rossie Feat",
  },
  {
    id: 4,
    img: images.ProfileImg,
    name: "John kel",
  },
  {
    id: 5,
    img: images.ProfileImg,
    name: "James Levin",
  },
  // 2 row
  {
    id: 6,
    img: images.ProfileImg,
    name: "Alex John",
  },
  {
    id: 7,
    img: images.ProfileImg,
    name: "Ammanda  ",
  },
  {
    id: 8,
    img: images.ProfileImg,
    name: "Rossie Feat",
  },
  {
    id: 9,
    img: images.ProfileImg,
    name: "John kel",
  },
  {
    id: 10,
    img: images.ProfileImg,
    name: "James Levin",
  },
  // 3row
  {
    id: 11,
    img: images.ProfileImg,
    name: "Alex John",
  },
  {
    id: 12,
    img: images.ProfileImg,
    name: "Ammanda  ",
  },
  {
    id: 13,
    img: images.ProfileImg,
    name: "Rossie Feat",
  },
  {
    id: 14,
    img: images.ProfileImg,
    name: "John kel",
  },
  {
    id: 15,
    img: images.ProfileImg,
    name: "James Levin",
  },
  // 4row
  {
    id: 16,
    img: images.ProfileImg,
    name: "Alex John",
  },
  {
    id: 17,
    img: images.ProfileImg,
    name: "Ammanda  ",
  },
  {
    id: 18,
    img: images.ProfileImg,
    name: "Rossie Feat",
  },
  {
    id: 19,
    img: images.ProfileImg,
    name: "John kel",
  },
  {
    id: 20,
    img: images.ProfileImg,
    name: "James Levin",
  },
  {
    id: 21,
    img: images.ProfileImg,
    name: "Ammanda  ",
  },
  {
    id: 22,
    img: images.ProfileImg,
    name: "Rossie Feat",
  },
  {
    id: 23,
    img: images.ProfileImg,
    name: "John kel",
  },
  {
    id: 24,
    img: images.ProfileImg,
    name: "James Levin",
  },
];

export const CandidatesBoxes = [
  {
    id: 1,
    img: images.ProfileImg,
    name: "Alex john",
    occupation: "Graphic designer",
  },
  {
    id: 2,
    img: images.ProfileImg,
    name: "Alex john",
    occupation: "Graphic designer",
  },
  {
    id: 3,
    img: images.ProfileImg,
    name: "Alex john",
    occupation: "Graphic designer",
  },
  {
    id: 4,
    img: images.ProfileImg,
    name: "Alex john",
    occupation: "Graphic designer",
  },
  {
    id: 5,
    img: images.ProfileImg,
    name: "Alex john",
    occupation: "Graphic designer",
  },
  {
    id: 6,
    img: images.ProfileImg,
    name: "Alex john",
    occupation: "Graphic designer",
  },
  {
    id: 7,
    img: images.ProfileImg,
    name: "Alex john",
    occupation: "Graphic designer",
  },
  {
    id: 8,
    img: images.ProfileImg,
    name: "Alex john",
    occupation: "Graphic designer",
  },
  {
    id: 9,
    img: images.ProfileImg,
    name: "Alex john",
    occupation: "Graphic designer",
  },
  {
    id: 10,
    img: images.ProfileImg,
    name: "Alex john",
    occupation: "Graphic designer",
  },
  {
    id: 11,
    img: images.ProfileImg,
    name: "Alex john",
    occupation: "Graphic designer",
  },
  {
    id: 12,
    img: images.ProfileImg,
    name: "Alex john",
    occupation: "Graphic designer",
  },
  {
    id: 13,
    img: images.ProfileImg,
    name: "Alex john",
    occupation: "Graphic designer",
  },
  {
    id: 14,
    img: images.ProfileImg,
    name: "Alex john",
    occupation: "Graphic designer",
  },
  {
    id: 15,
    img: images.ProfileImg,
    name: "Alex john",
    occupation: "Graphic designer",
  },
  {
    id: 16,
    img: images.ProfileImg,
    name: "Alex john",
    occupation: "Graphic designer",
  },
  {
    id: 17,
    img: images.ProfileImg,
    name: "Alex john",
    occupation: "Graphic designer",
  },
  {
    id: 18,
    img: images.ProfileImg,
    name: "Alex john",
    occupation: "Graphic designer",
  },
];
export const JobsList = [
  {
    id: 1,
    designation: "Front-End Developer",
    posted: "21-10-2021",
    lastdate: "22-10-2021",
  },
  {
    id: 2,
    designation: "Back-End Developer",
    posted: "21-10-2021",
    lastdate: "22-10-2021",
  },
  {
    id: 3,
    designation: "Designer",
    posted: "21-10-2021",
    lastdate: "22-10-2021",
  },
  {
    id: 4,
    designation: "SEO",
    posted: "21-10-2021",
    lastdate: "22-10-2021",
  },
  {
    id: 5,
    designation: "Front-End Developer",
    posted: "21-10-2021",
    lastdate: "22-10-2021",
  },
];
//multipleshops
export const multipleshops = [
  {
    id: 1,
    shopname: "Shop Name",
    location: "Mandian/Abbottabad",
  },

  {
    id: 2,
    shopname: "Shop Name",
    location: "Mandian/Abbottabad",
  },

  {
    id: 3,
    shopname: "Shop Name",
    location: "Mandian/Abbottabad",
  },

  {
    id: 4,
    shopname: "Shop Name",
    location: "Mandian/Abbottabad",
  },

  {
    id: 5,
    shopname: "Shop Name",
    location: "Mandian/Abbottabad",
  },

  {
    id: 6,
    shopname: "Shop Name",
    location: "Mandian/Abbottabad",
  },

  {
    id: 7,
    shopname: "Shop Name",
    location: "Mandian/Abbottabad",
  },

  {
    id: 8,
    shopname: "Shop Name",
    location: "Mandian/Abbottabad",
  },

  {
    id: 9,
    shopname: "Shop Name",
    location: "Mandian/Abbottabad",
  },

  {
    id: 10,
    shopname: "Shop Name",
    location: "Mandian/Abbottabad",
  },

  {
    id: 11,
    shopname: "Shop Name",
    location: "Mandian/Abbottabad",
  },

  {
    id: 12,
    shopname: "Shop Name",
    location: "Mandian/Abbottabad",
  },
];

export const OrderHistoryRecord = [
  {
    id: 1,
    trackingId: "123456789987654222",
    Name: "jamal akhter",
    Date: "14 / APR / 2020",
    Status: "ongoing",
    Show: images.Eye,
  },
  {
    id: 2,
    trackingId: "123456789987654222",
    Name: "jamal akhter",
    Date: "14 / APR / 2020",
    Status: "delevired",
    Show: images.Eye,
  },
  {
    id: 3,
    trackingId: "123456789987654222",
    Name: "jamal akhter",
    Date: "14 / APR / 2020",
    Status: "ongoing",
    Show: images.Eye,
  },
  {
    id: 4,
    trackingId: "123456789987654222",
    Name: "jamal akhter",
    Date: "14 / APR / 2020",
    Status: "Pending",
    Show: images.Eye,
  },
  {
    id: 5,
    trackingId: "123456789987654222",
    Name: "jamal akhter",
    Date: "14 / APR / 2020",
    Status: "delevired",
    Show: images.Eye,
  },
  {
    id: 6,
    trackingId: "123456789987654222",
    Name: "jamal akhter",
    Date: "14 / APR / 2020",
    Status: "ongoing",
    Show: images.Eye,
  },
  {
    id: 7,
    trackingId: "123456789987654222",
    Name: "jamal akhter",
    Date: "14 / APR / 2020",
    Status: "delevired",
    Show: images.Eye,
  },
  {
    id: 8,
    trackingId: "123456789987654222",
    Name: "jamal akhter",
    Date: "14 / APR / 2020",
    Status: "Pending",
    Show: images.Eye,
  },
  {
    id: 9,
    trackingId: "123456789987654222",
    Name: "jamal akhter",
    Date: "14 / APR / 2020",
    Status: "ongoing",
    Show: images.Eye,
  },
  {
    id: 10,
    trackingId: "123456789987654222",
    Name: "jamal akhter",
    Date: "14 / APR / 2020",
    Status: "Pending",
    Show: images.Eye,
  },
  {
    id: 11,
    trackingId: "123456789987654222",
    Name: "jamal akhter",
    Date: "14 / APR / 2020",
    Status: "delevired",
    Show: images.Eye,
  },
  {
    id: 12,
    trackingId: "123456789987654222",
    Name: "jamal akhter",
    Date: "14 / APR / 2020",
    Status: "ongoing",
    Show: images.Eye,
  },
  {
    id: 13,
    trackingId: "123456789987654222",
    Name: "jamal akhter",
    Date: "14 / APR / 2020",
    Status: "Pending",
    Show: images.Eye,
  },
  {
    id: 14,
    trackingId: "123456789987654222",
    Name: "jamal akhter",
    Date: "14 / APR / 2020",
    Status: "delevired",
    Show: images.Eye,
  },
];

export const PropertyItems = [
  {
    id: 1,
    Name: "Cookies",
    Pkr: "PKR 200",
    Rating: images.StarImg,
    ViewButton: "View",
    DeleteButton: "Delete",
    Propertimg: images.Property,
  },
  {
    id: 2,
    Name: "Cookies",
    Pkr: "PKR 200",
    Rating: images.StarImg,
    ViewButton: "View",
    DeleteButton: "Delete",
    Propertimg: images.Property,
  },
  {
    id: 3,
    Name: "Cookies",
    Pkr: "PKR 200",
    Rating: images.StarImg,
    ViewButton: "View",
    DeleteButton: "Delete",
    Propertimg: images.Property,
  },
  {
    id: 4,
    Name: "Cookies",
    Pkr: "PKR 200",
    Rating: images.StarImg,
    ViewButton: "View",
    DeleteButton: "Delete",
    Propertimg: images.Property,
  },
  {
    id: 5,
    Name: "Cookies",
    Pkr: "PKR 200",
    Rating: images.StarImg,
    ViewButton: "View",
    DeleteButton: "Delete",
    Propertimg: images.Property,
  },
  {
    id: 6,
    Name: "Cookies",
    Pkr: "PKR 200",
    Rating: images.StarImg,
    ViewButton: "View",
    DeleteButton: "Delete",
    Propertimg: images.Property,
  },
  {
    id: 7,
    Name: "Cookies",
    Pkr: "PKR 200",
    Rating: images.StarImg,
    ViewButton: "View",
    DeleteButton: "Delete",
    Propertimg: images.Property,
  },
  {
    id: 8,
    Name: "Cookies",
    Pkr: "PKR 200",
    Rating: images.StarImg,
    ViewButton: "View",
    DeleteButton: "Delete",
    Propertimg: images.Property,
  },
  {
    id: 9,
    Name: "Cookies",
    Pkr: "PKR 200",
    Rating: images.StarImg,
    ViewButton: "View",
    DeleteButton: "Delete",
    Propertimg: images.Property,
  },
  {
    id: 10,
    Name: "Cookies",
    Pkr: "PKR 200",
    Rating: images.StarImg,
    ViewButton: "View",
    DeleteButton: "Delete",
    Propertimg: images.Property,
  },
  {
    id: 11,
    Name: "Cookies",
    Pkr: "PKR 200",
    Rating: images.StarImg,
    ViewButton: "View",
    DeleteButton: "Delete",
    Propertimg: images.Property,
  },
  {
    id: 12,
    Name: "Cookies",
    Pkr: "PKR 200",
    Rating: images.StarImg,
    ViewButton: "View",
    DeleteButton: "Delete",
    Propertimg: images.Property,
  },
  {
    id: 13,
    Name: "Cookies",
    Pkr: "PKR 200",
    Rating: images.StarImg,
    ViewButton: "View",
    DeleteButton: "Delete",
    Propertimg: images.Property,
  },
  {
    id: 14,
    Name: "Cookies",
    Pkr: "PKR 200",
    Rating: images.StarImg,
    ViewButton: "View",
    DeleteButton: "Delete",
    Propertimg: images.Property,
  },
  {
    id: 15,
    Name: "Cookies",
    Pkr: "PKR 200",
    Rating: images.StarImg,
    ViewButton: "View",
    DeleteButton: "Delete",
    Propertimg: images.Property,
  },
  {
    id: 16,
    Name: "Cookies",
    Pkr: "PKR 200",
    Rating: images.StarImg,
    ViewButton: "View",
    DeleteButton: "Delete",
    Propertimg: images.Property,
  },
  {
    id: 17,
    Name: "Cookies",
    Pkr: "PKR 200",
    Rating: images.StarImg,
    ViewButton: "View",
    DeleteButton: "Delete",
    Propertimg: images.Property,
  },
  {
    id: 18,
    Name: "Cookies",
    Pkr: "PKR 200",
    Rating: images.StarImg,
    ViewButton: "View",
    DeleteButton: "Delete",
    Propertimg: images.Property,
  },
  {
    id: 19,
    Name: "Cookies",
    Pkr: "PKR 200",
    Rating: images.StarImg,
    ViewButton: "View",
    DeleteButton: "Delete",
    Propertimg: images.Property,
  },
  {
    id: 20,
    Name: "Cookies",
    Pkr: "PKR 200",
    Rating: images.StarImg,
    ViewButton: "View",
    DeleteButton: "Delete",
    Propertimg: images.Property,
  },
];

//usersidetransportbtn
export const usertransportbtn = [
  {
    id: 1,
    icon: <CallIcon />,
    title: "Call",
    color: "#3D3BEE",
  },

  {
    id: 2,
    icon: <ChatBubbleIcon />,
    title: "Message",
    color: "#3D3BEE",
  },
  {
    id: 3,
    icon: <StarIcon />,
    title: "RateMe",
    color: "#E9A71D",
  },
  // {
  //   id: 4,
  //   // icon: <ChatBubbleIcon />,
  //   title: "Connects",
  //   color: "#0d6efd",
  // },
];
// transport btn
export const transportbtn = [
  {
    id: 1,
    icon: <DeleteIcon />,
    title: "Delete",
    color: "#D62828",
  },
  {
    id: 2,
    icon: <SyncAltIcon />,
    title: "Update",
    color: "#E9A71D",
  },
  {
    id: 3,
    icon: <ChatBubbleIcon />,
    title: "Message",
    color: "#3D3BEE",
  },
  {
    id: 4,
    icon: <AddCircleIcon />,
    title: "Post Vehical",
    color: "#3D3BEE",
  },
];
// Tansport profile detail show
export const Transportdetail = [
  {
    id: 1,
    img: images.transport,
    carname: "Car Name",
    title: "Pick and Drop",
    description:
      "Lorem ipsum dolor sit amet, consectetur adi tempor incidila bore et dolore magna quis nostrud exercitation ullamco laboris.Lorem ipsum dolor sit amet,",
  },

  {
    id: 2,
    img: images.transport,
    carname: "Car Name",
    title: "Pick and Drop",
    description:
      "Lorem ipsum dolor sit amet, consectetur adi tempor incidila bore et dolore magna quis nostrud exercitation ullamco laboris.Lorem ipsum dolor sit amet,",
  },

  {
    id: 3,
    img: images.transport,
    carname: "Car Name",
    title: "Pick and Drop",
    description:
      "Lorem ipsum dolor sit amet, consectetur adi tempor incidila bore et dolore magna quis nostrud exercitation ullamco laboris.Lorem ipsum dolor sit amet,",
  },
];

export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const portfolioImages = [
  {
    id: 1,
    img: images.Car1,
  },
  {
    id: 2,
    img: images.Car2,
  },
  {
    id: 3,
    img: images.Car3,
  },
  {
    id: 4,
    img: images.Car4,
  },
  {
    id: 5,
    img: images.Car5,
  },
  {
    id: 6,
    img: images.Car6,
  },
  {
    id: 7,
    img: images.Car,
  },
];

//candidates for job
export const candidatesapi = [
  { id: 1, img: images.ProfileImg, name: "Alex John" },
  { id: 2, img: images.ProfileImg, name: "Alex John" },
  { id: 3, img: images.ProfileImg, name: "Alex John" },
  { id: 4, img: images.ProfileImg, name: "Alex John" },
  { id: 5, img: images.ProfileImg, name: "Alex John" },
  { id: 6, img: images.ProfileImg, name: "Alex John" },
  { id: 7, img: images.ProfileImg, name: "Alex John" },
  { id: 8, img: images.ProfileImg, name: "Alex John" },
  { id: 9, img: images.ProfileImg, name: "Alex John" },
  { id: 10, img: images.ProfileImg, name: "Alex John" },
  { id: 11, img: images.ProfileImg, name: "Alex John" },
  { id: 12, img: images.ProfileImg, name: "Alex John" },
  { id: 13, img: images.ProfileImg, name: "Alex John" },
  { id: 14, img: images.ProfileImg, name: "Alex John" },
  { id: 15, img: images.ProfileImg, name: "Alex John" },
  { id: 16, img: images.ProfileImg, name: "Alex John" },
  { id: 17, img: images.ProfileImg, name: "Alex John" },
  { id: 18, img: images.ProfileImg, name: "Alex John" },
  { id: 19, img: images.ProfileImg, name: "Alex John" },
  { id: 20, img: images.ProfileImg, name: "Alex John" },
];

// ROutes
export const Navbarapi = [
  // <--Profile routes -->

  {
    name: "Profile",
    route: "/user-profile",
  },

  {
    name: "Profile",
    route: "/view-friends",
  },

  {
    name: "Job Wanted",
    route: "/create-job-app",
  },

  {
    name: "Job Wanted",
    route: "/view-application",
  },

  {
    name: "Job Wanted",
    route: "/update-job-app",
  },

  {
    name: "Create Jobs Offer",
    route: "/company-form",
  },

  {
    name: "New Items Shop",
    route: "/new-items-form",
  },

  {
    name: "Create Transport",
    route: "/create-transport",
  },
  {
    name: "Transport Profile",
    route: "/transport-profile",
  },

  {
    name: "Find Job",
    route: "/find-job",
  },

  {
    name: "Find Job",
    route: "/find-jobs-online",
  },

  {
    name: "Find Job",
    route: "/view-job-details",
  },

  {
    name: "Buy Property",
    route: "/find-property",
  },

  {
    name: "Buy Property",
    route: "/user-peronal-property",
  },

  {
    name: "Find Candidates",
    route: "/find-candidates",
  },

  {
    name: "Buy Minerals",
    route: "/buy-Minerals",
  },

  {
    name: "Buy Minerals",
    route: "/Minerl-And-Mining-UserProfile",
  },

  {
    name: "New Product",
    route: "/buy-new-productse",
  },

  {
    name: "New Product",
    route: "/NewItems-UserProfile",
  },

  {
    name: "Find Transport",
    route: "/find-Transport",
  },

  {
    name: "HomeMade",
    route: "/buy-homemade-products",
  },

  {
    name: "HomeMade",
    route: "/Home-Made-Item-Userprofile",
  },

  {
    name: "HelpLine",
    route: "/selectezi-helpline",
  },

  {
    name: "Used Product",
    route: "/buy-used-products",
  },

  {
    name: "Used Product",
    route: "/UsedItems-UserProfile",
  },

  {
    name: "New Items",
    route: "/NewItem-bussines-Profile",
  },

  {
    name: "Used Items",
    route: "/Used-Items-Bussines-Profile",
  },

  {
    name: "Create Service",
    route: "/create-service",
  },

  {
    name: "Service Profile",
    route: "/services-profile",
  },

  {
    name: "Update Form",
    route: "/update-service",
  },

  {
    name: "Create Personal Property",
    route: "/create-personal-property",
  },

  {
    name: "Personal Property",
    route: "/SalePerosonalProperty",
  },

  {
    name: "Create Business Property",
    route: "/business-property-form",
  },

  {
    name: "Business Property",
    route: "/SaleBussinesProfile",
  },

  {
    name: "Create Home Made Items",
    route: "/Home-Made-Form",
  },

  {
    name: "HomeMade Profile",
    route: "/homemadeownerprofile",
  },

  {
    name: "Minerals and Mining",
    route: "/mineral-mining-form",
  },

  {
    name: "Mineral And Mining",
    route: "/MineralAndMining",
  },

  {
    name: "Used Items Shop",
    route: "/used-items-form",
  },

  {
    name: "New Product",
    route: "/buy-new-products",
  },

  {
    name: "Raw Material",
    route: "/raw-material",
  },

  {
    name: "Used Items Card",
    route: "/used-items-cards",
  },

  {
    name: "Post Single Item",
    route: "/post-items",
  },

  {
    name: "Raw Material",
    route: "/rawmaterial-newitem",
  },

  {
    name: "New Items",
    route: "/newitems-Postadd",
  },

  {
    name: "Used Items",
    route: "/useditems-postAdd",
  },

  {
    name: "Update Job",
    route: "/update-job",
  },

  {
    name: "Find Services",
    route: "/find-services",
  },

  {
    name: "Find Services",
    route: "/services-online",
  },
  {
    name: "Business Dashboard",
    route: "/business-dashboard",
  },

  {
    name: "Business Dashboard",
    route: "/manage-raw-shop",
  },

  {
    name: "Business Dashboard",
    route: "/manage-finish-shop",
  },

  {
    name: "Business Dashboard",
    route: "/manage-manu-shop",
  },

  {
    name: "Business Dashboard",
    route: "/manage-whole-shop",
  },

  {
    name: "Business Dashboard",
    route: "/manage-retailer-shop",
  },

  {
    name: "Retailer",
    route: "/retailer-form",
  },
  {
    name: "Wholeseller",
    route: "/wholeseller-form",
  },
  {
    name: "Manufacturer",
    route: "/manufacturer-form",
  },
  {
    name: "Raw Material",
    route: "/raw-material-form",
  },
  {
    name: "CV-Form",
    route: "/CV-Form",
  },
  {
    name: "Select-Template",
    route: "/select-template",
  },
  {
    name: "View-Application",
    route: "/view-application/6482c8f361b40c9b071a5e02",
  },
  {
    name: "Update-Job",
    route: "/update-job-app/6482c8f361b40c9b071a5e02",
  },
  {
    name: "Post-Single-ad",
    route: "/post-single-ad",
  },
  {
    name: "Sale-Bussines-Property",
    route: "/Sale-Bussines-Property/6482ca651ea3f75573a3085c",
  },
  {
    name: "ShopManage",
    route: "/ShopManage",
  },
  {
    name: "Create-Business-Ad",
    route: "/create-business-Ad/6482ca651ea3f75573a3085c",
  },
  {
    name: "Business-property-owner-view",
    route:
      "/business-property-owner-view/6482ca651ea3f75573a3085c/6482cbac61b40c9b071a5e28",
  },
  {
    name: "/Update-Business-Ad",
    route: "/update-business-Ad/6482cbac61b40c9b071a5e28",
  },
  {
    name: "Single Property",
    route: "/add-personal-property",
  },
  {
    name: "Personal-add",
    route: "/personal-add/6482cf821ea3f75573a30888",
  },
  {
    name: "Personal-add",
    route: "/personal-add/6482cfe61ea3f75573a30892",
  },
  {
    name: "Used-Vehicle",
    route: "/used-vehicle",
  },
  {
    name: "Used-vehicle-businessprofile",
    route: "/used-vehicle-businessprofile/6482d0aa1ea3f75573a3089f",
  },
  {
    name: "Used-Vehicle-profile",
    route: "/used-vehicle-profile/6482d0aa1ea3f75573a3089f",
  },
  {
    name: "Usedvehicle_add",
    route: "/usedvehicle_add/6482d0aa1ea3f75573a3089f",
  },
  {
    name: "Single Vehicle",
    route: "/sell-single",
  },
  {
    name: "Show-Single-add",
    route: "/show-single-add",
  },
  {
    name: "Used-vehicle-addview",
    route: "/used-vehicle-addview/63f3202dd4a6925b9c3e2f15",
  },
  {
    name: "Update-Use-vehicle",
    route: "/update-use-vehicle/63f3202dd4a6925b9c3e2f15",
  },
  {
    name: "Create Recycle Material",
    route: "/recycle-shop",
  },
  {
    name: "Create Farming Shop",
    route: "/farming-form",
  },
  {
    name: "Scrape-material",
    route: "/scrape-material",
  },
  {
    name: "Personal-add",
    route: "/personal-add/6482edb3ebc8aa3b2bd9cd34",
  },
  {
    name: "Personal-add",
    route: "/personal-add/6482ee11ebc8aa3b2bd9cd3a",
  },
  {
    name: "Emails",
    route: "/inbox",
  },
  {
    name: "Sent-emails",
    route: "/sent-mail",
  },
  {
    name: "Draft",
    route: "/email-draft",
  },
  {
    name: "Outbox",
    route: "/email-outbox",
  },
  {
    name: "Spam",
    route: "/email-spam",
  },
  {
    name: "Mails",
    route: "/home-email",
  },
  {
    name: "Important-mail",
    route: "/important-mail",
  },
  {
    name: "Trash",
    route: "/home-page-trash",
  },
  {
    name: "All-Inquiry-buying",
    route: "/all-inquiry-buying",
  },
  {
    name: "All-Quotation-buying",
    route: "/quotation-buying",
  },
  {
    name: "All-purchase-order-buying",
    route: "/purchase-order-buying",
  },
  {
    name: "All-invoice-buying",
    route: "/invoice-buying",
  },
  {
    name: "Trash-buying",
    route: "/trash-buying",
  },
  {
    name: "All-inquiry-selling",
    route: "/inquiry-selling",
  },
  {
    name: "All-Quotation-selling",
    route: "/quotation-selling",
  },
  {
    name: "All-purchase-order-selling",
    route: "/purchase-order-selling",
  },
  {
    name: "Trash-selling",
    route: "/trash-selling",
  },
  {
    name: "Meeting",
    route: "/GoogleMeet",
  },
  {
    name: "Compose-mail",
    route: "/compose-mail",
  },
  {
    name: "Chat-Room",
    route: "/chat-room-modal",
  },
  {
    name: "Own-Chatroom",
    route: "/own-chatroom",
  },
  {
    name: "My-chart",
    route: "/my-chart",
  },
  {
    name: "Joinown-chatroom",
    route: "/joinown-chatroom",
  },
  {
    name: "Chatroom-streamline",
    route: "/chatroom-streamline",
  },
  {
    name: "Helpline",
    route: "/selectezi-Helpline",
  },
  {
    name: "Newitems-Postadd",
    route: "/newitems-Postadd/63da1208da952fd412353ce3",
  },
  {
    name: "Buy-retailer",
    route: "/buy-retailer",
  },
  {
    name: "Retailer-userprofile",
    route: "/retailer-userprofile",
  },
  {
    name: "Buy-wholeseller",
    route: "/buy-wholeseller",
  },
  {
    name: "Wholeseller-userprofile",
    route: "/wholeseller-userprofile",
  },
  {
    name: "Buy-manufacturer",
    route: "/buy-manufacturer",
  },
  {
    name: "Manufacturer-userprofile",
    route: "/manufacturer-userprofile",
  },
  {
    name: "Raw-material",
    route: "/buy-raw-material",
  },
  {
    name: "Rawmaterial-userprofile",
    route: "/rawmaterial-userprofile",
  },
  {
    name: "Singleitemmerge",
    route: "/Singleitemmerge",
  },
  {
    name: "Buy-used-cars",
    route: "/buy-used-cars",
  },
  {
    name: "Recycle-online",
    route: "/recycle-online",
  },
  {
    name: "Scrap-online",
    route: "/scrap-online",
  },
  {
    name: "Farming-online",
    route: "/Farming-online",
  },
  {
    name: "Buying Side taiba",
    route: "/BuyingSide",
  },

  {
    name: "Selling Side",
    route: "/SellingSide",
  },
  {
    name: "Profile-setting",
    route: "/profile-setting",
  },

  {
    name: "New-Items-Shop",
    route: "/new-items-shops",
  },
  {
    name: "Homemade-Items-Shop",
    route: "/homemade-items-shops",
  },

  {
    name: "Gems&Jewelery-Shop",
    route: "/minerals-mining-shops",
  },

  {
    name: "Business-Property",
    route: "/business-properties",
  },

  {
    name: "Create-Jobs",
    route: "/job-companies",
  },

  {
    name: "Used-Items-Shop",
    route: "/used-items-shops",
  },

  {
    name: "Farming-Shop",
    route: "/farming-shops",
  },

  {
    name: "Recycle-Shop",
    route: "/recycle-shops",
  },

  {
    name: "Shop-Settings",
    route: "/shop-setting",
  },

  {
    name: "Use Vehicle",
    route: "/usedvehicle-shops",
  },

  {
    name: "Food & Restaurants",
    route: "/food-business-dashboard",
  },
];

export const addProfessionalSkills = [
  {
    id: 1,
    skill1: "Skill#1",
    skill2: "Skill#2",
    skill3: "Skill#3",
    skill4: "Skill#4",
    skill5: "Skill#5",
    skill6: "Skill#6",
  },
];
export const PreviousEmployement = [
  {
    id: 1,
    companyname: "Company Name",
    designation: "Designation",
    industry: "Industry",
    startdate: "Start Date",
    enddate: "End Date",
  },
];

export const Businesstext = [
  { id: 1, text: "Raw Material", link: "/manage-raw-shop" },
  { id: 2, text: "Finished Raw Material", link: "/manage-finish-shop" },
  { id: 3, text: "Manufacturer", link: "/manage-manu-shop" },
  { id: 4, text: "WholeSeller", link: "/manage-whole-shop" },
  { id: 5, text: "Retailer", link: "/manage-retailer-shop" },
];

export const BusinessCategory = [
  { id: 1, text: "Residence" },
  { id: 2, text: "Commercial" },
  { id: 3, text: "Plots" },
];

export const Menu = [
  {
    id: 2,
    icon: images.Menusix,
    text: "Homemade Shop",
    to: "/homemade-items-shops",
  },
  {
    id: 3,
    icon: images.Menufour,
    text: " Gems and Jewelery",
    to: "/minerals-mining-shops",
  },
  { id: 4, icon: images.Menutwo, text: "Services", to: "/services-shops" },
  {
    id: 5,
    icon: images.Menuseven,
    text: "Business Property",
    to: "/business-properties",
  },
  {
    id: 6,
    icon: images.Menuthree,
    text: "Create Company",
    to: "/job-companies",
  },
  {
    id: 7,
    icon: images.Menufive,
    text: " Used Items Shop",
    to: "/used-items-shops",
  },
  {
    id: 8,
    icon: images.Menueight,
    text: " Raw Material",
    to: "/RawmaterialShop",
  },
  {
    id: 9,
    icon: images.Menunine,
    text: " Manufacturer",
    to: "/ManufacturerShop",
  },
  {
    id: 10,
    icon: images.Menuten,
    text: " Whole Seller",
    to: "/WholesalerShop",
  },
  { id: 11, icon: images.Menueleven, text: " Retailer", to: "/RetailerShop" },
  {
    id: 12,
    icon: images.Menutwelve,
    text: " Recycle Items Shop",
    to: "/recycle-shops",
  },
  {
    id: 13,
    icon: images.Menuthirteen,
    text: " Farming Items Shop",
    to: "/farming-shops",
  },
  {
    id: 14,
    icon: images.Menufourteen,
    text: " Used Vehicle Shop",
    to: "/usedvehicle-shops",
  },

  {
    id: 15,
    icon: images.Menufifteen,
    text: " Food And Restaurent",
    to: "/food-business-dashboard",
  },


  {
    id: 16,
    icon: images.Menusixteen,
    text: " Transport Profile",
    to: "/transport-business-dashboard",
  },
];

export const FormalEducation = [
  {
    id: 1,
    institutename: "Institute Name",
    Startdate: "Start Date",
    enddate: "End Date",
    major: "Major",
    qualification: "Qualification",
    typeofstudy: "Type Of Study",
    degreestatus: "Degree Status",
  },
];

export const languageSkills = [
  {
    id: 1,
    language: "Language",
    speakingproficiency: "Speaking Proficiency",
    readingproficiency: "Reading Proficiency",
    writingproficiency: "Writing Proficiency",
  },
];

export const certifications = [
  {
    id: 1,
    nameofcertifications: "Name of Certifications",
    institution: "Institution",
    Issuingauthority: "Issuing Authority",
    effectivedate: "Efective Date",
    expirationdate: "Effective Date",
  },
];

export const Category = [
  {
    id: 1,
    title: "I.T services",
  },
  {
    id: 2,
    title: "Training services",
  },
  {
    id: 3,
    title: "Consulting services",
  },
  {
    id: 4,
    title: "Marketing services",
  },
  {
    id: 5,
    title: "Construction services",
  },
  {
    id: 6,
    title: "Waste management services",
  },
  {
    id: 7,
    title: "Legal services",
  },
  {
    id: 8,
    title: "Health and wellness services",
  },
  {
    id: 9,
    title: "Security services",
  },
  {
    id: 10,
    title: "Travel services",
  },
  {
    id: 11,
    title: "Research services",
  },
  {
    id: 12,
    title: "Finance services",
  },
  {
    id: 13,
    title: "Delivery services",
  },
  {
    id: 14,
    title: "Child care services",
  },

  {
    id: 15,
    title: "Personal services",
  },
];

export const transportcategory = [
  {
    id: 1,
    title: "Buses",
  },
  {
    id: 2,
    title: "Taxi Service",
  },
  {
    id: 3,
    title: "Personal Vehicles",
  },
  {
    id: 4,
    title: "Pedestrian Transportatio",
  },
  {
    id: 5,
    title: "Rent Cars services",
  },
  {
    id: 6,
    title: "Trucking services",
  },
];
export const Categories = [
  {
    id: 1,
    title: "Mens Clothing",
    icon: images.Category1,
  },
  {
    id: 2,
    title: "Mens Clothing",
    icon: images.Category2,
  },

  {
    id: 3,
    title: "Womens Clothing",
    icon: images.Category3,
  },

  {
    id: 4,
    title: "Summer Clothing",
    icon: images.Category4,
  },

  {
    id: 5,
    title: "Winter Clothing",
    icon: images.Category5,
  },

  {
    id: 6,
    title: "Kids Clothing",
    icon: images.Category6,
  },

  {
    id: 7,
    title: "Bridel Dress",
    icon: images.Category7,
  },
];

export const subcategor = [
  {
    id: 1,
    item: "Shirts",
    item1: "Pent",
    item2: "Coat",
    item3: "Socks",
    item4: "Shoes",
    item5: "Socks",
    item6: "Dress Coat",
  },
  {
    id: 2,
    item: "Shirts",
    item1: "Pent",
    item2: "Coat",
    item3: "Socks",
    item4: "Shoes",
    item5: "Socks",
    item6: "Dress Coat",
  },
  {
    id: 3,
    item: "Shirts",
    item1: "Pent",
    item2: "Coat",
    item3: "Socks",
    item4: "Shoes",
    item5: "Socks",
    item6: "Dress Coat",
  },
];
export const UsedItems = [
  {
    id: 1,
    title: "Post Single Add For SecondHand Items",
    link: "/post-items",
  },

  {
    id: 2,
    title: "Create a Shop For SecondHand Items",
    link: "/used-items-form",
  },

  {
    id: 3,
    title: "Post Single Add For Scrap",
    link: "/",
  },

  {
    id: 4,
    title: "Create a Shop For Recycle Material",
    link: "/",
  },
];
//sharemodal
export const Sharemodal = [
  {
    id: 1,
    icon: images.Timeline,
    text: "Timeline",
  },
  {
    id: 2,
    icon: images.Telegram,
    text: "Telegram",
  },
  {
    id: 3,
    icon: images.Twitter,
    text: "Twitter",
  },
  {
    id: 4,
    icon: images.Whatsapp,
    text: "Whatsapp",
  },
  {
    id: 5,
    icon: images.Emailtwo,
    text: "E-mail",
  },
  {
    id: 6,
    icon: images.Link,
    text: "More",
  },
];

export const Shareon = [
  {
    id: 1,
    label: "Service Timeline",
    isSelected: false,
  },

  {
    id: 2,
    label: "Transport Timeline",
    isSelected: false,
  },
  {

    id: 3,
    label: "Job Offers Timeline",
    isSelected: false,

  },

  {
    id: 4,
    label: "Job Wanted Timeline",
    isSelected: false,
  },
  {
    id: 5,
    label: "Retailer Timeline",
    isSelected: false,
  },
  {
    id: 6,
    label: "Wholesaler Timeline",
    isSelected: false,
  },
  {
    id: 7,
    label: "Manufacturer Timeline",
    isSelected: false,
  },
  {
    id: 8,
    label: "Raw Material Timeline",
    isSelected: false,
  },
  {
    id: 9,
    label: "Properties Online Timeline",
    isSelected: false,
  },
  {
    id: 10,
    label: "General Public Timeline",
    isSelected: false,
  },
];

export const ModalCards = [
  {
    id: 1,
    img: images.Car1,
    title: "Pink Hoodie t-shirt full",
    price: "$32.00",
    quantity: "1",
  },
  {
    id: 2,
    img: images.Car2,
    title: "Pink Hoodie t-shirt full",
    price: "$32.00",
    quantity: "1",
  },
  {
    id: 3,
    img: images.Car3,
    title: "Pink Hoodie t-shirt full",
    price: "$32.00",
    quantity: "1",
  },
  {
    id: 4,
    img: images.Car4,
    title: "Pink Hoodie t-shirt full",
    price: "$32.00",
    quantity: "1",
  },
  {
    id: 5,
    img: images.Car5,
    title: "Pink Hoodie t-shirt full",
    price: "$32.00",
    quantity: "1",
  },
];
export const listpic = [
  {
    id: 1,
    imgM: images.Alex1,
    Name: "ALex",
    title:
      "Lorem ipsum dolor sit amet, consectetur adi tempor incidila bore et dolore magna quis nostrudd fuyod...",
  },
  {
    id: 2,
    imgM: images.Alex1,
    Name: "ALex",
    title:
      "Lorem ipsum dolor sit amet, consectetur adi tempor incidila bore et dolore magna quis nostrudd fuyod...",
  },
  {
    id: 3,
    imgM: images.Alex1,
    Name: "ALex",
    title:
      "Lorem ipsum dolor sit amet, consectetur adi tempor incidila bore et dolore magna quis nostrudd fuyod...",
  },
];

export const listspics = [
  {
    id: 1,
    imgM: images.Alex2,
    Name: "Alex",
    title: "Alex send you Friend Request",
  },
  {
    id: 2,
    imgM: images.Alex2,
    Name: "Alex",
    title: "Alex send you Friend Request",
  },
  {
    id: 3,
    imgM: images.Alex2,
    Name: "Alex",
    title: "Alex send you Friend Request",
  },
];
export const viewlist = [
  {
    id: 1,
    imgM: images.Alex2,
    Name: "Alex",
    title: "Alex send you Friend Request",
  },
  {
    id: 2,
    imgM: images.Alex2,
    Name: "Alex",
    title: "Alex send you Friend Request",
  },
  {
    id: 3,
    imgM: images.Alex2,
    Name: "Alex",
    title: "Alex send you Friend Request",
  },
];

export const BuyingBusinessData = [
  {
    id: 1,
    title: "Retailer Market",
    info: "retailer info",
    titleone: "Retailer",
  },
  {
    id: 2,
    title: "Wholesale Market",
    info: "wholesaler info",
    titleone: "WholeSaller",
  },
  {
    id: 3,
    title: "Industrial Market",
    info: "industry info",
    titleone: "Industrial",
  },
  {
    id: 4,
    title: "Raw Material Market",
    info: "raw info",
    titleone: "Raw Material",
  },
];
export const BusinessData = [
  {
    id: 1,
    title: "Business Dashboard",
    para: "Create a shop and connect with other retailer market and",
    info: "information one",
  },

  {
    id: 2,
    title: "Wholesaler Market",
    para: "Create a shop and connect with other retailer market and",
    info: "information two",
    titleone: "WholeSaler",
  },

  {
    id: 3,
    title: "Industrial Market",
    para: "Create a shop and connect with other retailer market and",
    info: "information three",
    titleone: "Industrial",
  },

  {
    id: 4,
    title: "Raw Material Market",
    para: "Create a shop and connect with other retailer market and",
    info: "information four",
    titleone: "Raw Material",
  },
  {
    id: 5,
    title: "Retailer Market",
    para: "Create a shop and connect with other retailer market and",
    info: "information five",
    titleone: "Retailer",
  },

  {
    id: 6,
    title: "Homemade Items",
    para: "Create a shop and connect with other retailer market and",
    info: "information six",
    titleone: "HomeMade",
  },

  {
    id: 7,
    title: "Gems & Minreals",
    para: "Create a shop and connect with other retailer market and",
    info: "information seven",
    titleone: "Gems And Jewelry",
  },

  {
    id: 8,
    title: "Used Items Market",
    para: "Create a shop and connect with other retailer market and",
    info: "information eight",
    titleone: "Used Items",
  },
  {
    id: 9,
    title: "Create Job Offers",
    para: "Create a shop and connect with other retailer market and",
    info: "information nine",
    titleone: "Create Job",
  },

  {
    id: 10,
    title: "Post Your CV",
    para: "Create a shop and connect with other retailer market and",
    info: "information ten",
    titleone: "Post Your CV",
  },

  {
    id: 11,
    title: "Single Item Adds",
    para: "Create a shop and connect with other retailer market and",
    info: "information eleven",
    titleone: "Single Add",
  },
  {
    id: 12,
    title: "Food Services",
    para: "Create a shop and connect with other retailer market and",
    info: "information twelve",
    titleone: "Food And Restaurant",
  },
  {
    id: 13,
    title: "Services",
    para: "Create a shop and connect with other retailer market and",
    info: "information thirteen",
    titleone: "Services",
  },
  {
    id: 14,
    title: "Transportation",
    para: "Create a shop and connect with other retailer market and",
    info: "information fourteen",
    titleone: "Transportation",
  },
  {
    id: 15,
    title: "Business Property",
    para: "Create a shop and connect with other retailer market and",
    info: "information fifteen",
    titleone: "Business Property",
  },
  {
    id: 16,
    title: "Single Property",
    para: "Create a shop and connect with other retailer market and",
    info: "information sixteen",
    titleone: "Single Property",
  },
  {
    id: 17,
    title: "Farming Shop",
    para: "Create a shop and connect with other retailer market and",
    info: "information seventeen",
    titleone: "Farming",
  },
  {
    id: 18,
    title: "Recycled Material",
    para: "Create a shop and connect with other retailer market and",
    info: "information eighteen",
    titleone: "Recycled",
  },
  // {
  //   id: 19,
  //   title: "Scrap Material",
  //   para: "Create a  shop and connect with other retailer market and",
  //   info: "information ninteen",
  //   titleone: "Scrap Material",
  // },
  {
    id: 20,
    title: "Used Vehicles",
    para: "Create a shop and connect with other retailer market and",
    info: "information twenty",
    titleone: "Used Vehicles",
  },
  {
    id: 21,
    title: "Chat Room",
    para: "Create a shop and connect with other retailer market and",
    info: "information twentyone",
    titleone: "Chat Room",
  },
  {
    id: 22,
    title: "Helpline",
    para: "Create a shop and connect with other retailer market and",
    info: "information twentytwo",
    titleone: "Helpline",
  },
  {
    id: 23,
    title: "Email",
    para: "Create a shop and connect with other retailer market and",
    info: "information twentythree",
    titleone: "Email",
  },
];

export const BusinessDatatwo = [
  {
    id: 1,
    title: "Job Offers",
    para: "Create a shop and connect with other retailer market and",
    buytitle: "Job Offers",
    info: "create company",
  },

  {
    id: 2,
    title: "Available Candidates",
    para: "Create a shop and connect with other retailer market and",
    buytitle: "Avaliable Candidates",
    info: "create job offer",
  },
];

export const Generaluser = [
  {
    id: 1,
    title: "General Profile",
    para: "Create a shop and connect with other retailer market and",
    buytitle: "Job Offers",
    info: "View Profile",
  },

  {
    id: 2,
    title: "User Dashboard",
    para: "Create a shop and connect with other retailer market and",
    buytitle: "Avaliable Candidates",
    info: "User Dashboard",
  },
];

export const BusinessDatathree = [
  {
    id: 1,
    title: "Jewelry Shop",
    info: "Jewelry info",
  },

  {
    id: 2,
    title: "Used Item Shop",
    info: "used item info",
  },

  {
    id: 3,
    title: "Homemade Item ",
    info: "homemade info",
  },
];

export const RatingStar = [
  {
    id: 1,
    imgstarss: images.StarImg,
    Phera2: images.Verified2,
    Phera:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus faucibus tempus vestibulum. Morbi facilisis egestas",
    image: images.Image1,
    images: images.Image2,
  },
  {
    id: 2,
    imgstarss: images.StarImg,
    Phera2: images.Verified2,
    Phera:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus faucibus tempus vestibulum. Morbi facilisis egestas",
    image: images.Image1,
    images: images.Image2,
  },
  {
    id: 3,
    imgstarss: images.StarImg,
    Phera2: images.Verified2,
    Phera:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus faucibus tempus vestibulum. Morbi facilisis egestas",
    image: images.Image1,
    images: images.Image2,
  },
];

export const JuliaCards = [
  {
    id: 1,
    img: images.JuliaSideCard,
    name: "Julia",
    title: "Interviews",
    JuliaPara: "When it comes web  design interviews.",
    ChatDate: "May 17",
  },
  {
    id: 2,
    img: images.JuliaSideCard,
    name: "Julia",
    title: "Interviews",
    JuliaPara: "When it comes web  design interviews.",
    ChatDate: "May 17",
  },
  {
    id: 3,
    img: images.JuliaSideCard,
    name: "Julia",
    title: "Interviews",
    JuliaPara: "When it comes web  design interviews.",
    ChatDate: "May 17",
  },
  {
    id: 4,
    img: images.JuliaSideCard,
    name: "Julia",
    title: "Interviews",
    JuliaPara: "When it comes web  design interviews.",
    ChatDate: "May 17",
  },
  {
    id: 5,
    img: images.JuliaSideCard,
    name: "Julia",
    title: "Interviews",
    JuliaPara: "When it comes web  design interviews.",
    ChatDate: "May 17",
  },
  {
    id: 6,
    img: images.JuliaSideCard,
    name: "Julia",
    title: "Interviews",
    JuliaPara: "When it comes web  design interviews.",
    ChatDate: "May 17",
  },
  {
    id: 7,
    img: images.JuliaSideCard,
    name: "Julia",
    title: "Interviews",
    JuliaPara: "When it comes web  design interviews.",
    ChatDate: "May 17",
  },
  {
    id: 8,
    img: images.JuliaSideCard,
    name: "Julia",
    title: "Interviews",
    JuliaPara: "When it comes web  design interviews.",
    ChatDate: "May 17",
  },
  {
    id: 9,
    img: images.JuliaSideCard,
    name: "Julia",
    title: "Interviews",
    JuliaPara: "When it comes web  design interviews.",
    ChatDate: "May 17",
  },
  {
    id: 10,
    img: images.JuliaSideCard,
    name: "Julia",
    title: "Interviews",
    JuliaPara: "When it comes web  design interviews.",
    ChatDate: "May 17",
  },
];
export const MenuLeftbar = [
  { id: 1, icon: images.Mailbox, text: "Inbox", num: "23", route: "/inbox" },
  {
    id: 2,
    icon: images.PaperPlane,
    text: "Sent",
    num: "23",
    route: "/sent-mail",
  },
  {
    id: 3,
    icon: images.Draft,
    text: "Draft",
    num: "23",
    route: "/email-draft",
  },
  {
    id: 4,
    icon: images.Outbox,
    text: "Outbox",
    num: "23",
    route: "/email-outbox",
  },
  { id: 5, icon: images.Spam, text: "Spam", num: "23", route: "/email-spam" },
  {
    id: 6,
    icon: images.mail_one,
    text: "All Mails",
    num: "23",
    route: "/home-email",
  },
  {
    id: 7,
    icon: images.StarFilled,
    text: "Important",
    num: "23",
    route: "/important-mail",
  },
  {
    id: 9,
    icon: images.TrashCan,
    text: " Trash",
    num: "23",
    route: "/home-page-trash",
  },
];

export const MenuLeftbar2 = [
  {
    id: 1,
    icon: images.questions,
    text: "inquiry",
    num: "23",
    route: "/all-inquiry-buying",
    btn: "Send",
  },
  {
    id: 2,
    icon: images.receipt,
    text: "quotation",
    num: "23",
    route: "/quotation-buying",
    btn: "Received",
  },
  {
    id: 3,
    icon: images.order,
    text: "purchase order",
    num: "23",
    route: "/purchase-order-buying",
    btn: "Send",
  },
  {
    id: 4,
    icon: images.purchase,
    text: "Invoice",
    num: "23",
    route: "/invoice-buying",
    btn: "Received",
  },
  {
    id: 5,
    icon: images.Tracking,
    text: "Shipment",
    num: "23",
    btn: "Tracking",
  },
  {
    id: 6,
    icon: images.TrashCan,
    text: "Trash",
    num: "23",
    route: "/trash-buying",
  },
  { id: 7, icon: images.Save, text: "Save", num: "23" },
];

export const MenuLeftbar3 = [
  {
    id: 1,
    icon: images.receipt,
    text: "inquiry",
    num: "23",
    route: "/inquiry-selling",
    btn: "Received",
  },
  {
    id: 2,
    icon: images.questions,
    text: "quotation",
    num: "23",
    route: "/quotation-selling",
    btn: "Send",
  },
  {
    id: 3,
    icon: images.order,
    text: "purchase order",
    num: "23",
    btn: "Received",
    route: "/purchase-order-selling",
  },
  {
    id: 4,
    icon: images.purchase,
    text: "Invoice",
    num: "23",
    route: "/invoice-selling",
    btn: "Send",
  },
  {
    id: 5,
    icon: images.Tracking,
    text: "Shipment",
    num: "23",
    btn: "Tracking",
  },
  {
    id: 6,
    icon: images.TrashCan,
    text: "Trash",
    num: "23",
    route: "/trash-selling",
  },
  { id: 7, icon: images.Save, text: "Save", num: "23" },

  //  { id: 1, icon: images.meet, text: " New Meeting", num: "23" },
  //  { id: 2, icon: images.joinmeet, text: "Join Meeting", num: "23" },
  // { id: 3, icon: images.schedule, text: " Schedule Meeting", num: "23" },
  //  { id: 4, icon: images.chat, text: " Chats", num: "23" },
];

export const MenuLeftbar4 = [
  { id: 1, icon: images.Newmeeting, text: " New Meeting", num: "23" },
  { id: 2, icon: images.Joinmeeting, text: "Join Meeting", num: "23" },
  { id: 3, icon: images.Schedulemeeting, text: " Schedule Meeting", num: "23" },
];

export const MenuLeftbar5 = [
  { id: 1, icon: images.Chats, text: "Chats ", num: "23" },
];

export const SellCards = [
  {
    id: 1,
    img: images.MainAvatar,
    name: "Rosie Gabriel",
    btn: "View Profile",
  },
  {
    id: 2,
    img: images.MainAvatar,
    name: "Tahleel and sons",
    btn: "My Business Dashboard",
  },
];

export const ItemsImg = [
  {
    id: 1,
    imgone: images.Rectangle_1,
    imgtwo: images.Vectorone,
    description: "John",
  },
  {
    id: 2,
    imgone: images.Rectangle_2,
    imgtwo: images.Vectorone,
    description: "Julia",
  },
  {
    id: 3,
    imgone: images.Rectangle_3,
    imgtwo: images.Vectorone,
    description: "johny",
  },
  {
    id: 4,
    imgone: images.Rectangle_5,
    imgtwo: images.Vectorone,
    description: "Maxwell",
  },
  {
    id: 5,
    imgone: images.Rectangle_2,
    imgtwo: images.Vectorone,
    description: "Mariya",
  },
];

export const Ownchatroomapi = [
  {
    id: 1,
    image: images.ownchatroom1,
    groupname: "Group Name",
  },

  {
    id: 2,
    image: images.ownchatroom2,
    groupname: "Group Name",
  },

  {
    id: 3,
    image: images.ownchatroom3,
    groupname: "Group Name",
  },

  {
    id: 4,
    image: images.ownchatroom4,
    groupname: "Group Name",
  },

  {
    id: 5,
    image: images.ownchatroom5,
    groupname: "Group Name",
  },

  {
    id: 6,
    image: images.ownchatroom6,
    groupname: "Group Name",
  },

  {
    id: 7,
    image: images.ownchatroom7,
    groupname: "Group Name",
  },

  {
    id: 8,
    image: images.ownchatroom8,
    groupname: "Group Name",
  },

  {
    id: 9,
    image: images.ownchatroom9,
    groupname: "Group Name",
  },

  {
    id: 10,
    image: images.ownchatroom4,
    groupname: "Group Name",
  },

  {
    id: 11,
    image: images.ownchatroom5,
    groupname: "Group Name",
  },

  {
    id: 12,
    image: images.ownchatroom2,
    groupname: "Group Name",
  },
];

export const Mychatapi = [
  {
    id: 1,
    image: images.ownchatroom1,
    groupname: "Fun",
  },

  {
    id: 2,
    image: images.ownchatroom2,
    groupname: "Family",
  },

  {
    id: 3,
    image: images.ownchatroom3,
    groupname: "Fashion",
  },

  {
    id: 4,
    image: images.ownchatroom4,
    groupname: "Gaming",
  },
];

export const Ownchatroom = [
  {
    id: 1,
    image: images.ownchatroom1,
    groupname: "Stream Line",
  },

  {
    id: 2,
    image: images.ownchatroom2,
    groupname: "Group Name",
  },

  {
    id: 3,
    image: images.ownchatroom3,
    groupname: "Group Name",
  },

  {
    id: 4,
    image: images.ownchatroom4,
    groupname: "Group Name",
  },

  {
    id: 5,
    image: images.ownchatroom5,
    groupname: "Group Name",
  },

  {
    id: 6,
    image: images.ownchatroom6,
    groupname: "Group Name",
  },

  {
    id: 7,
    image: images.ownchatroom7,
    groupname: "Group Name",
  },

  {
    id: 8,
    image: images.ownchatroom8,
    groupname: "Group Name",
  },

  {
    id: 9,
    image: images.ownchatroom9,
    groupname: "Group Name",
  },

  {
    id: 10,
    image: images.ownchatroom5,
    groupname: "Group Name",
  },

  {
    id: 11,
    image: images.ownchatroom4,
    groupname: "Group Name",
  },

  {
    id: 12,
    image: images.ownchatroom7,
    groupname: "Group Name",
  },
];
//Livechatroom
export const Livechatroomapi = [
  {
    id: 1,
    image: images.livechat1,
    name: "Name",
  },

  {
    id: 2,
    image: images.livechat18,
    name: "Name",
  },

  {
    id: 3,
    image: images.livechat17,
    name: "Name",
  },

  {
    id: 4,
    image: images.livechat16,
    name: "Name",
  },

  {
    id: 5,
    image: images.livechat15,
    name: "Name",
  },

  {
    id: 6,
    image: images.livechat16,
    name: "Name",
  },

  {
    id: 7,
    image: images.livechat14,
    name: "Name",
  },

  {
    id: 8,
    image: images.livechat18,
    name: "Name",
  },

  {
    id: 9,
    image: images.livechat11,
    name: "Name",
  },

  {
    id: 10,
    image: images.livechat10,
    name: "Name",
  },

  {
    id: 11,
    image: images.livechat11,
    name: "Name",
  },

  {
    id: 12,
    image: images.livechat12,
    name: "Name",
  },

  {
    id: 13,
    image: images.livechat13,
    name: "Name",
  },

  {
    id: 14,
    image: images.livechat14,
    name: "Name",
  },

  {
    id: 15,
    image: images.livechat15,
    name: "Name",
  },

  {
    id: 16,
    image: images.livechat16,
    name: "Name",
  },

  {
    id: 17,
    image: images.livechat17,
    name: "Name",
  },

  {
    id: 18,
    image: images.livechat18,
    name: "Name",
  },
];

//Lobbies
export const Joinlobbies = [
  {
    id: 1,
    image: images.lobi1,
    name: "Cricket",
  },

  {
    id: 2,
    image: images.lobi2,
    name: "Football",
  },

  {
    id: 3,
    image: images.lobi3,
    name: "Tennis",
  },

  {
    id: 4,
    image: images.lobi4,
    name: "Rackets",
  },

  {
    id: 5,
    image: images.lobi5,
    name: "News",
  },

  {
    id: 6,
    image: images.lobi6,
    name: "Politics",
  },

  {
    id: 7,
    image: images.lobi7,
    name: "BaseBall",
  },

  {
    id: 8,
    image: images.lobi8,
    name: "Ice Hockey",
  },

  {
    id: 9,
    image: images.lobi9,
    name: "Dance",
  },

  {
    id: 10,
    image: images.lobi10,
    name: "Online Game",
  },

  {
    id: 11,
    image: images.lobi11,
    name: "Song",
  },

  {
    id: 12,
    image: images.lobi12,
    name: "Movies",
  },

  {
    id: 13,
    image: images.lobi13,
    name: "Technology",
  },

  {
    id: 14,
    image: images.lobi14,
    name: "Islamic",
  },

  {
    id: 15,
    image: images.lobi15,
    name: "Books",
  },

  {
    id: 16,
    image: images.lobi16,
    name: "Future Tech",
  },

  {
    id: 17,
    image: images.lobi17,
    name: "Robotics",
  },

  {
    id: 18,
    image: images.lobi18,
    name: "AI",
  },
];

export const UserChatroomviewImages = [
  {
    id: 1,
    img: images.Imgboyy1,
    image: images.Greendot,
    name: "Chipmunk",
    paragraph: "Admin",
    text: "Lorem ipsum dolor sit amet, consectetur...",
  },
  {
    id: 2,
    img: images.Imgboyy1,
    name: "Chipmunk",
    message: "message",
    text: "Lorem ipsum dolor sit amet, consectetur...",
  },
  {
    id: 3,
    img: images.Imgboyy1,
    name: "Chipmunk",
    message: "message",
    text: "Lorem ipsum dolor sit amet, consectetur...",
  },
  {
    id: 4,
    img: images.Imgboyy1,
    name: "Chipmunk",
    message: "message",
    text: "Lorem ipsum dolor sit amet, consectetur...",
  },
  {
    id: 5,
    img: images.Imgboyy1,
    name: "Chipmunk",
    message: "message",
    text: "Lorem ipsum dolor sit amet, consectetur...",
  },
  {
    id: 6,
    img: images.Imgboyy1,
    name: "Chipmunk",
    message: "message",
    text: "Lorem ipsum dolor sit amet, consectetur...",
  },
];

//Liveinvitechatroom
export const Liveinviteroomapi = [
  {
    id: 1,
    image: images.livechat1,
  },

  {
    id: 2,
    image: images.livechat1,
  },

  {
    id: 3,
    image: images.livechat1,
  },

  {
    id: 4,
    image: images.livechat1,
  },

  {
    id: 5,
    image: images.livechat1,
  },

  {
    id: 6,
    image: images.livechat1,
  },

  {
    id: 7,
    image: images.livechat10,
  },

  {
    id: 8,
    image: images.livechat10,
  },

  {
    id: 9,
    image: images.livechat12,
  },

  {
    id: 10,
    image: images.livechat10,
  },

  {
    id: 11,
    image: images.livechat11,
  },

  {
    id: 12,
    image: images.livechat12,
  },

  {
    id: 13,
    image: images.livechat13,
  },

  {
    id: 14,
    image: images.livechat14,
  },

  {
    id: 15,
    image: images.livechat15,
  },

  {
    id: 16,
    image: images.livechat16,
  },

  {
    id: 17,
    image: images.livechat17,
  },

  {
    id: 18,
    image: images.livechat18,
  },

  {
    id: 19,
    image: images.livechat1,
  },

  {
    id: 20,
    image: images.livechat18,
  },

  {
    id: 21,
    image: images.livechat18,
  },

  {
    id: 22,
    image: images.livechat17,
  },

  {
    id: 23,
    image: images.livechat17,
  },

  {
    id: 24,
    image: images.livechat17,
  },

  {
    id: 25,
    image: images.livechat17,
  },

  {
    id: 26,
    image: images.livechat17,
  },

  {
    id: 27,
    image: images.livechat17,
  },

  {
    id: 28,
    image: images.livechat10,
  },

  {
    id: 29,
    image: images.livechat11,
  },

  {
    id: 30,
    image: images.livechat12,
  },

  {
    id: 31,
    image: images.livechat13,
  },

  {
    id: 32,
    image: images.livechat14,
  },

  {
    id: 33,
    image: images.livechat15,
  },

  {
    id: 34,
    image: images.livechat16,
  },

  {
    id: 35,
    image: images.livechat17,
  },

  {
    id: 36,
    image: images.livechat18,
  },
];

export const commentsLiveChatRoom = [
  {
    id: 1,
    img: images.ProfileImg,
    personName: "zehra",
    personComment: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum...",
  },
  {
    id: 2,
    img: images.ProfileImg,
    personName: "zehra",
    personComment: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum...",
  },
  {
    id: 3,
    img: images.ProfileImg,
    personName: "zehra",
    personComment: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum...",
  },
  {
    id: 4,
    img: images.ProfileImg,
    personName: "zehra",
    personComment: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum...",
  },
  {
    id: 5,
    img: images.ProfileImg,
    personName: "zehra",
    personComment: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum...",
  },
  {
    id: 6,
    img: images.ProfileImg,
    personName: "zehra",
    personComment: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum...",
  },
  {
    id: 7,
    img: images.ProfileImg,
    personName: "zehra",
    personComment: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum...",
  },
  {
    id: 8,
    img: images.ProfileImg,
    personName: "zehra",
    personComment: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum...",
  },
  {
    id: 9,
    img: images.ProfileImg,
    personName: "zehra",
    personComment: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum...",
  },
  {
    id: 10,
    img: images.ProfileImg,
    personName: "zehra",
    personComment: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum...",
  },
];
export const SellingSteps = [
  {
    selector: ".first-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".second-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".third-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".four-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".five-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".six-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".seven-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".eight-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".nine-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".ten-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".eleven-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".twelve-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".thirteen-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".fourteen-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".fifteen-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".sixteen-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".seventeen-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".eighteen-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".nineteen-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".twenty-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".twentyone-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".twentytwo-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".twentythree-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".twentyfour-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".twentyfive-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".twentysix-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
];

export const BuyingSteps = [
  {
    selector: ".first-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".second-element",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".third",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".four",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".five",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".six",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".seven",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".eight",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".nine",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".ten",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".eleven",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".twelve",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".thirteen",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".fourteen",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".fifteen",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".sixteen",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".seventeen",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".eighteen",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".nineteen",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".twenty",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".twentyone",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".twentytwo",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".twentythree",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".twentyfour",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    selector: ".twentyfive",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
];
export const Addbox = [
  {
    id: 1,
    text: "Julia",
    description: "I think we should add more ideas",
    time: "9:35",
  },
  {
    id: 2,
    text: "Jhon",
    description: "You are right Julia",
    time: "9:36",
  },
  {
    id: 3,
    text: "Mariya",
    description: "https://dribbble.com/shots/146089",
    time: "9:40",
  },
  {
    id: 4,
    text: "You",
    description: "Lets start work on it",
    time: "9:40",
  },
  {
    id: 5,
    text: "You",
    description: "I think we should add more ideas",
    time: "9:35",
  },
  {
    id: 6,
    text: "Jhon",
    description: "You are right Sir",
    time: "9:45",
  },
  {
    id: 7,
    text: "Julia",
    description: "I think we should add more ideas",
    time: "9:35",
  },
];
export const BuyingRoute = [
  {
    id: 1,
    route: "/buy-retailer",
  },
  {
    id: 2,
    route: "/buy-wholeseller",
  },
  {
    id: 3,
    route: "/buy-manufacturer",
  },
  {
    id: 4,
    route: "/buy-raw-material",
  },
  {
    id: 5,
    route: "/find-job",
  },
  {
    id: 6,
    route: "/find-candidates",
  },
  {
    id: 7,
    route: "/buy-Minerals",
  },

  {
    id: 8,
    route: "/buy-homemade-products",
  },
  {
    id: 9,
    route: "/buy-used-products",
  },
  {
    id: 10,
    route: "/find-property",
  },
  {
    id: 11,
    route: "/buy-used-cars",
  },
  {
    id: 12,
    route: "/find-Transport",
  },
  {
    id: 13,
    route: "/recycle-online",
  },
  {
    id: 14,
    route: "/find-services",
  },
  {
    id: 15,
    route: "/scrap-online",
  },
  {
    id: 16,
    route: "/Farming-online",
  },
];

export const currencies = [
  {
    abbreviation: "AED",
    name: "Arabic dirham",
  },
  {
    abbreviation: "AFN",
    name: "afghani",
  },
  {
    abbreviation: "ALL",
    name: "lek",
  },
  {
    abbreviation: "AMD",
    name: "dram",
  },
  {
    abbreviation: "ANG",
    name: "Netherlands Antillean guilder",
  },
  {
    abbreviation: "AOA",
    name: "kwanza",
  },
  {
    abbreviation: "ARS",
    name: "Argentine peso",
  },
  {
    abbreviation: "AUD",
    name: "Australian dollar",
  },
  {
    abbreviation: "AWG",
    name: "guilder",
  },
  {
    abbreviation: "AZN",
    name: "manat",
  },
  {
    abbreviation: "BAM",
    name: "Convertible mark",
  },
  {
    abbreviation: "BBD",
    name: "Barbadian dollar",
  },
  {
    abbreviation: "BDT",
    name: "taka",
  },
  {
    abbreviation: "BGN",
    name: "Bulgarian lev",
  },
  {
    abbreviation: "BHD",
    name: "Bahrain dinar",
  },
  {
    abbreviation: "BIF",
    name: "Burundi franc",
  },
  {
    abbreviation: "BMD",
    name: "Bermudian dollar",
  },
  {
    abbreviation: "BND",
    name: "Brunei dollar",
  },
  {
    abbreviation: "BOB",
    name: "boliviano",
  },
  {
    abbreviation: "BRL",
    name: "Brazilian real",
  },
  {
    abbreviation: "BSD",
    name: "Bahamian dollar",
  },
  {
    abbreviation: "BTN",
    name: "ngultrum",
  },
  {
    abbreviation: "BWP",
    name: "pula",
  },
  {
    abbreviation: "BYR",
    name: "Belarus rubel",
  },
  {
    abbreviation: "BZD",
    name: "Belize dollar",
  },
  {
    abbreviation: "CAD",
    name: "Canadian dollar",
  },
  {
    abbreviation: "CDF",
    name: "Congolais franc",
  },
  {
    abbreviation: "CHF",
    name: "Swiss franc",
  },
  {
    abbreviation: "CKD",
    name: "Cook dollar",
  },
  {
    abbreviation: "CLP",
    name: "Chilean peso",
  },
  {
    abbreviation: "CNY",
    name: "Renminbi yuan",
  },
  {
    abbreviation: "COP",
    name: "Colombian peso",
  },
  {
    abbreviation: "CRC",
    name: "colón",
  },
  {
    abbreviation: "CUP",
    name: "Cuban peso",
  },
  {
    abbreviation: "CVE",
    name: "Cape Verdean escudo",
  },
  {
    abbreviation: "CZK",
    name: "Czech krone",
  },
  {
    abbreviation: "DJF",
    name: "Djibouti franc",
  },
  {
    abbreviation: "DKK",
    name: "Danish krone",
  },
  {
    abbreviation: "DOP",
    name: "Dominican peso",
  },
  {
    abbreviation: "DZD",
    name: "Algerian dinar",
  },
  {
    abbreviation: "EGP",
    name: "Egypt pound",
  },
  {
    abbreviation: "ERN",
    name: "nakfa",
  },
  {
    abbreviation: "ETB",
    name: "birr",
  },
  {
    abbreviation: "FJD",
    name: "Fiji dollar",
  },
  {
    abbreviation: "FKP",
    name: "Falklands pound",
  },
  {
    abbreviation: "FOK",
    name: "Faroese krona",
  },
  {
    abbreviation: "GBP",
    name: "Sterling pound",
  },
  {
    abbreviation: "GEL",
    name: "Georgian lari",
  },
  {
    abbreviation: "GGP",
    name: "Guernsey pound",
  },
  {
    abbreviation: "GHS",
    name: "Ghana cedi",
  },
  {
    abbreviation: "GIP",
    name: "Gibraltar pound",
  },
  {
    abbreviation: "GMD",
    name: "dalasi",
  },
  {
    abbreviation: "GNF",
    name: "Guinea franc",
  },
  {
    abbreviation: "GTQ",
    name: "quetzal",
  },
  {
    abbreviation: "GYD",
    name: "Guyana dollar",
  },
  {
    abbreviation: "HKD",
    name: "Hong Kong dollar",
  },
  {
    abbreviation: "HNL",
    name: "lempira",
  },
  {
    abbreviation: "HTG",
    name: "gourde",
  },
  {
    abbreviation: "HUF",
    name: "Hungarian forint",
  },
  {
    abbreviation: "IDR",
    name: "Indonesian rupiah",
  },
  {
    abbreviation: "IMP",
    name: "Manx pound",
  },
  {
    abbreviation: "INR",
    name: "Indian rupee",
  },
  {
    abbreviation: "IQD",
    name: "Iraqi dinar",
  },
  {
    abbreviation: "IRR",
    name: "Iranian rial",
  },
  {
    abbreviation: "ISK",
    name: "Icelandic krone",
  },
  {
    abbreviation: "JEP",
    name: "Jersey Sterling pound",
  },
  {
    abbreviation: "JMD",
    name: "Jamaica dollar",
  },
  {
    abbreviation: "JOD",
    name: "Jordanian dinar",
  },
  {
    abbreviation: "JPY",
    name: "Japanese yen",
  },
  {
    abbreviation: "KES",
    name: "Kenian schilling",
  },
  {
    abbreviation: "KGS",
    name: "som",
  },
  {
    abbreviation: "KHR",
    name: "Cambodian riel",
  },
  {
    abbreviation: "KID",
    name: "Kiribati dollar",
  },
  {
    abbreviation: "KMF",
    name: "Comorian franc",
  },
  {
    abbreviation: "KPW",
    name: "North Korean won",
  },
  {
    abbreviation: "KRW",
    name: "South Korean won",
  },
  {
    abbreviation: "KWD",
    name: "Kuwaiti dinar",
  },
  {
    abbreviation: "KYD",
    name: "Cayman dollar",
  },
  {
    abbreviation: "KZT",
    name: "tenge",
  },
  {
    abbreviation: "LAK",
    name: "kip",
  },
  {
    abbreviation: "LBP",
    name: "Lebanese pound",
  },
  {
    abbreviation: "LKR",
    name: "Sri Lanka rupee",
  },
  {
    abbreviation: "LRD",
    name: "Liberian dollar",
  },
  {
    abbreviation: "LSL",
    name: "Lesotho loti",
  },
  {
    abbreviation: "LYD",
    name: "Libyan dinar",
  },
  {
    abbreviation: "MAD",
    name: "Moroccan dirham",
  },
  {
    abbreviation: "MDL",
    name: "Moldovan leu",
  },
  {
    abbreviation: "MGA",
    name: "Malagasy ariary",
  },
  {
    abbreviation: "MKD",
    name: "denar",
  },
  {
    abbreviation: "MMK",
    name: "kyat",
  },
  {
    abbreviation: "MNT",
    name: "tugrik",
  },
  {
    abbreviation: "MOP",
    name: "Macanese pataca",
  },
  {
    abbreviation: "MRO",
    name: "Mauritanian ouguiya",
  },
  {
    abbreviation: "MUR",
    name: "Mauritian rupee",
  },
  {
    abbreviation: "MVR",
    name: "Maldivian rufiyaa",
  },
  {
    abbreviation: "MWK",
    name: "Malawian kwacha",
  },
  {
    abbreviation: "MXN",
    name: "Mexican peso",
  },
  {
    abbreviation: "MYR",
    name: "ringgit",
  },
  {
    abbreviation: "MZN",
    name: "metical",
  },
  {
    abbreviation: "NAD",
    name: "Namibian dollar",
  },
  {
    abbreviation: "NGN",
    name: "naira",
  },
  {
    abbreviation: "NIO",
    name: "Córdoba oro",
  },
  {
    abbreviation: "NOK",
    name: "Norwegian krone",
  },
  {
    abbreviation: "NPR",
    name: "Nepalese rupee",
  },
  {
    abbreviation: "NZD",
    name: "New Zealand dollar",
  },
  {
    abbreviation: "OMR",
    name: "Omani rial",
  },
  {
    abbreviation: "PAB",
    name: "Panamanian balboa",
  },
  {
    abbreviation: "PEN",
    name: "Nuevo sol",
  },
  {
    abbreviation: "PGK",
    name: "kina",
  },
  {
    abbreviation: "PHP",
    name: "Philippine peso",
  },
  {
    abbreviation: "PKR",
    name: "Pakistanian rupee",
  },
  {
    abbreviation: "PLN",
    name: "zloty",
  },
  {
    abbreviation: "PYG",
    name: "guaraní",
  },
  {
    abbreviation: "QAR",
    name: "Qatari rial",
  },
  {
    abbreviation: "RON",
    name: "Romanian leu",
  },
  {
    abbreviation: "RSD",
    name: "Serbian dinar",
  },
  {
    abbreviation: "RUB",
    name: "Russian rubel",
  },
  {
    abbreviation: "RWF",
    name: "Rwandan franc",
  },
  {
    abbreviation: "SAR",
    name: "Saudi riyal",
  },
  {
    abbreviation: "SBD",
    name: "Solomon Islands dollar",
  },
  {
    abbreviation: "SCR",
    name: "Seychellois rupee",
  },
  {
    abbreviation: "SDG",
    name: "Sudanese pound",
  },
  {
    abbreviation: "SEK",
    name: "Swedish krona",
  },
  {
    abbreviation: "SGD",
    name: "Singapore dollar",
  },
  {
    abbreviation: "SHP",
    name: "Saint Helena pound",
  },
  {
    abbreviation: "SLL",
    name: "Sierra Leonean leone",
  },
  {
    abbreviation: "SOS",
    name: "Somalian shilling",
  },
  {
    abbreviation: "SRD",
    name: "Surinamese dollar",
  },
  {
    abbreviation: "SSP",
    name: "South Sudanese pound",
  },
  {
    abbreviation: "STD",
    name: "São Tomé and Príncipe dobra",
  },
  {
    abbreviation: "SVC",
    name: "Salvadoran colón",
  },
  {
    abbreviation: "SYP",
    name: "Syrian pound",
  },
  {
    abbreviation: "SZL",
    name: "lilangeni",
  },
  {
    abbreviation: "THB",
    name: "Thai baht",
  },
  {
    abbreviation: "TJS",
    name: "Tajikistani somoni",
  },
  {
    abbreviation: "TMT",
    name: "Turkmenistani manat",
  },
  {
    abbreviation: "TND",
    name: "Tunisian dinar",
  },
  {
    abbreviation: "TOP",
    name: "pa'anga",
  },
  {
    abbreviation: "TRY",
    name: "Turkish lira",
  },
  {
    abbreviation: "TTD",
    name: "Trinidad and Tobago dollar",
  },
  {
    abbreviation: "TVD",
    name: "Tuvalu dollar",
  },
  {
    abbreviation: "TWD",
    name: "New Taiwan dollar",
  },
  {
    abbreviation: "TZS",
    name: "Tanzanian shilling",
  },
  {
    abbreviation: "UAH",
    name: "Ukrainian hryvnia",
  },
  {
    abbreviation: "UGX",
    name: "Ugandan shilling",
  },
  {
    abbreviation: "USD",
    name: "United States dollar",
  },
  {
    abbreviation: "UYU",
    name: "Uruguayan peso",
  },
  {
    abbreviation: "UZS",
    name: "Uzbekistani som",
  },
  {
    abbreviation: "VEF",
    name: "Venezuelan bolívar",
  },
  {
    abbreviation: "VND",
    name: "Vietnamese dong",
  },
  {
    abbreviation: "VUV",
    name: "Vanuatu vatu",
  },
  {
    abbreviation: "WST",
    name: "tala",
  },
  {
    abbreviation: "XAF",
    name: "Central African CFA franc",
  },
  {
    abbreviation: "XCD",
    name: "East Caribbean dollar",
  },
  {
    abbreviation: "XDR",
    name: "Special Drawing Rights",
  },
  {
    abbreviation: "XOF",
    name: "West African CFA franc",
  },
  {
    abbreviation: "XPF",
    name: "CFP franc",
  },
  {
    abbreviation: "YER",
    name: "Yemeni rial",
  },
  {
    abbreviation: "ZAR",
    name: "South African rand",
  },
  {
    abbreviation: "ZMW",
    name: "Zambian kwacha",
  },
  {
    abbreviation: "ZWD",
    name: "Zimbabwean dollar",
  },
];

export const commentimage = [
  {
    id: 1,
    img: images.Commentspic,
  },
  {
    id: 2,
    img: images.commentspic2,
  },
  {
    id: 3,
    img: images.Commentspic,
  },
  {
    id: 4,
    img: images.commentspic2,
  },
  {
    id: 5,
    img: images.Commentspic,
  },
  {
    id: 6,
    img: images.commentspic2,
  },
  {
    id: 7,
    img: images.Commentspic,
  },
];
export const Measurementunit = [
  {
    name: "Piece (pc)",
    abbreviation: "pc",
  },
  {
    name: "Unit (u)",
    abbreviation: "u",
  },
  {
    name: "Dozen (dz)",
    abbreviation: "dz",
  },
  {
    name: "Pack",
    abbreviation: "pack",
  },
  {
    name: "Set",
    abbreviation: "set",
  },
  {
    name: "Pair",
    abbreviation: "pair",
  },
  {
    name: "Kilogram (kg)",
    abbreviation: "kg",
  },
  {
    name: "Gram (g)",
    abbreviation: "g",
  },
  {
    name: "Pound (lb)",
    abbreviation: "lb",
  },
  {
    name: "Ounce (oz)",
    abbreviation: "oz",
  },
  {
    name: "Liter (L)",
    abbreviation: "L",
  },
  {
    name: "Milliliter (mL)",
    abbreviation: "mL",
  },
  {
    name: "Gallon (gal)",
    abbreviation: "gal",
  },
  {
    name: "Fluid ounce (fl oz)",
    abbreviation: "fl oz",
  },
  {
    name: "Meter (m)",
    abbreviation: "m",
  },
  {
    name: "Centimeter (cm)",
    abbreviation: "cm",
  },
  {
    name: "Inch (in)",
    abbreviation: "in",
  },
  {
    name: "Square meter (m²)",
    abbreviation: "m²",
  },
  {
    name: "Square inch (in²)",
    abbreviation: "in²",
  },
  {
    name: "Cubic meter (m³)",
    abbreviation: "m³",
  },
  {
    name: "Cubic foot (ft³)",
    abbreviation: "ft³",
  },
  {
    name: "Each (ea)",
    abbreviation: "ea",
  },
  {
    name: "Per pound (lb)",
    abbreviation: "lb",
  },
  {
    name: "Per ounce (oz)",
    abbreviation: "oz",
  },
  {
    name: "Per liter (L)",
    abbreviation: "L",
  },
  {
    name: "Pack of 6",
    abbreviation: "pack of 6",
  },
  {
    name: "Pack of 12",
    abbreviation: "pack of 12",
  },
  {
    name: "Pack of 24",
    abbreviation: "pack of 24",
  },
  {
    name: "Square foot (ft²)",
    abbreviation: "ft²",
  },
  {
    name: "Pounds per dozen (lb/dz)",
    abbreviation: "lb/dz",
  },
  {
    name: "Pounds per hundred (lb/c)",
    abbreviation: "lb/c",
  },
  {
    name: "Pounds per thousand (lb/M)",
    abbreviation: "lb/M",
  },
  {
    name: "Yard (yd)",
    abbreviation: "yd",
  },
];

export const accordionData = [
  {
    eventKey: "1",
    header: "Starting a store",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    eventKey: "2",
    header: "Store management",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    eventKey: "3",
    header: "Online store",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    eventKey: "4",
    header: "purchase Storage",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    eventKey: "5",
    header: "Account",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    eventKey: "6",
    header: "What payment methods are supported",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    eventKey: "7",
    header: "What is the return and refund policy?",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    eventKey: "8",
    header: "Payment system",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
];

export const accordionBuyer = [
  {
    eventKey: "1",
    header: "What payment methods are supported",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    eventKey: "2",
    header: "How do I track my orders?",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    eventKey: "3",
    header: "What is the return and refund policy?",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    eventKey: "4",
    header: "What should I do if I encounter a problem during checkout?",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    eventKey: "5",
    header: "How can I contact customer support?",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    eventKey: "6",
    header: "Is there a mobile app",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    eventKey: "7",
    header: "What do I do if I forget my password?",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    eventKey: "8",
    header: "Are there any restrictions on the type of content",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
];
export const Suggestionmdl = [
  {
    id: 1,
    name: "Alex",
    IMage: images.commentspic2,
    phera1: "Graphic designer",
    phera2:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy..... ",
  },
  {
    id: 2,
    name: "Alex",
    IMage: images.commentspic2,
    phera1: "Graphic designer",
    phera2:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy.....",
  },

  {
    id: 3,
    name: "Alex",
    IMage: images.commentspic2,
    phera1: "Graphic designer",
    phera2:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy..... ",
  },

  {
    id: 4,
    name: "Alex",
    IMage: images.commentspic2,
    phera1: "Graphic designer",
    phera2:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy..... ",
  },
  {
    id: 5,
    name: "julia",
    IMage: images.commentspic2,
    phera1: "Graphic designer",
    phera2:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy..... ",
  },
  {
    id: 6,
    name: "Alex",
    IMage: images.commentspic2,
    phera1: "Graphic designer",
    phera2:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy..... ",
  },
  {
    id: 7,
    name: "Alex",
    IMage: images.commentspic2,
    phera1: "Graphic designer",
    phera2:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy..... ",
  },
  {
    id: 8,
    name: "Alex",
    IMage: images.commentspic2,
    phera1: "Graphic designer",
    phera2:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy..... ",
  },
  {
    id: 9,
    name: "Alex",
    IMage: images.commentspic2,
    phera1: "Graphic designer",
    phera2:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy.....",
  },
];
export const Reports = [
  {
    id: 1,
    name: "Alex",
    IMage: images.commentspic2,
    phera1: "Graphic designer",
    phera2:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy..... ",
  },
  {
    id: 2,
    name: "Alex",
    IMage: images.commentspic2,
    phera1: "Graphic designer",
    phera2:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy.....",
  },
  {
    id: 3,
    name: "Alex",
    IMage: images.commentspic2,
    phera1: "Graphic designer",
    phera2:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy..... ",
  },
  {
    id: 4,
    name: "Alex",
    IMage: images.commentspic2,
    phera1: "Graphic designer",
    phera2:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy..... ",
  },
  {
    id: 5,
    name: "julia",
    IMage: images.commentspic2,
    phera1: "Graphic designer",
    phera2:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy..... ",
  },
  {
    id: 6,
    name: "Alex",
    IMage: images.commentspic2,
    phera1: "Graphic designer",
    phera2:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy..... ",
  },
  {
    id: 7,
    name: "Alex",
    IMage: images.commentspic2,
    phera1: "Graphic designer",
    phera2:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy..... ",
  },
  {
    id: 8,
    name: "Alex",
    IMage: images.commentspic2,
    phera1: "Graphic designer",
    phera2:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy..... ",
  },
  {
    id: 9,
    name: "Alex",
    IMage: images.commentspic2,
    phera1: "Graphic designer",
    phera2:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy.....",
  },
];

export const paymentoption = [
  {
    id: 1,
    label: "Card",
  },
  {
    id: 2,
    label: "Bank",
  },
  {
    id: 3,
    label: "EasyPaisa",
  },
  {
    id: 4,
    label: "Jazzcash",
  },
  {
    id: 5,
    label: "Cash on delivery",
  },
];

export const Paymentmethod = [
  {
    id: 1,
    label: "Payment through cradit cards",
    labelvalue: "creditCard",
  },
  {
    id: 2,
    label: "Payment through Bank Transfer",
    labelvalue: "bankTransfer",
  },
  {
    id: 3,
    label: "Cash on delivery",
    labelvalue: "cashOnDelivery",
  },
  {
    id: 4,
    label: "Eassypasia ",
    labelvalue: "easyPaisa",
  },
  {
    id: 5,
    label: "Jazz Cash",
    labelvalue: "jazzCash",
  },
];

export const Restaurent = [
  {
    id: 1,
    name: "Beef",
    IMage: images.FoodBeef,
    Time: "Preparation Time :45:00 Mins",
    Discrip: "Discripition",
    Phara:
      "Lorem ipsum dolor sit amet, consectetur adi tempor incidila bore et dolore magna quis nostrud exercitation ullamco laboris.Lorem ipsum dolor sit amet,",
  },
  {
    id: 2,
    name: "French Salad",
    IMage: images.Foodsalad,
    Time: "Preparation Time :22:00 Mins",
    Discrip: "Discripition",
    Phara:
      "Lorem ipsum dolor sit amet, consectetur adi tempor incidila bore et dolore magna quis nostrud exercitation ullamco laboris.Lorem ipsum dolor sit amet,",
  },
  // {
  //   id:3,
  //   name:"Beef ",
  //   IMage: images.FoodBeef,
  //   Time:"Preparation Time :45:00 Mins",
  //   Discrip:"Discripition",
  //   Phara:"Lorem ipsum dolor sit amet, consectetur adi tempor incidila bore et dolore magna quis nostrud exercitation ullamco laboris.Lorem ipsum dolor sit amet,",
  // },
  // {
  //   id:4,
  //   name:"French Salad ",
  //   IMage: images.Foodsalad,
  //   Time:"Preparation Time :22:00 Mins",
  //   Discrip:"Discripition",
  //   Phara:"Lorem ipsum dolor sit amet, consectetur adi tempor incidila bore et dolore magna quis nostrud exercitation ullamco laboris.Lorem ipsum dolor sit amet,",
  // },
  // {
  //   id:5,
  //   name:"Beef ",
  //   IMage: images.FoodBeef,
  //   Time:"Preparation Time :45:00 Mins",
  //   Discrip:"Discripition",
  //   Phara:"Lorem ipsum dolor sit amet, consectetur adi tempor incidila bore et dolore magna quis nostrud exercitation ullamco laboris.Lorem ipsum dolor sit amet,",
  // },
  // {
  //   id:6,
  //   name:"French Salad ",
  //   IMage: images.Foodsalad,
  //   Time:"Preparation Time :22:00 Mins",
  //   Discrip:"Discripition",
  //   Phara:"Lorem ipsum dolor sit amet, consectetur adi tempor incidila bore et dolore magna quis nostrud exercitation ullamco laboris.Lorem ipsum dolor sit amet,",
  // },
];

export const salepromotion = [
  { id: 1, label: "5%" },
  { id: 2, label: "10%" },
  { id: 3, label: "15%" },
  { id: 4, label: "20%" },
  { id: 5, label: "25%" },
  { id: 6, label: "30%" },
  { id: 7, label: "35%" },
  { id: 8, label: "40%" },
  { id: 9, label: "45%" },
  { id: 10, label: "50%" },
  { id: 11, label: "55%" },
  { id: 12, label: "60%" },
  { id: 13, label: "65%" },
  { id: 14, label: "70%" },
  { id: 15, label: "75%" },
];

export const saleoffer = [
  { id: 1, label: "Buy 1 get 1 free" },
  { id: 2, label: "Buy 3 get 1 free" },
];
export const SalesPromotionjson = [
  {
    id: 1,
    itemname: "Frenchaaaaaayyyy",
    IMage: images.Foodsalad,
    price: "10",
    orders: "22",
  },
  {
    id: 2,
    itemname: "French",
    IMage: images.Car2,
    price: "10",
    orders: "44",
  },
  {
    id: 3,
    itemname: "French ",
    IMage: images.Car2,
    price: "10",
    orders: "44",
  },
  {
    id: 4,
    itemname: "French",
    IMage: images.Car2,
    price: "10",
    orders: "44",
  },
  {
    id: 5,
    itemname: "French  ",
    IMage: images.Car2,
    price: "10",
    orders: "44",
  },
  {
    id: 6,
    itemname: "French ",
    IMage: images.Car2,
    price: "10",
    orders: "44",
  },
  {
    id: 7,
    itemname: "French  ",
    IMage: images.Car2,
    price: "10",
    orders: "44",
  },
];
export const SpecialOffers = [
  {
    id: 1,
    itemname: "French  ",
    IMage: images.Foodsalad,
    price: "10",
    orders: "22",
  },
  {
    id: 2,
    itemname: "French  ",
    IMage: images.Foodsalad,
    price: "10",
    orders: "22",
  },
  {
    id: 3,
    itemname: "French  ",
    IMage: images.Foodsalad,
    price: "10",
    orders: "22",
  },
  {
    id: 4,
    itemname: "French  ",
    IMage: images.Foodsalad,
    price: "10",
    orders: "22",
  },
  {
    id: 5,
    itemname: "French  ",
    IMage: images.Foodsalad,
    price: "10",
    orders: "22",
  },
  {
    id: 6,
    itemname: "French  ",
    IMage: images.Foodsalad,
    price: "10",
    orders: "22",
  },
  {
    id: 7,
    itemname: "French  ",
    IMage: images.Foodsalad,
    price: "10",
    orders: "22",
  },
];
export const ReportBox = [
  {
    id: 1,
    name: "French  ",
    IMage: images.Foodsalad,
    title: "hdsahsdhjhsdjh",
    description:
      "lkdjaljdsajsdaljsdaljdsklsjdlsdjlksdjlsdjjkldsjkdsqrwrwrwrwrwrwrwrwrwrwrw",
  },
  {
    id: 2,
    name: "French  ",
    IMage: images.Car1,
    title: "dsjdslkjsdljsddsj",
    description: "dskjdsjsdjdskjdsdsjklsdajdsdsrqwwwwwwwwwwwwwwwwwwwwwwwwwwww",
  },
  {
    id: 3,
    name: "French  ",
    IMage: images.Foodsalad,
    title: "dsdsdsadssdsdds",
    description:
      "sdadsadsasddsadsadsasdsdrrerreewqqqqqqqqqqqqqqrwwwwwwwwwwwwww",
  },
  {
    id: 4,
    name: "French  ",
    IMage: images.Foodsalad,
    title: "dsadsadssdasd",
    description: "weqewqewqweqweqewqwqeewqwqeewqwqeweqweqqqqqqqqqqqrrwrrrrrrr",
  },
  {
    id: 5,
    name: "French  ",
    IMage: images.Foodsalad,
    title: "weqewqweqweq",
    description: "22wqeewqewqewqewqewqewqewqewqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq",
  },
  {
    id: 6,
    name: "French  ",
    IMage: images.Foodsalad,
    title: "10",
    description:
      "qwrrrrrrrrrrrrrrrrrrrrrrrre333333333333333tttttttttttttttttthhhhhhhhhhhhhhhhhhhhhhhhh",
  },
  {
    id: 7,
    name: "French  ",
    IMage: images.Foodsalad,
    title: "ewqqqqqqqqqq",
    description:
      "ewqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq",
  },
  {
    id: 8,
    name: "French  ",
    IMage: images.Foodsalad,
    title: "ewqqqqqqqqqqq",
    description:
      "ewqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq",
  },
  {
    id: 9,
    name: "French  ",
    IMage: images.Foodsalad,
    title: "weqqqqqq",
    description:
      "qweeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
  },
  {
    id: 10,
    name: "French  ",
    IMage: images.Foodsalad,
    title: "weqqqqqqqqq",
    description:
      "ewqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq",
  },
];

export const Modaldataid = [
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 },
  { id: 6 },
  { id: 7 },
  { id: 8 },
  { id: 9 },
  { id: 10 },
  { id: 11 },
  { id: 12 },
  { id: 13 },
  { id: 14 },
  { id: 15 },
  { id: 16 },
  { id: 17 },
  { id: 18 },
  { id: 19 },
  { id: 20 },
  { id: 21 },
  { id: 22 },
  { id: 23 },
];

export const languages = [
  { id: 1, lang: "Afrikaans" },
  { id: 2, lang: "Albanian" },
  { id: 3, lang: "Amharic" },
  { id: 4, lang: "Arabic" },
  { id: 5, lang: "Armenian" },
  { id: 6, lang: "Azerbaijani" },
  { id: 7, lang: "Basque" },
  { id: 8, lang: "Belarusian" },
  { id: 9, lang: "Bengali" },
  { id: 10, lang: "Bosnian" },
  { id: 11, lang: "Bulgarian" },
  { id: 12, lang: "Catalan" },
  { id: 13, lang: "Cebuano" },
  { id: 14, lang: "Chichewa" },
  { id: 15, lang: "Chinese (Simplified)" },
  { id: 16, lang: "Chinese (Traditional)" },
  { id: 17, lang: "Corsican" },
  { id: 18, lang: "Croatian" },
  { id: 19, lang: "Czech" },
  { id: 20, lang: "Danish" },
  { id: 21, lang: "Dutch" },
  { id: 22, lang: "English" },
  { id: 23, lang: "Esperanto" },
  { id: 24, lang: "Estonian" },
  { id: 25, lang: "Filipino" },
  { id: 26, lang: "Finnish" },
  { id: 27, lang: "French" },
  { id: 28, lang: "Frisian" },
  { id: 29, lang: "Galician" },
  { id: 30, lang: "Georgian" },
  { id: 31, lang: "German" },
  { id: 32, lang: "Greek" },
  { id: 33, lang: "Gujarati" },
  { id: 34, lang: "Haitian Creole" },
  { id: 35, lang: "Hausa" },
  { id: 36, lang: "Hawaiian" },
  { id: 37, lang: "Hebrew" },
  { id: 38, lang: "Hindi" },
  { id: 39, lang: "Hmong" },
  { id: 40, lang: "Hungarian" },
  { id: 41, lang: "Icelandic" },
  { id: 42, lang: "Igbo" },
  { id: 43, lang: "Indonesian" },
  { id: 44, lang: "Irish" },
  { id: 45, lang: "Italian" },
  { id: 46, lang: "Japanese" },
  { id: 47, lang: "Javanese" },
  { id: 48, lang: "Kannada" },
  { id: 49, lang: "Kazakh" },
  { id: 50, lang: "Khmer" },
  { id: 51, lang: "Kinyarwanda" },
  { id: 52, lang: "Korean" },
  { id: 53, lang: "Kurdish (Kurmanji)" },
  { id: 54, lang: "Kyrgyz" },
  { id: 55, lang: "Lao" },
  { id: 56, lang: "Latin" },
  { id: 57, lang: "Latvian" },
  { id: 58, lang: "Lithuanian" },
  { id: 59, lang: "Luxembourgish" },
  { id: 60, lang: "Macedonian" },
  { id: 61, lang: "Malagasy" },
  { id: 62, lang: "Malay" },
  { id: 63, lang: "Malayalam" },
  { id: 64, lang: "Maltese" },
  { id: 65, lang: "Maori" },
  { id: 66, lang: "Marathi" },
  { id: 67, lang: "Mongolian" },
  { id: 68, lang: "Myanmar (Burmese)" },
  { id: 69, lang: "Nepali" },
  { id: 70, lang: "Norwegian" },
  { id: 71, lang: "Odia (Oriya)" },
  { id: 72, lang: "Pashto" },
  { id: 73, lang: "Persian" },
  { id: 74, lang: "Polish" },
  { id: 75, lang: "Portuguese" },
  { id: 76, lang: "Punjabi" },
  { id: 77, lang: "Romanian" },
  { id: 78, lang: "Russian" },
  { id: 79, lang: "Samoan" },
  { id: 80, lang: "Scots Gaelic" },
  { id: 81, lang: "Serbian" },
  { id: 82, lang: "Sesotho" },
  { id: 83, lang: "Shona" },
  { id: 84, lang: "Sindhi" },
  { id: 85, lang: "Sinhala" },
  { id: 86, lang: "Slovak" },
  { id: 87, lang: "Slovenian" },
  { id: 88, lang: "Somali" },
  { id: 89, lang: "Spanish" },
  { id: 90, lang: "Sundanese" },
  { id: 91, lang: "Swahili" },
  { id: 92, lang: "Swedish" },
  { id: 93, lang: "Tajik" },
  { id: 94, lang: "Tamil" },
  { id: 95, lang: "Tatar" },
  { id: 96, lang: "Telugu" },
  { id: 97, lang: "Thai" },
  { id: 98, lang: "Turkish" },
  { id: 99, lang: "Turkmen" },
  { id: 100, lang: "Ukrainian" },
  { id: 101, lang: "Urdu" },
  { id: 102, lang: "Uyghur" },
  { id: 103, lang: "Uzbek" },
  { id: 104, lang: "Vietnamese" },
  { id: 105, lang: "Welsh" },
  { id: 106, lang: "Xhosa" },
  { id: 107, lang: "Yiddish" },
  { id: 108, lang: "Yoruba" },
  { id: 109, lang: "Zulu" },
];

export const courior = [
  { service: "TCS" },
  { service: "Leopards" },
  { service: "DHL" },
  { service: "SpeedeX" },
  { service: "Pakistan Post" },
  { service: "FedEx Express" },
  { service: "M&P" },
  { service: "DCS" },
  { service: "MOVEX" },
  { service: "Trax" },
  { service: "Forrun" },
];
export const UserDashboard = [
  {
    id: 1,
    Username: "User Name",
    image: images.Foodsalad,
    mobile: "Iphone 11",
    login: "Login Form",
    date: "22/3/2023",
    time: "10:00",
  },
  {
    id: 2,
    Username: "User Name",
    image: images.Foodsalad,
    login: "Login Form",
    mobile: "Iphone 11",
    date: "22/3/2023",
    time: "10:00",
  },
  {
    id: 3,
    Username: "User Name",
    image: images.Foodsalad,
    login: "Login Form",
    mobile: "Iphone 11",
    date: "22/3/2023",
    time: "10:00",
  },
];
export const sponsersdata = [
  {
    id: 1,
    image: images.Volta,
  },
  // {
  //   id: 2,
  //   image: images.Allied,
  // },
  {
    id: 3,
    image: images.PSO,
  },
  {
    id: 4,
    image: images.Steel,
  },
  {
    id: 5,
    image: images.Mughal,
  },
  {
    id: 6,
    image: images.Candyland,
  },
  {
    id: 7,
    image: images.Bank,
  },

];

export const subscriptionmdl = [

  {
    id: 1,
    trail: "1 month",
    price: "44",
    package: "Free",
    description: "fdlfdlsfdsldfljljlfdsjdsffdsfdsdffdfdfdssfdfdsfddfsfdsdf",
    checkdata: "ddddddddddddddddddddddhhh",
    btn: "Purchase plan",
  },
  {
    id: 2,
    trail: "1 year",
    price: "55",
    package: "Basic",
    description: "fdlfdlsfdsldfljljlfdsjdsffdsfdsdffdfdfdssfdfdsfddfsfdsdf",
    checkdata: "ddddddddddddddddddddddhhh",
    btn: "Purchase plan",
  },
  {
    id: 3,
    trail: "1 year",
    price: "55",
    package: "Basic",
    description: "fdlfdlsfdsldfljljlfdsjdsffdsfdsdffdfdfdssfdfdsfddfsfdsdf",
    checkdata: "ddddddddddddddddddddddhhh",
    btn: "Purchase plan",
  },

];